import { Box, MenuItem, Select, Typography, useTheme } from '@mui/material';
import { FunctionComponent, useContext } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ConditionBuilderContext } from '../../context/ConditionBuilderContext';
import DocumentBuilderContext from 'views/pages/clients/features/detail/workflow/features/document-builder/context/workflow.context';

interface ThenOrElseBlockHeaderProps {
    thenOrElse?: 'then' | 'else';
}

export const ThenOrElseBlockHeader: FunctionComponent<ThenOrElseBlockHeaderProps> = ({ thenOrElse = 'then' }) => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
        <Typography sx={{ fontWeight: 600, fontSize: '14px', color: '#AAAAAA' }}>{thenOrElse.toUpperCase()}</Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
            {/* <Button sx={{ width: '100px', borderColor: '#fff', p: 0, display: 'flex', gap: '4px' }}>
                <AddIcon sx={{ fontSize: '16px' }} />
                <Typography onClick={() => {}}>Add Output</Typography>
            </Button>
            <Button onClick={() => {}} sx={{ width: '100px', borderColor: '#fff', p: 0, display: 'flex', gap: '4px' }}>
                <AddIcon sx={{ fontSize: '16px' }} />
                <Typography sx={{ fontWeight: 500, fontSize: '12px' }}>Add Rule</Typography>
            </Button> */}
        </Box>
    </Box>
);

interface ThenOrElseBlockContentProps {
    conditionObject: any;
}

export const ThenOrElseBlockContent: FunctionComponent<ThenOrElseBlockContentProps> = ({ conditionObject }) => {
    const { updateConditions, nodeId, edges, setEdges } = useContext(ConditionBuilderContext);
    const { documentBuilder } = useContext(DocumentBuilderContext);

    const nextSteps = documentBuilder.workflow.flowNodes
        .map((node: any) => ({
            id: node.id,
            name: `${node.type} node - (${node?.data?.name || 'Unnamed'})`
        }))
        .filter((node: any) => node.id !== nodeId) as {
        id: string;
        name: string;
    }[];

    const selectedNextStepId = conditionObject.content;
    const selectedNextStep = nextSteps.find((step) => step.id === selectedNextStepId);

    const selectNextStepHandler = (targetId: string) => {
        const newFlowEdges = [...edges].filter((edge: any) => edge.source !== nodeId || edge.target !== conditionObject.content);
        newFlowEdges.push({
            id: `${nodeId}-${targetId}`,
            type: 'approval_task_outgoing_connector',
            source: nodeId,
            target: targetId,
            animated: true,
            label: 'conditional connection',
            style: { stroke: '#EE9A8F' },
            labelBgPadding: [8, 4],
            labelBgBorderRadius: 4,
            labelBgStyle: { fill: '#FFCC00', color: '#fff', fillOpacity: 0.7 },
            markerEnd: {
                type: 'arrow',
                color: '#EE9A8F'
            }
        });

        setEdges(newFlowEdges);

        const newConditionObject = { ...conditionObject, content: targetId };
        updateConditions(newConditionObject);
    };

    const theme = useTheme();
    return (
        <Box>
            <Box sx={{ display: 'flex', ml: 3 }}>
                <Box sx={{ display: 'flex', gap: '40px' }}>
                    {/* TODO : Add condition on length of actions */}
                    {false && (
                        <Box
                            sx={{
                                position: 'relative',
                                borderRight: `2px solid ${theme.palette.primary[500]}`,
                                mt: '7px'
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    width: '12px',
                                    height: '2px',
                                    background: theme.palette.primary[500],
                                    top: 0
                                }}
                            >
                                {' '}
                            </Box>
                            <Box sx={{ position: 'absolute', top: 'calc(50% - 12px)', right: '-20px' }}>
                                <Box
                                    sx={{
                                        p: '4px 8px',
                                        border: `1px solid ${theme.palette.primary[500]}`,
                                        borderRadius: '100px',
                                        width: '38px',
                                        background: '#fff'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: '10px',
                                            color: theme.palette.primary[500]
                                        }}
                                    >
                                        AND
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    width: '12px',
                                    height: '2px',
                                    background: theme.palette.primary[500],
                                    bottom: 0
                                }}
                            >
                                {' '}
                            </Box>
                        </Box>
                    )}
                    <Box>
                        {['one_go_to'].map((selectorsItem: any, indexIf: number) => (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItmes: 'center',
                                    gap: '10px',
                                    justifyContent: 'flex-end',
                                    mt: 1
                                }}
                            >
                                <Select
                                    value={selectedNextStepId}
                                    onChange={(e) => {
                                        selectNextStepHandler(e.target.value);
                                    }}
                                    disableUnderline
                                    IconComponent={KeyboardArrowDownIcon}
                                    renderValue={(value) => (
                                        <div style={{ display: 'flex' }}>
                                            {selectedNextStep?.name !== 'Do nothing' && (
                                                <span style={{ fontWeight: 400, marginRight: '5px' }}>Go To</span>
                                            )}
                                            <span>{selectedNextStep?.name}</span>
                                        </div>
                                    )}
                                    sx={{
                                        fieldset: {
                                            border: '1px solid',
                                            borderColor: theme.palette.grey[400],
                                            borderRadius: '6px'
                                        },
                                        '.MuiSelect-select': {
                                            p: '4px 8px',
                                            color: theme.palette.grey[400],
                                            fontSize: '14px',
                                            fontFamily: 'Inter',
                                            fontWeight: 400,
                                            width: '100%',
                                            background: theme.palette.dark[100]
                                        }
                                    }}
                                >
                                    {nextSteps.map((item, index: number) => (
                                        <MenuItem key={index} value={item.id}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    width: '100%'
                                                }}
                                            >
                                                {item.name}
                                            </Box>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
