import { Box, Dialog, Grid } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import MissingInstagramHelp from './MissingInstagramHelp';
import stepByStep from 'assets/stepByStep.mp4';

interface HelpDialogProps {
    openDialogHelp: boolean;
    handleCloseDialogHelp: any;
}

const HelpDialog = ({ openDialogHelp, handleCloseDialogHelp }: HelpDialogProps) => (
    <Dialog
        sx={{ '.MuiPaper-root': { maxWidth: '700px', p: 0, maxHeight: '600px', overflow: 'hidden' } }}
        open={openDialogHelp}
        onClose={handleCloseDialogHelp}
    >
        <Box sx={{ borderRadius: '16px', width: { xs: '100%', sm: '662px' }, position: 'relative' }}>
            <Box onClick={handleCloseDialogHelp} sx={{ position: 'absolute', top: '8px', right: '8px', cursor: 'pointer' }}>
                <CloseIcon />
            </Box>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <video width="100%" height="600" controls>
                            <source src={stepByStep} type="video/mp4" />
                            <track src="" kind="captions" label="english_captions" />
                        </video>
                    </Box>
                    <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                        <video width="100%" height="300" controls>
                            <source src={stepByStep} type="video/mp4" />
                            <track src="" kind="captions" label="english_captions" />
                        </video>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} p={2} sx={{ overflow: 'scroll', maxHeight: '600px' }}>
                    <MissingInstagramHelp />
                </Grid>
            </Grid>
        </Box>
    </Dialog>
);

export default HelpDialog;
