import { AdminPanelHeader } from './AdminPanelHeader';
import { AdminPanelFooter } from './AdminPanelFooter';
import { ChannelNameInputField } from './ChannelNameInputField';

import { UserList } from './UserList';
import { WhatsappPanel } from './WhatsappPanel';

import { useWorkspaceController } from '../../context/WorkspaceController';
import { useAdminPanelFormState } from './context/AdminPanelFormContext';

import { ChannelImageInputField } from './ChannelImageInputField';

import { Grid } from '@mui/material';

export const EditChannel = () => {
    const { closeAdminPanel } = useWorkspaceController();
    const { name, handleInputChange, handleSubmit, errors } = useAdminPanelFormState();

    return (
        // <div className="admin-panel__form">
        <>
            <div className="admin-panel__form">
                <AdminPanelHeader onClose={closeAdminPanel} title="Edit Channel" />
            </div>
            <div>
                <Grid sx={{ p: { xs: '24px 0', sm: 4 }, height: 1000 }} className="overflow-y">
                    <Grid container spacing={2}>
                        <Grid item xs={3} sm={1}>
                            <ChannelImageInputField />
                        </Grid>
                        <Grid item xs={9} sm={11}>
                            <ChannelNameInputField name={name} error={errors.name} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <UserList isCreate={false} />
                        </Grid>
                        <Grid item xs={12}>
                            <WhatsappPanel />
                        </Grid>
                        <Grid item xs={12}>
                            <AdminPanelFooter
                                buttonText="Save Changes"
                                onButtonClick={handleSubmit}
                                onCancelButtonClick={closeAdminPanel}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {/* </Box> */}
            </div>
        </>
    );
};
