import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';

type TextInputProps = Partial<TextFieldProps> & {
    name: string;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
};
export const TextInput = (props: TextInputProps) => {
    const { name, sx, onBlur, ...others } = props;

    return (
        <TextField
            sx={{
                '.MuiOutlinedInput-input': {
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    fontSize: '14px'
                },
                ...sx
            }}
            id={name}
            name={name}
            variant="outlined"
            fullWidth
            onBlur={onBlur}
            {...others}
        />
    );
};
