import { useState } from 'react';
import { Typography, Box, Grid, CardMedia, Popover } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import book_open from 'assets/images/book_open.svg';
import logoMini from 'assets/images/logoMini.svg';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import DIalogWeiwImage from './DIalogWeiwImage';
import DIalogConfirmDelete from './DIalogConfirmDelete';
import DIalogAddPortfolio from './DIalogAddPortfolio';

const Portfolio = ({ porfolio, role }: any) => {
    const theme = useTheme();
    const [openDialogImage, setOpenDialogImage] = useState(false);
    const [openDialogEditPortfolio, setOpenDialogEditPortfolio] = useState(false);
    const [selectedImage, setSelectedImage] = useState(0);
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const [selectedPortfolio, setSelectedPortfolio] = useState<any>();
    const id = anchorEl ? 'simple-popover' : undefined;
    const [dialogConfirmDelete, setDialogConfirmDelete] = useState(false);
    const openPopover = (event: any) => setAnchorEl(event.currentTarget);
    const closePopover = () => setAnchorEl(null);
    const handleCloseDialogImage = () => setOpenDialogImage(false);
    const handleCloseDialogEditPortfolio = () => setOpenDialogEditPortfolio(false);
    const handleOpenDialogEditPortfolio = () => setOpenDialogEditPortfolio(true);
    const handleOpenDialogImage = (index: number) => {
        setSelectedImage(index);
        setOpenDialogImage(true);
    };
    const handleSelectedImagePrev = () => {
        if (selectedImage !== 0) setSelectedImage(selectedImage - 1);
    };
    const handleSelectedImageNext = () => {
        if (selectedImage + 1 !== porfolio.images.length) setSelectedImage(selectedImage + 1);
    };
    const handleCloseDialogConfirmDelete = () => setDialogConfirmDelete(false);
    const handleOpenDialogConfirmDelete = () => {
        setDialogConfirmDelete(true);
        closePopover();
    };
    const editPortfolio = (portfolio: any) => {
        setSelectedPortfolio(portfolio);
        handleOpenDialogEditPortfolio();
        closePopover();
    };
    return (
        <Box sx={{ width: { xs: '100%', sm: '80%', lg: '60%' }, mb: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ p: 2, borderRadius: '100px', background: theme.palette.orange[200] }}>
                        <Box sx={{ width: '20px', height: '20px' }}>
                            <CardMedia component="img" image={book_open} alt="" />
                        </Box>
                    </Box>
                    <Box ml={1}>
                        <Typography
                            sx={{
                                color: '#2D2D2D',
                                fontFamily: 'Inter',
                                fontWeight: 600,
                                fontSize: '15px'
                            }}
                        >
                            {porfolio.skil}
                        </Typography>
                        <Typography
                            sx={{
                                color: '#2D2D2D',
                                fontFamily: 'Inter',
                                fontWeight: 500,
                                fontSize: '13px',
                                fontStyle: 'italic',
                                mt: 1
                            }}
                        >
                            {porfolio.finance}
                        </Typography>
                    </Box>
                </Box>
                {porfolio.snikpicClient ? (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            border: `1px solid ${theme.palette.orange.main}`,
                            p: '6px 8px',
                            borderRadius: '40px'
                        }}
                    >
                        <Box sx={{ width: '12px', height: '12px' }}>
                            <CardMedia sx={{ objectFit: 'contain' }} component="img" image={logoMini} alt="" />
                        </Box>
                        <Typography
                            sx={{
                                color: theme.palette.orange.main,
                                fontFamily: 'Inter',
                                fontWeight: 500,
                                fontSize: '10px',
                                ml: 1
                            }}
                        >
                            <FormattedMessage id="snikpic_client" />
                        </Typography>
                    </Box>
                ) : (
                    <Box>
                        {role === 'freelancer' && (
                            <Box>
                                <MoreVertOutlinedIcon onClick={openPopover} sx={{ cursor: 'pointer' }} />
                                <Popover
                                    id={id}
                                    open={!!anchorEl}
                                    anchorEl={anchorEl}
                                    onClose={closePopover}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                    }}
                                >
                                    <Box sx={{ p: '24px' }}>
                                        <Box
                                            onClick={() => editPortfolio(porfolio)}
                                            sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: theme.palette.grey[400],
                                                    fontFamily: 'Inter',
                                                    fontWeight: 500,
                                                    fontSize: '14px',
                                                    mb: '10px'
                                                }}
                                            >
                                                <FormattedMessage id="edit" />
                                            </Typography>
                                        </Box>
                                        <Box
                                            onClick={handleOpenDialogConfirmDelete}
                                            mt={1}
                                            sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: theme.palette.grey[400],
                                                    fontFamily: 'Inter',
                                                    fontWeight: 500,
                                                    fontSize: '14px'
                                                }}
                                            >
                                                <FormattedMessage id="delete" />
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Popover>
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
            <Typography
                sx={{
                    color: theme.palette.grey[300],
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '135%',
                    mt: 3,
                    mb: 3
                }}
            >
                {porfolio.description}
            </Typography>
            <Grid container spacing={2}>
                {porfolio.images.map((image: string, index: number) => (
                    <Grid key={index} item xs={12} sm={4}>
                        <CardMedia
                            onClick={() => handleOpenDialogImage(index)}
                            sx={{ objectFit: 'contain', borderRadius: '8px', cursor: 'pointer' }}
                            component="img"
                            image={image}
                            alt=""
                        />
                    </Grid>
                ))}
            </Grid>
            <DIalogWeiwImage
                handleCloseDialogImage={handleCloseDialogImage}
                openDialogImage={openDialogImage}
                image={porfolio.images[selectedImage]}
                skil={porfolio.skil}
                selectedImages={selectedImage + 1}
                imagesCount={porfolio.images?.length}
                handleSelectedImagePrev={handleSelectedImagePrev}
                handleSelectedImageNext={handleSelectedImageNext}
            />
            <DIalogConfirmDelete
                onClose={handleCloseDialogConfirmDelete}
                open={dialogConfirmDelete}
                title="are_you_sure_you_want_to_delete_this_item"
            />
            <DIalogAddPortfolio
                selectedPortfolio={selectedPortfolio}
                onClose={handleCloseDialogEditPortfolio}
                openDialog={openDialogEditPortfolio}
            />
        </Box>
    );
};

export default Portfolio;
