import { Theme } from '@mui/material/styles';
import { TaskDueDateStatusEnum } from 'features/workflow-task-execution/types';
import StatusChip from './StatusChip';

interface TaskDueDateStatusProps {
    status: TaskDueDateStatusEnum | undefined;
}

const DueDateStatusMapping = (
    theme: Theme
): { [key in TaskDueDateStatusEnum | 'default']: { backgroundColor: string; textColor: string; text: string } } => ({
    [TaskDueDateStatusEnum.OVERDUE]: {
        backgroundColor: theme.palette.error[100],
        textColor: theme.palette.primary.light,
        text: 'Overdue'
    },
    [TaskDueDateStatusEnum.DUE_IN_LESS_THAN_12_HOURS]: {
        backgroundColor: theme.palette.orange[100],
        textColor: theme.palette.primary.light,
        text: 'Less than 12 hours left'
    },
    [TaskDueDateStatusEnum.ON_TRACK]: {
        backgroundColor: theme.palette.success[400],
        textColor: theme.palette.primary.light,
        text: 'On track'
    },
    default: {
        backgroundColor: theme.palette.grey[500],
        textColor: theme.palette.grey[100],
        text: 'No status'
    }
});

export default function TaskDueDateStatus({ status }: TaskDueDateStatusProps) {
    return <StatusChip status={status} mapping={DueDateStatusMapping} />;
}
