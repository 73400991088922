import axios from 'axios';

interface GetWhatsappMembersInput {
    twilioChannelId: string;
}

export const fetchWhatsappMembers = async (input: GetWhatsappMembersInput) => {
    const baseURL = process.env.REACT_APP_SNIKPIC_BASE_URL;
    const endpointURL = `${baseURL}/twilio/whatsapp/get_participants_of_conversation`;
    try {
        const response = await axios.post(
            endpointURL,
            {
                channelSid: input.twilioChannelId
            },
            {
                headers: {
                    authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '')?.tokens?.access_token ?? ''}`
                }
            }
        );
        return response.data;
    } catch (e) {
        // console.log(JSON.stringify(e));
        return null;
        // TO DO - handle error ?
    }
};

interface RemoveMemberFromConversationInput {
    channelSid: string;
    participantSid: string;
}

export const removeMemberFromConversation = async (input: RemoveMemberFromConversationInput) => {
    const baseURL = process.env.REACT_APP_SNIKPIC_BASE_URL;
    const endpointURL = `${baseURL}/twilio/whatsapp/remove_participant_from_conversation`;
    try {
        const response = await axios.post(
            endpointURL,
            {
                channelSid: input.channelSid,
                participantSid: input.participantSid
            },
            {
                headers: {
                    authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '')?.tokens?.access_token ?? ''}`
                }
            }
        );
        return response.data;
    } catch (e) {
        // console.log(JSON.stringify(e));
        return null;
        // TO DO - handle error ?
    }
};
