import { AdminPanelHeader } from './AdminPanelHeader';
import { AdminPanelFooter } from './AdminPanelFooter';
import { ChannelNameInputField } from './ChannelNameInputField';
import { UserList } from './UserList';

import { useAdminPanelFormState } from './context/AdminPanelFormContext';
import { useWorkspaceController } from '../../context/WorkspaceController';

// import { ChannelImageInputField } from './ChannelImageInputField';

import { Grid } from '@mui/material';
import { ChannelImageInputField } from './ChannelImageInputField';

export const CreateChannel = () => {
    const { closeAdminPanel } = useWorkspaceController();
    const { createChannelType, name, handleInputChange, handleSubmit, errors } = useAdminPanelFormState();

    return (
        <>
            <div className="admin-panel__form">
                <AdminPanelHeader
                    onClose={closeAdminPanel}
                    title={createChannelType === 'team' ? 'Create a New Channel' : 'Send a Direct Message'}
                />
            </div>

            <Grid sx={{ p: 4 }}>
                <Grid container spacing={2}>
                    {/* <Grid item xs={1}> */}
                    {/* <ChannelImageInputField /> */}
                    {/* </Grid> */}
                    <Grid item xs={12}>
                        {createChannelType === 'team' && (
                            <>
                                <ChannelNameInputField name={name} error={errors.name} onChange={handleInputChange} />
                            </>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <UserList isCreate />
                    </Grid>
                    <Grid item xs={12}>
                        <AdminPanelFooter
                            onButtonClick={handleSubmit}
                            buttonText={createChannelType === 'team' ? 'Create Channel' : 'Create Message Group'}
                            onCancelButtonClick={closeAdminPanel}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
