import { FormControl, FormHelperText, Typography } from '@mui/material';
import { useFieldHook } from './useFieldHook';
import { useSelector } from 'store';
import DragAndDropFilesV2 from 'ui-component/dragAndDropFilesV2';
import { BaseFieldProps } from 'features/workflow-task-execution/types';

interface FileUploaderProps extends BaseFieldProps {}

export default function FileUploader({ field, setSavingStatus, handleFieldValueUpdate }: FileUploaderProps) {
    const [value, handleChange, intl] = useFieldHook<{ url: string }[]>({ field, setSavingStatus, handleFieldValueUpdate });
    const { currentClient } = useSelector((state) => state.clients);
    const basePath = currentClient?.id ? `clients/${currentClient.id}/deliverables` : 'clients/deliverables';

    return (
        <>
            <Typography sx={{ color: '#000', fontFamily: 'Inter', fontWeight: 500, fontSize: '14px', mb: 2, mt: 3 }}>
                {field.title}
            </Typography>
            <DragAndDropFilesV2 files={value} setFiles={handleChange} basePath={basePath} disabled={field.permission?.type === 'READ'} />
            <FormControl error={Boolean(field.permission?.is_required)}>
                <FormHelperText>
                    {field.permission?.is_required ? intl.formatMessage({ id: 'please_upload_files_to_complete_your_task' }) : ''}
                </FormHelperText>
            </FormControl>
        </>
    );
}
