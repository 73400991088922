import {
    Typography,
    Box,
    Breadcrumbs,
    Grid,
    CardMedia,
    RadioGroup,
    Popover,
    Radio,
    FormControlLabel,
    Checkbox,
    Divider,
    Drawer,
    CircularProgress
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import ButtonCustom from 'ui-component/extended/Button';
import NotFoundImg from 'ui-component/Offer';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import shuffle from 'assets/images/shuffle.svg';
import TasksTable from './TasksTable';
import AddOrEditTask from './AddTask';
import Filters from './Filters';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import TaskMobile from './TaskMobile';
import { useLazyQuery } from '@apollo/client';
import { GET_USER_ORGANIZATIONS } from 'services/graphQL';
import { getStandaloneTasks, GetTaskFilters, SortBy } from 'services/rest/workflow';
import { loadState } from 'utils/storage';
import useAuth from 'hooks/useAuth';
import { OpenInFull } from '@mui/icons-material';

const Tasks = () => {
    const theme = useTheme();
    const { user } = useAuth();
    const [tasks, setTasks] = React.useState<any>();
    const [openDivider, setOpenDivider] = React.useState(false);
    const [sortOrder, setSortOrder] = React.useState<'asc' | 'desc'>('asc');
    const [sortFieldByDueDate, setSortFieldByDueDate] = React.useState(true);
    const [openDividerFilter, setOpenDividerFilter] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const [isLoadingTasks, setIsLoadingTasks] = React.useState(false);

    const [filters, setFilters] = React.useState<GetTaskFilters>({
        userIds: [user.id],
        status: ['ACTIVE'],
        type: ['standalone', 'workflow'],
        clientOrganizationIds: []
    });
    const [sort, setSort] = React.useState<SortBy>({
        field: 'due_date',
        order: 'asc'
    });

    const openPopover = Boolean(anchorEl);
    const id = openPopover ? 'simple-popover' : undefined;

    const [organizationName, setOrganizationName] = React.useState('');

    const [getUser] = useLazyQuery(GET_USER_ORGANIZATIONS, {
        onCompleted: (res) => {
            setOrganizationName(res.organizations[0].name);
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first'
    });

    const userInfo = loadState('user');
    const organizationId = userInfo.member.organizationId;

    const handleClosePopover = () => {
        setSort({
            field: sortFieldByDueDate ? 'due_date' : 'created_at',
            order: sortOrder
        });
        setAnchorEl(null);
    };
    const handleClickPopover = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const refreshTasks = () => {
        setIsLoadingTasks(true);
        getStandaloneTasks(organizationId, filters, sort).then((data) => {
            setTasks(data.data);
            setIsLoadingTasks(false);
        });
    };

    React.useEffect(() => {
        getUser({
            variables: {
                user_id: userInfo.user.id
            }
        });
        refreshTasks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        refreshTasks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, sort]);

    const handleClickCloseDivider = () => {
        setOpenDivider(false);
        setOpenDividerFilter(false);
    };
    const handleOpenDivider = () => {
        setOpenDivider(true);
    };

    const handleOpenDividerFilter = () => {
        setOpenDividerFilter(true);
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSortFieldByDueDate(event.target.checked);
    };
    return (
        <Box>
            <Breadcrumbs aria-label="breadcrumb">
                <Typography sx={{ textTransform: 'capitalize', color: theme.palette.grey[200], fontFamily: 'Inter', fontWeight: 500 }}>
                    {organizationName}
                </Typography>
                <Typography sx={{ textTransform: 'capitalize', color: theme.palette.grey[300], fontFamily: 'Inter', fontWeight: 500 }}>
                    <FormattedMessage id="tasks" />
                </Typography>
            </Breadcrumbs>
            <Grid mt={2} container alignItems="center">
                <Grid item xs={6}>
                    <Typography
                        sx={{
                            textTransform: 'capitalize',
                            color: theme.palette.grey[400],
                            fontSize: '24px',
                            fontWeight: 500,
                            fontFamily: 'Inter'
                        }}
                    >
                        <FormattedMessage id="tasks" />
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Box sx={{ width: '146px' }}>
                            <ButtonCustom
                                onClick={handleOpenDivider}
                                titleBtn={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography
                                            sx={{
                                                color: theme.palette.background.paper,
                                                fontWeight: 500,
                                                fontFamily: 'Inter',
                                                fontSize: '20px',
                                                m: '-3px 5px 0 0'
                                            }}
                                        >
                                            +
                                        </Typography>
                                        <Typography sx={{ fontWeight: 500, fontFamily: 'Inter' }}>
                                            <FormattedMessage id="add_task" />
                                        </Typography>
                                    </Box>
                                }
                                colorBtn="red"
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid mt={3} mb={3} container justifyContent="end" alignItems="center">
                    {/* <Grid item xs={5}>
                        <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                            <SearchSharpIcon sx={{ fill: theme.palette.grey[600] }} />
                            <Typography sx={{ color: theme.palette.grey[600], fontFamily: 'Inter', fontWeight: 500 }}>
                                <FormattedMessage id="search" />
                            </Typography>
                        </Box>
                    </Grid> */}
                    <Grid item xs={7}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Box
                                onClick={handleOpenDividerFilter}
                                mr={2}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    border: `1px solid ${theme.palette.dark[700]}`,
                                    borderRadius: '8px',
                                    p: '8px 16px 8px 8px',
                                    cursor: 'pointer'
                                }}
                            >
                                <FilterAltOutlinedIcon sx={{ fill: theme.palette.grey[600], fontSize: '20px', mt: '-1px' }} />
                                <Typography ml={1} sx={{ color: theme.palette.grey[600], fontFamily: 'Inter', fontWeight: 500 }}>
                                    <FormattedMessage id="filter" />
                                </Typography>
                            </Box>
                            <Box
                                onClick={handleClickPopover}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    border: `1px solid ${theme.palette.dark[700]}`,
                                    borderRadius: '8px',
                                    p: '8px 16px 8px 8px',
                                    cursor: 'pointer'
                                }}
                            >
                                <CardMedia sx={{ objectFit: 'contain' }} component="img" width="18px" image={shuffle} alt="alt image" />
                                <Typography ml={1} sx={{ color: theme.palette.grey[600], fontFamily: 'Inter', fontWeight: 500 }}>
                                    <FormattedMessage id="sort" />
                                </Typography>
                            </Box>
                            <Popover
                                id={id}
                                open={openPopover}
                                anchorEl={anchorEl}
                                onClose={handleClosePopover}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                            >
                                <Box sx={{ width: '237px' }}>
                                    <Box sx={{ p: '24px 28px 10px 24px' }}>
                                        <Typography mb={1} sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500 }}>
                                            <FormattedMessage id="order_by" />
                                        </Typography>
                                        <FormControlLabel
                                            sx={{
                                                '.MuiFormControlLabel-label': { fontFamily: 'Inter', fontSize: '14px', mt: '2px' }
                                            }}
                                            control={
                                                <Checkbox
                                                    checkedIcon={<CheckBoxOutlinedIcon />}
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }}
                                                    checked={sortFieldByDueDate}
                                                    onChange={handleChange}
                                                    name="sortFieldByDueDate"
                                                />
                                            }
                                            label={<FormattedMessage id="due_date" />}
                                        />
                                    </Box>
                                    <Divider sx={{ background: theme.palette.grey[500] }} />
                                    <Box sx={{ p: '16px 24px' }}>
                                        <Typography mb={1} sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500 }}>
                                            <FormattedMessage id="sort" />
                                        </Typography>
                                        <RadioGroup
                                            aria-labelledby="status"
                                            name="status"
                                            value={sortOrder}
                                            onChange={(e) => setSortOrder(e.target.value as any)}
                                        >
                                            <FormControlLabel value="asc" control={<Radio />} label={<FormattedMessage id="ascending" />} />
                                            <FormControlLabel
                                                value="desc"
                                                control={<Radio />}
                                                label={<FormattedMessage id="descending" />}
                                            />
                                        </RadioGroup>
                                    </Box>
                                    <Divider sx={{ background: theme.palette.grey[500] }} />
                                    <Box pb={2} sx={{ display: 'flex', width: '82%', m: '16px auto 0', justifyContent: 'space-between' }}>
                                        <Box sx={{ width: '87px' }}>
                                            <ButtonCustom
                                                onClick={handleClosePopover}
                                                colorBtn="white"
                                                titleBtn={<FormattedMessage id="cancel" />}
                                            />
                                        </Box>
                                        <Box sx={{ width: '87px' }}>
                                            <ButtonCustom
                                                onClick={handleClosePopover}
                                                colorBtn="red"
                                                titleBtn={<FormattedMessage id="apply" />}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Popover>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>

            {!isLoadingTasks && Boolean(tasks?.length) && (
                <Box>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <TasksTable item={tasks} refresh={refreshTasks} />
                        <Typography
                            mt={4}
                            sx={{
                                color: theme.palette.grey[600],
                                fontFamily: 'Inter',
                                fontWeight: 600,
                                fontSize: '10px',
                                textTransform: 'uppercase',
                                letterSpacing: '0.12em',
                                textAlign: 'center'
                            }}
                        >
                            <FormattedMessage id="end_of_page" /> - {tasks?.length} <FormattedMessage id="results" />
                        </Typography>
                    </Box>
                    {/* <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                        {tasks &&
                            tasks.map((task: any) => (
                                <Box key={task.id}>
                                    <TaskMobile task={task} refresh={refreshTasks} />
                                </Box>
                            ))}
                    </Box> */}
                </Box>
            )}
            {!isLoadingTasks && !tasks?.length && (
                <Box sx={{ width: { xs: '315px', sm: '393px' }, m: '10% auto 0' }}>
                    <NotFoundImg title={<FormattedMessage id="no_tasks_created" />} image />
                </Box>
            )}
            {isLoadingTasks && (
                <Box sx={{ width: { xs: '315px', sm: '393px' }, m: '10% auto 0', display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            )}
            <Drawer keepMounted anchor="right" open={openDivider || openDividerFilter} onClose={handleClickCloseDivider}>
                {openDivider && <AddOrEditTask handleClickCloseDivider={handleClickCloseDivider} refresh={refreshTasks} />}
                {openDividerFilter && (
                    <Filters handleClickCloseDivider={handleClickCloseDivider} setFilters={setFilters} filters={filters} />
                )}
            </Drawer>
        </Box>
    );
};

export default Tasks;
