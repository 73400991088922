import React, { useState } from 'react';
import { Popover, Dialog, TableCell, TableRow, Typography, FormControlLabel, Radio, RadioGroup, Box, IconButton } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import DialogConfirm from 'ui-component/dialogConfirm';
import moment from 'moment';
import { STATUS_ACTIVE, STATUS_CANCELED, STATUS_INACTIVE } from 'utils/constants';
import { ProjectMemberType } from 'types/clients';
import { Menu } from './menu';
import { MemberBar } from '../../../components/member-bar';

interface ServiceTableRowProps {
    project: any;
    availableTeamMembers: ProjectMemberType[];
    onChangeStatus: (status: string, id: string) => void;
    onAddTeamMember: (memberId: string, projectId: string) => void;
    onViewService: (projectId: string) => void;
}
export const ServiceTableRow = ({
    project,
    availableTeamMembers,
    onChangeStatus,
    onAddTeamMember,
    onViewService
}: ServiceTableRowProps) => {
    console.log('In ServiceTableRow, availableTeamMembers: ', availableTeamMembers);
    const theme = useTheme();
    const [showConfirm, setShowConfirm] = useState(false);
    const [anchorMenu, setAnchorMenu] = useState<any>(null);
    const [anchorElStatus, setAnchorElStatus] = useState<any>(null);
    const idStatus = anchorElStatus ? 'simple-popover' : undefined;

    const openMenu = (event: any) => setAnchorMenu(event.currentTarget);
    const closeMenu = () => setAnchorMenu(null);
    const openStatusMenu = (event: any) => setAnchorElStatus(event.currentTarget);
    const closeStatusMenu = () => setAnchorElStatus(null);
    const openConfirm = () => setShowConfirm(true);
    const closeConfirm = () => setShowConfirm(false);

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        closeStatusMenu();
        onChangeStatus(event.target.value === 'running' ? STATUS_ACTIVE : STATUS_INACTIVE, project.id);
    };

    const handleDeactivate = () => {
        closeConfirm();
        onChangeStatus(STATUS_CANCELED, project.id);
    };

    const handleAddTeamMember = async (member: ProjectMemberType) => {
        closeStatusMenu();
        onAddTeamMember(member.orgMemberId, project.id);
    };

    const handleMenu = (key: string) => {
        switch (key) {
            case 'view':
                onViewService(project.id);
                closeMenu();
                break;
            case 'deactivate':
                openConfirm();
                closeMenu();
                break;
            default:
                break;
        }
    };

    const handleClickCell = () => onViewService(project.id);

    const status = project && project.status === 'ACTIVE' ? 'running' : 'stopped';
    const membersAvailable = availableTeamMembers.filter((member: ProjectMemberType) =>
        project.teamMembers.every((mem: ProjectMemberType) => mem.orgMemberId !== member.orgMemberId)
    );
    console.log('In ServiceTableRow, membersAvailable: ', membersAvailable);

    return (
        <TableRow hover role="checkbox" tabIndex={-1} key={project.name} sx={{ position: 'relative' }}>
            <TableCell align="left" onClick={handleClickCell}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {/* <Avatar sx={{ width: '32px', height: '32px' }} alt="Remy Sharp" src={project.src} /> */}
                    <Typography ml={1} sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500 }}>
                        {project.name}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell scope="row" onClick={handleClickCell}>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ display: 'flex' }} onClick={(e) => e.stopPropagation()}>
                        <MemberBar members={project.teamMembers} onAddMember={handleAddTeamMember} membersAvailable={membersAvailable} />
                    </Box>
                </Box>
            </TableCell>
            <TableCell align="left" onClick={handleClickCell}>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={(e) => e.stopPropagation()}>
                        <Box
                            sx={{
                                borderRadius: '72px',
                                p: '8px 16px',
                                background: status === 'running' ? 'rgba(33, 150, 243, 0.08)' : theme.palette.primary[500]
                            }}
                        >
                            <Typography
                                sx={{
                                    color: status === 'running' ? '#2196F3' : theme.palette.secondary[400],
                                    fontFamily: 'Inter',
                                    fontWeight: 500
                                }}
                            >
                                <FormattedMessage id={status} />
                            </Typography>
                        </Box>
                        <Box sx={{ borderRight: `1px solid ${theme.palette.grey[500]}`, m: '0 5px' }}>
                            <Typography sx={{ color: theme.palette.grey[500], fontFamily: 'Inter', fontWeight: 600, display: 'none' }}>
                                a
                            </Typography>
                        </Box>
                        <IconButton onClick={openStatusMenu}>
                            <EditIcon sx={{ color: theme.palette.grey[400] }} />
                        </IconButton>
                        <Popover
                            id={idStatus}
                            open={!!anchorElStatus}
                            anchorEl={anchorElStatus}
                            onClose={closeStatusMenu}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                        >
                            <Box sx={{ p: '20px' }}>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={status}
                                    onChange={handleChange}
                                >
                                    <FormControlLabel
                                        color="secondary"
                                        value="running"
                                        control={<Radio />}
                                        label={<FormattedMessage id="running" />}
                                    />
                                    <FormControlLabel
                                        color="secondary"
                                        value="stopped"
                                        control={<Radio />}
                                        label={<FormattedMessage id="stopped" />}
                                    />
                                </RadioGroup>
                            </Box>
                        </Popover>
                    </Box>
                </Box>
            </TableCell>
            <TableCell align="left" onClick={handleClickCell}>
                <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600 }}>
                    {moment(project.date).format('DD MMMM, YYYY')}
                </Typography>
            </TableCell>
            <TableCell align="left" onClick={handleClickCell}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box onClick={(e) => e.stopPropagation()}>
                        <IconButton onClick={openMenu}>
                            <MoreHorizIcon sx={{ color: theme.palette.grey[400] }} />
                        </IconButton>
                        <Menu id={idStatus} open={!!anchorMenu} anchorEl={anchorMenu} onClose={closeMenu} onMenu={handleMenu} />
                    </Box>
                </Box>
            </TableCell>
            <Dialog sx={{ '.MuiDialog-paper': { minWidth: { xs: '350px', sm: '562px' } } }} onClose={closeConfirm} open={showConfirm}>
                <DialogConfirm onConfirm={handleDeactivate} onClose={closeConfirm} />
            </Dialog>
        </TableRow>
    );
};
