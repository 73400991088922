import { Typography } from '@mui/material';
import SelectCustom from 'ui-component/selectCustom';
import { useFieldHook } from './useFieldHook';
import { BaseFieldProps } from 'features/workflow-task-execution/types';

interface DropdownProps extends BaseFieldProps {}

// interface configuration {
//     options: string[];
// }

export default function Dropdown({ field, setSavingStatus, handleFieldValueUpdate }: DropdownProps) {
    const [value, handleChange, intl] = useFieldHook<string>({ field, setSavingStatus, handleFieldValueUpdate });

    return (
        <>
            <Typography sx={{ color: '#000', fontFamily: 'Inter', fontWeight: 500, fontSize: '14px', mb: 2, mt: 3 }}>
                {field.title}
            </Typography>
            <SelectCustom
                value={value}
                setValue={handleChange}
                labels={field.configuration.options}
                sx={{ background: '#fff' }}
                disabled={field.permission?.type === 'READ'}
                error={Boolean(field.permission?.is_required)}
                helperText={
                    field.permission?.is_required ? intl.formatMessage({ id: 'please_select_an_option_to_complete_your_task' }) : ''
                }
            />
        </>
    );
}
