import { Box, CardMedia, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from 'react';
import ViewPastRuns from './ViewPastRuns';
import SelectorAutocomplite from 'ui-component/SelectorAutoComplite';
import { Button } from 'ui-component/extended/Button';
import PublicationConceptGeneratorResults from './PublicationConceptGeneratorResults';
import DatePickerAiTool from './DatePickerAiTool';
import DescriptionInputAiTool from './DescriptionInputAiTool';
import { useMutation, useLazyQuery } from '@apollo/client';
import { GENERATE_CONCEPT } from 'services/graphQL/mutations/aiTools/AiToolsMutations';
import { GET_AI_TOOLS_HISTORY, GET_SERVICE_USAGE_LIMITS } from 'services/graphQL/queries/aiTool';
import { GET_CLIENTS } from 'services/graphQL';
import useAuth from 'hooks/useAuth';
import filetex from 'assets/images/filetex.svg';
import lightbulb from 'assets/images/lightbulb.svg';
import Promotion from 'assets/images/Promotion.svg';
import ph_quotes from 'assets/images/ph_quotes.svg';
import users from 'assets/images/users.svg';
import Past_events from 'assets/images/Past_events.svg';
import calendarevents from 'assets/images/calendarevents.svg';
import uploadIcon from 'assets/images/uploadIcon.svg';
import imagepc from 'assets/images/imagepc.svg';
import { useDropzone } from 'react-dropzone';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

// API
import { generateConcepts } from '../../services/rest/ai_tools';

interface PublicationConceptGeneratorProps {
    handleToolsNone: () => void;
}
interface LabelType {
    title: string;
    src?: string;
}
// interface ResultsValueType {
//     description: string;
//     type: string;
// }

interface ResultsValueType {
    id: string;
    name: string;
    description: string;
    score: number;
    links: string[];
    date: string;
}

interface ResultsValueInputType {
    client_organization_id: string;
    brand_type: string;
    period: string;
}
// interface ResultsValueOutputType {
//     description: string;
//     type: string;
// }

interface ResultsValueOutputType {
    id: string;
    name: string;
    description: string;
    score: number;
    links: string[];
    date: string;
}

interface PreviousValueType {
    client_organization_id: string;
    id: string;
    organization_id: string;
    service: number;
    timestamp: string;
    input: ResultsValueInputType;
    output: ResultsValueOutputType[];
}
interface LabelsImages {
    id: string;
    title: string;
    image: string;
    active: boolean;
    description: string;
}

const PublicationConceptGenerator = ({ handleToolsNone }: PublicationConceptGeneratorProps) => {
    const theme = useTheme();
    const { member } = useAuth();
    const intl = useIntl();
    const [pendingValue, setPendingValue] = React.useState<LabelType[]>([]);
    const [selectedClients, setSelectedClients] = React.useState<any[]>([]);
    const [brandDescription, setBrandDescription] = React.useState('');
    const [datePickerValue, setDatePickerValue] = React.useState<Date | null>(new Date());
    const [openDatePicker, setOpenDatePicker] = React.useState(false);
    const [resultsValue, setResultsValue] = React.useState<any[]>([]);
    const [previousValue, setPreviousValue] = React.useState<PreviousValueType[]>([]);
    const [currentUsage, setCurrentUsage] = React.useState(0);
    const [limit, setLimit] = React.useState(0);
    const [credits, setCredits] = React.useState(0);
    const [results, setResults] = React.useState(false);
    const [history, setHistory] = React.useState(false);
    const [errorDesc, setErrorDesc] = React.useState(false);
    const [clientsLabels, setClientsLabels] = React.useState<any>([]);
    const [descriptionValid, setDescriptionValid] = React.useState(false);
    const [additionalInfo, setAdditionalInfo] = React.useState('');
    // this sets the label for each category and provides example description for each category with data format
    const [generationInProgress, setGenerationInProgress] = React.useState(false);
    const [labels, setLabels] = React.useState<any>([
        {
            id: 'information',
            image: filetex,
            title: intl.formatMessage({ id: 'information' }),
            active: false,
            description: [{ title: '', description: '', date: '', match: null }]
        },
        {
            id: 'inspiration',
            image: lightbulb,
            title: intl.formatMessage({ id: 'inspiration' }),
            active: false,
            description: [{ title: '', description: '', date: '', birthday: '', match: null }]
        },
        {
            id: 'promotion',
            image: Promotion,
            title: intl.formatMessage({ id: 'promotion' }),
            active: false,
            description: [{ title: '', description: '', date: '', birthday: '', match: null }]
        },
        {
            id: 'quotes',
            image: ph_quotes,
            title: intl.formatMessage({ id: 'quotes' }),
            active: false,
            description: [{ title: '', description: '', date: '', birthday: '', match: null }]
        }
        // {
        //     id: 'portrait',
        //     image: users,
        //     title: intl.formatMessage({ id: 'portrait' }),
        //     active: false,
        //     description: [{ title: '', description: '', birthday: '', match: null }]
        // },
        // {
        //     id: 'past_events',
        //     image: Past_events,
        //     title: intl.formatMessage({ id: 'past_events' }),
        //     active: false,
        //     description: [{ title: '', description: '', birthday: '', match: null }]
        // },
        // {
        //     id: 'calendar_events',
        //     image: calendarevents,
        //     title: intl.formatMessage({ id: 'calendar_events' }),
        //     active: false,
        //     description: [{ title: '', description: '', birthday: '', match: null }]
        // }
    ]);
    const [errCategories, setErrCategories] = React.useState(false);
    const [image, setImage] = React.useState<any>();

    const { getRootProps, getInputProps, open } = useDropzone({
        multiple: false,
        noClick: true,
        noKeyboard: true,
        onDrop: (acceptedFiles) => {
            // setImage(acceptedFiles[0]);
            setImage(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                )
            );
        }
    });
    // set image to null
    const deleteImage = () => setImage(null);
    // Map through the state of labels, finds the label that matches the title and changes the active state
    const handleCategories = (title: string) => {
        setLabels(
            labels.map((label: LabelsImages) => {
                if (label.title === title) return { ...label, active: !label.active };
                return label;
            })
        );
    };
    // Get the history of AI tools concept generator and sets the state of previousValue and resultsValue to the data returned from the query
    const [fetchConceptGenerator, { loading: loadingFetchConceptGenerator }] = useLazyQuery(GET_AI_TOOLS_HISTORY, {
        onCompleted: (data) => {
            console.log('data', data);
            setPreviousValue(data.ai_tools_history);
            setResultsValue(data.ai_tools_history[0]?.output);
        }
    });
    // Get the usage limits of AI tools and sets the state of currentUsage, limit and credits to the data returned from the query
    const [getServiceUsage] = useLazyQuery(GET_SERVICE_USAGE_LIMITS, {
        onCompleted: (data) => {
            setCurrentUsage(data.ai_tools_get_usage.current);
            setLimit(data.ai_tools_get_usage.client_limit);
            setCredits(data.ai_tools_get_usage.available_credits);
        },
        onError: (error) => {
            console.log(error);
        }
    });
    // Get the clients of the organization and sets the state of clientsLabels to the data returned from the query
    const [getClients, { loading: loadingClients }] = useLazyQuery(GET_CLIENTS, {
        onCompleted: (data) => {
            setClientsLabels(data.client_organizations);
        },
        onError: (error) => {
            console.log(error);
        }
    });
    React.useEffect(() => {
        getClients({ variables: { organizationId: member?.organizationId } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (selectedClients[0]) {
            fetchConceptGenerator({
                variables: {
                    client_organization_id: selectedClients[0].id,
                    service: 'CONCEPT'
                }
            });
            getServiceUsage({
                variables: {
                    client_organization_id: selectedClients[0].id,
                    service: 'CONCEPT'
                }
            });
            const organization = clientsLabels.find((org: any) => org.id === selectedClients[0].id);
            setBrandDescription(organization.ai_brand_description ?? '');
        }
    }, [selectedClients]);

    // function to transform date to a readable format
    function formatDate(dateString: string) {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });
    }

    // Maxime 2023-03-07: Function not used anymore
    // Get concept generator results and sets the state of resultsValue to the data returned from the query
    // const [generateConcept, { loading: loadingGenerateConcept }] = useMutation(GENERATE_CONCEPT, {
    //     onCompleted: (data) => {
    //         console.log('generateConcept > onCompleted > data', data);
    //         setResultsValue(data.ai_tools_generate_concept);
    //         setResults(true);
    //         setCurrentUsage(currentUsage + 1);
    //         if (currentUsage >= limit && credits > 1) {
    //             setCredits(credits - 1);
    //         }
    //         const dataInformation: any = [];
    //         const dataInspiration: any = [];
    //         const dataPromotion: any = [];
    //         const dataQuotes: any = [];
    //         const dataPastEvents: any = [];
    //         const dataCalendarEvents: any = [];
    //         const dataPortrait: any = [];
    //         data.ai_tools_generate_concept.forEach((item: any) => {
    //             if (item.id === 'information') {
    //                 dataInformation.push({
    //                     name: item.name,
    //                     description: item.description,
    //                     date: item.date,
    //                     score: item.score,
    //                     links: item.links
    //                 });
    //             }
    //             if (item.id === 'inspiration') {
    //                 dataInspiration.push({
    //                     name: item.name,
    //                     description: item.description,
    //                     date: item.date,
    //                     score: item.score,
    //                     links: item.links,
    //                     birthday: ''
    //                 });
    //             }
    //             if (item.id === 'promotion') {
    //                 dataPromotion.push({
    //                     name: item.name,
    //                     description: item.description,
    //                     date: item.date,
    //                     score: item.score,
    //                     links: item.links
    //                 });
    //             }
    //             if (item.id === 'quotes' && item.description !== '') {
    //                 dataQuotes.push({
    //                     name: item.name,
    //                     description: item.description,
    //                     date: item.date,
    //                     score: item.score,
    //                     links: item.links
    //                 });
    //             }
    //             if (item.id === 'past_events') {
    //                 dataPastEvents.push({
    //                     name: item.name,
    //                     description: item.description,
    //                     date: item.date,
    //                     score: item.score,
    //                     links: item.links
    //                 });
    //             }
    //             if (item.id === 'calendar_events') {
    //                 dataCalendarEvents.push({
    //                     name: item.name,
    //                     description: item.description,
    //                     date: item.date,
    //                     score: item.score,
    //                     links: item.links
    //                 });
    //             }
    //             if (item.id === 'portrait') {
    //                 dataPortrait.push({
    //                     name: item.name,
    //                     description: item.description,
    //                     date: item.date,
    //                     score: item.score,
    //                     links: item.links
    //                 });
    //             }
    //         });
    //         setLabels([
    //             {
    //                 id: 'information',
    //                 image: filetex,
    //                 title: intl.formatMessage({ id: 'information' }),
    //                 active: dataInformation.length > 0,
    //                 description: [
    //                     ...dataInformation.map((item: any) => ({
    //                         title: '',
    //                         description: item.description,
    //                         date: '',
    //                         match: null
    //                     }))
    //                 ]
    //             },
    //             {
    //                 id: 'quotes',
    //                 image: ph_quotes,
    //                 title: intl.formatMessage({ id: 'quotes' }),
    //                 active: dataQuotes.length > 0,
    //                 description: [
    //                     ...dataQuotes.map((item: any) => ({
    //                         title: item.name,
    //                         // description is item.description with quotes added to the beginning and end
    //                         description: `"${item.description}"`,
    //                         date: '',
    //                         match: null
    //                     }))
    //                 ]
    //             },
    //             {
    //                 id: 'inspiration',
    //                 image: lightbulb,
    //                 title: intl.formatMessage({ id: 'inspiration' }),
    //                 active: dataInspiration.length > 0,
    //                 description: [
    //                     ...dataInspiration.map((item: any) => ({
    //                         title: '',
    //                         description: item.description,
    //                         date: '',
    //                         match: null
    //                     }))
    //                 ]
    //             },
    //             {
    //                 id: 'promotion',
    //                 image: Promotion,
    //                 title: intl.formatMessage({ id: 'promotion' }),
    //                 active: dataPromotion.length > 0,
    //                 description: [
    //                     ...dataPromotion.map((item: any) => ({
    //                         title: '',
    //                         description: item.description,
    //                         date: '',
    //                         match: null
    //                     }))
    //                 ]
    //             },
    //             {
    //                 id: 'past_events',
    //                 image: Past_events,
    //                 title: intl.formatMessage({ id: 'past_events' }),
    //                 active: dataPastEvents.length > 0,
    //                 description: [
    //                     ...dataPastEvents.map((item: any) => ({
    //                         title: item.name,
    //                         description: item.description,
    //                         birthday: formatDate(item.date),
    //                         match: Math.round(item.score * 100)
    //                     }))
    //                 ]
    //             },
    //             {
    //                 id: 'calendar_events',
    //                 image: calendarevents,
    //                 title: intl.formatMessage({ id: 'calendar_events' }),
    //                 active: dataCalendarEvents.length > 0,
    //                 description: [
    //                     ...dataCalendarEvents.map((item: any) => ({
    //                         title: item.name,
    //                         description: item.description,
    //                         birthday: formatDate(item.date),
    //                         match: Math.round(item.score * 100)
    //                     }))
    //                 ]
    //             },
    //             {
    //                 id: 'portrait',
    //                 image: users,
    //                 title: intl.formatMessage({ id: 'portrait' }),
    //                 active: dataPortrait.length > 0,
    //                 description: [
    //                     ...dataPortrait.map((item: any) => ({
    //                         title: item.name,
    //                         description: item.description,
    //                         birthday: formatDate(item.date),
    //                         match: Math.round(item.score * 100)
    //                     }))
    //                 ]
    //             }
    //         ]);
    //     },
    //     onError: (error: any) => {
    //         console.log(error);
    //     }
    // });

    const hanleDatePickerValue = (value: Date) => {
        setDatePickerValue(value);
    };
    const handleCloseResults = () => {
        if (selectedHistory) {
            setSelectedHistory(null);
        } else {
            setResults(false);
            setHistory(false);
        }
    };
    const handleOpenResults = async () => {
        setGenerationInProgress(true);
        const arrTrueCategory: any = [];
        const displayLabel = labels.filter((label: LabelsImages) => label.active).map((label: LabelsImages) => label.title);
        const lowercaseLabel = displayLabel.map((label: string) => label.toLowerCase().replace(/ /g, '_'));
        const date = datePickerValue ? new Date(datePickerValue) : new Date();
        labels.forEach((item: any) => {
            if (item.active) arrTrueCategory.push(item);
        });
        // const variables = {
        //     client_organization_id: selectedClients[0].id,
        //     brand_description: brandDescription,
        //     period: parseInt(`${date.getMonth() + 1}`, 10),
        //     year: parseInt(`${date.getFullYear()}`, 10),
        //     category: lowercaseLabel
        //     // context_string: additionalInfo,
        //     // context_image: ''
        // };
        // console.log('variables: ', variables);
        if (brandDescription.split(' ').length < 51 && brandDescription.length !== 0 && arrTrueCategory.length > 0) {
            setErrorDesc(false);
            setErrCategories(false);
            // generateConcept({
            //     variables
            // });
            const config = {
                client_organization_id: selectedClients[0].id,
                brand_type: brandDescription,
                period: parseInt(`${date.getMonth() + 1}`, 10),
                year: parseInt(`${date.getFullYear()}`, 10),
                categories: lowercaseLabel
            };
            // console.log('config: ', config);
            const { data } = await generateConcepts(config);
            // console.log('data: ', data);
            setGenerationInProgress(false);
            const dataInformation: any = [];
            const dataInspiration: any = [];
            const dataPromotion: any = [];
            const dataQuotes: any = [];
            const dataPastEvents: any = [];
            const dataCalendarEvents: any = [];
            const dataPortrait: any = [];
            const newData = data.map((item: any) => ({
                id: item.category,
                description: item.concept
            }));
            // setResultsValue(data.ai_tools_generate_concept);
            setResultsValue(newData);
            setResults(true);
            setCurrentUsage(currentUsage + 1);
            if (currentUsage >= limit && credits > 1) {
                setCredits(credits - 1);
            }

            // data.ai_tools_generate_concept.forEach((item: any) => {
            newData.forEach((item: any) => {
                if (item.id === 'information') {
                    dataInformation.push({
                        name: item.name,
                        description: item.description,
                        date: item.date,
                        score: item.score,
                        links: item.links
                    });
                }
                if (item.id === 'inspiration') {
                    dataInspiration.push({
                        name: item.name,
                        description: item.description,
                        date: item.date,
                        score: item.score,
                        links: item.links,
                        birthday: ''
                    });
                }
                if (item.id === 'promotion') {
                    dataPromotion.push({
                        name: item.name,
                        description: item.description,
                        date: item.date,
                        score: item.score,
                        links: item.links
                    });
                }
                if (item.id === 'quotes' && item.description !== '') {
                    dataQuotes.push({
                        name: item.name,
                        description: item.description,
                        date: item.date,
                        score: item.score,
                        links: item.links
                    });
                }
                if (item.id === 'past_events') {
                    dataPastEvents.push({
                        name: item.name,
                        description: item.description,
                        date: item.date,
                        score: item.score,
                        links: item.links
                    });
                }
                if (item.id === 'calendar_events') {
                    dataCalendarEvents.push({
                        name: item.name,
                        description: item.description,
                        date: item.date,
                        score: item.score,
                        links: item.links
                    });
                }
                if (item.id === 'portrait') {
                    dataPortrait.push({
                        name: item.name,
                        description: item.description,
                        date: item.date,
                        score: item.score,
                        links: item.links
                    });
                }
            });
            setLabels([
                {
                    id: 'information',
                    image: filetex,
                    title: intl.formatMessage({ id: 'information' }),
                    active: dataInformation.length > 0,
                    description: [
                        ...dataInformation.map((item: any) => ({
                            title: '',
                            description: item.description,
                            date: '',
                            match: null
                        }))
                    ]
                },
                {
                    id: 'quotes',
                    image: ph_quotes,
                    title: intl.formatMessage({ id: 'quotes' }),
                    active: dataQuotes.length > 0,
                    description: [
                        ...dataQuotes.map((item: any) => ({
                            title: item.name,
                            // description is item.description with quotes added to the beginning and end
                            description: `"${item.description}"`,
                            date: '',
                            match: null
                        }))
                    ]
                },
                {
                    id: 'inspiration',
                    image: lightbulb,
                    title: intl.formatMessage({ id: 'inspiration' }),
                    active: dataInspiration.length > 0,
                    description: [
                        ...dataInspiration.map((item: any) => ({
                            title: '',
                            description: item.description,
                            date: '',
                            match: null
                        }))
                    ]
                },
                {
                    id: 'promotion',
                    image: Promotion,
                    title: intl.formatMessage({ id: 'promotion' }),
                    active: dataPromotion.length > 0,
                    description: [
                        ...dataPromotion.map((item: any) => ({
                            title: '',
                            description: item.description,
                            date: '',
                            match: null
                        }))
                    ]
                },
                {
                    id: 'past_events',
                    image: Past_events,
                    title: intl.formatMessage({ id: 'past_events' }),
                    active: dataPastEvents.length > 0,
                    description: [
                        ...dataPastEvents.map((item: any) => ({
                            title: item.name,
                            description: item.description,
                            birthday: formatDate(item.date),
                            match: Math.round(item.score * 100)
                        }))
                    ]
                },
                {
                    id: 'calendar_events',
                    image: calendarevents,
                    title: intl.formatMessage({ id: 'calendar_events' }),
                    active: dataCalendarEvents.length > 0,
                    description: [
                        ...dataCalendarEvents.map((item: any) => ({
                            title: item.name,
                            description: item.description,
                            birthday: formatDate(item.date),
                            match: Math.round(item.score * 100)
                        }))
                    ]
                },
                {
                    id: 'portrait',
                    image: users,
                    title: intl.formatMessage({ id: 'portrait' }),
                    active: dataPortrait.length > 0,
                    description: [
                        ...dataPortrait.map((item: any) => ({
                            title: item.name,
                            description: item.description,
                            birthday: formatDate(item.date),
                            match: Math.round(item.score * 100)
                        }))
                    ]
                }
            ]);
        } else {
            setErrorDesc(true);
            if (arrTrueCategory.length === 0) setErrCategories(true);
        }
    };
    const handleHistory = () => {
        fetchConceptGenerator({
            variables: {
                client_organization_id: selectedClients[0].id,
                service: 'CONCEPT'
            },
            fetchPolicy: 'network-only'
        }).then(() => {
            setResults(true);
            setHistory(true);
        });
    };
    const handleOpenkDatePicker = () => setOpenDatePicker(true);
    const handleCloseDatePicker = () => {
        console.log('datePickerValue', datePickerValue);
        setOpenDatePicker(false);
    };

    const [selectedHistory, setSelectedHistory] = React.useState<any>();
    const handleSelectedHistory = (index: number) => {
        setSelectedHistory(labelsPrevious[index]);
    };
    const labelsPrevious = [
        {
            id: 'information',
            description: 'description',
            categories: ['information', 'inspiration', 'promotion', 'quotes', 'portrait', 'past_events', 'calendar_events'],
            date: '2022-11-04T13:23:12.655Z'
        },
        {
            id: 'inspiration',
            description: 'description',
            categories: ['information', 'inspiration'],
            date: '2022-11-04T13:23:12.655Z'
        }
    ];
    return (
        <Box sx={{ overflowY: 'auto', maxHeight: { xs: 'calc(100vh - 200px)', sm: 'calc(100vh - 78px)' } }}>
            {/* {loadingGenerateConcept ? ( */}
            {generationInProgress ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: { xs: 'calc(100vh - 200px)', sm: 'calc(100vh - 78px)' }
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (
                <Box>
                    {results ? (
                        <PublicationConceptGeneratorResults
                            handleResults={handleCloseResults}
                            history={history}
                            resultsValue={resultsValue}
                            previousValue={previousValue}
                            labels={labels}
                            labelsPrevious={labelsPrevious}
                            handleSelectedHistory={handleSelectedHistory}
                            selectedHistory={selectedHistory}
                        />
                    ) : (
                        <Box>
                            <Box onClick={handleToolsNone} sx={{ display: 'flex', alignItems: 'center', p: '24px', cursor: 'pointer' }}>
                                <ArrowBackIcon sx={{ fontSize: '26px', fill: theme.palette.success[300] }} />
                                <Typography
                                    ml={1}
                                    sx={{ color: theme.palette.success[300], fontFamily: 'Inter', fontWeight: 500, fontSize: '14px' }}
                                >
                                    <FormattedMessage id="publication_concept_generator" />
                                </Typography>
                            </Box>
                            {/* {selectedClients.length > 0 ? (
                                <ViewPastRuns
                                    size={currentUsage}
                                    handleHistory={handleHistory}
                                    limit={limit}
                                    credits={credits}
                                    loading={loadingFetchConceptGenerator}
                                />
                            ) : null} */}
                            <Box sx={{ p: '10px 24px 24px 24px' }}>
                                {loadingClients ? (
                                    <CircularProgress />
                                ) : (
                                    <SelectorAutocomplite
                                        autocompleteItems={clientsLabels}
                                        pendingValue={pendingValue}
                                        setPendingValue={setPendingValue}
                                        arrItemSelected={selectedClients}
                                        setArrItemsSelected={setSelectedClients}
                                        title="client"
                                        imagePath="logo_url"
                                        labelPath="name"
                                        singleSelect
                                    />
                                )}
                            </Box>
                            {selectedClients.length > 0 ? (
                                <Box>
                                    <Box sx={{ p: '10px 24px 24px 24px' }}>
                                        <DescriptionInputAiTool
                                            value={brandDescription}
                                            setValue={setBrandDescription}
                                            placholder="write_a_brief_description_of_the_brand"
                                            totalWordsLength={50}
                                            error={errorDesc}
                                            title="brand_description"
                                            setValid={setDescriptionValid}
                                            rows={2}
                                        />
                                    </Box>
                                    <Box sx={{ p: '10px 24px 24px 24px' }}>
                                        <DatePickerAiTool
                                            date={datePickerValue}
                                            handleDate={hanleDatePickerValue}
                                            handleOpen={handleOpenkDatePicker}
                                            open={openDatePicker}
                                            handleClose={handleCloseDatePicker}
                                            title="date"
                                        />
                                    </Box>
                                    <Box sx={{ p: '10px 24px 24px 24px' }}>
                                        <Typography
                                            mb={2}
                                            sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600, fontSize: '14px' }}
                                        >
                                            <FormattedMessage id="concept_categories" />
                                        </Typography>
                                        <Grid container spacing={2}>
                                            {labels.map((label: LabelsImages) => (
                                                <Grid item xs={6}>
                                                    <Box
                                                        onClick={() => handleCategories(label.title)}
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            p: label.active ? '12px 17px 12px' : '12px 17px 14px',
                                                            borderRadius: '8px',
                                                            border: label.active
                                                                ? `2px solid ${theme.palette.orange.main}`
                                                                : `1px solid ${theme.palette.grey[500]}`,
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <Box sx={{ width: '24px', mr: 2 }}>
                                                            <CardMedia
                                                                sx={{ objectFit: 'contain' }}
                                                                component="img"
                                                                image={label.image}
                                                                alt="AI tools"
                                                            />
                                                        </Box>
                                                        <Typography
                                                            sx={{
                                                                color: theme.palette.grey[400],
                                                                fontFamily: 'Inter',
                                                                fontWeight: 500,
                                                                fontSize: '14px'
                                                            }}
                                                        >
                                                            {label.title}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            ))}
                                        </Grid>
                                        {errCategories && (
                                            <Typography
                                                mb={2}
                                                sx={{
                                                    color: '#c62828',
                                                    fontFamily: 'Inter',
                                                    fontWeight: 400,
                                                    fontSize: '12px',
                                                    mt: 1
                                                }}
                                            >
                                                <FormattedMessage id="please_select_a_category" />
                                            </Typography>
                                        )}
                                    </Box>
                                    {/* <Box sx={{ p: '10px 24px 24px 24px' }}>
                                        <Typography
                                            sx={{
                                                color: theme.palette.grey[400],
                                                fontFamily: 'Inter',
                                                fontWeight: 600,
                                                fontSize: '14px',
                                                mb: 2
                                            }}
                                        >
                                            <FormattedMessage id="image_to_create_concepts_on" />
                                        </Typography>
                                        {image ? (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    p: '14px',
                                                    borderRadius: '8px',
                                                    border: `1px solid ${theme.palette.grey[500]}`,
                                                    justifyContent: 'space-between'
                                                }}
                                            >
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Box mr={2} sx={{ width: '20px', height: '20px' }}>
                                                        <CardMedia component="img" width="20px" image={imagepc} alt="alt image" />
                                                    </Box>
                                                    <Typography
                                                        sx={{
                                                            color: theme.palette.grey[400],
                                                            fontFamily: 'Inter',
                                                            fontWeight: 500,
                                                            fontSize: '14px'
                                                        }}
                                                    >
                                                        {image.name}
                                                    </Typography>
                                                </Box>
                                                <DeleteOutlineOutlinedIcon
                                                    onClick={deleteImage}
                                                    sx={{ fill: theme.palette.orange.main, cursor: 'pointer' }}
                                                />
                                            </Box>
                                        ) : (
                                            <div {...getRootProps({ className: 'dropzon dropzone' })}>
                                                <input {...getInputProps()} />
                                                <Box
                                                    p={4}
                                                    sx={{
                                                        border: `1px dotted ${theme.palette.grey[500]}`,
                                                        borderRadius: '8px',
                                                        background: '#fafafa'
                                                    }}
                                                >
                                                    <Box mr={1} sx={{ width: '24px', height: '24px', m: '0 auto' }}>
                                                        <CardMedia component="img" width="24px" image={uploadIcon} alt="alt image" />
                                                    </Box>
                                                    <Box sx={{ display: 'flex', alignItem: 'center', justifyContent: 'center', mt: 2 }}>
                                                        <Typography
                                                            sx={{
                                                                color: theme.palette.grey[400],
                                                                fontFamily: 'Inter',
                                                                fontWeight: 500,
                                                                fontSize: '14px',
                                                                mt: '4px'
                                                            }}
                                                        >
                                                            <FormattedMessage id="drag_and_drop_an_image_here_or" />
                                                        </Typography>
                                                        <Typography
                                                            onClick={open}
                                                            sx={{
                                                                color: theme.palette.orange.main,
                                                                fontFamily: 'Inter',
                                                                fontWeight: 500,
                                                                fontSize: '14px',
                                                                mt: '4px',
                                                                ml: '8px',
                                                                cursor: 'pointer'
                                                            }}
                                                        >
                                                            <FormattedMessage id="browse" />
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </div>
                                        )}
                                    </Box> */}
                                    {/* <Box sx={{ p: '10px 24px 24px 24px' }}>
                                        <Typography
                                            sx={{
                                                color: theme.palette.grey[400],
                                                fontFamily: 'Inter',
                                                fontWeight: 600,
                                                fontSize: '14px',
                                                mb: 2
                                            }}
                                        >
                                            <FormattedMessage id="additional_info_to_create_concepts_on" />
                                        </Typography>
                                        <TextField
                                            sx={{
                                                '.MuiOutlinedInput-input': {
                                                    fontWeight: 400,
                                                    fontFamily: 'Inter',
                                                    fontSize: '14px',
                                                    background: '#fff',
                                                    '&:disabled': {
                                                        background: '#EAECF0'
                                                    }
                                                }
                                            }}
                                            disabled={image}
                                            value={additionalInfo}
                                            onChange={(e) => {
                                                setAdditionalInfo(e.target.value);
                                            }}
                                            variant="outlined"
                                            fullWidth
                                            placeholder={intl.formatMessage({
                                                id: 'add_the_topic_on_which_you_wish_to_generate_publication_ideas'
                                            })}
                                        />
                                        <Typography
                                            sx={{
                                                color: theme.palette.grey[300],
                                                fontFamily: 'Inter',
                                                fontWeight: 500,
                                                fontSize: '12px',
                                                mt: 1
                                            }}
                                        >
                                            <FormattedMessage id="you_cant_add_an_additional_info_if_an_image_is_uploaded" />
                                        </Typography>
                                    </Box> */}
                                    <Box sx={{ p: '0 24px 24px 24px', width: '310px' }}>
                                        <Button
                                            onClick={handleOpenResults}
                                            disabled={(currentUsage >= limit && credits < 1) || !descriptionValid}
                                            loading={generationInProgress}
                                            title={
                                                <Typography sx={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '14px' }}>
                                                    <FormattedMessage id="generate_publication_concepts" />
                                                </Typography>
                                            }
                                        />
                                    </Box>
                                </Box>
                            ) : null}
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default PublicationConceptGenerator;
