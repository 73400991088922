import { Box, Typography, Grid, CardMedia, Dialog } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import { useDropzone } from 'react-dropzone';
import React from 'react';
import DIalogImage from 'views/pages/tasks/DIalogImage';
import clip from 'assets/images/clip.svg';
import ph_trash from 'assets/images/ph_trash.svg';
import { useMutation } from '@apollo/client';
import { GENERATE_ASSET_URL } from 'services/graphQL/mutations/assets/generateUrl';
import { uploadFile } from 'services/rest';
import { FileCard } from './FileCard';

interface DragAndDropFilesProps {
    files: { url: string }[];
    setFiles: (files: { url: string }[]) => void;
    basePath: string;
    disabled?: boolean;
}

export default function DragAndDropFilesV2({ files, setFiles, basePath, disabled = false }: DragAndDropFilesProps) {
    const theme = useTheme();
    const [dialogImage, setDialogImage] = React.useState(false);
    const [imageView, setImageView] = React.useState('');
    const [generateURL] = useMutation(GENERATE_ASSET_URL);

    const handleOnDrop = async (acceptedFiles: File[]) => {
        for (let index = 0; index < acceptedFiles.length; index++) {
            const file = acceptedFiles[index];
            const {
                data: {
                    generate_upload_url: { url }
                }
                // eslint-disable-next-line no-await-in-loop
            } = await generateURL({
                variables: { path: `${basePath}/${Date.now()}-${file.name}` }
            });

            Object.assign(file, { uploadLink: url });
        }

        const promises = acceptedFiles.map((file) => uploadFile((file as any).uploadLink, file));

        await Promise.all(promises);

        const newLinks = acceptedFiles.map((file) => {
            const url = (file as any).uploadLink.substring(0, (file as any).uploadLink.indexOf('?'));
            return { url };
        });

        setFiles([...(files ?? []), ...newLinks]);
    };

    const { getRootProps, getInputProps, open } = useDropzone({
        noClick: true,
        noKeyboard: true,
        onDrop: handleOnDrop
    });

    const handleDeleteImage = (url: string) => {
        // todo remove file from server
        const newArr: any = [];
        files.forEach((element: any) => {
            if (element.url !== url) newArr.push(element);
        });
        setFiles([...newArr]);
    };

    const handleOpenDialogImage = (url: string) => {
        setImageView(url);
        setDialogImage(true);
    };
    const handleCloseDialogImage = () => {
        setDialogImage(false);
    };

    return (
        <Box>
            {!disabled && (
                <div {...getRootProps({ className: 'dropzon dropzone' })}>
                    <input {...getInputProps()} />
                    <Box sx={{ display: 'flex', alignItem: 'center' }}>
                        <Box mr={1} sx={{ width: '24px', height: '24px' }}>
                            <CardMedia sx={{ objectFit: 'contain' }} component="img" width="18px" image={clip} alt="alt image" />
                        </Box>
                        <Typography
                            sx={{
                                color: theme.palette.grey[400],
                                fontFamily: 'Inter',
                                fontWeight: 400,
                                fontSize: '14px',
                                fontStyle: 'italic',
                                mt: '4px'
                            }}
                        >
                            <FormattedMessage id="drag_and_drop_files_to_attach_or" />
                        </Typography>
                        <Typography
                            onClick={open}
                            sx={{
                                color: theme.palette.grey[400],
                                fontFamily: 'Inter',
                                fontWeight: 700,
                                fontSize: '14px',
                                textDecoration: 'underline',
                                mt: '4px',
                                ml: '8px',
                                cursor: 'pointer'
                            }}
                        >
                            <FormattedMessage id="browse" />
                        </Typography>
                    </Box>
                </div>
            )}
            <Grid container spacing={3} mt={2}>
                {files &&
                    files.map((file) => (
                        <Grid item xs={6} sm={4} key={file.url}>
                            <Box sx={{ position: 'relative' }}>
                                {/* <CardMedia
                                    sx={{ objectFit: 'cover', borderRadius: '5px', cursor: 'pointer' }}
                                    component="img"
                                    onClick={() => handleOpenDialogImage(file.url)}
                                    width="100%"
                                    height="100%"
                                    image={file.url}
                                    alt="alt image"
                                /> */}
                                <FileCard fileUrl={file.url} handleOpenDialog={() => handleOpenDialogImage(file.url)} />
                                <Box
                                    onClick={() => handleDeleteImage(file.url)}
                                    sx={{
                                        position: 'absolute',
                                        bottom: '10px',
                                        right: '10px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <CardMedia
                                        sx={{ objectFit: 'cover', borderRadius: '5px', cursor: 'pointer' }}
                                        component="img"
                                        width="100%"
                                        height="100%"
                                        image={ph_trash}
                                        alt="alt image"
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    ))}
            </Grid>
            <Dialog sx={{ '.MuiDialog-paper': { p: 0 } }} onClose={handleCloseDialogImage} open={dialogImage}>
                <DIalogImage handleClose={handleCloseDialogImage} image={imageView} />
            </Dialog>
        </Box>
    );
}
