import { styled, Box, Button, TextareaAutosize, TextField } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { background } from '@storybook/theming';

export const ColumnHeader = styled(Box)(() => ({
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 20px',
    borderLeft: '1px solid #D4DBEA'
}));

export const ColumnBody = styled(Box)(() => ({
    borderTop: '1px solid #D4DBEA',
    borderLeft: '1px solid #D4DBEA',
    // height: '100%',
    // height: 'calc(100vh - 84px - 16px - 56px - 10px)',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
        display: 'none'
    }
}));

export const QualityButton = styled(Button)(() => ({
    width: '100%',
    backgroundColor: '#D4DBEA',
    justifyContent: 'left',
    color: 'black',
    borderRadius: '0px',
    height: '50px',
    borderBottom: '1px solid'
}));
export const ColumnFooter = styled(Box)(() => ({
    borderTop: '1px solid #D4DBEA',
    borderLeft: '1px solid #D4DBEA',
    overflowY: 'auto',
    paddingTop: '50px',
    textAlign: 'center'
}));

export const ColumnBodyOne = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
}));

export const RowBody = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center'
}));

export const FlowNode = styled(Box)(() => ({
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px',
    boxShadow: '0px 10px 16px rgba(0, 0, 0, 0.1)',
    backgroundColor: 'white',
    border: '1px solid #E0E0E0',
    padding: '24px 16px',
    gap: '24px'
}));

export const NextButton = styled(Button)(({ theme }) => ({
    fontSize: '12px',
    color: 'white',
    borderRadius: '10px',
    background: theme.palette.orange.main,
    '&:hover': {
        background: theme.palette.orange.main
    },
    height: '36px',
    minHeight: '36px'
}));

export const BackButton = styled(Button)(({ theme }) => ({
    fontSize: '12px',
    color: 'black',

    height: '36px',
    minHeight: '36px'
}));

export const TwoColumnBox = styled(Box)(() => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '16px'
}));

export const AddSectionButton = styled(Button)(() => ({
    marginTop: '30px',
    fontSize: '14px',
    color: '#393D4E',
    border: '2px solid #D4DBEA',
    minHeight: '42px',
    borderRadius: '21px',
    padding: '0 20px'
}));

export const SectionPaper = styled(Box)(() => ({
    width: '100%',
    padding: '16px',
    border: '1px solid #D4DBEA',
    boxShadow: '0px 10px 16px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px'
}));
export const Textarea = styled(TextareaAutosize)(({ theme }) => ({
    width: '100%',
    border: `1px solid #D4DBEA`,
    borderRadius: 8,
    padding: '16px',
    fontFamily: theme.typography.fontFamily,
    resize: 'none',
    '&::placeholder': {
        color: '#D4DBEA',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 400
    },
    ':hover': {
        borderColor: theme.palette.primary.main,
        borderWidth: 1
    },
    ':focus': {
        borderColor: theme.palette.primary.main,
        borderWidth: 1,
        outline: 'none !important'
    }
}));

export const CustomTextField = styled(TextField)(({ theme }) => ({
    '.MuiOutlinedInput-input': {
        color: theme.palette.grey[300],
        fontWeight: 400,
        fontFamily: 'Inter',
        fontSize: '14px'
    }
}));

export const UploadButton = styled(Button)(({ theme }) => ({
    display: 'flex',
    borderWidth: '2px',
    height: '48px',
    minWidth: '162px',
    fontSize: '16px',
    borderRadius: '10px',
    alignItems: 'center',
    gap: '8px',
    'svg path': { stroke: theme.palette.orange.main }
}));

export const CreateNewButton = styled(Button)(({ theme }) => ({
    width: '10rem',
    height: '10rem',
    border: '1px solid gray',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
}));

export const BoxNavbar = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px'
}));

export const BoxTemplate = styled(Button)(({ theme }) => ({
    width: '200px',
    height: '120px',
    marginLeft: '1rem',
    border: '1px solid',
    padding: '15px',
    position: 'relative'
}));

export const BoxRect = styled(Box)(({ theme }) => ({
    height: '30px',
    position: 'absolute',
    backgroundColor: '#FFE8E5',
    width: '198px',
    top: '88px'
}));

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3)
    },
    '& .MuiInputBase-input': {
        position: 'relative',
        // fontFamily:'Inter',
        borderRadius: 16,
        backgroundColor: '#FEDF89',
        fontSize: 12,
        fontWeight: '500',
        fontStyle: 'normal',
        padding: '9px 30px 9px 12px',
        lineHeight: '120%',
        color: '#93370D',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(','),
        '&:focus': {
            borderRadius: 16,
            backgroundColor: '#FEDF89 !important',
            fontSize: 12,
            fontWeight: '500',
            padding: '9px 30px 9px 12px',
            fontStyle: 'normal',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
        }
    }
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    color: 'black',
    [`&.${tableCellClasses.head}`]: {
        color: 'black',
        border: '1px solid #c1cde5',
        backgroundColor: '#e0e4ed'
    },
    [`&.${tableCellClasses.body}`]: {
        border: '1px solid #D4DBEA',
        fontSize: 14
    }
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: '1px solid #D4DBEA'
    }
}));

export default null;
