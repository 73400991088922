import { createContext, FC, useContext, useState } from 'react';
import { ContextRole } from '../../../types/role';

interface RoleContextProps {
    role: ContextRole;
    updateRole: (newRole: string) => void;
}

const RoleContext = createContext({} as RoleContextProps);

export const RoleProvider: FC<{ fetchedRole: ContextRole }> = ({ children, fetchedRole }) => {
    const [role, setRole] = useState(fetchedRole);

    return (
        <RoleContext.Provider
            value={{
                role,
                updateRole: setRole
            }}
        >
            {children}
        </RoleContext.Provider>
    );
};

export const useRole = () => useContext(RoleContext);
