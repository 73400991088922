import { ChannelList } from 'stream-chat-react';

import { ChannelSearch } from '../ChannelSearch/ChannelSearch';
import { TeamChannelList } from '../TeamChannelList/TeamChannelList';
import { ChannelPreview } from '../ChannelPreview/ChannelPreview';
import useAuth from 'hooks/useAuth';
import type { Channel } from 'stream-chat';
import { ChannelSort } from 'stream-chat';

import { StreamChatType } from '../../types';

const options = { state: true, watch: true, presence: true };
const sort: ChannelSort<StreamChatType> = { last_message_at: -1, updated_at: -1 };

const customChannelTeamFilter = (channels: Channel[]) => channels.filter((channel) => channel.type === 'team');

const customChannelMessagingFilter = (channels: Channel[]) => channels.filter((channel) => channel.type === 'messaging');

const TeamChannelsList = ({ userId }: { userId: string }) => (
    <ChannelList
        channelRenderFilterFn={customChannelTeamFilter}
        filters={{ members: { $in: [userId] } }}
        options={options}
        sort={sort}
        List={(listProps) => <TeamChannelList {...listProps} type="team" />}
        Preview={(previewProps) => <ChannelPreview {...previewProps} type="team" />}
    />
);

const MessagingChannelsList = ({ userId }: { userId: string }) => (
    <ChannelList
        channelRenderFilterFn={customChannelMessagingFilter}
        filters={{ type: 'messaging', members: { $in: [userId] } }}
        options={options}
        sort={sort}
        setActiveChannelOnMount={false}
        List={(listProps) => <TeamChannelList {...listProps} type="messaging" />}
        Preview={(previewProps) => <ChannelPreview {...previewProps} type="messaging" />}
    />
);

export const SidebarSide = () => {
    const { user } = useAuth();
    const userId = user?.id;
    return (
        <div className="sidebar-side1">
            <div className="sidebar-side1_Box">
                <div className="sidebar-side1_Box_Paragraph">
                    {/* Client Chats <p>730</p> */}
                    Messages
                </div>
            </div>
            <ChannelSearch />
            {userId && <TeamChannelsList userId={userId} />}
            {userId && <MessagingChannelsList userId={userId} />}
        </div>
    );
};
