import { Button as MuiButton, CircularProgress, SvgIcon, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

interface ButtonCustomProps {
    titleBtn: any;
    colorBtn: 'red' | 'white';
    loading?: boolean;
    onClick?: any;
    type?: string;
    sx?: { [key: string]: any };
}

export default function ButtonCustom({ colorBtn, titleBtn, loading, onClick, type, sx = {} }: ButtonCustomProps) {
    const theme = useTheme();
    return (
        <MuiButton
            onClick={onClick}
            sx={{
                letterSpacing: '0.46px',
                fontSize: { xs: '14px', md: '14px' },
                borderRadius: { xs: '4px', md: '10px' },
                padding: { xs: '13px', md: '16px' },
                background: colorBtn === 'red' ? theme.palette.orange.main : theme.palette.background.paper,
                width: '100%',
                color: colorBtn === 'red' ? theme.palette.background.paper : theme.palette.orange.main,
                border: colorBtn === 'red' ? 'none' : `2px solid ${theme.palette.orange.main}`,
                fontFamily: 'Inter',
                fontWeight: 500,
                '&:hover': {
                    background: colorBtn === 'red' ? theme.palette.orange.main : theme.palette.background.paper
                },
                height: '42px',
                minHeight: '42px',
                lineHeight: '16px',
                minWidth: '0px',
                ...sx
            }}
        >
            {loading ? <CircularProgress /> : titleBtn}
        </MuiButton>
    );
}

const ButtonText = styled(Typography)(({ theme }) => ({
    color: theme.palette.background.paper,
    fontWeight: 500,
    fontFamily: 'Inter',
    fontSize: 20,
    lineHeight: '20px'
}));

interface ButtonProps {
    sx?: { [key: string]: any };
    variant?: 'primary' | 'secondary';
    onClick: any;
    icon?: React.ReactNode;
    prefix?: string;
    title: string | React.ReactNode;
    loading?: boolean;
    disabled?: boolean;
}
export function Button({ sx = {}, variant = 'primary', title, icon, prefix, onClick, loading = false, disabled }: ButtonProps) {
    const theme = useTheme();
    const foreColor = variant === 'primary' ? theme.palette.background.paper : theme.palette.orange.main;
    const backColor = variant === 'primary' ? theme.palette.orange.main : theme.palette.background.paper;

    return (
        <MuiButton
            onClick={onClick}
            disabled={disabled}
            sx={{
                letterSpacing: '0.46px',
                borderRadius: { xs: '4px', md: '10px' },
                padding: '8px 16px',
                background: backColor,
                width: '100%',
                color: foreColor,
                textTransform: 'none',
                border: `2px solid ${theme.palette.orange.main}`,
                fontFamily: 'Inter',
                fontWeight: 500,
                height: '42px',
                minHeight: '42px',
                lineHeight: '24px',
                display: 'flex',
                alignItems: 'center',
                pointerEvents: loading ? 'none' : 'auto',
                gap: { xs: 1, sm: 0.75, md: 0.5 },
                '&:hover': {
                    background: backColor
                },
                '&:disabled': {
                    background: theme.palette.orange[300],
                    borderColor: theme.palette.orange[300]
                },
                ...sx
            }}
        >
            {!!icon && <SvgIcon sx={{ fontSize: 20, color: foreColor }}>{icon}</SvgIcon>}
            {!!prefix && <ButtonText sx={{ color: foreColor }}>{prefix}</ButtonText>}
            {!loading && <ButtonText sx={{ fontSize: 14, color: foreColor }}>{title}</ButtonText>}
            {loading && <CircularProgress size={20} sx={{ color: 'white' }} />}
        </MuiButton>
    );
}
