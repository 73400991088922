import { Box, Drawer, Typography, Grid, TextField } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import { Button } from 'ui-component/extended/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import 'react-quill/dist/quill.snow.css';
import { useMutation } from '@apollo/client';
import { EDIT_CLIENT } from 'services/graphQL/mutations';
import { useDispatch, useSelector } from 'store';
import { setCurrentClient } from 'store/slices/clients';
import { openSnackbar } from 'store/slices/snackbar';

interface EditClientModalProps {
    show: boolean;
    onClose: () => void;
    client?: any;
}

export const EditClientModal = ({ show, onClose, client }: EditClientModalProps) => {
    const theme = useTheme();
    const intl = useIntl();

    const dispatch = useDispatch();
    const { currentClient } = useSelector((state) => state.clients);
    const [editClient, { loading }] = useMutation(EDIT_CLIENT, {
        onCompleted: (data: any) => {
            const { update_client_organizations_by_pk: org, update_users_by_pk: user } = data;
            const updateCurrentClient = {
                ...currentClient,
                name: { title: org.name, src: org.logo_url },
                description: org.description || '',
                contactName: `${user.first_name}`,
                phoneNumber: user.phone
            };
            dispatch(setCurrentClient(updateCurrentClient));
            onClose();
        },
        onError: (e: any) => {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e.message,
                    variant: 'error'
                })
            );
        }
    });

    const formik = useFormik({
        initialValues: {
            brandName: '',
            contactName: '',
            phoneNumber: '',
            email: '',
            description: ''
        },
        validationSchema: Yup.object({
            brandName: Yup.string().required('Brand name is required'),
            contactName: Yup.string().required('Contact name is Required'),
            phoneNumber: Yup.string().required('Phone number is Required'),
            description: Yup.string().required('Description number is Required'),
            email: Yup.string().email('Invalid email address').required('Email is required')
        }),
        onSubmit: (values) => {
            editClient({
                variables: {
                    clientId: client.id,
                    brand_name: values.brandName,
                    first_name: values.contactName,
                    phone_number: values.phoneNumber,
                    email: values.email,
                    description: values.description,
                    contact_id: client.userId
                }
            });
        }
    });

    React.useEffect(() => {
        if (client) {
            formik.setFieldValue('brandName', client?.name.title, false);
            formik.setFieldValue('contactName', client?.contactName, false);
            formik.setFieldValue('phoneNumber', client?.phoneNumber, false);
            formik.setFieldValue('email', client?.email, false);
            formik.setFieldValue('description', client?.description, false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client]);

    return (
        <Drawer anchor="right" open={show} onClose={onClose}>
            <Box sx={{ width: { xs: '100%', sm: '562px' } }}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container direction="column" justifyContent="space-between" sx={{ minHeight: { xs: '100%', xl: '100vh' } }}>
                        <Grid item sx={{ p: '26px 32px' }}>
                            <Typography
                                mb={3}
                                sx={{ fontWeight: 600, fontFamily: 'Inter', color: theme.palette.common.black, fontSize: '16px' }}
                            >
                                <FormattedMessage id="edit_client" />
                            </Typography>
                            <Typography
                                mb={1}
                                sx={{ fontWeight: 500, fontFamily: 'Inter', color: theme.palette.grey[400], fontSize: '14px' }}
                            >
                                <FormattedMessage id="brand_name" />
                            </Typography>
                            <TextField
                                sx={{
                                    '.MuiOutlinedInput-input': {
                                        fontWeight: 400,
                                        fontFamily: 'Inter',
                                        fontSize: '14px'
                                    }
                                }}
                                id="brandName"
                                name="brandName"
                                value={formik.values.brandName}
                                onChange={formik.handleChange}
                                variant="outlined"
                                fullWidth
                                placeholder={intl.formatMessage({ id: 'brand_name' })}
                                error={formik.touched.brandName && Boolean(formik.errors.brandName)}
                                helperText={formik.touched.brandName && formik.errors.brandName}
                            />
                            <Typography
                                sx={{
                                    fontWeight: 500,
                                    fontFamily: 'Inter',
                                    color: theme.palette.grey[400],
                                    fontSize: '14px',
                                    mt: 3,
                                    mb: 1
                                }}
                            >
                                <FormattedMessage id="email" />
                            </Typography>
                            <TextField
                                sx={{
                                    '.MuiOutlinedInput-input': {
                                        fontWeight: 400,
                                        fontFamily: 'Inter',
                                        fontSize: '14px'
                                    }
                                }}
                                id="email"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                variant="outlined"
                                fullWidth
                                placeholder={intl.formatMessage({ id: 'email' })}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                disabled
                            />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontFamily: 'Inter',
                                            color: theme.palette.grey[400],
                                            fontSize: '14px',
                                            mt: 3,
                                            mb: 1
                                        }}
                                    >
                                        <FormattedMessage id="contact_point_name" />
                                    </Typography>
                                    <TextField
                                        sx={{
                                            '.MuiOutlinedInput-input': {
                                                fontWeight: 400,
                                                fontFamily: 'Inter',
                                                fontSize: '14px'
                                            }
                                        }}
                                        id="contactName"
                                        name="contactName"
                                        value={formik.values.contactName}
                                        onChange={formik.handleChange}
                                        variant="outlined"
                                        fullWidth
                                        placeholder={intl.formatMessage({ id: 'contact_point_name' })}
                                        error={formik.touched.contactName && Boolean(formik.errors.contactName)}
                                        helperText={formik.touched.contactName && formik.errors.contactName}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontFamily: 'Inter',
                                            color: theme.palette.grey[400],
                                            fontSize: '14px',
                                            mt: 3,
                                            mb: 1
                                        }}
                                    >
                                        <FormattedMessage id="phone_number" />
                                    </Typography>
                                    <TextField
                                        sx={{
                                            '.MuiOutlinedInput-input': {
                                                fontWeight: 400,
                                                fontFamily: 'Inter',
                                                fontSize: '14px'
                                            }
                                        }}
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        value={formik.values.phoneNumber}
                                        onChange={formik.handleChange}
                                        variant="outlined"
                                        fullWidth
                                        placeholder="+44"
                                        error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                        helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{ mt: 3 }}>
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        fontFamily: 'Inter',
                                        color: theme.palette.grey[400],
                                        fontSize: '14px',
                                        mb: 1
                                    }}
                                >
                                    <FormattedMessage id="brand_description" />
                                </Typography>
                                <TextField
                                    sx={{
                                        '.MuiOutlinedInput-input': {
                                            fontWeight: 400,
                                            fontFamily: 'Inter',
                                            fontSize: '14px'
                                        }
                                    }}
                                    id="description"
                                    name="description"
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    maxRows={6}
                                    minRows={6}
                                    placeholder="Brand description"
                                    error={formik.touched.description && Boolean(formik.errors.description)}
                                    helperText={formik.touched.description && formik.errors.description}
                                />
                            </Box>
                        </Grid>

                        <Grid
                            item
                            container
                            direction="column"
                            justifyContent="flex-end"
                            alignItems="flex-end"
                            sx={{ borderTop: `1px solid ${theme.palette.grey[500]}`, p: '32px' }}
                        >
                            <Grid item>
                                <Box sx={{ display: 'flex' }}>
                                    <Box mr={2} sx={{ width: '87px' }}>
                                        <Button title={<FormattedMessage id="cancel" />} onClick={onClose} variant="secondary" />
                                    </Box>
                                    <Box sx={{ width: '144px' }}>
                                        <Button
                                            title={<FormattedMessage id="save_changes" />}
                                            onClick={formik.handleSubmit}
                                            loading={loading}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Drawer>
    );
};
