import { Box, Typography, CardMedia, Dialog, TextField, Select, MenuItem, Grid } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import fileplus from 'assets/images/fileplus.svg';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDropzone } from 'react-dropzone';
import { Button } from 'ui-component/extended/Button';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

interface DIalogAddPortfolioProps {
    openDialog: boolean;
    onClose: any;
    selectedPortfolio?: any;
}

const DIalogAddPortfolio = ({ openDialog, onClose, selectedPortfolio }: DIalogAddPortfolioProps) => {
    const theme = useTheme();
    const intl = useIntl();
    const formik = useFormik({
        initialValues: {
            industry: '',
            descriptionTask: '',
            volume: '',
            skill: 'Caption Writing'
        },
        validationSchema: Yup.object({
            industry: Yup.string().required('Required'),
            descriptionTask: Yup.string().required('Required'),
            volume: Yup.string().required('Required'),
            skill: Yup.string().required('Required')
        }),
        onSubmit: (values) => {
            console.log(values);
        }
    });
    const [files, setFiles] = useState<any>([]);
    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop: (acceptedFiles) => {
            if (files.length < 4) {
                const arrFiles = files;
                acceptedFiles.forEach((image: any) => {
                    arrFiles.push(image);
                });
                setFiles(
                    arrFiles.map((file: any) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file)
                        })
                    )
                );
            }
        }
    });
    const deleteImage = (imageUrl: string) => {
        setFiles(files.filter((image: any) => image.preview !== imageUrl));
    };
    React.useEffect(() => {
        if (!openDialog) return;

        if (selectedPortfolio) {
            formik.setFieldValue('skill', selectedPortfolio?.skil);
            formik.setFieldValue('descriptionTask', selectedPortfolio?.description);
            formik.setFieldValue('industry', selectedPortfolio?.finance);
            setFiles(selectedPortfolio?.images);
        } else {
            formik.setFieldValue('skill', 'Caption Writing');
            formik.setFieldValue('descriptionTask', '');
            formik.setFieldValue('industry', '');
            setFiles([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPortfolio, openDialog]);
    const itmesSelect = ['Caption Writing', 'Copywriting', 'Designing', 'Content Writing'];
    return (
        <Dialog onClose={onClose} open={openDialog} sx={{ '.MuiPaper-root': { p: '30px', width: { xs: '100%', sm: '562px' } } }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography
                    sx={{
                        color: theme.palette.grey[400],
                        fontFamily: 'Inter',
                        fontWeight: 700,
                        fontSize: '16px'
                    }}
                >
                    <FormattedMessage id={selectedPortfolio ? 'edit_portfolio' : 'add_portfolio'} />
                </Typography>
                <CloseIcon onClick={onClose} sx={{ fill: theme.palette.grey[300], cursor: 'pointer', fontSize: '20px' }} />
            </Box>
            <form onSubmit={formik.handleSubmit}>
                <Typography
                    sx={{
                        color: theme.palette.grey[400],
                        fontFamily: 'Inter',
                        fontWeight: 600,
                        fontSize: '14px',
                        mt: 3,
                        mb: '4px'
                    }}
                >
                    <FormattedMessage id="skill" />
                </Typography>
                <Select
                    name="skill"
                    id="skill"
                    value={formik.values.skill}
                    onChange={formik.handleChange}
                    disableUnderline
                    IconComponent={KeyboardArrowDownIcon}
                    fullWidth
                    sx={{
                        fieldset: {
                            border: '1px solid',
                            borderColor: theme.palette.grey[500],
                            borderRadius: '6px'
                        },
                        '.MuiSelect-select': {
                            p: 2,
                            fontSize: '14px',
                            fontFamily: 'Manrope',
                            fontWeight: 400,
                            width: '100%',
                            background: '#fff',
                            color: theme.palette.grey[300]
                        },
                        color: theme.palette.grey[400],
                        '.MuiTypography-root': {
                            color: theme.palette.grey[300]
                        }
                    }}
                >
                    {itmesSelect.map((item: string) => (
                        <MenuItem key={item} value={item}>
                            <Typography
                                sx={{
                                    color: '#000',
                                    fontFamily: 'Inter',
                                    fontWeight: 500,
                                    fontSize: '14px'
                                }}
                            >
                                {item}
                            </Typography>
                        </MenuItem>
                    ))}
                </Select>
                <Typography
                    sx={{
                        color: theme.palette.grey[400],
                        fontFamily: 'Inter',
                        fontWeight: 600,
                        fontSize: '14px',
                        mt: 3,
                        mb: '4px'
                    }}
                >
                    <FormattedMessage id="industry" />
                </Typography>
                <TextField
                    sx={{
                        '.MuiOutlinedInput-input': {
                            fontWeight: 400,
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            background: '#fff'
                        }
                    }}
                    id="industry"
                    name="industry"
                    value={formik.values.industry}
                    onChange={formik.handleChange}
                    variant="outlined"
                    fullWidth
                    placeholder={intl.formatMessage({ id: 'financials' })}
                    error={formik.touched.industry && Boolean(formik.errors.industry)}
                    helperText={formik.touched.industry && formik.errors.industry}
                />
                <Typography
                    sx={{
                        color: theme.palette.grey[400],
                        fontFamily: 'Inter',
                        fontWeight: 600,
                        fontSize: '14px',
                        mt: 3,
                        mb: '4px'
                    }}
                >
                    <FormattedMessage id="description" />
                </Typography>
                <TextField
                    sx={{
                        '.MuiOutlinedInput-input': {
                            fontWeight: 400,
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            background: '#fff'
                        },
                        mb: 3
                    }}
                    id="descriptionTask"
                    name="descriptionTask"
                    value={formik.values.descriptionTask}
                    onChange={formik.handleChange}
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={6}
                    maxRows={6}
                    placeholder={intl.formatMessage({ id: 'description' })}
                    error={formik.touched.descriptionTask && Boolean(formik.errors.descriptionTask)}
                    helperText={formik.touched.descriptionTask && formik.errors.descriptionTask}
                />
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            border: `1px solid ${theme.palette.grey[500]}`,
                            borderRadius: '10px',
                            background: theme.palette.warning[200],
                            p: '12px 16px',
                            alignItems: 'center',
                            cursor: 'pointer',
                            mb: 3
                        }}
                    >
                        <Typography
                            sx={{
                                color: theme.palette.grey[400],
                                fontFamily: 'Inter',
                                fontWeight: 600,
                                fontSize: '14px'
                            }}
                        >
                            <FormattedMessage id="гpload_up_to_3_files" />
                        </Typography>
                        <Box sx={{ width: '24px' }}>
                            <CardMedia component="img" image={fileplus} alt="fileplus" />
                        </Box>
                    </Box>
                </div>
                <Grid container spacing={2}>
                    {files &&
                        [...files].splice(0, 3).map((image: any, index: number) => {
                            console.log('imageimageimage', image.preview);
                            return (
                                <Grid key={index} item xs={12} sm={4}>
                                    <Box
                                        sx={{
                                            border: `1px solid ${theme.palette.dark[700]}`,
                                            borderRadius: '8px',
                                            width: '155px',
                                            height: '150px',
                                            position: 'relative'
                                        }}
                                    >
                                        <CardMedia
                                            sx={{ objectFit: 'cover', borderRadius: '8px' }}
                                            component="img"
                                            width="100%"
                                            height="100%"
                                            image={image.preview}
                                            alt="alt image"
                                        />
                                        <DeleteForeverOutlinedIcon
                                            onClick={() => deleteImage(image.preview)}
                                            sx={{
                                                position: 'absolute',
                                                bottom: 8,
                                                right: 8,
                                                background: 'rgba(255, 255, 255, 0.9)',
                                                cursor: 'pointer',
                                                borderRadius: '4px'
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            );
                        })}
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                    <Button sx={{ width: '90px', mr: 1 }} title={<FormattedMessage id="cancel" />} onClick={onClose} variant="secondary" />
                    <Button sx={{ width: '80px' }} title={<FormattedMessage id="save" />} onClick={onClose} />
                </Box>
            </form>
        </Dialog>
    );
};

export default DIalogAddPortfolio;
