import { Box, Typography, Grid, Breadcrumbs, CardMedia, Divider, Dialog } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import { Button } from 'ui-component/extended/Button';
import React from 'react';
import { Link } from 'react-router-dom';
import logosFacebook from 'assets/images/logos_facebook.svg';
import { GET_FACEBOOK_CONNECTION } from 'services/graphQL/queries/fbIntegration';
import { useLazyQuery, useMutation } from '@apollo/client';
import IntegrationDialog from './IntegrationDialog';
import DialogConfirm from 'ui-component/dialogConfirm';
import { DELETE_AGENCY_FACEBOOK_CONNECTION } from 'services/graphQL/mutations/fbIntegration';
import { loadState } from 'utils/storage';
import { GET_USER_ORGANIZATIONS } from 'services/graphQL';

interface InregrationType {
    title: string;
    src: string;
    desc: string;
    status: string;
}
const IntegrationTab = () => {
    const theme = useTheme();
    const userInfo = loadState('user');
    const organizationId = userInfo.member.organizationId;

    const [openDialogIntegrate, setOpenDialogIntegrate] = React.useState(false);
    const [checkConnection, setCheckConnection] = React.useState(null);
    const handleCloseDialogIntegrate = () => {
        setOpenDialogIntegrate(false);
    };

    const [showDialog, setShowDialog] = React.useState(false);
    const openDialog = () => setShowDialog(true);
    const closeDialog = () => setShowDialog(false);
    const integrations = [
        {
            title: 'Facebook',
            src: logosFacebook,
            desc: 'Get Access to Meta/Facebook Pages, Ad Accounts, Catalogs, Pixels, Instagram Accounts and more',
            status: 'Not Connected'
        }
    ];
    const [organizationName, setOrganizationName] = React.useState('');
    const userId = JSON.parse(`${localStorage.getItem('user')}`);
    const [getUser] = useLazyQuery(GET_USER_ORGANIZATIONS, {
        onCompleted: (res) => {
            setOrganizationName(res.organizations[0].name);
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first'
    });
    const [fetchFbConnection] = useLazyQuery(GET_FACEBOOK_CONNECTION, {
        onCompleted: (data) => {
            console.log('fetchFbConnection', data);
            if (data.organization_fb_connection.length > 0) {
                console.log({ a: data.organization_fb_connection[0].organization_id });
                setCheckConnection(data.organization_fb_connection[0].organization_id);
            }
            closeDialog();
        },
        fetchPolicy: 'network-only'
    });
    const [deleteFbConnection] = useMutation(DELETE_AGENCY_FACEBOOK_CONNECTION, {
        onCompleted: () => {
            setCheckConnection(null);
            initialize();
        },
        fetchPolicy: 'network-only'
    });

    const handleDisconnect = () => {
        if (checkConnection !== null) {
            deleteFbConnection({
                variables: {
                    organization_id: checkConnection
                }
            });
        }
    };

    const initialize = () => {
        fetchFbConnection({
            variables: {
                organization_id: organizationId
            }
        });
    };
    React.useEffect(() => {
        getUser({
            variables: {
                user_id: userId.user.id
            }
        });
        initialize();
    }, []);

    return (
        <Box sx={{ p: '0 24px' }}>
            <Breadcrumbs aria-label="breadcrumb">
                {/* <Link className="link" to="/">
                    <FormattedMessage id="venly" />
                </Link> */}
                <Typography sx={{ textTransform: 'capitalize', color: theme.palette.grey[200], fontFamily: 'Inter', fontWeight: 500 }}>
                    {organizationName}
                </Typography>
                <Link className="link" to="/settings">
                    <FormattedMessage id="menu_settings" />
                </Link>
                <Link className="link" to="/settings">
                    <FormattedMessage id="agency" />
                </Link>
                <Typography sx={{ textTransform: 'capitalize', color: theme.palette.grey[300], fontFamily: 'Inter', fontWeight: 500 }}>
                    <FormattedMessage id="integrations" />
                </Typography>
            </Breadcrumbs>
            <Typography
                mt={3}
                sx={{
                    fontWeight: 500,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '24px'
                }}
            >
                <FormattedMessage id="integrations" />
            </Typography>
            <Typography
                mt={1}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[600],
                    fontSize: '14px'
                }}
            >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </Typography>
            <Divider sx={{ m: '20px 0' }} />
            <Grid container spacing={3}>
                {integrations.map((integration: InregrationType) => (
                    <Grid key={integration.title} item xs={12} sm={6}>
                        <Box
                            p={3}
                            sx={{
                                border: `1px solid ${theme.palette.grey[500]}`,
                                borderRadius: '8px',
                                boxShadow: '0px 10px 16px rgba(0, 0, 0, 0.04)'
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box mr={1} sx={{ width: '32px' }}>
                                    <CardMedia
                                        sx={{ objectFit: 'contain' }}
                                        component="img"
                                        width="18px"
                                        image={integration.src}
                                        alt="alt image"
                                    />
                                </Box>
                                <Typography
                                    sx={{
                                        fontWeight: 600,
                                        fontFamily: 'Inter',
                                        color: theme.palette.grey[400],
                                        fontSize: '14px'
                                    }}
                                >
                                    {integration.title}
                                </Typography>
                            </Box>
                            <Typography
                                mt={4}
                                sx={{
                                    fontWeight: 400,
                                    fontFamily: 'Inter',
                                    color: theme.palette.grey[600],
                                    fontSize: '14px'
                                }}
                            >
                                {integration.desc}
                            </Typography>
                            <Box mt={4} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Box sx={{ width: '87px' }}>
                                    {checkConnection ? (
                                        <Button onClick={openDialog} variant="primary" title={<FormattedMessage id="Disconnect" />} />
                                    ) : (
                                        <Button
                                            onClick={() => setOpenDialogIntegrate(true)}
                                            variant="primary"
                                            title={<FormattedMessage id="integrate" />}
                                        />
                                    )}
                                </Box>
                                <Box
                                    sx={{
                                        background: checkConnection ? theme.palette.primary[100] : theme.palette.primary[500],
                                        p: '8px 12px',
                                        borderRadius: '100px'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontFamily: 'Inter',
                                            color: checkConnection ? theme.palette.primary[600] : theme.palette.secondary[400],
                                            fontSize: '12px'
                                        }}
                                    >
                                        {checkConnection ? <FormattedMessage id="connected" /> : integration.status}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <IntegrationDialog
                openDialogIntegrate={openDialogIntegrate}
                handleCloseDialogAddClient={handleCloseDialogIntegrate}
                setCheckConnection={setCheckConnection}
            />
            <Dialog onClose={closeDialog} open={showDialog}>
                <DialogConfirm onConfirm={handleDisconnect} onClose={closeDialog} />
            </Dialog>
        </Box>
    );
};

export default IntegrationTab;
