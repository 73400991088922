import { gql } from '@apollo/client';

export const AUTH_SIGN_IN = gql`
    mutation MyMutation($email: String!, $password: String!) {
        auth_sign_in(arg1: { email: $email, password: $password }) {
            access_token
            refresh_token
        }
    }
`;

export const AUTH_SIGN_UP = gql`
    mutation MyMutation($email: String!, $first_name: String!, $last_name: String!, $password: String!) {
        auth_sign_up(arg1: { email: $email, first_name: $first_name, last_name: $last_name, password: $password }) {
            access_token
            address_id
            created_at
            email
            first_name
            id
            image_url
            last_name
            phone
            refresh_token
        }
    }
`;

export const GET_USER_BY_EMAIL = gql`
    query getUser($email: String!) {
        users(where: { email: { _eq: $email } }) {
            id
            first_name
            last_name
            image_url
            email
            created_at
            address_id
        }
    }
`;

export const AUTH_REFRESH_TOKEN = gql`
    mutation refreshToken($refresh_token: String!) {
        auth_refresh(arg1: { refresh_token: $refresh_token }) {
            access_token
        }
    }
`;

export const AUTH_SIGN_OUT = gql`
    mutation MyMutation($email: String!) {
        auth_sign_out(arg1: { email: $email }) {
            ok
        }
    }
`;

export const UPDATE_USER_PASSWORD = gql`
    mutation MyMutation($new_password: String!, $previous_password: String!) {
        auth_update_password(arg1: { new_password: $new_password, previous_password: $previous_password }) {
            ok
        }
    }
`;

export const ADD_LOGO_TO_USER = gql`
    mutation addLogoToUser($userId: uuid!, $logoUrl: String!) {
        update_users_by_pk(pk_columns: { id: $userId }, _set: { image_url: $logoUrl }) {
            image_url
            id
        }
    }
`;

export const UPDATE_USER_INFO = gql`
    mutation updateUserInfo($userId: uuid!, $first_name: String, $last_name: String) {
        update_users_by_pk(pk_columns: { id: $userId }, _set: { first_name: $first_name, last_name: $last_name }) {
            id
            first_name
            last_name
            image_url
        }
    }
`;
