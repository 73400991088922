import { useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import { Typography, Box, CircularProgress } from '@mui/material';
import WorkflowTable from './WorkflowTable';
import NotFoundImg from 'ui-component/Offer';
import { WorkflowTableContext } from './context/workflowTable.context';
import { ClientDetailsContext } from 'views/pages/clients/context/client-details.context';

const Workflows = () => {
    const theme = useTheme();

    const { workflows, updateWorkflows, getWorkflowsLoading } = useContext(ClientDetailsContext);

    return (
        <WorkflowTableContext.Provider value={{ updateWorkflows }}>
            {getWorkflowsLoading ? (
                <Box mt={10} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box mt={3}>
                    {workflows.length > 0 ? (
                        <Box>
                            <WorkflowTable />
                            <Typography
                                mt={4}
                                sx={{
                                    color: theme.palette.grey[600],
                                    fontFamily: 'Inter',
                                    fontWeight: 600,
                                    fontSize: '10px',
                                    textTransform: 'uppercase',
                                    letterSpacing: '0.12em',
                                    textAlign: 'center'
                                }}
                            >
                                <FormattedMessage id="end_of_page" /> - {workflows?.length} <FormattedMessage id="results" />
                            </Typography>
                        </Box>
                    ) : (
                        <Box sx={{ width: '315px', m: '15% auto 0' }}>
                            <NotFoundImg title={<FormattedMessage id="no_workflows_found" />} />
                        </Box>
                    )}
                </Box>
            )}
        </WorkflowTableContext.Provider>
    );
};

export default Workflows;
