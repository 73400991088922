import { useQuery } from '@apollo/client';
import { TaskQuery, TaskQueryVariables } from 'api/graphql/generated';
import TASK_QUERY from 'api/graphql/workflow/queries/task.query';
import { useEffect, useMemo } from 'react';
import { useDocument } from '../../../../../document-builder/context/document.context';
import { ContextEmployee } from '../../types/employee';

const useTask = (formId?: string) => {
    const { document } = useDocument();
    const { data, loading, error } = useQuery<TaskQuery, TaskQueryVariables>(TASK_QUERY, {
        variables: { document_id: { _eq: document }, task_id: formId ? { _eq: formId } : { _is_null: true } }
    });

    useEffect(() => {
        // console.log(data);
    }, [data]);

    const formattedTask = useMemo(() => {
        const task = data?.task[0];

        if (!task || !task.user || !task.user.processmaker_user?.processmaker_id) return undefined;

        return {
            ...task,
            user: {
                id: task.user.id,
                image_url: task.user.image_url,
                name: task.user.first_name,
                processmaker_id: task.user.processmaker_user?.processmaker_id
            } as ContextEmployee
        };
    }, [data?.task]);

    return { document: data?.document[0], task: formattedTask, loading, error };
};

export default useTask;
