import axios, { AxiosInstance } from 'axios';

// Axios client
const baseURL = process.env.REACT_APP_SNIKPIC_BASE_URL;

// Concepts
interface GenerateConceptsInput {
    client_organization_id: string;
    brand_type: string;
    period: number;
    year: number;
    categories: string[];
}

export const generateConcepts = async (input: GenerateConceptsInput) => {
    try {
        const client: AxiosInstance = axios.create({ baseURL, headers: generateHeaders() });
        const endpoint = '/ai-tools-forwarder/concepts';
        const data = input;
        return await client.post(endpoint, data);
    } catch (e) {
        const err: any = e;
        console.error(err.response.data);
        throw new Error(err.response.data.extensions.message);
    }
};

// Events
// interface GenerateEventsInput {
//     client_organization_id: string;
//     desc: string;
//     year: number;
//     month: number;
//     day: number;
// }

// export const generateEvents = async (input: GenerateEventsInput) => {
//     try {
//         const endpoint = '/ai-tools-forwarder/concepts';
//         const data = input;
//         return await client.post(endpoint, data);
//     } catch (e) {
//         const err: any = e;
//         console.error(err.response.data);
//         throw new Error(err.response.data.extensions.message);
//     }
// };

// Images
interface GenerateImagesInput {
    client_organization_id?: string;
    category: string;
    description: string;
    number_of_images: number;
}

export const generateImagesREST = async (input: GenerateImagesInput) => {
    try {
        const client: AxiosInstance = axios.create({ baseURL, headers: generateHeaders() });
        const endpoint = '/ai-tools-forwarder/images';
        const data = input;
        return await client.post(endpoint, data);
    } catch (e) {
        const err: any = e;
        console.error(err.response.data);
        throw new Error(err.response.data.extensions.message);
    }
};

// Captions
interface GenerateCaptionsInput {
    client_organization_id: string;
    category: string;
    language: string;
    request: string;
}

export const generateCaptions = async (input: GenerateCaptionsInput) => {
    try {
        const client: AxiosInstance = axios.create({ baseURL, headers: generateHeaders() });
        const endpoint = '/ai-tools-forwarder/captions';
        const data = input;
        return await client.post(endpoint, data);
    } catch (e) {
        const err: any = e;
        console.error(err.response.data);
        throw new Error(err.response.data.extensions.message);
    }
};

const generateHeaders = () => ({
    authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || `{}`)?.tokens?.access_token ?? ''}`
});
