import { Button, DialogActions } from '@mui/material';

import { NextButton } from '../../document-builder/ui';
import { useContext } from 'react';
import DocumentBuilderContext from '../../document-builder/context/workflow.context';

interface DialogFooterProps {
    onClose?: () => void;
    arrNodes: Node[];
}

const DialogFooter = ({ onClose, arrNodes }: DialogFooterProps) => {
    const { documentBuilder } = useContext(DocumentBuilderContext);
    const saveChanges = () => {
        // @ts-ignore
        documentBuilder.workflow.setNodes(arrNodes);
        // @ts-ignore
        onClose();
    };

    return (
        <DialogActions style={{ padding: '24px 32px 12px', gap: '16px' }}>
            <Button
                sx={{
                    padding: '12px 20px',
                    borderRadius: '10px',
                    border: '2px solid #EE9A8F',
                    color: '#EE9A8F',
                    width: '87px',
                    height: '42px',
                    fontWeight: 500,
                    fontSize: '14px'
                }}
                onClick={onClose}
            >
                Cancel
            </Button>
            <NextButton
                sx={{
                    fontWeight: 500,
                    fontSize: '14px',
                    width: '134px',
                    height: '42px'
                }}
                onClick={saveChanges}
            >
                Save changes
            </NextButton>
        </DialogActions>
    );
};

export default DialogFooter;
