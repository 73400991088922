import { useChatContext } from 'stream-chat-react';
import type { StreamChatType } from '../../types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, Grid, Box } from '@mui/material';
import ButtonCustom from 'ui-component/extended/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { handleAddWhatsappMember } from '../../../../../services/rest/message_forward';
import { useWorkspaceController } from '../../context/WorkspaceController';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

export const WhatsappPanelToolbar = () => {
    // App context
    const dispatch = useDispatch();
    const intl = useIntl();

    // Chat context
    const { channel } = useChatContext<StreamChatType>();
    const channelId = channel?.id || '';
    const { closeAdminPanel } = useWorkspaceController();

    // Formik
    const formik = useFormik({
        enableReinitialize: true, // Important to get initial values from useEffect API
        initialValues: {
            phoneNumber: '' || ''
        },
        validationSchema: Yup.object({
            phoneNumber: Yup.string().max(255).required('Whatsapp number is required')
        }),
        onSubmit: async () => {
            await handleSubmit();
        }
    });

    // Actions
    const handleSubmit = async () => {
        try {
            await handleAddWhatsappMember({
                streamChannelId: channelId,
                whatsappNumber: formik.values.phoneNumber
            });
            closeAdminPanel();
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({ id: 'whatsapp_number_added_to_channel' })
                })
            );
        } catch (e) {
            const err: any = e;
            const errorCode = err.message.toLowerCase();
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({ id: errorCode }),
                    variant: 'error' // or success
                })
            );
        }
    };

    // Final render
    // ............................................................................................
    return (
        <>
            <form>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={9}>
                        <TextField
                            error={Boolean(formik.touched.phoneNumber && formik.errors.phoneNumber)}
                            fullWidth
                            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                            label="Whatsapp number"
                            margin="normal"
                            name="phoneNumber"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            type="text"
                            value={formik.values.phoneNumber}
                            variant="outlined"
                            placeholder="+12345678987"
                        />
                    </Grid>
                    <Grid item xs={12} md={3} sx={{ pl: 2 }}>
                        <Box sx={{ height: '100%' }}>
                            <ButtonCustom
                                onClick={formik.handleSubmit}
                                colorBtn="white"
                                titleBtn={<FormattedMessage id="add_whatsapp_number" />}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};
