import { Tabs, Typography, Grid, Tab, Divider } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
// import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import MemoryOutlinedIcon from '@mui/icons-material/MemoryOutlined';
// import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import IntegrationTab from './IntegrationTab';
import BillingTab from './BillingTab';
import ProfileSettings from './ProfileSettings';

const Settings = () => {
    const theme = useTheme();
    const [tabValue, setTabValue] = React.useState('profile');
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };
    const renderTab = () => {
        switch (tabValue) {
            case 'profile':
                return <ProfileSettings />;
            case 'whitelabeling':
                return 'Whitelabeling';
            case 'integrations':
                return <IntegrationTab />;
            case 'notifications':
                return 'Notifications';
            case 'billing':
                return <BillingTab />;
            case 'about_snikpic':
                return 'About Snikpic';
            default:
                return '';
        }
    };

    return (
        <Grid container sx={{ height: 'calc(100vh - 110px)' }}>
            <Grid item xs={12} sm={2} sx={{ borderRight: `1px solid ${theme.palette.grey[500]}` }}>
                <Typography
                    mb={3}
                    sx={{
                        fontWeight: 600,
                        fontFamily: 'Inter',
                        color: theme.palette.grey[300],
                        fontSize: '10px',
                        letterSpacing: '0.12em',
                        textTransform: 'uppercase'
                    }}
                >
                    <FormattedMessage id="manager_settings" />
                </Typography>
                <Tabs
                    sx={{ '.MuiTabs-flexContainer': { border: 'none' }, justifyContent: 'flex-start' }}
                    orientation="vertical"
                    value={tabValue}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                >
                    <Tab
                        sx={{ justifyContent: 'flex-start', p: 0, minHeight: '30px' }}
                        icon={<PersonOutlineOutlinedIcon />}
                        iconPosition="start"
                        label={<FormattedMessage id="profile_settings" />}
                        value="profile"
                    />
                    <Divider sx={{ m: '20px 0 20px' }} />
                    <Typography
                        mb={2}
                        sx={{
                            fontWeight: 600,
                            fontFamily: 'Inter',
                            color: theme.palette.grey[300],
                            fontSize: '10px',
                            letterSpacing: '0.12em',
                            textTransform: 'uppercase'
                        }}
                    >
                        <FormattedMessage id="agency_settings" />
                    </Typography>
                    {/* <Tab
                        sx={{ justifyContent: 'flex-start', p: 0, minHeight: '40px' }}
                        icon={<BuildOutlinedIcon sx={{ transform: 'rotate(90deg)', fontSize: '18px' }} />}
                        iconPosition="start"
                        label={<FormattedMessage id="whitelabeling" />}
                        value="whitelabeling"
                    /> */}
                    <Tab
                        sx={{ justifyContent: 'flex-start', p: 0, minHeight: '40px' }}
                        icon={<MemoryOutlinedIcon sx={{ fontSize: '22px' }} />}
                        iconPosition="start"
                        label={<FormattedMessage id="integrations" />}
                        value="integrations"
                    />
                    {/* <Tab
                        sx={{ justifyContent: 'flex-start', p: 0, minHeight: '40px' }}
                        icon={<NotificationsOutlinedIcon sx={{ fontSize: '22px' }} />}
                        iconPosition="start"
                        label={<FormattedMessage id="notifications" />}
                        value="notifications"
                    /> */}
                    <Divider sx={{ m: '20px 0' }} />
                    <Typography
                        mb={2}
                        sx={{
                            fontWeight: 600,
                            fontFamily: 'Inter',
                            color: theme.palette.grey[300],
                            fontSize: '10px',
                            letterSpacing: '0.12em',
                            textTransform: 'uppercase'
                        }}
                    >
                        <FormattedMessage id="resources" />
                    </Typography>
                    <Tab
                        sx={{ justifyContent: 'flex-start', p: 0, minHeight: '40px' }}
                        icon={<CreditCardIcon sx={{ fontSize: '18px' }} />}
                        iconPosition="start"
                        label={<FormattedMessage id="billing" />}
                        value="billing"
                    />
                    {/* <Tab
                        sx={{ justifyContent: 'flex-start', p: 0, minHeight: '40px' }}
                        icon={<InfoOutlinedIcon sx={{ fontSize: '18px' }} />}
                        iconPosition="start"
                        label={<FormattedMessage id="about_snikpic" />}
                        value="about_snikpic"
                    /> */}
                </Tabs>
            </Grid>
            <Grid item xs={12} sm={10}>
                {renderTab()}
            </Grid>
        </Grid>
    );
};

export default Settings;
