import { useState } from 'react';
import { Box, Typography, Dialog } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { Member } from './member';
import { ProjectMemberType } from 'types/clients';
import DialogConfirm from 'ui-component/dialogConfirm';
import { MemberBar } from '../../../../components/member-bar';
import { ProjectStatus } from '../../../../components/project-status';
import { AutoCompleteClients } from '../../../../components/autocomplete-clients';

export const MembersForm = ({ project, availableMembers, onAddMember, onDelMember, deleting }: any) => {
    const theme = useTheme();
    const [showDialog, setShowDialog] = useState(false);
    const [memberId, setMemberId] = useState('');
    const [pendingValue, setPendingValue] = useState<ProjectMemberType[]>([]);

    const openDialog = () => setShowDialog(true);
    const closeDialog = () => setShowDialog(false);

    const onDelete = (id: string) => {
        setMemberId(id);
        openDialog();
    };

    const deleteMember = async () => {
        await onDelMember(memberId);
        closeDialog();
    };

    return (
        <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    my: 3,
                    py: 2,
                    borderBottom: `1px solid ${theme.palette.grey[500]}`
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <MemberBar members={project.teamMembers} membersAvailable={availableMembers} />
                    {project.teamMembers.length > 0 && <Box sx={{ width: 32 }} />}
                    <ProjectStatus status={project.status} />
                </Box>
                <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600, textAlign: 'right' }}>
                    {moment(project.date).format('DD MMMM, YYYY')}
                </Typography>
            </Box>
            <Box sx={{ my: 1 }}>
                <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600, py: 1 }}>
                    <FormattedMessage id="description" />
                </Typography>
                <Typography
                    sx={{
                        color: theme.palette.grey[400],
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        fontSize: 12,
                        py: 2,
                        borderBottom: `1px solid ${theme.palette.grey[500]}`
                    }}
                >
                    {project.description}
                </Typography>
            </Box>
            <Typography mt={4} sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600 }}>
                <FormattedMessage id="team_members" />
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    borderBottom: `1px solid ${theme.palette.grey[500]}`,
                    py: '15px'
                }}
            >
                <AutoCompleteClients
                    labels={availableMembers}
                    pendingValue={pendingValue}
                    setPendingValue={setPendingValue}
                    placeholder="start_typing_team_member_name"
                    handleClickAutoCompleteItem={onAddMember}
                />
                <Typography ml={2} sx={{ color: theme.palette.grey[300], fontFamily: 'Inter', fontWeight: 500 }}>
                    <FormattedMessage id="add_team_member" />
                </Typography>
            </Box>
            <Box sx={{ my: 3, flexGrow: 1, borderBottom: `1px solid ${theme.palette.grey[500]}` }}>
                {project.teamMembers.map((member: ProjectMemberType) => (
                    <Member member={member} key={member.orgMemberId} onDelete={onDelete} />
                ))}
            </Box>
            <Dialog onClose={closeDialog} open={showDialog}>
                <DialogConfirm onConfirm={deleteMember} onClose={closeDialog} loading={deleting} />
            </Dialog>
        </Box>
    );
};
