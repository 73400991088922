import { Box, CardMedia, Typography, Grid, Popover } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';
import copy from 'assets/images/copy.svg';
import { openSnackbar } from 'store/slices/snackbar';
import { useDispatch } from 'store';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Text, DarkText } from 'ui-component/typography';
import { DateTime } from 'luxon';

interface LinkType {
    id: string;
    expires_at: string;
    created_at: string;
}
interface LinkSharedProps {
    link: LinkType;
    handleDeletion: () => void;
}

const LinkShared = ({ link, handleDeletion }: LinkSharedProps) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [copyLink, setCopyLink] = React.useState({
        value: '',
        copied: false
    });

    const closePopover = () => setAnchorEl(null);
    const openPopover = (event: any) => setAnchorEl(event.currentTarget);

    const createdAtDate = DateTime.fromISO(link.created_at);
    const expiresAtDate = DateTime.fromISO(link.expires_at);

    const handleSnackbar = () => {
        dispatch(
            openSnackbar({
                open: true,
                message: 'copied_to_clipboard',
                transition: 'SlideDown'
            })
        );
    };
    const onCopy = () => {
        setCopyLink({
            ...copyLink,
            copied: true
        });
        setTimeout(() => {
            setCopyLink({
                ...copyLink,
                copied: false
            });
        }, 1000);
    };

    return (
        <Box key={link.id} mt={3}>
            <Box sx={{ display: 'flex', alignItem: 'center', justifyContent: 'space-between' }}>
                <Text sx={{ fontSize: 14, m: '-3px 0 0 7px' }}>
                    <FormattedMessage id="created_on" /> {createdAtDate.toFormat('dd/MM/yyyy')}
                </Text>
                <Typography
                    onClick={openPopover}
                    sx={{
                        color: theme.palette.grey[400],
                        fontSize: '24px',
                        mt: '-10px',
                        cursor: 'pointer'
                    }}
                >
                    ...
                </Typography>
            </Box>
            <Grid container spacing={1} mt={1}>
                <Grid item xs={9}>
                    <Box
                        sx={{
                            border: `1px solid ${theme.palette.grey[500]}`,
                            borderRadius: '8px',
                            p: '15px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            maxWidth: '373px'
                        }}
                    >
                        <Text
                            sx={{ fontSize: 14, m: '-3px 0 0 7px', color: theme.palette.grey[300], fontWeight: 400 }}
                        >{`${process.env.REACT_APP_DOMAIN}/reporting/${link.id}`}</Text>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <CopyToClipboard onCopy={onCopy} text={`${process.env.REACT_APP_DOMAIN}/reporting/${link.id}`}>
                        <Box
                            onClick={handleSnackbar}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: theme.palette.warning[200],
                                p: '12px 24px',
                                cursor: 'pointer',
                                borderRadius: '8px'
                            }}
                        >
                            <Box sx={{ width: '25px', mr: '1px' }}>
                                <CardMedia sx={{ objectFit: 'contain' }} component="img" image={copy} alt="AI tools" />
                            </Box>
                            <Text sx={{ m: '-3px 0 0 7px', display: { xs: 'none', sm: 'block' } }}>
                                <FormattedMessage id="copy" />
                            </Text>
                        </Box>
                    </CopyToClipboard>
                </Grid>
            </Grid>
            <DarkText
                sx={{
                    fontSize: 12,
                    fontWeight: 600,
                    m: '-3px 0 0 7px',
                    textTransform: 'uppercase',
                    mt: '4px',
                    color: DateTime.now() < expiresAtDate ? theme.palette.success[100] : theme.palette.error[100]
                }}
            >
                {DateTime.now() > expiresAtDate ? <FormattedMessage id="expired_on" /> : <FormattedMessage id="expires_on" />}
                {` ${expiresAtDate.toFormat('dd/MM/yyyy')}`}
            </DarkText>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={closePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <Box sx={{ p: '16px 24px' }}>
                    <Box onClick={handleDeletion} sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
                        <BackspaceOutlinedIcon sx={{ width: '20px' }} />
                        <Text sx={{ fontSize: 14, ml: '10px' }}>
                            <FormattedMessage id="remove" />
                        </Text>
                    </Box>
                </Box>
            </Popover>
        </Box>
    );
};

export default LinkShared;
