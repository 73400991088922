import { Box, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useIntl } from 'react-intl';

interface InformationToolTipProps {
    message: string;
}

const InformationToolTip = ({ message }: InformationToolTipProps) => {
    const intl = useIntl();

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Tooltip title={intl.formatMessage({ id: message })}>
                <IconButton>
                    <HelpOutlineIcon />
                </IconButton>
            </Tooltip>
        </Box>
    );
};

export default InformationToolTip;
