import { PublicPerformance } from 'views/pages/clients/features/detail/performance/PublicPerformance';

const ReportingRoutes = {
    children: [
        {
            path: '/reporting/:sharedReportId',
            element: <PublicPerformance />
        }
    ]
};

export default ReportingRoutes;
