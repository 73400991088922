// Config
import { useParams } from 'react-router-dom';

// UI
import { Grid } from '@mui/material';

// Components
import GeneralTaskFinetuning from 'features/ai/components/GeneralTaskFinetuning';

const IndividualFinetuningPage = () => {
    const { taskId, userId } = useParams();

    // Return
    if (!userId || !taskId) {
        return <div>No userId or taskId provided</div>;
    }
    return (
        <>
            <Grid container justifyContent="center">
                <Grid item m={2} xs={12} sm={9}>
                    <GeneralTaskFinetuning userId={userId} taskId={taskId} />
                </Grid>
            </Grid>
        </>
    );
};

export default IndividualFinetuningPage;
