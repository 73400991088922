import { useState } from 'react';
import { Box, Typography, Popover, CardMedia, IconButton, Dialog } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import edit from 'assets/images/edit.svg';
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';
import { Text } from 'ui-component/typography';
import ConfirmDialog from 'ui-component/dialogConfirm';
import AddCaptionGeneratorDrawer, { CaptionGeneratorCardType } from './AddCaptionGeneratorDrawer';
import { useMutation } from '@apollo/client';
import { REMOVE_CAPTION_EXAMPLE } from 'services/graphQL/mutations/aiTools/AiToolsMutations';

interface CaptionGeneratorCardProps {
    item: CaptionGeneratorCardType;
    getCaptions: any;
}

const CaptionGeneratorCard = ({ item, getCaptions }: CaptionGeneratorCardProps) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const [showConfirm, setShowConfirm] = useState(false);
    const id = anchorEl ? 'simple-popover' : undefined;
    const [showCaptionGenerator, setShowCaptionGenerator] = useState(false);
    const [selectedCaptionGenerator, setSelectedCaptionGenerator] = useState<CaptionGeneratorCardType>();

    const closeCaptionGenerator = () => setShowCaptionGenerator(false);
    const openCaptionGenerator = (item: CaptionGeneratorCardType) => {
        setShowCaptionGenerator(true);
        setSelectedCaptionGenerator(item);
        closePopover();
    };

    const openConfirm = () => setShowConfirm(true);
    const closeConfirm = () => setShowConfirm(false);
    const openPopover = (event: any) => setAnchorEl(event.currentTarget);
    const closePopover = () => setAnchorEl(null);

    const [removeCaptionExample, { loading }] = useMutation(REMOVE_CAPTION_EXAMPLE, {
        onCompleted: (data) => {
            getCaptions();
            closePopover();
            closeConfirm();
        },
        onError: (error) => {
            console.log(error);
        }
    });

    const removeCaption = (itemId: string) => {
        removeCaptionExample({
            variables: {
                id: itemId
            }
        });
    };

    return (
        <Box sx={{ background: theme.palette.grey[700], borderRadius: '8px', p: 2, mt: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography
                    sx={{
                        color: theme.palette.grey[600],
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        fontSize: '10px',
                        textTransform: 'uppercase',
                        letterSpacing: '0.12em'
                    }}
                >
                    <FormattedMessage id="concept_description" />
                </Typography>
                <IconButton onClick={openPopover}>
                    <MoreHoriz />
                </IconButton>
                <Popover
                    id={id}
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    onClose={closePopover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                >
                    <Box sx={{ p: '16px 24px' }}>
                        <Box
                            onClick={() => openCaptionGenerator(item)}
                            sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center', mb: 2 }}
                        >
                            <Box sx={{ width: '20px', mr: '5px' }}>
                                <CardMedia sx={{ objectFit: 'contain' }} component="img" image={edit} alt="AI tools" />
                            </Box>
                            <Text sx={{ fontSize: '14px', ml: '10px' }}>
                                <FormattedMessage id="edit" />
                            </Text>
                        </Box>
                        <Box onClick={openConfirm} sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
                            <BackspaceOutlinedIcon sx={{ width: '20px' }} />
                            <Text sx={{ fontSize: '14px', ml: '10px' }}>
                                <FormattedMessage id="remove" />
                            </Text>
                        </Box>
                    </Box>
                </Popover>
            </Box>
            <Typography
                sx={{
                    color: theme.palette.grey[400],
                    fontFamily: 'Inter',
                    fontWeight: 500,
                    fontSize: '14px',
                    mt: 2
                }}
            >
                {item.concept}
            </Typography>
            <Typography
                sx={{
                    color: theme.palette.grey[600],
                    fontFamily: 'Inter',
                    fontWeight: 500,
                    fontSize: '10px',
                    textTransform: 'uppercase',
                    letterSpacing: '0.12em',
                    mt: 3
                }}
            >
                <FormattedMessage id="caption" />
            </Typography>
            <Typography
                sx={{
                    color: theme.palette.grey[400],
                    fontFamily: 'Inter',
                    fontWeight: 500,
                    fontSize: '14px',
                    mt: 2,
                    maxWidth: '90%',
                    whiteSpace: 'pre-line'
                }}
            >
                {item.caption}
            </Typography>
            <AddCaptionGeneratorDrawer
                open={showCaptionGenerator}
                onClose={closeCaptionGenerator}
                item={selectedCaptionGenerator}
                getCaptions={getCaptions}
            />
            <Dialog open={showConfirm} onClose={closeConfirm}>
                <ConfirmDialog onClose={closeConfirm} onConfirm={() => removeCaption(item.id)} loading={loading} />
            </Dialog>
        </Box>
    );
};

export default CaptionGeneratorCard;
