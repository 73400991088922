import { Box, CircularProgress, ClickAwayListener, Input, Popover, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from 'react';
import { Button } from 'ui-component/extended/Button';
import SelectorAutocomplite from 'ui-component/SelectorAutoComplite';
import ViewPastRuns from './ViewPastRuns';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SelectCustom, { LabelsType } from 'ui-component/selectCustom';
import CaptionGeneratorReults from './CaptionGeneratorResults';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
    GET_AI_TOOLS_HISTORY,
    GET_CAPTION_EXAMPLE_CATEGORIES,
    GET_CAPTION_EXAMPLE_SUBCATEGORIES,
    GET_SERVICE_USAGE_LIMITS
} from 'services/graphQL/queries/aiTool';
import { GENERATE_CAPTION } from 'services/graphQL/mutations/aiTools/AiToolsMutations';
import { GET_CLIENTS } from 'services/graphQL';
import useAuth from 'hooks/useAuth';
import { Subcategory } from 'views/pages/clients/features/detail/AITools/AddCaptionGeneratorDrawer';

// API
import { generateCaptions } from '../../services/rest/ai_tools';

interface CaptionGeneratorProps {
    handleToolsNone: () => void;
}
interface LabelType {
    title: string;
    src?: string;
}
interface ResultsValueInputType {
    client_organization_id: string;
    category: string;
    language: string;
    request: string;
}

interface PreviousValueType {
    client_organization_id: string;
    id: string;
    organization_id: string;
    service: number;
    timestamp: string;
    input: ResultsValueInputType;
    output: any;
}

const languageOptions: LabelsType[] = [
    { id: 'BG', name: 'Bulgarian' },
    { id: 'CS', name: 'Czech' },
    { id: 'DA', name: 'Danish' },
    { id: 'DE', name: 'German' },
    { id: 'EL', name: 'Greek' },
    { id: 'EN', name: 'English' },
    { id: 'ES', name: 'Spanish' },
    { id: 'ET', name: 'Estonian' },
    { id: 'FI', name: 'Finnish' },
    { id: 'FR', name: 'French' },
    { id: 'HU', name: 'Hungarian' },
    { id: 'IT', name: 'Italian' },
    { id: 'JA', name: 'Japanese' },
    { id: 'LT', name: 'Lithuanian' },
    { id: 'LV', name: 'Latvian' },
    { id: 'NL', name: 'Dutch' },
    { id: 'PL', name: 'Polish' },
    { id: 'PT', name: 'Portuguese' },
    { id: 'RO', name: 'Romanian' },
    { id: 'RU', name: 'Russian' },
    { id: 'SK', name: 'Slovak' },
    { id: 'SL', name: 'Slovenian' },
    { id: 'SV', name: 'Swedish' },
    { id: 'ZH', name: 'Chinese' }
];

const CaptionGenerator = ({ handleToolsNone }: CaptionGeneratorProps) => {
    const theme = useTheme();
    const intl = useIntl();
    const { member } = useAuth();

    const [pendingValue, setPendingValue] = React.useState<LabelType[]>([]);
    const [results, setResults] = React.useState(false);
    const [history, setHistory] = React.useState(false);
    const [resultsValue, setResultsValue] = React.useState<any>();
    const [previousValue, setPreviousValue] = React.useState<PreviousValueType[]>([]);
    const [selectedClients, setSelectedClients] = React.useState<any[]>([]);
    const [clientsLabels, setClientsLabels] = React.useState<any>([]);
    const [currentUsage, setCurrentUsage] = React.useState(0);
    const [limit, setLimit] = React.useState(0);
    const [credits, setCredits] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
    const openPopover = (event: React.MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const [selectedLabel, setSelectedLabel] = React.useState<Subcategory | null>(null);
    const [subcategories, setSubcategories] = React.useState<Subcategory[]>([]);
    const [categories, setCategories] = React.useState<any[]>([]);

    const [isCaptionGenerationLoading, setIsCaptionGenerationLoading] = React.useState(false);

    const id = anchorEl ? 'simple-popover' : undefined;

    const formik = useFormik({
        initialValues: {
            lang: 'EN',
            category: '',
            description: ''
        },
        validationSchema: Yup.object({
            lang: Yup.string().required('Required'),
            category: Yup.string().required('Required'),
            description: Yup.string().trim()
        }),
        onSubmit: async (values) => {
            console.log({
                category: values.category,
                client_organization_id: selectedClients[0].id,
                language: values.lang,
                concept_description: `${selectedLabel?.name} ${values.description}`
            });
            if (values.description.split(' ').length < 201 && values.description.length !== 0) {
                setIsCaptionGenerationLoading(true);
                const variablesForRestRequest = {
                    category: values.category,
                    client_organization_id: selectedClients[0].id,
                    language: values.lang,
                    request: `${selectedLabel?.name} ${values.description}`
                };
                console.log('variablesForRestRequest: ', variablesForRestRequest);
                // const variablesForHasuraRequest = {
                //     category: values.category,
                //     client_organization_id: selectedClients[0].id,
                //     language: values.lang,
                //     concept_description: `${selectedLabel?.name} ${values.description}`
                // };
                // // generateCaption({
                // //     variables: variablesForHasuraRequest
                // // });
                const response = await generateCaptions(variablesForRestRequest);
                console.log('response', response);
                setCurrentUsage(currentUsage + 1);
                setResultsValue({ caption: response.data.caption });
                setResults(true);
                if (currentUsage >= limit && credits > 1) {
                    setCredits(credits - 1);
                }
                setIsCaptionGenerationLoading(false);
            }
        }
    });
    const handleSelectedLabel = (e: React.MouseEvent<HTMLDivElement>, value: Subcategory) => {
        e.stopPropagation();
        setSelectedLabel(value);
        const category = categories.find((category) => category.id === value.category_id);
        formik.setFieldValue('category', category.name);
        handleClose();
    };
    const [getServiceUsage] = useLazyQuery(GET_SERVICE_USAGE_LIMITS, {
        onCompleted: (data) => {
            setCurrentUsage(data.ai_tools_get_usage.current);
            setLimit(data.ai_tools_get_usage.client_limit);
            setCredits(data.ai_tools_get_usage.available_credits);
        },
        onError: (error) => {
            console.log(error);
        }
    });
    const [getClients, { loading: loadingClients }] = useLazyQuery(GET_CLIENTS, {
        onCompleted: (data) => {
            setClientsLabels(data.client_organizations);
        },
        onError: (error) => {
            console.log(error);
        }
    });
    const [generateCaption, { loading: loadingGenerateCaption }] = useMutation(GENERATE_CAPTION, {
        onCompleted: (data: any) => {
            console.log('data from hasura', data);
            setCurrentUsage(currentUsage + 1);
            setResultsValue(data.ai_tools_generate_caption);
            setResults(true);
            if (currentUsage >= limit && credits > 1) {
                setCredits(credits - 1);
            }
        },
        onError: (error: any) => {
            console.log(error);
        }
    });
    const [fetchCaptionHistory, { loading: loadingFetchCaptiontHistory }] = useLazyQuery(GET_AI_TOOLS_HISTORY, {
        onCompleted: (data) => {
            setPreviousValue(data.ai_tools_history);
        }
    });
    const [getCaptionExampleSubcategories] = useLazyQuery(GET_CAPTION_EXAMPLE_SUBCATEGORIES, {
        onCompleted: (data) => {
            setSubcategories(data.caption_example_subcategories);
        }
    });

    const [getCaptionExampleCategories] = useLazyQuery(GET_CAPTION_EXAMPLE_CATEGORIES, {
        onCompleted: (data) => {
            setCategories(data.caption_example_categories);
        }
    });

    React.useEffect(() => {
        getClients({ variables: { organizationId: member?.organizationId } });
        getCaptionExampleSubcategories();
        getCaptionExampleCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (selectedClients[0]) {
            fetchCaptionHistory({
                variables: {
                    client_organization_id: selectedClients[0].id,
                    service: 'CAPTION'
                }
            });
            getServiceUsage({
                variables: {
                    client_organization_id: selectedClients[0].id,
                    service: 'CAPTION'
                }
            });
        }
    }, [selectedClients]);

    const handleLang = (item: string) => formik.setFieldValue('lang', item);

    const handleCloseResults = () => {
        setResults(false);
        setHistory(false);
    };
    const handleHistory = () => {
        fetchCaptionHistory({
            variables: {
                client_organization_id: selectedClients[0].id,
                service: 'CAPTION'
            },
            fetchPolicy: 'network-only'
        }).then(() => {
            setResults(true);
            setHistory(true);
            setResultsValue(null);
        });
    };

    return (
        <Box sx={{ overflowY: 'auto', maxHeight: { xs: 'calc(100vh - 200px)', sm: 'calc(100vh - 78px)' } }}>
            {/* {loadingGenerateCaption ? ( */}
            {isCaptionGenerationLoading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: { xs: 'calc(100vh - 200px)', sm: 'calc(100vh - 78px)' }
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (
                <Box>
                    {results ? (
                        <CaptionGeneratorReults
                            handleResults={handleCloseResults}
                            history={history}
                            resultsValue={resultsValue}
                            previousValue={previousValue}
                        />
                    ) : (
                        <Box>
                            <Box onClick={handleToolsNone} sx={{ display: 'flex', alignItems: 'center', p: '24px', cursor: 'pointer' }}>
                                <ArrowBackIcon sx={{ fontSize: '26px', fill: theme.palette.success[300] }} />
                                <Typography
                                    ml={1}
                                    sx={{ color: theme.palette.success[300], fontFamily: 'Inter', fontWeight: 500, fontSize: '14px' }}
                                >
                                    <FormattedMessage id="caption_generator" />
                                </Typography>
                            </Box>
                            <form onSubmit={formik.handleSubmit}>
                                {selectedClients.length > 0 ? (
                                    <ViewPastRuns
                                        size={currentUsage}
                                        handleHistory={handleHistory}
                                        limit={limit}
                                        credits={credits}
                                        loading={loadingFetchCaptiontHistory}
                                    />
                                ) : null}
                                <Box sx={{ p: '10px 24px 24px 24px' }}>
                                    {loadingClients ? (
                                        <CircularProgress />
                                    ) : (
                                        <SelectorAutocomplite
                                            autocompleteItems={clientsLabels}
                                            pendingValue={pendingValue}
                                            setPendingValue={setPendingValue}
                                            arrItemSelected={selectedClients}
                                            setArrItemsSelected={setSelectedClients}
                                            title="client"
                                            imagePath="logo_url"
                                            labelPath="name"
                                            singleSelect
                                        />
                                    )}
                                </Box>
                                {selectedClients.length > 0 ? (
                                    <Box>
                                        <Box sx={{ p: '0 24px 24px 24px' }}>
                                            <Typography
                                                mb={2}
                                                sx={{
                                                    color: theme.palette.grey[400],
                                                    fontFamily: 'Inter',
                                                    fontWeight: 600,
                                                    fontSize: '14px',
                                                    textTransform: 'capitalize'
                                                }}
                                            >
                                                <FormattedMessage id="concept_description" />
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    border: `1px solid ${theme.palette.grey[500]}`,
                                                    borderRadius: '8px',
                                                    p: 1,
                                                    alignItems: 'center',
                                                    width: '100%'
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        borderRight: `1px solid ${theme.palette.grey[500]}`,
                                                        p: 1,
                                                        width: '40%'
                                                    }}
                                                    role="button"
                                                    onClick={openPopover}
                                                >
                                                    {selectedLabel ? (
                                                        <Typography
                                                            sx={{
                                                                color: theme.palette.grey[400],
                                                                fontFamily: 'Inter',
                                                                fontWeight: 500,
                                                                fontSize: '14px',
                                                                mr: 1,
                                                                cursor: 'pointer'
                                                            }}
                                                        >
                                                            {selectedLabel.name}
                                                        </Typography>
                                                    ) : (
                                                        <Typography
                                                            sx={{
                                                                color: theme.palette.grey[300],
                                                                fontFamily: 'Inter',
                                                                fontWeight: 400,
                                                                fontSize: '14px',
                                                                mr: 1,
                                                                cursor: 'pointer'
                                                            }}
                                                        >
                                                            <FormattedMessage id="select_a_category" />
                                                        </Typography>
                                                    )}
                                                    <Popover
                                                        id={id}
                                                        open={!!anchorEl}
                                                        anchorEl={anchorEl}
                                                        onClose={handleClose}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left'
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left'
                                                        }}
                                                        sx={{ '& .MuiPaper-root': { ml: -1, mt: 1.5 } }}
                                                    >
                                                        <ClickAwayListener onClickAway={handleClose}>
                                                            <Box sx={{ maxHeight: 400, overflowY: 'auto' }}>
                                                                {subcategories.map((item: Subcategory, index: number) => (
                                                                    <Box
                                                                        onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                                                                            handleSelectedLabel(e, item)
                                                                        }
                                                                        key={index}
                                                                        sx={{
                                                                            p: 2,
                                                                            cursor: 'pointer',
                                                                            '&:not(last-child)': {
                                                                                borderBottom: `1px solid ${theme.palette.grey[500]}`
                                                                            }
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            sx={{
                                                                                color: theme.palette.grey[400],
                                                                                fontFamily: 'Inter',
                                                                                fontWeight: 500,
                                                                                fontSize: '14px'
                                                                            }}
                                                                        >
                                                                            {item.name}
                                                                        </Typography>
                                                                    </Box>
                                                                ))}
                                                            </Box>
                                                        </ClickAwayListener>
                                                    </Popover>
                                                    <KeyboardArrowDownIcon />
                                                </Box>
                                                <Box sx={{ width: '60%' }}>
                                                    <Input
                                                        disableUnderline
                                                        name="name"
                                                        value={formik.values.description}
                                                        onChange={(e: any) => formik.setFieldValue('description', e.target.value)}
                                                        placeholder={intl.formatMessage({ id: 'description_here' })}
                                                        sx={{
                                                            pl: 1,
                                                            ml: 0,
                                                            '.MuiInput-input': {
                                                                '&::placeholder': { color: theme.palette.grey[300], opacity: 1 }
                                                            },
                                                            width: '100%'
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{ p: '0 24px 24px 24px' }}>
                                            <Typography
                                                mb={2}
                                                sx={{
                                                    color: theme.palette.grey[400],
                                                    fontFamily: 'Inter',
                                                    fontWeight: 600,
                                                    fontSize: '14px'
                                                }}
                                            >
                                                <FormattedMessage id="language_of_publication" />
                                            </Typography>
                                            <SelectCustom value={formik.values.lang} setValue={handleLang} labels={languageOptions} />
                                        </Box>
                                        <Box sx={{ p: '0 24px 24px 24px', width: '220px' }}>
                                            <Button
                                                onClick={formik.handleSubmit}
                                                disabled={currentUsage >= limit && credits < 1}
                                                title={<FormattedMessage id="generate_captions" />}
                                            />
                                        </Box>
                                    </Box>
                                ) : null}
                            </form>
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default CaptionGenerator;
