import { Box, Typography, CardMedia, CircularProgress, Divider } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from 'react';
import ButtonCustom from 'ui-component/extended/Button';
import fa_solid_robot from 'assets/images/fa_solid_robot.svg';
import copy from 'assets/images/copy.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import SelectHistoryAiTools from './SelectHistoryAiTools';
import PreviousResult from './PreviousResult';
import { openSnackbar } from 'store/slices/snackbar';
import { useDispatch } from 'store';

interface ResultsValueInputType {
    client_organization_id: string;
    day?: number;
    desc: string;
    month: number;
    year: number;
}

interface ResultsValueOutputType {
    date: string;
    excerpt: string;
    name: string;
    score: number;
    url: string;
}
interface ResultsValueType {
    client_organization_id: string;
    id: string;
    organization_id: string;
    service: number;
    timestamp: string;
    input: ResultsValueInputType;
    output: ResultsValueOutputType[];
}
interface RelevantEventFinderResultsProps {
    handleResults: any;
    history?: boolean;
    resultsValue: any;
}

const RelevantEventFinderResults = ({ handleResults, history, resultsValue }: RelevantEventFinderResultsProps) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [labelsHistoryDateSelector, setLabelsHistoryDateSelector] = React.useState<any>();
    const [valueHistoryDateSelector, setValueHistoryDateSelector] = React.useState('');
    const [renderResults, setRenderResults] = React.useState<any>();
    const [resultsPrevious, setResultsPrevious] = React.useState<ResultsValueInputType>();
    const handleSnackbar = () => {
        dispatch(
            openSnackbar({
                open: true,
                message: 'copied_to_clipboard',
                transition: 'SlideDown'
            })
        );
    };
    const makeUniq = (arr: any) => {
        console.log(arr);
        return arr.filter((el: any, id: number) => arr.indexOf(el) === id);
    };

    React.useEffect(() => {
        if (resultsValue[0]?.output) {
            setRenderResults(resultsValue[0].output);
            setValueHistoryDateSelector(`${resultsValue[0].input.year} ${resultsValue[0].input.month}`);
            setResultsPrevious(resultsValue[0].input);
            const arrLabels = resultsValue.map((result: any) => `${result.input.year} ${result.input.month}`);
            const filteredArr = makeUniq(arrLabels);
            setLabelsHistoryDateSelector(filteredArr);
        } else {
            setRenderResults(resultsValue);
        }
    }, [resultsValue]);
    const [copyValueDescription, setCopyValueDescription] = React.useState({
        value: '',
        copied: false
    });
    const onCopy = () => {
        setCopyValueDescription({
            ...copyValueDescription,
            copied: true
        });
        setTimeout(() => {
            setCopyValueDescription({
                ...copyValueDescription,
                copied: false
            });
        }, 1000);
    };

    const handleValueelectorDate = (value: string) => {
        setValueHistoryDateSelector(value);
        resultsValue.forEach((item: ResultsValueType) => {
            if (value === `${item.input.year} ${item.input.month}`) {
                setRenderResults(item.output);
                setResultsPrevious({
                    client_organization_id: item.input.client_organization_id,
                    day: item.input.day,
                    desc: item.input.desc,
                    month: item.input.month,
                    year: item.input.year
                });
            }
        });
    };
    const colorProgress = (progress: number) => {
        let color: string;
        if (progress < 50) {
            color = theme.palette.error[100];
        } else if (progress > 49 && progress < 70) {
            color = theme.palette.orange[100];
        } else color = theme.palette.success[100];
        return color;
    };
    const colorBorder = (progress: number) => {
        let color: string;
        if (progress < 50) {
            color = theme.palette.primary[400];
        } else if (progress > 49 && progress < 70) {
            color = theme.palette.primary[500];
        } else color = theme.palette.primary[100];
        return color;
    };

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: '18px 24px',
                    cursor: 'pointer',
                    borderBottom: `1px solid ${theme.palette.grey[500]}`
                }}
            >
                <Box
                    onClick={handleResults}
                    sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <ArrowBackIcon sx={{ fontSize: '26px', fill: theme.palette.success[300] }} />
                    <Typography ml={1} sx={{ color: theme.palette.success[300], fontFamily: 'Inter', fontWeight: 500, fontSize: '14px' }}>
                        <FormattedMessage id={history ? 'history' : 'results'} />
                    </Typography>
                </Box>
                {history && valueHistoryDateSelector ? (
                    <SelectHistoryAiTools
                        labels={labelsHistoryDateSelector}
                        state={valueHistoryDateSelector}
                        setState={handleValueelectorDate}
                        format="MMMM YYYY"
                    />
                ) : (
                    <Box sx={{ width: '160px' }}>
                        <ButtonCustom
                            onClick={handleResults}
                            titleBtn={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Box mr={1} sx={{ width: '20px' }}>
                                        <CardMedia
                                            sx={{ objectFit: 'contain' }}
                                            component="img"
                                            width="18px"
                                            image={fa_solid_robot}
                                            alt="alt image"
                                        />
                                    </Box>
                                    <Typography sx={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '14px' }}>
                                        <FormattedMessage id="ai_powered" />
                                    </Typography>
                                </Box>
                            }
                            colorBtn="red"
                        />
                    </Box>
                )}
            </Box>
            {history && resultsPrevious && (
                <Box sx={{ p: '24px 24px 0 24px' }}>
                    <PreviousResult resultsPrevious={resultsPrevious} />
                </Box>
            )}
            <Box>
                {renderResults &&
                    renderResults.map((result: any, index: number) => (
                        <Box key={index} sx={{ p: '24px 24px 0 24px' }}>
                            <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 700, fontSize: '14px' }}>
                                {result.name}
                            </Typography>
                            <Typography
                                mt={1}
                                sx={{ color: theme.palette.grey[300], fontFamily: 'Inter', fontWeight: 500, fontSize: '12px' }}
                            >
                                {moment(result.date).format('DD MMMM, YYYY')}
                            </Typography>
                            <Typography
                                mt={2}
                                sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 400, fontSize: '14px' }}
                            >
                                {result.excerpt}
                            </Typography>
                            <Box mt={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box sx={{ position: 'relative', width: '40px', height: '40px' }}>
                                        <CircularProgress
                                            variant="determinate"
                                            thickness={3}
                                            value={result.progress}
                                            sx={{ color: colorProgress(result.score * 100), zIndex: 2 }}
                                        />
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                width: '39px',
                                                height: '39px',
                                                border: `1px solid ${colorBorder(Math.floor(result.score * 100))}`,
                                                borderRadius: '100px',
                                                top: 0,
                                                left: 0,
                                                zIndex: '-1',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: colorProgress(Math.floor(result.score * 100)),
                                                    fontFamily: 'Inter',
                                                    fontWeight: 700,
                                                    fontSize: '12px'
                                                }}
                                            >
                                                {Math.floor(result.score * 100)}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: colorProgress(Math.floor(result.score * 100)),
                                                    fontFamily: 'Inter',
                                                    fontWeight: 700,
                                                    fontSize: '12px'
                                                }}
                                            >
                                                %
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Typography
                                        ml={1}
                                        sx={{
                                            color: theme.palette.grey[400],
                                            fontFamily: 'Inter',
                                            fontWeight: 500,
                                            fontSize: '14px'
                                        }}
                                    >
                                        <FormattedMessage id="match" />
                                    </Typography>
                                </Box> */}
                                <CopyToClipboard onCopy={onCopy} text={result.excerpt}>
                                    <Box onClick={handleSnackbar} sx={{ width: '18px', cursor: 'pointer' }}>
                                        <CardMedia
                                            sx={{ objectFit: 'contain' }}
                                            component="img"
                                            width="18px"
                                            image={copy}
                                            alt="alt image"
                                        />
                                    </Box>
                                </CopyToClipboard>
                            </Box>
                            {index + 1 !== resultsValue.length && <Divider sx={{ borderColor: theme.palette.grey[500], mt: '12px' }} />}
                        </Box>
                    ))}
            </Box>
        </Box>
    );
};

export default RelevantEventFinderResults;
