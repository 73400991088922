import * as React from 'react';
import Box from '@mui/material/Box';
import {
    Table,
    Popover,
    Avatar,
    CardMedia,
    Dialog,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    Drawer
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import share from 'assets/images/share.svg';
import ArchiveSVG from 'assets/images/archive.svg';
import EditSVG from 'assets/images/edit.svg';
import CheckSVG from 'assets/images/check.svg';
import moment from 'moment';
import DIalogSharedLink from './DIalogSharedLink';
import AddOrEditTask from './AddTask';
import { deleteStandaloneTask } from 'services/rest/workflow';
import { loadState } from 'utils/storage';
import { DialogTableDeliverables } from 'features/workflow-task-execution';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { completeTask } from 'services/rest/workflow_run';
import { useEffect } from 'react';

function EnhancedTableHead() {
    const theme = useTheme();

    return (
        <TableHead sx={{ borderBottom: `1px solid ${theme.palette.grey[500]}`, background: theme.palette.grey[700] }}>
            <TableRow>
                <TableCell align="left" sx={{ borderRight: `1px solid ${theme.palette.grey[500]}` }}>
                    {' '}
                    <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600 }}>
                        <FormattedMessage id="task_name" />
                    </Typography>
                </TableCell>
                <TableCell align="left" sx={{ borderRight: `1px solid ${theme.palette.grey[500]}` }}>
                    <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600, textAlign: 'center' }}>
                        <FormattedMessage id="status" />
                    </Typography>
                </TableCell>
                <TableCell align="left" sx={{ borderRight: `1px solid ${theme.palette.grey[500]}` }}>
                    <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600 }}>
                        <FormattedMessage id="due_date" />
                    </Typography>
                </TableCell>
                <TableCell align="left" sx={{ borderRight: `1px solid ${theme.palette.grey[500]}` }}>
                    <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600, textAlign: 'center' }}>
                        <FormattedMessage id="assignee" />
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600 }}>
                        <FormattedMessage id="brand" />
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600, display: 'none' }}>
                        <FormattedMessage id="date_added" />
                    </Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

interface FullTableRowProps {
    isItemSelected: boolean;
    row: any;
    organizationId: string;
    refresh: () => void;
    setSelectedTask: (task: any) => void;
    openDivider: boolean;
    setOpenDivider: (open: boolean) => void;
}

const FullTableRow = ({
    isItemSelected,
    row,
    organizationId,
    refresh,
    setSelectedTask,
    openDivider,
    setOpenDivider
}: FullTableRowProps) => {
    const theme = useTheme();
    const intl = useIntl();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [openDialogSharedLink, setOpenDialogSharedLink] = React.useState(false);
    const [linkShared, setLinkShared] = React.useState<any>();
    const [openWorkflowTaskDivider, setOpenWorkflowTaskDivider] = React.useState(false);
    const hendleOpenDialogSharedLink = (itemLink: any) => {
        setLinkShared(itemLink);
        setOpenDialogSharedLink(true);
        handleClose();
    };
    const handleClickCloseDivider = () => {
        setOpenDivider(false);
    };
    const handleOpenDivider = (task: any) => {
        setSelectedTask(task);
        if (task.type === 'standalone') {
            setOpenDivider(true);
        } else if (task.type === 'workflow') {
            setOpenWorkflowTaskDivider(true);
        }
    };
    const hendleCloseDialogSharedLink = () => {
        setOpenDialogSharedLink(false);
    };
    const handleClickPopover = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMarkAsCompleted = async (taskId: number) => {
        try {
            handleClose();
            await completeTask(taskId, organizationId);
            refresh();
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({ id: 'task_was_marked_as_completed' })
                })
            );
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({ id: 'unable_to_mark_task_as_completed' }),
                    variant: 'error' // or success
                })
            );
        }
    };

    const handleDelete = async (taskId: number) => {
        try {
            handleClose();
            await deleteStandaloneTask(taskId, organizationId);
            refresh();
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({ id: 'task_was_deleted' })
                })
            );
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({ id: 'unable_to_delete_task' }),
                    variant: 'error' // or success
                })
            );
        }
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const background = (status: string) => {
        let color: string;
        if (status === 'COMPLETED') {
            color = theme.palette.primary[100];
        } else if (status === 'ACTIVE') {
            color = theme.palette.primary[300];
        } else if (status === 'CLOSED') {
            color = theme.palette.primary[400];
        } else if (status === 'ACTIVE') {
            color = theme.palette.primary[500];
        } else color = theme.palette.grey[900];
        return color;
    };
    const fontColor = (status: string) => {
        let color: string;
        if (status === 'COMPLETED') {
            color = theme.palette.primary[600];
        } else if (status === 'ACTIVE') {
            color = theme.palette.primary[700];
        } else if (status === 'CLOSED') {
            color = theme.palette.primary[900];
        } else if (status === 'ACTIVE') {
            color = theme.palette.secondary[400];
        } else color = theme.palette.secondary[300];
        return color;
    };

    return (
        <>
            <TableRow hover role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row.taskName} selected={isItemSelected}>
                <TableCell
                    component="th"
                    scope="row"
                    sx={{ borderRight: `1px solid ${theme.palette.grey[500]}`, p: '8px 18px', maxWidth: '300px' }}
                >
                    <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center' }}>
                        <Typography
                            onClick={() => handleOpenDivider(row)}
                            sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500, cursor: 'pointer' }}
                        >
                            {row.title}
                        </Typography>
                        {row.type === 'workflow' && (
                            <Box
                                sx={{
                                    background: theme.palette.secondary[500],
                                    p: '3px 10px',
                                    borderRadius: '4px',
                                    ml: '10px',
                                    cursor: 'pointer'
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: theme.palette.background.paper,
                                        fontFamily: 'Inter',
                                        fontWeight: 500,
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        fontSize: '12px',
                                        width: '60px'
                                    }}
                                >
                                    {/* IAN Figure this out */}
                                    {row.idk}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </TableCell>
                <TableCell align="left" sx={{ borderRight: `1px solid ${theme.palette.grey[500]}`, p: '8px 18px' }}>
                    <Box
                        sx={{
                            width: '95%',
                            m: '0 auto',
                            p: '12px 0',
                            borderRadius: '100px',
                            background: background(row.status)
                        }}
                    >
                        <Typography sx={{ color: fontColor(row.status), fontFamily: 'Inter', fontWeight: 500, textAlign: 'center' }}>
                            <FormattedMessage id={row.status} />
                        </Typography>
                    </Box>
                </TableCell>
                <TableCell align="left" sx={{ borderRight: `1px solid ${theme.palette.grey[500]}`, p: '8px 18px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600 }}>
                            {moment(row.due_date).format('DD MMMM, YYYY')}
                        </Typography>
                        {row.due_date_status !== 'on_track' && (
                            <Box
                                sx={{
                                    background:
                                        row.due_date_status === 'overdue' ? theme.palette.secondary[600] : theme.palette.primary[500],
                                    borderRadius: '16px',
                                    p: '4px 8px'
                                }}
                            >
                                <Typography
                                    sx={{
                                        color:
                                            row.due_date_status === 'overdue' ? theme.palette.secondary[700] : theme.palette.secondary[400],
                                        fontFamily: 'Inter',
                                        fontWeight: 500,
                                        fontSize: '12px'
                                    }}
                                >
                                    <FormattedMessage id={row.due_date_status} />
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </TableCell>
                <TableCell align="left" sx={{ borderRight: `1px solid ${theme.palette.grey[500]}`, p: '8px 18px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: '32px', height: '32px' }}>
                            <Avatar sx={{ width: '32px', height: '32px' }} alt="Remy Sharp" src={row.user.image_url} />
                        </Box>
                        <Typography ml={1} sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500 }}>
                            {row.user.first_name}
                        </Typography>
                    </Box>
                </TableCell>
                <TableCell align="left" sx={{ p: '8px 18px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: '32px', height: '32px' }}>
                            <Avatar sx={{ width: '32px', height: '32px' }} alt="Remy Sharp" src={row?.client_organization?.logo_url} />
                        </Box>
                        <Typography ml={1} sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500 }}>
                            {row?.client_organization?.name}
                        </Typography>
                    </Box>
                </TableCell>
                <TableCell align="right" sx={{ p: '8px 18px' }}>
                    {row?.type === 'standalone' ? (
                        <Typography
                            onClick={handleClickPopover}
                            sx={{
                                color: theme.palette.grey[400],
                                fontFamily: 'Inter',
                                fontWeight: 600,
                                fontSize: '26px',
                                m: '-10px 20px 0 0',
                                cursor: 'pointer',
                                zIndex: 111
                            }}
                        >
                            ...
                        </Typography>
                    ) : (
                        <></>
                    )}
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClosePopover}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                    >
                        <Box sx={{ p: '20px' }}>
                            {/* <Box
                                onClick={() => hendleOpenDialogSharedLink(row)}
                                sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
                            >
                                <Box sx={{ width: '20px' }}>
                                    <CardMedia
                                        sx={{ objectFit: 'contain' }}
                                        component="img"
                                        width="18px"
                                        image={share}
                                        alt="alt image"
                                    />
                                </Box>

                                <Typography
                                    sx={{
                                        color: theme.palette.grey[400],
                                        fontFamily: 'Inter',
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        ml: '10px'
                                    }}
                                >
                                    <FormattedMessage id="share" />
                                </Typography>
                            </Box> */}
                            <Box
                                onClick={() => handleMarkAsCompleted(row.id)}
                                sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
                            >
                                <CardMedia
                                    sx={{ objectFit: 'contain', maxWidth: '18px' }}
                                    component="img"
                                    width="18px"
                                    image={CheckSVG}
                                    alt="alt image"
                                />
                                <Typography
                                    sx={{
                                        color: theme.palette.grey[400],
                                        fontFamily: 'Inter',
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        ml: '12px'
                                    }}
                                >
                                    <FormattedMessage id="mark_as_completed" />
                                </Typography>
                            </Box>
                            <Box
                                onClick={() => handleOpenDivider(row)}
                                sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center', mt: 2 }}
                            >
                                <CardMedia
                                    sx={{ objectFit: 'contain', maxWidth: '18px' }}
                                    component="img"
                                    width="18px"
                                    image={EditSVG}
                                    alt="alt image"
                                />
                                <Typography
                                    sx={{
                                        color: theme.palette.grey[400],
                                        fontFamily: 'Inter',
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        ml: '12px'
                                    }}
                                >
                                    <FormattedMessage id="edit" />
                                </Typography>
                            </Box>
                            <Box
                                onClick={() => handleDelete(row.id)}
                                sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center', mt: 2 }}
                            >
                                <CardMedia
                                    sx={{ objectFit: 'contain', maxWidth: '18px' }}
                                    component="img"
                                    width="18px"
                                    image={ArchiveSVG}
                                    alt="alt image"
                                />
                                <Typography
                                    sx={{
                                        color: theme.palette.grey[400],
                                        fontFamily: 'Inter',
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        ml: '12px'
                                    }}
                                >
                                    <FormattedMessage id="delete" />
                                </Typography>
                            </Box>
                        </Box>
                    </Popover>
                </TableCell>
                <Dialog
                    sx={{ '.MuiDialog-paper': { minWidth: { xs: '350px', sm: '562px' } } }}
                    onClose={hendleCloseDialogSharedLink}
                    open={openDialogSharedLink}
                >
                    <DIalogSharedLink handleClose={hendleCloseDialogSharedLink} linkShared={linkShared} />
                </Dialog>
                {/* <Drawer keepMounted anchor="right" open={openDivider} onClose={handleClickCloseDivider}>
                    <AddOrEditTask
                        handleClickCloseDivider={handleClickCloseDivider}
                        selectedTask={selectedTask}
                        refresh={refresh}
                        isOnEditMode
                    />
                </Drawer> */}
            </TableRow>
            {openWorkflowTaskDivider && (
                <Dialog
                    fullScreen
                    open={openWorkflowTaskDivider}
                    onClose={() => {
                        setOpenWorkflowTaskDivider(false);
                    }}
                    sx={{ '.MuiPaper-root': { p: 0 } }}
                >
                    <DialogTableDeliverables
                        taskId={row.id}
                        handleClose={() => {
                            setOpenWorkflowTaskDivider(false);
                            refresh();
                        }}
                    />
                </Dialog>
            )}
        </>
    );
};

export default function TasksTable({ item, refresh }: any) {
    const userInfo = loadState('user');
    const organizationId = userInfo.member.organizationId;
    const [selectedTask, setSelectedTask] = React.useState<any>();
    const [openDivider, setOpenDivider] = React.useState(false);

    useEffect(() => {
        console.log({ selectedTask });
    }, [selectedTask]);

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <TableContainer>
                        <Table sx={{ borderRadius: '8px 8px 0 0', minWidth: 750 }} aria-labelledby="tableTitle">
                            <EnhancedTableHead />
                            <TableBody>
                                {item.map((row: any, index: number) => (
                                    <FullTableRow
                                        row={row}
                                        key={index}
                                        isItemSelected={false}
                                        organizationId={organizationId}
                                        refresh={refresh}
                                        setSelectedTask={setSelectedTask}
                                        openDivider={openDivider}
                                        setOpenDivider={setOpenDivider}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Box>
            <Drawer keepMounted anchor="right" open={openDivider} onClose={() => setOpenDivider(false)}>
                <AddOrEditTask
                    handleClickCloseDivider={() => setOpenDivider(false)}
                    selectedTask={selectedTask}
                    refresh={refresh}
                    isOnEditMode
                />
            </Drawer>
        </>
    );
}
