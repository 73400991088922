import { MultiLanguageString } from '@sni4/snikpic-common';
import { useIntl } from 'react-intl';

interface FormatMultiLangStringProps {
    log?: string;
    multiLangString: MultiLanguageString;
}

export const FormatMultiLangString = ({ log, multiLangString }: FormatMultiLangStringProps) => {
    const intl = useIntl();
    const { locale } = intl;

    if (!multiLangString) return <>Undefined</>;

    return <>{multiLangString[locale as 'en' | 'fr'] || multiLangString.en}</>;
};

export const ExtractMultiLangString = (multiLangString: MultiLanguageString) => {
    const intl = useIntl();
    const { locale } = intl;

    if (!multiLangString) return undefined;

    return multiLangString[locale as 'en' | 'fr'] || multiLangString.en;
};
