import { Typography, Box, CardMedia } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import snickpicLogo from 'assets/images/snickpicLogo.svg';
import { FormattedMessage } from 'react-intl';
import { Button } from 'ui-component/extended/Button';
import React from 'react';
import { TabContent } from './TabContent';
import ConfigureDialog from './ConfigureDialog';
import { ReportTypeEnum } from '.';
import { ReportType } from './types';

interface TabFacebookProps {
    connection: boolean;
    isReportAvailable: boolean;
    title: string;
    report: ReportType | null;
    setConnection: any;
    reportType: ReportTypeEnum;
    isPublicReport: boolean;
}

export const TabFacebook = ({
    connection,
    isReportAvailable,
    title,
    report,
    setConnection,
    reportType,
    isPublicReport
}: TabFacebookProps) => {
    const theme = useTheme();
    const [configureDashboard, setConfigureDashboard] = React.useState(false);
    const [showConfigDialog, setShowConfigDialog] = React.useState(false);
    const handleCloseDialogConfigure = () => setShowConfigDialog(false);
    const handleOpenDialogConfigure = () => setShowConfigDialog(true);

    function generateErrorTextId(): string {
        if (isPublicReport) return 'not_able_to_fetch_report';
        if (!connection) return 'facebook_business_manager_not_found';
        if (!isReportAvailable) return 'not_able_to_fetch_report';
        return '';
    }

    return (
        <Box>
            {/* todo put back "connection" */}
            {connection && isReportAvailable ? (
                <TabContent title={title} report={report} reportType={reportType} />
            ) : (
                <Box
                    sx={{
                        border: { xs: '', sm: `1px solid ${theme.palette.dark[700]}` },
                        borderRadius: '8px',
                        boxShadow: { xs: '', sm: '0px 10px 16px rgba(0, 0, 0, 0.05)' },
                        p: 2
                    }}
                >
                    {configureDashboard ? (
                        <Box sx={{ mt: '7%' }}>
                            <Box sx={{ width: '97px', height: '97px', m: '0 auto' }}>
                                <CardMedia
                                    sx={{ objectFit: 'contain' }}
                                    component="img"
                                    width="18px"
                                    image={snickpicLogo}
                                    alt="alt image"
                                />
                            </Box>
                            <Typography
                                sx={{
                                    color: theme.palette.grey[600],
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    fontFamily: 'Inter',
                                    maxWidth: '300px',
                                    textAlign: 'center',
                                    m: '20px auto'
                                }}
                            >
                                <FormattedMessage id="configure_dashboard_to_get_started" />
                            </Typography>
                            <Box sx={{ width: '80px', m: '0 auto' }}>
                                <Button onClick={handleOpenDialogConfigure} title={<FormattedMessage id="configure" />} />
                            </Box>
                        </Box>
                    ) : (
                        <Box sx={{ mt: '7%' }}>
                            <Box sx={{ width: '97px', height: '97px', m: '0 auto' }}>
                                <CardMedia
                                    sx={{ objectFit: 'contain' }}
                                    component="img"
                                    width="18px"
                                    image={snickpicLogo}
                                    alt="alt image"
                                />
                            </Box>
                            <Typography
                                sx={{
                                    color: theme.palette.grey[600],
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    fontFamily: 'Inter',
                                    maxWidth: '300px',
                                    textAlign: 'center',
                                    m: '20px auto'
                                }}
                            >
                                <FormattedMessage id={generateErrorTextId()} />
                            </Typography>
                            {!isPublicReport && !connection && (
                                <Box sx={{ width: '80px', m: '0 auto' }}>
                                    <Button onClick={() => setConfigureDashboard(true)} title={<FormattedMessage id="connect" />} />
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            )}
            <ConfigureDialog
                openDialogIntegrate={showConfigDialog}
                handleCloseDialogAddClient={handleCloseDialogConfigure}
                setConnection={setConnection}
            />
        </Box>
    );
};
