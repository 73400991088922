import { FileIcon as ReactFileIcon, defaultStyles, DefaultExtensionType } from 'react-file-icon';

export function getFileExtensionFromUrl(url: string) {
    return url?.split(/[#?]/)[0]?.split('.')?.pop()?.trim();
}

export function getFileNameFromUrl(url: string) {
    return url.substring(url.lastIndexOf('/') + 1);
}

export function isImage(extension: string) {
    return ['jpg', 'jpeg', 'png', 'gif', 'svg'].includes(extension);
}

interface FileIconProps {
    extension: string;
}

export const FileIcon = ({ extension }: FileIconProps) => (
    <ReactFileIcon extension={extension} {...(extension && defaultStyles[extension as DefaultExtensionType])} />
);
