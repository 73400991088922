import { useState, useEffect } from 'react';
import { Typography, Box, Breadcrumbs, Grid, CircularProgress, CardMedia, Avatar, Divider } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import NotFoundImg from 'ui-component/Offer';
import { useLazyQuery } from '@apollo/client';
import { Button } from 'ui-component/extended/Button';
import { OfferType } from 'types/offer';
import { GET_USER_ORGANIZATIONS } from 'services/graphQL';
import StarRoundedIcon from '@mui/icons-material/StarRounded';

export interface VirtualEmployeeData {
    profile_picture: string;
    first_name: string;
    last_name: string;
    role: string;
    short_bio: string;
    bio: string;
    tags: string;
    price: number;
}

interface VirtualEmployeeCardProps {
    data: VirtualEmployeeData;
    handleViewClick: () => void;
}

const VirtualEmployeeCard = ({ data, handleViewClick }: VirtualEmployeeCardProps) => {
    const theme = useTheme();
    console.log({ data });

    return (
        <Box sx={{ border: `1px solid ${theme.palette.grey[500]}`, p: '20px', borderRadius: '8px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ position: 'relative' }}>
                    <Avatar sx={{ width: '48px', height: '48px' }} alt="Remy Sharp" src={data.profile_picture} />
                    <Box
                        sx={{
                            background: theme.palette.success[100],
                            border: '1.5px solid #fff',
                            width: '12px',
                            height: '12px',
                            borderRadius: '100px',
                            position: 'absolute',
                            bottom: 0,
                            right: '2px'
                        }}
                    >
                        {' '}
                    </Box>
                </Box>

                <Box ml={1}>
                    <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600, fontSize: '16px' }}>
                        {data.first_name}
                    </Typography>
                    <Typography sx={{ color: theme.palette.grey[300], fontFamily: 'Inter', fontWeight: 400, fontSize: '14px' }}>
                        {data.role}
                    </Typography>
                </Box>
            </Box>
            <Typography sx={{ color: theme.palette.grey[300], fontFamily: 'Inter', fontWeight: 400, fontSize: '16px', mt: 3, mb: 3 }}>
                {data.short_bio}
            </Typography>
            <Grid container spacing={1}>
                {data?.tags?.split(',').map((tag: string, index: number) => (
                    <Grid item key={index}>
                        <Typography
                            sx={{
                                color: theme.palette.grey[300],
                                fontFamily: 'Inter',
                                fontWeight: 600,
                                fontSize: '14px',
                                background: theme.palette.grey[700],
                                p: '6px 8px',
                                borderRadius: '4px'
                            }}
                        >
                            {tag}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
            {/* <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
                <StarRoundedIcon sx={{ fill: '#FDB022' }} />
                <Typography
                    sx={{
                        color: '#FDB022',
                        fontFamily: 'Inter',
                        fontWeight: 600,
                        fontSize: '14px',
                        ml: '4px'
                    }}
                >
                    {freelancer.rating}
                </Typography>
                <Typography
                    sx={{
                        color: theme.palette.grey[300],
                        fontFamily: 'Inter',
                        fontWeight: 400,
                        fontSize: '14px',
                        ml: '4px'
                    }}
                >
                    {`(${freelancer.worksCompleted} Jobs Done)`}
                </Typography>
            </Box> */}
            <Divider sx={{ mt: 3, mb: 3, background: theme.palette.grey[500] }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography
                    sx={{
                        color: theme.palette.grey[400],
                        fontFamily: 'Inter',
                        fontWeight: 600,
                        fontSize: '18px'
                    }}
                >
                    {`Starting from €${data.price}`}
                </Typography>
                <Button sx={{ width: '80px' }} onClick={handleViewClick} title={<FormattedMessage id="view" />} />
            </Box>
        </Box>
    );
};

export default VirtualEmployeeCard;
