import { Box, Typography } from '@mui/material';
import { memo } from 'react';
import { useTheme } from '@mui/material/styles';

import { FlowNode } from '../../features/document-builder/ui';
import { Handle, Position } from 'react-flow-renderer';

export default memo(({ data, isConnectable }: any) => {
    const theme = useTheme();

    return (
        <FlowNode sx={{ padding: '24px', gap: '8px', width: 'fit-content' }}>
            <Handle
                type="target"
                position={Position.Left}
                isConnectable={isConnectable}
                style={{ width: '1px', height: '1px', background: 'white' }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <Typography sx={{ color: theme.palette.text.primary, fontSize: '14px', fontWeight: 500, fontFamily: 'Inter' }}>
                    1. Copywirting
                </Typography>
            </Box>
        </FlowNode>
    );
});
