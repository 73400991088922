import * as React from 'react';
import { Dialog, Box, Typography } from '@mui/material';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useTheme } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import { Button } from 'ui-component/extended/Button';

const Transition = React.forwardRef((props: TransitionProps & { children: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => (
    <Slide direction="down" ref={ref} {...props} />
));

interface DialogTableProps {
    open: boolean;
    handleClose: () => void;
    handleConfirmStatus: () => void;
}
export default function DialogConfirmStatus({ open, handleClose, handleConfirmStatus }: DialogTableProps) {
    const theme = useTheme();

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            sx={{ '.MuiPaper-root': { p: 0, borderRadius: '16px' } }}
        >
            <Box p={5} sx={{ width: { xs: '100%', sm: '560px' } }}>
                <Typography
                    sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500, fontSize: '20px', textAlign: 'center' }}
                >
                    <FormattedMessage id="are_you_sure" />
                </Typography>
                <Typography
                    sx={{
                        color: theme.palette.grey[300],
                        fontFamily: 'Inter',
                        fontWeight: 400,
                        fontSize: '14px',
                        textAlign: 'center',
                        mt: 4
                    }}
                >
                    This workflow is still in progress
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <Box sx={{ width: '102px', mr: 2 }}>
                        <Button title="Yes, do it" onClick={handleConfirmStatus} variant="primary" />
                    </Box>
                    <Box sx={{ width: '102px' }}>
                        <Button title="No, don’t" onClick={handleClose} variant="secondary" />
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );
}
