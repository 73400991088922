import { Outlet } from 'react-router-dom';
import NotFound from 'views/pages/others/404';

const ErrorRoutes = {
    path: '/',
    element: <Outlet />,
    children: [
        {
            path: '*',
            element: <NotFound />
        }
    ]
};

export default ErrorRoutes;
