import { useIntl } from 'react-intl';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

export const useSnackbar = () => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();

    const showSuccessSnackbar = (arg: { message: string }) => {
        dispatch(
            openSnackbar({
                open: true,
                message: formatMessage({ id: arg.message })
            })
        );
    };

    const showErrorSnackbar = (arg: { message: string }) => {
        dispatch(
            openSnackbar({
                open: true,
                message: formatMessage({ id: arg.message }),
                variant: 'error'
            })
        );
    };

    return {
        showSuccessSnackbar,
        showErrorSnackbar
    };
};
