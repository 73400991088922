import React from 'react';

const LightningBoltSmall = () => (
    <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.75749 0.666626H5.66659L3.72719 4.54541H5.66659L2.03022 11.3333L2.75749 6.48481H0.333252L2.75749 0.666626Z"
            fill="white"
        />
    </svg>
);

export default LightningBoltSmall;
