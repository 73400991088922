import { useState, useEffect } from 'react';
import { Typography, Box, CardMedia, Divider, Popover } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import briefcasee from 'assets/images/briefcasee.svg';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import DialogAddPastExperience from './DIalogAddPastExperience';
import DIalogConfirmDelete from './DIalogConfirmDelete';

const PastExperience = ({ pastExperience, role }: any) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const [dialogConfirmDelete, setDialogConfirmDelete] = useState(false);
    const id = anchorEl ? 'simple-popover' : undefined;
    const [dialogAddPastExperience, setDialogAddPastExperience] = useState(false);
    const [selectedPastExperience, setSelectedPastExperience] = useState<any>();

    const openPopover = (event: any) => setAnchorEl(event.currentTarget);
    const closePopover = () => setAnchorEl(null);
    const handleCloseDialogConfirmDelete = () => setDialogConfirmDelete(false);
    const handleOpenDialogConfirmDelete = () => {
        setDialogConfirmDelete(true);
        closePopover();
    };
    const handleOpenDialogAddPastExperience = () => setDialogAddPastExperience(true);
    const handleCloseDialogAddPastExperience = () => setDialogAddPastExperience(false);
    const editPastExperience = (selectedItem: any) => {
        handleOpenDialogAddPastExperience();
        setSelectedPastExperience(selectedItem);
        closePopover();
    };
    return (
        <Box sx={{ width: { xs: '100%', sm: '80%', lg: '60%' }, mb: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '43px', height: '43px', borderRadius: '100px', background: theme.palette.orange[200] }}>
                    <Box sx={{ width: '20px', m: '12px' }}>
                        <CardMedia component="img" width="18px" image={briefcasee} alt="briefcase" />
                    </Box>
                </Box>
                <Box ml={1}>
                    <Box sx={{ display: 'flex' }}>
                        <Typography
                            sx={{
                                color: '#2D2D2D',
                                fontFamily: 'Inter',
                                fontWeight: 600,
                                fontSize: '15px'
                            }}
                        >
                            {`${pastExperience.title} - `}
                        </Typography>
                        <Typography
                            sx={{
                                color: '#2D2D2D',
                                fontFamily: 'Inter',
                                fontWeight: 600,
                                fontSize: '15px',
                                fontStyle: 'italic'
                            }}
                        >
                            {pastExperience.subtitle}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <Typography
                            sx={{
                                color: '#2D2D2D',
                                fontFamily: 'Inter',
                                fontWeight: 500,
                                fontSize: '13px',
                                fontStyle: 'italic',
                                mt: '4px'
                            }}
                        >
                            {pastExperience.dateStart}&nbsp;
                        </Typography>
                        <Typography
                            sx={{
                                color: '#2D2D2D',
                                fontFamily: 'Inter',
                                fontWeight: 500,
                                fontSize: '13px',
                                fontStyle: 'italic',
                                mt: '4px'
                            }}
                        >
                            {`- ${pastExperience.dateEnd}`}
                        </Typography>
                    </Box>
                </Box>
                {role === 'freelancer' && (
                    <Box ml={2} sx={{ mt: '-5px' }}>
                        <MoreVertOutlinedIcon onClick={openPopover} sx={{ cursor: 'pointer' }} />
                        <Popover
                            id={id}
                            open={!!anchorEl}
                            anchorEl={anchorEl}
                            onClose={closePopover}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                        >
                            <Box sx={{ p: '24px' }}>
                                <Box
                                    onClick={() => editPastExperience(pastExperience)}
                                    sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
                                >
                                    <Typography
                                        sx={{
                                            color: theme.palette.grey[400],
                                            fontFamily: 'Inter',
                                            fontWeight: 500,
                                            fontSize: '14px',
                                            mb: '10px'
                                        }}
                                    >
                                        <FormattedMessage id="edit" />
                                    </Typography>
                                </Box>
                                <Box
                                    onClick={handleOpenDialogConfirmDelete}
                                    mt={1}
                                    sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
                                >
                                    <Typography
                                        sx={{
                                            color: theme.palette.grey[400],
                                            fontFamily: 'Inter',
                                            fontWeight: 500,
                                            fontSize: '14px'
                                        }}
                                    >
                                        <FormattedMessage id="delete" />
                                    </Typography>
                                </Box>
                            </Box>
                        </Popover>
                    </Box>
                )}
            </Box>
            <Typography
                sx={{
                    color: theme.palette.grey[300],
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '135%',
                    mt: 2
                }}
            >
                {pastExperience.desc}
            </Typography>
            <DIalogConfirmDelete
                onClose={handleCloseDialogConfirmDelete}
                open={dialogConfirmDelete}
                title="are_you_sure_you_want_to_delete_this_item"
            />
            <DialogAddPastExperience
                onClose={handleCloseDialogAddPastExperience}
                openDialog={dialogAddPastExperience}
                selectedPastExperience={selectedPastExperience}
            />
        </Box>
    );
};

export default PastExperience;
