import { useState, useEffect } from 'react';
import { Typography, Box, Breadcrumbs, Grid, Avatar, CardMedia, Divider, Rating, Drawer } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Button } from 'ui-component/extended/Button';
import CreateProposal from './CreateProposal';
import DialogConfirm from './DialogConfirm';
import DIalogConfirmDelete from '../profile/feature/DIalogConfirmDelete';

interface PackageDetailsContainerProp {
    selectedPackege: any;
    back?: boolean;
    backFunction?: any;
    client?: any;
    editPackage?: boolean;
    setEditPackageValue?: any;
    coantractFreelancer?: boolean;
}

const PackageDetailsContainer = ({
    selectedPackege,
    back,
    backFunction,
    client,
    editPackage,
    setEditPackageValue,
    coantractFreelancer
}: PackageDetailsContainerProp) => {
    const theme = useTheme();
    const [createProposal, setCreateProposal] = useState(false);
    const [dialogConfirm, setDialogConfirm] = useState(false);
    const [dialogConfirmDelete, setDialogConfirmDelete] = useState(false);
    const [update, setUpdate] = useState(false);
    const [titleDialog, setTitleDialog] = useState('');
    const handleCloseCreateProposal = () => setCreateProposal(false);
    const handleOpenCreateProposal = () => setCreateProposal(true);
    const handleCloseDialogConfirm = () => setDialogConfirm(false);
    const closeDialogConfirmDelete = () => setDialogConfirmDelete(false);
    const openDialogConfirmDelete = () => setDialogConfirmDelete(true);
    const handleOpenDialogConfirm = (value: string) => {
        setDialogConfirm(true);
        if (value === 'pause') {
            setTitleDialog('title_dialog_confirm_pause');
        } else setTitleDialog('titl_dialog_confirm_terminate');
    };
    const handleUpdate = () => {
        setCreateProposal(true);
        setUpdate(true);
    };
    return (
        <Box>
            {createProposal ? (
                <CreateProposal
                    handleCloseCreateProposal={handleCloseCreateProposal}
                    selectedPackege={selectedPackege}
                    back={back || false}
                    backFunction={handleCloseCreateProposal}
                    update={update}
                />
            ) : (
                <Box sx={{ height: '100vh' }}>
                    <Box sx={{ height: 'calc(100vh - 107px)', overflowY: 'auto', p: 4 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {back && (
                                <ArrowBackIosNewIcon
                                    onClick={backFunction}
                                    sx={{ fill: theme.palette.grey[400], cursor: 'pointer', mr: 1, fontSize: '16px' }}
                                />
                            )}
                            <Typography
                                sx={{
                                    color: theme.palette.grey[400],
                                    fontFamily: 'Inter',
                                    fontWeight: 600,
                                    fontSize: '20px'
                                }}
                            >
                                {selectedPackege?.skil}
                            </Typography>
                        </Box>
                        {client && (
                            <Box>
                                <Typography
                                    sx={{
                                        color: theme.palette.grey[400],
                                        fontFamily: 'Inter',
                                        fontWeight: 600,
                                        fontSize: '14px',
                                        mt: 3,
                                        mb: 2
                                    }}
                                >
                                    <FormattedMessage id="client" />
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Avatar sx={{ width: 32, height: 32, mr: 1 }} src="" />
                                    <Typography
                                        sx={{
                                            color: theme.palette.grey[400],
                                            fontFamily: 'Inter',
                                            fontWeight: 500,
                                            fontSize: '14px'
                                        }}
                                    >
                                        {client}
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                        <Typography
                            sx={{
                                color: theme.palette.grey[400],
                                fontFamily: 'Inter',
                                fontWeight: 600,
                                fontSize: '14px',
                                mt: 3,
                                mb: 2
                            }}
                        >
                            <FormattedMessage id="description" />
                        </Typography>
                        <Typography
                            sx={{
                                color: theme.palette.grey[300],
                                fontFamily: 'Inter',
                                fontWeight: 400,
                                fontSize: '14px',
                                mb: 3
                            }}
                        >
                            {selectedPackege?.description}
                        </Typography>
                        {selectedPackege?.jobs.map((item: any, index: number) => (
                            <Box key={index} sx={{ border: `1px solid ${theme.palette.grey[500]}`, borderRadius: '8px', mb: 3 }}>
                                <Typography
                                    sx={{
                                        color: theme.palette.grey[400],
                                        fontFamily: 'Inter',
                                        fontWeight: 600,
                                        fontSize: '14px',
                                        p: 2,
                                        background: theme.palette.dark[100],
                                        borderRadius: '8px 8px 0 0'
                                    }}
                                >
                                    {item.title}
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        p: 2,
                                        justifyContent: client ? 'flex-start' : 'space-between',
                                        gap: client ? '100px' : ''
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            sx={{
                                                color: theme.palette.grey[400],
                                                fontFamily: 'Inter',
                                                fontWeight: 600,
                                                fontSize: '14px'
                                            }}
                                        >
                                            <FormattedMessage id="volume" />
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: theme.palette.grey[300],
                                                fontFamily: 'Inter',
                                                fontWeight: 400,
                                                fontSize: '14px',
                                                mt: 2
                                            }}
                                        >
                                            {item.volume}
                                        </Typography>
                                    </Box>
                                    {!client && (
                                        <Box>
                                            <Typography
                                                sx={{
                                                    color: theme.palette.grey[400],
                                                    fontFamily: 'Inter',
                                                    fontWeight: 600,
                                                    fontSize: '14px'
                                                }}
                                            >
                                                <FormattedMessage id="min_order_volume" />
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: theme.palette.grey[300],
                                                    fontFamily: 'Inter',
                                                    fontWeight: 400,
                                                    fontSize: '14px',
                                                    mt: 2
                                                }}
                                            >
                                                {item.minVolume}
                                            </Typography>
                                        </Box>
                                    )}
                                    <Box>
                                        <Typography
                                            sx={{
                                                color: theme.palette.grey[400],
                                                fontFamily: 'Inter',
                                                fontWeight: 600,
                                                fontSize: '14px'
                                            }}
                                        >
                                            <FormattedMessage id="rate" />
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: theme.palette.grey[300],
                                                fontFamily: 'Inter',
                                                fontWeight: 400,
                                                fontSize: '14px',
                                                mt: 2
                                            }}
                                        >
                                            {`€${item.price}`}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box p={2}>
                                    <Typography
                                        sx={{
                                            color: theme.palette.grey[400],
                                            fontFamily: 'Inter',
                                            fontWeight: 600,
                                            fontSize: '14px'
                                        }}
                                    >
                                        <FormattedMessage id="description" />
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: theme.palette.grey[300],
                                            fontFamily: 'Inter',
                                            fontWeight: 400,
                                            fontSize: '14px',
                                            mt: 2
                                        }}
                                    >
                                        {item.description && item.description}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    <Box
                        sx={{
                            p: 4,
                            borderTop: `1px solid ${theme.palette.grey[500]}`,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            height: '107px'
                        }}
                    >
                        <Typography
                            sx={{
                                color: theme.palette.grey[400],
                                fontFamily: 'Inter',
                                fontWeight: 600,
                                fontSize: '30px'
                            }}
                        >
                            {selectedPackege?.price}
                        </Typography>
                        {editPackage ? (
                            <Box sx={{ display: 'flex' }}>
                                <Button
                                    variant="secondary"
                                    sx={{ width: '90px', mr: 1 }}
                                    title={<FormattedMessage id="delete" />}
                                    onClick={openDialogConfirmDelete}
                                />
                                <Button
                                    sx={{ width: '70px' }}
                                    title={<FormattedMessage id="edit" />}
                                    onClick={() => setEditPackageValue(true)}
                                />
                            </Box>
                        ) : (
                            <Box>
                                {client ? (
                                    <Box>
                                        {!coantractFreelancer && (
                                            <Box sx={{ display: 'flex' }}>
                                                <Button
                                                    variant="secondary"
                                                    sx={{
                                                        width: '90px',
                                                        background: theme.palette.warning[200],
                                                        border: `1px solid ${theme.palette.warning[200]}`,
                                                        mr: 1
                                                    }}
                                                    title={<FormattedMessage id="terminate" />}
                                                    onClick={() => handleOpenDialogConfirm('terminate')}
                                                />
                                                <Button
                                                    variant="secondary"
                                                    sx={{ width: '60px', mr: 1 }}
                                                    title={<FormattedMessage id="pause" />}
                                                    onClick={() => handleOpenDialogConfirm('pause')}
                                                />
                                                <Button
                                                    sx={{ width: '70px' }}
                                                    title={<FormattedMessage id="update" />}
                                                    onClick={handleUpdate}
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                ) : (
                                    <Button
                                        sx={{ width: '160px' }}
                                        title={<FormattedMessage id="create_proposal" />}
                                        onClick={handleOpenCreateProposal}
                                    />
                                )}
                            </Box>
                        )}
                    </Box>
                </Box>
            )}
            <DialogConfirm open={dialogConfirm} onClose={handleCloseDialogConfirm} title={titleDialog} />
            <DIalogConfirmDelete
                onClose={closeDialogConfirmDelete}
                open={dialogConfirmDelete}
                title="are_you_sure_you_want_to_delete_the_package"
            />
        </Box>
    );
};

export default PackageDetailsContainer;
