import * as React from 'react';
import { Dialog, Box, Typography, CardMedia, Popover, RadioGroup, FormControlLabel, Radio, Slide } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import PopoverRadio from './components/PopoverRadio';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckIcon from '@mui/icons-material/Check';
import calendar from 'assets/images/calendar.svg';
import { Button } from 'ui-component/extended/Button';
import WorkflowDialogTable from './components/WorkflowDialogTable';
import DialogConfirmStatus from './components/DialogConfirmStatus';
import DialogTableDeliverables from './Deliverable';
import { getProcessmakerRequests, getTasksByProcessmakerRequestId } from 'services/rest/workflow_run';
import { DateTime } from 'luxon';
import { TaskDTO, WorkflowRun } from './types';
import { TransitionProps } from '@mui/material/transitions';
import useAuth from 'hooks/useAuth';
import { retryInterval } from 'stream-chat/dist/types/utils';

const Transition = React.forwardRef((props: TransitionProps & { children: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => (
    <Slide direction="down" ref={ref} {...props} />
));

interface DialogTableProps {
    open: boolean;
    handleClose: () => void;
    setShowContent: any;
    showContent: boolean;
    workflowId: string;
}
export default function DialogTable({ open, handleClose, setShowContent, showContent, workflowId }: DialogTableProps) {
    console.log('workflowId', workflowId);
    const theme = useTheme();
    const { member } = useAuth();

    const [anchorElStatus, setAnchorElStatus] = useState<any>(null);
    const [anchorElDatePicker, setAnchorElDatePicker] = useState<any>(null);
    const [selectedWorkflowRunIndex, setSelectedWorkflowRunIndex] = React.useState(0);
    const idDatePicker = anchorElDatePicker ? 'simple-popover' : undefined;
    const idStatus = anchorElStatus ? 'simple-popover' : undefined;
    const [openDialogStatus, setOpenDialogStatus] = React.useState(false);
    const [workflowRuns, setWorkflowRuns] = React.useState<WorkflowRun[]>([]);
    const [tasks, setTasks] = React.useState<TaskDTO[]>([]);
    const [selectedTaskId, setSelectedTaskId] = React.useState<number | null>(null);
    const [isFetchingWorkflowRunDetails, setIsFetchingWorkflowRunDetails] = React.useState(false);

    const handleSetSelectedTask = (taskId: number) => {
        setSelectedTaskId(taskId);
        setShowContent(false);
    };
    const handleCloseDialogStatus = () => setOpenDialogStatus(false);
    const handleOpenDialogStatus = () => setOpenDialogStatus(true);

    const handleChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleOpenDialogStatus();
        closePopoverStatus();
    };
    const handleConfirmStatus = () => {
        handleOpenDialogStatus();
        // setValueRadioStatus(valueRadioStatusSelected);
        handleCloseDialogStatus();
    };
    const handleChangeAnchorElDatePicker = async (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value);
        setSelectedWorkflowRunIndex(parseInt(event.target.value, 10));
        await handleChangeWorkflowRun(parseInt(event.target.value, 10));
        // setDatePickerValue((event.target as HTMLInputElement));
        closePopoverDatePicker();
    };
    const closePopoverStatus = () => setAnchorElStatus(null);
    const openPopoverStatus = (event: any) => setAnchorElStatus(event.currentTarget);
    const closePopoverDatePicker = () => setAnchorElDatePicker(null);
    const openPopoverDatePicker = (event: any) => setAnchorElDatePicker(event.currentTarget);

    React.useEffect(() => {
        console.log('rendering DialogTable');
        handleChangeWorkflowRun(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeWorkflowRun = async (workflowRunIndex: number) => {
        try {
            setIsFetchingWorkflowRunDetails(true);

            let workflowRunsData: WorkflowRun[] = [];

            if (workflowRuns.length === 0) {
                workflowRunsData = (await getProcessmakerRequests(workflowId, member?.organizationId))?.data?.workflow_runs;
            }

            if (!workflowRunsData) return;

            const tasksData = await fetchTasks(workflowRunIndex, workflowRunsData);

            setWorkflowRuns(workflowRunsData);
            setTasks(tasksData);
            setSelectedWorkflowRunIndex(workflowRunIndex);
        } finally {
            setIsFetchingWorkflowRunDetails(false);
        }
    };

    const fetchTasks = async (workflowRunIndex: number, workflowRunsData: WorkflowRun[]) => {
        const { data: tasksData } = await getTasksByProcessmakerRequestId(
            workflowRunsData[workflowRunIndex]?.processmaker_request_id,
            member?.organizationId
        );

        if (!tasksData) throw new Error('No tasks found');

        return tasksData;
    };

    const generateWorkflowRunTitle = (workflowRun: WorkflowRun) =>
        `${DateTime.fromISO(workflowRun.period.start_date).toFormat('dd LLL yyyy')} to ${DateTime.fromISO(
            workflowRun.period.end_date
        ).toFormat('dd LLL yyyy')}`;

    return (
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} sx={{ '.MuiPaper-root': { p: 0 } }}>
            {showContent ? (
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            p: 3,
                            borderBottom: `1px solid ${theme.palette.grey[500]}`
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                                sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500, fontSize: '16px', mr: 2 }}
                            >
                                Instagram Content Creation
                            </Typography>
                            {/* <Box>
                                <PopoverRadio
                                    anchorEl={anchorEl}
                                    id={id}
                                    closePopover={closePopover}
                                    openPopover={openPopover}
                                    value={valueRadioHeader}
                                    handleChange={handleChange}
                                    labels={labelsRadioHeader}
                                />
                            </Box> */}
                        </Box>
                        <Box onClick={handleClose} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                            <CloseIcon sx={{ fill: theme.palette.grey[400], fontize: '22px' }} />
                            <Typography
                                sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500, fontSize: '14px', ml: 2 }}
                            >
                                <FormattedMessage id="close" />
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderBottom: `1px solid ${theme.palette.grey[500]}`,
                            p: '4px 24px'
                        }}
                    >
                        <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500, fontSize: '16px', mr: 2 }}>
                            Workflow Run
                        </Typography>
                        <Box sx={{ display: 'flex', alignItmes: 'center' }}>
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ p: '0' }}>
                                    <Box
                                        sx={{
                                            background: theme.palette.grey[700],
                                            display: 'flex',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                            borderRadius: '8px',
                                            p: '8px'
                                        }}
                                        onClick={openPopoverDatePicker}
                                    >
                                        <Box sx={{ width: '20px' }}>
                                            <CardMedia
                                                sx={{ objectFit: 'contain' }}
                                                component="img"
                                                width="18px"
                                                image={calendar}
                                                alt="alt image"
                                            />
                                        </Box>
                                        <Typography
                                            ml={1}
                                            sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 400, fontSize: '14px' }}
                                        >
                                            {workflowRuns.length > 0
                                                ? generateWorkflowRunTitle(workflowRuns[selectedWorkflowRunIndex])
                                                : ''}
                                        </Typography>
                                        <KeyboardArrowDownIcon sx={{ fontSize: '25px', fill: theme.palette.grey[400], ml: '8px' }} />
                                    </Box>
                                    <Popover
                                        id={idDatePicker}
                                        open={!!anchorElDatePicker}
                                        anchorEl={anchorElDatePicker}
                                        onClose={closePopoverDatePicker}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left'
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left'
                                        }}
                                        sx={{
                                            '.MuiPaper-root': {
                                                boxShadow: '0px 10px 16px rgba(0, 0, 0, 0.1)',
                                                border: `1px solid ${theme.palette.grey[500]}`
                                            },
                                            '.MuiButtonBase-root': {
                                                mt: '-3px',
                                                mr: '10px'
                                            }
                                        }}
                                    >
                                        <Box>
                                            <RadioGroup
                                                value={
                                                    workflowRuns.length > 0
                                                        ? generateWorkflowRunTitle(workflowRuns[selectedWorkflowRunIndex])
                                                        : ''
                                                }
                                                onChange={handleChangeAnchorElDatePicker}
                                            >
                                                {workflowRuns.map((workflowRun, index) => (
                                                    <FormControlLabel
                                                        key={index}
                                                        value={index}
                                                        control={
                                                            <Radio
                                                                checkedIcon={<CheckIcon sx={{ fill: theme.palette.grey[400] }} />}
                                                                icon={<CheckIcon sx={{ fill: '#fff' }} />}
                                                            />
                                                        }
                                                        sx={{
                                                            borderBottom:
                                                                index + 1 !== workflowRuns.length
                                                                    ? `1px solid ${theme.palette.grey[500]}`
                                                                    : '',
                                                            width: '100%',
                                                            ml: 0,
                                                            alignItems: 'flex-start',
                                                            p: '16px 40px 16px 12px'
                                                        }}
                                                        label={
                                                            <Box>
                                                                <Box sx={{ display: 'flex' }}>
                                                                    <Box
                                                                        sx={{
                                                                            p: '8px 16px',
                                                                            borderRadius: '16px',
                                                                            background:
                                                                                workflowRun.status === 'Completed'
                                                                                    ? theme.palette.primary[100]
                                                                                    : '#FFD1CB',
                                                                            color:
                                                                                workflowRun.status === 'Completed'
                                                                                    ? theme.palette.primary[600]
                                                                                    : '#B0210E'
                                                                        }}
                                                                    >
                                                                        {workflowRun.status}
                                                                    </Box>
                                                                </Box>

                                                                <Typography
                                                                    sx={{
                                                                        color: theme.palette.grey[400],
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: 500,
                                                                        fontSize: '14px',
                                                                        mt: 1
                                                                    }}
                                                                >
                                                                    {generateWorkflowRunTitle(workflowRun)}
                                                                </Typography>
                                                            </Box>
                                                        }
                                                    />
                                                ))}
                                            </RadioGroup>
                                        </Box>
                                    </Popover>
                                </Box>
                                <Box ml={2}>
                                    <PopoverRadio
                                        anchorEl={anchorElStatus}
                                        id={idStatus}
                                        closePopover={closePopoverStatus}
                                        openPopover={openPopoverStatus}
                                        value={workflowRuns[selectedWorkflowRunIndex]?.status}
                                        handleChange={handleChangeStatus}
                                        labels={[]}
                                        sx={{ p: '12px 10px', borderRadius: '50px' }}
                                    />
                                </Box>
                                <Box ml={2}>
                                    <Button
                                        title="View Deliverables"
                                        disabled={isFetchingWorkflowRunDetails}
                                        onClick={() => {
                                            setSelectedTaskId(null);
                                            setShowContent(false);
                                        }}
                                        variant="primary"
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ p: '30px 16px' }}>
                        <WorkflowDialogTable tasks={tasks} setSelectedTask={handleSetSelectedTask} />
                    </Box>
                    {/* <DialogConfirmCheckbox
                        open={openDialogCheckbox}
                        handleClose={handleCloseDialogCheckbox}
                        setValueRadioHeader={setValueRadioHeader}
                    /> */}
                    <DialogConfirmStatus
                        open={openDialogStatus}
                        handleClose={handleCloseDialogStatus}
                        handleConfirmStatus={handleConfirmStatus}
                    />
                </Box>
            ) : (
                <></>
            )}
            {!showContent && selectedTaskId && <DialogTableDeliverables handleClose={handleClose} taskId={selectedTaskId} />}
            {!showContent && !selectedTaskId && (
                <DialogTableDeliverables
                    handleClose={handleClose}
                    workflowRunId={workflowRuns[selectedWorkflowRunIndex].processmaker_request_id}
                    documentVersionId={workflowRuns[selectedWorkflowRunIndex].data.document_version_id}
                />
            )}
        </Dialog>
    );
}
