// import { useEffect, useMemo, useState } from 'react';
// import { useChatContext } from 'stream-chat-react';
// import type { UserResponse } from 'stream-chat';
// import type { StreamChatType } from '../../types';
// import { useAdminPanelFormState } from './context/AdminPanelFormContext';
// import { ValidationError } from './ValidationError';
// import { useLazyQuery } from '@apollo/client';
// import { FETCH_ORGANIZATION_MEMBERS } from 'services/graphQL/queries/teams';
// import { FETCH_USERS_BY_PKS } from 'services/graphQL/queries/user';
// import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
// import PersonAddIcon from '@mui/icons-material/PersonAdd'; // Added Max
// import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'; // Added
import { Typography, Divider } from '@mui/material';
// import { useWorkspaceController } from '../../context/WorkspaceController';
import { useTheme } from '@mui/material/styles'; // Added Max
// import CheckboxFilter from 'ui-component/checkboxFilter/CheckboxFilter';

import { WhatsappPanelToolbar } from './WhatsappPanelToolbar';
import { WhatsappPanelUsers } from './WhatsappPanelUsers';

// Main component - Display tables to add and remove users
// -----------------------------------------------------------------------------------------------

export const WhatsappPanel = () => {
    // App context
    const theme = useTheme();

    // Final render
    // ............................................................................................
    return (
        <>
            <Typography
                mt={3}
                sx={{
                    fontWeight: 500,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px'
                }}
            >
                Whatsapp integration
            </Typography>
            <Typography
                // mt={1}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[600],
                    fontSize: '12px'
                }}
            >
                Phone numbers listed below will be able to send and receive all messages from this channel
            </Typography>
            <Divider sx={{ m: '20px 0' }} />
            <WhatsappPanelUsers />
            <WhatsappPanelToolbar />
        </>
    );
};
