import { Typography } from '@mui/material';

export const AuthTitle: React.FC = ({ children }) => (
    <Typography
        sx={{
            mt: 3,
            color: (theme) => theme.palette.grey[900],
            fontFamily: 'Inter',
            fontWeight: 700,
            fontSize: '24px',
            textAlign: 'center'
        }}
    >
        {children}
    </Typography>
);
