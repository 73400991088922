import * as React from 'react';
import { ProjectMemberType } from 'types/clients';

import { useTheme, styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Autocomplete, { AutocompleteCloseReason, autocompleteClasses } from '@mui/material/Autocomplete';
import InputBase from '@mui/material/InputBase';
import { Box, Avatar, Typography, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Button } from 'ui-component/extended/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import SearchIcon from '@mui/icons-material/Search';
import { get } from 'lodash';

interface PopperComponentProps {
    anchorEl?: any;
    disablePortal?: boolean;
    open: boolean;
}
const StyledAutocompletePopper = styled('div')(({ theme }) => ({
    [`& .${autocompleteClasses.paper}`]: {
        boxShadow: 'none',
        margin: 0,
        color: 'inherit',
        fontSize: 13
    },
    [`& .${autocompleteClasses.listbox}`]: {
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
        padding: 0,
        [`& .${autocompleteClasses.option}`]: {
            minHeight: 'auto',
            alignItems: 'flex-start',
            padding: 16,
            borderTop: `1px solid ${theme.palette.grey[500]}`,
            '&[aria-selected="true"]': {
                backgroundColor: 'transparent'
            },
            [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]: {
                backgroundColor: theme.palette.action.hover
            }
        }
    },
    [`&.${autocompleteClasses.popperDisablePortal}`]: {
        position: 'relative'
    }
}));

function PopperComponent(props: PopperComponentProps) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { disablePortal, anchorEl, open, ...other } = props;
    return <StyledAutocompletePopper {...other} />;
}

const StyledPopper = styled(Popper)(({ theme }) => ({
    border: `1px solid ${theme.palette.grey[500]} !important`,
    borderRadius: 8,
    margin: '4px 0 !important',
    width: '320px',
    zIndex: theme.zIndex.modal,
    fontSize: 13,
    color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
    backgroundColor: `${theme.palette.background.paper} !important`,
    '.MuiAutocomplete-popper': {
        width: '318px !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important'
    },
    '.MuiAutocomplete-paper': {
        borderRadius: '0 0 8px 8px'
    }
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
    padding: 10,
    width: 'calc(100% - 34px)',
    borderBottom: `none`,
    '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.background.paper,
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: `none`,
        fontSize: 14,
        '&:focus': {
            boxShadow: `none`,
            borderColor: 'none'
        }
    }
}));

interface AutoCompliteClientsProps {
    labels: any;
    buttonOpen?: boolean;
    pendingValue: any;
    setPendingValue: any;
    width?: boolean;
    setClients?: any;
    clients?: any;
    handleClickAutoCompleteItem?: (option: ProjectMemberType) => void;
    btn?: any;
    handleValueSelect?: any;
    placeholder?: string;
    imagePath?: string;
    labelPath?: string;
    lastNamePath?: string;
    singleSelect?: boolean;
}
export function AutoCompleteClients({
    labels,
    buttonOpen,
    pendingValue,
    setPendingValue,
    width,
    setClients,
    clients,
    handleClickAutoCompleteItem,
    btn,
    handleValueSelect,
    placeholder,
    singleSelect = false,
    imagePath = 'members[0]?.user.image_url', // TODO (Max) - I don't think the imagePath is correct, check correction I did for the lastNamePath and advise. I think it should be 'icon' (console.log(option) in getOptionLabel)
    labelPath = 'members[0]?.user.first_name', // TODO (Max) - I don't think the labelPatch is correct, check correction I did for the lastNamePath and advise. I think it should be 'lastName' (console.log(option) in getOptionLabel)
    lastNamePath = 'lastName'
}: AutoCompliteClientsProps) {
    console.log('In AutoCompleteClients');
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [value, setValue] = React.useState<LabelType[]>([]);
    const intl = useIntl();
    const theme = useTheme();
    React.useEffect(() => {
        setValue(labels);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleValue = (valueAutocomplete: any) => {
        if (singleSelect) {
            setClients([valueAutocomplete]);
        } else if (clients) {
            const arrClients = clients;
            if (!arrClients.length) {
                arrClients.push(valueAutocomplete);
            } else if (arrClients.length && JSON.stringify(arrClients).includes(JSON.stringify(valueAutocomplete)) === false)
                arrClients.push(valueAutocomplete);
            setClients([...arrClients]);
        }
        handleClickAutoCompleteItem?.(valueAutocomplete);
    };
    const handleClose = () => {
        setValue(pendingValue);
        if (anchorEl) {
            anchorEl.focus();
        }
        setAnchorEl(null);
    };
    const handleClickPopoverAddTeam = (event: any) => {
        event.stopPropagation();
        setPendingValue(value);
        setAnchorEl(event.currentTarget);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'github-label' : undefined;
    const handleClickOption = (option: any) => {
        handleValue(option);
        handleValueSelect?.(option);
    };

    return (
        <Box>
            <Box>
                {btn ? (
                    <Box onClick={handleClickPopoverAddTeam}>{btn}</Box>
                ) : (
                    <Box>
                        {buttonOpen ? (
                            <Box sx={{ width: { xs: '100%', sm: '220px' } }}>
                                <Button
                                    title={<FormattedMessage id="add_provided_service" />}
                                    icon={<AddIcon />}
                                    onClick={handleClickPopoverAddTeam}
                                />
                            </Box>
                        ) : (
                            <IconButton onClick={handleClickPopoverAddTeam}>
                                <AddIcon sx={{ color: theme.palette.grey[400] }} />
                            </IconButton>
                            // <Box
                            //     onClick={handleClickPopoverAddTeam}
                            //     sx={{
                            //         width: '32px',
                            //         height: '32px',
                            //         borderRadius: '100px',
                            //         background: theme.palette.grey[700],
                            //         cursor: 'pointer',
                            //         display: 'flex',
                            //         alignItmes: 'center'
                            //     }}
                            // >
                            //     <Typography
                            //         sx={{
                            //             color: theme.palette.grey[400],
                            //             fontFamily: 'Inter',
                            //             fontWeight: 300,
                            //             fontSize: '30px',
                            //             m: '-6px 0 0 6px'
                            //         }}
                            //     >
                            //         +
                            //     </Typography>
                            // </Box>
                        )}
                    </Box>
                )}
            </Box>

            <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
                <ClickAwayListener onClickAway={handleClose}>
                    <Box sx={{ zIndex: 1010 }}>
                        <Autocomplete
                            sx={{ width: width ? '300px' : '320px' }}
                            open
                            multiple
                            onClose={(event: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
                                if (reason === 'escape') {
                                    handleClose();
                                }
                            }}
                            value={pendingValue}
                            onChange={(event, newValue, reason) => {
                                if (
                                    event.type === 'keydown' &&
                                    (event as React.KeyboardEvent).key === 'Backspace' &&
                                    reason === 'removeOption'
                                ) {
                                    return;
                                }
                                setPendingValue(newValue);
                                handleClose();
                            }}
                            disableCloseOnSelect
                            PopperComponent={PopperComponent}
                            renderTags={() => null}
                            noOptionsText="No labels"
                            renderOption={(props, option) => (
                                <li {...props}>
                                    <Box
                                        key={`${get(option, labelPath, option.firstName || option.name)}`}
                                        onClick={() => handleClickOption(option)}
                                        sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
                                    >
                                        {/* {(option.icon || option.members) && ( */}
                                        <Box
                                            sx={{
                                                width: 32,
                                                height: 32,
                                                flexShrink: 0,
                                                borderRadius: '3px',
                                                mr: 1,
                                                mt: '2px',
                                                ml: '10px'
                                            }}
                                        >
                                            <Avatar
                                                sx={{ width: '32px', height: '32px' }}
                                                alt="Avatar"
                                                src={option.icon || get(option, imagePath)}
                                            />
                                        </Box>
                                        {/* )} */}
                                        <Box
                                            sx={{
                                                flexGrow: 1,
                                                '& span': {
                                                    color: theme.palette.grey[200]
                                                }
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: theme.palette.grey[400],
                                                    fontFamily: 'Inter',
                                                    fontWeight: 500,
                                                    ml: buttonOpen ? '8px' : '0px',
                                                    mt: buttonOpen ? '0px' : '10px'
                                                }}
                                            >
                                                {`${get(option, labelPath, option.firstName || option.name)} ${get(
                                                    option,
                                                    lastNamePath,
                                                    '' // If last name is undefined, display an empty string
                                                )}`}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </li>
                            )}
                            options={[...labels].sort((a, b) => {
                                // Display the selected labels first.
                                let ai = value.indexOf(a);
                                ai = ai === -1 ? value.length + labels.indexOf(a) : ai;
                                let bi = value.indexOf(b);
                                bi = bi === -1 ? value.length + labels.indexOf(b) : bi;
                                return ai - bi;
                            })}
                            getOptionLabel={(option) => {
                                console.log('option: ', option);
                                return `${get(option, labelPath, option.firstName || option.name)} vvv`;
                            }}
                            renderInput={(params) => (
                                <Box sx={{ display: 'flex', width: '320px', alignItem: 'center' }}>
                                    <SearchIcon
                                        sx={{
                                            m: '15px 0 0 20px',
                                            fill: theme.palette.grey[200]
                                        }}
                                    />
                                    <StyledInput
                                        ref={params.InputProps.ref}
                                        inputProps={params.inputProps}
                                        autoFocus
                                        placeholder={intl.formatMessage({ id: `${placeholder}` })}
                                    />
                                </Box>
                            )}
                        />
                    </Box>
                </ClickAwayListener>
            </StyledPopper>
        </Box>
    );
}

interface LabelType {
    firstName: string;
    icon?: string;
}
