/* eslint-disable react-hooks/rules-of-hooks */
import { MouseEventHandler, useEffect, useState, useMemo, useContext } from 'react';
import Box from '@mui/material/Box';
import {
    Table,
    Popover,
    CardMedia,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    IconButton,
    Dialog
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import zapoff from 'assets/images/zapoff.svg';
import edit2 from 'assets/images/edit2.svg';
import DialogConfirm from 'ui-component/dialogConfirm';
import { useMutation } from '@apollo/client';
import { UPDATE_WORKFLOW_STATUS } from 'services/graphQL/mutations/workflow';
import DocumentBuilder from './features/document-builder/CreateNew';
import DocumentBuilderContext from './features/document-builder/context/workflow.context';
import useDocumentBuilder from './features/document-builder/hooks/useDocumentBuilder';
import DialogTable from 'features/workflow-task-execution/WorkflowRun';
import DesignerSVG from 'assets/images/designer.svg';
import { ClientDetailsContext } from 'views/pages/clients/context/client-details.context';
import { stopWorkflow } from 'services/rest/workflow';
import { useSnackbar } from 'utils/snackbar.utils';
import CancelWorkflowRunsDialog from 'features/workflow-task-execution/components/CancelWorkflowRunsDialog';

function EnhancedTableHead() {
    const theme = useTheme();

    return (
        <TableHead sx={{ borderBottom: `1px solid ${theme.palette.grey[500]}`, background: theme.palette.grey[700] }}>
            <TableRow>
                <TableCell align="left">
                    {' '}
                    <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600 }}>
                        <FormattedMessage id="name" />
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600 }}>
                        <FormattedMessage id="status" />
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600 }}>
                        <FormattedMessage id="provided_service" />
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600, display: 'none' }}>
                        <FormattedMessage id="date_added" />
                    </Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

interface WorkflowsTableProps {
    workflows: any;
    setWorkflows: any;
    // refetch: () => Promise<any>;
}

interface WorkflowAction {
    action: MouseEventHandler;
    icon: JSX.Element;
    label: string;
}

interface FullTableRowProps {
    workflow: any;
}

const FullTableRow = ({ workflow }: FullTableRowProps) => {
    const { workflows, setWorkflows, updateWorkflows } = useContext(ClientDetailsContext);

    const [showDivider, setShowDivider] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState<any>(null);

    const [nameOffers, setNameOffers] = useState<any>();
    const [offerId, setOfferId] = useState('');
    const [showDocumentBuilder, setShowDocumentBuilder] = useState(false);
    const [nonAvailableFeatureModalIsOpen, setNonAvailableFeatureModalIsOpen] = useState(false);
    const builderContext = useDocumentBuilder(workflow);
    const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

    const theme = useTheme();

    const [actions, setActions] = useState<WorkflowAction[]>([
        {
            action: () => handleClickPopoverView(workflow),
            icon: <VisibilityOutlinedIcon />,
            label: 'view'
        },
        {
            action: () => console.log('deactivate'),
            icon: (
                <Box sx={{ width: '20px' }}>
                    <CardMedia sx={{ objectFit: 'contain' }} component="img" width="18px" image={zapoff} alt="alt image" />
                </Box>
            ),
            label: 'deactivate'
        }
    ]);

    const [updateWorkflowStatus] = useMutation(UPDATE_WORKFLOW_STATUS);

    useEffect(() => {
        // setBuilderContext(useDocumentBuilder(workflow));
        const viewAction = {
            action: () => handleClickPopoverView(workflow),
            icon: <VisibilityOutlinedIcon sx={{ width: '20px' }} />,
            label: 'view'
        };

        const activateAction = {
            action: async () => {
                await updateWorkflowStatus({
                    variables: {
                        id: workflow.id,
                        status: 'IN_PROGRESS'
                    }
                });

                setWorkflows([
                    ...workflows.filter((w: any) => w.id !== workflow.id),
                    {
                        ...workflow,
                        status: 'IN_PROGRESS'
                    }
                ]);
            },
            icon: <CardMedia sx={{ width: '18px' }} component="img" image={zapoff} alt="alt image" />,
            label: 'activate'
        };

        const editAction = {
            action: () => {
                setShowDocumentBuilder(true);
                // setNonAvailableFeatureModalIsOpen(true);
            },
            icon: <CardMedia sx={{ width: '18px' }} component="img" image={edit2} alt="alt image" />,
            label: 'edit'
        };

        const archiveAction = {
            action: async () => {
                try {
                    await updateWorkflowStatus({
                        variables: {
                            id: workflow.id,
                            status: 'ARCHIVED'
                        }
                    });
                    setWorkflows(workflows.filter((w: any) => w.id !== workflow.id));
                    showSuccessSnackbar({ message: 'workflow_was_successfully_archived' });
                } catch (e) {
                    showErrorSnackbar({ message: 'unable_to_archive_workflow' });
                }
            },
            icon: <CardMedia sx={{ width: '18px' }} component="img" image={zapoff} alt="alt image" />,
            label: 'archive'
        };

        // TODO: correct this

        const deactivateAction = {
            action: () => {
                setOpenCancelWorkflowRunsDialog(true);
            },
            icon: <CardMedia sx={{ width: '18px' }} component="img" image={zapoff} alt="alt image" />,
            label: 'deactivate'
        };

        switch (workflow.status) {
            case 'DRAFT':
                setActions([editAction, archiveAction]);
                break;
            case 'IN_PROGRESS':
                setActions([viewAction, editAction, deactivateAction]);
                break;
            case 'STOPPED':
                setActions([archiveAction]);
        }
    }, [workflow]);

    const getColorsForStatus = (status: string) => {
        switch (status) {
            case 'DRAFT':
                return {
                    color: theme.palette.secondary[400],
                    background: theme.palette.primary[500]
                };
            case 'IN_PROGRESS':
                return {
                    color: theme.palette.secondary[700],
                    background: theme.palette.success.main
                };
            case 'STOPPED':
                return {
                    color: theme.palette.secondary[700],
                    background: theme.palette.secondary[600]
                };
            default:
                return {
                    color: theme.palette.grey[400],
                    background: theme.palette.grey[700]
                };
        }
    };

    const id = anchorEl ? 'simple-popover' : undefined;
    const openPopover = (event: any) => setAnchorEl(event.currentTarget);
    const closePopover = () => setAnchorEl(null);
    const openDialog = () => setShowDialog(true);
    const closeDialog = () => setShowDialog(false);
    const openDivider = () => setShowDivider(true);
    const closeDivider = () => setShowDivider(false);
    const [showContent, setShowContent] = useState(true);
    const [open, setOpen] = useState(false);

    const [selectedWorkflowRunIdsToCancel, setSelectedWorkflowRunIdsToCancel] = useState<number[]>([]);
    const [openCancelWorkflowRunsDialog, setOpenCancelWorkflowRunsDialog] = useState(false);

    const handleSubmitCancelWorkflowRunsDialog = async () => {
        try {
            setOpenCancelWorkflowRunsDialog(false);
            await stopWorkflow({ workflowId: workflow.id, workflowRunIds: selectedWorkflowRunIdsToCancel });

            setWorkflows([
                ...workflows.filter((w: any) => w.id !== workflow.id),
                {
                    ...workflow,
                    status: 'STOPPED'
                }
            ]);
            showSuccessSnackbar({ message: 'workflow_was_successfully_deactivated' });
        } catch (e) {
            showErrorSnackbar({ message: 'unable_to_deactivate_workflow' });
            throw e;
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        if (!showContent) {
            setShowContent(true);
        } else setOpen(false);
    };
    const handleClickDeactive = (offer: any) => {
        setOfferId(offer.id);
        openDialog();
        closePopover();
    };

    const handleClickPopoverView = (itemOffers: any) => {
        setNameOffers(itemOffers);
        handleClickOpen();
        openDivider();
        closePopover();
    };

    return (
        <TableRow hover role="checkbox" tabIndex={-1}>
            <TableCell onClick={actions[0].action} component="th" scope="row" sx={{ width: '657px', cursor: 'pointer' }}>
                <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500 }}>{workflow.name}</Typography>
            </TableCell>
            <TableCell onClick={actions[0].action} align="left" sx={{ width: '157px', cursor: 'pointer' }}>
                <Box
                    sx={{
                        background: getColorsForStatus(workflow.status).background,
                        p: '8px 24px',
                        borderRadius: '16px',
                        height: '32px'
                    }}
                >
                    <Typography
                        sx={{
                            color: getColorsForStatus(workflow.status).color,
                            fontFamily: 'Inter',
                            fontWeight: 500,
                            textAlign: 'center',
                            fontSize: '12px'
                        }}
                    >
                        <FormattedMessage id={workflow.status.toLowerCase()} />
                    </Typography>
                </Box>
            </TableCell>
            <TableCell onClick={actions[0].action} align="left" sx={{ width: '357px', cursor: 'pointer' }}>
                <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500 }}>
                    {workflow.project?.name}
                </Typography>
            </TableCell>
            <TableCell align="right">
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box sx={{ width: '40px' }}>
                        <IconButton onClick={openPopover}>
                            <MoreHoriz />
                        </IconButton>
                        <Popover
                            id={id}
                            open={!!anchorEl}
                            anchorEl={anchorEl}
                            onClose={closePopover}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                        >
                            <Box sx={{ p: '8px 24px' }}>
                                {actions.map((action: WorkflowAction) => (
                                    <Box
                                        key={action.label}
                                        onClick={action.action}
                                        sx={{ display: 'flex', my: 2, cursor: 'pointer', alignItems: 'center' }}
                                    >
                                        {action.icon}
                                        <Typography
                                            sx={{
                                                color: theme.palette.grey[400],
                                                fontFamily: 'Inter',
                                                fontWeight: 500,
                                                fontSize: '14px',
                                                ml: '10px'
                                            }}
                                        >
                                            <FormattedMessage id={action.label} />
                                            {builderContext.workflowIsBeingEdited && action.label === 'edit' ? ' (continue)' : ''}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Popover>
                    </Box>
                </Box>
            </TableCell>
            {/* We need to have conditional rendering here otherwise worklflow runs are fetched everytime 
                we update the document builder which can be heavy if we have plenty of workflows */}
            {open && (
                <DialogTable
                    showContent={showContent}
                    open={open}
                    handleClose={handleClose}
                    setShowContent={setShowContent}
                    workflowId={workflow.id}
                />
            )}

            <Dialog
                open={showDocumentBuilder}
                // sx={{ '.MuiDialog-paper': { minWidth: { md: '100%', lg: '1444px' } } }}
                fullScreen
                onClose={() => {
                    setShowDocumentBuilder(false);
                    updateWorkflows();
                }}
                aria-labelledby="create-new-workflow-modal"
                keepMounted={false}
            >
                <DocumentBuilderContext.Provider value={builderContext}>
                    <DocumentBuilder
                        closeDialog={() => {
                            setShowDocumentBuilder(false);
                        }}
                    />
                </DocumentBuilderContext.Provider>
            </Dialog>

            <Dialog sx={{ '.MuiDialog-paper': { minWidth: { xs: '350px', sm: '562px' } } }} onClose={closeDialog} open={showDialog}>
                <DialogConfirm onConfirm={closeDialog} onClose={closeDialog} />
            </Dialog>

            <Dialog open={nonAvailableFeatureModalIsOpen}>
                <DialogConfirm
                    onConfirm={() => setNonAvailableFeatureModalIsOpen(false)}
                    image={DesignerSVG}
                    messageTextKey="workflow_editing_is_not_possible"
                    confirmTextKey="Close"
                />
            </Dialog>

            <CancelWorkflowRunsDialog
                open={openCancelWorkflowRunsDialog}
                handleClose={() => {
                    setOpenCancelWorkflowRunsDialog((_) => !_);
                }}
                workflowId={workflow.id}
                handleSuccess={handleSubmitCancelWorkflowRunsDialog}
                selectedIds={selectedWorkflowRunIdsToCancel}
                setSelectedIds={setSelectedWorkflowRunIdsToCancel}
            />
        </TableRow>
    );
};

export default function WorkflowTable() {
    const { workflows } = useContext(ClientDetailsContext);

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table sx={{ borderRadius: '8px 8px 0 0', minWidth: 750 }} aria-labelledby="tableTitle">
                        <EnhancedTableHead />
                        <TableBody>
                            {workflows.map((workflow: any, index: number) => (
                                <FullTableRow workflow={workflow} key={index} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
}
