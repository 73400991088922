import { gql } from '@apollo/client';

export const GET_CLIENT_CODE = gql`
    query GetFacebookClientCode {
        facebook_client_code {
            code
        }
    }
`;

export const GET_FACEBOOK_CONNECTION = gql`
    query GetFacebookConnection($organization_id: uuid) {
        organization_fb_connection(where: { organization_id: { _eq: $organization_id } }) {
            id
            fb_user_id
            business_manager_id
            organization_id
        }
    }
`;

export const GET_URL = gql`
    query GetUrl($client_organization_id: String!) {
        get_url_metadata(arg1: { client_organization_id: $client_organization_id }) {
            scopes {
                default
                instagram
                ad
                domain
                lead
                page
            }
            done
            aggregated
            business_id
            business_name
            agency_id
        }
    }
`;

export const GET_CLIENT_FACEBOOK_RESOURCES = gql`
    query GetClientFBResources($client_organization_id: uuid!) {
        client_fb_resources(where: { client_organization_id: { _eq: $client_organization_id } }) {
            status
            resource_type
            fb_name
            fb_id
            created_at
            connection_date
            client_organization_id
        }
    }
`;

export const VERIFY_RESOURCES = gql`
    query VerifyFBResources($client_organization_id: String!) {
        verify_facebook_resources(arg1: { client_organization_id: $client_organization_id }) {
            status
            resource_type
            fb_name
            fb_id
            created_at
            connection_date
            client_organization_id
        }
    }
`;
