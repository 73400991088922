import { Box, Typography, CardMedia } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from 'react';
import ButtonCustom from 'ui-component/extended/Button';
import fa_solid_robot from 'assets/images/fa_solid_robot.svg';
import SelectHistoryAiTools from './SelectHistoryAiTools';
import PreviousResult from './PreviousResult';
import AccordionResults from './AccordionResults';

interface PublicationConceptGeneratorResultsProps {
    handleResults: any;
    history?: boolean;
    resultsValue: ResultsValueType[];
    previousValue: PreviousValueType[];
    labels?: any;
    labelsPrevious?: any;
    handleSelectedHistory?: any;
    selectedHistory?: any;
}
interface PreviosuResult {
    brand_type: string;
    client_organization_id: string;
    period: string;
}
interface ResultsValueInputType {
    client_organization_id: string;
    brand_type: string;
    period: string;
}
interface ResultsValueOutputType {
    id: string;
    name: string;
    description: string;
    score: number;
    links: string[];
    date: string;
}
interface PreviousValueType {
    client_organization_id: string;
    id: string;
    organization_id: string;
    service: number;
    timestamp: string;
    input: ResultsValueInputType;
    output: ResultsValueOutputType[];
}
interface ResultsValueType {
    id: string;
    name: string;
    description: string;
    score: number;
    links: string[];
    date: string;
}
interface RenderResultsType {
    promotion: ResultsValueType[];
    inspiration: ResultsValueType[];
    quotes: ResultsValueType[];
}
const PublicationConceptGeneratorResults = ({
    handleResults,
    history,
    resultsValue,
    previousValue,
    labels,
    labelsPrevious,
    handleSelectedHistory,
    selectedHistory
}: PublicationConceptGeneratorResultsProps) => {
    const theme = useTheme();
    const [labelsHistoryDateSelector, setLabelsHistoryDateSelector] = React.useState<any>();
    const [valueHistoryDateSelector, setValueHistoryDateSelector] = React.useState('');
    const [renderResults, setRenderResults] = React.useState<RenderResultsType>({
        promotion: [],
        inspiration: [],
        quotes: []
    });
    const [resultsPrevious, setResultsPrevious] = React.useState<PreviosuResult>();

    console.log('renderResults', renderResults);
    console.log('previousValue', previousValue);
    console.log('resultsValue', resultsValue);
    React.useEffect(() => {
        const arrPromotion: ResultsValueType[] = [];
        const arrInspiration: ResultsValueType[] = [];
        const arrQuotes: ResultsValueType[] = [];
        if (resultsValue) {
            resultsValue.forEach((item: ResultsValueType) => {
                if (item.id === 'promotion') {
                    arrPromotion.push(item);
                } else if (item.id === 'inspiration') {
                    arrInspiration.push(item);
                } else if (item.id === 'quotes') {
                    arrQuotes.push(item);
                }
            });
        }
        setRenderResults({
            promotion: arrPromotion,
            inspiration: arrInspiration,
            quotes: arrQuotes
        });
        if (previousValue.length) {
            const arrLabels = previousValue.map((result: PreviousValueType) => result.input.period);
            setValueHistoryDateSelector(previousValue[0].input.period);
            setResultsPrevious(previousValue[0].input);
            setLabelsHistoryDateSelector(arrLabels);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultsValue]);

    const handleValueelectorDate = (value: string) => {
        setValueHistoryDateSelector(value);
        previousValue.forEach((item: PreviousValueType) => {
            if (value === item.input.period) {
                setResultsPrevious(item.input);
                const arrPromotion: ResultsValueType[] = [];
                const arrInspiration: ResultsValueType[] = [];
                const arrQuotes: ResultsValueType[] = [];
                item.output.forEach((itemOutput: ResultsValueType) => {
                    if (itemOutput.id === 'promotion') {
                        arrPromotion.push(itemOutput);
                    } else if (itemOutput.id === 'inspiration') {
                        arrInspiration.push(itemOutput);
                    } else if (itemOutput.id === 'quotes') {
                        arrQuotes.push(itemOutput);
                    }
                });
                setRenderResults({
                    promotion: arrPromotion,
                    inspiration: arrInspiration,
                    quotes: arrQuotes
                });
            }
        });
    };
    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: '18px 24px',
                    cursor: 'pointer',
                    borderBottom: `1px solid ${theme.palette.grey[500]}`
                }}
            >
                <Box
                    onClick={handleResults}
                    sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <ArrowBackIcon sx={{ fontSize: '26px', fill: theme.palette.success[300] }} />
                    <Typography ml={1} sx={{ color: theme.palette.success[300], fontFamily: 'Inter', fontWeight: 500, fontSize: '14px' }}>
                        <FormattedMessage id={history ? 'history' : 'results'} />
                    </Typography>
                </Box>
                {history ? (
                    <SelectHistoryAiTools
                        labels={labelsHistoryDateSelector}
                        state={valueHistoryDateSelector}
                        setState={handleValueelectorDate}
                        format="MMMM YYYY"
                    />
                ) : (
                    <Box sx={{ width: '160px' }}>
                        <ButtonCustom
                            onClick={handleResults}
                            titleBtn={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Box mr={1} sx={{ width: '20px' }}>
                                        <CardMedia
                                            sx={{ objectFit: 'contain' }}
                                            component="img"
                                            width="18px"
                                            image={fa_solid_robot}
                                            alt="alt image"
                                        />
                                    </Box>
                                    <Typography sx={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '14px' }}>
                                        <FormattedMessage id="ai_powered" />
                                    </Typography>
                                </Box>
                            }
                            colorBtn="red"
                        />
                    </Box>
                )}
            </Box>
            {history && resultsPrevious ? (
                <Box sx={{ p: '24px 24px 0 24px' }}>
                    <PreviousResult
                        resultsPrevious={resultsPrevious}
                        previousPublication
                        labelsResults={labels}
                        labels={labelsPrevious}
                        handleSelectedHistory={handleSelectedHistory}
                        selectedHistory={selectedHistory}
                    />
                </Box>
            ) : (
                <Box sx={{ p: '10px 24px' }}>
                    {labels
                        .filter((item: any) => item.active)
                        .map((label: any, index: number) => (
                            <Box key={index}>
                                <AccordionResults label={label} />
                            </Box>
                        ))}
                </Box>
            )}
        </Box>
    );
};

export default PublicationConceptGeneratorResults;
