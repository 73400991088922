import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ClockIcon from 'assets/images/clock.svg';
import WebhookIcon from 'assets/images/webhook.svg';
import { FC } from 'react';
import { WorkFlowTriggerType } from 'views/pages/clients/features/detail/workflow/features/types';

interface TriggerItemProps {
    type?: WorkFlowTriggerType;
}

const TriggerItem: FC<TriggerItemProps> = ({ type }) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                padding: '9px',
                cursor: 'pointer',
                borderRadius: '8px',
                backgroundColor: theme.palette.dark[100]
            }}
        >
            {type?.toUpperCase() === WorkFlowTriggerType.SCHEDULE && (
                <>
                    <img src={ClockIcon} style={{ width: '18px', height: '18px' }} alt="schedule" />
                    <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
                        At a scheduled time
                    </Typography>
                </>
            )}

            {type?.toUpperCase() === WorkFlowTriggerType.WEBHOOK && (
                <>
                    <img src={WebhookIcon} style={{ width: '18px', height: '18px' }} alt="webhook" />
                    <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
                        When webhook received
                    </Typography>
                </>
            )}
        </Box>
    );
};

export default TriggerItem;
