import React from 'react';
import { Box, Typography } from '@mui/material';
import { STATUS_ACTIVE } from 'utils/constants';

interface ProjectStatusProps {
    status: string;
}
export const ProjectStatus = ({ status }: ProjectStatusProps) => (
    <Box
        sx={{
            bgcolor: status === 'ACTIVE' ? '#B9E6FE' : '#F0F0F0',
            color: status === 'ACTIVE' ? '#065986' : '#666',
            fontSize: 12,
            padding: '8px 12px',
            borderRadius: 12
        }}
    >
        <Typography sx={{ textTransform: 'capitalize', fontSize: 12, fontWeight: 500 }}>
            {status === STATUS_ACTIVE ? 'running' : 'stopped'}
        </Typography>
    </Box>
);
