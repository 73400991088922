import React from 'react';
import { Box, Popover, Typography, RadioGroup, FormControlLabel, CardMedia, Radio, ClickAwayListener } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import calendar from 'assets/images/calendar.svg';
import moment from 'moment';
import { months } from 'utils/months';

interface SelectHistoryAiToolsProps {
    labels: any;
    state: string;
    setState: any;
    format: string;
}

export default function SelectHistoryAiTools({ labels, state, setState, format }: SelectHistoryAiToolsProps) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const theme = useTheme();

    const handleClick = (event: any) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const id = anchorEl ? 'simple-popover' : undefined;

    return (
        <Box
            sx={{
                background: theme.palette.grey[700],
                alignItems: 'center',
                cursor: 'pointer',
                borderRadius: '8px',
                p: '8px',
                display: labels ? 'flex' : 'none'
            }}
            aria-describedby={id}
            onClick={handleClick}
        >
            <Box sx={{ width: '20px' }}>
                <CardMedia sx={{ objectFit: 'contain' }} component="img" width="18px" image={calendar} alt="alt image" />
            </Box>
            {state && (
                <Box>
                    {+state > 1 ? (
                        <Typography ml={1} sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 400, fontSize: '14px' }}>
                            {`${months[+state - 1]} ${state}`}
                        </Typography>
                    ) : (
                        <Typography ml={1} sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 400, fontSize: '14px' }}>
                            {moment(state).format(format)}
                        </Typography>
                    )}
                </Box>
            )}

            <KeyboardArrowDownIcon sx={{ fontSize: '25px', fill: theme.palette.grey[400], ml: '8px' }} />
            <Popover
                sx={{
                    zIndex: '2222300',
                    '.MuiPopover-paper': {
                        boxShadow: '0px 10px 16px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px',
                        border: `1px solid ${theme.palette.grey[500]}`
                    }
                }}
                id={id}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <Box sx={{ width: '190px' }}>
                        <RadioGroup
                            value={state}
                            onChange={(e) => {
                                setState(e.target.value);
                                setAnchorEl(null);
                            }}
                            aria-label="position"
                            name="position"
                            defaultValue="top"
                            sx={{ display: 'flex', alignItems: 'start' }}
                        >
                            {labels &&
                                labels.map((label: string, index: string) => (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: state === label ? '10px 10px' : '10px 30px',
                                            borderBottom: index + 1 !== labels.length ? `1px solid ${theme.palette.grey[500]}` : '',
                                            width: '100%',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {state === label && <CheckIcon sx={{ fill: theme.palette.grey[400], fontSize: '20px' }} />}
                                        <FormControlLabel
                                            sx={{
                                                '.MuiFormControlLabel-label': {
                                                    color: theme.palette.grey[400],
                                                    fontFamily: 'Inter',
                                                    fontWeight: 500,
                                                    fontSize: '14px'
                                                }
                                            }}
                                            value={label}
                                            control={<Radio value={label} sx={{ display: 'none' }} />}
                                            label={+label > 1 ? `${months[+label - 1]} ${label}` : moment(label).format(format)}
                                            labelPlacement="start"
                                        />
                                    </Box>
                                ))}
                        </RadioGroup>
                    </Box>
                </ClickAwayListener>
            </Popover>
        </Box>
    );
}
