import { Box, useTheme } from '@mui/system';
import React from 'react';
import InstagramIcon from '@mui/icons-material/Instagram';
import { CardMedia, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { WORKFLOW_NODE_TYPES } from 'views/pages/clients/features/detail/workflow/features/types';
import { availableAutomationNodes } from 'features/workflow-automation/types';
import SVG from 'react-inlinesvg';

interface AutomationNodeDraggableItemListProps {
    onDragStart: (event: any, nodeType: any) => void;
}

export const AutomationNodeDraggableItemList = ({ onDragStart }: AutomationNodeDraggableItemListProps) => {
    const theme = useTheme();

    return (
        <>
            {availableAutomationNodes().map((availableAutomation, index) => (
                <Box
                    onDragStart={(event: any) => onDragStart(event, availableAutomation.automation_set)}
                    draggable
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'grab',
                        p: '16px 24px',
                        borderBottom: ` 1px solid ${theme.palette.grey[500]}`,
                        background: '#fff'
                    }}
                    key={index}
                >
                    <SVG src={availableAutomation.icon} width="20px" />

                    <Typography
                        sx={{
                            color: theme.palette.grey[400],
                            fontFamily: 'Inter',
                            fontWeight: 500,
                            fontSize: '14px',
                            ml: 1
                        }}
                    >
                        {/* <FormattedMessage id={availableAutomation.getDefaultConfiguration().id} /> */}
                        Hello
                    </Typography>
                </Box>
            ))}
        </>
    );
};
