import axios from 'axios';

export const uploadFile = async (url: string, file: File) => {
    try {
        const response = await axios.put(url, file);
        console.log(response);
    } catch (e) {
        console.log(JSON.stringify(e));
    }
};
