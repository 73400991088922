import axios, { AxiosInstance } from 'axios';

// Axios client
const baseURL = process.env.REACT_APP_SNIKPIC_BASE_URL;

// Utils
const generateHeaders = () => ({
    authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || `{}`)?.tokens?.access_token ?? ''}`
});

// Reset session
interface ResetSessionInput {
    channelId: string;
}

interface UpdateChannelStateInput {
    channelId: string;
    virtualEmployeeId: string | null;
    brandId: string | null;
    taskId: string | null;
}

export const resetSession = async (input: ResetSessionInput) => {
    try {
        const client: AxiosInstance = axios.create({ baseURL, headers: generateHeaders() });
        const endpoint = '/chat-bot/reset-session';
        const data = input;
        return await client.post(endpoint, data);
    } catch (e) {
        const err: any = e;
        console.error(err.response.data);
        throw new Error(err.response.data.extensions.message);
    }
};

export const updateChannelState = async (input: UpdateChannelStateInput) => {
    try {
        const client: AxiosInstance = axios.create({ baseURL, headers: generateHeaders() });
        const endpoint = '/chat-bot/update-channel-chatbot-state';
        const data = input;
        return await client.post(endpoint, data);
    } catch (e) {
        const err: any = e;
        console.error(err.response.data);
        throw new Error(err.response.data.extensions.message);
    }
};
