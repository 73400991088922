import FacebookConnection from 'views/pages/facebookConnection';
import MissingInstagramHelp from 'views/pages/facebookConnection/MissingInstagramHelp';

const FacebookRoute = {
    children: [
        {
            path: '/facebookconnection/:organizationId',
            element: <FacebookConnection />
        },
        {
            path: '/help',
            element: <MissingInstagramHelp />
        }
    ]
};

export default FacebookRoute;
