import { Edge, Node, ReactFlowInstance } from 'react-flow-renderer';
import { WORKFLOW_NODE_TYPES } from '../../../types';
import { v4 as uuid } from 'uuid';
import { AutomationNodeHelper } from 'features/workflow-automation/utils/automation-node.util';
import { TaskNodeHelper } from 'features/workflow/workflow-builder/nodes/Task/TaskNodeHelper';
import { ApprovalTaskNodeHelper } from 'features/workflow/workflow-builder/nodes/ApprovalTask/ApprovalTaskNodeHelper';

interface ICreateNodes {
    event: MouseEvent;
    reactFlowBounds: DOMRectReadOnly;
    type: string;
    getId: () => string;
    reactFlowInstance: ReactFlowInstance;
    setNodes: (nodes: Node[] | ((nodes: Node[]) => Node[])) => void;
    setEdges: (edges: Edge[] | ((edges: Edge[]) => Edge[])) => void;
}

const createNodes = ({ event, reactFlowBounds, type, getId, reactFlowInstance, setNodes, setEdges }: ICreateNodes) => {
    let triggerNode: any;
    let newNode: any;

    const position = reactFlowInstance!.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top
    });

    // if (type === WORKFLOW_NODE_TYPES.SCHEDULE_NODE || type === WORKFLOW_NODE_TYPES.WEBHOOK_NODE) {
    // eslint-disable-next-line no-constant-condition
    if (false) {
        const idNode = getId();
        const idTrigger = `react-flow-node_${uuid()}__${WORKFLOW_NODE_TYPES.TRIGGER_NODE}-1`;

        triggerNode = {
            id: idTrigger,
            type: WORKFLOW_NODE_TYPES.TRIGGER_NODE,
            position: { x: 100, y: 100 },
            data: { type }
        };

        const newNode = {
            id: idNode,
            type,
            position: { x: 100, y: 300 },
            data: { label: `${type} node` }
        };

        const newEdges = {
            id: `${Date.now()}`,
            source: idTrigger,
            target: idNode
        };

        setNodes((nds) => nds.concat(triggerNode, newNode));

        setEdges((nds) => nds.concat(newEdges));
    } else if (type === WORKFLOW_NODE_TYPES.TASK_NODE) {
        newNode = TaskNodeHelper.createNode({ position });
        setNodes((nds) => nds.concat(newNode));
    } else if (type === WORKFLOW_NODE_TYPES.APPROVAL_NODE) {
        newNode = ApprovalTaskNodeHelper.createNode({ position });
        setNodes((nds) => nds.concat(newNode));
    } else if (type.startsWith('automation_')) {
        newNode = AutomationNodeHelper.createNode({ position, automationSet: type as WORKFLOW_NODE_TYPES });
        setNodes((nds) => nds.concat(newNode));
    } else {
        newNode = {
            id: getId(),
            type,
            position,
            data: {
                document_permissions: [],
                label: `${type} node`,
                getId
            }
        };

        setNodes((nds) => nds.concat(newNode));
    }
};

export default createNodes;
