import { useMemo, useState, useContext, useCallback, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import update from 'immutability-helper';
import { ReactComponent as DragThumbIcon } from 'assets/images/dragThumb.svg';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SelectCustom from 'ui-component/selectCustom';
import { CustomTextField } from '../ui';
import DropdownItem from './dropdownItem';
import DocumentBuilderContext from '../context/workflow.context';
import useValidate from '../hooks/useValidate';

import { Field, FIELD_TYPE, FIELD_TYPE_NAME, ItemTypes } from '../../types';

import { useDrag, useDrop } from 'react-dnd';

interface FieldProps {
    fieldIndex: number;
    sectionIndex: number;
    onHover: (index: number) => void;
}
interface FieldHeaderProps {
    title: string;
}
const FieldHeader = ({ title }: FieldHeaderProps) => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', width: '100%', padding: '0 32px 8px 30px' }}>
        <Box sx={{ fontWeight: 500 }}>{title}</Box>
        <Box sx={{ flex: 1, height: '1px', backgroundColor: '#E0E0E0' }}> </Box>
    </Box>
);

export interface FieldItemData {
    field: Field;
    originalIndex: number;
}

const FieldElement = ({ fieldIndex, sectionIndex, onHover }: FieldProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [active, setActive] = useState<boolean>(false);
    const {
        pageIndex,
        documentBuilder,
        setDocumentBuilder,
        moveField,
        findField,
        updateFieldName,
        updateFieldValue,
        changeFieldType,
        removeField
    } = useContext(DocumentBuilderContext);
    const { error, validateField } = useValidate({ documentBuilder, pageIndex, sectionIndex, fieldIndex });
    const fieldData = documentBuilder.pages[pageIndex].pageSections[sectionIndex].sectionFields[fieldIndex];
    const originalIndex = findField(sectionIndex, fieldData).index;
    const items = useMemo(
        () => documentBuilder.pages[pageIndex].pageSections[sectionIndex].sectionFields[fieldIndex]?.fieldItems ?? [],
        [documentBuilder, pageIndex, sectionIndex, fieldIndex]
    );

    const [, drag] = useDrag(
        () => ({
            type: ItemTypes.FIELD,
            item: { field: fieldData, originalIndex },
            collect: (monitor) => ({
                isDragging: monitor.isDragging()
            }),
            end: (item, monitor) => {
                const { field: droppedSection, originalIndex: idx } = item;
                const didDrop = monitor.didDrop();
                if (!didDrop) {
                    moveField(sectionIndex, droppedSection, idx);
                }
            }
        }),
        [sectionIndex, fieldData, originalIndex, moveField]
    );

    const [, drop] = useDrop(
        () => ({
            accept: ItemTypes.FIELD,
            hover({ field: draggedField }: FieldItemData) {
                if (draggedField !== fieldData) {
                    const { index: overIndex } = findField(sectionIndex, fieldData);
                    moveField(sectionIndex, draggedField, overIndex);
                }
            }
        }),
        [sectionIndex, findField, moveField]
    );

    const [, newFieldDrop] = useDrop(
        () => ({
            accept: ItemTypes.FIELD_NEW,
            hover({ field: draggedField }: FieldItemData) {
                onHover(fieldIndex);
            }
        }),
        [onHover, fieldIndex, documentBuilder]
    );

    const findItem = useCallback(
        (item: string) => {
            const f = {
                item,
                index: items.indexOf(item)
            };
            return f;
        },
        [items]
    );

    const moveItem = useCallback(
        (item: string, atIndex: number) => {
            const { item: resItem, index } = findItem(item);
            setDocumentBuilder(
                update(documentBuilder, {
                    pages: {
                        [pageIndex]: {
                            pageSections: {
                                [sectionIndex]: {
                                    sectionFields: {
                                        [fieldIndex]: {
                                            fieldItems: {
                                                $splice: [
                                                    [index, 1],
                                                    [atIndex, 0, resItem]
                                                ]
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                })
            );
        },
        [findItem, setDocumentBuilder, sectionIndex, fieldIndex, pageIndex, documentBuilder]
    );

    const [, tableItemDrop] = useDrop(() => ({ accept: ItemTypes.TABLE_ITEM }));

    const [, dropdownItemDrop] = useDrop(() => ({ accept: ItemTypes.DROPDOWN_ITEM }));

    const handleMouseEnter = () => {
        setActive(true);
    };

    const handleMouseOut = () => {
        setActive(false);
    };

    const handleDelete = () => {
        removeField(sectionIndex, fieldData.fieldId);
    };

    drag(newFieldDrop(ref));
    return (
        <Box ref={ref} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseOut} sx={{ margin: '24px 12px' }}>
            <FieldHeader title={FIELD_TYPE_NAME[fieldData.fieldType as FIELD_TYPE]} />
            <Box ref={drop}>
                <Box sx={{ position: 'relative', display: 'flex', alignItems: 'flex-start', gap: '16px', padding: '0 30px' }}>
                    {active && <DragThumbIcon style={{ position: 'absolute', left: 0, top: '14px', cursor: 'pointer' }} />}
                    <Box sx={{ flex: 1 }}>
                        <Typography variant="caption">Field name</Typography>
                        <CustomTextField
                            error={error}
                            onBlur={validateField}
                            name={`[${pageIndex}].pageSections[${sectionIndex}].sectionFields[${fieldIndex}].fieldName`}
                            value={documentBuilder.pages[pageIndex].pageSections[sectionIndex].sectionFields[fieldIndex].fieldName}
                            onChange={(e) => updateFieldName(sectionIndex, fieldIndex, e)}
                            variant="outlined"
                            fullWidth
                            placeholder="Name"
                        />
                    </Box>
                    <Box sx={{ width: '180px', display: 'flex', alignItems: 'center', gap: '16px', position: 'absolute', right: '-10px' }}>
                        {active && (
                            <>
                                <SelectCustom
                                    value={fieldData.fieldType}
                                    setValue={(type: string) => changeFieldType(sectionIndex, fieldIndex, type)}
                                    labels={Object.values(FIELD_TYPE)}
                                />
                                <RemoveCircleOutlineIcon sx={{ cursor: 'pointer' }} onClick={handleDelete} />
                            </>
                        )}
                    </Box>
                </Box>
                <Box sx={{ flex: 1, margin: '10px 30px 8px 30px' }}>
                    {fieldData.fieldType === FIELD_TYPE.TABLE && (
                        <>
                            <Typography variant="caption">Maximum number of items the user can specify</Typography>
                            <CustomTextField
                                error={error}
                                onBlur={validateField}
                                name={`[${pageIndex}].pageSections[${sectionIndex}].sectionFields[${fieldIndex}].fieldValue`}
                                value={documentBuilder.pages[pageIndex].pageSections[sectionIndex].sectionFields[fieldIndex].fieldValue}
                                // TODO: update field value
                                onChange={(e) => updateFieldValue(sectionIndex, fieldIndex, e)}
                                type="number"
                                // InputProps={{
                                //     startAdornment: <InputAdornment position="start">Maximum number of items</InputAdornment>
                                // }}
                                variant="outlined"
                                fullWidth
                            />
                        </>
                    )}
                </Box>
            </Box>

            {fieldData.fieldType === FIELD_TYPE.DROPDOWN && (
                <Box ref={dropdownItemDrop} sx={{ margin: '4px 30px 0 30px', display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <Typography variant="caption">Dropdown Options</Typography>
                    {fieldData.fieldItems?.map((item: string, index: number) => (
                        <DropdownItem
                            isLast={index === (fieldData.fieldItems?.length ?? 0) - 1}
                            sectionIndex={sectionIndex}
                            fieldIndex={fieldIndex}
                            itemIndex={index}
                            itemType={ItemTypes.DROPDOWN_ITEM}
                            moveItem={moveItem}
                            findItem={findItem}
                        />
                    ))}
                </Box>
            )}
            {fieldData.fieldType === FIELD_TYPE.TABLE && (
                <Box ref={tableItemDrop} sx={{ margin: '4px 30px 0 30px', display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <Typography variant="caption">Column titles</Typography>
                    {fieldData.fieldItems?.map((item: string, index: number) => (
                        <DropdownItem
                            isLast={index === (fieldData.fieldItems?.length ?? 0) - 1}
                            sectionIndex={sectionIndex}
                            fieldIndex={fieldIndex}
                            itemIndex={index}
                            itemType={ItemTypes.TABLE_ITEM}
                            moveItem={moveItem}
                            findItem={findItem}
                        />
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default FieldElement;
