import { useState, ChangeEventHandler } from 'react';
import { ValidationError } from './ValidationError';

import { useMutation, useLazyQuery } from '@apollo/client';
import { GET_USER, GENERATE_ASSET_URL, GET_USER_ORGANIZATIONS } from 'services/graphQL';
import { UPDATE_USER_PASSWORD, UPDATE_USER_INFO, ADD_LOGO_TO_USER } from 'services/graphQL/mutations';

import { uploadFile } from 'services/rest';

import { Avatar, CircularProgress, Dialog, Box } from '@mui/material';

import { EditImageButton } from 'views/pages/clients/components/edit-image-button';
import { CropDialog } from 'views/pages/clients/components/crop-dialog';

import { useTheme } from '@mui/material/styles';

import { updateChannelImage } from '../../../../../services/rest/stream';

import { useChatContext } from 'stream-chat-react';
import { StreamChatType } from '../../types';

import ChatPreviewPlaceholderImage from '../../assets/newImages/sidebar/ChatPreviewPlaceholderImage.svg';

// type ChannelNameInputProps = {
//     name: string;
//     error: string | null;
//     onChange: ChangeEventHandler<HTMLInputElement>;
//     placeholder?: string;
// };

// export const ChannelImageInputField = ({ name = '', error, placeholder = 'channel-name', onChange }: ChannelNameInputProps) => (
export const ChannelImageInputField = () => {
    const theme = useTheme();

    const [showCropModal, setShowCropModal] = useState(false);
    const closeCropModal = () => setShowCropModal(false);
    const openCropModal = () => setShowCropModal(true);

    const [imageCrop, setImageCrop] = useState('');
    const [uploading, setUploading] = useState(false);

    const [generateURL] = useMutation(GENERATE_ASSET_URL);
    const [addLogoToUser] = useMutation(ADD_LOGO_TO_USER);
    const [updateUser] = useMutation(UPDATE_USER_INFO);

    const { client, channel } = useChatContext<StreamChatType>();
    // console.log('In ChannelImageInputField - channel: ', channel);
    const channelId = channel?.id || '';
    // console.log('channelId: ', channelId);

    const [userData, setUserData] = useState({
        created_at: '',
        email: '',
        first_name: '',
        freelancer_status: '',
        id: '',
        image_url: '',
        last_name: '',
        phone: ''
    });

    const user = userData;

    const onEditImage = (src: string) => {
        setImageCrop(src);
        openCropModal();
    };

    const onImageCropped = async (file: File) => {
        try {
            setUploading(true);
            const {
                data: {
                    generate_upload_url: { url }
                }
            } = await generateURL({
                variables: { path: `${user.id}-${Date.now()}.png` }
            });

            // console.log('url: ', url);

            await uploadFile(url, file);

            const avatarUrl = url.substring(0, url.indexOf('?'));

            await updateChannelImage(channelId, avatarUrl);

            // console.log('avatarUrl:', avatarUrl);
            // console.log('variables passed to mutation:', { userId: user.id, logoUrl: avatarUrl });
            // const { data } = await addLogoToUser({
            //     variables: { userId: user.id, logoUrl: avatarUrl }
            // });

            // setUserData({ ...userData, image_url: avatarUrl });

            // dispatch(
            //     openSnackbar({
            //         open: true,
            //         message: 'updated_avatar',
            //         variant: 'success'
            //     })
            // );
        } catch (e: any) {
            // console.log('Error data:', e.data);
            // console.log('Error message:', e.message);
            // dispatch(
            //     openSnackbar({
            //         open: true,
            //         message: e.message,
            //         variant: 'error'
            //     })
            // );
        } finally {
            setUploading(false);
        }
    };

    return (
        <>
            {/* <div className="channel-name-input-field"> */}
            <div>
                <h2>
                    {/* <span>Image</span> */}
                    {/* <ValidationError errorMessage={error} /> */}
                </h2>
                {/* <input onChange={onChange} placeholder={placeholder} type="text" value={name} /> */}
            </div>

            {/* <Box mr={2} sx={{ width: 150, position: 'relative' }}> */}
            <Box mr={2} sx={{ width: 65, position: 'relative' }}>
                <Avatar
                    // sx={{ width: 120, height: 120 }}
                    sx={{ width: 50, height: 50 }}
                    alt={channel?.id || ''}
                    src={
                        (channel?.data?.imageUrl as string) ||
                        // 'src/views/pages/messages/assets/newImages/sidebar/ChatPreviewPlaceholderImage.svg'
                        ChatPreviewPlaceholderImage
                    }
                />
                <EditImageButton
                    sx={{
                        width: 24,
                        height: 24,
                        background: theme.palette.grey[700],
                        borderRadius: '100px',
                        border: '2px solid #fff',
                        position: 'absolute',
                        bottom: 0,
                        right: 0
                    }}
                    onEdit={onEditImage}
                />
                {uploading && <CircularProgress size={20} sx={{ position: 'absolute', left: 24, top: 24 }} />}
            </Box>

            <Dialog
                open={showCropModal}
                onClose={closeCropModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <CropDialog onClose={closeCropModal} url={imageCrop} onDone={onImageCropped} />{' '}
            </Dialog>
        </>
    );
};
