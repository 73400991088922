import clsx from 'clsx';
import { useCallback, useContext } from 'react';
import { ChannelPreviewUIComponentProps, useChatContext } from 'stream-chat-react';

import { DirectMessagingChannelPreview } from './DirectMessagingChannelPreview';
import { TeamChannelPreview } from './TeamChannelPreview';

import { useWorkspaceController } from '../../context/WorkspaceController';

import type { StreamChatType } from '../../types';

import { Box } from '@mui/material';
import { MessagesCtx } from '../../MessagesContext';

type TeamChannelPreviewProps = ChannelPreviewUIComponentProps<StreamChatType> & {
    type: string;
};

export const ChannelPreview = ({ channel, type }: TeamChannelPreviewProps) => {
    const { channel: activeChannel, setActiveChannel } = useChatContext<StreamChatType>();
    const { displayWorkspace } = useWorkspaceController();
    const useMessages = () => useContext(MessagesCtx);
    const messagesContext = useMessages();
    const handleClick = useCallback(() => {
        displayWorkspace('Chat');
        if (setActiveChannel) {
            setActiveChannel(channel);
            messagesContext.setShowChannels(false);
        }
    }, [channel, displayWorkspace, setActiveChannel]);

    // Render the last message in the channel
    const renderMessageText = () => {
        const lastMessage = channel.state.messages[channel.state.messages.length - 1];
        const channelHasOneLastMessage = !!lastMessage;
        // Case: channel doesn't have messages
        if (!channelHasOneLastMessage) {
            return 'Channel is empty';
        }

        // Case: channel has one last message and is a text
        const lastMessageHasText = !!lastMessage.text;
        if (lastMessageHasText) {
            const lastMessageText = lastMessage?.text || 'No messages yet';
            const text = lastMessageText || 'message text';
            return text.length < 60 ? lastMessageText : `${text.slice(0, 70)}...`;
        }

        // Case: channel has one last message without text, so we check for attachment
        const lastMessageHasAttachment = !!lastMessage.attachments;
        if (lastMessageHasAttachment) {
            return 'Click to see attachment(s)';
        }

        // Case: other
        return 'Click to open channel';
    };

    // Render the number of unread messages for the logged in user
    const renderNumberOfUnreadMessages = () => {
        const countUnread = channel.countUnread();
        return countUnread;
    };

    // Determines whether the channel is active and should be highlighted
    const determineIfActiveChannel = () => {
        // console.log('new test');
        // console.log('activeChannel: ', activeChannel?.id);
        // console.log('currentChannel: ', channel.id);
        if (activeChannel?.id === channel.id) {
            // console.log('match found for :', channel);
            return true;
        }
        return false;
        // return channel.countUnread();
    };

    // if (channel.id === 'NEW-CHANNEL') {
    //     console.log('channel ', channel);
    //     console.log('channel.data?.imageUrl', channel.data?.imageUrl);
    // }

    return (
        <Box sx={{ bgcolor: determineIfActiveChannel() ? '#F0998D36' : '', width: '100%', height: '100%' }}>
            <button type="button" className={clsx('channel-preview')} onClick={handleClick}>
                {type === 'team' ? (
                    <TeamChannelPreview
                        name={channel?.data?.name || (channel?.data?.id as string) || 'random'}
                        lastMessage={renderMessageText()}
                        numberOfUnreadMessages={renderNumberOfUnreadMessages()}
                        imageUrl={(channel?.data?.imageUrl as string) || ''}
                    />
                ) : (
                    <DirectMessagingChannelPreview channel={channel} />
                )}
            </button>
        </Box>
    );
};
