import { ChangeEventHandler, ReactNode } from 'react';
import { TextField } from '@mui/material';

interface AuthInputProps {
    name: string;
    value: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
    error?: boolean;
    helperText?: ReactNode;
    placeholder?: string;
}
export const AuthInput = ({ name, value, onChange, error, helperText, placeholder }: AuthInputProps) => (
    <TextField
        sx={{
            '.MuiOutlinedInput-input': {
                fontWeight: 400,
                fontFamily: 'Inter',
                fontSize: '14px'
            },
            my: 1
        }}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        variant="outlined"
        fullWidth
        placeholder={placeholder}
        error={error}
        helperText={helperText}
    />
);
