import { Box, Grid, CardMedia, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Performance } from '.';
import logoShared from 'assets/images/logoShared.svg';
import { useTheme } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import { useLazyQuery } from '@apollo/client';
import { GET_AGENCY_LOGO_FROM_SHARED_REPORT_ID } from 'services/graphQL/queries/client/clientPerformance';
import React from 'react';

export const PublicPerformance = () => {
    const { sharedReportId } = useParams();
    const [logo, setLogo] = React.useState(logoShared);
    const theme = useTheme();
    const [getAgencyLogo] = useLazyQuery(GET_AGENCY_LOGO_FROM_SHARED_REPORT_ID);

    const getLogo = async () => {
        const {
            data: {
                client_report_set_by_pk: {
                    client_organization: {
                        agency: { logo_url: fetchedLogo }
                    }
                }
            }
        } = await getAgencyLogo({
            variables: {
                id: sharedReportId
            }
        });
        console.log('fetchedLogo', fetchedLogo);
        setLogo(fetchedLogo);
    };

    React.useEffect(() => {
        getLogo();
    }, []);
    return (
        <Box>
            <Box sx={{ background: '#FFD1CB', height: '100px' }}> </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '80%', m: '-50px auto 0' }}>
                <Box sx={{ width: '100px' }}>
                    <CardMedia sx={{ objectFit: 'contain' }} component="img" image={logo} alt="AI tools" />
                </Box>
                <Box
                    sx={{
                        p: '8px 16px',
                        background: theme.palette.orange.main,
                        borderRadius: '43px',
                        border: `2px solid ${theme.palette.background.paper}`,
                        ml: 1
                    }}
                >
                    <Typography
                        sx={{
                            color: theme.palette.background.paper,
                            fontSize: '14px',
                            fontWeight: 600,
                            fontFamily: 'Inter'
                        }}
                    >
                        <FormattedMessage id="performance_report" />
                    </Typography>
                </Box>
            </Box>

            <Grid sx={{ width: '82%', m: '50px auto' }}>
                <Performance isPublicReport sharedReportId={sharedReportId} />
            </Grid>
        </Box>
    );
};
