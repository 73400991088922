import axios, { AxiosRequestConfig } from 'axios';

interface UpdateCurrentOrganizationIdInput {
    current_organization_id: string;
}

export const updateCurrentOrganizationId = async (input: UpdateCurrentOrganizationIdInput) => {
    const endpointURL = `${process.env.REACT_APP_SNIKPIC_BASE_URL}/auth/current_organization_id`;

    return axios.put(
        endpointURL,
        {
            ...input
        },
        {
            headers: {
                authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '')?.tokens?.access_token ?? ''}`
            }
        }
    );
};
