import React, { MouseEvent } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Autocomplete, { AutocompleteCloseReason, autocompleteClasses } from '@mui/material/Autocomplete';
import InputBase from '@mui/material/InputBase';
import { Box, Avatar, Typography } from '@mui/material';
import { Button } from 'ui-component/extended/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';

interface IValue {
    id: string;
    value: string | number;
    icon?: string;
}

interface PopperComponentProps {
    anchorEl?: any;
    disablePortal?: boolean;
    open: boolean;
}
const StyledAutocompletePopper = styled('div')(({ theme }) => ({
    [`& .${autocompleteClasses.paper}`]: {
        boxShadow: 'none',
        margin: 0,
        color: 'inherit',
        fontSize: 13
    },
    [`& .${autocompleteClasses.listbox}`]: {
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
        padding: 0,
        [`& .${autocompleteClasses.option}`]: {
            minHeight: 'auto',
            alignItems: 'flex-start',
            padding: 16,
            borderTop: `1px solid ${theme.palette.grey[500]}`,
            '&[aria-selected="true"]': {
                backgroundColor: 'transparent'
            },
            [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]: {
                backgroundColor: theme.palette.action.hover
            }
        }
    },
    [`&.${autocompleteClasses.popperDisablePortal}`]: {
        position: 'relative'
    }
}));

function PopperComponent(props: PopperComponentProps) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { disablePortal, anchorEl, open, ...other } = props;
    return <StyledAutocompletePopper {...other} />;
}

const StyledPopper = styled(Popper)(({ theme }) => ({
    border: `1px solid ${theme.palette.grey[500]} !important`,
    borderRadius: 8,
    margin: '4px 0 !important',
    width: '320px',
    zIndex: theme.zIndex.modal,
    fontSize: 13,
    color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
    backgroundColor: `${theme.palette.background.paper} !important`,
    '.MuiAutocomplete-popper': {
        width: '318px !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important'
    },
    '.MuiAutocomplete-paper': {
        borderRadius: '0 0 8px 8px'
    }
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
    padding: 10,
    width: 'calc(100% - 34px)',
    borderBottom: `none`,
    '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.background.paper,
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: `none`,
        fontSize: 14,
        '&:focus': {
            boxShadow: `none`,
            borderColor: 'none'
        }
    }
}));

interface AutoCompleteSelectButtonProps {
    labels: IValue[];
    title: string;
    onSelect: (id: string) => void;
    sx?: object;
    avatar?: boolean;
    placeholder?: string;
}
export function AutoCompleteSelectButton({ labels, title, onSelect, avatar = true, sx = {}, placeholder }: AutoCompleteSelectButtonProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const intl = useIntl();
    const theme = useTheme();

    const handleSelect = (value: IValue) => {
        onSelect && onSelect(value.id);
    };

    const openPopper = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
    const closePopper = () => {
        anchorEl && anchorEl.focus();
        setAnchorEl(null);
    };

    const id = anchorEl ? 'github-label' : undefined;

    return (
        <Box>
            <Box sx={{ width: { xs: '100%', sm: '220px' } }}>
                <Button title={<FormattedMessage id={title} />} icon={<AddIcon />} onClick={openPopper} />
            </Box>

            <StyledPopper id={id} open={!!anchorEl} anchorEl={anchorEl} placement="bottom-start">
                <ClickAwayListener onClickAway={closePopper}>
                    <Box sx={{ zIndex: 1010 }}>
                        <Autocomplete
                            sx={{ width: 320, ...sx }}
                            open
                            onClose={(event: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
                                if (reason === 'escape') {
                                    closePopper();
                                }
                            }}
                            onChange={(event, newValue, reason) => {
                                if (
                                    event.type === 'keydown' &&
                                    (event as React.KeyboardEvent).key === 'Backspace' &&
                                    reason === 'removeOption'
                                ) {
                                    return;
                                }
                                // setPendingValue(newValue);
                                closePopper();
                            }}
                            disableCloseOnSelect
                            PopperComponent={PopperComponent}
                            renderTags={() => null}
                            noOptionsText="No labels"
                            renderOption={(props, option) => (
                                <li {...props} key={option.id}>
                                    <Box onClick={() => handleSelect(option)} sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        {avatar && (
                                            <Box
                                                sx={{
                                                    width: 32,
                                                    height: 32,
                                                    flexShrink: 0,
                                                    borderRadius: '3px',
                                                    mr: 1,
                                                    mt: '2px',
                                                    ml: '10px'
                                                }}
                                            >
                                                <Avatar sx={{ width: '32px', height: '32px' }} alt="Remy Sharp" src={option.icon} />
                                            </Box>
                                        )}
                                        {/* )} */}
                                        <Box
                                            sx={{
                                                flexGrow: 1,
                                                '& span': {
                                                    color: theme.palette.grey[200]
                                                }
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: theme.palette.grey[400],
                                                    fontFamily: 'Inter',
                                                    fontWeight: 500,
                                                    ml: 1,
                                                    mt: 0
                                                }}
                                            >
                                                {option.value}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </li>
                            )}
                            options={labels}
                            getOptionLabel={(option) => `${option.value}`}
                            renderInput={(params) => (
                                <Box sx={{ display: 'flex', width: '320px', alignItem: 'center' }}>
                                    <SearchIcon
                                        sx={{
                                            m: '15px 0 0 20px',
                                            fill: theme.palette.grey[200]
                                        }}
                                    />
                                    <StyledInput
                                        ref={params.InputProps.ref}
                                        inputProps={params.inputProps}
                                        autoFocus
                                        placeholder={intl.formatMessage({ id: placeholder })}
                                    />
                                </Box>
                            )}
                        />
                    </Box>
                </ClickAwayListener>
            </StyledPopper>
        </Box>
    );
}
