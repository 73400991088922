import { gql } from '@apollo/client';

const GET_CAPTIONS = gql`
    query MyQuery($client_organization_id: uuid) {
        client_organization_caption_examples(where: { client_organization_id: { _eq: $client_organization_id } }) {
            subcategory_id
            caption
            client_organization_id
            concept
            id
        }
    }
`;

// eslint-disable-next-line import/prefer-default-export
export { GET_CAPTIONS };
