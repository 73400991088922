import { Typography, Box, Dialog, CardMedia, Divider, CircularProgress } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import logoFacebookSnickpik from 'assets/images/logoasdasd.png';
import Facebook from 'assets/images/Facebook.svg';
import { useTheme } from '@mui/material/styles';
import FacebookLogin from '@greatsumini/react-facebook-login';
import axios from 'axios';
import { useMutation } from '@apollo/client';
import { STORE_AGENCY_LOGIN_STATE } from 'services/graphQL/mutations/fbIntegration';
import AutoCompliteClients from '../clients/AutocompleteClients';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button } from 'ui-component/extended/Button';
import CloseIcon from '@mui/icons-material/Close';

interface IntegrationDialogProps {
    openDialogIntegrate: boolean;
    handleCloseDialogAddClient: any;
    setCheckConnection: any;
}
interface ValueSelectType {
    id: string;
    name: string;
}
interface UserDataType {
    accessToken: string;
    userID: string;
}

const IntegrationDialog = ({ openDialogIntegrate, handleCloseDialogAddClient, setCheckConnection }: IntegrationDialogProps) => {
    const theme = useTheme();
    const [labels, setLabels] = React.useState<ValueSelectType[]>([]);
    const [valueSelect, setValueSelect] = React.useState<ValueSelectType>();
    const [valueSelectInput, setValueSelectInput] = React.useState<ValueSelectType>();
    const [userData, setUserData] = React.useState<UserDataType>();

    const [addFacebookConnection, { loading: addFacebookConnectionLoading }] = useMutation(STORE_AGENCY_LOGIN_STATE, {
        onCompleted: (data) => {
            setCheckConnection(data.add_facebook_connection.organization_id);
            handleCloseDialogAddClient();
        }
    });

    const handleValueSelect = (val: ValueSelectType) => {
        setValueSelectInput(val);
    };

    const getResponse = async (config: any, response: any) => {
        try {
            const values = await axios(config);
            if (values.data.data.length > 0) {
                const arrData = values.data.data.map((item: ValueSelectType) => item);
                setLabels(arrData);
                setUserData({
                    accessToken: response.accessToken,
                    userID: response.userID
                });
            }
        } catch (err) {
            console.log(err);
        }
    };
    const responseFacebook = (response: any) => {
        console.log('<><><>', response);
        const config: any = {
            method: 'get',
            url: `https://graph.facebook.com/v14.0/me/businesses?access_token=${response.accessToken}`,
            headers: {}
        };
        getResponse(config, response);
    };
    const handleIntegrate = () => {
        addFacebookConnection({
            variables: {
                access_token: userData?.accessToken,
                business_manager_id: valueSelectInput?.id,
                facebook_user_id: userData?.userID,
                business_id: valueSelectInput?.id
            }
        });
    };
    return (
        <Dialog open={openDialogIntegrate} onClose={handleCloseDialogAddClient}>
            <Box sx={{ p: '32px', borderRadius: '16px', width: { xs: '100%', sm: '562px' }, position: 'relative' }}>
                <Box onClick={handleCloseDialogAddClient} sx={{ position: 'absolute', top: '32px', right: '32px', cursor: 'pointer' }}>
                    <CloseIcon />
                </Box>
                <Box sx={{ width: '114px', m: '0 auto' }}>
                    <CardMedia sx={{ objectFit: 'contain' }} component="img" width="18px" image={logoFacebookSnickpik} alt="alt image" />
                </Box>
                <Typography
                    mt={2}
                    sx={{
                        fontWeight: 700,
                        fontFamily: 'Inter',
                        color: theme.palette.grey[400],
                        fontSize: '34px',
                        textAlign: 'center'
                    }}
                >
                    <FormattedMessage id="integrate_with_facebook" />
                </Typography>
                <Typography
                    mt={2}
                    sx={{
                        fontWeight: 400,
                        fontFamily: 'Inter',
                        color: theme.palette.grey[300],
                        fontSize: '14px',
                        textAlign: 'center'
                    }}
                >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eu ex pulvinar, convallis lacus non, efficitur justo
                </Typography>
                <Box mt={5} sx={{ background: theme.palette.grey[700], p: '24px', borderRadiu: '8px' }}>
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontFamily: 'Inter',
                            color: theme.palette.grey[400],
                            fontSize: '16px'
                        }}
                    >
                        <FormattedMessage id="steps_to_complete" />
                    </Typography>
                    <Box mt={4} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ background: theme.palette.background.paper, borderRadius: '100px', width: '24px', height: '24px' }}>
                            <Typography
                                sx={{
                                    fontWeight: 500,
                                    fontFamily: 'Inter',
                                    color: theme.palette.grey[400],
                                    fontSize: '14px',
                                    m: '3px 0 0 8px'
                                }}
                            >
                                1
                            </Typography>
                        </Box>
                        <Box ml={2}>
                            <FacebookLogin
                                appId="595760385272978"
                                onSuccess={responseFacebook}
                                scope="email,public_profile,business_management"
                                render={(renderProps) => (
                                    <Box
                                        onClick={renderProps.onClick}
                                        sx={{
                                            display: 'flex',
                                            background: theme.palette.warning[100],
                                            alignItem: 'center',
                                            p: '6px 12px',
                                            borderRadius: '10px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <Box mr={1} sx={{ width: '16px' }}>
                                            <CardMedia
                                                sx={{ objectFit: 'contain' }}
                                                component="img"
                                                width="18px"
                                                image={Facebook}
                                                alt="alt image"
                                            />
                                        </Box>
                                        <Typography
                                            sx={{
                                                fontWeight: 500,
                                                fontFamily: 'Inter',
                                                color: theme.palette.background.paper,
                                                fontSize: '12px'
                                            }}
                                        >
                                            <FormattedMessage id="login_with_facebook" />
                                        </Typography>
                                    </Box>
                                )}
                            />
                        </Box>
                    </Box>
                    <Divider
                        sx={{ background: theme.palette.grey[500], width: '36px', transform: 'rotate(90deg)', m: '24px 2px 0 -5px' }}
                    />
                    <Box mt={4} sx={{ display: 'flex' }}>
                        <Box>
                            <Box sx={{ background: theme.palette.background.paper, borderRadius: '100px', width: '24px', height: '24px' }}>
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        fontFamily: 'Inter',
                                        color: theme.palette.grey[400],
                                        fontSize: '14px',
                                        m: '3px 0 0 8px'
                                    }}
                                >
                                    2
                                </Typography>
                            </Box>
                            <Divider
                                sx={{
                                    background: theme.palette.grey[500],
                                    width: '36px',
                                    transform: 'rotate(90deg)',
                                    m: '24px 2px 0 -5px'
                                }}
                            />
                        </Box>

                        <Box ml={2} sx={{ mt: labels.length > 0 ? '-20px' : 0, width: '100%' }}>
                            <Typography
                                sx={{
                                    fontWeight: 500,
                                    fontFamily: 'Inter',
                                    color: theme.palette.grey[400],
                                    fontSize: '14px'
                                }}
                            >
                                <FormattedMessage id="business_manager" />
                            </Typography>
                            <Typography
                                mt={2}
                                mb={2}
                                sx={{
                                    fontWeight: 400,
                                    fontFamily: 'Inter',
                                    color: theme.palette.grey[300],
                                    fontSize: '12px'
                                }}
                            >
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit
                            </Typography>
                            {labels.length > 0 && (
                                <AutoCompliteClients
                                    labels={labels}
                                    pendingValue={valueSelect}
                                    setPendingValue={setValueSelect}
                                    handleValueSelect={handleValueSelect}
                                    btn={
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                border: `1px solid ${theme.palette.grey[500]}`,
                                                p: '8px',
                                                background: theme.palette.background.paper,
                                                borderRadius: '8px',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontWeight: 400,
                                                    fontFamily: 'Inter',
                                                    color: theme.palette.grey[300],
                                                    fontSize: '14px'
                                                }}
                                            >
                                                {valueSelectInput?.name ? (
                                                    valueSelectInput.name
                                                ) : (
                                                    <FormattedMessage id="select_your_business_manager" />
                                                )}
                                            </Typography>
                                            <KeyboardArrowDownIcon sx={{ fill: theme.palette.grey[300] }} />
                                        </Box>
                                    }
                                />
                            )}
                        </Box>
                    </Box>
                    {addFacebookConnectionLoading ? (
                        <Box mt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box mt={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Box>
                                <Button
                                    disabled={valueSelectInput === undefined}
                                    onClick={handleIntegrate}
                                    variant="primary"
                                    title={<FormattedMessage id="integrate" />}
                                />
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
        </Dialog>
    );
};

export default IntegrationDialog;
