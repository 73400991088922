import { useContext, useEffect, useState } from 'react';
import { StreamChat, OwnUserResponse } from 'stream-chat';
import { Chat, enTranslations, Streami18n } from 'stream-chat-react';
import { useChecklist } from './ChecklistTasks';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { ChannelContainer } from './components/ChannelContainer/ChannelContainer';
import { SidebarSide } from './components/Sidebar/Sidebar';
import { WorkspaceController } from './context/WorkspaceController';
import type { StreamChatType } from './types';
import useAuth from 'hooks/useAuth';
import { GET_USER } from 'services/graphQL';
import { useLazyQuery } from '@apollo/client';
import './styles/index.scss';
import { Box } from '@mui/material';
import { Button } from 'ui-component/extended/Button';
import { MessagesContext, MessagesCtx } from './MessagesContext';

export interface ExtendedUserResponse extends OwnUserResponse {
    organization_id: string;
    organization_role: string;
}

const Messages = () => {
    // const apiKey = '8qvergudtbky';
    const apiKey = process.env.REACT_APP_STREAM_API_KEY;
    const theme = 'light';
    // const userToken =
    // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiYzI0MDlmM2QtZGRhZC00NWQyLWFiNzYtNzgwMzNiYjNiZjQ2In0.eXkzkQuKmS8yTVOpwNuTe6oezD7_H93tMlxUDzUGOnM';
    const targetOrigin = process.env.REACT_APP_TARGET_ORIGIN;
    const [userConnected, setUserConnected] = useState(false);
    const [showChannels, setShowChannels] = useState(false);
    const i18nInstance = new Streami18n({
        language: 'en',
        translationsForLanguage: {
            ...enTranslations
        }
    });
    const MessagesContext: MessagesContext = {
        showChannels,
        setShowChannels
    };
    const [getUser] = useLazyQuery(GET_USER);
    const dispatch = useDispatch();
    const { user } = useAuth();
    // console.log('In Messages - user: ', user);
    const client = StreamChat.getInstance<StreamChatType>(apiKey!, {
        enableInsights: true,
        enableWSFallback: true
    });
    useChecklist({ chatClient: client, targetOrigin: targetOrigin! });
    const setUser = async (user: any) => {
        const {
            data: { users_by_pk: data }
        } = await getUser({ variables: { user_id: user.id } });
        // console.log('data.users_by_pk: ', data.users_by_pk);
        if (data) {
            console.log('user', user);
            console.log('data', data);
            await client.connectUser(
                {
                    id: data.id,
                    name: data.first_name,
                    image: data.image_url,
                    organization_id: JSON.parse(`${localStorage.getItem('user')}`).member.organizationId,
                    organization_role: JSON.parse(`${localStorage.getItem('user')}`).member.role
                } as unknown as ExtendedUserResponse,
                data.stream_token.toString()
            );
            setUserConnected(true);
        }
    };

    useEffect(() => {
        try {
            if (user && user.id) {
                setUser(user);
            }
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e.message,
                    variant: 'error'
                })
            );
        }
    }, [user]);

    useEffect(() => {
        const handleColorChange = (color: string) => {
            const root = document.documentElement;
            if (color.length && color.length === 7) {
                root.style.setProperty('--primary-color', `${color}E6`);
                root.style.setProperty('--primary-color-alpha', `${color}1A`);
            }
        };

        window.addEventListener('message', (event) => handleColorChange(event.data));
        return () => {
            client.disconnectUser();
            window.removeEventListener('message', (event) => handleColorChange(event.data));
        };
    }, [client]);

    return (
        <MessagesCtx.Provider value={MessagesContext}>
            <Box>
                {!showChannels && (
                    <Button
                        sx={{ display: { xs: 'block', sm: 'none' }, width: '160px', mb: 2 }}
                        title="Channels"
                        onClick={() => setShowChannels(true)}
                    />
                )}
                <Box sx={{ display: { xs: 'none !important', sm: 'block !important' } }} className="app__wrapper str-chat">
                    {userConnected && (
                        <Chat {...{ client, i18nInstance }} theme={`team ${theme}`}>
                            <WorkspaceController>
                                <div className="app__wrapper_box">
                                    <div className="app__wrapper_box_bottom">
                                        <SidebarSide />
                                        <ChannelContainer />
                                    </div>
                                </div>
                            </WorkspaceController>
                        </Chat>
                    )}
                </Box>
                <Box sx={{ display: { xs: 'block', sm: 'none' }, position: 'relative' }}>
                    {userConnected && (
                        <Chat {...{ client, i18nInstance }} theme={`team ${theme}`}>
                            <WorkspaceController>
                                <Box>
                                    {showChannels && (
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                height: '100vh',
                                                background: '#fff',
                                                width: '100%',
                                                zIndex: 999
                                            }}
                                        >
                                            <SidebarSide />
                                        </Box>
                                    )}
                                    <Box sx={{ display: 'none' }}>
                                        <SidebarSide />
                                    </Box>
                                    <ChannelContainer />
                                </Box>
                            </WorkspaceController>
                        </Chat>
                    )}
                </Box>
            </Box>
        </MessagesCtx.Provider>
    );
};

export default Messages;
