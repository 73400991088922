import { useIntl } from 'react-intl';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface TextEditorProps {
    setValue: any;
    value: string;
    placeholder: string;
}

const TextEditor = ({ value, setValue, placeholder }: TextEditorProps) => {
    const intl = useIntl();
    const modules = {
        toolbar: [
            [{ header: 1 }, { header: 2 }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'image'],
            ['clean'],
            [{ align: 'center' }, { align: '' }, { align: 'right' }],
            [{ script: 'super' }, { script: 'sub' }],
            ['code-block']
        ]
    };
    const formats = [
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'link',
        'image',
        'align',
        'script',
        'code-block'
    ];
    return (
        <ReactQuill
            modules={modules}
            formats={formats}
            theme="snow"
            placeholder={intl.formatMessage({ id: placeholder })}
            value={value}
            onChange={(e: any) => setValue(e)}
        />
    );
};

export default TextEditor;
