import { ChangeEventHandler, ReactNode, useState } from 'react';
import { Box, OutlinedInput, InputAdornment, IconButton, Typography } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface AuthPasswordProps {
    name: string;
    value: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
    error?: boolean;
    placeholder?: string;
    helperText?: ReactNode;
}
export const AuthPassword = ({ name, value, onChange, error, placeholder, helperText }: AuthPasswordProps) => {
    const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Box sx={{ my: 2 }}>
            <OutlinedInput
                id={name}
                name={name}
                fullWidth
                type={showPassword ? 'text' : 'password'}
                value={value}
                onChange={onChange}
                sx={{
                    '.MuiOutlinedInput-input': {
                        color: (theme) => theme.palette.grey[300],
                        fontWeight: 400,
                        fontFamily: 'Inter',
                        fontSize: '14px'
                    }
                }}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={toggleShowPassword} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
                placeholder={placeholder}
                error={error}
            />
            {helperText && (
                <Typography color="error" sx={{ mx: '14px', fontSize: 12, mt: '3px' }}>
                    {helperText}
                </Typography>
            )}
        </Box>
    );
};
