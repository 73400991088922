import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button } from 'ui-component/extended/Button';
import { AuthLogo, AuthTitle, AuthInput, AuthPassword } from '../components';
import useAuth from 'hooks/useAuth';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

const TextLink = ({ to, text }: any) => (
    <Link to={to}>
        <Typography
            mb={3}
            sx={{
                color: (theme) => theme.palette.grey[300],
                fontFamily: 'Inter',
                fontWeight: 400,
                textAlign: 'center'
            }}
        >
            <FormattedMessage id={text} />
        </Typography>
    </Link>
);

const SignIn = () => {
    const theme = useTheme();
    const intl = useIntl();
    const dispatch = useDispatch();

    const { login } = useAuth();
    const [busy, setBusy] = useState(false);
    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email').required('Required'),
            password: Yup.string().required('Required')
        }),
        onSubmit: async (values) => {
            setBusy(true);
            try {
                await login(values.email, values.password);
            } catch (e: any) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: e.message,
                        variant: 'error'
                    })
                );
            } finally {
                setBusy(false);
            }
        }
    });
    return (
        <Box sx={{ position: 'relative' }}>
            <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ minHeight: '100vh' }}>
                <Grid item>
                    <Box sx={{ height: '467px', width: { xs: '90%', sm: '405px' }, m: '0 auto' }}>
                        <AuthLogo />
                        <AuthTitle>
                            <FormattedMessage id="sign_in" />
                        </AuthTitle>
                        <Typography
                            sx={{
                                mt: 1,
                                mb: 3,
                                color: theme.palette.grey[300],
                                fontFamily: 'Inter',
                                fontWeight: 400,
                                textAlign: 'center'
                            }}
                        >
                            <FormattedMessage id="signin_desc" />
                        </Typography>
                        <form onSubmit={formik.handleSubmit}>
                            <AuthInput
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                placeholder={intl.formatMessage({ id: 'email' })}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                            <AuthPassword
                                name="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                placeholder={intl.formatMessage({ id: 'password' })}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <TextLink to="/auth/forgotpassword" text="forgot-password" />
                                <TextLink to="/auth/signup" text="sign_up" />
                            </Box>
                            <Button onClick={formik.handleSubmit} title={<FormattedMessage id="login" />} loading={busy} />
                        </form>
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{ position: 'absolute', bottom: 0, width: '100%' }}>
                <Box sx={{ maxWidth: '410px', m: '0 auto' }}>
                    <Typography
                        mb={3}
                        sx={{
                            color: theme.palette.grey[300],
                            fontFamily: 'Inter',
                            fontWeight: 400,
                            textAlign: 'center'
                        }}
                    >
                        Snikpic SRL - VAT BE0729.842.450 - Contact@snikpic.io Avenue Armand Huysmans 157, 1050 Bruxelles
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default SignIn;
