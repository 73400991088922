import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import {
    Table,
    Avatar,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    CardMedia,
    Dialog
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import moment from 'moment';
import { PopoverMenu, MenuItem } from 'ui-component/menus';
import DialogConfirm from 'ui-component/dialogConfirm';
import zapoff from 'assets/images/zapoff.svg';

function ClientsTableHead() {
    const theme = useTheme();
    const color = theme.palette.grey[400];

    return (
        <TableHead sx={{ borderBottom: `1px solid ${theme.palette.grey[500]}`, background: theme.palette.grey[700] }}>
            <TableRow>
                <TableCell align="left">
                    {' '}
                    <Typography sx={{ color, fontFamily: 'Inter', fontWeight: 600 }}>
                        <FormattedMessage id="brand_name" />
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography sx={{ color, fontFamily: 'Inter', fontWeight: 600 }}>
                        <FormattedMessage id="contact_point_name" />
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography sx={{ color, fontFamily: 'Inter', fontWeight: 600 }}>
                        <FormattedMessage id="email" />
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography sx={{ color, fontFamily: 'Inter', fontWeight: 600 }}>
                        <FormattedMessage id="phone_number" />
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography sx={{ color, fontFamily: 'Inter', fontWeight: 600 }}>
                        <FormattedMessage id="date_added" />
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography sx={{ color, fontFamily: 'Inter', fontWeight: 600, display: 'none' }}>
                        <FormattedMessage id="date_added" />
                    </Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

const FullTableRow = ({ client, onDeactivate }: any) => {
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const theme = useTheme();
    const navigate = useNavigate();

    const openPopper = (event: any) => setAnchorEl(event.currentTarget);
    const closePopover = () => setAnchorEl(null);

    const handleClickView = () => {
        navigate(`/clients/${client.id}`, { replace: true });
    };
    const handleClickDeactive = () => {
        onDeactivate(client.id);
        closePopover();
    };

    return (
        <TableRow hover role="checkbox" tabIndex={-1} key={client.name} sx={{ cursor: 'pointer' }}>
            <TableCell onClick={handleClickView} align="left" sx={{ width: '357px', pt: '35px', cursor: 'pointer' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar sx={{ width: '32px', height: '32px' }} alt="Remy Sharp" src={client.name.src} />
                    <Typography ml={1} sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500 }}>
                        {client.name.title}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell onClick={handleClickView} component="th" scope="client" sx={{ width: '357px', pt: '35px', cursor: 'pointer' }}>
                <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500 }}>{client.contactName}</Typography>
            </TableCell>
            <TableCell onClick={handleClickView} align="left" sx={{ width: '357px', pt: '30px', cursor: 'pointer' }}>
                <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600 }}>{client.email}</Typography>
            </TableCell>
            <TableCell onClick={handleClickView} align="left" sx={{ width: '357px', pt: '30px', cursor: 'pointer' }}>
                <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600 }}>{client.phoneNumber}</Typography>
            </TableCell>
            <TableCell onClick={handleClickView} align="left" sx={{ width: '357px', pt: '30px', cursor: 'pointer' }}>
                <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600 }}>
                    {moment(client.date).format('DD MMMM, YYYY')}
                </Typography>
            </TableCell>
            <TableCell align="right" sx={{ pt: '30px' }}>
                <Typography
                    onClick={openPopper}
                    sx={{
                        color: theme.palette.grey[400],
                        fontFamily: 'Inter',
                        fontWeight: 600,
                        fontSize: '26px',
                        m: '-10px 20px 0 0',
                        cursor: 'pointer',
                        zIndex: 1111
                    }}
                >
                    ...
                </Typography>
                <PopoverMenu open={!!anchorEl} anchorEl={anchorEl} onClose={closePopover}>
                    <MenuItem icon={<VisibilityOutlinedIcon sx={{ width: '20px' }} />} onClick={handleClickView}>
                        <FormattedMessage id="view" />
                    </MenuItem>
                    <MenuItem
                        icon={<CardMedia sx={{ objectFit: 'contain' }} component="img" width="18px" image={zapoff} alt="alt image" />}
                        onClick={handleClickDeactive}
                    >
                        <FormattedMessage id="deactivate" />
                    </MenuItem>
                </PopoverMenu>
            </TableCell>
        </TableRow>
    );
};

export default function EnhancedTable({ clients, deactivate, updating }: any) {
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [clientId, setClientId] = React.useState<string>('');

    const openConfirm = () => setShowConfirm(true);
    const closeConfirm = () => setShowConfirm(false);

    const handleDeactivate = (id: string) => {
        setClientId(id);
        openConfirm();
    };
    const deactivateClient = async () => {
        await deactivate({ variables: { clientId } });
        closeConfirm();
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table sx={{ borderRadius: '8px 8px 0 0', minWidth: 750 }} aria-labelledby="tableTitle">
                        <ClientsTableHead />
                        <TableBody>
                            {clients.map((row: any, index: number) => {
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return <FullTableRow client={row} key={labelId} onDeactivate={handleDeactivate} />;
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <Dialog sx={{ '.MuiDialog-paper': { minWidth: { xs: '350px', sm: '562px' } } }} onClose={closeConfirm} open={showConfirm}>
                <DialogConfirm onConfirm={deactivateClient} onClose={closeConfirm} loading={updating} />
            </Dialog>
        </Box>
    );
}
