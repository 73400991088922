import React, { createContext, useReducer, useEffect, useCallback } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

import { CHANGE_ORGANIZATION, LOGIN, LOGOUT } from './auth-actions';
import { accountReducer } from './auth-reducer';
import { JWTContextType, InitialLoginContextProps } from 'types/auth';
import { loadState } from 'utils/storage';
import {
    GET_USER,
    GET_USER_ORGANIZATIONS,
    GET_USER_ROLE,
    AUTH_SIGN_IN,
    AUTH_SIGN_UP,
    GET_USER_BY_EMAIL,
    AUTH_REFRESH_TOKEN,
    AUTH_SIGN_OUT
} from 'services/graphQL';
import useClient from 'hooks/useClient';
import { updateCurrentOrganizationId } from 'contexts/api';

// const
const initialState: InitialLoginContextProps = loadState('user') ?? {
    user: null,
    isLoggedIn: false,
    isInitialized: false,
    member: {}
};

const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const { setRefresh } = useClient();

    const [signIn] = useMutation(AUTH_SIGN_IN);
    const [signUp] = useMutation(AUTH_SIGN_UP);
    const [refreshToken] = useMutation(AUTH_REFRESH_TOKEN);
    const [signOut] = useMutation(AUTH_SIGN_OUT);

    const [getUser] = useLazyQuery(GET_USER);
    const [getUserByEmail] = useLazyQuery(GET_USER_BY_EMAIL);
    const [getOrganizations] = useLazyQuery(GET_USER_ORGANIZATIONS);
    const [getRoles] = useLazyQuery(GET_USER_ROLE);

    const signup = async (email: string, password: string, firstName: string, lastName: string) => {
        const {
            data: { auth_sign_up: data }
        } = await signUp({
            variables: {
                email,
                first_name: firstName,
                last_name: lastName,
                password
            }
        });

        const { id, access_token, refresh_token } = data;

        const {
            data: { users_by_pk: user }
        } = await getUser({ variables: { user_id: id } });

        const {
            data: { organizations: orgs }
        } = await getOrganizations({ variables: { user_id: id } });

        const payload: InitialLoginContextProps = {
            user,
            tokens: { access_token, refresh_token },
            isLoggedIn: true
        };

        if (orgs && orgs.length > 0) {
            const {
                data: { roles }
            } = await getRoles({
                variables: {
                    user_id: user.id,
                    organization_id: orgs[0]?.id
                }
            });

            payload.member = {
                organizationId: orgs[0]?.id,
                role: roles[0]?.id
            };
        }

        dispatch({ type: LOGIN, payload });
        return data;
    };

    const login = async (email: string, password: string) => {
        const {
            data: { auth_sign_in: tokens }
        } = await signIn({ variables: { email, password } });

        console.log('tokens', tokens);

        const {
            data: { users }
        } = await getUserByEmail({
            variables: { email },
            context: {
                headers: {
                    authorization: `Bearer ${tokens.access_token}`
                }
            }
        });

        if (users.length === 0) return;

        const user = users[0];
        const {
            data: { organizations: orgs }
        } = await getOrganizations({
            variables: { user_id: user.id },
            context: {
                headers: {
                    authorization: `Bearer ${tokens.access_token}`
                }
            }
        });

        const {
            data: { roles }
        } = await getRoles({
            variables: {
                user_id: user.id,
                organization_id: orgs[0]?.id
            },
            context: {
                headers: {
                    authorization: `Bearer ${tokens.access_token}`
                }
            }
        });

        dispatch({
            type: LOGIN,
            payload: {
                user,
                tokens,
                isLoggedIn: true,
                member: {
                    organizationId: orgs[0]?.id,
                    role: roles[0]?.id
                }
            }
        });
    };

    const logout = async () => {
        try {
            await signOut({ variables: { email: state.user?.email } });
        } catch (e: any) {
            console.log(e.message);
        } finally {
            dispatch({ type: LOGOUT });
            console.log('User logged out');
        }
    };

    const refresh = useCallback(async () => {
        const { tokens } = state;

        if (!tokens) {
            dispatch({ type: LOGOUT });
        } else {
            try {
                const {
                    data: { auth_refresh: token }
                } = await refreshToken({ variables: { refresh_token: tokens.refresh_token } });

                dispatch({
                    type: LOGIN,
                    payload: {
                        ...state,
                        tokens: {
                            ...tokens,
                            access_token: token.access_token
                        }
                    }
                });
            } catch (e: any) {
                console.log(e.message);
                dispatch({ type: LOGOUT });
            }
        }
    }, [refreshToken, state]);

    useEffect(() => {
        const timer = setInterval(() => refresh(), 30 * 60 * 1000);
        return () => clearInterval(timer);
    }, [refresh]);

    const resetPassword = async (email: string) => {};

    const changeOrganization = async (organizationId: string) => {
        const { data: organizationMember } = await updateCurrentOrganizationId({ current_organization_id: organizationId });
        await refresh();
        dispatch({
            type: CHANGE_ORGANIZATION,
            payload: {
                ...state,
                member: {
                    organizationId: organizationMember.organization_id,
                    role: organizationMember.role_id
                }
            }
        });
    };

    useEffect(() => {
        setRefresh(refresh);
    }, [refresh, setRefresh]);

    return (
        <JWTContext.Provider
            value={{
                ...state,
                signup,
                login,
                logout,
                refresh,
                resetPassword,
                changeOrganization
            }}
        >
            {children}
        </JWTContext.Provider>
    );
};

export default JWTContext;
