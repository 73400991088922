import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Box, Breadcrumbs, Dialog, CircularProgress, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';

import { GET_CLIENTS, DEACTIVATE_CLIENT, GET_USER_ORGANIZATIONS } from 'services/graphQL';
import { setClientList as setClientListAction } from 'store/slices/clients';
import { useSelector } from 'store';
import { Title, Text } from 'ui-component/typography';
import { Button } from 'ui-component/extended/Button';
import NotFoundImg from 'ui-component/Offer';
import { ClientType } from 'types/clients';

import ClientsTable from './clients-table';
import DialogAddClient from './add-client-dialog';
import { ClientOrganization } from '../../types';
import { openSnackbar } from 'store/slices/snackbar';
import useAuth from 'hooks/useAuth';

const formatData = (organizations: ClientOrganization[]) => {
    const result: ClientType[] = [];
    if (!organizations) {
        return result;
    }

    for (const org of organizations) {
        if (org.members?.length) {
            const member = org.members[0];
            result.push({
                id: org.id,
                agencyId: org.agency_id,
                name: { title: org.name, src: org.logo_url },
                email: member.user.email,
                contactName: member.user.first_name,
                phoneNumber: member.user.phone,
                lang: 'English',
                description: 'Test description',
                date: org.created_at,
                links: org.client_relevant_links
            });
        }
    }

    return result;
};

export const Clients = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { member } = useAuth();
    const { clientList } = useSelector((state) => state.clients);
    const [organizationName, setOrganizationName] = React.useState('');
    const [showAddDialog, setShowAddDialog] = React.useState(false);
    const openAddClientDialog = () => setShowAddDialog(true);
    const closeAddClientDialog = () => setShowAddDialog(false);
    const userId = JSON.parse(`${localStorage.getItem('user')}`);

    const [getUser] = useLazyQuery(GET_USER_ORGANIZATIONS, {
        onCompleted: (res) => {
            setOrganizationName(res.organizations[0].name);
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first'
    });
    const [getClientsByOrgID, { loading, refetch }] = useLazyQuery(GET_CLIENTS, {
        onCompleted: (data: any) => {
            const { client_organizations: clientOrgs } = data;
            if (clientOrgs) {
                const formattedData = formatData(clientOrgs);
                dispatch(setClientListAction(formattedData));
            }
        },
        onError: (e: any) => {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e.message,
                    variant: 'error'
                })
            );
        }
    });
    const [deactivateClient, { loading: updating }] = useMutation(DEACTIVATE_CLIENT, {
        onCompleted: (_: any) => {
            refetch();
        },
        onError: (e: any) => {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e.message,
                    variant: 'error'
                })
            );
        }
    });

    useEffect(() => {
        const fetch = async () => {
            await getClientsByOrgID({ variables: { organizationId: member?.organizationId } });
        };
        getUser({
            variables: {
                user_id: userId.user.id
            }
        });
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box>
            <Breadcrumbs aria-label="breadcrumb">
                <Typography sx={{ textTransform: 'capitalize', color: theme.palette.grey[200], fontFamily: 'Inter', fontWeight: 500 }}>
                    {organizationName}
                </Typography>
                <Text sx={{ textTransform: 'capitalize', color: theme.palette.grey[300] }}>
                    <FormattedMessage id="clients" />
                </Text>
            </Breadcrumbs>
            <Box sx={{ display: 'flex', justifyContent: 'justify-between', alignItems: 'center', my: 2 }}>
                <Title sx={{ flexGrow: 1 }}>
                    <FormattedMessage id="clients" />
                </Title>
                <Box>
                    <Button onClick={openAddClientDialog} title={<FormattedMessage id="add_client" />} icon={<AddIcon />} />
                </Box>
            </Box>

            {loading && (
                <Box mt={10} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            )}

            {clientList?.length ? (
                <Box>
                    <ClientsTable clients={clientList} deactivate={deactivateClient} updating={updating} />
                    <Text
                        sx={{
                            color: theme.palette.grey[600],
                            fontWeight: 600,
                            fontSize: 10,
                            mt: 4,
                            textTransform: 'uppercase',
                            letterSpacing: '0.12em',
                            textAlign: 'center'
                        }}
                    >
                        <FormattedMessage id="end_of_page" /> - {clientList?.length} <FormattedMessage id="results" />
                    </Text>
                </Box>
            ) : (
                <Box sx={{ width: '315px', m: '15% auto 0' }}>
                    <NotFoundImg title={<FormattedMessage id="no_clients_found" />} />
                </Box>
            )}

            <Dialog
                sx={{ '.MuiDialog-paper': { minWidth: { xs: '350px', sm: '562px' } } }}
                open={showAddDialog}
                onClose={closeAddClientDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogAddClient onClose={closeAddClientDialog} item={clientList && clientList[0]} />
            </Dialog>
        </Box>
    );
};
