import { useState, useEffect, useCallback } from 'react';
import { FormData, Section, Field, FIELD_TYPE } from '../../types';
import { defaultValue, defaultPage, defaultSection, defaultField } from '../data';
import update from 'immutability-helper';
import { useMutation } from '@apollo/client';
import { CREATE_WORKFLOW } from 'services/graphQL/mutations/workflow';
import { toSnikpicDocument, toWorkflowDocumentInput } from 'utils/formatters/workflow';
import { useParams } from 'react-router-dom';
import { createWorkflowVersion, updateDocumentVersion } from 'services/rest/workflow';
import useAuth from 'hooks/useAuth';
import { flow } from 'lodash';
import { v4 as uuid } from 'uuid';
import { AutomationNodeHelper } from 'features/workflow-automation/utils/automation-node.util';
import { TaskNodeHelper } from 'features/workflow/workflow-builder/nodes/Task/TaskNodeHelper';
import { ApprovalTaskNodeHelper } from 'features/workflow/workflow-builder/nodes/ApprovalTask/ApprovalTaskNodeHelper';

const toFieldTypeEnum = (workflowDocumentFieldType: keyof typeof FIELD_TYPE) =>
    FIELD_TYPE[workflowDocumentFieldType as keyof typeof FIELD_TYPE];

export const extractFlowNodesAndEdges = (workflow: any) => {
    try {
        const flowNodes: any = [];
        const flowEdges: any = [];

        let trigger: any;

        workflow.active_version.reactflow_json?.forEach((element: any) => {
            if (element.type === 'connector') {
                flowEdges.push({
                    id: element.properties.id,
                    source: element.properties.source_id,
                    sourceHandle: null,
                    target: element.properties.target_id,
                    targetHandle: null,
                    type: 'buttonEdge',
                    markerEnd: {
                        type: 'arrow',
                        color: '#EE9A8F'
                    }
                });
                return;
            }

            if (element.type === 'approval_task_outgoing_connector') {
                flowEdges.push({
                    id: element.properties.id,
                    type: element.type,
                    source: element.properties.source_id,
                    sourceHandle: null,
                    target: element.properties.target_id,
                    targetHandle: null,
                    animated: true,
                    label: 'conditional connection',
                    style: { stroke: '#EE9A8F' },
                    labelBgPadding: [8, 4],
                    labelBgBorderRadius: 4,
                    labelBgStyle: { fill: '#FFCC00', color: '#fff', fillOpacity: 0.7 },
                    markerEnd: {
                        type: 'arrow',
                        color: '#EE9A8F'
                    }
                });
                return;
            }

            let node: any = {
                id: element.properties.id,
                type: element.type,
                data: {
                    label: element.properties.name
                },
                position: element.properties.position ?? { x: 0, y: 0 }
            };

            if (element.type === 'scheduled_trigger') {
                node.type = 'schedule';
                node.data.trigger_date = element.properties.trigger_date;
                node.data.recurring_interval = element.properties.recurring_interval;
            } else if (element.type === 'task') {
                node = TaskNodeHelper.convertToReactFlowNode(element);
            } else if (element.type === 'approval_task') {
                node = ApprovalTaskNodeHelper.convertToReactFlowNode(element);
            }
            if (element.type === 'automation_task') {
                node = AutomationNodeHelper.convertToReactFlowNode(element);
            }
            flowNodes.push(node);
        });

        // flowNodes.push({
        //     id: 'trigger-1',
        //     type: 'trigger',
        //     position: {
        //         x: trigger.position?.x ?? 100,
        //         y: trigger.position?.y - 200 ?? 100
        //     },
        //     data: {
        //         type: trigger.type
        //     },
        //     width: 300,
        //     height: 136
        // });
        // flowEdges.push({
        //     id: 'trigger-edge',
        //     source: 'trigger-1',
        //     target: trigger.id,
        //     markerEnd: {
        //         type: 'arrow',
        //         color: '#EE9A8F'
        //     }
        // });

        return [flowNodes, flowEdges];
    } catch (e) {
        return [[], []];
    }
};

export const createDocumentBuilder = (workflow?: any) => {
    if (!workflow) {
        return defaultValue;
    }

    const pages = workflow.active_version.document_version.content.map(createDocumentBuilderPage);
    const [flowNodes, flowEdges] = extractFlowNodesAndEdges(workflow);

    return {
        workflowId: workflow.id,
        documentId: workflow.active_version.document_version.document_id,
        documentVersionId: workflow.active_version.document_version.id,
        workflowName: workflow.name,
        workflowStatus: workflow.status,
        workflowCreationStatus: workflow.creation_status,
        pages: pages ?? [defaultPage(1)],
        workflow: {
            flowStyle: {
                rowHeight: 180,
                colWidth: 300,
                floorSpace: 20
            },
            flowNodes,
            flowEdges,
            taskAssigned: false,
            taskApproved: false
        },
        workflowVersionId: workflow.active_version.id,
        workflowDurationInDays: 0
    };
};

export const createDocumentBuilderPage = (page: any) => {
    const sections = page.sections.map(createDocumentBuilderSection);
    return {
        pageId: page.id,
        pageName: page.name,
        pageVariable: page.is_variable,
        pageNumber: page.number,
        pageSections: sections,
        pageChecked: false,
        pageClicked: false
    };
};

export const createDocumentBuilderSection = (section: any) => {
    const generateFieldItems = (field: any) => {
        if (field.type === FIELD_TYPE.TABLE) {
            return ['', ''];
        }
        if (field.type === FIELD_TYPE.DROPDOWN) {
            return field.configuration.options;
        }
        return undefined;
    };

    const fields = section.fields.map((field: any) => ({
        fieldId: field.id,
        fieldType: toFieldTypeEnum(field.type),
        fieldName: field.title,
        fieldValue: '',
        fieldItems: generateFieldItems(field),
        fieldTime: Date.now()
    }));
    return {
        sectionId: section.id,
        sectionName: section.name,
        sectionVariable: section.is_variable,
        sectionFields: fields,
        secondCols: [],
        sectionChecked: false,
        sectionClicked: false
    };
};

const useDocumentBuilder = (workflow?: any) => {
    const [documentBuilder, setDocumentBuilder] = useState<FormData>(workflow ? createDocumentBuilder(workflow) : defaultValue);
    const [workflowIsBeingEdited, setWorkflowIsBeingEdited] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);
    const [pageIndex, setPageIndex] = useState(0);
    const { idClient } = useParams();
    const { member } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setDocumentBuilder(workflow ? createDocumentBuilder(workflow) : defaultValue);
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.log('newVersionWasForWorkflowUpdate', workflowIsBeingEdited);
    }, [workflowIsBeingEdited]);

    useEffect(() => {
        console.log('documentBuilder has changed', documentBuilder);
    }, [documentBuilder]);

    //  Page interactions
    const addPage = useCallback(
        () => setDocumentBuilder({ ...documentBuilder, pages: [...documentBuilder.pages, defaultPage(documentBuilder.pages.length)] }),
        [documentBuilder]
    );

    const updatePage = useCallback((pageIndex, page) => {
        setDocumentBuilder((currState: any) =>
            update(currState, {
                pages: {
                    [pageIndex]: {
                        $set: page
                    }
                }
            })
        );
    }, []);

    const duplicatePage = useCallback(
        (pageIndex: number) => {
            const newPage = { ...documentBuilder.pages[pageIndex] };
            newPage.pageName = `${newPage.pageName} (copy)`;
            newPage.pageId = uuid();
            newPage.pageSections = newPage.pageSections.map((section: any) => {
                const newSection = { ...section };
                newSection.sectionId = uuid();
                newSection.sectionFields = newSection.sectionFields.map((field: any) => {
                    const newField = { ...field };
                    newField.fieldId = uuid();
                    return newField;
                });
                return newSection;
            });

            setDocumentBuilder((currState: any) =>
                update(currState, {
                    pages: {
                        $splice: [[pageIndex + 1, 0, newPage]]
                    }
                })
            );
        },
        [documentBuilder]
    );

    const removePage = useCallback(
        (pageIndex: number) => {
            if (documentBuilder.pages.length < 2) return;

            const fieldIdsToDelete = documentBuilder.pages[pageIndex].pageSections
                .map((section: any) => section.sectionFields.map((field: any) => field.fieldId))
                .flat();

            const updatedWorkflow = documentBuilder.workflow;

            updatedWorkflow.flowNodes.forEach((node: any) => {
                if (node?.data?.document_permissions) {
                    node.data.document_permissions = node.data.document_permissions.filter(
                        (permission: any) => !fieldIdsToDelete.includes(permission.field_id)
                    );
                }
            });

            const updatedPages = documentBuilder.pages
                .filter((page: any, index: number) => page.pageIndex !== pageIndex)
                .map((page: any) => {
                    if (page.pageIndex > pageIndex) {
                        return {
                            ...page,
                            pageIndex: page.pageIndex - 1
                        };
                    }
                    return page;
                });

            setPageIndex(pageIndex - 1);
            setDocumentBuilder(update(documentBuilder, { pages: { $set: updatedPages }, workflow: { $set: updatedWorkflow } }));
        },
        [documentBuilder]
    );

    // Section interactions
    const addSection = useCallback(
        () =>
            setDocumentBuilder((currState: any) =>
                update(currState, {
                    pages: {
                        [pageIndex]: {
                            pageSections: {
                                $push: [defaultSection(currState.pages[pageIndex].pageSections.length)]
                            }
                        }
                    }
                })
            ),
        [pageIndex]
    );

    const updateSection = useCallback((pageIndex, sectionIndex, section) => {
        setDocumentBuilder((currState: any) =>
            update(currState, {
                pages: {
                    [pageIndex]: {
                        pageSections: {
                            [sectionIndex]: {
                                $set: section
                            }
                        }
                    }
                }
            })
        );
    }, []);

    const duplicateSection = useCallback(
        (sectionId) => {
            const sectionIndex = documentBuilder.pages[pageIndex].pageSections.findIndex((section) => section.sectionId === sectionId);
            const sectionToDuplicate = documentBuilder.pages[pageIndex].pageSections[sectionIndex];
            if (!sectionToDuplicate) return;
            const newSection = {
                ...sectionToDuplicate,
                sectionId: uuid(),
                sectionFields: sectionToDuplicate.sectionFields.map((field) => ({
                    ...field,
                    fieldId: uuid()
                }))
            };

            setDocumentBuilder((currState: any) =>
                update(currState, {
                    pages: {
                        [pageIndex]: {
                            pageSections: {
                                $splice: [[sectionIndex + 1, 0, newSection]]
                            }
                        }
                    }
                })
            );
        },
        [documentBuilder.pages, pageIndex]
    );

    const removeSection = useCallback(
        (sectionId) => {
            const fieldIdsToDelete = documentBuilder.pages[pageIndex].pageSections
                .filter((section) => section.sectionId === sectionId)
                .map((section: any) => section.sectionFields.map((field: any) => field.fieldId))
                .flat();

            const updatedWorkflow = documentBuilder.workflow;

            updatedWorkflow.flowNodes.forEach((node: any) => {
                if (node?.data?.document_permissions) {
                    node.data.document_permissions = node.data.document_permissions.filter(
                        (permission: any) => !fieldIdsToDelete.includes(permission.field_id)
                    );
                }
            });

            const updatedSections = documentBuilder.pages[pageIndex].pageSections.filter(
                (section: any, index: number) => section.sectionId !== sectionId
            );

            setDocumentBuilder((currState: any) =>
                update(currState, {
                    pages: { [pageIndex]: { pageSections: { $set: updatedSections } } },
                    workflow: { $set: updatedWorkflow }
                })
            );
        },
        [documentBuilder.pages, documentBuilder.workflow, pageIndex]
    );

    const findSection = useCallback(
        (section: Section) => {
            const f = {
                section,
                index: documentBuilder.pages[pageIndex].pageSections.indexOf(section)
            };
            return f;
        },
        [documentBuilder, pageIndex]
    );

    const moveSection = useCallback(
        (section: Section, atIndex: number) => {
            const { section: resSection, index } = findSection(section);
            setDocumentBuilder((currState: any) =>
                update(currState, {
                    pages: {
                        [pageIndex]: {
                            pageSections: {
                                $splice: [
                                    [index, 1],
                                    [atIndex, 0, resSection]
                                ]
                            }
                        }
                    }
                })
            );
        },
        [findSection, setDocumentBuilder, pageIndex]
    );

    // Field interactions
    const addField = useCallback(
        (sectionIndex, type, handler) => {
            setDocumentBuilder((currState: any) =>
                update(currState, {
                    pages: { [pageIndex]: { pageSections: { [sectionIndex]: { sectionFields: { $push: [defaultField(type)] } } } } }
                })
            );
            handler();
        },
        [pageIndex]
    );

    const findField = useCallback(
        (sectionIndex: number, field: Field) => {
            const f = {
                field,
                index: documentBuilder.pages[pageIndex].pageSections[sectionIndex].sectionFields.indexOf(field)
            };
            return f;
        },
        [documentBuilder, pageIndex]
    );

    const removeField = useCallback(
        (sectionIndex, fieldId) => {
            const updatedWorkflow = documentBuilder.workflow;

            updatedWorkflow.flowNodes.forEach((node: any) => {
                if (node?.data?.document_permissions) {
                    node.data.document_permissions = node.data.document_permissions.filter(
                        (permission: any) => permission.field_id !== fieldId
                    );
                }
            });

            const updatedFields = documentBuilder.pages[pageIndex].pageSections[sectionIndex].sectionFields.filter(
                (field: any) => field.fieldId !== fieldId
            );

            setDocumentBuilder((currState: any) =>
                update(currState, {
                    pages: {
                        [pageIndex]: {
                            pageSections: {
                                [sectionIndex]: {
                                    sectionFields: { $set: updatedFields }
                                }
                            }
                        }
                    },
                    workflow: { $set: updatedWorkflow }
                })
            );
        },
        [documentBuilder.pages, documentBuilder.workflow, pageIndex]
    );

    const moveField = useCallback(
        (sectionIndex: number, field: Field, atIndex: number) => {
            const { field: resField, index } = findField(sectionIndex, field);
            setDocumentBuilder((currState: any) =>
                update(currState, {
                    pages: {
                        [pageIndex]: {
                            pageSections: {
                                [sectionIndex]: {
                                    sectionFields: {
                                        $splice: [
                                            [index, 1],
                                            [atIndex, 0, resField]
                                        ]
                                    }
                                }
                            }
                        }
                    }
                })
            );
        },
        [setDocumentBuilder, pageIndex, findField]
    );

    const changeFieldType = (sectionIndex: number, fieldIndex: number, type: FIELD_TYPE) => {
        const updateData: Field = {
            fieldId: documentBuilder.pages[pageIndex].pageSections[sectionIndex].sectionFields[fieldIndex].fieldId,
            fieldType: type,
            fieldName: '',
            fieldValue: '',
            fieldItems: type === FIELD_TYPE.TABLE || type === FIELD_TYPE.DROPDOWN ? ['', ''] : undefined,
            fieldTime: Date.now()
        };
        setDocumentBuilder((currState: any) =>
            update(currState, {
                pages: {
                    [pageIndex]: { pageSections: { [sectionIndex]: { sectionFields: { [fieldIndex]: { $set: updateData } } } } }
                }
            })
        );
    };

    const updateFieldName = (sectionIndex: number, fieldIndex: number, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setDocumentBuilder(() =>
            update(documentBuilder, {
                pages: {
                    [pageIndex]: {
                        pageSections: {
                            [sectionIndex]: { sectionFields: { [fieldIndex]: { fieldName: { $set: e.target.value } } } }
                        }
                    }
                }
            })
        );
    };

    const updateFieldValue = (sectionIndex: number, fieldIndex: number, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setDocumentBuilder(() =>
            update(documentBuilder, {
                pages: {
                    [pageIndex]: {
                        pageSections: {
                            [sectionIndex]: { sectionFields: { [fieldIndex]: { fieldValue: { $set: e.target.value } } } }
                        }
                    }
                }
            })
        );
    };

    const [createWorkflow] = useMutation(CREATE_WORKFLOW, {
        onCompleted: (data) => {}
    });

    const saveChanges = useCallback(async () => {
        const createNewWorkflowAndWorkflowVersion = async () => {
            const document = toSnikpicDocument(documentBuilder);

            const dbInput = toWorkflowDocumentInput(document);

            const createWorkflowResponse = await createWorkflow({
                variables: {
                    client_organization_id: idClient,
                    name: documentBuilder.workflowName,
                    document_name: `${documentBuilder.workflowName} - document`
                }
            });

            const workflow = createWorkflowResponse.data.insert_workflow_workflows_one;

            const workflowVersion = await createWorkflowVersion({
                workflowId: workflow.id,
                documentId: workflow.document.id,
                content: dbInput.pages
            });

            setDocumentBuilder({
                ...documentBuilder,
                documentId: workflow.document.id,
                documentVersionId: workflowVersion.document_version.id,
                workflowStatus: 'DRAFT',
                workflowVersionId: workflowVersion.id,
                workflowId: workflow.id
            });
        };

        const updateWorkflowVersion = async () => {
            const document = toSnikpicDocument(documentBuilder);

            const dbInput = toWorkflowDocumentInput(document);

            await updateDocumentVersion({
                documentVersionId: documentBuilder.documentVersionId,
                content: dbInput.pages,
                organizationId: member?.organizationId || ''
            });
        };

        const createNewWorkflowVersionAndDocumentVersion = async () => {
            const document = toSnikpicDocument(documentBuilder);
            const dbInput = toWorkflowDocumentInput(document);

            const workflowVersion = await createWorkflowVersion({
                workflowId: documentBuilder.workflowId,
                documentId: documentBuilder.documentId,
                content: dbInput.pages
            });

            setDocumentBuilder((newDocumentBuilder) => ({
                ...newDocumentBuilder,
                documentVersionId: workflowVersion.document_version.id,
                workflowVersionId: workflowVersion.id
            }));
        };

        if (documentBuilder.workflowStatus === '') {
            // create new workflow version
            await createNewWorkflowAndWorkflowVersion();
        } else if (documentBuilder.workflowStatus === 'DRAFT') {
            // update workflow version
            await updateWorkflowVersion();
        } else if (documentBuilder.workflowStatus === 'IN_PROGRESS') {
            // update workflow version
            // TODO create new workflow version
            if (!workflowIsBeingEdited) {
                await createNewWorkflowVersionAndDocumentVersion();
                setWorkflowIsBeingEdited(true);
            } else {
                await updateWorkflowVersion();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentBuilder, setDocumentBuilder]);

    return {
        documentBuilder,
        setDocumentBuilder,
        pageIndex,
        setPageIndex,
        addPage,
        updatePage,
        removePage,
        duplicatePage,
        addSection,
        updateSection,
        duplicateSection,
        removeSection,
        moveSection,
        findSection,
        addField,
        removeField,
        moveField,
        findField,
        changeFieldType,
        updateFieldName,
        updateFieldValue,
        loading,
        saveChanges,
        workflowIsBeingEdited
    };
};

export default useDocumentBuilder;
