// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconMessage, IconPercentage, IconUser, IconUsers, IconLayoutColumns } from '@tabler/icons';
import { GroupMenuItems } from 'types';
import bx_store from 'assets/images/bx_store.svg';
import { boolean } from 'yup/lib/locale';

const menu: GroupMenuItems = {
    id: 'sample-docs-roadmap-menu',
    type: 'group',
    title: 'Menu',
    children: [
        {
            id: 'offers',
            title: <FormattedMessage id="menu_offers" />,
            type: 'item',
            url: '/offers',
            icon: IconPercentage,
            breadcrumbs: false
        },
        {
            id: 'clients',
            title: <FormattedMessage id="clients" />,
            type: 'item',
            url: '/clients',
            icon: IconUser,
            breadcrumbs: false
        },
        {
            id: 'profile',
            title: <FormattedMessage id="profile" />,
            type: 'item',
            url: '/profile',
            icon: IconUser,
            breadcrumbs: false
        },
        {
            id: 'team',
            title: <FormattedMessage id="menu_team" />,
            type: 'item',
            url: '/team',
            icon: IconUsers,
            breadcrumbs: false
        },
        {
            id: 'marketplace',
            title: <FormattedMessage id="marketplace" />,
            type: 'item',
            url: '/marketplace',
            icon: IconUsers,
            breadcrumbs: false
        },
        ...(process.env.REACT_APP_SHOW_CHAT === 'true'
            ? [
                  {
                      id: 'messages',
                      title: <FormattedMessage id="menu_messages" />,
                      type: 'item',
                      url: '/messages',
                      icon: IconMessage,
                      breadcrumbs: false
                  }
              ]
            : []),

        {
            id: 'tasks',
            title: <FormattedMessage id="tasks" />,
            type: 'item',
            url: '/tasks',
            icon: IconLayoutColumns,
            breadcrumbs: false
        }
    ]
};

export default menu;
