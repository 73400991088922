import { Box, Typography, useTheme } from '@mui/material';

import TriggerNode from '../../../../../../features/workflow-builder/nodes/start-node';
import ScheduleNode from '../../../../../../features/workflow-builder/nodes/schedule-node';
import WebhookNode from '../../../../../../features/workflow-builder/nodes/webhookNode';
import ApprovalNode from '../../../../../../../../../../../../features/workflow/workflow-builder/nodes/ApprovalTask/ApprovalTaskNode';
import ReactFlow, { Edge, Node, useEdgesState, useNodesState } from 'react-flow-renderer';
import AutomationNode from 'features/workflow-automation/components/AutomationNode';
import TaskNode from 'features/workflow/workflow-builder/nodes/Task/TaskNode';

const nodeTypes = {
    triggerNode: TriggerNode,
    scheduledNode: ScheduleNode,
    webhookingNode: WebhookNode,
    taskNode: TaskNode,
    automationNode: AutomationNode,
    approvalNode: ApprovalNode
};

const initialNodes: Node[] = [];

const initialEdges: Edge[] = [];

const WorkflowPreview = () => {
    const theme = useTheme();
    const [nodes, , onNodesChange] = useNodesState(initialNodes);
    const [edges, , onEdgesChange] = useEdgesState(initialEdges);

    return (
        <Box
            sx={{
                border: '1px dashed #D4DBEA',
                borderRadius: '8px',
                height: '850px',
                margin: '24px',
                padding: '24px'
            }}
        >
            <Typography sx={{ fontWeight: 500, fontSize: '16px', color: theme.palette.text.primary }}>Steps</Typography>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                nodesDraggable={false}
                defaultZoom={0.8}
            />
        </Box>
    );
};

export default WorkflowPreview;
