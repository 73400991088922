import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { AutomationConfigurationDocumentSelectorField, AutomationConfigurationField } from '@sni4/snikpic-common';
import { RowBody } from 'views/pages/clients/features/detail/workflow/features/document-builder/ui';
import { Page } from 'views/pages/clients/features/detail/workflow/features/types';
import { RequiredTag } from './RequiredTag';
import { FormatMultiLangString } from 'features/workflow-automation/utils/multi-lang-string.util';

interface DocumentFieldSelectorProps {
    pages: Page[];
    pageIndex?: number;
    sectionIndex?: number;
    configuration: AutomationConfigurationDocumentSelectorField;
    path: string;
    updateConfiguration: (arg: { configuration: AutomationConfigurationField; path: string }) => void;
}

export const DocumentFieldSelector = ({
    pages,
    pageIndex,
    sectionIndex,
    configuration,
    path,
    updateConfiguration: updateConfigurationState
}: DocumentFieldSelectorProps) => {
    const [selectedPageIndex, setSelectedPageIndex] = useState<string>(pageIndex || pageIndex === 0 ? `${pageIndex}` : '');
    const selectedPage = pages[parseInt(selectedPageIndex, 10)];
    const [selectedSectionIndex, setSelectedSectionIndex] = useState<string>(sectionIndex || sectionIndex === 0 ? `${sectionIndex}` : '');
    const selectedSection = selectedPage?.pageSections[parseInt(selectedSectionIndex, 10)];
    const [selectedFieldIndex, setSelectedFieldIndex] = useState<string>();
    const allowedFieldToBeSelected =
        pages[parseInt(selectedPageIndex, 10)]?.pageSections[parseInt(selectedSectionIndex, 10)]?.sectionFields?.filter((elem) =>
            (configuration.source_type as any[]).includes(elem.fieldType)
        ) || [];

    const selectedField = selectedFieldIndex && allowedFieldToBeSelected[parseInt(selectedFieldIndex, 10)];

    const [mappedValue, setMappedValue] = useState<string[]>(configuration?.selected_object?.mapped_value || []);

    const theme = useTheme();

    useEffect(() => {
        if (configuration?.selected_object?.id) {
            const pageIndex = pages.findIndex((p) => p.pageId === configuration?.selected_object?.page_id);
            setSelectedPageIndex(pageIndex.toString());
            const sectionIndex = pages[pageIndex].pageSections.findIndex((s) => s.sectionId === configuration?.selected_object?.section_id);
            setSelectedSectionIndex(sectionIndex.toString());
            const fieldIndex = allowedFieldToBeSelected.findIndex((f) => f.fieldId === configuration?.selected_object?.id);
            setSelectedFieldIndex(fieldIndex.toString());

            // TODO : if index is not found, set the selected object to null
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allowedFieldToBeSelected, pages]);

    const handlePageChange = (event: SelectChangeEvent) => {
        if (selectedPageIndex !== event.target.value.toString()) {
            setSelectedSectionIndex('');
            setSelectedFieldIndex('');
        }
        setSelectedPageIndex(event.target.value.toString());
    };

    const handleSectionChange = (event: SelectChangeEvent) => {
        if (selectedSectionIndex !== event.target.value.toString()) {
            setSelectedFieldIndex('');
        }
        setSelectedSectionIndex(event.target.value.toString());
    };

    const handleFieldChange = (event: SelectChangeEvent) => {
        const newSelectedFieldIndex = event.target.value.toString();
        setSelectedFieldIndex(newSelectedFieldIndex);
        const selectedField = allowedFieldToBeSelected[parseInt(newSelectedFieldIndex, 10)];
        updateConfigurationState({
            configuration: {
                ...configuration,
                selected_object: { id: selectedField.fieldId, section_id: selectedSection.sectionId, page_id: selectedPage.pageId }
            },
            path
        });
    };

    const handleMappedValueChange = (event: SelectChangeEvent, index: number) => {
        if (configuration?.selected_object?.id) {
            const newMappedValue = [...mappedValue];
            newMappedValue[index] = event.target.value;
            setMappedValue(newMappedValue);

            updateConfigurationState({
                configuration: {
                    ...configuration,
                    selected_object: { ...(configuration.selected_object || {}), mapped_value: newMappedValue }
                },
                path
            });
        }
    };

    return (
        <>
            <RowBody sx={{ gap: '16px' }}>
                <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
                    <FormatMultiLangString multiLangString={configuration.label} />
                </Typography>
                {configuration.required && <RequiredTag />}
            </RowBody>
            <Box sx={{ display: 'flex' }}>
                {!pageIndex?.toString() && (
                    <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
                        <InputLabel id="demo-simple-select-helper-label">Page</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label-2"
                            id="demo-simple-select-helper-2"
                            value={selectedPageIndex}
                            label="Page"
                            onChange={handlePageChange}
                            sx={{ '& .MuiSelect-select': { fontWeight: 400, backgroundColor: 'white' } }}
                        >
                            {pages.map((page, index) => (
                                <MenuItem key={page.pageId} value={index}>
                                    {page.pageName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                {!sectionIndex?.toString() && (selectedPageIndex || selectedPageIndex === '0') && (
                    <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
                        <InputLabel id="demo-simple-select-helper-label">Section</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={selectedSectionIndex}
                            label="Section"
                            onChange={handleSectionChange}
                            sx={{ '& .MuiSelect-select': { fontWeight: 400, backgroundColor: 'white' } }}
                        >
                            {pages[parseInt(selectedPageIndex || '0', 10)].pageSections.map((section, index) => (
                                <MenuItem key={section.sectionId} value={index}>
                                    {section.sectionName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                {(selectedSectionIndex || selectedSectionIndex === '0') && (
                    <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
                        <InputLabel id="demo-simple-select-helper-label">Field</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={selectedFieldIndex}
                            label="Field"
                            onChange={handleFieldChange}
                            sx={{ '& .MuiSelect-select': { fontWeight: 400, backgroundColor: 'white' } }}
                        >
                            {allowedFieldToBeSelected.map((field, index) => (
                                <MenuItem key={field.fieldId} value={index}>
                                    {field.fieldName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </Box>
            {configuration.type === 'DOCUMENT_FIELD_DROPDOWN' && selectedField && (
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        {configuration.value_for_mapping.map((valueToMap, index) => (
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '16px' }} key={valueToMap}>
                                <RowBody
                                    sx={{
                                        justifyContent: 'center',
                                        width: '84px',
                                        height: '24px',
                                        borderRadius: '16px',
                                        backgroundColor: theme.palette.orange[100]
                                    }}
                                >
                                    <Typography variant="subtitle2" sx={{ color: theme.palette.grey[700] }}>
                                        {valueToMap}
                                    </Typography>
                                </RowBody>
                                <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
                                    to be mapped with:
                                </Typography>

                                <FormControl sx={{ m: 1, width: 120 }}>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={mappedValue[index]}
                                        onChange={(e) => handleMappedValueChange(e, index)}
                                        sx={{ '& .MuiSelect-select': { fontWeight: 400, backgroundColor: 'white' } }}
                                    >
                                        {selectedField?.fieldItems?.map((field: any, index: number) => (
                                            <MenuItem key={field} value={field}>
                                                {field}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        ))}
                    </Box>
                </Box>
            )}
        </>
    );
};
