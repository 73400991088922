import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Table, TableBody, TableContainer, Paper, CircularProgress } from '@mui/material';
import {
    GET_ORGANIZATION_MEMBERS,
    ADD_MEMBER_TO_PROIVIDED_SERVICE,
    CHANGE_PROJECT_STATUS,
    GET_PROJECTS_FOR_CLIENT,
    REMOVE_PROJECT_USER
} from 'services/graphQL';

import { ProjectMemberType } from 'types/clients';
import { useLazyQuery, useMutation } from '@apollo/client';
import { formatProject } from 'utils/formatters';
import { updateCurrentClient } from 'store/slices/clients';
import { openSnackbar } from 'store/slices/snackbar';
import { useDispatch } from 'store';

import { ServiceTableRow } from './service-table-row';
import { ServiceTableHead } from './service-table-head';
import { ProvidedServicesDrawer } from './provided-service-drawer';
import useAuth from 'hooks/useAuth';

export function ClientProvidedServices({ projects }: any) {
    // console.log('In ClientProvidedServices');
    const [currentService, setCurrentService] = useState('');
    const [busy, setBusy] = useState(false);
    const { idClient } = useParams();
    const dispatch = useDispatch();
    const { member } = useAuth();

    const [availableTeamMembers, setAvailableTeamMembers] = useState<ProjectMemberType[]>([]);
    const [, { refetch }] = useLazyQuery(GET_PROJECTS_FOR_CLIENT);
    const [getOrgMembersByRole] = useLazyQuery(GET_ORGANIZATION_MEMBERS);
    const [changeStatus] = useMutation(CHANGE_PROJECT_STATUS);
    const [addMembertoProject] = useMutation(ADD_MEMBER_TO_PROIVIDED_SERVICE);
    const [removeProjectUser, { loading: deleting }] = useMutation(REMOVE_PROJECT_USER, {
        onError: (e: any) => {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e.message,
                    variant: 'error'
                })
            );
        }
    });
    const currentAgencyId = member?.organizationId;

    const openEditModal = (id: string) => setCurrentService(id);
    const closeEditModal = () => setCurrentService('');

    const formatData = (data: any) => {
        const result: ProjectMemberType[] = data.map((orgMember: any) => ({
            orgMemberId: orgMember.id,
            icon: orgMember.user.image_url,
            firstName: orgMember.user.first_name,
            lastName: orgMember.user.last_name
        }));
        return result;
    };

    const onViewService = (id: string) => openEditModal(id);

    const showError = (message: string) => {
        dispatch(
            openSnackbar({
                open: true,
                message,
                variant: 'error',
                close: true
            })
        );
    };

    useEffect(() => {
        const getMembers = async () => {
            try {
                setBusy(true);
                const { data } = await getOrgMembersByRole({
                    variables: { clientOrganizationId: idClient }
                });
                console.log('In ClientProvidedServices > data', data);
                const result = formatData(data?.organization_members ?? []);
                console.log('ClientProvidedServices > result', result);
                setAvailableTeamMembers(result);
            } catch (e: any) {
                showError(e.message);
            } finally {
                setBusy(false);
            }
        };

        getMembers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refreshProjects = async () => {
        const { data } = await refetch({ organizationId: currentAgencyId, clientId: idClient });
        const formattedProjects = formatProject(data?.projects);
        dispatch(updateCurrentClient({ providedServices: formattedProjects }));
    };

    const onChangeStatus = async (status: string, id: string) => {
        setBusy(true);

        try {
            await changeStatus({
                variables: {
                    projectId: id,
                    status
                }
            });

            await refreshProjects();
        } catch (e: any) {
            showError(e.message);
        } finally {
            setBusy(false);
        }
    };

    const onAddTeamMember = async (memberId: string, projectId: string) => {
        setBusy(true);

        try {
            await addMembertoProject({
                variables: {
                    project_id: projectId,
                    membership_id: memberId
                }
            });
            await refreshProjects();
        } catch (e: any) {
            showError(e.message);
        } finally {
            setBusy(false);
        }
    };

    const onDelTeamMember = async (id: string) => {
        await removeProjectUser({ variables: { id } });
        await refreshProjects();
    };

    return (
        <Box sx={{ width: '100%', mt: 3 }}>
            <Paper sx={{ width: '100%', mb: 2, position: 'relative' }}>
                {busy && (
                    <>
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                backgroundColor: '#0001',
                                zIndex: 1001,
                                pointerEvents: 'none',
                                position: 'absolute'
                            }}
                        />
                        <CircularProgress
                            size={32}
                            sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
                        />
                    </>
                )}
                <TableContainer>
                    <Table sx={{ borderRadius: '8px 8px 0 0', minWidth: 750 }} aria-labelledby="tableTitle">
                        <ServiceTableHead />
                        <TableBody>
                            {projects.map((project: any, index: number) => {
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <ServiceTableRow
                                        project={project}
                                        key={labelId}
                                        availableTeamMembers={availableTeamMembers}
                                        onChangeStatus={onChangeStatus}
                                        onAddTeamMember={onAddTeamMember}
                                        onViewService={onViewService}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <ProvidedServicesDrawer
                open={!!currentService}
                onClose={closeEditModal}
                project={projects.find((proj: any) => proj.id === currentService)}
                availableTeamMembers={availableTeamMembers}
                onAddMember={onAddTeamMember}
                onDelMember={onDelTeamMember}
                deleting={deleting}
            />
        </Box>
    );
}
