import { Box, FormControlLabel, Popover, Radio, RadioGroup, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TuneIcon from '@mui/icons-material/Tune';

interface PopoverRadioProps {
    anchorEl: any;
    id: any;
    closePopover: () => void;
    openPopover: (event: any) => void;
    value: string;
    handleChange: (event: any) => void;
    labels: any;
    sx?: { [key: string]: any };
    button?: boolean;
}

export default function PopoverRadio({
    anchorEl,
    id,
    closePopover,
    openPopover,
    value,
    handleChange,
    labels,
    sx,
    button
}: PopoverRadioProps) {
    const theme = useTheme();
    return (
        <Box>
            {button ? (
                <Box onClick={openPopover} sx={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}>
                    <Typography sx={{ color: theme.palette.grey[300], fontFamily: 'Inter', fontWeight: 500, fontSize: '12px', mr: 1 }}>
                        All comments
                    </Typography>
                    <TuneIcon sx={{ fill: theme.palette.grey[300], transform: 'rotate(90deg)', fontSize: '15px' }} />
                </Box>
            ) : (
                <Box
                    onClick={openPopover}
                    sx={{
                        display: 'flex',
                        p: '4px 12px',
                        borderRadius: '16px',
                        background: theme.palette.primary[500],
                        alignItems: 'center',
                        cursor: 'pointer',
                        ...sx
                    }}
                >
                    <Typography sx={{ color: theme.palette.secondary[400], fontFamily: 'Inter', fontWeight: 500, mr: 1 }}>
                        {value}
                    </Typography>
                    <KeyboardArrowDownIcon sx={{ fill: theme.palette.secondary[400], fontSize: '20px' }} />
                </Box>
            )}
            <Popover
                id={id}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={closePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                sx={{
                    '.MuiPaper-root': {
                        boxShadow: '0px 10px 16px rgba(0, 0, 0, 0.1)',
                        border: `1px solid ${theme.palette.grey[500]}`
                    }
                }}
            >
                <Box sx={{ p: '12px 24px' }}>
                    <RadioGroup value={value} onChange={handleChange}>
                        {labels.map((label: string, index: number) => (
                            <FormControlLabel key={index} value={label} control={<Radio />} label={label} />
                        ))}
                    </RadioGroup>
                </Box>
            </Popover>
        </Box>
    );
}
