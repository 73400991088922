import axios from 'axios';

const API_ENDPOINT_URL = 'https://api.baseql.com/airtable/graphql/appoCz91YUwoolwdN';

export const getSpecialistRequests = async (orgId: string) => {
    const data = JSON.stringify({
        query: `query MyQuery($organizationId: String!) {
                  requests(_filter: { organizationId: $organizationId }, _order_by: { createdAt: "desc" }) {
                      createdAt
                      description
                      id
                      organizationId
                      organizationName
                      role
                      status
                  }
              }`,
        variables: { organizationId: orgId }
    });

    const config: any = {
        method: 'post',
        url: API_ENDPOINT_URL,
        headers: {
            Authorization: `Bearer ${process.env.REACT_APP_BASEQL_TOKEN}`,
            'Content-Type': 'application/json'
        },
        data
    };

    const values = await axios(config);
    return values.data.data?.requests;
};

export const sendSpecialistRequest = async (orgId: string, orgName: string, role: string, description: string) => {
    const data = JSON.stringify({
        query: `mutation MyMutation($description: String, $organizationId: String, $organizationName: String, $role: String, $status: String) {
              insert_requests(
                  organizationName: $organizationName
                  role: $role
                  status: $status
                  description: $description
                  organizationId: $organizationId
              ) {
                  id
                  organizationId
                  organizationName
                  role
                  description
                  status
                  createdAt
              }
          }`,
        variables: {
            organizationId: orgId,
            organizationName: orgName,
            role,
            description,
            status: 'pending'
        }
    });

    const config: any = {
        method: 'post',
        url: API_ENDPOINT_URL,
        headers: {
            Authorization: `Bearer ${process.env.REACT_APP_BASEQL_TOKEN}`,
            'Content-Type': 'application/json'
        },
        data
    };
    await axios(config);
};
