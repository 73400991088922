import { Box, Dialog, Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button } from 'ui-component/extended/Button';
import { Text, DarkText } from 'ui-component/typography';

const items = ['page_likes', 'page_views', 'page_reach'];
interface ConfigureDialogProps {
    openDialogIntegrate: boolean;
    handleCloseDialogAddClient: any;
    setConnection: any;
}
const ConfigureDialog = ({ openDialogIntegrate, handleCloseDialogAddClient, setConnection }: ConfigureDialogProps) => {
    const theme = useTheme();
    const closeDialog = () => {
        setConnection(true);
        handleCloseDialogAddClient();
    };

    return (
        <Dialog
            open={openDialogIntegrate}
            onClose={handleCloseDialogAddClient}
            sx={{ '.MuiPaper-root': { p: 0, width: { xs: '100%', sm: '568px' } } }}
        >
            <Box sx={{ p: '16px 24px', background: theme.palette.grey[700] }}>
                <DarkText sx={{ fontSize: 10, fontWeight: 600, textTransform: 'uppercase' }}>
                    <FormattedMessage id="page" />
                </DarkText>
            </Box>
            <Grid container>
                {items.map((item: string, index: number) => (
                    <Grid key={index} item xs={12} sm={6}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItem: 'center',
                                p: '16px 24px',
                                borderBottom: index + 1 !== items.length ? `1px solid ${theme.palette.grey[800]}` : '',
                                borderRight: (index + 1) % 2 !== 0 ? `1px solid ${theme.palette.grey[800]}` : ''
                            }}
                        >
                            <Text sx={{ fontSize: 14 }}>
                                <FormattedMessage id={item} />
                            </Text>
                            <CheckCircleIcon sx={{ fill: theme.palette.orange.main, fontSize: '20px' }} />
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <Box sx={{ p: '16px 24px', background: theme.palette.grey[700] }}>
                <DarkText sx={{ fontSize: 10, fontWeight: 600, textTransform: 'uppercase' }}>
                    <FormattedMessage id="publication" />
                </DarkText>
            </Box>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItem: 'center',
                            p: '16px 24px',
                            borderBottom: `1px solid ${theme.palette.grey[800]}`,
                            borderRight: { xs: '', sm: `1px solid ${theme.palette.grey[800]}` }
                        }}
                    >
                        <Text sx={{ fontSize: 14 }}>
                            <FormattedMessage id="impressions" />
                        </Text>
                        <CheckCircleIcon sx={{ fill: theme.palette.orange.main, fontSize: '20px' }} />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ borderBottom: `1px solid ${theme.palette.grey[800]}` }}>
                    {' '}
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: '8px 16px' }}>
                <Box sx={{ width: '80px' }}>
                    <Button onClick={closeDialog} variant="secondary" title={<FormattedMessage id="close" />} />
                </Box>
            </Box>
        </Dialog>
    );
};

export default ConfigureDialog;
