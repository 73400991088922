import { Box, Typography, TextField } from '@mui/material';
import { useIntl, FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import React from 'react';

interface DescriptionInputAiToolProps {
    value: string;
    setValue?: any;
    placholder: string;
    totalWordsLength: number;
    error: boolean;
    title: string;
    limit?: boolean;
    setValid?: any;
    rows?: number;
}

const DescriptionInputAiTool = ({
    value,
    setValue,
    placholder,
    totalWordsLength,
    error,
    title,
    limit,
    setValid,
    rows
}: DescriptionInputAiToolProps) => {
    const theme = useTheme();
    const intl = useIntl();
    const [errText, setErrText] = React.useState('');
    React.useEffect(() => {
        if (value.split(' ').length > totalWordsLength) {
            setErrText(`Available word count ${totalWordsLength}`);
        } else if (value.length === 0) {
            setErrText('Required');
        } else {
            setErrText('');
        }
    }, [value]);

    const isValid = () => {
        let valid = false;
        if (limit) {
            valid = value.length <= 2200;
        }
        valid = value.split(' ').length <= totalWordsLength;
        if (setValid) {
            setValid(valid);
        }
        return valid;
    };
    return (
        <Box>
            <Typography mb={2} sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600, fontSize: '14px' }}>
                <FormattedMessage id={title} />
            </Typography>
            <Box sx={{ position: 'relative' }}>
                <TextField
                    sx={{
                        '.MuiOutlinedInput-input': {
                            fontWeight: 400,
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            p: '10px 10px 40px 10px',
                            background: '#fff'
                        }
                    }}
                    id="phoneNumber"
                    name="phoneNumber"
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value);
                    }}
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={rows || 6}
                    maxRows={rows || 6}
                    placeholder={intl.formatMessage({ id: placholder })}
                />
                <Box
                    sx={{ position: 'absolute', bottom: '10px', width: '100%', display: 'flex', justifyContent: 'flex-end', right: '10px' }}
                >
                    <Box sx={{ display: 'flex' }}>
                        <Typography
                            sx={{
                                color: isValid() ? theme.palette.grey[400] : theme.palette.error.main,
                                fontFamily: 'Inter',
                                fontWeight: 500,
                                fontSize: '10px',
                                letterSpacing: '0.12em'
                            }}
                        >
                            {limit ? value.length : value.split(' ').length}
                        </Typography>
                        <Typography
                            sx={{
                                color: isValid() ? theme.palette.grey[400] : theme.palette.error.main,
                                fontFamily: 'Inter',
                                fontWeight: 500,
                                fontSize: '10px',
                                letterSpacing: '0.12em',
                                mr: '10px',
                                textTransform: 'uppercase'
                            }}
                        >
                            {limit ? `/2200 characters` : `/${totalWordsLength} WORDS`}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            {error && (
                <Typography
                    mt={1}
                    sx={{
                        color: theme.palette.error.dark,
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        fontSize: '12px'
                    }}
                >
                    {errText}
                </Typography>
            )}
        </Box>
    );
};

export default DescriptionInputAiTool;
