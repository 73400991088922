import { gql } from '@apollo/client';

const GENERATE_ASSET_URL = gql`
    mutation MyMutation($path: String!) {
        generate_upload_url(arg1: { path: $path }) {
            url
        }
    }
`;

export { GENERATE_ASSET_URL };
