import { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Button } from 'ui-component/extended/Button';
import AddIcon from '@mui/icons-material/Add';
import CaptionGeneratorCard from './CaptionGeneratorCard';
import EditDescDrawer from './EditDescDrawer';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_CAPTIONS } from 'services/graphQL/queries/client/clientAITools';
import AddCaptionGeneratorDrawer from './AddCaptionGeneratorDrawer';
import { GET_BRAND_DESCRIPTION } from 'services/graphQL/queries/aiTool';
import { UPDATE_BRAND_DESCRIPTION } from 'services/graphQL/mutations/aiTools/AiToolsMutations';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { useParams } from 'react-router-dom';

// Utils & services
import { getTasks } from '../../../../../../services/rest/virtual-employee';

// Components
import FinetuningCard from './FinetuningCard';

interface CaptionGeneratorCardType {
    subcategory_id: string;
    client_organization_id: string;
    caption: string;
    concept: string;
    id: string;
}

interface ActionType {
    id: string;
    action: string;
}

const AITools = () => {
    // Context & global state
    const theme = useTheme();
    const dispatch = useDispatch();
    const { idClient } = useParams();

    // Local state
    const [showDivider, setShowDivider] = useState(false);
    const [showCaptionGenerator, setShowCaptionGenerator] = useState(false);
    const [description, setDescription] = useState<string>('');
    const [captionGeneratorCards, setCaptionGeneratorCards] = useState<CaptionGeneratorCardType[]>([]);
    const closeCaptionGenerator = () => setShowCaptionGenerator(false);
    const openCaptionGenerator = () => {
        if (captionGeneratorCards.length < 9) {
            setShowCaptionGenerator(true);
        }
    };
    const closeDivider = () => setShowDivider(false);
    const openDivider = () => setShowDivider(true);
    const [actions, setActions] = useState<ActionType[]>([]);
    console.log('In local state - actions: ', actions);

    // Data fetching (old methods but still used)
    const [getCaptions, { refetch: refetchGetCaptions }] = useLazyQuery(GET_CAPTIONS, {
        onCompleted: (data) => {
            setCaptionGeneratorCards(data.client_organization_caption_examples);
        },
        onError: (e) => {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e.message,
                    variant: 'error'
                })
            );
        }
    });
    const [getBrandDescription, { refetch: refetchGetBrandDescription }] = useLazyQuery(GET_BRAND_DESCRIPTION, {
        onCompleted: (data) => {
            if (data.client_organizations[0].ai_brand_description !== null)
                setDescription(data.client_organizations[0].ai_brand_description);
        },
        onError: (e) => {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e.message,
                    variant: 'error'
                })
            );
        }
    });
    const [updateAiBrandDesc] = useMutation(UPDATE_BRAND_DESCRIPTION, {
        onError: (e) => {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e.message,
                    variant: 'error'
                })
            );
        }
    });

    const handleCaption = () => {
        getCaptions({
            variables: {
                client_organization_id: idClient
            }
        });
    };

    const onUpdate = async (desc: string) => {
        await updateAiBrandDesc({
            variables: {
                ai_brand_description: desc,
                client_organization_id: idClient
            }
        });
        await refetchGetBrandDescription();
    };

    // Data fetching
    const fetchActions = async () => {
        const response = await getTasks({});
        const actions = response.data.map((item: any) => ({
            id: item.id,
            action: item.action,
            role: item.role // Max: this will probably be deleted
        }));
        setActions(actions);
    };

    const initialize = async () => {
        await fetchActions();
    };

    // useEffects
    useEffect(() => {
        getBrandDescription({
            variables: {
                client_organization_id: idClient
            }
        });
        handleCaption();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        initialize();
    }, []);

    return (
        <Box mt={3}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <Typography
                        sx={{
                            textTransform: 'capitalize',
                            color: theme.palette.grey[400],
                            fontFamily: 'Inter',
                            fontWeight: 700,
                            fontSize: '14px',
                            mt: 2
                        }}
                    >
                        <FormattedMessage id="brand_description" />
                    </Typography>
                    <Typography
                        sx={{
                            color: theme.palette.grey[300],
                            fontFamily: 'Inter',
                            fontWeight: 400,
                            fontSize: '14px',
                            mt: 2
                        }}
                    >
                        <FormattedMessage id="brand_desc" />
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Box
                        sx={{
                            border: `1px solid ${theme.palette.grey[500]}`,
                            p: 2,
                            boxShadow: '0px 10px 16px rgba(0, 0, 0, 0.05)',
                            borderRadius: '8px'
                        }}
                    >
                        <Typography
                            sx={{
                                color: theme.palette.grey[600],
                                fontFamily: 'Inter',
                                fontWeight: 500,
                                textTransform: 'uppercase',
                                fontSize: '10px'
                            }}
                        >
                            <FormattedMessage id="brand_description" />
                        </Typography>
                        {description && (
                            <Typography
                                sx={{
                                    color: theme.palette.grey[400],
                                    fontFamily: 'Inter',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    mt: 2
                                }}
                            >
                                {description}
                            </Typography>
                        )}
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                            <Box sx={{ width: { xs: '40%', sm: '80px' } }}>
                                <Button
                                    variant="primary"
                                    icon={<BorderColorOutlinedIcon />}
                                    onClick={openDivider}
                                    title={<FormattedMessage id="edit" />}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={5}>
                <Grid item xs={12} sm={3}>
                    <Typography
                        sx={{
                            textTransform: 'capitalize',
                            color: theme.palette.grey[400],
                            fontFamily: 'Inter',
                            fontWeight: 700,
                            fontSize: '14px',
                            mt: 2
                        }}
                    >
                        <FormattedMessage id="caption_generation" />
                    </Typography>
                    <Typography
                        sx={{
                            color: theme.palette.grey[300],
                            fontFamily: 'Inter',
                            fontWeight: 400,
                            fontSize: '14px',
                            mt: 2
                        }}
                    >
                        <FormattedMessage id="caption_generation_desc" />
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Box
                        sx={{
                            border: `1px solid ${theme.palette.grey[500]}`,
                            p: 2,
                            boxShadow: '0px 10px 16px rgba(0, 0, 0, 0.05)',
                            borderRadius: '8px'
                        }}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography
                                sx={{
                                    color: theme.palette.grey[300],
                                    fontFamily: 'Inter',
                                    fontWeight: 500,
                                    fontSize: '14px'
                                }}
                            >
                                <FormattedMessage id="caption_generation" />
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography
                                    sx={{
                                        color: theme.palette.grey[400],
                                        fontFamily: 'Inter',
                                        fontWeight: 700,
                                        fontSize: '14px'
                                    }}
                                >
                                    {captionGeneratorCards.length}
                                </Typography>
                                <Typography
                                    sx={{
                                        color: theme.palette.grey[300],
                                        fontFamily: 'Inter',
                                        fontWeight: 500,
                                        fontSize: '14px'
                                    }}
                                >
                                    /9
                                </Typography>
                                <AddIcon onClick={openCaptionGenerator} sx={{ ml: 1, fontSize: '20px', cursor: 'pointer' }} />
                            </Box>
                        </Box>
                        {captionGeneratorCards?.map((item: CaptionGeneratorCardType, index: number) => (
                            <CaptionGeneratorCard key={index} item={item} getCaptions={refetchGetCaptions} />
                        ))}
                    </Box>
                </Grid>
            </Grid>
            {actions?.length > 0 &&
                idClient &&
                actions.map((item: ActionType) => (
                    <>
                        <Typography variant="h4" mt={4} mb={2}>
                            {item.action}
                        </Typography>
                        <FinetuningCard taskId={item.id} brandId={idClient} />
                    </>
                ))}
            {/* {idClient && <FinetuningCard taskId="a14601a2-93a6-4275-b6a0-b5875157fa6b" brandId={idClient} />} */}
            <EditDescDrawer open={showDivider} onClose={closeDivider} description={description} onUpdate={onUpdate} />
            <AddCaptionGeneratorDrawer
                open={showCaptionGenerator}
                onClose={closeCaptionGenerator}
                getCaptions={getCaptions}
                refetchGetCaptions={refetchGetCaptions}
            />
        </Box>
    );
};

export default AITools;
