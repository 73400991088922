import { Box, Dialog, Popover, Slide, Typography } from '@mui/material';
import { forwardRef, ReactElement, Ref, useState, useContext, useEffect } from 'react';
import { Handle, NodeProps, Position } from 'react-flow-renderer';
import { TransitionProps } from '@mui/material/transitions';
import { useTheme } from '@mui/material/styles';
import CustomMenuItem from 'views/pages/clients/features/detail/workflow/features/document-builder/subcomponents/menuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ReactComponent as ArrowRightCircleIcon } from 'assets/images/arrowRightCircle.svg';
import { ReactComponent as CopyIcon } from 'assets/images/copy.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/trash.svg';
import TaskProvider from 'views/pages/clients/features/detail/workflow/features/workflow-builder/components/task/context';
import DuplicateNode from 'views/pages/clients/features/detail/workflow/features/workflow-builder/diagram/hooks/duplicate-node';
import DocumentBuilderContext from 'views/pages/clients/features/detail/workflow/features/document-builder/context/workflow.context';
import { removeNodes } from 'views/pages/clients/features/detail/workflow/features/workflow-builder/diagram/hooks/delete-nodes';
import { FlowNode, RowBody } from 'views/pages/clients/features/detail/workflow/features/document-builder/ui';
import DialogConfirmDelete from 'views/pages/clients/features/detail/workflow/features/workflow-builder/sideBar/DialogConfirmDelete';
import { AutomationConfiguration, MultiLanguageString } from '@sni4/snikpic-common';
import { AutomationNodeDialog } from '../AutomationNodeDialog';
import { ReactFlowAutomationNode } from 'features/workflow/types/react-flow.types';
import { FormattedMessage, useIntl } from 'react-intl';
import { availableAutomationNodes } from 'features/workflow-automation/types';
import SVG from 'react-inlinesvg';
import { GreenCheckMark, RedCrossMark } from 'ui-component/Icons';
import { AutomationNodeHelper } from 'features/workflow-automation/utils/automation-node.util';
import { FormatMultiLangString } from 'features/workflow-automation/utils/multi-lang-string.util';

const Transition = forwardRef((props: TransitionProps & { children: ReactElement<any, any> }, ref: Ref<unknown>) => (
    <Slide direction="down" ref={ref} {...props} />
));

interface AutomationNodeData {
    automationConfiguration: AutomationConfiguration;
    getId: () => string;
    name: string;
    description: string;
}

const AutomationNode = ({ yPos, xPos, type, id, data }: NodeProps<AutomationNodeData>) => {
    const theme = useTheme();
    const { documentBuilder } = useContext(DocumentBuilderContext);
    const [open, setOpen] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [isDataValid, setIsDataValid] = useState<boolean>(false);

    useEffect(() => {
        if (data?.automationConfiguration) {
            setIsDataValid(AutomationNodeHelper.isValid(data.automationConfiguration.fields));
        }
    }, [data]);

    const handleCloseDialog = () => setOpenDialog(false);
    const handleOpenDialog = () => setOpenDialog(true);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSettingsOpen = () => {
        setOpen(true);
    };

    const [moreAnchorEl, setMoreAnchorEl] = useState(null);
    const morePopoverOpen = Boolean(moreAnchorEl);
    const morePopoverId = morePopoverOpen ? 'simple-popover' : undefined;

    const handleClickMorePopupOver = (event: any) => {
        setMoreAnchorEl(event.currentTarget);
    };

    const handleCloseMorePopupOver = () => {
        setMoreAnchorEl(null);
    };

    const handleDuplicate = () => {
        const { getId } = data;
        const setNodes = documentBuilder.workflow.setNodes;
        DuplicateNode({ getId, setNodes, type, xPos, yPos });
        handleCloseMorePopupOver();
    };

    const handleDeleteNode = () => {
        removeNodes(documentBuilder.workflow, id);
        handleCloseMorePopupOver();
    };

    const node = documentBuilder.workflow.flowNodes.find((node: any) => node.id === id) as ReactFlowAutomationNode;

    const availableAutomation = availableAutomationNodes().find(
        (automation) => automation.automation_set === node.data.automation_configuration.automation_set
    );

    const automationIcon = availableAutomation?.icon || '';

    return (
        <TaskProvider formId={id}>
            <FlowNode>
                <Handle
                    type="target"
                    position={Position.Top}
                    isConnectable
                    style={{ width: '9px', height: '9px', opacity: 1, background: '#98A2B3' }}
                />
                <RowBody sx={{ justifyContent: 'space-between' }}>
                    <RowBody sx={{ flex: 1, gap: 1 }}>
                        <SVG src={automationIcon} width="20px" />
                        <Typography variant="subtitle1" sx={{ color: theme.palette.text.primary }}>
                            <FormatMultiLangString log="node label" multiLangString={node.data.automation_configuration.label} />
                        </Typography>
                    </RowBody>
                    <MoreHorizIcon sx={{ cursor: 'pointer' }} onClick={handleClickMorePopupOver} />
                </RowBody>
                <RowBody sx={{ justifyContent: 'space-between', cursor: 'pointer' }} onClick={handleSettingsOpen}>
                    <Typography variant="body1" sx={{ color: '#5A5A5A' }}>
                        {documentBuilder.workflow.taskAssigned ? 'View' : 'Set'} parameters
                    </Typography>
                    <ArrowRightCircleIcon style={{ width: '18px', height: '18px' }} />
                </RowBody>
                <RowBody sx={{ gap: 1 }}>
                    {node?.data?.is_configuration_valid ? <GreenCheckMark /> : <RedCrossMark />}{' '}
                    <Typography variant="caption" sx={{ color: '#5A5A5A' }}>
                        <FormattedMessage id={isDataValid ? 'configured' : 'missing_parameters'} />
                    </Typography>
                </RowBody>
                <Popover
                    id={morePopoverId}
                    open={morePopoverOpen}
                    anchorEl={moreAnchorEl}
                    onClose={handleCloseMorePopupOver}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Box sx={{ width: '142px' }}>
                        <CustomMenuItem icon={<CopyIcon />} name="Duplicate" onClick={handleDuplicate} />
                        <CustomMenuItem icon={<DeleteIcon />} name="Delete" onClick={handleOpenDialog} />
                    </Box>
                </Popover>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    onClose={handleClose}
                    sx={{ '.MuiPaper-root': { width: { xs: '100%', sm: '563px' }, height: { xs: '100%', sm: '90vh' }, p: 0 } }}
                >
                    <AutomationNodeDialog onClose={handleClose} isOpen={open} id={id} />
                </Dialog>
                <Handle
                    type="source"
                    position={Position.Bottom}
                    isConnectable
                    style={{ width: '9px', height: '9px', opacity: 1, background: '#98A2B3' }}
                />
            </FlowNode>
            <DialogConfirmDelete open={openDialog} handleClose={handleCloseDialog} onClick={handleDeleteNode} />
        </TaskProvider>
    );
};

export default AutomationNode;
