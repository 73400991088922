import { Avatar, Box, CircularProgress, MenuItem, Modal, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import { ReactComponent as ChevronDownIcon } from 'assets/images/chevron-down.svg';
import useAuth from 'hooks/useAuth';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_PROJECTS_FOR_CLIENT, GET_SPECIFIC_CLIENT } from 'services/graphQL';
import DocumentBuilderContext from '../document-builder/context/workflow.context';
import { publishWorkflow, verifyWorkflow } from 'services/rest/workflow';
import { Button } from 'ui-component/extended/Button';
import { UPDATE_WORKFLOW_PROJECT_ID } from 'services/graphQL/queries/workflow';
import { useIntl } from 'react-intl';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { ClientDetailsContext } from 'views/pages/clients/context/client-details.context';
import { getProcessmakerRequests } from 'services/rest/workflow_run';
import DialogConfirmCheckbox from 'features/workflow-task-execution/components/CancelWorkflowRunsDialog';
import { generateWorkflowRunTitle } from 'features/workflow-task-execution/utils/workflow-run.utils';
import { LoadingModal } from 'ui-component/LoadingModal';
import CancelWorkflowRunsDialog from 'features/workflow-task-execution/components/CancelWorkflowRunsDialog';

interface PublishContentProps {
    closeModal: () => void;
}

const PublishContent = ({ closeModal }: PublishContentProps) => {
    const { documentBuilder, updatePage, updateSection, saveChanges, workflowIsBeingEdited } = useContext(DocumentBuilderContext);
    const { updateWorkflows } = useContext(ClientDetailsContext);

    const [selectServiceValue, setSelectServiceValue] = useState<string>('');
    const [isPublishing, setIsPublishing] = useState(false);

    const [workflowRuns, setWorkflowRuns] = useState<{ id: number; title: string }[]>([]);
    const [selectedWorkflowRunIdsToCancel, setSelectedWorkflowRunIdsToCancel] = useState<number[]>([]);
    const [openCancelWorkflowRunsDialog, setOpenCancelWorkflowRunsDialog] = useState(false);

    const handleSelectServiceValueChange = (event: SelectChangeEvent) => {
        setSelectServiceValue(event.target.value as string);
    };

    const handlePageVariableChange = (pageIndex: number, page: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (parseInt(event.target.value, 10) > -1) {
            updatePage(pageIndex, { ...page, pageNumber: parseInt(event.target.value, 10) });
        }
    };

    const handleSectionVariableChange =
        (pageIndex: number, sectionIndex: number, section: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
            if (parseInt(event.target.value, 10) > -1) {
                updateSection(pageIndex, sectionIndex, { ...section, sectionNumber: parseInt(event.target.value, 10) });
            }
        };

    const { member } = useAuth();
    const intl = useIntl();
    const currentAgencyId = member?.organizationId;
    const { idClient } = useParams();
    const [getClientById] = useLazyQuery(GET_SPECIFIC_CLIENT);
    const [getClientProjects] = useLazyQuery(GET_PROJECTS_FOR_CLIENT);

    const [updateWorkflowProjectId] = useMutation(UPDATE_WORKFLOW_PROJECT_ID);

    const [clientData, setClientData] = useState<any>({});
    const [clientProjects, setClientProjects] = useState<any>([]);

    const handleOpenCancelWorkflowRunsDialog = () => {
        if (workflowIsBeingEdited && workflowRuns.length > 0) {
            setOpenCancelWorkflowRunsDialog(true);
        } else {
            handlePublish();
        }
    };

    const handleSubmitCancelWorkflowRunsDialog = () => {
        setOpenCancelWorkflowRunsDialog(false);
        handlePublish();
    };

    const handlePublish = async () => {
        try {
            setIsPublishing(true);
            await saveChanges();
            await updateWorkflowProjectId({
                variables: {
                    id: documentBuilder.workflowId,
                    project_id: selectServiceValue
                }
            });
            await publishWorkflow({
                workflowVersionId: documentBuilder.workflowVersionId,
                organizationId: currentAgencyId || '',
                workflowRunsToCancel: selectedWorkflowRunIdsToCancel
            });
            await updateWorkflows();
            setIsPublishing(false);
            closeModal();
        } catch (error: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({ id: 'unable_to_publish_workflow' }),
                    variant: 'error'
                })
            );
            setIsPublishing(false);
        }
    };

    const handleVerify = async () => {
        await saveChanges();
        await updateWorkflowProjectId({
            variables: {
                id: documentBuilder.workflowId,
                project_id: selectServiceValue
            }
        });
        await verifyWorkflow({
            workflowVersionId: documentBuilder.workflowVersionId,
            organizationId: currentAgencyId || ''
        });
    };

    useEffect(() => {
        const getData = async () => {
            const {
                data: { client_organizations_by_pk: _clientData }
            } = await getClientById({ variables: { clientOrganizationId: idClient } });
            setClientData(_clientData);
            const {
                data: { projects: _clientProjects }
            } = await getClientProjects({
                variables: { organizationId: currentAgencyId, clientId: idClient }
            });

            setClientProjects(_clientProjects);
        };
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const getWorkflowRunData = async () => {
            const {
                data: { workflow_runs }
            } = await getProcessmakerRequests(documentBuilder.workflowId, member?.organizationId);

            const workflowRunsToCancel = workflow_runs
                .filter((workflowRun: any) => workflowRun.status === 'ACTIVE')
                .map((workflowRun: any) => ({
                    id: workflowRun.processmaker_request_id,
                    title: `Run - ${generateWorkflowRunTitle(workflowRun)}`
                }));

            console.log('workflowRunsToCancel', workflowRunsToCancel);

            setWorkflowRuns(workflowRunsToCancel);
        };

        getWorkflowRunData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box>
            <Box sx={{ padding: '24px', display: 'flex', flexDirection: 'column', gap: '32px', borderLeft: '1px solid #D4DBEA' }}>
                <Typography sx={{ fontWeight: 400, fontSize: '14px', color: '#677487' }}>
                    You are about to publish your workflow. Please select the service this workflow is part of and the number of variable
                    pages and sections.
                </Typography>
                <Box sx={{ gap: '16px', display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>Client</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <Avatar sx={{ width: '32px', height: '32px' }} alt={clientData.name} src={clientData?.logo_url || 'src/'} />
                        <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>{clientData.name}</Typography>
                    </Box>
                </Box>
                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: 'black' }}>Provided service</Typography>
                <Select
                    value={selectServiceValue}
                    onChange={handleSelectServiceValueChange}
                    IconComponent={ChevronDownIcon}
                    sx={{ '& .MuiSelect-select': { fontWeight: 400, backgroundColor: 'white' } }}
                >
                    {clientProjects.map((project: any) => (
                        <MenuItem key={project.id} value={project.id}>
                            {project.name}
                        </MenuItem>
                    ))}
                </Select>
                {/* <Box sx={{ gap: '16px', display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 500, fontSize: '14px', color: 'black' }}>Variable</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                    <FormControl sx={{ flex: 1 }}>
                        <Select
                            value={selectPostValue}
                            onChange={handleSelectPostValue}
                            IconComponent={ChevronDownIcon}
                            sx={{
                                '& .MuiSelect-select': { fontWeight: 700, backgroundColor: 'white', textAlign: 'right' }
                            }}
                            renderValue={(value) => (
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span style={{ fontWeight: 400 }}>Post</span>
                                    <span>{value}</span>
                                </div>
                            )}
                        >
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ flex: 1 }}>
                        <Select
                            value={selectStoryValue}
                            onChange={handleSelectStoryValue}
                            IconComponent={ChevronDownIcon}
                            sx={{
                                '& .MuiSelect-select': { fontWeight: 700, backgroundColor: 'white', textAlign: 'right' }
                            }}
                            renderValue={(value) => (
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span style={{ fontWeight: 400 }}>Stories</span>
                                    <span>{value}</span>
                                </div>
                            )}
                        >
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box> */}
                {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: 'black' }}>Scheduling</Typography>
                <Select
                    value={selectPeriodValue}
                    onChange={handleSelectPeriodValue}
                    IconComponent={ChevronDownIcon}
                    sx={{ '& .MuiSelect-select': { fontWeight: 700, backgroundColor: 'white', textAlign: 'right' } }}
                    renderValue={(value) => (
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span style={{ fontWeight: 400 }}>Publication occurs every</span>
                            <span>{value} weeks</span>
                        </div>
                    )}
                >
                    <MenuItem value={2}>2 weeks</MenuItem>
                    <MenuItem value={3}>3 weeks</MenuItem>
                </Select>
            </Box> */}
                {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: 'black' }}>First Due Date</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        renderInput={(props) => (
                            <TextField
                                {...props}
                                sx={{
                                    '& .MuiInputBase-input': { padding: '8px 0' },
                                    width: '260px',
                                    height: '36px',
                                    background: '#EFF2FA'
                                }}
                            />
                        )}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CalendarIcon />
                                </InputAdornment>
                            )
                        }}
                        label=""
                        value={dueDate}
                        onChange={(newValue) => {
                            setDueDate(newValue);
                        }}
                        components={{
                            OpenPickerIcon: ChevronDownIcon
                        }}
                    />
                </LocalizationProvider>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '16px',
                    height: '48px',
                    background: '#F6F7FC',
                    borderRadius: '8px'
                }}
            >
                <Checkbox value={checkValue} onChange={handleCheckboxChange} />
                <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>Save as Template</Typography>
            </Box>
            <TextField multiline rows="8" placeholder="Description" /> */}

                {/* {documentBuilder.pages.map((page: any, pageIndex: number) => (
                    <>
                        {page.pageVariable ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '16px'
                                }}
                            >
                                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: 'black' }}>
                                    Page variable - {page.pageName}
                                </Typography>
                                <TextField
                                    id="outlined-number"
                                    inputProps={{ style: { backgroundColor: 'white' } }}
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    value={page.pageNumber}
                                    onChange={handlePageVariableChange(pageIndex, page)}
                                />
                            </Box>
                        ) : null}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '16px'
                            }}
                        >
                            {page.pageSections.map((section: any, sectionIndex: number) =>
                                section.sectionVariable ? (
                                    <>
                                        <Typography sx={{ fontSize: '14px', fontWeight: 500, color: 'black' }}>
                                            Section variable - {section.sectionName}
                                        </Typography>
                                        <TextField
                                            id="outlined-number"
                                            inputProps={{ style: { backgroundColor: 'white' } }}
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            value={section.sectionNumber}
                                            onChange={handleSectionVariableChange(pageIndex, sectionIndex, section)}
                                        />
                                    </>
                                ) : null
                            )}
                        </Box>
                    </>
                ))} */}

                <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    {process.env.REACT_APP_ENVIRONMENT === 'development' && (
                        <Button
                            onClick={() => {
                                handleVerify();
                            }}
                            title="Verify"
                        />
                    )}
                    <Button
                        onClick={() => {
                            // setOpenCancelWorkflowRunsDialog(true);
                            handleOpenCancelWorkflowRunsDialog();
                        }}
                        title="Publish"
                    />
                </Box>
            </Box>
            <CancelWorkflowRunsDialog
                open={openCancelWorkflowRunsDialog}
                handleClose={() => {
                    setOpenCancelWorkflowRunsDialog((_) => !_);
                }}
                workflowId={documentBuilder.workflowId}
                handleSuccess={handleSubmitCancelWorkflowRunsDialog}
                selectedIds={selectedWorkflowRunIdsToCancel}
                setSelectedIds={setSelectedWorkflowRunIdsToCancel}
            />
            <LoadingModal open={isPublishing} />
        </Box>
    );
};

export default PublishContent;
