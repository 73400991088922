import { Box, Checkbox, FormControl, InputAdornment, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useState } from 'react';

import IBMIcon from 'assets/images/icons/ibm.png';
import { ReactComponent as CalendarIcon } from 'assets/images/calendar.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/images/chevron-down.svg';

const PublishContent = () => {
    const [dueDate, setDueDate] = useState<Date | null>(new Date());

    const [selectServiceValue, setSelectServiceValue] = useState<string>('1');

    const handleSelectServiceValueChange = (event: SelectChangeEvent) => {
        setSelectServiceValue(event.target.value as string);
    };

    const [selectPostValue, setSelectPostValue] = useState<string>('5');

    const handleSelectPostValue = (event: SelectChangeEvent) => {
        setSelectPostValue(event.target.value as string);
    };

    const [selectStoryValue, setSelectStoryValue] = useState<string>('5');

    const handleSelectStoryValue = (event: SelectChangeEvent) => {
        setSelectStoryValue(event.target.value as string);
    };

    const [selectPeriodValue, setSelectPeriodValue] = useState<string>('2');

    const handleSelectPeriodValue = (event: SelectChangeEvent) => {
        setSelectPeriodValue(event.target.value as string);
    };

    const [checkValue, setCheckValue] = useState<boolean>(false);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckValue(event.target.checked);
    };

    return (
        <Box sx={{ padding: '24px', display: 'flex', flexDirection: 'column', gap: '32px', borderLeft: '1px solid #D4DBEA' }}>
            <Typography sx={{ fontWeight: 400, fontSize: '14px', color: '#677487' }}>
                Cursus neque viverra tellus felis ac, in arcu. Tincidunt non nullam id justo. Ornare amet et pellentesque quis egestas.
            </Typography>
            <Box sx={{ gap: '16px', display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>Client</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <img src={IBMIcon} style={{ width: '32px', height: '32px' }} alt="IBM" />
                    <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>IBM</Typography>
                </Box>
            </Box>
            <Select
                value={selectServiceValue}
                onChange={handleSelectServiceValueChange}
                IconComponent={ChevronDownIcon}
                sx={{ '& .MuiSelect-select': { fontWeight: 400, backgroundColor: 'white' } }}
            >
                <MenuItem value={1}>My Service #1</MenuItem>
                <MenuItem value={2}>My Service #2</MenuItem>
            </Select>
            <Box sx={{ gap: '16px', display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 500, fontSize: '14px', color: 'black' }}>Variable</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                    <FormControl sx={{ flex: 1 }}>
                        <Select
                            value={selectPostValue}
                            onChange={handleSelectPostValue}
                            IconComponent={ChevronDownIcon}
                            sx={{
                                '& .MuiSelect-select': { fontWeight: 700, backgroundColor: 'white', textAlign: 'right' }
                            }}
                            renderValue={(value) => (
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span style={{ fontWeight: 400 }}>Post</span>
                                    <span>{value}</span>
                                </div>
                            )}
                        >
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ flex: 1 }}>
                        <Select
                            value={selectStoryValue}
                            onChange={handleSelectStoryValue}
                            IconComponent={ChevronDownIcon}
                            sx={{
                                '& .MuiSelect-select': { fontWeight: 700, backgroundColor: 'white', textAlign: 'right' }
                            }}
                            renderValue={(value) => (
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span style={{ fontWeight: 400 }}>Stories</span>
                                    <span>{value}</span>
                                </div>
                            )}
                        >
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: 'black' }}>Scheduling</Typography>
                <Select
                    value={selectPeriodValue}
                    onChange={handleSelectPeriodValue}
                    IconComponent={ChevronDownIcon}
                    sx={{ '& .MuiSelect-select': { fontWeight: 700, backgroundColor: 'white', textAlign: 'right' } }}
                    renderValue={(value) => (
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span style={{ fontWeight: 400 }}>Publication occurs every</span>
                            <span>{value} weeks</span>
                        </div>
                    )}
                >
                    <MenuItem value={2}>2 weeks</MenuItem>
                    <MenuItem value={3}>3 weeks</MenuItem>
                </Select>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: 'black' }}>First Due Date</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        renderInput={(props) => (
                            <TextField
                                {...props}
                                sx={{
                                    '& .MuiInputBase-input': { padding: '8px 0' },
                                    width: '260px',
                                    height: '36px',
                                    background: '#EFF2FA'
                                }}
                            />
                        )}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CalendarIcon />
                                </InputAdornment>
                            )
                        }}
                        label=""
                        value={dueDate}
                        onChange={(newValue) => {
                            setDueDate(newValue);
                        }}
                        components={{
                            OpenPickerIcon: ChevronDownIcon
                        }}
                    />
                </LocalizationProvider>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '16px',
                    height: '48px',
                    background: '#F6F7FC',
                    borderRadius: '8px'
                }}
            >
                <Checkbox value={checkValue} onChange={handleCheckboxChange} />
                <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>Save as Template</Typography>
            </Box>
            <TextField multiline rows="8" placeholder="Description" />
        </Box>
    );
};

export default PublishContent;
