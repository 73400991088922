import _ from 'lodash';
import { WFTask } from '@sni4/snikpic-common';
import { CreateNodeParams, ReactFlowTaskNode } from 'features/workflow/types/react-flow.types';
import { getId } from 'features/workflow/utils/react-flow.utils';

export class TaskNodeHelper {
    static createNode({ position }: CreateNodeParams): ReactFlowTaskNode {
        return {
            id: getId(),
            type: 'task',
            position,
            data: {
                assignee: '',
                name: '',
                role: '',
                description: '',
                duration: {
                    unit: 'days',
                    value: 1
                },
                document_permissions: [],
                is_valid: false
            }
        };
    }

    static convertToWorkflowNode(node: ReactFlowTaskNode): WFTask {
        return {
            type: 'task',
            properties: {
                id: node.id,
                position: node.position,
                assignee: node.data.assignee,
                name: node.data.name,
                duration: node.data.duration,
                role: node.data.role,
                description: node.data.description,
                document_permissions: node.data.document_permissions
            }
        };
    }

    static convertToReactFlowNode(node: WFTask): ReactFlowTaskNode {
        return TaskNodeHelper.validateNode({
            id: node.properties.id,
            type: node.type,
            position: node.properties.position,
            data: {
                assignee: node.properties.assignee,
                name: node.properties.name,
                role: node.properties.role,
                description: node.properties.description,
                duration: node.properties.duration,
                document_permissions: node.properties.document_permissions,
                is_valid: false
            }
        });
    }

    static validateNode(node: ReactFlowTaskNode): ReactFlowTaskNode {
        return {
            ...node,
            data: {
                ...node.data,
                is_valid:
                    !_.isEmpty(node.data.assignee) &&
                    !_.isEmpty(node.data.name) &&
                    !_.isEmpty(node.data.role) &&
                    !_.isEmpty(node.data.document_permissions)
            }
        };
    }
}
