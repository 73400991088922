import Box from '@mui/material/Box';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, CardMedia } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import Avatar from 'ui-component/extended/Avatar';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import calendar from 'assets/images/calendar.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import moment from 'moment';
import React from 'react';
import { TaskDTO, TaskStatusEnum } from '../types';

function EnhancedTableHead() {
    const theme = useTheme();

    return (
        <TableHead sx={{ borderBottom: `1px solid ${theme.palette.grey[500]}`, background: theme.palette.grey[700] }}>
            <TableRow>
                <TableCell align="left">
                    {' '}
                    <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600 }}>
                        <FormattedMessage id="task_name" />
                    </Typography>
                </TableCell>
                <TableCell align="left" sx={{ borderLeft: `1px solid ${theme.palette.grey[500]}` }}>
                    <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600 }}>
                        <FormattedMessage id="assignee" />
                    </Typography>
                </TableCell>
                <TableCell align="center" sx={{ borderLeft: `1px solid ${theme.palette.grey[500]}` }}>
                    <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600 }}>
                        <FormattedMessage id="status" />
                    </Typography>
                </TableCell>
                <TableCell align="left" sx={{ borderLeft: `1px solid ${theme.palette.grey[500]}` }}>
                    <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600 }}>
                        <FormattedMessage id="due_date" />
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600, display: 'none' }}>
                        <FormattedMessage id="date_added" />
                    </Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

interface OffersTableProps {
    tasks: TaskDTO[];
    // refresh: () => void;
    setSelectedTask: (id: number) => void;
}
export default function WorkflowDialogTable({ tasks, setSelectedTask }: OffersTableProps) {
    const theme = useTheme();

    const FullTableRow = ({ task, setSelectedTask }: { task: TaskDTO; setSelectedTask: (id: number) => void }) => {
        const [openDatePicker, setOpenDatePicker] = React.useState(false);
        // const [datePickerValue, setDatePickerValue] = React.useState<Date | null>(new Date());
        // const hanleDatePickerValue = (value: any) => {
        //     setDatePickerValue(value);
        // };
        const handleOpenkDatePicker = () => setOpenDatePicker(true);
        const handleCloseDatePicker = () => setOpenDatePicker(false);

        return (
            <TableRow hover role="checkbox" tabIndex={-1} onClick={() => setSelectedTask(task.id)}>
                <TableCell component="th" scope="row" sx={{ cursor: 'pointer', width: '412px' }}>
                    <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500 }}>{task.title}</Typography>
                </TableCell>
                <TableCell align="left" sx={{ cursor: 'pointer', borderLeft: `1px solid ${theme.palette.grey[500]}`, width: '412px' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Avatar sx={{ width: '32px', height: '32px' }} alt="Remy Sharp" src={task.user.image_url} />
                        <Typography
                            sx={{
                                color: theme.palette.grey[400],
                                fontFamily: 'Inter',
                                fontWeight: 500,
                                fontSize: '14px',
                                ml: 1
                            }}
                        >
                            {`${task.user.first_name} ${task.user.last_name}`}
                        </Typography>
                    </Box>
                </TableCell>
                <TableCell align="center" sx={{ cursor: 'pointer', borderLeft: `1px solid ${theme.palette.grey[500]}`, width: '158px' }}>
                    <Box
                        sx={{
                            width: '125px',
                            p: '4px 12px',
                            background: task.status === TaskStatusEnum.CLOSED ? theme.palette.dark[100] : theme.palette.primary[300],
                            borderRadius: '16px'
                        }}
                    >
                        <Typography
                            sx={{
                                color: task.status === TaskStatusEnum.CLOSED ? theme.palette.secondary[300] : theme.palette.primary[700],
                                fontFamily: 'Inter',
                                fontWeight: 500,
                                fontSize: '12px'
                            }}
                        >
                            {task.status}
                        </Typography>
                    </Box>
                </TableCell>
                <TableCell align="left" sx={{ borderLeft: `1px solid ${theme.palette.grey[500]}` }}>
                    <Box sx={{ display: 'flex', alignItmes: 'center' }}>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ p: '0' }}>
                                <Box
                                    sx={{
                                        background: theme.palette.grey[700],
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        borderRadius: '8px',
                                        p: '8px'
                                    }}
                                    onClick={handleOpenkDatePicker}
                                >
                                    <Box sx={{ width: '20px' }}>
                                        <CardMedia
                                            sx={{ objectFit: 'contain' }}
                                            component="img"
                                            width="18px"
                                            image={calendar}
                                            alt="alt image"
                                        />
                                    </Box>
                                    <Typography
                                        ml={1}
                                        sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 400, fontSize: '14px' }}
                                    >
                                        {moment(task.due_date).format('DD MMMM YYYY')}
                                    </Typography>
                                    {/* <KeyboardArrowDownIcon sx={{ fontSize: '25px', fill: theme.palette.grey[400], ml: '8px' }} /> */}
                                </Box>
                                <Box>
                                    {' '}
                                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            value={datePickerValue}
                                            open={openDatePicker}
                                            onClose={handleCloseDatePicker}
                                            onChange={(newValue) => {
                                                hanleDatePickerValue(newValue);
                                            }}
                                            renderInput={({ inputRef, inputProps, InputProps }) => <Box ref={inputRef}> </Box>}
                                        />
                                    </LocalizationProvider> */}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </TableCell>
                <TableCell align="right">
                    {task.due_date_status && (
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Box sx={{ p: '4px 12px', borderRadius: '16px', background: theme.palette.primary[500] }}>
                                <Typography
                                    sx={{ color: theme.palette.secondary[400], fontFamily: 'Inter', fontWeight: 500, fontSize: '12px' }}
                                >
                                    {task.due_date_status}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </TableCell>
            </TableRow>
        );
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table sx={{ borderRadius: '8px 8px 0 0', minWidth: 750 }} aria-labelledby="tableTitle">
                        <EnhancedTableHead />
                        <TableBody>
                            {tasks.map((task, index) => (
                                <FullTableRow task={task} key={task.id} setSelectedTask={setSelectedTask} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
}
