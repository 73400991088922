import { createContext, FC, useContext, useState } from 'react';
import { ContextEmployee, Employee } from '../../../types/employee';

interface EmployeeContextProps {
    selectedEmployee: ContextEmployee;
    updateSelectedEmployee: (newEmployee: Employee) => void;
}

const SelectedEmployeeContext = createContext({} as EmployeeContextProps);

export const EmployeeProvider: FC<{ fetchedEmployee: ContextEmployee }> = ({ children, fetchedEmployee }) => {
    const [selectedEmployee, setSelectedEmployee] = useState(fetchedEmployee);

    return (
        <SelectedEmployeeContext.Provider
            value={{
                selectedEmployee,
                updateSelectedEmployee: setSelectedEmployee
            }}
        >
            {children}
        </SelectedEmployeeContext.Provider>
    );
};

export const useSelectedEmployee = () => useContext(SelectedEmployeeContext);
