import { Box, Typography, CardMedia, Dialog, Avatar, TextField, Grid } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import { Button } from 'ui-component/extended/Button';
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';

interface EditProfileProps {
    setEditProfile: any;
    freelancer: any;
}

const EditProfile = ({ setEditProfile, freelancer }: EditProfileProps) => {
    const theme = useTheme();
    const intl = useIntl();
    const formik = useFormik({
        initialValues: {
            role: '',
            description: '',
            numberHours: '',
            bgImage: undefined
        },
        validationSchema: Yup.object({
            role: Yup.string().trim('The name cannot be all spaces').required('Required'),
            description: Yup.string().trim().required('Required'),
            numberHours: Yup.string().trim().required('Required'),
            bgImage: Yup.mixed().required('A file is required')
        }),
        onSubmit: (values) => {
            console.log(values);
            setEditProfile(false);
        }
    });
    React.useEffect(() => {
        formik.setFieldValue('role', freelancer?.type);
        formik.setFieldValue('description', freelancer?.description);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Box>
            <form onSubmit={formik.handleSubmit}>
                <Typography
                    mb={3}
                    sx={{
                        textTransform: 'capitalize',
                        color: theme.palette.grey[400],
                        fontSize: '24px',
                        fontWeight: 600,
                        fontFamily: 'Inter'
                    }}
                >
                    <FormattedMessage id="edit_profile" />
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ position: 'relative' }}>
                        <Avatar
                            sx={{ width: '149px', height: '149px', filter: 'brightness(40%)' }}
                            alt="as"
                            src={formik.values.bgImage && URL.createObjectURL(formik.values.bgImage)}
                        />
                        <label htmlFor="raised-button-file">
                            <input
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="raised-button-file"
                                type="file"
                                onChange={(e: any) => formik.setFieldValue('bgImage', e.target.files[0])}
                            />

                            <BorderColorOutlinedIcon
                                sx={{ position: 'absolute', top: '43%', right: '40%', fill: '#fff', cursor: 'pointer' }}
                            />
                        </label>
                    </Box>

                    <Box sx={{ display: 'flex' }}>
                        <Button
                            sx={{ width: '90px', mr: 1 }}
                            variant="secondary"
                            title={<FormattedMessage id="cancel" />}
                            onClick={() => setEditProfile(false)}
                        />
                        <Button sx={{ width: '73px' }} title={<FormattedMessage id="save" />} onClick={formik.handleSubmit} />
                    </Box>
                </Box>
                <Box sx={{ width: { xs: '100%', lg: '50%' } }}>
                    <Typography
                        sx={{ fontWeight: 500, fontFamily: 'Inter', color: theme.palette.grey[400], fontSize: '14px', mt: 3, mb: 1 }}
                    >
                        <FormattedMessage id="role" />
                    </Typography>
                    <TextField
                        sx={{
                            '.MuiOutlinedInput-input': {
                                fontWeight: 400,
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                background: '#fff'
                            }
                        }}
                        id="role"
                        name="role"
                        value={formik.values.role}
                        onChange={formik.handleChange}
                        variant="outlined"
                        fullWidth
                        placeholder={intl.formatMessage({ id: 'Content Writer' })}
                        error={formik.touched.role && Boolean(formik.errors.role)}
                        helperText={formik.touched.role && formik.errors.role}
                    />
                    <Typography
                        sx={{ fontWeight: 500, fontFamily: 'Inter', color: theme.palette.grey[400], fontSize: '14px', mt: 3, mb: 1 }}
                    >
                        <FormattedMessage id="description" />
                    </Typography>
                    <TextField
                        sx={{
                            '.MuiOutlinedInput-input': {
                                fontWeight: 400,
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                background: '#fff'
                            }
                        }}
                        id="description"
                        name="description"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        variant="outlined"
                        fullWidth
                        multiline
                        minRows={6}
                        maxRows={6}
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        helperText={formik.touched.description && formik.errors.description}
                    />
                    <Typography
                        sx={{ fontWeight: 500, fontFamily: 'Inter', color: theme.palette.grey[400], fontSize: '14px', mt: 3, mb: 1 }}
                    >
                        <FormattedMessage id="number_of_available_hours_per_week" />
                    </Typography>
                    <TextField
                        sx={{
                            '.MuiOutlinedInput-input': {
                                fontWeight: 400,
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                background: '#fff'
                            }
                        }}
                        id="numberHours"
                        name="numberHours"
                        value={formik.values.numberHours}
                        onChange={formik.handleChange}
                        variant="outlined"
                        fullWidth
                        error={formik.touched.numberHours && Boolean(formik.errors.numberHours)}
                        helperText={formik.touched.numberHours && formik.errors.numberHours}
                    />
                    <Typography
                        sx={{ fontWeight: 500, fontFamily: 'Inter', color: theme.palette.grey[400], fontSize: '14px', mt: 3, mb: 1 }}
                    >
                        <FormattedMessage id="skills" />
                    </Typography>
                    <TextField
                        sx={{
                            '.MuiOutlinedInput-input': {
                                fontWeight: 400,
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                background: '#fff'
                            }
                        }}
                        id="numberHours"
                        name="numberHours"
                        value={formik.values.numberHours}
                        onChange={formik.handleChange}
                        variant="outlined"
                        fullWidth
                        placeholder="Graphics, social media etc."
                        error={formik.touched.numberHours && Boolean(formik.errors.numberHours)}
                        helperText={formik.touched.numberHours && formik.errors.numberHours}
                    />
                    <Grid container sx={{ display: 'flex', mt: 2 }}>
                        {freelancer?.works.map((work: any, index: number) => (
                            <Grid item key={index} sx={{ display: 'flex', m: '10px 10px 0 0' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        border: `1px solid #AAAAAA`,
                                        p: '8px 16px',
                                        borderRadius: '40px'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: theme.palette.grey[400],
                                            fontFamily: 'Inter',
                                            fontWeight: 500,
                                            fontSize: '14px'
                                        }}
                                    >
                                        {work.skil}
                                    </Typography>
                                    <CloseIcon
                                        // onClick={() => deleteValueSkiltest(work.title)}
                                        sx={{ fill: theme.palette.grey[400], cursor: 'pointer', ml: 1, fontSize: '20px' }}
                                    />
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </form>
        </Box>
    );
};

export default EditProfile;
