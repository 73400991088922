import { useContext } from 'react';
import ClientContext from 'contexts/client';

const useClient = () => {
    const context = useContext(ClientContext);

    if (!context) throw new Error('context must be use inside provider');

    return context;
};

export default useClient;
