import {
    Typography,
    Box,
    CardMedia,
    CircularProgress,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Avatar,
    Divider
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import fileorange from 'assets/images/fileorange.svg';
import Inspirationorange from 'assets/images/Inspirationorange.svg';
import Promotionorange from 'assets/images/Promotionorange.svg';
import Quotesorange from 'assets/images/Quotesorange.svg';
import Portraitorange from 'assets/images/Portraitorange.svg';
import Pasteventsorange from 'assets/images/Pasteventsorange.svg';
import Calendarventsorange from 'assets/images/Calendarventsorange.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import copy from 'assets/images/copy.svg';
import { FormattedMessage } from 'react-intl';

const AccordionResults = ({ label }: any) => {
    const [expanded, setExpanded] = React.useState(true);
    const theme = useTheme();
    const dispatch = useDispatch();
    const handleImage = (id: string) => {
        switch (id) {
            case 'information':
                return fileorange;
            case 'inspiration':
                return Inspirationorange;
            case 'promotion':
                return Promotionorange;
            case 'quotes':
                return Quotesorange;
            case 'portrait':
                return Portraitorange;
            case 'past_events':
                return Pasteventsorange;
            case 'calendar_events':
                return Calendarventsorange;
            default:
                return '';
        }
    };
    console.log('label', label);
    const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded);
    };
    const Description = ({ desc }: any) => {
        const [isShown, setIsShown] = React.useState(false);
        const [showDesc, setShowDesc] = React.useState(200);
        const [copyValueDescription, setCopyValueDescription] = React.useState({
            value: '',
            copied: false
        });
        const onCopy = () => {
            setCopyValueDescription({
                ...copyValueDescription,
                copied: true
            });
            setTimeout(() => {
                setCopyValueDescription({
                    ...copyValueDescription,
                    copied: false
                });
            }, 1000);
        };
        const handleSnackbar = () => {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'copied_to_clipboard',
                    transition: 'SlideDown'
                })
            );
        };
        const handleShowDesc = () => {
            if (showDesc === 200) {
                setShowDesc(desc.description.length);
            } else {
                setShowDesc(200);
            }
        };
        return (
            <Box mb={1}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    {/* <Avatar sx={{ width: '26px', height: '26px' }} alt="" src="" /> */}
                    <Typography
                        sx={{
                            color: theme.palette.grey[400],
                            fontFamily: 'Inter',
                            fontWeight: 700,
                            fontSize: '14px'
                        }}
                    >
                        {desc.title}
                    </Typography>
                </Box>

                {desc.birthday && (
                    <Typography
                        sx={{
                            color: theme.palette.grey[300],
                            fontFamily: 'Inter',
                            fontWeight: 500,
                            fontSize: '12px',
                            mb: 1
                        }}
                    >
                        <FormattedMessage id="date" />
                        :&nbsp;{desc.birthday}
                    </Typography>
                )}
                <CopyToClipboard onCopy={onCopy} text={desc.description}>
                    <Box
                        onClick={handleSnackbar}
                        onMouseEnter={() => setIsShown(true)}
                        onMouseLeave={() => setIsShown(false)}
                        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}
                    >
                        <Box sx={{ display: 'flex' }}>
                            <Typography
                                sx={{
                                    color: theme.palette.grey[600],
                                    fontFamily: 'Inter',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    mr: 1,
                                    mb: 1
                                }}
                            >
                                {desc.description.slice(0, showDesc) + (desc.description.length > 200 && showDesc === 200 ? '...' : '')}
                            </Typography>
                        </Box>

                        {isShown && (
                            <Box sx={{ width: '18px', cursor: 'pointer' }}>
                                <CardMedia sx={{ objectFit: 'contain' }} component="img" width="18px" image={copy} alt="alt image" />
                            </Box>
                        )}
                    </Box>
                </CopyToClipboard>
                {desc.description.length > 200 && (
                    <Typography
                        onClick={handleShowDesc}
                        sx={{
                            color: theme.palette.grey[400],
                            fontFamily: 'Inter',
                            fontWeight: 700,
                            fontSize: '12px',
                            cursor: 'pointer'
                        }}
                    >
                        <FormattedMessage id={showDesc === 200 ? 'show_more' : 'show_less'} />
                    </Typography>
                )}
                {desc?.match && (
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, pb: 3 }}>
                        <Box
                            sx={{
                                position: 'relative',
                                width: '40px',
                                height: '40px',
                                borderRadius: '100px',
                                border: desc.match < 51 ? '3px solid #FEDF89' : '3px solid #A6F4C5',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <CircularProgress
                                size="40px"
                                variant="determinate"
                                value={desc.match}
                                sx={{
                                    position: 'absolute',
                                    top: '-3px',
                                    left: '-3px',
                                    color: desc.match < 51 ? '#F79009' : '#12B76A'
                                }}
                            />
                            <Typography
                                sx={{
                                    color: desc.match < 51 ? '#F79009' : '#12B76A',
                                    fontFamily: 'Inter',
                                    fontWeight: 700,
                                    fontSize: '12px',
                                    mt: '10px'
                                }}
                            >
                                {desc.match}
                            </Typography>
                        </Box>
                        <Typography
                            sx={{
                                color: theme.palette.grey[400],
                                fontFamily: 'Inter',
                                fontWeight: 500,
                                fontSize: '14px',
                                ml: 1
                            }}
                        >
                            Match
                        </Typography>
                    </Box>
                )}
            </Box>
        );
    };
    return (
        <Accordion
            expanded={expanded}
            onChange={handleChange}
            sx={{
                borderBottom: `1px solid ${theme.palette.grey[500]}`,
                '.MuiAccordionSummary-root': { p: 0 },
                '.MuiAccordionDetails-root': { p: 0 },
                mt: 2
            }}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: '24px', mr: 2 }}>
                            <CardMedia component="img" image={handleImage(label.id)} alt="AI tools" />
                        </Box>
                        <Typography
                            sx={{
                                color: theme.palette.orange.main,
                                fontFamily: 'Inter',
                                fontWeight: 500,
                                fontSize: '14px'
                            }}
                        >
                            {label?.title}
                        </Typography>
                    </Box>
                    <Typography
                        sx={{
                            color: theme.palette.grey[600],
                            fontFamily: 'Inter',
                            fontWeight: 500,
                            fontSize: '14px',
                            mr: 1
                        }}
                    >
                        {`${label?.description?.length} results`}
                    </Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                {label?.description?.map((desc: any, index: number) => (
                    <Box key={index}>
                        <Description desc={desc} />
                        {label.id === 'portrait' && index + 1 !== label.description.length && (
                            <Divider sx={{ width: '80%', m: '0px auto 24px', color: theme.palette.grey[500] }} />
                        )}
                    </Box>
                ))}
            </AccordionDetails>
        </Accordion>
    );
};

export default AccordionResults;
