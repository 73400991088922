import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Avatar, Box, Drawer, Typography, Grid, CardMedia, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { IconChevronRight } from '@tabler/icons';
import RelevantEventFinder from './RelevantEventFinder';
import PublicationConceptGenerator from './PublicationConceptGeneratorv2';
import CaptionGenerator from './CaptionGenerator';
import bookmark from 'assets/images/bookmark.svg';
import file from 'assets/images/file.svg';
import fileText from 'assets/images/fileText.svg';
import imageAi from 'assets/images/imageAi.svg';
import ImageGenerator from './ImageGenerator';
import AIToolsDrawerContent from './AIToolsDrawerContent';

interface AIToolsDrawerProps {
    openDivider: any;
    handleClickCloseDivider: any;
    item?: any;
}

const AIToolsDrawer = ({ openDivider, handleClickCloseDivider, item }: AIToolsDrawerProps) => {
    const theme = useTheme();
    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Drawer
            container={container}
            variant="temporary"
            anchor="right"
            open={openDivider}
            elevation={1}
            onClose={handleClickCloseDivider}
            // onClose={(_, reason) => reason === 'backdropClick' && handleClickCloseDivider}
            sx={{
                '& .MuiDrawer-paper': {
                    width: { xs: '100%', sm: '562px' },
                    background: theme.palette.background.default,
                    color: theme.palette.text.primary,
                    borderRight: 'none',
                    overflow: 'visible'
                    // [theme.breakpoints.up('md')]: {
                    //     top: '88px'
                    // }
                }
            }}
            ModalProps={{ keepMounted: true }}
            color="inherit"
        >
            <AIToolsDrawerContent handleClose={handleClickCloseDivider} />
        </Drawer>
    );
};

export default AIToolsDrawer;
