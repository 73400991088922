import TextField from './TextField';
import { Grid } from '@mui/material';
import { BaseFieldProps } from 'features/workflow-task-execution/types';
import Dropdown from './Dropdown';
import TickBox from './TickBox';
import List from './List';
import DatePicker from './DatePicker';
import FileUploader from './FileUploader';

interface FieldFactoryProps extends BaseFieldProps {
    type: 'TEXT' | 'TEXT_AREA' | 'DROPDOWN' | 'TICKBOX' | 'LIST' | 'DATE' | 'FILE_UPLOADER';
    size: number;
}

export default function FieldFactory(props: FieldFactoryProps) {
    const { size } = props;
    return (
        <Grid item sm={size}>
            <FieldFactoryHelper {...props} />
        </Grid>
    );
}

function FieldFactoryHelper({ type, field, setSavingStatus, size, handleFieldValueUpdate }: FieldFactoryProps) {
    const baseProps = { field, setSavingStatus, size, handleFieldValueUpdate };
    if (type === 'TEXT') {
        return <TextField {...baseProps} />;
    }
    if (type === 'TEXT_AREA') {
        return <TextField {...baseProps} isTextArea />;
    }
    if (type === 'DROPDOWN') {
        return <Dropdown {...baseProps} />;
    }
    if (type === 'TICKBOX') {
        return <TickBox {...baseProps} />;
    }
    if (type === 'LIST') {
        return <List {...baseProps} />;
    }
    if (type === 'DATE') {
        return <DatePicker {...baseProps} />;
    }
    if (type === 'FILE_UPLOADER') {
        return <FileUploader {...baseProps} />;
    }
    return null;
}
