/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useLazyQuery, useMutation } from '@apollo/client';

import { Typography, Box, Breadcrumbs, Grid, Dialog, Avatar, Tabs, Tab, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';

import {
    GET_SPECIFIC_CLIENT,
    GENERATE_ASSET_URL,
    ADD_LOGO_TO_CLIENT,
    GET_PROJECTS_FOR_CLIENT,
    GET_OFFERS_FOR_AGENCY,
    SUBSCRIBE_TO_OFFER,
    GET_USER_ORGANIZATIONS
} from 'services/graphQL';
import { uploadFile } from 'services/rest';
import { setCurrentClient, updateCurrentClient } from 'store/slices/clients';
import { openSnackbar } from 'store/slices/snackbar';
import { useDispatch, useSelector } from 'store';
import { RelevantLink, RelevantLinkType } from 'types/clients';
import { formatProject } from 'utils/formatters';
import { Button } from 'ui-component/extended/Button';

import { AutoCompleteSelectButton } from '../../components/autocomplete-select-button';
import { EditClientModal } from '../../components/edit-client-dialog';
import { EditImageButton } from '../../components/edit-image-button';
import { CropDialog } from '../../components/crop-dialog';

import CLientDetailsGeneral from './general';
import ClientDetailsIntegrations from './integration';
import ClientProvidedServices from './service';
import { Performance } from './performance';
import AITools from './AITools';
import Workflows from './workflow';
import CreateNewWorkflow from './workflow/CreateNewWorkflow';
import useAuth from 'hooks/useAuth';
import { GET_WORKFLOWS } from 'services/graphQL/queries/workflow';
import { ClientDetailsContext } from '../../context/client-details.context';

export const ClientDetails = () => {
    const theme = useTheme();
    const { member } = useAuth();

    const [showCropModal, setShowCropModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [imageCrop, setImageCrop] = useState('');
    const { idClient } = useParams();

    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [offers, setOffers] = useState<any[]>([]);
    const [organizationName, setOrganizationName] = useState('');
    const [getClientById] = useLazyQuery(GET_SPECIFIC_CLIENT);
    const [getClientProjects, { refetch }] = useLazyQuery(GET_PROJECTS_FOR_CLIENT);
    const [getOffers] = useLazyQuery(GET_OFFERS_FOR_AGENCY);
    const [generateURL] = useMutation(GENERATE_ASSET_URL);
    const [addLogoToClient] = useMutation(ADD_LOGO_TO_CLIENT);
    const [subscribeToOffer] = useMutation(SUBSCRIBE_TO_OFFER);

    const dispatch = useDispatch();
    const { currentClient } = useSelector((state) => state.clients);
    const currentAgencyId = member?.organizationId;

    const closeEditModal = () => setShowEditModal(false);
    const openEditModal = () => setShowEditModal(true);
    const userId = JSON.parse(`${localStorage.getItem('user')}`);

    const [getUser] = useLazyQuery(GET_USER_ORGANIZATIONS, {
        onCompleted: (res) => {
            setOrganizationName(res.organizations[0].name);
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first'
    });

    useEffect(() => {
        getUser({
            variables: {
                user_id: userId.user.id
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleChange = (_: React.SyntheticEvent, newValue: number) => setActiveTab(newValue);

    useEffect(() => {
        const getData = async () => {
            try {
                setLoading(true);

                const stack = [
                    getClientById({ variables: { clientOrganizationId: idClient } }),
                    getClientProjects({
                        variables: { organizationId: currentAgencyId, clientId: idClient }
                    }),
                    getOffers({ variables: { agencyId: currentAgencyId } })
                ];

                const [
                    {
                        data: { client_organizations_by_pk: clientData }
                    },
                    {
                        data: { projects: clientProjects }
                    },
                    {
                        data: { offers }
                    }
                ] = await Promise.all(stack);

                const formattedProjects = formatProject(clientProjects);

                const clientLinks: RelevantLink[] =
                    clientData?.client_relevant_links?.map((relevantLink: RelevantLinkType) => ({
                        id: relevantLink.id,
                        name: relevantLink.title,
                        src: relevantLink.icon,
                        link: relevantLink.url
                    })) ?? [];

                const user = clientData.members?.[0]?.user;
                const clientDetails = {
                    id: clientData.id,
                    agencyId: clientData.agency_id,
                    name: { title: clientData.name, src: clientData.logo_url ?? '' },
                    userId: user?.id ?? '',
                    email: user?.email ?? '',
                    contactName: user?.first_name ?? '',
                    phoneNumber: user?.phone ?? '',
                    description: clientData?.description ?? '',
                    date: '',
                    links: clientLinks,
                    providedServices: formattedProjects
                };
                dispatch(setCurrentClient(clientDetails));
                setOffers(offers);
            } catch (e: any) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: e.message,
                        variant: 'error'
                    })
                );
            } finally {
                setLoading(false);
            }
        };

        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idClient]);

    const closeCropModal = () => setShowCropModal(false);
    const openCropModal = () => setShowCropModal(true);

    const onEditImage = (src: string) => {
        setImageCrop(src);
        openCropModal();
    };

    const onImageCropped = async (file: File) => {
        try {
            setUploading(true);
            const {
                data: {
                    generate_upload_url: { url }
                }
            } = await generateURL({
                variables: { path: `${currentClient.id}-${Date.now()}.png` }
            });

            await uploadFile(url, file);
            const {
                data: {
                    update_client_organizations_by_pk: { logo_url }
                }
            } = await addLogoToClient({
                variables: { clientId: idClient, logoUrl: url.substring(0, url.indexOf('?')) }
            });
            dispatch(setCurrentClient({ ...currentClient, name: { ...currentClient.name, src: logo_url } }));
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e.message,
                    variant: 'error'
                })
            );
        } finally {
            setUploading(false);
        }
    };

    const offersToSubscribe = offers?.map((offer) => ({ value: offer.title, description: offer.description, id: offer.id })) ?? [];

    const onAddService = async (id: string) => {
        const offer = offersToSubscribe.find((offer) => offer.id === id);
        await subscribeToOffer({
            variables: {
                agencyId: currentAgencyId,
                businessId: currentClient.id,
                name: offer?.value,
                details: offer?.description,
                offerId: offer?.id
            }
        });
        const { data } = await refetch({ organizationId: currentAgencyId, clientId: idClient });
        const formattedProjects = formatProject(data?.projects);
        dispatch(updateCurrentClient({ providedServices: formattedProjects }));
    };

    // WORKFLOWS CONTEXT SETUP
    const [workflows, setWorkflows] = React.useState([]);
    const [getWorkflows, { loading: getWorkflowsLoading }] = useLazyQuery(GET_WORKFLOWS, {
        onCompleted: (data) => {
            setWorkflows(data.workflow_workflows);
        }
    });
    useEffect(() => {
        updateWorkflows();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateWorkflows = useCallback(async () => {
        await getWorkflows({
            variables: {
                client_organization_id: idClient
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idClient]);

    return (
        <ClientDetailsContext.Provider value={{ workflows, setWorkflows, updateWorkflows, getWorkflowsLoading }}>
            <Box>
                <Breadcrumbs aria-label="breadcrumb">
                    {/* <Link to="/" className="link">
                    <FormattedMessage id="venly" />
                </Link> */}
                    <Typography sx={{ textTransform: 'capitalize', color: theme.palette.grey[200], fontFamily: 'Inter', fontWeight: 500 }}>
                        {organizationName}
                    </Typography>
                    <Link className="link" to="/clients">
                        <FormattedMessage id="clients" />
                    </Link>
                    <Typography sx={{ textTransform: 'capitalize', color: theme.palette.grey[300], fontFamily: 'Inter', fontWeight: 500 }}>
                        {currentClient?.name?.title}
                    </Typography>
                </Breadcrumbs>
                <Grid mt={3} mb={2} container alignItems="center">
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box mr={2} sx={{ width: 64, position: 'relative' }}>
                                <Avatar sx={{ width: 64, height: 64 }} alt="Remy Sharp" src={currentClient?.name.src} />
                                <EditImageButton
                                    onEdit={onEditImage}
                                    sx={{
                                        width: 24,
                                        height: 24,
                                        background: theme.palette.grey[700],
                                        borderRadius: '100px',
                                        border: '2px solid #fff',
                                        position: 'absolute',
                                        bottom: 0,
                                        right: 0
                                    }}
                                />
                                {uploading && <CircularProgress size={20} sx={{ position: 'absolute', left: 24, top: 24 }} />}
                            </Box>
                            <Typography
                                sx={{
                                    textTransform: 'capitalize',
                                    color: theme.palette.grey[400],
                                    fontSize: 24,
                                    fontWeight: 500,
                                    fontFamily: 'Inter'
                                }}
                            >
                                {currentClient?.name?.title}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ display: 'flex', justifyContent: { xs: 'flex-start', sm: 'flex-end' }, mt: { xs: '20px', sm: 0 } }}>
                            {activeTab === 0 && (
                                <Box>
                                    <Button onClick={openEditModal} icon={<EditIcon />} title={<FormattedMessage id="edit_client" />} />
                                </Box>
                            )}
                            {activeTab === 1 && (
                                <AutoCompleteSelectButton
                                    labels={offersToSubscribe}
                                    title="add_provided_service"
                                    onSelect={onAddService}
                                    placeholder="start_typing_offers"
                                    avatar={false}
                                />
                            )}
                            {activeTab === 2 && <CreateNewWorkflow />}
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ borderBottom: `1px solid ${theme.palette.grey[500]}` }}>
                    <Tabs
                        value={activeTab}
                        onChange={handleChange}
                        variant="scrollable"
                        aria-label="wrapped label tabs example"
                        sx={{ '.MuiTabs-flexContainer': { borderBottom: 'none' } }}
                    >
                        <Tab sx={{ minWidth: '30px' }} label={<FormattedMessage id="general_information" />} />
                        <Tab label={<FormattedMessage id="provided_services" />} />
                        <Tab label={<FormattedMessage id="workflows" />} />
                        <Tab label={<FormattedMessage id="ai_tools" />} />
                        <Tab label={<FormattedMessage id="integrations" />} />
                        <Tab label={<FormattedMessage id="performance" />} />
                    </Tabs>
                </Box>
                {currentClient && (
                    <Box>
                        {loading ? (
                            <Box mt={10} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Box>
                                {activeTab === 0 && <CLientDetailsGeneral details={currentClient} />}
                                {activeTab === 1 && <ClientProvidedServices projects={currentClient.providedServices} />}
                                {activeTab === 2 && <Workflows />}
                                {activeTab === 3 && <AITools />}
                                {activeTab === 4 && <ClientDetailsIntegrations clientId={idClient} />}
                                {activeTab === 5 && <Performance />}
                            </Box>
                        )}
                    </Box>
                )}
                <Dialog
                    open={showCropModal}
                    onClose={closeCropModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <CropDialog onClose={closeCropModal} url={imageCrop} onDone={onImageCropped} />{' '}
                </Dialog>
                <EditClientModal show={showEditModal} onClose={closeEditModal} client={currentClient} />
            </Box>
        </ClientDetailsContext.Provider>
    );
};
