import { Accordion, AccordionSummary, Box, Typography, Grid, Avatar, CardMedia, Input, AccordionDetails, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import AddComment from 'assets/images/AddComment.svg';
import { Button } from 'ui-component/extended/Button';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { useMutation } from '@apollo/client';
import { DocumentInstanceDTO, WorkflowSectionCommentStatusEnumDTO } from 'features/workflow-task-execution/types';
import FieldFactory from 'features/workflow-task-execution/components/Fields/FieldFactory';
import { SavingStatusProps } from './Chips/SavingStatusChip';
import Comment from './Comment';
import { CREATE_SECTION_COMMENT } from '../services/graphQL/queries';

interface SectionContentProps {
    pageId: string;
    pageName: string;
    section: DocumentInstanceDTO['pages'][number]['sections'][number];
    updateDocumentInstance: () => void;
    handleCommentUpdate: (
        id: string,
        pageIndex: number,
        sectionIndex: number,
        status: WorkflowSectionCommentStatusEnumDTO,
        content: string
    ) => Promise<void>;
    setSavingStatus: (string: SavingStatusProps['status']) => void;
    handleFieldValueUpdate: (sectionIndex: number, fieldIndex: number, body: any) => void;
    sectionIndex: number;
}

export default function SectionContent({
    pageId,
    pageName,
    section,
    updateDocumentInstance,
    handleCommentUpdate,
    setSavingStatus,
    handleFieldValueUpdate,
    sectionIndex
}: SectionContentProps) {
    const theme = useTheme();
    const [creatingComment, setCreatingComment] = React.useState(false);
    const [newCommentContent, setNewCommentContent] = React.useState('');
    const [showComments, setShowComments] = React.useState(false);

    // Integration by Anouar

    const [createSectionComment] = useMutation(CREATE_SECTION_COMMENT, {
        onCompleted: (data) => {
            updateDocumentInstance();
        },
        onError: (e: any) => {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e.message,
                    variant: 'error'
                })
            );
        }
    });

    const handleCreateSectionComment = async () => {
        await createSectionComment({
            variables: {
                content: newCommentContent,
                page_index: section.page_index,
                processmaker_process_request_id: section.processmaker_process_request_id,
                section_id: section.section_id,
                section_index: section.section_index,
                page_id: pageId,
                page_name: pageName,
                section_name: section.name
            }
        });
        setNewCommentContent('');
        setCreatingComment(false);
    };

    // END INTEGRATION

    return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
            <Grid item xs={8}>
                <form onSubmit={() => {}}>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                                background: theme.palette.dark[100],
                                minHeight: '52px',
                                borderBottom: `1px solid ${theme.palette.grey[500]}`
                            }}
                        >
                            <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500, fontSize: '14px' }}>
                                {section.name}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 3 }}>
                            <Grid container spacing={4}>
                                <SectionFields
                                    fields={section.fields}
                                    setSavingStatus={setSavingStatus}
                                    sectionIndex={sectionIndex}
                                    handleFieldValueUpdate={handleFieldValueUpdate}
                                />
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </form>
            </Grid>
            <Grid item xs={2}>
                <Box
                    sx={{
                        display: 'flex'
                    }}
                >
                    <Box
                        onClick={() => setCreatingComment(true)}
                        sx={{
                            border: `1px solid ${theme.palette.secondary[500]}`,
                            p: 1,
                            borderRadius: '10px',
                            display: 'flex',
                            alignItmes: 'center',
                            cursor: 'pointer'
                        }}
                    >
                        <Box sx={{ width: '18px' }}>
                            <CardMedia sx={{ objectFit: 'contain' }} component="img" width="18px" image={AddComment} alt="AddComment" />
                        </Box>
                        <Typography
                            sx={{
                                color: theme.palette.secondary[500],
                                fontFamily: 'Inter',
                                fontWeight: 500,
                                fontSize: '14px',
                                ml: 1
                            }}
                        >
                            Add another comment
                        </Typography>
                    </Box>
                </Box>
                {creatingComment && (
                    <Box sx={{ display: 'flex', mt: 2 }}>
                        <Avatar sx={{ width: 24, height: 24 }} alt="Remy Sharp" src="" />
                        <Box ml={1} sx={{ border: `1px solid ${theme.palette.grey[500]}`, borderRadius: '8px', width: '100%' }}>
                            <Box sx={{ p: '12px 16px' }}>
                                <Input
                                    value={newCommentContent}
                                    onChange={(e) => setNewCommentContent(e.target.value)}
                                    disableUnderline
                                    multiline
                                    maxRows={4}
                                    minRows={4}
                                    fullWidth
                                />
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    borderTop: `1px solid ${theme.palette.grey[500]}`,
                                    p: 2
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box sx={{ width: '87px' }}>
                                        <Button title="Cancel" onClick={() => setCreatingComment(false)} variant="secondary" />
                                    </Box>
                                    <Box
                                        onClick={handleCreateSectionComment}
                                        sx={{
                                            width: '42px',
                                            height: '42px',
                                            background: theme.palette.orange.main,
                                            borderRadius: '100px',
                                            ml: 2,
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <ArrowUpwardIcon sx={{ fill: '#fff', fontSize: '20px', m: '10px 0px 0px 11px' }} />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                )}
                {section?.comments &&
                    section?.comments.map((comment: any, index: number) => {
                        if (showComments) {
                            return (
                                <Box key={index}>
                                    <Comment
                                        comment={comment}
                                        updateDocumentInstance={updateDocumentInstance}
                                        handleCommentUpdate={handleCommentUpdate}
                                    />
                                </Box>
                            );
                        }
                        if (index === 0 || index === 1) {
                            return (
                                <Box key={index}>
                                    <Comment
                                        comment={comment}
                                        updateDocumentInstance={updateDocumentInstance}
                                        handleCommentUpdate={handleCommentUpdate}
                                    />
                                </Box>
                            );
                        }
                        return '';
                    })}
                {section?.comments && section?.comments.length > 2 && (
                    <Box onClick={() => setShowComments(!showComments)} sx={{ mt: 3, ml: '34px', cursor: 'pointer' }}>
                        <Typography
                            sx={{ color: '#000', fontFamily: 'Inter', fontWeight: 500, fontSize: '14px' }}
                        >{`See All ${section?.comments.length} open comments`}</Typography>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
}

interface SectionFieldsProps {
    fields: DocumentInstanceDTO['pages'][number]['sections'][number]['fields'];
    sectionIndex: number;
    setSavingStatus: (string: SavingStatusProps['status']) => void;
    handleFieldValueUpdate: (sectionIndex: number, fieldIndex: number, body: any) => void;
}
function SectionFields({ fields, sectionIndex, setSavingStatus, handleFieldValueUpdate }: SectionFieldsProps) {
    const groupedFields = groupFieldsByRow(fields);

    return (
        <>
            {Object.keys(groupedFields).map((key) => {
                const rowFields = groupedFields[key];
                return (
                    <React.Fragment key={`${fields[0].page_index}-${fields[0].section_index}-${key}`}>
                        <Grid container item>
                            {rowFields.map((field, fieldIndex) => (
                                <FieldFactory
                                    key={field.id}
                                    field={field}
                                    type={field.type}
                                    setSavingStatus={setSavingStatus}
                                    size={12}
                                    handleFieldValueUpdate={(body: any) => handleFieldValueUpdate(sectionIndex, fieldIndex, body)}
                                />
                            ))}
                        </Grid>
                    </React.Fragment>
                );
            })}
        </>
    );
}

const groupFieldsByRow = (fields: DocumentInstanceDTO['pages'][number]['sections'][number]['fields']) => {
    const groupedFields: { [key: string]: DocumentInstanceDTO['pages'][number]['sections'][number]['fields'] } = {};

    fields.forEach((field) => {
        if (!groupedFields[field.row_index]) {
            if (field) groupedFields[field.row_index] = [];
        }

        groupedFields[field.row_index].push(field);
    });
    return groupedFields;
};
