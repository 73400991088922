import UserGuard from 'routes/guards/user-guard';
import MainLayout from 'layout/MainLayout';
import Offers from 'views/pages/offers';
import Team from 'views/pages/team';
import Clients from 'views/pages/clients';
import ClientDetails from 'views/pages/clients/features/detail';
import Messages from 'views/pages/messages';
import Settings from 'views/pages/settings';
import HelpCenter from 'views/pages/helpCenter';
import Tasks from 'views/pages/tasks';
import Marketplace from 'views/pages/marketplace';
import Profile from 'views/pages/marketplace/profile';
import Contract from 'views/pages/clients/features/detail/freelancer/Contract';

const MainRoutes = {
    path: '',
    element: (
        <UserGuard>
            <MainLayout />
        </UserGuard>
    ),
    children: [
        {
            path: '/offers',
            element: <Offers />
        },
        {
            path: '/team',
            element: <Team />
        },
        {
            path: '/clients',
            element: <Clients />
        },
        {
            path: '/profile',
            element: <Profile />
        },
        {
            path: '/clients/:idClient',
            element: <ClientDetails />
        },
        // {
        //     path: '/clients_freelancer/:idClient',
        //     element: <Contract />
        // },
        ...(process.env.REACT_APP_SHOW_CHAT === 'true'
            ? [
                  {
                      path: '/messages',
                      element: <Messages />
                  }
              ]
            : []),
        {
            path: '/marketplace',
            element: <Marketplace />
        },
        {
            path: '/marketplace/:idClient',
            element: <Marketplace />
        },
        {
            path: '/settings',
            element: <Settings />
        },
        {
            path: '/help-center',
            element: <HelpCenter />
        },
        {
            path: '/tasks',
            element: <Tasks />
        }
    ]
};

export default MainRoutes;
