import { gql } from '@apollo/client';

const GET_USER = gql`
    query GetUser($user_id: uuid!) {
        users_by_pk(id: $user_id) {
            created_at
            email
            first_name
            freelancer_status
            id
            image_url
            last_name
            phone
            stream_token
        }
    }
`;

const GET_USER_ORGANIZATIONS = gql`
    query GetUserOrganizations($user_id: uuid!) {
        organizations(where: { members: { user_id: { _eq: $user_id } } }) {
            id
            created_at
            name
            status
            logo_url
            stripe_customer_id
        }
    }
`;

const GET_USER_ROLE = gql`
    query GetUserRole($user_id: uuid!, $organization_id: uuid!) {
        roles(where: { organization_members: { organization_id: { _eq: $organization_id }, user_id: { _eq: $user_id } } }) {
            id
            name
        }
    }
`;

const FETCH_USERS_BY_PKS = gql`
    query FetchUsersByPks($ids: [uuid!]) {
        users(where: { id: { _in: $ids } }) {
            id
            last_name
            first_name
            freelancer_status
            image_url
            email
            organization_members {
                role {
                    id
                    name
                }
            }
        }
    }
`;

// eslint-disable-next-line import/prefer-default-export
export { GET_USER, GET_USER_ORGANIZATIONS, GET_USER_ROLE, FETCH_USERS_BY_PKS };
