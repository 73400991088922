// react imports
import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
// material imports
import { Box, Breadcrumbs, Typography, Divider, Avatar, CircularProgress, Dialog } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// integration
import { useMutation, useLazyQuery } from '@apollo/client';
import useAuth from 'hooks/useAuth';
import { useDispatch } from 'store';
import { uploadFile } from 'services/rest';
// types
// store
import { openSnackbar } from 'store/slices/snackbar';
// validation
import * as Yup from 'yup';
// internal components
import { Text } from 'ui-component/typography';
import { Button } from 'ui-component/extended/Button';
import { AuthPassword } from 'views/pages/authentication/components';
import { EditImageButton } from 'views/pages/clients/components/edit-image-button';
import { CropDialog } from 'views/pages/clients/components/crop-dialog';
import { TextInput } from 'views/pages/clients/components/text-input';
// integration : get user
import { GET_USER, GENERATE_ASSET_URL, GET_USER_ORGANIZATIONS } from 'services/graphQL';
// update user (dummy)
import { UPDATE_USER_PASSWORD, UPDATE_USER_INFO, ADD_LOGO_TO_USER } from 'services/graphQL/mutations';

const ProfileSettings = () => {
    const theme = useTheme();
    const { user } = useAuth();
    const dispatch = useDispatch();
    const intl = useIntl();

    const [showCropModal, setShowCropModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [imageCrop, setImageCrop] = useState('');

    const [getUser, { data }] = useLazyQuery(GET_USER);
    const [organizationName, setOrganizationName] = React.useState('');

    const [userData, setUserData] = useState({
        created_at: '',
        email: '',
        first_name: '',
        freelancer_status: '',
        id: '',
        image_url: '',
        last_name: '',
        phone: ''
    });

    const [generateURL] = useMutation(GENERATE_ASSET_URL);
    const [addLogoToUser] = useMutation(ADD_LOGO_TO_USER);
    const [updateUser] = useMutation(UPDATE_USER_INFO);
    const [updateUserPassword] = useMutation(UPDATE_USER_PASSWORD);

    // const userId = JSON.parse(`${localStorage.getItem('user')}`);
    const [getUserOrg] = useLazyQuery(GET_USER_ORGANIZATIONS, {
        onCompleted: (res) => {
            setOrganizationName(res.organizations[0].name);
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first'
    });

    const formikUserInfo = useFormik({
        initialValues: {
            first_name: userData.first_name,
            last_name: userData.last_name
        },
        validationSchema: Yup.object({}),
        onSubmit: async (values) => {
            setUserData({
                ...userData,
                first_name: values.first_name,
                last_name: values.last_name
            });
            try {
                setLoading(true);
                await updateUser({
                    variables: {
                        userId: userData.id,
                        first_name: values.first_name,
                        last_name: values.last_name,
                        phone_number: userData.phone
                    }
                });
                dispatch(
                    openSnackbar({
                        open: true,
                        message: intl.formatMessage({ id: 'updated_user' })
                    })
                );
            } catch (e: any) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: e.message,
                        variant: 'error'
                    })
                );
            } finally {
                setLoading(false);
            }
        }
    });

    const formikUserPassword = useFormik({
        initialValues: {
            oldPassword: '',
            password: '',
            confirmPassword: ''
        },
        validationSchema: Yup.object({
            oldPassword: Yup.string().required('old_password_required'),
            password: Yup.string().required('password_required').min(8, 'must_be_at_least_8_characters'),
            confirmPassword: Yup.string()
                .required('confirm_password_required')
                .oneOf([Yup.ref('password'), null], 'passwords_must_match')
        }),
        onSubmit: async (values) => {
            try {
                await updateUserPassword({
                    variables: {
                        new_password: values.password,
                        previous_password: values.oldPassword
                    }
                });

                dispatch(
                    openSnackbar({
                        open: true,
                        message: intl.formatMessage({ id: 'password_updated' })
                    })
                );
            } catch (e: any) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: intl.formatMessage({ id: 'invalid_previous_password' }),
                        variant: 'error'
                    })
                );
            } finally {
                setLoading(false);
            }
        }
    });

    // setUser async function that is called in the useEffect and  getUser oand getUserOrg to setUserData and values of the formik

    const setUser = async (user: any) => {
        const {
            data: { users_by_pk: data }
        } = await getUser({ variables: { user_id: user.id } });
        const {
            data: { organizations }
        } = await getUserOrg({ variables: { user_id: user.id } });

        if (data) {
            setUserData(data);
            setOrganizationName(organizations[0].name);
            formikUserInfo.setValues({
                first_name: data.first_name,
                last_name: data.last_name
            });
        }
    };

    // useEffect to populate user data in the fields, and to get the user data returns an error message if the info couldn't be retrieved
    useEffect(() => {
        try {
            if (user && user.id) {
                setUser(user);
            }
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e.message,
                    variant: 'error'
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, user]);

    const closeCropModal = () => setShowCropModal(false);
    const openCropModal = () => setShowCropModal(true);

    const onEditImage = (src: string) => {
        setImageCrop(src);
        openCropModal();
    };

    const onImageCropped = async (file: File) => {
        try {
            setUploading(true);
            const {
                data: {
                    generate_upload_url: { url }
                }
            } = await generateURL({
                variables: { path: `${user.id}-${Date.now()}.png` }
            });

            await uploadFile(url, file);
            const avatarUrl = url.substring(0, url.indexOf('?'));
            await addLogoToUser({
                variables: { userId: user.id, logoUrl: avatarUrl }
            });

            setUserData({ ...userData, image_url: avatarUrl });

            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({ id: 'updated_avatar' }),
                    variant: 'success'
                })
            );
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e.message,
                    variant: 'error'
                })
            );
        } finally {
            setUploading(false);
        }
    };

    return (
        <Box
            sx={{
                p: '0 24px'
            }}
        >
            <Box sx={{ alignItems: 'flex-start' }} display="flex" justifyContent="space-between">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography sx={{ textTransform: 'capitalize', color: theme.palette.grey[200], fontFamily: 'Inter', fontWeight: 500 }}>
                        {organizationName}
                    </Typography>
                    <Link className="link" to="/settings">
                        <FormattedMessage id="settings" />
                    </Link>
                    <Link className="link" to="settings/manager">
                        <FormattedMessage id="manager" />
                    </Link>
                    <Typography sx={{ textTransform: 'capitalize', color: theme.palette.grey[300], fontFamily: 'Inter', fontWeight: 500 }}>
                        <FormattedMessage id="profile_settings" />
                    </Typography>
                </Breadcrumbs>
            </Box>
            <Box sx={{ alignItems: 'flex-start' }}>
                <Typography
                    mt={3}
                    sx={{
                        fontWeight: 500,
                        fontFamily: 'Inter',
                        color: theme.palette.grey[400],
                        fontSize: '24px'
                    }}
                >
                    <FormattedMessage id="profile" />
                </Typography>

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mt: theme.spacing(1) }}>
                    <Box mr={2} sx={{ width: 150, position: 'relative' }}>
                        <Avatar
                            sx={{ width: 120, height: 120 }}
                            alt={userData.first_name}
                            src={userData?.image_url || 'src/assets/images/default-avatar.png'}
                        />
                        <EditImageButton
                            sx={{
                                width: 24,
                                height: 24,
                                background: theme.palette.grey[700],
                                borderRadius: '100px',
                                border: '2px solid #fff',
                                position: 'absolute',
                                bottom: 0,
                                right: 0
                            }}
                            onEdit={onEditImage}
                        />
                        {uploading && <CircularProgress size={20} sx={{ position: 'absolute', left: 24, top: 24 }} />}
                    </Box>
                    <form onSubmit={formikUserInfo.handleSubmit}>
                        <Box
                            sx={{
                                flexDirection: 'column',
                                mt: theme.spacing(1),
                                width: '300px'
                            }}
                        >
                            <Typography
                                mt={2}
                                sx={{
                                    textTransform: 'uppercase',
                                    color: theme.palette.grey[600],
                                    fontFamily: 'Inter',
                                    fontWeight: 500,
                                    letterSpacing: '0.12em',
                                    fontSize: '12px'
                                }}
                            >
                                <FormattedMessage id="general_information" />
                            </Typography>
                            <Box
                                sx={{
                                    flexDirection: 'column',
                                    mt: theme.spacing(1)
                                }}
                            >
                                <Box mt={3}>
                                    <Text>
                                        <FormattedMessage id="first_name" />
                                    </Text>
                                </Box>
                                <Box mt={1}>
                                    <TextInput
                                        name="first_name"
                                        value={formikUserInfo.values.first_name}
                                        onChange={formikUserInfo.handleChange}
                                        placeholder={intl.formatMessage({ id: 'first_name' })}
                                        error={formikUserInfo.touched.first_name && Boolean(formikUserInfo.errors.first_name)}
                                        helperText={formikUserInfo.touched.first_name && formikUserInfo.errors.first_name}
                                    />
                                </Box>
                                <Box mt={3}>
                                    <Text>
                                        <FormattedMessage id="last_name" />
                                    </Text>
                                </Box>
                                <Box mt={1}>
                                    <TextInput
                                        name="last_name"
                                        value={formikUserInfo.values.last_name}
                                        onChange={formikUserInfo.handleChange}
                                        placeholder={intl.formatMessage({ id: 'last_name' })}
                                        error={formikUserInfo.touched.last_name && Boolean(formikUserInfo.errors.last_name)}
                                        helperText={formikUserInfo.touched.last_name && formikUserInfo.errors.last_name}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Button
                            onClick={formikUserInfo.handleSubmit}
                            title={<FormattedMessage id="update_user_info" />}
                            sx={{ width: '180px', mt: theme.spacing(2) }}
                            loading={loading}
                        />
                    </form>
                </Box>

                <Divider sx={{ m: '20px 0' }} />
                <Typography
                    mt={2}
                    sx={{
                        textTransform: 'uppercase',
                        color: theme.palette.grey[600],
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        letterSpacing: '0.12em',
                        fontSize: '12px'
                    }}
                >
                    <FormattedMessage id="password" />
                </Typography>
                <form onSubmit={formikUserPassword.handleSubmit}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '300px'
                        }}
                    >
                        <Box mt={3}>
                            <Text>
                                <FormattedMessage id="previous_password" />
                            </Text>

                            <AuthPassword
                                name="oldPassword"
                                placeholder="Previous password"
                                value={formikUserPassword.values.oldPassword}
                                onChange={formikUserPassword.handleChange}
                                error={formikUserPassword.touched.oldPassword && Boolean(formikUserPassword.errors.oldPassword)}
                                helperText={
                                    formikUserPassword.touched.oldPassword &&
                                    formikUserPassword.errors.oldPassword &&
                                    intl.formatMessage({
                                        id: formikUserPassword.errors.oldPassword
                                    })
                                }
                            />
                        </Box>
                        <Box mt={1}>
                            <Text>
                                <FormattedMessage id="new_password" />
                            </Text>

                            <AuthPassword
                                name="password"
                                placeholder={intl.formatMessage({ id: 'new_password' })}
                                value={formikUserPassword.values.password}
                                onChange={formikUserPassword.handleChange}
                                error={formikUserPassword.touched.password && Boolean(formikUserPassword.errors.password)}
                                helperText={
                                    formikUserPassword.touched.password &&
                                    formikUserPassword.errors.password &&
                                    intl.formatMessage({
                                        id: formikUserPassword.errors.password
                                    })
                                }
                            />
                        </Box>
                        <Box mt={1}>
                            <Text>
                                <FormattedMessage id="confirm_password" />
                            </Text>

                            <AuthPassword
                                name="confirmPassword"
                                placeholder={intl.formatMessage({ id: 'confirm_password' })}
                                value={formikUserPassword.values.confirmPassword}
                                onChange={formikUserPassword.handleChange}
                                error={formikUserPassword.touched.confirmPassword && Boolean(formikUserPassword.errors.confirmPassword)}
                                helperText={
                                    formikUserPassword.touched.confirmPassword &&
                                    formikUserPassword.errors.confirmPassword &&
                                    intl.formatMessage({
                                        id: formikUserPassword.errors.confirmPassword
                                    })
                                }
                            />
                        </Box>
                    </Box>
                    <Button
                        onClick={() => {
                            formikUserPassword.handleSubmit();
                        }}
                        title={<FormattedMessage id="update_password" />}
                        sx={{ width: '180px', mt: theme.spacing(1), mb: theme.spacing(2) }}
                        loading={loading}
                    />
                </form>
            </Box>

            <Dialog
                open={showCropModal}
                onClose={closeCropModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <CropDialog onClose={closeCropModal} url={imageCrop} onDone={onImageCropped} />{' '}
            </Dialog>
        </Box>
    );
};

export default ProfileSettings;
