import { Box, Drawer, Typography, Grid, TextField } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import ButtonCustom, { Button } from 'ui-component/extended/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import { CREATE_OFFER, EDIT_OFFER } from '../../../services/graphQL/mutations/offer/OffersMutations';
import { useMutation } from '@apollo/client';
import TextEditor from 'ui-component/textEditor';
import useAuth from 'hooks/useAuth';

interface DrawerAddOffersProps {
    open: boolean;
    onClose: () => void;
    item?: any;
    fetchOffers?: any;
}

const DrawerAddOffers = ({ open, onClose, item, fetchOffers }: DrawerAddOffersProps) => {
    const theme = useTheme();
    const intl = useIntl();
    const { member } = useAuth();
    const formik = useFormik({
        initialValues: {
            name: '',
            description: ''
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .trim('The name cannot be all spaces')
                .required(intl.formatMessage({ id: 'required' })),
            description: Yup.string()
                .trim()
                .required(intl.formatMessage({ id: 'required' }))
        }),
        onSubmit: (values) => {
            if (item) {
                editOffer({
                    variables: {
                        id: item.id,
                        title: values.name,
                        description: values.description,
                        image_url: 'offer image url',
                        agency_id: member?.organizationId
                    }
                });
            } else {
                createOffer({
                    variables: {
                        title: values.name,
                        description: values.description,
                        image_url: 'offer image url',
                        agency_id: member?.organizationId
                    }
                });
            }
        }
    });
    const handleDescription = (value: any) => formik.setFieldValue('description', value);
    const [createOffer] = useMutation(CREATE_OFFER, {
        onCompleted: (data: any) => {
            fetchOffers();
            onClose();
        },
        onError: (error: any) => {
            console.log(error);
        }
    });
    const [editOffer] = useMutation(EDIT_OFFER, {
        onCompleted: (data: any) => {
            onClose();
            fetchOffers();
        },
        onError: (error: any) => {
            console.log(error);
        }
    });
    React.useEffect(() => {
        if (!open) return;

        if (item) {
            formik.setFieldValue('name', item?.title);
            formik.setFieldValue('description', item?.description);
        } else {
            formik.setFieldValue('name', '');
            formik.setFieldValue('description', '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item, open]);

    return (
        <Drawer anchor="right" open={open} onClose={onClose}>
            <Box sx={{ width: { xs: '100%', sm: '562px' } }}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container direction="column" justifyContent="space-between" sx={{ minHeight: '100vh' }}>
                        <Grid item sx={{ p: '32px' }} xs={10}>
                            <Typography
                                mb={3}
                                sx={{ fontWeight: 600, fontFamily: 'Inter', color: theme.palette.common.black, fontSize: '16px' }}
                            >
                                {item ? <FormattedMessage id="edit_offer" /> : <FormattedMessage id="create_offer" />}
                            </Typography>
                            <TextField
                                sx={{
                                    '.MuiOutlinedInput-input': {
                                        fontWeight: 400,
                                        fontFamily: 'Inter',
                                        fontSize: '14px'
                                    }
                                }}
                                id="name"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                variant="outlined"
                                fullWidth
                                placeholder={intl.formatMessage({ id: 'name_of_offer' })}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                            <Box
                                mt={3}
                                sx={{
                                    borderRadius: '10px'
                                    // border: `1px solid ${theme.palette.grey[500]}`,
                                    // minHeight: { xs: '350px', sm: '400px' }
                                }}
                            >
                                <TextField
                                    sx={{
                                        '.MuiOutlinedInput-input': {
                                            fontWeight: 400,
                                            fontFamily: 'Inter',
                                            fontSize: '14px'
                                        }
                                    }}
                                    id="description"
                                    name="description"
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={6}
                                    placeholder={intl.formatMessage({ id: 'description' })}
                                    error={formik.touched.description && Boolean(formik.errors.description)}
                                    helperText={formik.touched.description && formik.errors.description}
                                />
                                {/* <TextEditor value={formik.values.description} setValue={handleDescription} placeholder="description" /> */}
                            </Box>
                            {/* <Typography mt={2} sx={{ color: theme.palette.error.main, fontSize: '12px' }}>
                                {formik.errors.description && formik.touched.description ? formik.errors.description : ''}
                            </Typography> */}
                        </Grid>
                        <Grid
                            xs={2}
                            item
                            container
                            justifyContent="flex-end"
                            alignItems="flex-end"
                            sx={{ borderTop: `1px solid ${theme.palette.grey[500]}`, p: '32px' }}
                        >
                            <Grid item>
                                <Box sx={{ display: 'flex' }}>
                                    <Box mr={2} sx={{ width: '95px' }}>
                                        <Button title={<FormattedMessage id="cancel" />} onClick={onClose} variant="secondary" />
                                    </Box>
                                    <Box sx={{ width: '144px' }}>
                                        <ButtonCustom
                                            onClick={formik.handleSubmit}
                                            colorBtn="red"
                                            titleBtn={
                                                item ? <FormattedMessage id="save_changes" /> : <FormattedMessage id="create_offer" />
                                            }
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Drawer>
    );
};

export default DrawerAddOffers;
