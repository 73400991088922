import React from 'react';
import { Box, Grid } from '@mui/material';
// import { useTheme } from '@mui/material/styles';
// import { FormattedMessage } from 'react-intl';
// import { Button } from 'ui-component/extended/Button';
// import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
// import DatePickerAiTool from 'modules/ai-tools/DatePickerAiTool';
import { TabContentCard } from './TabContentCard';
import { ReportTypeEnum } from '.';
import { AggregatedDataType, ReportType } from './types';

interface TabContentProps {
    title: string | React.ReactNode;
    report: ReportType | null;
    reportType: ReportTypeEnum;
}

export const TabContent = ({ title, report, reportType }: TabContentProps) => {
    // const theme = useTheme();
    // const [open, setOpen] = React.useState(false);
    // const [datePickerValue, setDatePickerValue] = React.useState<Date | null>(new Date());

    // const hanleDatePickerValue = (value: Date) => setDatePickerValue(value);
    // const handleOpenkDatePicker = () => setOpen(true);
    // const handleCloseDatePicker = () => setOpen(false);

    console.log(title, report, reportType);
    return (
        <Box>
            {/* <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={12} sm={2} sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <Typography
                        sx={{
                            color: theme.palette.grey[400],
                            fontSize: '14px',
                            fontWeight: 500,
                            fontFamily: 'Inter'
                        }}
                    >
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={10} sx={{ mt: { xs: '20px', sm: 0 }, mb: { xs: 3, sm: 0 } }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'space-between', sm: 'flex-end' } }}>
                        <Box sx={{ width: { xs: '40%', sm: '70px' }, mr: 2 }}>
                            <Button
                                variant="secondary"
                                icon={<BorderColorOutlinedIcon />}
                                onClick={() => console.log(1)}
                                title={<FormattedMessage id="edit" />}
                            />
                        </Box>
                        <DatePickerAiTool
                            date={datePickerValue}
                            handleDate={hanleDatePickerValue}
                            handleOpen={handleOpenkDatePicker}
                            open={open}
                            handleClose={handleCloseDatePicker}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Divider sx={{ m: '16px 0 24px', display: { xs: 'none', sm: 'block' } }} /> */}
            <Grid container spacing={3}>
                {report?.aggregated_data
                    .filter(
                        (aggregatedData: AggregatedDataType) => aggregatedData.selected_period.value && aggregatedData.previous_period.value
                    )
                    .map((aggregatedData: AggregatedDataType, index: number) => (
                        <Grid key={index} item xs={12} sm={4}>
                            <TabContentCard aggregatedData={aggregatedData} reportType={reportType} />
                        </Grid>
                    ))}
            </Grid>
        </Box>
    );
};
