import React from 'react';
import { Typography, Box, Popover, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import InfoIcon from '@mui/icons-material/Info';
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';
import { Text } from 'ui-component/typography';
import { ReportTypeEnum } from '.';
import { AggregatedDataType } from './types';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11
        }
    })
);

interface TabContentProps {
    aggregatedData: AggregatedDataType;
    reportType: ReportTypeEnum;
}
export const TabContentCard = ({ aggregatedData, reportType }: TabContentProps) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const id = anchorEl ? 'simple-popover' : undefined;

    const variation = parseFloat((aggregatedData.selected_period.value - aggregatedData.previous_period.value).toFixed(2));

    const handleClosePopover = () => setAnchorEl(null);
    // const handleClickPopover = (event: any) => setAnchorEl(event.currentTarget);

    return (
        <Box sx={{ background: theme.palette.grey[700], borderRadius: '8px', p: 2 }}>
            {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Typography
                    onClick={handleClickPopover}
                    sx={{
                        color: theme.palette.grey[400],
                        fontSize: '24px',
                        mt: '-10px',
                        cursor: 'pointer'
                    }}
                >
                    ...
                </Typography>
            </Box> */}
            <Text sx={{ fontSize: 24, fontWeight: 600, mt: 2 }}>{aggregatedData.selected_period.value}</Text>
            <Box sx={{ display: 'flex' }}>
                {variation > 0 && <Text sx={{ color: theme.palette.success[100], fontSize: 10 }}>+</Text>}
                <Typography
                    sx={{
                        color: variation > 0 ? theme.palette.success[100] : theme.palette.error[100],
                        fontSize: '10px',
                        fontWeight: 500,
                        fontFamily: 'Inter',
                        mr: 1
                    }}
                >
                    {variation}
                </Typography>
                <Typography
                    sx={{
                        color: variation > 0 ? theme.palette.success[100] : theme.palette.error[100],
                        fontSize: '10px',
                        fontWeight: 500,
                        fontFamily: 'Inter'
                    }}
                >
                    <FormattedMessage id="compared_to_previous_period" />
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItem: 'center', mt: 3 }}>
                <Text sx={{ fontSize: 14, fontWeight: 600, mr: 1 }}>
                    <FormattedMessage id={`${reportType.toLowerCase()}_${aggregatedData.metric}`} />
                </Text>
                <LightTooltip
                    title={
                        <Typography
                            sx={{
                                color: theme.palette.grey[400],
                                fontFamily: 'Inter',
                                fontWeight: 500,
                                p: '20px'
                            }}
                        >
                            <FormattedMessage id={`${reportType.toLowerCase()}_${aggregatedData.metric}_description`} />
                        </Typography>
                    }
                >
                    <InfoIcon sx={{ fill: theme.palette.grey[400], fontSize: '15px', mt: '2px' }} />
                </LightTooltip>
            </Box>
            <Popover
                id={id}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <Box sx={{ p: '16px 24px' }}>
                    <Box onClick={handleClosePopover} sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
                        <BackspaceOutlinedIcon sx={{ width: '20px' }} />
                        <Text sx={{ fontSize: '14px', ml: '10px' }}>
                            <FormattedMessage id="remove" />
                        </Text>
                    </Box>
                </Box>
            </Popover>
        </Box>
    );
};
