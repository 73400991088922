import { useRoutes } from 'react-router-dom';

import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import ReportingRoutes from './ReportingRoutes';
import FacebookRoute from './FacebookRoute';
import ErrorRoutes from './ErrorRoutes';
import PublicTasksRoutes from './PublicTaskRoutes';
import PlaygroundRoutes from './Playground';
import MarketplaceRoutes from './Marketplace';

export default function ThemeRoutes() {
    return useRoutes([
        LoginRoutes,
        MainRoutes,
        FacebookRoute,
        ReportingRoutes,
        ErrorRoutes,
        PublicTasksRoutes,
        PlaygroundRoutes,
        MarketplaceRoutes
    ]);
}
