import { gql } from '@apollo/client';

const GET_TEAMS = gql`
    query getTeams($organization_id: uuid_comparison_exp) {
        organizations(where: { id: $organization_id }) {
            id
            name
            members(where: { type: { _neq: CLIENT } }) {
                id
                organization_id
                role_id
                title
                status
                user_id
                type
                created_at
                user {
                    id
                    email
                    first_name
                    last_name
                    image_url
                }
            }
        }
    }
`;

const GET_MEMBERS_AND_CLIENTS = gql`
    query getMembersAndClients($clientOrganizationId: uuid!) {
        organizations {
            id
            name
            members(
                where: {
                    _and: [
                        { status: { _eq: ACTIVE } }
                        {
                            _or: [
                                { client_organization_id: { _eq: $clientOrganizationId } }
                                { _and: [{ client_organization_id: { _is_null: true } }, { type: { _eq: MEMBER } }] }
                            ]
                        }
                    ]
                }
            ) {
                organization_id
                role_id
                title
                status
                user_id
                type
                created_at
                user {
                    id
                    email
                    first_name
                    last_name
                    image_url
                }
            }
        }
    }
`;

const GET_PENDING_MEMBERS = gql`
    query getPendingMembers($organizationId: uuid!) {
        organizations(where: { id: { _eq: $organizationId } }) {
            address_id
            created_at
            id
            name
            status
            type
            member_invites {
                member_email
                message
                created_at
                agency_id
                status
            }
        }
    }
`;
const FETCH_SPECIALIST = gql`
    query MyQuery($organizationId: String!) {
        requests(_filter: { organizationId: $organizationId }, _order_by: { createdAt: "desc" }) {
            createdAt
            description
            id
            organizationId
            organizationName
            role
            status
        }
    }
`;
const FETCH_ORGANIZATION_MEMBERS = gql`
    query GetOrganizationMembersByOrganizationId($organizationId: uuid!) {
        organizations_by_pk(id: $organizationId) {
            status
            type
            members {
                status
                title
                type
                user {
                    first_name
                    last_name
                    image_url
                    email
                    freelancer_status
                    id
                }
            }
        }
    }
`;

export { FETCH_ORGANIZATION_MEMBERS, GET_TEAMS, GET_PENDING_MEMBERS, FETCH_SPECIALIST, GET_MEMBERS_AND_CLIENTS };
