import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconChevronRight } from '@tabler/icons';
import PopoverRadio from './PopoverRadio';
import DeliverableComment from './Comment';
import { SectionCommentDTO, WorkflowSectionCommentStatusEnumDTO } from '../types';

interface CommentDrawerProps {
    handleClickCloseDivider: any;
    comments: SectionCommentDTO[];
    updateDocumentInstance: () => void;
    handleCommentUpdate: (
        id: string,
        pageIndex: number,
        sectionIndex: number,
        status: WorkflowSectionCommentStatusEnumDTO,
        content: string
    ) => Promise<void>;
}

const CommentDrawer = ({ handleClickCloseDivider, comments, updateDocumentInstance, handleCommentUpdate }: CommentDrawerProps) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const [valueFilterComments, setValueFilterComments] = React.useState('All comments');
    const id = anchorEl ? 'simple-popover' : undefined;
    const labelsFilterComments = ['All comments', 'Open comments', 'Resolved comments'];

    const closePopover = () => setAnchorEl(null);
    const openPopover = (event: any) => setAnchorEl(event.currentTarget);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValueFilterComments((event.target as HTMLInputElement).value);
        closePopover();
    };
    console.log('comments', comments);

    return (
        <Box sx={{ background: theme.palette.background.default, height: '100%', borderLeft: `1px solid ${theme.palette.grey[500]}` }}>
            <Box sx={{ p: '16px 24px', borderBottom: `1px solid ${theme.palette.grey[500]}` }}>
                <Box
                    sx={{
                        position: 'relative',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                        <Typography>Comments</Typography>
                    </Box>
                    <PopoverRadio
                        anchorEl={anchorEl}
                        id={id}
                        closePopover={closePopover}
                        openPopover={openPopover}
                        value={valueFilterComments}
                        handleChange={handleChange}
                        labels={labelsFilterComments}
                        button
                    />
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            position: 'absolute',
                            left: '-24px',
                            top: '-7px',
                            transform: { xs: 'translateX(0%)', sm: 'translateX(-50%)' },
                            overflow: 'hidden',
                            transition: 'all .2s ease-in-out',
                            border: '1px solid',
                            borderColor: theme.palette.grey[500],
                            borderRadius: '100%',
                            backgroundColor: 'white'
                        }}
                        onClick={() => handleClickCloseDivider()}
                        color="inherit"
                    >
                        <IconChevronRight stroke={1.5} size="1.3rem" />
                    </Avatar>
                </Box>
            </Box>
            <Box sx={{ p: '0px 24px' }}>
                {comments?.map((comment) => (
                    <Box>
                        <DeliverableComment
                            comment={comment}
                            showContent
                            updateDocumentInstance={updateDocumentInstance}
                            handleCommentUpdate={handleCommentUpdate}
                        />
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default CommentDrawer;
