import {
    Grid,
    Typography,
    Box,
    Divider,
    Tabs,
    Tab,
    CardMedia,
    Popover,
    ToggleButtonGroup,
    ToggleButton,
    CircularProgress
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
// import BarChartAsset from 'assets/images/barChart.svg';
import FacebookLogo from 'assets/images/logos_facebook.svg';
import InstagramLogo from 'assets/images/instagram.svg';
// import GoogleLogo from 'assets/images/google.svg';
import { TabFacebook } from './TabFacebook';
import { Button } from 'ui-component/extended/Button';
import ShareReportDrawer from './ShareReportDrawer';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import { TabContent } from './TabContent';
import { useMutation, useLazyQuery } from '@apollo/client';
import { GET_REPORT } from 'services/graphQL/mutations/client/clientPerformance';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { DateTime } from 'luxon';
import { GET_SHARED_REPORT_LINK } from 'services/graphQL/queries/client/clientPerformance';
import NotFoundImg from 'ui-component/Offer';
import { useParams } from 'react-router-dom';
import { ReportType } from './types';

interface ItemType {
    icon: any;
    title: any;
    step: number;
}

export enum ReportTypeEnum {
    FACEBOOK_PAGE_REPORT = 'FACEBOOK_PAGE_REPORT',
    INSTAGRAM_PAGE_REPORT = 'INSTAGRAM_PAGE_REPORT',
    FACEBOOK_ADS_REPORT = 'FACEBOOK_ADS_REPORT'
}

const arrTabs = [
    // {
    //     icon: BarChartAsset,
    //     title: <FormattedMessage id="all" />,
    //     step: 0
    // },
    {
        icon: FacebookLogo,
        title: 'Facebook',
        report_type: ReportTypeEnum.FACEBOOK_PAGE_REPORT,
        metrics:
            'page_fans,page_fan_adds,page_fan_adds_unique,page_fan_removes,page_impressions,page_impressions_unique,page_views_total,page_views_logout,page_views_logged_in_total,page_views_logged_in_unique,page_video_views,page_video_views_paid,page_video_views_organic,page_content_activity,post_activity,post_activity_unique,page_engaged_users,page_post_engagements,page_consumptions,page_consumptions_unique,page_total_actions,page_call_phone_clicks_logged_in_unique,page_get_directions_clicks_logged_in_unique,page_website_clicks_logged_in_unique',
        step: 0
    },
    {
        icon: FacebookLogo,
        title: 'Facebook Ads',
        report_type: ReportTypeEnum.FACEBOOK_ADS_REPORT,
        metrics:
            'reach,impressions,social_spend,cpc,cpm,cpp,ctr,frequency,cost_per_inline_post_engagement,cost_per_inline_link_click,clicks',
        step: 1
    },
    {
        icon: InstagramLogo,
        title: 'Instagram',
        report_type: ReportTypeEnum.INSTAGRAM_PAGE_REPORT,
        metrics:
            'followers_count,email_contacts,get_directions_clicks,impressions,phone_call_clicks,profile_views,reach,text_message_clicks,website_clicks',
        step: 2
    }
    // {
    //     icon: Google,
    //     title: 'Google',
    //     step: 4
    // }
];

enum PeriodEnum {
    LAST_7_DAYS = 'last_7_days',
    LAST_14_DAYS = 'last_14_days'
}

export enum ReportingErrorEnum {
    FACEBOOK_TOKEN_NOT_AVAILABLE = 'FACEBOOK_TOKEN_NOT_AVAILABLE',
    UNABLE_TO_GET_REPORT = 'UNABLE_TO_GET_REPORT'
}

interface PerformanceProps {
    isPublicReport?: boolean;
    sharedReportId?: string;
}

export const Performance = ({ isPublicReport = false, sharedReportId = '' }: PerformanceProps) => {
    const theme = useTheme();
    const { idClient } = useParams();
    const [isLoading, setIsLoading] = React.useState(true);
    const [tabValue, setTabValue] = React.useState(0);
    const [connection, setConnection] = React.useState(true);
    const [isReportAvailable, setIsReportAvailable] = React.useState(true);
    const [showDrawer, setShowDrawer] = React.useState(false);
    const [report, setReport] = React.useState<ReportType | null>(null);
    const [valueSelect, setValueSelect] = React.useState({
        icon: FacebookLogo,
        title: 'Facebook'
    });

    const periodEndDate = DateTime.now();
    const [selectedPeriod, setSelectedPeriod] = React.useState<PeriodEnum>(PeriodEnum.LAST_7_DAYS);
    const [periodStartDate, setPeriodStartDate] = React.useState<DateTime>(periodEndDate.minus({ days: 7 }));

    const [isPublicReportExpired, setIsPublicReportExpired] = React.useState<boolean>(false);

    const handleSelectedPeriodChange = (event: React.MouseEvent<HTMLElement>, newSelectedPeriod: PeriodEnum) => {
        switch (newSelectedPeriod) {
            case PeriodEnum.LAST_7_DAYS:
                setPeriodStartDate(periodEndDate.minus({ days: 7 }));
                break;
            case PeriodEnum.LAST_14_DAYS:
                setPeriodStartDate(periodEndDate.minus({ days: 14 }));
                break;
        }
        setSelectedPeriod(newSelectedPeriod);
    };

    // Store metrics here and pass the setter to > TabFacebook > Tab Content > Tab
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const [getReport] = useMutation(GET_REPORT);
    const [getSharedReport] = useLazyQuery(GET_SHARED_REPORT_LINK);

    const getData = async () => {
        setIsLoading(true);
        try {
            let clientOrganizationId: string;
            let publicReportExpirationDate: string;
            let shouldFetchReport = true;
            if (isPublicReport) {
                ({
                    data: {
                        client_report_set_by_pk: { client_organization_id: clientOrganizationId, expires_at: publicReportExpirationDate }
                    }
                } = await getSharedReport({
                    variables: {
                        id: sharedReportId
                    }
                }));
                if (DateTime.fromISO(publicReportExpirationDate) < DateTime.now()) {
                    setIsPublicReportExpired(true);
                    shouldFetchReport = false;
                }
            } else {
                clientOrganizationId = idClient ?? '';
            }

            let report: ReportType;
            if (shouldFetchReport) {
                ({
                    data: { get_client_report: report }
                } = await getReport({
                    variables: {
                        client_organization_id: clientOrganizationId,
                        metrics: arrTabs[tabValue].metrics,
                        report_type: arrTabs[tabValue].report_type,
                        since: periodStartDate.toISO(),
                        until: periodEndDate.toISO()
                    }
                }));

                report && setReport(report);
            }
            setConnection(true);
        } catch (e: any) {
            setIsLoading(false);
            if (e.message === ReportingErrorEnum.FACEBOOK_TOKEN_NOT_AVAILABLE) {
                setConnection(false);
            } else {
                setIsReportAvailable(false);
            }

            console.log(e.message);
            // dispatch(
            //     openSnackbar({
            //         open: true,
            //         message: e.message,
            //         variant: 'error'
            //     })
            // );
        }
        setIsLoading(false);
    };

    React.useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPeriod, tabValue]);

    const openPopover = (event: any) => setAnchorEl(event.currentTarget);
    const closePopover = () => setAnchorEl(null);

    const id = anchorEl ? 'simple-popover' : undefined;
    const handleChangeTab = (_: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const closeDrawer = () => setShowDrawer(false);
    const openDrawer = () => setShowDrawer(true);

    const handleSelect = (value: ItemType) => {
        setValueSelect({
            icon: value.icon,
            title: value.title
        });
        setTabValue(value.step);
        closePopover();
    };

    return (
        <Box>
            <Grid container sx={{ mt: 3 }}>
                <Grid item xs={12} sm={3}>
                    <Box sx={{ p: '0 24px 24px', display: { xs: 'none', sm: 'block' } }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            {/* <Typography
                                sx={{
                                    color: theme.palette.grey[600],
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    fontFamily: 'Inter'
                                }}
                            >
                                <FormattedMessage id="reports" />
                            </Typography> */}
                            <Box>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={selectedPeriod}
                                    exclusive
                                    onChange={handleSelectedPeriodChange}
                                    aria-label="Platform"
                                >
                                    <ToggleButton value={PeriodEnum.LAST_7_DAYS}>
                                        <FormattedMessage id={PeriodEnum.LAST_7_DAYS} />
                                    </ToggleButton>
                                    <ToggleButton value={PeriodEnum.LAST_14_DAYS}>
                                        <FormattedMessage id={PeriodEnum.LAST_14_DAYS} />
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Box>
                            {/* todo logic is different if it's a public report or not */}
                            {!isPublicReport && (
                                <Box sx={{ width: '60px' }}>
                                    <Button variant="secondary" onClick={openDrawer} title={<FormattedMessage id="share" />} />
                                </Box>
                            )}
                        </Box>
                        <Divider sx={{ m: '16px 0 24px' }} />
                        <Tabs
                            sx={{
                                '.MuiTabs-flexContainer': { border: 'none', width: '100%' },
                                justifyContent: 'flex-start',
                                '.MuiButtonBase-root': { width: '100%' }
                            }}
                            orientation="vertical"
                            value={tabValue}
                            onChange={handleChangeTab}
                            aria-label="Vertical tabs example"
                        >
                            {arrTabs.map((tab: any, index: number) => (
                                <Tab
                                    key={index}
                                    sx={{ justifyContent: 'flex-start', p: 0, minHeight: '30px' }}
                                    label={
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                width: '100%',
                                                p: 2,
                                                borderRadius: '8px',
                                                background: tabValue === index ? theme.palette.orange[200] : ''
                                            }}
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Box sx={{ width: '21px', height: '21px', mr: 1 }}>
                                                    <CardMedia
                                                        sx={{ objectFit: 'contain' }}
                                                        component="img"
                                                        width="18px"
                                                        image={tab.icon}
                                                        alt="alt image"
                                                    />
                                                </Box>
                                                <Typography
                                                    sx={{
                                                        color: tabValue === index ? theme.palette.grey[400] : theme.palette.grey[300],
                                                        fontSize: '14px',
                                                        fontWeight: tabValue === index ? 600 : 400,
                                                        fontFamily: 'Inter'
                                                    }}
                                                >
                                                    {tab.title}
                                                </Typography>
                                            </Box>
                                            <ArrowForwardIosRoundedIcon
                                                sx={{
                                                    fill: tabValue === index ? theme.palette.grey[400] : theme.palette.grey[600],
                                                    fontSize: '16px'
                                                }}
                                            />
                                        </Box>
                                    }
                                />
                            ))}
                        </Tabs>
                    </Box>
                    <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                        {connection && (
                            <Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography
                                        sx={{
                                            color: theme.palette.grey[600],
                                            fontSize: '14px',
                                            fontWeight: 500,
                                            fontFamily: 'Inter'
                                        }}
                                    >
                                        <FormattedMessage id="reports" />
                                    </Typography>
                                    <Box sx={{ width: '60px' }}>
                                        <Button variant="secondary" onClick={openDrawer} title={<FormattedMessage id="share" />} />
                                    </Box>
                                </Box>
                                <Divider sx={{ m: '16px 0 24px' }} />
                            </Box>
                        )}
                        <Box
                            aria-describedby={id}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                p: 1,
                                border: `1px solid ${theme.palette.grey[500]}`,
                                borderRadius: '8px',
                                alignItems: 'center'
                            }}
                            onClick={openPopover}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ width: '21px', height: '21px', mr: 1 }}>
                                    <CardMedia
                                        sx={{ objectFit: 'contain' }}
                                        component="img"
                                        width="18px"
                                        image={valueSelect.icon}
                                        alt="alt image"
                                    />
                                </Box>
                                <Typography
                                    sx={{
                                        color: theme.palette.grey[400],
                                        fontSize: '14px',
                                        fontWeight: 600,
                                        fontFamily: 'Inter'
                                    }}
                                >
                                    {valueSelect.title}
                                </Typography>
                            </Box>
                            <KeyboardArrowDownIcon
                                sx={{
                                    fill: theme.palette.grey[400],
                                    fontSize: '26px'
                                }}
                            />
                        </Box>
                        <Popover
                            id={id}
                            open={!!anchorEl}
                            anchorEl={anchorEl}
                            onClose={closePopover}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                            sx={{ '.MuiPaper-root': { width: '86%' } }}
                        >
                            {arrTabs.map((item: ItemType, index: number) => (
                                <Box
                                    onClick={() => handleSelect(item)}
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        p: 1,
                                        borderBottom: index + 1 !== arrTabs.length ? `1px solid ${theme.palette.grey[500]}` : ''
                                    }}
                                >
                                    <Box sx={{ width: '21px', height: '21px', mr: 1 }}>
                                        <CardMedia
                                            sx={{ objectFit: 'contain' }}
                                            component="img"
                                            width="18px"
                                            image={item.icon}
                                            alt="alt image"
                                        />
                                    </Box>
                                    <Typography
                                        sx={{
                                            color: theme.palette.grey[400],
                                            fontSize: '14px',
                                            fontWeight: 600,
                                            fontFamily: 'Inter'
                                        }}
                                    >
                                        {item.title}
                                    </Typography>
                                </Box>
                            ))}
                        </Popover>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={9}>
                    {isLoading && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
                            <CircularProgress />
                        </Box>
                    )}

                    {!isLoading && isPublicReportExpired && (
                        <Box sx={{ width: '315px', m: '15% auto 0' }}>
                            <NotFoundImg title={<FormattedMessage id="report_is_not_available_anymore" />} />
                        </Box>
                    )}
                    {!isLoading && !isPublicReportExpired && (
                        <TabFacebook
                            connection={connection}
                            setConnection={setConnection}
                            isReportAvailable={isReportAvailable}
                            title={arrTabs[tabValue].title}
                            report={report}
                            reportType={arrTabs[tabValue].report_type}
                            isPublicReport={isPublicReport}
                        />
                    )}
                </Grid>
            </Grid>
            {/* todo change to dynamic client org id */}
            {!isPublicReport && (
                <ShareReportDrawer clientOrganizationId={idClient ?? ''} openDivider={showDrawer} handleClickCloseDivider={closeDrawer} />
            )}
        </Box>
    );
};
