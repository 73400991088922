import { FC } from 'react';
import { EmployeeProvider } from '../components/employees/context/employee.context';
import { DescriptionProvider } from '../components/description/context/description.context';
import { DueDateProvider } from '../components/due-date/context/due-date.context';
import { RoleProvider } from '../components/role/context/role.context';
import { FieldProvider } from '../components/fields/context/field.context';
import useTask from '../hooks/api/task';

const Index: FC<{ formId?: string }> = ({ children, formId }) => {
    const { document, task } = useTask(formId);

    return (
        <EmployeeProvider fetchedEmployee={task?.user}>
            <DescriptionProvider fetchedDescription={task?.description}>
                <DueDateProvider fetchedDueDate={task?.due_date}>
                    <RoleProvider fetchedRole={task?.role}>
                        <FieldProvider fetchedDocument={document}>{children}</FieldProvider>
                    </RoleProvider>
                </DueDateProvider>
            </DescriptionProvider>
        </EmployeeProvider>
    );
};

export default Index;
