// import SidebarImage from '../../assets/newImages/sidebar/SidebarImage.png';
import ChatPreviewPlaceholderImage from '../../assets/newImages/sidebar/ChatPreviewPlaceholderImage.svg';

export const TeamChannelPreview = (props: { name: string; lastMessage: string; numberOfUnreadMessages: number; imageUrl: string }) => {
    const { name, lastMessage, numberOfUnreadMessages, imageUrl } = props;
    // console.log('In TeamChannelPreview: ', imageUrl);
    const channelImage = imageUrl.length > 0 ? imageUrl : ChatPreviewPlaceholderImage;
    return (
        <div className="channel-preview__item">
            {/* <img src={ChatPreviewPlaceholderImage} alt="Side bar" className="channel-preview__image" /> */}
            <img src={channelImage} alt="Side bar" className="channel-preview__image" />
            {/* <img src={SidebarImage} alt="Side bar" className="channel-preview__image" /> */}
            <div className="channel-preview__box">
                <div className="channel-preview__box_top">
                    <p>{`${name.substring(0, 25)}`}</p>
                    {/* <p className="channel-preview__box_paragraph">Yesterday</p> */}
                </div>
                <div className="channel-preview__box_bottom">
                    {/* <p className="channel-preview__box_bottom_paragraph">channel_preview_message</p> */}
                    <p className="channel-preview__box_bottom_paragraph">{`${lastMessage}`}</p>
                    {numberOfUnreadMessages > 0 ? <div className="channel-preview__box_paragraph1">{`${numberOfUnreadMessages}`}</div> : ''}
                </div>
            </div>
        </div>
    );
};
