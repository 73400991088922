import { Box, CircularProgress, Divider, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useContext, useEffect, useState } from 'react';
import { ColumnBodyOne } from 'views/pages/clients/features/detail/workflow/features/document-builder/ui';
import DueDate from 'views/pages/clients/features/detail/workflow/features/workflow-builder/components/task/components/due-date';
import Role from 'views/pages/clients/features/detail/workflow/features/workflow-builder/components/task/components/role';
import Description from 'views/pages/clients/features/detail/workflow/features/workflow-builder/components/task/components/description';
import DocumentBuilderContext from '../../../../../views/pages/clients/features/detail/workflow/features/document-builder/context/workflow.context';
import { GET_ORGANIZATION_MEMBERS } from 'services/graphQL';
import { useLazyQuery } from '@apollo/client';
import useAuth from 'hooks/useAuth';
import _ from 'lodash';
import DialogFooter from '../../../../../views/pages/clients/features/detail/workflow/features/workflow-builder/nodes/dialogFooter';
import FullPopOverSelector from 'views/pages/clients/components/PopOverSelector/FullPopOverSelector';
import AssignDocument from '../../../../../views/pages/clients/features/detail/workflow/features/workflow-builder/nodes/task-nodes/components/AssignDocument';
import { LogicBuilder } from '../../../../../views/pages/clients/features/detail/workflow/features/workflow-builder/nodes/task-nodes/components/LogicBuilder';
import {
    ConditionBuilderContext,
    useConditionBuilderContext
} from '../../../../../views/pages/clients/features/detail/workflow/features/workflow-builder/nodes/task-nodes/components/LogicBuilder/context/ConditionBuilderContext';
import { useParams } from 'react-router-dom';
import { WFTaskDocumentPermissions } from '@sni4/snikpic-common';
import { ReactFlowApprovalTaskNode, ReactFlowTaskNode } from 'features/workflow/types/react-flow.types';
import { TaskNodeHelper } from './TaskNodeHelper';
import { ApprovalTaskNodeHelper } from '../ApprovalTask/ApprovalTaskNodeHelper';
import { LogicBuilderTextExpression } from 'views/pages/clients/features/detail/workflow/features/workflow-builder/nodes/task-nodes/components/LogicBuilder/types/logicBuilder.types';

interface DialogTaskProps {
    onClose: () => void;
    id: string;
    type: string;
    nodeData: ReactFlowTaskNode['data'] | ReactFlowApprovalTaskNode['data'];
    isApprovalTask?: boolean;
}

const DialogTask = ({ onClose, id, type, nodeData, isApprovalTask = false }: DialogTaskProps) => {
    const theme = useTheme();
    const { member } = useAuth();
    const { idClient } = useParams();

    const { documentBuilder } = useContext(DocumentBuilderContext);

    const initialAssignee = nodeData?.assignee;

    const [organizationMembers, setOrganizationMembers] = useState<any>([]);

    const [selectedOrganizationMember, setSelectedOrganizationMember] = useState<any>();
    const [taskName, setTaskName] = useState<string>(nodeData?.name || '');
    const [numberOfDays, setNumberOfDays] = useState<number>(nodeData?.duration?.value || 0);
    const [role, setRole] = useState<string>(nodeData?.role || '');
    const [description, setDescription] = useState<string>(nodeData?.description || '');
    const [documentPermissions, setDocumentPermissions] = useState<WFTaskDocumentPermissions[]>(nodeData?.document_permissions || []);
    const [edges, setEdges] = useState<any[]>(documentBuilder.workflow.flowEdges || []);

    const conditionBuilderContext = useConditionBuilderContext(
        id,
        (nodeData as ReactFlowApprovalTaskNode['data'])?.conditions || [],
        edges,
        setEdges
    );

    const isSaveButtonDisabled =
        !selectedOrganizationMember ||
        !taskName ||
        !numberOfDays ||
        !role ||
        !documentPermissions.length ||
        (isApprovalTask &&
            (!(conditionBuilderContext.conditions[0]?.content[1] as LogicBuilderTextExpression)?.content ||
                !(conditionBuilderContext.conditions[0]?.content[2] as LogicBuilderTextExpression)?.content));

    const handleTaskNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newTaskName = event.target.value;
        setTaskName(newTaskName);
    };

    const handleNumberOfDaysChange = (newNumberOfDays: number) => {
        if (newNumberOfDays < 0) {
            return;
        }
        setNumberOfDays(newNumberOfDays);
    };

    const handleSave = () => {
        const arrNodes: any[] = [];
        documentBuilder.workflow.flowNodes.forEach((el: any) => {
            if (el.id === id) {
                if (type === 'task') {
                    const castedElement = el as ReactFlowTaskNode;
                    const updatedElement: ReactFlowTaskNode = {
                        ...castedElement,
                        data: {
                            ...castedElement.data,
                            assignee: selectedOrganizationMember.user.id,
                            name: taskName,
                            duration: {
                                unit: 'days',
                                value: numberOfDays
                            },
                            role,
                            description,
                            document_permissions: documentPermissions
                        }
                    };
                    const validatedAndUpdatedNode = TaskNodeHelper.validateNode(updatedElement);
                    arrNodes.push(validatedAndUpdatedNode);
                } else if (type === 'approval_task') {
                    const castedElement = el as ReactFlowApprovalTaskNode;
                    const updatedElement: ReactFlowApprovalTaskNode = {
                        ...castedElement,
                        data: {
                            ...castedElement.data,
                            assignee: selectedOrganizationMember.user.id,
                            name: taskName,
                            duration: {
                                unit: 'days',
                                value: numberOfDays
                            },
                            role,
                            description,
                            document_permissions: documentPermissions,
                            conditions: conditionBuilderContext.convertConditionsArrayToJsonLogic()
                        }
                    };
                    const validatedAndUpdatedNode = ApprovalTaskNodeHelper.validateNode(updatedElement);
                    arrNodes.push(validatedAndUpdatedNode);
                }
            } else {
                arrNodes.push(el);
            }
        });
        documentBuilder.workflow.setNodes(arrNodes);
        documentBuilder.workflow.setEdges(edges);
        onClose();
    };

    const [getOrganizationMembers, { loading: loadingOrganizationMembers }] = useLazyQuery(GET_ORGANIZATION_MEMBERS, {
        onCompleted: (data: any) => {
            setOrganizationMembers(data.organization_members);
            if (initialAssignee) {
                const assignee = _.find(data.organization_members, { user: { id: initialAssignee } });
                setSelectedOrganizationMember(assignee);
            }
        },
        onError: (error: any) => {}
    });

    useEffect(() => {
        getOrganizationMembers({ variables: { clientOrganizationId: idClient } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
            <Box sx={{ height: 'calc(90vh - 92px)', overflow: 'auto' }}>
                <ColumnBodyOne sx={{ padding: '20px 32px', width: '563px', gap: '24px', overflowY: 'auto' }}>
                    <Typography sx={{ fontWeight: 600, fontSize: '16px', color: theme.palette.text.primary }}>Task</Typography>
                    <Box>
                        {loadingOrganizationMembers ? (
                            <CircularProgress />
                        ) : (
                            <FullPopOverSelector
                                title="task_assigned_to_mandatory"
                                options={organizationMembers}
                                optionLabelPath="user.first_name"
                                optionIconPath="user.image_url"
                                setSelectedOption={setSelectedOrganizationMember}
                                selectedOption={selectedOrganizationMember}
                                searchInputPlaceholder="Search for a client"
                            />
                        )}
                    </Box>
                    <ColumnBodyOne>
                        <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600, fontSize: '14px' }}>
                            Task details
                        </Typography>{' '}
                        <TextField
                            sx={{
                                '.MuiOutlinedInput-input': { background: '#fff' }
                            }}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label="Task Name*"
                            placeholder="e.g., Create captions for publications"
                            value={taskName}
                            onChange={handleTaskNameChange}
                        />
                        {/* TODO - add due date, role, description component here (they are not complex enough to be a separate component) */}
                        <DueDate numberOfDays={numberOfDays} setNumberOfDays={handleNumberOfDaysChange} />
                        <Role role={role} setRole={setRole} />
                        <Description description={description} setDescription={setDescription} />
                        <Typography sx={{ fontWeight: 600, fontSize: '14px', color: theme.palette.text.primary }}>
                            Approval Logic
                        </Typography>
                        {isApprovalTask && (
                            <ConditionBuilderContext.Provider value={conditionBuilderContext}>
                                <LogicBuilder />
                            </ConditionBuilderContext.Provider>
                        )}
                    </ColumnBodyOne>
                </ColumnBodyOne>
                <Divider />
                <AssignDocument documentPermissions={documentPermissions} setDocumentPermissions={setDocumentPermissions} />
                <DialogFooter onClose={onClose} onSave={handleSave} isSaveButtonDisabled={isSaveButtonDisabled} />
            </Box>
        </Box>
    );
};

export default DialogTask;
