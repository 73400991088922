import { Box, Drawer, Typography, Grid, CardMedia, FormControlLabel, Switch, Divider } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import ButtonCustom from 'ui-component/extended/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import Facebook from 'assets/images/logos_facebook.svg';
import SelectCustom from 'ui-component/selectCustom';
import { SET_META_DATA } from 'services/graphQL/mutations/fbIntegration';
import { useMutation } from '@apollo/client';

interface DrawerAddOffersProps {
    openDivider: any;
    handleClickCloseDivider: any;
    metaData: any;
    clientId: string;
}

export const FacebookIntegrationDrawer = ({ openDivider, handleClickCloseDivider, metaData, clientId }: DrawerAddOffersProps) => {
    const theme = useTheme();
    const formik = useFormik({
        initialValues: {
            businesManager: '',
            pixelAccess: false,
            instagramAccess: false,
            domainAccess: false,
            leadsAccess: false
        },
        validationSchema: Yup.object({
            businesManager: Yup.string().required('Required')
        }),
        onSubmit: (values) => {
            const arrMetadata: any = ['page'];
            if (values.pixelAccess === true) arrMetadata.push('ad');
            if (values.instagramAccess === true) arrMetadata.push('instagram');
            if (values.domainAccess === true) arrMetadata.push('domain');
            if (values.leadsAccess === true) arrMetadata.push('lead');

            setUrlData({
                variables: {
                    client_organization_id: clientId,
                    categories: arrMetadata
                }
            });

            handleClickCloseDivider();
        }
    });
    const [setUrlData] = useMutation(SET_META_DATA, {
        onCompleted: (data) => {
            console.log('setUrlData', data);
        }
    });
    React.useEffect(() => {
        if (metaData?.scopes?.ad?.length > 0) {
            formik.setFieldValue('pixelAccess', true);
        }
        if (metaData?.scopes?.instagram?.length > 0) {
            formik.setFieldValue('instagramAccess', true);
        }
        if (metaData?.scopes?.domain?.length > 0) {
            formik.setFieldValue('domainAccess', true);
        }
        if (metaData?.scopes?.lead?.length > 0) {
            formik.setFieldValue('leadsAccess', true);
        }
        formik.setFieldValue('businesManager', metaData.business_name);
    }, [metaData]);

    const labels = [metaData.business_name];
    const handleBusinesManager = (value: string) => {
        formik.setFieldValue('businesManager', value);
    };
    return (
        <Drawer anchor="right" open={openDivider} onClose={handleClickCloseDivider}>
            <Box sx={{ width: { xs: '100%', sm: '562px' } }}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container direction="column" justifyContent="space-between" sx={{ minHeight: { xs: '100%', xl: '100vh' } }}>
                        <Grid item sx={{ p: '26px 32px' }}>
                            <Typography
                                mb={5}
                                sx={{ fontWeight: 600, fontFamily: 'Inter', color: theme.palette.common.black, fontSize: '16px' }}
                            >
                                <FormattedMessage id="facebook_integration" />
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box mr={1} sx={{ width: '24px' }}>
                                    <CardMedia
                                        sx={{ objectFit: 'contain' }}
                                        component="img"
                                        width="18px"
                                        image={Facebook}
                                        alt="alt image"
                                    />
                                </Box>
                                <Typography
                                    sx={{
                                        fontWeight: 600,
                                        fontFamily: 'Inter',
                                        color: theme.palette.grey[400],
                                        fontSize: '14px'
                                    }}
                                >
                                    Facebook
                                </Typography>
                            </Box>
                            <Typography
                                mt={2}
                                sx={{ fontWeight: 400, fontFamily: 'Inter', color: theme.palette.grey[600], fontSize: '14px' }}
                            >
                                <FormattedMessage id="fb_drawer_desc" />
                            </Typography>
                            <Typography
                                mt={3}
                                mb={1}
                                sx={{ fontWeight: 500, fontFamily: 'Inter', color: theme.palette.grey[400], fontSize: '14px' }}
                            >
                                <FormattedMessage id="meta_business_manager" />
                            </Typography>
                            <SelectCustom value={formik.values.businesManager} labels={labels} setValue={handleBusinesManager} />
                            <Typography
                                mt={1}
                                sx={{ fontWeight: 400, fontFamily: 'Inter', color: theme.palette.grey[300], fontSize: '14px' }}
                            >
                                <FormattedMessage id="fb_drawer_busines_selector_desc" />
                            </Typography>
                            <Typography
                                mt={3}
                                mb={3}
                                sx={{ fontWeight: 500, fontFamily: 'Inter', color: theme.palette.grey[400], fontSize: '14px' }}
                            >
                                <FormattedMessage id="advanced_facebook_settings" />
                            </Typography>
                            <Typography sx={{ fontWeight: 400, fontFamily: 'Inter', color: theme.palette.grey[300], fontSize: '14px' }}>
                                <FormattedMessage id="fb_settings_desc" />
                            </Typography>
                            <Typography
                                mt={3}
                                mb={3}
                                sx={{ fontWeight: 500, fontFamily: 'Inter', color: theme.palette.grey[400], fontSize: '14px' }}
                            >
                                <FormattedMessage id="request_full_ad_account_acess" />
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formik.values.pixelAccess}
                                        onChange={() => formik.setFieldValue('pixelAccess', !formik.values.pixelAccess)}
                                    />
                                }
                                label={
                                    <Typography
                                        sx={{ fontWeight: 400, fontFamily: 'Inter', color: theme.palette.grey[300], fontSize: '14px' }}
                                    >
                                        <FormattedMessage id="request_full_ad_account_acess_switch_lebel" />
                                    </Typography>
                                }
                            />
                            <Divider sx={{ m: '30px 0' }} />
                            <Typography
                                mt={3}
                                mb={3}
                                sx={{ fontWeight: 500, fontFamily: 'Inter', color: theme.palette.grey[400], fontSize: '14px' }}
                            >
                                <FormattedMessage id="request_full_instagram_access" />
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formik.values.instagramAccess}
                                        onChange={() => formik.setFieldValue('instagramAccess', !formik.values.instagramAccess)}
                                    />
                                }
                                label={
                                    <Typography
                                        sx={{ fontWeight: 400, fontFamily: 'Inter', color: theme.palette.grey[300], fontSize: '14px' }}
                                    >
                                        <FormattedMessage id="request_full_instagram_access_lebel" />
                                    </Typography>
                                }
                            />
                            <Divider sx={{ m: '30px 0' }} />
                            <Typography
                                mt={3}
                                mb={3}
                                sx={{ fontWeight: 500, fontFamily: 'Inter', color: theme.palette.grey[400], fontSize: '14px' }}
                            >
                                <FormattedMessage id="request_domain_asset_acess" />
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formik.values.domainAccess}
                                        onChange={() => formik.setFieldValue('domainAccess', !formik.values.domainAccess)}
                                    />
                                }
                                label={
                                    <Typography
                                        sx={{ fontWeight: 400, fontFamily: 'Inter', color: theme.palette.grey[300], fontSize: '14px' }}
                                    >
                                        <FormattedMessage id="request_domain_asset_acess_desc" />
                                    </Typography>
                                }
                            />
                            <Divider sx={{ m: '30px 0' }} />
                            <Typography
                                mt={3}
                                mb={3}
                                sx={{ fontWeight: 500, fontFamily: 'Inter', color: theme.palette.grey[400], fontSize: '14px' }}
                            >
                                <FormattedMessage id="request_lead_asset_access" />
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formik.values.leadsAccess}
                                        onChange={() => formik.setFieldValue('leadsAccess', !formik.values.leadsAccess)}
                                    />
                                }
                                label={
                                    <Typography
                                        sx={{ fontWeight: 400, fontFamily: 'Inter', color: theme.palette.grey[300], fontSize: '14px' }}
                                    >
                                        <FormattedMessage id="request_lead_asset_access_desc" />
                                    </Typography>
                                }
                            />
                        </Grid>
                        <Grid
                            item
                            container
                            direction="column"
                            justifyContent="flex-end"
                            alignItems="flex-end"
                            sx={{ borderTop: `1px solid ${theme.palette.grey[500]}`, p: '32px' }}
                        >
                            <Grid item>
                                <Box sx={{ display: 'flex' }}>
                                    <Box mr={2} sx={{ width: '87px' }}>
                                        <ButtonCustom
                                            onClick={handleClickCloseDivider}
                                            colorBtn="white"
                                            titleBtn={<FormattedMessage id="cancel" />}
                                        />
                                    </Box>
                                    <Box sx={{ width: '144px' }}>
                                        <ButtonCustom
                                            onClick={formik.handleSubmit}
                                            colorBtn="red"
                                            titleBtn={<FormattedMessage id="save_changes" />}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Drawer>
    );
};
