import axios from 'axios';

interface UpsertWhatsappBridgeInput {
    streamChannelId: string;
    whatsappNumber: string;
}

export const handleAddWhatsappMember = async (input: UpsertWhatsappBridgeInput) => {
    const baseURL = process.env.REACT_APP_SNIKPIC_BASE_URL;
    const endpointURL = `${baseURL}/message-forward/add_user_to_twilio_channel_and_create_it_if_needed`;
    try {
        const response = await axios.post(
            endpointURL,
            {
                streamChannelId: input.streamChannelId,
                whatsappNumber: input.whatsappNumber
            },
            {
                headers: {
                    authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '')?.tokens?.access_token ?? ''}`
                }
            }
        );
        return response.data;
    } catch (e) {
        const err: any = e;
        console.error(err.response.data);
        throw new Error(err.response.data.extensions.message);
    }
};

interface GetWhatsappBridgeDetailsInput {
    streamChannelId: string;
}

export const getWhatsappBridgeDetails = async (input: GetWhatsappBridgeDetailsInput) => {
    const baseURL = process.env.REACT_APP_SNIKPIC_BASE_URL;
    const endpointURL = `${baseURL}/stream/channels/get_wa_bridge_detail_by_channel_id`;
    try {
        const response = await axios.post(
            endpointURL,
            {
                channelId: input.streamChannelId
            },
            {
                headers: {
                    authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '')?.tokens?.access_token ?? ''}`
                }
            }
        );
        return response.data;
    } catch (e) {
        const err: any = e;
        console.error(err.response.data);
        throw new Error(err.response.data.extensions.message);
    }
};
