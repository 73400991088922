import React, { useState, useEffect } from 'react';
import { Box, Drawer, Typography, Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import { Button } from 'ui-component/extended/Button';
import DescriptionInputAiTool from 'modules/ai-tools/DescriptionInputAiTool';

interface DrawerAddOffersProps {
    open: boolean;
    onClose: () => void;
    description: string;
    onUpdate: (desc: string) => Promise<void>;
}

const EditDescDrawer = ({ open, onClose, description, onUpdate }: DrawerAddOffersProps) => {
    const theme = useTheme();
    const [errorDesc, setErrorDesc] = useState(false);
    const [desc, setDesc] = useState(description);
    const [loading, setLoading] = useState(false);
    const [descriptionValid, setDescriptionValid] = React.useState(false);

    useEffect(() => {
        if (open) {
            setDesc(description);
        }
    }, [description, open]);

    const handleSubmitDesc = async () => {
        try {
            setLoading(true);
            if (desc) {
                await onUpdate(desc);
                setErrorDesc(false);
                onClose();
            } else {
                setErrorDesc(true);
            }
        } catch (e: any) {
            console.log(e.message);
        } finally {
            setLoading(false);
        }
    };
    return (
        <Drawer anchor="right" open={open} onClose={onClose}>
            <Box>
                <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    sx={{ height: '100vh', width: { xs: '100%', sm: '562px' } }}
                >
                    <Grid item xs={10} sm={11} sx={{ p: 3 }}>
                        <Typography
                            sx={{
                                color: theme.palette.grey[400],
                                fontFamily: 'Inter',
                                fontWeight: 600,
                                fontSize: '16px',
                                mb: 3
                            }}
                        >
                            <FormattedMessage id="edit" />
                        </Typography>
                        <DescriptionInputAiTool
                            value={desc}
                            setValue={setDesc}
                            placholder="caption_generator_desc"
                            totalWordsLength={200}
                            error={errorDesc}
                            title="brand_description"
                            setValid={setDescriptionValid}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        sm={1}
                        sx={{ display: 'flex', justifyContent: 'flex-end', p: '16px', borderTop: `1px solid ${theme.palette.grey[500]}` }}
                    >
                        <Box sx={{ width: '90px', mr: 2 }}>
                            <Button variant="secondary" onClick={onClose} title={<FormattedMessage id="cancel" />} />
                        </Box>
                        <Box sx={{ width: '135px' }}>
                            <Button
                                onClick={handleSubmitDesc}
                                title={<FormattedMessage id="save_changes" />}
                                loading={loading}
                                disabled={!descriptionValid}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Drawer>
    );
};

export default EditDescDrawer;
