import { DateTime } from 'luxon';

interface IscheduleTOJson {
    type: string;
    data: any;
    id: string;
    newValue: any;
}

export const scheduleTOJson = ({ type, data, id, newValue }: IscheduleTOJson) => {
    data.setNodes((nodes = []) =>
        nodes.map((el: any) => {
            if (el.id === id) {
                if (type === 'date') {
                    try {
                        el.data.trigger_date = DateTime.fromISO((newValue as Date).toISOString())
                            .startOf('minute')
                            .toUTC()
                            .toISO();
                    } catch (error) {
                        // user is still typing
                    }
                }

                if (type === 'recurring') {
                    el.data.recurring_interval = {
                        unit: 'days',
                        value: newValue
                    };
                }
            }

            return el;
        })
    );
};
