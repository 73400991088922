import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, Typography } from '@mui/material';
import { useFieldHook } from './useFieldHook';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { BaseFieldProps } from 'features/workflow-task-execution/types';

interface TickBoxProps extends BaseFieldProps {}

// interface configuration {
//     question: string;
// }

export default function TickBox({ field, setSavingStatus, handleFieldValueUpdate }: TickBoxProps) {
    const [value, handleChange, intl] = useFieldHook<boolean>({ field, setSavingStatus, handleFieldValueUpdate });

    return (
        <Box mt={3}>
            <FormControl error={Boolean(field.permission?.is_required)}>
                <FormControlLabel
                    sx={{
                        '.MuiFormControlLabel-label': {
                            color: '#000',
                            fontFamily: 'Inter',
                            fontWeight: 500,
                            fontSize: '14px',
                            mt: '2px'
                        }
                    }}
                    control={
                        <Checkbox
                            checkedIcon={<CheckBoxOutlinedIcon />}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }}
                            checked={value}
                            onChange={(e) => handleChange(e.target.checked)}
                            name="boosted"
                            disabled={field.permission?.type === 'READ'}
                        />
                    }
                    label={field.configuration.question}
                />
                <FormHelperText>
                    {field.permission?.is_required
                        ? intl.formatMessage({ id: 'please_tick_this_box_if_necessary_as_part_of_your_task' })
                        : ''}
                </FormHelperText>
            </FormControl>
        </Box>
    );
}
