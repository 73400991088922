import { useState, useEffect } from 'react';
import {
    Typography,
    Box,
    Breadcrumbs,
    Grid,
    CircularProgress,
    CardMedia,
    Avatar,
    Divider,
    Drawer,
    Autocomplete,
    TextField,
    Checkbox,
    Slider,
    InputAdornment,
    FormControlLabel,
    FormGroup
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import EuroIcon from '@mui/icons-material/Euro';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from 'ui-component/extended/Button';

const optionsSkiltes = [{ title: 'Brand Designer' }, { title: 'Copywriting' }, { title: 'Design' }, { title: 'Content Writing' }];
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface CheckboxesType {
    value: string;
    check: boolean;
}

const FiltersFreelancers = ({ openDivider, handleClickCloseDivider }: any) => {
    const theme = useTheme();
    const intl = useIntl();
    const [valueSkiltest, setValueSkiltest] = useState<any>();
    const [level, setLevel] = useState(6);
    const [priceRange, setPriceRange] = useState({
        startPrice: '',
        endPrice: ''
    });
    const [availabilityCheckboxes, setAvailabilityCheckboxes] = useState<CheckboxesType[]>([
        { value: 'Less than 5 hours', check: false },
        { value: '5 - 10 hours', check: false },
        { value: '10 - 40 hours', check: false },
        { value: '40+ hours', check: false }
    ]);
    const [inputValueSkiltest, setInputValueSkiltest] = useState('');

    const handleChangeSkiltest = (event: any, value: any) => {
        setValueSkiltest(value);
    };
    console.log('valueSkiltest', valueSkiltest);

    const deleteValueSkiltest = (title: string) => {
        setValueSkiltest(valueSkiltest.filter((item: any) => item.title !== title));
    };
    const handleChangeLevel = (event: Event, newValue: number | number[]) => {
        setLevel(newValue as number);
    };
    function valueLevel(value: number) {
        return `${value}`;
    }
    const handlePrice = (value: string, name: string) => {
        setPriceRange({
            ...priceRange,
            [name]: value
        });
    };
    const handleChangeAvailabilityCheckboxes = (value: string) => {
        setAvailabilityCheckboxes(
            availabilityCheckboxes.map((item: CheckboxesType) => {
                if (item.value === value) return { value, check: !item.check };
                return item;
            })
        );
    };
    return (
        <Drawer anchor="right" open={openDivider} onClose={handleClickCloseDivider}>
            <Box sx={{ height: '100vh', width: '350px', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ height: 'calc(100vh - 90px)', p: 4, zIndex: 1, overflow: 'auto' }}>
                    <Typography ml={1} sx={{ color: '#000', fontFamily: 'Inter', fontWeight: 600, fontSize: '16px' }}>
                        <FormattedMessage id="filter" />
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3, alignItems: 'center', mb: 2 }}>
                        <Typography ml={1} sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600, fontSize: '14px' }}>
                            <FormattedMessage id="skillset" />
                        </Typography>
                        <Typography
                            ml={1}
                            sx={{ color: '#000', fontFamily: 'Inter', fontWeight: 500, fontSize: '12px', curosr: 'pointer' }}
                        >
                            <FormattedMessage id="clear_all" />
                        </Typography>
                    </Box>
                    <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={optionsSkiltes}
                        disableCloseOnSelect
                        value={valueSkiltest}
                        inputValue={inputValueSkiltest}
                        onInputChange={(event, newInputValue) => {
                            setInputValueSkiltest(newInputValue);
                        }}
                        onChange={handleChangeSkiltest}
                        getOptionLabel={(option) => option.title}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                {option.title}
                            </li>
                        )}
                        fullWidth
                        popupIcon={<ZoomInIcon />}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={intl.formatMessage({ id: 'search_skillset' })}
                                sx={{ '.MuiOutlinedInput-root': { background: '#fff' }, '.MuiOutlinedInput-input': { background: '#fff' } }}
                            />
                        )}
                        sx={{ '.MuiChip-root': { display: 'none' }, '.MuiButtonBase-root': { transform: 'none' } }}
                    />
                    {valueSkiltest &&
                        valueSkiltest.map((item: any, index: number) => (
                            <Box key={index} sx={{ display: 'flex', m: '10px 10px 0 0' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        border: `1px solid #AAAAAA`,
                                        p: '8px 16px',
                                        borderRadius: '40px'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: theme.palette.grey[400],
                                            fontFamily: 'Inter',
                                            fontWeight: 500,
                                            fontSize: '14px'
                                        }}
                                    >
                                        {item.title}
                                    </Typography>
                                    <CloseIcon
                                        onClick={() => deleteValueSkiltest(item.title)}
                                        sx={{ fill: theme.palette.grey[400], cursor: 'pointer', ml: 1, fontSize: '20px' }}
                                    />
                                </Box>
                            </Box>
                        ))}
                    <Divider sx={{ mt: 3 }} />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3, alignItems: 'center', mb: 2 }}>
                        <Typography ml={1} sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600, fontSize: '14px' }}>
                            <FormattedMessage id="minimum_experience_level" />
                        </Typography>
                        <Typography
                            ml={1}
                            sx={{ color: '#000', fontFamily: 'Inter', fontWeight: 500, fontSize: '12px', curosr: 'pointer' }}
                        >
                            <FormattedMessage id="clear_all" />
                        </Typography>
                    </Box>
                    <Slider
                        aria-label="Volume"
                        value={level}
                        onChange={handleChangeLevel}
                        step={1}
                        max={10}
                        getAriaValueText={() => valueLevel(level)}
                        valueLabelDisplay="on"
                        sx={{
                            height: '8px',
                            '.MuiSlider-rail': {
                                background: theme.palette.grey[800],
                                height: '8px'
                            },
                            '.MuiSlider-thumbColorPrimary': {
                                width: '24px',
                                height: '24px',
                                background: '#fff',
                                border: '1px solid #EE9A8F',
                                boxShadow: '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)'
                            },
                            '.MuiSlider-valueLabelOpen': {
                                background: 'transparent',
                                color: theme.palette.grey[400],
                                transform: 'translateY(150%) !important'
                            }
                        }}
                    />
                    <Divider sx={{ mt: 6 }} />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3, alignItems: 'center', mb: 3 }}>
                        <Typography ml={1} sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600, fontSize: '14px' }}>
                            <FormattedMessage id="price_range" />
                        </Typography>
                        <Typography
                            ml={1}
                            sx={{ color: '#000', fontFamily: 'Inter', fontWeight: 500, fontSize: '12px', curosr: 'pointer' }}
                        >
                            <FormattedMessage id="clear_all" />
                        </Typography>
                    </Box>
                    <Grid container spacing={4}>
                        <Grid item xs={6} sx={{ position: 'relative' }}>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '48px',
                                    right: '-20px',
                                    color: '#7A7A7A',
                                    fontSize: '16px',
                                    fontWeight: 600
                                }}
                            >
                                -
                            </Box>
                            <TextField
                                sx={{
                                    '.MuiOutlinedInput-input': {
                                        fontWeight: 400,
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        background: '#fff',
                                        ml: '-6px'
                                    },
                                    '.MuiOutlinedInput-root': {
                                        pl: 1
                                    }
                                }}
                                value={priceRange.startPrice}
                                onChange={(e) => handlePrice(e.target.value, 'startPrice')}
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EuroIcon sx={{ fontSize: '20px', fill: theme.palette.grey[400] }} />
                                        </InputAdornment>
                                    )
                                }}
                                placeholder="50"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                sx={{
                                    '.MuiOutlinedInput-input': {
                                        fontWeight: 400,
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        background: '#fff',
                                        ml: '-6px'
                                    },
                                    '.MuiOutlinedInput-root': {
                                        pl: 1
                                    }
                                }}
                                value={priceRange.endPrice}
                                onChange={(e) => handlePrice(e.target.value, 'endPrice')}
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EuroIcon sx={{ fontSize: '20px', fill: theme.palette.grey[400] }} />
                                        </InputAdornment>
                                    )
                                }}
                                placeholder="50"
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3, alignItems: 'center', mb: 3 }}>
                        <Typography ml={1} sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600, fontSize: '14px' }}>
                            <FormattedMessage id="availability" />
                        </Typography>
                        <Typography
                            ml={1}
                            sx={{ color: '#000', fontFamily: 'Inter', fontWeight: 500, fontSize: '12px', curosr: 'pointer' }}
                        >
                            <FormattedMessage id="clear_all" />
                        </Typography>
                    </Box>
                    <FormGroup>
                        {availabilityCheckboxes.map((checkbox: CheckboxesType, index: number) => (
                            <FormControlLabel
                                sx={{
                                    '.MuiTypography-root': {
                                        color: theme.palette.grey[400],
                                        fontWeight: '500',
                                        fontFamily: 'Inter'
                                    }
                                }}
                                key={index}
                                control={
                                    <Checkbox
                                        onChange={() => handleChangeAvailabilityCheckboxes(checkbox.value)}
                                        checked={checkbox.check}
                                    />
                                }
                                label={checkbox.value}
                            />
                        ))}
                    </FormGroup>
                </Box>
                <Box
                    sx={{
                        height: '90px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        p: '24px 32px',
                        borderTop: `1px solid ${theme.palette.grey[500]}`,
                        zIndex: 1,
                        background: '#fff'
                    }}
                >
                    <Box sx={{ display: 'flex' }}>
                        <Button
                            variant="secondary"
                            sx={{ width: '90px', mr: 1 }}
                            onClick={handleClickCloseDivider}
                            title={<FormattedMessage id="cancel" />}
                        />
                        <Button sx={{ width: '120px' }} onClick={handleClickCloseDivider} title={<FormattedMessage id="apply_filter" />} />
                    </Box>
                </Box>
            </Box>
        </Drawer>
    );
};

export default FiltersFreelancers;
