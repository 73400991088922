// action - state management
import { LOGIN, LOGOUT, REGISTER, CHANGE_ORGANIZATION } from './auth-actions';
import { InitialLoginContextProps } from 'types/auth';
import { saveState } from 'utils/storage';

// ==============================|| ACCOUNT REDUCER ||============================== //

interface AccountReducerActionProps {
    type: string;
    payload?: InitialLoginContextProps;
}

const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    tokens: null,
    user: null
};

export const accountReducer = (state = initialState, action: AccountReducerActionProps) => {
    let newState;
    switch (action.type) {
        case REGISTER: {
            const { user } = action.payload!;
            newState = { ...state, user };
            break;
        }
        case LOGIN: {
            const { user, tokens, member } = action.payload!;
            newState = { ...state, isLoggedIn: true, isInitialized: true, user, tokens, member };
            break;
        }
        case LOGOUT: {
            newState = { ...state, isLoggedIn: false, isInitialized: true, user: null, tokens: null, member: {} };
            break;
        }
        case CHANGE_ORGANIZATION: {
            const { member } = action.payload!;
            newState = { ...state, member };
            console.log('newState', newState);
            break;
        }
        default: {
            newState = state;
        }
    }

    saveState('user', newState);
    return newState;
};
