// routing
import Gleap from 'gleap';
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';

import { AuthProvider } from 'contexts/auth';
import { ClientProvider } from 'contexts/client';
import ReduxProvider from 'store/store';

import ErrorBoundary from 'ui-component/ErrorBoundary';
import SegmentIO from 'utils/SegmentIO';

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    Gleap.initialize('TbfPZOoHO8PYy2gMfuco4Et8BSarjW0m');
}

// ==============================|| APP ||============================== //

const App = () => (
    <ErrorBoundary>
        <ClientProvider>
            <ThemeCustomization>
                <Locales>
                    <NavigationScroll>
                        <AuthProvider>
                            <SegmentIO>
                                <>
                                    <Routes />
                                    <Snackbar />
                                </>
                            </SegmentIO>
                        </AuthProvider>
                    </NavigationScroll>
                </Locales>
            </ThemeCustomization>
        </ClientProvider>
    </ErrorBoundary>
);

export default App;
