import { useState, useEffect } from 'react';
import { Typography, Box, Breadcrumbs, Grid, Avatar, CardMedia, Divider, Drawer } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import { useLazyQuery } from '@apollo/client';
import { GET_USER_ORGANIZATIONS } from 'services/graphQL';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { Button } from 'ui-component/extended/Button';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import fa6_solid_medal from 'assets/images/fa6_solid_medal.svg';
import Portfolio from './feature/Portfolio';
import Reviews from './feature/Reviews';
import PastExperience from './feature/PastExperience';
import SelectPackageDrawer from '../feature/SelectPackageDrawer';
import portfolio2 from 'assets/images/portfolio2.png';
import portfolio from 'assets/images/portfolio.png';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import EditProfile from './feature/EditProfile';
import DIalogCreatePackage from './feature/DIalogCreatePackage';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DIalogAddPortfolio from './feature/DIalogAddPortfolio';
import Packages from './feature/Packages';
import DialogAddPastExperience from './feature/DIalogAddPastExperience';
import DIalogConfirmDelete from './feature/DIalogConfirmDelete';

const newFreelancer = {
    name: 'Albert Flores',
    type: 'Content Writer',
    description: 'Donec dictum tristique porta. Etiam convallis lorem lobortis nulla molestie, nec tincidunt ex Donec dictum tris...',
    works: [
        {
            skil: 'Caption Writing',
            rating: 'TOP 1%',
            worksCompleted: '15',
            snikpicClient: true,
            finance: 'Financial Services'
        },
        {
            skil: 'Copywriting',
            rating: 'TOP 5%',
            worksCompleted: '4'
        },
        {
            skil: 'Design',
            rating: 'TOP 10%',
            worksCompleted: '6'
        },
        {
            skil: 'Content Writing',
            rating: 'test not taken',
            worksCompleted: '9'
        }
    ],
    reviews: [
        {
            title: 'Social Media Design for Instagram',
            rating: 4.2,
            tasksCompleted: 3,
            price: '150',
            description:
                'Ut sodales, ex sit amet consectetur accumsan, nibh ex sollicitudin metus, volutpat lacinia arcu nibh vel ante. Proin dapibus dui eget justo tincidunt eleifend. Mauris porta elementum est. Nullam euismod quis libero sed convallis.'
        },
        {
            title: 'Social Media Design for Instagram',
            rating: 4.2,
            tasksCompleted: 3,
            price: '150',
            description:
                'Ut sodales, ex sit amet consectetur accumsan, nibh ex sollicitudin metus, volutpat lacinia arcu nibh vel ante. Proin dapibus dui eget justo tincidunt eleifend. Mauris porta elementum est. Nullam euismod quis libero sed convallis.'
        },
        {
            title: 'Social Media Design for Instagram',
            rating: 4.2,
            tasksCompleted: 3,
            price: '150',
            description:
                'Ut sodales, ex sit amet consectetur accumsan, nibh ex sollicitudin metus, volutpat lacinia arcu nibh vel ante. Proin dapibus dui eget justo tincidunt eleifend. Mauris porta elementum est. Nullam euismod quis libero sed convallis.'
        }
    ],
    portfolio: [
        {
            snikpicClient: true,
            finance: 'Financial Services',
            skil: 'Caption Writing',
            description:
                'Donec dictum tristique porta. Etiam convallis lorem lobortis nulla molestie, nec tincidunt ex Donec dictum tris...',
            images: [portfolio2, portfolio, portfolio2]
        },
        {
            snikpicClient: false,
            finance: 'Commerce',
            skil: 'Copywriting',
            description:
                'Donec dictum tristique porta. Etiam convallis lorem lobortis nulla molestie, nec tincidunt ex Donec dictum tris...',
            images: [portfolio2, portfolio, portfolio2]
        },
        {
            snikpicClient: false,
            finance: 'Commerce',
            skil: 'Copywriting',
            description:
                'Donec dictum tristique porta. Etiam convallis lorem lobortis nulla molestie, nec tincidunt ex Donec dictum tris...',
            images: [portfolio2, portfolio, portfolio2]
        },
        {
            snikpicClient: false,
            finance: 'Commerce',
            skil: 'Copywriting',
            description:
                'Donec dictum tristique porta. Etiam convallis lorem lobortis nulla molestie, nec tincidunt ex Donec dictum tris...',
            images: [portfolio2, portfolio, portfolio2]
        }
    ],
    packages: [
        {
            id: 1,
            description: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.',
            skil: 'Social Media Design for Instagram',
            jobs: [
                {
                    title: 'Story Highlights',
                    volume: 3,
                    minVolume: 3,
                    price: 50,
                    description:
                        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                },
                {
                    title: 'Carousel Post',
                    volume: 1,
                    minVolume: 1,
                    price: 100,
                    description:
                        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                },
                {
                    title: 'Pictural Post',
                    volume: 2,
                    minVolume: 2,
                    price: 200,
                    description:
                        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                },
                {
                    title: 'Story',
                    volume: 2,
                    minVolume: 2,
                    price: 200,
                    description:
                        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                }
            ],
            price: '€550'
        },
        {
            id: 2,
            description: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.',
            skil: 'Social Media Design for Instagram',
            jobs: [
                {
                    title: 'Story Highlights',
                    volume: 3,
                    minVolume: 3,
                    price: 50,
                    description:
                        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                },
                {
                    title: 'Carousel Post',
                    volume: 1,
                    minVolume: 1,
                    price: 100,
                    description:
                        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                },
                {
                    title: 'Pictural Post',
                    volume: 2,
                    minVolume: 2,
                    price: 200,
                    description:
                        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                },
                {
                    title: 'Story',
                    volume: 2,
                    minVolume: 2,
                    price: 200,
                    description:
                        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                }
            ],
            price: '€550'
        },
        {
            id: 3,
            description: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.',
            skil: 'Social Media Design for Instagram',
            jobs: [
                {
                    title: 'Story Highlights',
                    volume: 3,
                    minVolume: 3,
                    price: 50,
                    description:
                        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                },
                {
                    title: 'Carousel Post',
                    volume: 1,
                    minVolume: 1,
                    price: 100,
                    description:
                        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                },
                {
                    title: 'Pictural Post',
                    volume: 2,
                    minVolume: 2,
                    price: 200,
                    description:
                        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                },
                {
                    title: 'Story',
                    volume: 2,
                    minVolume: 2,
                    price: 200,
                    description:
                        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                }
            ],
            price: '€550'
        },
        {
            id: 4,
            description: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.',
            skil: 'Social Media Design for Instagram',
            jobs: [
                {
                    title: 'Story Highlights',
                    volume: 3,
                    minVolume: 3,
                    price: 50,
                    description:
                        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                },
                {
                    title: 'Carousel Post',
                    volume: 1,
                    minVolume: 1,
                    price: 100,
                    description:
                        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                },
                {
                    title: 'Pictural Post',
                    volume: 2,
                    minVolume: 2,
                    price: 200,
                    description:
                        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                },
                {
                    title: 'Story',
                    volume: 2,
                    minVolume: 2,
                    price: 200,
                    description:
                        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                }
            ],
            price: '€550'
        }
    ],
    pastExperience: [
        {
            title: 'Designer',
            subtitle: 'Snikpic',
            dateStart: '2019',
            dateEnd: '2022',
            desc: 'Ut sodales, ex sit amet consectetur accumsan, nibh ex sollicitudin metus, volutpat lacinia arcu nibh vel ante. Proin dapibus dui eget justo tincidunt eleifend. Mauris porta elementum est. Nullam euismod quis libero sed convallis. Vestibulum fringilla felis nec turpis aliquam auctor a a lectus. Etiam porttitor at eros vitae posuere. '
        },
        {
            title: 'Designer',
            subtitle: 'Snikpic',
            dateStart: '2019',
            dateEnd: 'Present',
            desc: 'Ut sodales, ex sit amet consectetur accumsan, nibh ex sollicitudin metus, volutpat lacinia arcu nibh vel ante. Proin dapibus dui eget justo tincidunt eleifend. Mauris porta elementum est. Nullam euismod quis libero sed convallis. Vestibulum fringilla felis nec turpis aliquam auctor a a lectus. Etiam porttitor at eros vitae posuere. '
        }
    ],
    rating: '4.2',
    worksCompleted: '34',
    startPrice: '550',
    online: false
};

const Profile = ({ freelancerSelected }: any) => {
    const theme = useTheme();
    const [organizationName, setOrganizationName] = useState('');
    const [spliceProtfolio, setSpliceProtfolio] = useState(2);
    const [selectPackageDrawer, setSelectPackageDrawer] = useState(false);
    const [dIalogCreatePackage, setDIalogCreatePackage] = useState(false);
    const [dialogAddPastExperience, setDialogAddPastExperience] = useState(false);
    const [dIalogAddPortfolio, setDIalogAddPortfolio] = useState(false);
    const [editProfile, setEditProfile] = useState(false);
    const [freelancer, setFreelancer] = useState<any>();
    const [spliceReviews, setSpliceReviews] = useState(2);
    const userId = JSON.parse(`${localStorage.getItem('user')}`);
    const intl = useIntl();
    const handleOpenSelectPackageDrawer = () => setSelectPackageDrawer(true);
    const handleCloseSelectPackageDrawer = () => setSelectPackageDrawer(false);
    const handleOpendialogCreatePackage = () => setDIalogCreatePackage(true);
    const handleCloseDIalogCreatePackage = () => setDIalogCreatePackage(false);
    const handleOpendIalogAddPortfolio = () => setDIalogAddPortfolio(true);
    const handleClosedIalogAddPortfolio = () => setDIalogAddPortfolio(false);
    const handleOpenDialogAddPastExperience = () => setDialogAddPastExperience(true);
    const handleCloseDialogAddPastExperience = () => setDialogAddPastExperience(false);
    const role = 'freelancer';
    const [getUser] = useLazyQuery(GET_USER_ORGANIZATIONS, {
        onCompleted: (res) => {
            setOrganizationName(res.organizations[0].name);
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first'
    });

    useEffect(() => {
        setFreelancer(freelancerSelected);
        getUser({
            variables: {
                user_id: userId.user.id
            }
        });
        if (role === 'freelancer') {
            setFreelancer(newFreelancer);
        } else setFreelancer(freelancerSelected);
    }, []);
    return (
        <Box>
            {editProfile ? (
                <EditProfile setEditProfile={setEditProfile} freelancer={freelancer} />
            ) : (
                <Box>
                    {role === 'freelancer' ? (
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography
                                sx={{ textTransform: 'capitalize', color: theme.palette.grey[200], fontFamily: 'Inter', fontWeight: 500 }}
                            >
                                <FormattedMessage id="freelancer" />
                            </Typography>
                            <Typography
                                sx={{ textTransform: 'capitalize', color: theme.palette.grey[300], fontFamily: 'Inter', fontWeight: 500 }}
                            >
                                <FormattedMessage id="profile" />
                            </Typography>
                        </Breadcrumbs>
                    ) : (
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography
                                sx={{ textTransform: 'capitalize', color: theme.palette.grey[200], fontFamily: 'Inter', fontWeight: 500 }}
                            >
                                {organizationName}
                            </Typography>
                            <Typography
                                sx={{ textTransform: 'capitalize', color: theme.palette.grey[200], fontFamily: 'Inter', fontWeight: 500 }}
                            >
                                <FormattedMessage id="marketplace" />
                            </Typography>
                            <Typography
                                sx={{ textTransform: 'capitalize', color: theme.palette.grey[300], fontFamily: 'Inter', fontWeight: 500 }}
                            >
                                <FormattedMessage id="profile" />
                            </Typography>
                        </Breadcrumbs>
                    )}
                    <Typography
                        mt={2}
                        mb={3}
                        sx={{
                            textTransform: 'capitalize',
                            color: theme.palette.grey[400],
                            fontSize: '24px',
                            fontWeight: 500,
                            fontFamily: 'Inter'
                        }}
                    >
                        <FormattedMessage id="profile" />
                    </Typography>
                    <Grid container justifyContent="space-between">
                        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar sx={{ width: '98px', height: '98px' }} alt="Remy Sharp" src="" />
                            <Box ml={3}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography
                                        sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600, fontSize: '24px' }}
                                    >
                                        {freelancer?.name}
                                    </Typography>
                                    <ModeEditOutlineOutlinedIcon
                                        onClick={() => setEditProfile(true)}
                                        sx={{ fill: theme.palette.grey[400], ml: 1, cursor: 'pointer' }}
                                    />
                                </Box>
                                <Typography
                                    sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500, fontSize: '16px', mt: 1 }}
                                >
                                    {freelancer?.type}
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                    <StarRoundedIcon sx={{ fill: '#FDB022' }} />
                                    <Typography
                                        sx={{
                                            color: '#FDB022',
                                            fontFamily: 'Inter',
                                            fontWeight: 600,
                                            fontSize: '14px',
                                            ml: '4px'
                                        }}
                                    >
                                        {freelancer?.rating}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: theme.palette.grey[300],
                                            fontFamily: 'Inter',
                                            fontWeight: 400,
                                            fontSize: '14px',
                                            ml: '4px'
                                        }}
                                    >
                                        {`(${freelancer?.worksCompleted} Jobs Done)`}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        {role !== 'freelancer' && (
                            <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    variant="secondary"
                                    sx={{ width: '102px', mr: 2 }}
                                    onClick={() => console.log(1)}
                                    title={<FormattedMessage id="messageOne" />}
                                />
                                <Button
                                    sx={{ width: '140px' }}
                                    onClick={handleOpenSelectPackageDrawer}
                                    title={<FormattedMessage id="send_proposal" />}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Box sx={{ display: 'flex', mt: 3 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                border: `1px solid #AAAAAA`,
                                p: '12px 18px',
                                borderRadius: '40px'
                            }}
                        >
                            <QueryBuilderIcon sx={{ fill: theme.palette.grey[400], cursor: 'pointer', mr: 1, fontSize: '20px' }} />
                            <Typography
                                sx={{
                                    color: theme.palette.grey[400],
                                    fontFamily: 'Inter',
                                    fontWeight: 500,
                                    fontSize: '14px'
                                }}
                            >
                                15 hours available/week
                            </Typography>
                        </Box>
                    </Box>
                    <Typography
                        sx={{
                            color: theme.palette.grey[400],
                            fontFamily: 'Inter',
                            fontWeight: 400,
                            fontSize: '15px',
                            maxWith: '620px',
                            mt: 3
                        }}
                    >
                        {freelancer?.description}
                    </Typography>
                    <Typography
                        sx={{
                            color: '#2D2D2D',
                            fontFamily: 'Inter',
                            fontWeight: 600,
                            fontSize: '18px',
                            mt: 5,
                            mb: 2
                        }}
                    >
                        <FormattedMessage id="skills" />
                    </Typography>
                    <Grid container spacing={3}>
                        {freelancer?.works.map((item: any, index: number) => (
                            <Grid item sx={{ width: { xs: '100%', sm: '20%' } }}>
                                <Box sx={{ background: theme.palette.grey[700], p: '20px', borderRadius: '8px' }}>
                                    <Box sx={{ alignItems: 'center', display: 'flex' }}>
                                        <Box sx={{ width: '24px', height: '24px', mr: 1 }}>
                                            <CardMedia component="img" image={fa6_solid_medal} alt="AI tools" />
                                        </Box>
                                        <Typography
                                            sx={{
                                                color: theme.palette.grey[400],
                                                fontFamily: 'Inter',
                                                fontWeight: 600,
                                                fontSize: '12px',
                                                textTransform: 'uppercase',
                                                letterSpacing: '0.12em'
                                            }}
                                        >
                                            {item.rating}
                                        </Typography>
                                    </Box>
                                    <Typography
                                        sx={{
                                            color: theme.palette.grey[400],
                                            fontFamily: 'Inter',
                                            fontWeight: 500,
                                            fontSize: '16px',
                                            mt: 3
                                        }}
                                    >
                                        {item.skil}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: theme.palette.grey[600],
                                            fontFamily: 'Inter',
                                            fontWeight: 500,
                                            fontSize: '14px',
                                            mt: 3
                                        }}
                                    >
                                        {`${item.worksCompleted} Jobs Done`}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                    <Divider sx={{ mt: 4, mb: 5 }} />
                    <Box sx={{ display: 'flex' }}>
                        <Typography
                            sx={{
                                color: '#2D2D2D',
                                fontFamily: 'Inter',
                                fontWeight: 600,
                                fontSize: '18px',
                                mb: 2
                            }}
                        >
                            <FormattedMessage id="packages" />
                        </Typography>
                        {role === 'freelancer' && (
                            <AddOutlinedIcon
                                onClick={handleOpendialogCreatePackage}
                                sx={{
                                    fill: theme.palette.grey[400],
                                    p: '1px',
                                    border: `1px solid ${theme.palette.grey[400]}`,
                                    borderRadius: '30px',
                                    ml: 2,
                                    cursor: 'pointer',
                                    fontSize: '20px'
                                }}
                            />
                        )}
                    </Box>
                    <Grid container spacing={2}>
                        {freelancer?.packages.map((item: any, index: number) => (
                            <Grid item xs={12} sm={3} key={index}>
                                <Packages packageContainer={item} />
                            </Grid>
                        ))}
                    </Grid>
                    <Divider sx={{ mt: 4, mb: 5 }} />
                    <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                        <Typography
                            sx={{
                                color: '#2D2D2D',
                                fontFamily: 'Inter',
                                fontWeight: 600,
                                fontSize: '18px'
                            }}
                        >
                            <FormattedMessage id="porfolio" />
                        </Typography>
                        {role === 'freelancer' && (
                            <AddOutlinedIcon
                                onClick={handleOpendIalogAddPortfolio}
                                sx={{
                                    fill: theme.palette.grey[400],
                                    p: '1px',
                                    border: `1px solid ${theme.palette.grey[400]}`,
                                    borderRadius: '30px',
                                    ml: 2,
                                    cursor: 'pointer',
                                    fontSize: '20px'
                                }}
                            />
                        )}
                    </Box>
                    {freelancer &&
                        [...freelancer.portfolio].splice(0, spliceProtfolio).map((item: any, index: number) => (
                            <Box key={index}>
                                <Portfolio porfolio={item} role={role} />
                            </Box>
                        ))}
                    {spliceProtfolio !== freelancer?.portfolio.length && freelancer?.portfolio.length > 2 && (
                        <Button
                            sx={{ width: '120px' }}
                            variant="secondary"
                            title={<FormattedMessage id="view_more" />}
                            onClick={() => setSpliceProtfolio(freelancer?.portfolio.length)}
                        />
                    )}
                    <Divider sx={{ mt: 4, mb: 5 }} />
                    <Typography
                        sx={{
                            color: '#2D2D2D',
                            fontFamily: 'Inter',
                            fontWeight: 600,
                            fontSize: '18px',
                            mb: 2
                        }}
                    >
                        {`${intl.formatMessage({ id: 'reviews' })} (${freelancer?.reviews.length})`}
                    </Typography>
                    {freelancer &&
                        [...freelancer.reviews].splice(0, spliceReviews).map((item: any, index: number) => (
                            <Box key={index}>
                                <Reviews review={item} />
                            </Box>
                        ))}
                    {spliceReviews !== freelancer?.reviews.length && freelancer?.reviews.length > 2 && (
                        <Button
                            sx={{ width: '120px' }}
                            variant="secondary"
                            title={<FormattedMessage id="view_more" />}
                            onClick={() => setSpliceReviews(freelancer?.reviews.length)}
                        />
                    )}
                    <Divider sx={{ mt: 4, mb: 5 }} />
                    <Box sx={{ display: 'flex' }}>
                        <Typography
                            sx={{
                                color: '#2D2D2D',
                                fontFamily: 'Inter',
                                fontWeight: 600,
                                fontSize: '18px',
                                mb: 2
                            }}
                        >
                            <FormattedMessage id="past_experience" />
                        </Typography>
                        {role === 'freelancer' && (
                            <AddOutlinedIcon
                                onClick={handleOpenDialogAddPastExperience}
                                sx={{
                                    fill: theme.palette.grey[400],
                                    p: '1px',
                                    border: `1px solid ${theme.palette.grey[400]}`,
                                    borderRadius: '30px',
                                    ml: 2,
                                    cursor: 'pointer',
                                    fontSize: '20px'
                                }}
                            />
                        )}
                    </Box>
                    {freelancer &&
                        [...freelancer.pastExperience].map((item: any, index: number) => (
                            <Box key={index}>
                                <PastExperience pastExperience={item} role={role} />
                            </Box>
                        ))}
                    <SelectPackageDrawer
                        open={selectPackageDrawer}
                        onClose={handleCloseSelectPackageDrawer}
                        packages={freelancer?.packages}
                    />
                </Box>
            )}
            <Drawer
                anchor="right"
                onClose={handleCloseDIalogCreatePackage}
                open={dIalogCreatePackage}
                sx={{
                    '.MuiPaper-root': {
                        maxWidth: '562px'
                    }
                }}
            >
                <DIalogCreatePackage onClose={handleCloseDIalogCreatePackage} />
            </Drawer>
            <DIalogAddPortfolio onClose={handleClosedIalogAddPortfolio} openDialog={dIalogAddPortfolio} />
            <DialogAddPastExperience onClose={handleCloseDialogAddPastExperience} openDialog={dialogAddPastExperience} />
        </Box>
    );
};

export default Profile;
