import useAuth from 'hooks/useAuth';
import { useEffect, ReactElement, useState } from 'react';
import { useLocation } from 'react-router-dom';

const SegmentIO = ({ children }: { children: ReactElement | null }) => {
    const [previousLocation, setPreviousLocation] = useState<string | null>(null);
    const [userIsIdentified, setUserIsIdentified] = useState<Boolean>(false);
    const { user } = useAuth();
    const location = useLocation();

    useEffect(() => {
        if (userIsIdentified) {
            return;
        }
        if (user?.id && (window as any)?.analytics) {
            (window as any).analytics.identify(user.id, {
                name: user.first_name,
                email: user.email
            });
            setUserIsIdentified(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (location.pathname !== previousLocation && (window as any)?.analytics) {
            setPreviousLocation(location.pathname);
            (window as any).analytics.page();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return children || null;
};

export default SegmentIO;
