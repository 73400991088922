import { Box } from '@mui/material';
import { memo, useContext, useState } from 'react';
import { Handle, Position } from 'react-flow-renderer';
import DocumentBuilderContext from '../../../document-builder/context/workflow.context';
import ButtonDeleteNode from '../../components/buttonDeleteNode';
import { removeNodes } from '../../diagram/hooks/delete-nodes';
import DialogConfirmDelete from '../../sideBar/DialogConfirmDelete';

export default memo(({ data, isConnectable, id }: any) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { documentBuilder } = useContext(DocumentBuilderContext);

    const handleDeleteNode = () => {
        removeNodes(documentBuilder.workflow, id);
        handleClose();
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <Handle
                type="target"
                position={Position.Top}
                isConnectable={isConnectable}
                style={{ width: '9px', height: '9px', background: '#98A2B3', opacity: 1 }}
            />
            <Box
                sx={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '64px',
                    border: '1px solid #E0E0E0',
                    padding: '8px',
                    backgroundColor: 'white',
                    boxShadow: '0px 10px 16px rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Box sx={{ width: '24px', height: '24px', borderRadius: '38px', backgroundColor: 'black' }} />
            </Box>
            <Box sx={{ position: 'absolute', top: '-40px', right: 0 }}>
                <ButtonDeleteNode className="delbutton" onClick={handleOpen} />
            </Box>
            <DialogConfirmDelete open={open} handleClose={handleClose} onClick={handleDeleteNode} />
        </Box>
    );
});
