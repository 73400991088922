import { Grid, Box, Typography, Divider, CardMedia, Snackbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import React from 'react';
import ButtonCustom from 'ui-component/extended/Button';
import step2 from 'assets/images/step2.png';
import step3 from 'assets/images/step3.jpg';
import flag from 'assets/images/flag.svg';
import stepByStep from 'assets/stepByStep.mp4';
import { useLazyQuery } from '@apollo/client';
import IntegrationDialogSuccess from './IntegrationDialogSuccess';
import Slide, { SlideProps } from '@mui/material/Slide';
import { VERIFY_RESOURCES } from 'services/graphQL/queries/fbIntegration';
import { MetaDataProps } from 'types/facebook';

type TransitionProps = Omit<SlideProps, 'direction'>;

function TransitionDown(props: TransitionProps) {
    return <Slide {...props} direction="down" />;
}

interface UserDataType {
    accessToken: string;
    userID: string;
}
interface StepThreeProps {
    userData: UserDataType;
    urlMetaData: MetaDataProps;
    context: any;
    setContext: any;
    setStep: any;
}

export default function StepThree({ userData, urlMetaData, context, setContext, setStep }: StepThreeProps) {
    const theme = useTheme();
    const [openDialogIntegrate, setOpenDialogIntegrate] = React.useState(false);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [transition, setTransition] = React.useState<React.ComponentType<TransitionProps> | undefined>(undefined);

    const handleClick = (Transition: React.ComponentType<TransitionProps>) => {
        setTransition(() => Transition);
        setOpenSnackbar(true);
    };
    const handleClose = () => {
        setOpenSnackbar(false);
    };
    const handleCloseDialog = () => {
        setOpenDialogIntegrate(false);
        setStep(4);
    };
    const handleOpenDialog = () => {
        setOpenDialogIntegrate(true);
    };
    const params = useParams();
    const organizationId = params.organizationId;
    const [verifyResources] = useLazyQuery(VERIFY_RESOURCES, {
        variables: {
            client_organization_id: organizationId
        },
        onCompleted: (verified: any) => {
            for (const resource of verified.verify_facebook_resources) {
                if (resource.resource_type === 'ad') {
                    if (resource.status === 'COMPLETED') {
                        handleOpenDialog();
                        return;
                    }
                }
            }
            handleClick(TransitionDown);
            // setResources(clientResources.client_fb_resources);
        }
    });

    const openBusinessManager = () => {
        window.open(`https://business.facebook.com/settings/partners/${context.agency_business.id}?business_id=${context.business.id}`);
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <Box sx={{ p: { xs: '24px', sm: '24px 95px' } }}>
                <Grid container justifyContent="space-between">
                    <Grid item xs={12} sm={8}>
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontFamily: 'Inter',
                                color: theme.palette.grey[400],
                                fontSize: '16px'
                            }}
                        >
                            <FormattedMessage id="youre_almost_done_one_more_step" />
                        </Typography>
                        <Typography
                            mt={2}
                            sx={{
                                fontWeight: 400,
                                fontFamily: 'Inter',
                                color: theme.palette.grey[300],
                                fontSize: '14px',
                                maxWidth: { xs: '100%', sm: '70%' }
                            }}
                        >
                            <FormattedMessage id="to_grant_access_to_your" />
                            <span className="spanFacebookStep3">
                                <FormattedMessage id="ad_account_domain" />
                            </span>
                            and
                            <span className="spanFacebookStep3">
                                <FormattedMessage id="leads_access" />
                            </span>
                            <FormattedMessage id="please_follow" />
                            <span className="spanFacebookStep3">
                                <FormattedMessage id="these_simple_instructions" />
                            </span>
                        </Typography>
                    </Grid>
                    {/* <Grid item xs={12} sm={4} sx={{ mt: { xs: '20px', sm: 0 } }}>
                        <Box sx={{ background: theme.palette.grey[100], p: '24px', borderRadius: '8px' }}>
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontFamily: 'Inter',
                                    fontStyle: 'italic',
                                    color: theme.palette.error[200],
                                    fontSize: '14px'
                                }}
                            >
                                <FormattedMessage id="cant_do_this_right_now" />{' '}
                                <Link to="#" className="linkStep3">
                                    {' '}
                                    <FormattedMessage id="click_here_to_receive_these_instructions_via_email" />
                                </Link>
                            </Typography>
                        </Box>
                    </Grid> */}
                </Grid>
                <Divider sx={{ m: '30px 0' }} />
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontFamily: 'Inter',
                        color: theme.palette.grey[400],
                        fontSize: '14px'
                    }}
                >
                    1. <FormattedMessage id="open_your" />
                    <span className="spanFacebookStep3">
                        <FormattedMessage id="business_manager" />
                    </span>{' '}
                    - you will already see your new partner <span className="spanFacebookStep3">“{urlMetaData.business_name}”</span> added
                </Typography>
                <Box mt={3} mb={3} sx={{ width: { xs: '100%', sm: '234px' } }}>
                    <ButtonCustom onClick={openBusinessManager} colorBtn="red" titleBtn={<FormattedMessage id="open_business_manager" />} />
                </Box>
                <Typography
                    mb={3}
                    sx={{
                        fontWeight: 400,
                        fontFamily: 'Inter',
                        color: theme.palette.grey[400],
                        fontSize: '14px'
                    }}
                >
                    2. <FormattedMessage id="in_your_business_manager_click_on" />
                    <span className="spanFacebookStep3">
                        <FormattedMessage id="share_assets" />
                    </span>
                    (<FormattedMessage id="displayed_under" />
                    <span className="spanFacebookStep3">{context.business?.name}</span>)
                </Typography>
                <Box>
                    <CardMedia sx={{ objectFit: 'contain' }} component="img" image={step2} alt="AI tools" />
                </Box>
                <Typography
                    mt={3}
                    mb={3}
                    sx={{
                        fontWeight: 400,
                        fontFamily: 'Inter',
                        color: theme.palette.grey[400],
                        fontSize: '14px'
                    }}
                >
                    3. <FormattedMessage id="select" />
                    <span className="spanFacebookStep3">
                        &quot;
                        <FormattedMessage id="share_assets" />
                        &quot;
                    </span>
                    <FormattedMessage id="on_the_left" />.
                    <span className="spanFacebookStep3">
                        <FormattedMessage id="choose_your_ad_account" />
                    </span>
                    and
                    <span className="spanFacebookStep3">
                        <FormattedMessage id="switch_on" />
                    </span>
                    &quot;
                    <FormattedMessage id="manage_ad_account" />
                    &quot;
                </Typography>
                <Box>
                    <CardMedia sx={{ objectFit: 'contain' }} component="img" image={step3} alt="AI tools" />
                </Box>
                <Typography
                    mt={3}
                    mb={3}
                    sx={{
                        fontWeight: 400,
                        fontFamily: 'Inter',
                        color: theme.palette.grey[400],
                        fontSize: '14px'
                    }}
                >
                    4. <FormattedMessage id="select" />
                    <span className="spanFacebookStep3">
                        &quot;
                        <FormattedMessage id="domains" />
                        &quot;
                    </span>
                    <FormattedMessage id="on_the_left" />.
                    <span className="spanFacebookStep3">
                        <FormattedMessage id="choose_your_domain" />
                    </span>
                    and
                    <span className="spanFacebookStep3">
                        <FormattedMessage id="switch_on" />
                    </span>
                    &quot;
                    <FormattedMessage id="manage_domain" />
                    &quot;
                </Typography>
                <Typography
                    mt={3}
                    mb={3}
                    sx={{
                        fontWeight: 400,
                        fontFamily: 'Inter',
                        color: theme.palette.grey[400],
                        fontSize: '14px'
                    }}
                >
                    5. <FormattedMessage id="select" />
                    <span className="spanFacebookStep3">
                        &quot;
                        <FormattedMessage id="leads_access" />
                        &quot;
                    </span>
                    <FormattedMessage id="on_the_left" />.
                    <span className="spanFacebookStep3">
                        <FormattedMessage id="choose_your_leads_access" />
                    </span>
                    and
                    <span className="spanFacebookStep3">
                        <FormattedMessage id="switch_on" />
                    </span>
                    &quot;
                    <FormattedMessage id="manage_leads_access" />
                    &quot;
                </Typography>
                <Typography
                    mt={3}
                    sx={{
                        fontWeight: 400,
                        fontFamily: 'Inter',
                        color: theme.palette.grey[400],
                        fontSize: '14px'
                    }}
                >
                    6. <FormattedMessage id="click" />
                    <span className="spanFacebookStep3">
                        <FormattedMessage id="save_changes" />
                    </span>
                </Typography>
                <Divider sx={{ m: '30px 0' }} />
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontFamily: 'Inter',
                        color: theme.palette.grey[400],
                        fontSize: '16px',
                        mb: { xs: 0, sm: '20px' }
                    }}
                >
                    <FormattedMessage id="watch_our_video_step_by_step" />
                </Typography>
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <video width="100%" height="600" controls>
                        <source src={stepByStep} type="video/mp4" />
                        <track src="" kind="captions" label="english_captions" />
                    </video>
                </Box>
                <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <video width="100%" height="300" controls>
                        <source src={stepByStep} type="video/mp4" />
                        <track src="" kind="captions" label="english_captions" />
                    </video>
                </Box>
                <Divider sx={{ m: '30px 0' }} />
                <Grid container justifyContent="space-between">
                    <Grid item xs={12} sm={8}>
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontFamily: 'Inter',
                                color: theme.palette.grey[400],
                                fontSize: '14px'
                            }}
                        >
                            <FormattedMessage id="done_hit" />
                            <span className="spanFacebookStep3">
                                &quot;
                                <FormattedMessage id="continue" />
                                &quot;
                            </span>
                        </Typography>
                        <Box mt={3} sx={{ width: { xs: '100%', sm: '60%' } }}>
                            <ButtonCustom onClick={verifyResources} colorBtn="red" titleBtn={<FormattedMessage id="continue" />} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} sx={{ mt: { xs: '20px', sm: 0 } }}>
                        <Box sx={{ background: theme.palette.orange[200], p: '24px', borderRadius: '8px' }}>
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontFamily: 'Inter',
                                    fontStyle: 'italic',
                                    color: theme.palette.error[200],
                                    fontSize: '14px'
                                }}
                            >
                                <FormattedMessage id="cant_do_this_right_now" />{' '}
                                <Link to="#" className="linkStep3">
                                    {' '}
                                    <FormattedMessage id="click_here_to_receive_these_instructions_via_email" />
                                </Link>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <IntegrationDialogSuccess openDialogIntegrate={openDialogIntegrate} handleCloseDialog={handleCloseDialog} />
            <Snackbar
                sx={{
                    '.MuiPaper-root': {
                        background: theme.palette.primary[500],
                        minWidth: '220px',
                        boxShadow: 'none',
                        borderRadius: '8px',
                        p: '8px 16px'
                    }
                }}
                open={openSnackbar}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                TransitionComponent={transition}
                autoHideDuration={2000}
                message={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box ml={1} sx={{ width: '24px' }}>
                            <CardMedia sx={{ objectFit: 'contain' }} component="img" image={flag} alt="AI tools" />
                        </Box>
                        <Typography sx={{ fontFamily: 'Inter', fontWeight: 500, fontSize: '14px', color: theme.palette.secondary[400] }}>
                            <FormattedMessage id="error_integration" />
                        </Typography>
                    </Box>
                }
                key={transition ? transition.name : ''}
            />
        </Box>
    );
}
