import axios, { AxiosRequestConfig } from 'axios';
import useAuth from 'hooks/useAuth';
import { filter } from 'lodash';

interface TaskAttachment {
    name: string;
    url: string;
}
interface CreateStandaloneTaskInput {
    organizationId: string;
    clientOrganizationId: string;
    projectId: string;
    userId: string;
    title: string;
    description: string;
    attachments: TaskAttachment[] | [];
    dueDate: Date;
}

interface UpdateStandaloneTaskInput extends CreateStandaloneTaskInput {
    id: number;
    status: string;
    type: string;
}

const post = async (path: string, data: any, config?: AxiosRequestConfig<any>) => {
    const baseURL = process.env.REACT_APP_SNIKPIC_BASE_URL;
    if (!path.startsWith('/')) {
        path = `/${path}`;
    }
    const endpointURL = `${baseURL}${path}`;
    try {
        const response = await axios.post(endpointURL, data, config);
        return response.data;
    } catch (e) {
        console.error(JSON.stringify(e));
        throw e;
    }
};

interface CreateWorkflowVersionInput {
    workflowId: string;
    documentId: string;
    content: any;
}

export const createWorkflowVersion = async (input: CreateWorkflowVersionInput) => {
    const baseURL = process.env.REACT_APP_SNIKPIC_BASE_URL;
    const endpointURL = `${baseURL}/workflow/document/insert_document_version`;
    const response = await axios.post(
        endpointURL,
        {
            workflow_id: input.workflowId,
            document_id: input.documentId,
            content: input.content
        },
        {
            headers: {
                authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '')?.tokens?.access_token ?? ''}`
                // 'x-hasura-organization-id': pullOrganizationIdFromAuthContext()
            }
        }
    );
    return response.data;
};

export const stopWorkflow = async (input: { workflowId: string; workflowRunIds: number[] }) => {
    const baseURL = process.env.REACT_APP_SNIKPIC_BASE_URL;
    const endpointURL = `${baseURL}/workflow/stop_workflow`;
    const response = await axios.post(
        endpointURL,
        {
            workflow_id: input.workflowId,
            workflow_run_ids: input.workflowRunIds
        },
        {
            headers: {
                authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '')?.tokens?.access_token ?? ''}`
            }
        }
    );
    return response.data;
};

interface UpdateDocumentVersionInput {
    documentVersionId: string;
    content: any;
    organizationId: string;
}

export const updateDocumentVersion = async (input: UpdateDocumentVersionInput) => {
    const baseURL = process.env.REACT_APP_SNIKPIC_BASE_URL;
    const endpointURL = `${baseURL}/workflow/document/update_document_version`;
    try {
        const response = await axios.post(
            endpointURL,
            {
                id: input.documentVersionId,
                content: input.content
            },
            {
                headers: {
                    authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '')?.tokens?.access_token ?? ''}`,
                    'x-hasura-organization-id': input.organizationId
                }
            }
        );
        console.log(response);
    } catch (e) {
        console.log(JSON.stringify(e));
    }
};

interface InsertSavedDocumentPartInput {
    name: string;
    content: any;
    organizationId: string;
}

export const insertSavedSection = async (input: InsertSavedDocumentPartInput) => {
    const baseURL = process.env.REACT_APP_SNIKPIC_BASE_URL;
    const endpointURL = `${baseURL}/workflow/document/insert_saved_section`;
    try {
        const response = await axios.post(
            endpointURL,
            {
                type: 'SECTION',
                ...input
            },
            {
                headers: {
                    authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '')?.tokens?.access_token ?? ''}`,
                    'x-hasura-organization-id': input.organizationId
                }
            }
        );
        console.log(response);
    } catch (e) {
        console.log(JSON.stringify(e));
    }
};

export const insertSavedPage = async (input: InsertSavedDocumentPartInput) => {
    const baseURL = process.env.REACT_APP_SNIKPIC_BASE_URL;
    const endpointURL = `${baseURL}/workflow/document/insert_saved_page`;
    try {
        const response = await axios.post(
            endpointURL,
            {
                type: 'PAGE',
                ...input
            },
            {
                headers: {
                    authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '')?.tokens?.access_token ?? ''}`,
                    'x-hasura-organization-id': input.organizationId
                }
            }
        );
        console.log(response);
    } catch (e) {
        console.log(JSON.stringify(e));
    }
};

interface publishWorkflowInput {
    workflowVersionId: string;
    organizationId: string;
    workflowRunsToCancel: number[];
}

export const publishWorkflow = async (input: publishWorkflowInput) => {
    const baseURL = process.env.REACT_APP_SNIKPIC_BASE_URL;
    const endpointURL = `${baseURL}/workflow-versions/publish_workflow`;

    const response = await axios.post(
        endpointURL,
        {
            workflow_version_id: input.workflowVersionId,
            worklfow_runs_to_cancel: input.workflowRunsToCancel
        },
        {
            headers: {
                authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '')?.tokens?.access_token ?? ''}`,
                'x-hasura-organization-id': input.organizationId
            }
        }
    );
};

interface VerifyWorkflowInput {
    workflowVersionId: string;
    organizationId: string;
}

export const verifyWorkflow = async (input: VerifyWorkflowInput) => {
    const baseURL = process.env.REACT_APP_SNIKPIC_BASE_URL;
    const endpointURL = `${baseURL}/workflow-versions/verify_reactflow_json`;

    const response = await axios.post(
        endpointURL,
        {
            workflow_version_id: input.workflowVersionId
        },
        {
            headers: {
                authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '')?.tokens?.access_token ?? ''}`,
                'x-hasura-organization-id': input.organizationId
            }
        }
    );
};

export type GetTaskType = 'workflow' | 'standalone';
export type GetTaskStatus = 'ACTIVE' | 'Completed';

export interface GetTaskFilters {
    userIds: string[];
    clientOrganizationIds: string[];
    type: GetTaskType[];
    status: GetTaskStatus[];
}

export interface SortBy {
    field: string;
    order: 'asc' | 'desc';
}

export const getStandaloneTasks = async (
    organizationId: string,
    filters: GetTaskFilters,
    sort: SortBy = {
        field: 'due_date',
        order: 'asc'
    }
) =>
    post(
        '/workflow/task/get_tasks',
        {
            status: filters.status ?? [],
            type: filters.type ?? [],
            user_ids: filters.userIds,
            client_organization_ids: filters.clientOrganizationIds ?? [],
            task_title: '',
            order_by: sort.field,
            order_direction: sort.order,
            page: 1,
            per_page: Number.MAX_SAFE_INTEGER
        },
        {
            headers: {
                // TODO: Replace this with token from auth service
                authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '')?.tokens?.access_token ?? ''}`,
                'x-hasura-organization-id': organizationId
            }
        }
    );

export const createStandaloneTask = async (input: CreateStandaloneTaskInput) =>
    post(
        '/workflow/task/create_standalone_task',
        {
            organization_id: input.organizationId,
            client_organization_id: input.clientOrganizationId,
            project_id: input.projectId,
            user_id: input.userId,
            title: input.title,
            description: input.description,
            attachments: input.attachments,
            due_date: input.dueDate.toISOString()
        },
        {
            headers: {
                // TODO: Replace this with token from auth service
                authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '')?.tokens?.access_token ?? ''}`,
                'x-hasura-organization-id': input.organizationId
            }
        }
    );

export const deleteStandaloneTask = async (id: number, organizationId: string) =>
    post(
        '/workflow/task/delete_standalone_task',
        { id },
        {
            headers: {
                authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '')?.tokens?.access_token ?? ''}`,
                'x-hasura-organization-id': organizationId
            }
        }
    );

export const updateStandaloneTask = async (input: UpdateStandaloneTaskInput) =>
    post(
        '/workflow/task/update_standalone_task',
        {
            id: input.id,
            status: input.status,
            type: input.type,
            organization_id: input.organizationId,
            client_organization_id: input.clientOrganizationId,
            project_id: input.projectId,
            user_id: input.userId,
            title: input.title,
            description: input.description,
            attachments: input.attachments,
            due_date: input.dueDate.toISOString()
        },
        {
            headers: {
                // TODO: Replace this with token from auth service
                authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '')?.tokens?.access_token ?? ''}`,
                'x-hasura-organization-id': input.organizationId
            }
        }
    );
