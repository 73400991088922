// eslint-disable-next-line import/no-named-as-default
import Playground from 'views/pages/playground';
import React, { useState, useEffect } from 'react';
import Settings from 'views/pages/settings';

const PlaygroundRoutes = {
    children: [
        {
            path: '/dev/playground',
            element: <Playground />
        }
    ]
};

export default PlaygroundRoutes;
