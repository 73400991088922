import { Grid, Box, Typography, Divider, CardMedia, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import logoFacebookSnickpik from 'assets/images/logoasdasd.png';
import Facebook from 'assets/images/Facebook.svg';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { MetaDataProps } from 'types/facebook';
import React from 'react';

interface StepOneProps {
    setStep: any;
    step: number;
    setUserData: any;
    urlMetaData: MetaDataProps;
    context: any;
    setContext: any;
}

export default function StepOne({ setStep, step, setUserData, urlMetaData, context, setContext }: StepOneProps) {
    const theme = useTheme();
    const responseFacebook = (response: any) => {
        if (response.accessToken && response.userID) {
            setUserData({
                accessToken: response.accessToken,
                userID: response.userID
            });
            setStep(step + 1);
        }
    };
    const [permissionsLoading, setPermissionsLoading] = React.useState(true);
    const [permissions, setPermissions] = React.useState<any[]>([]);
    const permissionButtons: any = {
        ad: ['ad', 'pixel'],
        domain: ['domain'],
        instagram: ['instagram'],
        lead: ['lead'],
        page: ['page', 'catalog']
    };
    React.useEffect(() => {
        const arrButtons = new Set([...permissionButtons.page]);
        Object.keys(urlMetaData.scopes).forEach((scope) => {
            permissionButtons[scope]?.forEach((permission: any) => {
                arrButtons.add(permission);
            });
        });
        setPermissions(Array.from(arrButtons));
        setPermissionsLoading(false);
        if (urlMetaData.done) {
            setStep(4);
        }
    }, [urlMetaData]);
    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ height: '100vh', position: 'relative' }}>
            <Grid item>
                <Box sx={{ width: '114px', m: '0 auto' }}>
                    <CardMedia sx={{ objectFit: 'contain' }} component="img" width="18px" image={logoFacebookSnickpik} alt="alt image" />
                </Box>
                {step === 1 ? (
                    <Box>
                        <Typography
                            mt={2}
                            sx={{
                                fontWeight: 600,
                                fontFamily: 'Inter',
                                color: theme.palette.grey[400],
                                fontSize: '24px',
                                textAlign: 'center'
                            }}
                        >
                            <FormattedMessage id="lets_get_you_started" />
                        </Typography>
                        <Typography
                            mt={3}
                            mb={3}
                            sx={{
                                fontWeight: 500,
                                fontFamily: 'Inter',
                                color: theme.palette.grey[600],
                                fontSize: '14px',
                                textAlign: 'center'
                            }}
                        >
                            <FormattedMessage id="confirm_assets_to_grant_access_to" />
                        </Typography>
                    </Box>
                ) : (
                    <Box>
                        <Typography
                            mt={2}
                            sx={{
                                fontWeight: 600,
                                fontFamily: 'Inter',
                                color: theme.palette.grey[400],
                                fontSize: '24px',
                                textAlign: 'center'
                            }}
                        >
                            <FormattedMessage id="congratulations" />
                        </Typography>
                        <Typography
                            mt={3}
                            sx={{
                                fontWeight: 500,
                                fontFamily: 'Inter',
                                color: theme.palette.grey[600],
                                fontSize: '14px',
                                textAlign: 'center'
                            }}
                        >
                            <FormattedMessage id="client_facebook_already_connected" />
                            {context.agency_business?.name}.
                        </Typography>
                        <Typography
                            mb={3}
                            sx={{
                                fontWeight: 500,
                                fontFamily: 'Inter',
                                color: theme.palette.grey[600],
                                fontSize: '14px',
                                textAlign: 'center'
                            }}
                        >
                            <FormattedMessage id="can_close_window" />
                        </Typography>
                    </Box>
                )}

                <Grid container spacing={1} sx={{ maxWidth: '400px', justifyContent: 'center' }}>
                    {Array.from(permissions).map((button: string) => (
                        <Grid key={`permission_${button}`} item>
                            <Button
                                sx={{
                                    background: theme.palette.grey[700],
                                    borderRadius: '8px',
                                    p: '8px 16px',
                                    fontSize: '12px',
                                    lineHeight: '15px',
                                    fontFamily: 'Inter',
                                    color: theme.palette.grey[400],
                                    fontWeight: 500
                                }}
                            >
                                <FormattedMessage id={`permission_${button}`} />
                            </Button>
                        </Grid>
                    ))}
                </Grid>
                {step === 1 ? (
                    <Box>
                        <Divider sx={{ maxWidth: '350px', m: '30px auto' }} />
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            {!permissionsLoading ? (
                                <FacebookLogin
                                    appId="595760385272978"
                                    scope={urlMetaData.aggregated}
                                    onSuccess={responseFacebook}
                                    onFail={console.log}
                                    onProfileSuccess={console.log}
                                    initParams={{
                                        version: 'v14.0'
                                    }}
                                    render={(renderProps) => (
                                        <Button
                                            onClick={renderProps.onClick}
                                            sx={{
                                                background: theme.palette.success[500],
                                                borderRadius: '10px',
                                                p: '12px 24px',
                                                fontSize: '12px',
                                                fontFamily: 'Inter',
                                                color: theme.palette.grey[400],
                                                fontWeight: 500,
                                                display: 'flex',
                                                '&:hover': {
                                                    background: theme.palette.success[500]
                                                }
                                            }}
                                        >
                                            <Box mr={1} sx={{ width: '24px' }}>
                                                <CardMedia
                                                    sx={{ objectFit: 'contain' }}
                                                    component="img"
                                                    width="18px"
                                                    image={Facebook}
                                                    alt="alt image"
                                                />
                                            </Box>
                                            <Typography
                                                sx={{
                                                    fontWeight: 500,
                                                    fontFamily: 'Inter',
                                                    color: theme.palette.background.paper,
                                                    fontSize: '16px',
                                                    lineHeight: '16px'
                                                }}
                                            >
                                                <FormattedMessage id="connect_facebook" />
                                            </Typography>
                                        </Button>
                                    )}
                                />
                            ) : null}
                        </Box>
                    </Box>
                ) : null}
            </Grid>
            <Box sx={{ position: 'absolute', bottom: '20px', width: '100%' }}>
                <Typography
                    sx={{
                        fontWeight: 500,
                        fontFamily: 'Inter',
                        color: theme.palette.grey[600],
                        fontSize: '14px',
                        textAlign: { xs: 'center', sm: 'right' },
                        mr: { xs: '0', sm: '20px' }
                    }}
                >
                    © 2022 Snikpic SRL - VAT BE0729.842.450
                </Typography>
            </Box>
        </Grid>
    );
}
