import { Box, Typography, Grid, Avatar } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import { AutoCompleteClients } from 'views/pages/clients/components/autocomplete-clients';
import { get } from 'lodash';

interface LabelType {
    title: string;
    src?: string;
}
interface SelectorAutocompliteProps {
    autocompleteItems: LabelType[];
    pendingValue: LabelType[];
    setPendingValue: any;
    arrItemSelected: any[];
    setArrItemsSelected: any;
    title?: string;
    imagePath?: string;
    labelPath?: string;
    singleSelect?: boolean;
}

const SelectorAutocomplite = ({
    autocompleteItems,
    pendingValue,
    setPendingValue,
    arrItemSelected,
    setArrItemsSelected,
    title,
    imagePath = 'members[0]?.user.image_url',
    labelPath = 'members[0]?.user.first_name',
    singleSelect = false
}: SelectorAutocompliteProps) => {
    console.log('In SelectorAutocomplite');
    console.log('autocompleteItems: ', autocompleteItems);

    const theme = useTheme();

    return (
        <Box>
            <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600, fontSize: '14px' }}>
                <FormattedMessage id={title} />
            </Typography>
            <Grid container>
                <Grid item mt={2}>
                    <AutoCompleteClients
                        labels={autocompleteItems}
                        pendingValue={pendingValue}
                        setPendingValue={setPendingValue}
                        clients={arrItemSelected}
                        setClients={setArrItemsSelected}
                        placeholder="start_typing_client"
                        imagePath={imagePath}
                        labelPath={labelPath}
                        singleSelect
                    />
                </Grid>
                {arrItemSelected &&
                    arrItemSelected.map((client: any, index) => (
                        <Grid
                            mt={2}
                            item
                            key={index}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                ml: '10px',
                                pl: '10px',
                                borderLeft: `1px solid ${theme.palette.grey[500]}`
                            }}
                        >
                            <Box sx={{ width: '32px' }}>
                                <Avatar sx={{ width: '32px', height: '32px' }} alt="Remy Sharp" src={get(client, imagePath)} />
                            </Box>
                            <Typography
                                ml={1}
                                sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600, fontSize: '14px' }}
                            >
                                {get(client, labelPath)}
                            </Typography>
                        </Grid>
                    ))}
            </Grid>
        </Box>
    );
};

export default SelectorAutocomplite;
