import { gql } from '@apollo/client';

const GET_REPORT = gql`
    mutation getClientReport($client_organization_id: String!, $metrics: String, $report_type: String, $since: String, $until: String) {
        get_client_report(
            arg1: {
                client_organization_id: $client_organization_id
                metrics: $metrics
                report_type: $report_type
                since: $since
                until: $until
            }
        ) {
            aggregated_data {
                description
                metric
                title
                previous_period {
                    since
                    until
                    value
                }
                selected_period {
                    since
                    until
                    value
                }
            }
            type
            social_media
            metric
        }
    }
`;

const DELETE_SHARED_REPORT_LINK = gql`
    mutation deleteSharedReportLink($id: uuid!) {
        delete_client_report_set_by_pk(id: $id) {
            id
        }
    }
`;

const ADD_SHARED_REPORT_LINK = gql`
    mutation addSharedReportLink($client_organization_id: uuid, $expires_at: timestamptz) {
        insert_client_report_set_one(object: { client_organization_id: $client_organization_id, expires_at: $expires_at }) {
            id
            expires_at
            created_at
        }
    }
`;

const UPDATE_SHARED_REPORT_EMAIL_RECIPIENTS = gql`
    mutation updateSharedReportEmailRecipient($id: uuid!, $email_recipients: jsonb) {
        update_client_report_set_by_pk(pk_columns: { id: $id }, _set: { email_recipients: $email_recipients }) {
            id
            email_recipients
        }
    }
`;

// eslint-disable-next-line import/prefer-default-export
export { GET_REPORT, DELETE_SHARED_REPORT_LINK, ADD_SHARED_REPORT_LINK, UPDATE_SHARED_REPORT_EMAIL_RECIPIENTS };
