import { Box, Grid } from '@mui/material';
import DeliverableSectionContent from './SectionContent';
import React from 'react';
import { SavingStatusProps } from './Chips/SavingStatusChip';
import { DocumentInstanceDTO, WorkflowSectionCommentStatusEnumDTO } from 'features/workflow-task-execution/types';

interface PageContentProps {
    page: DocumentInstanceDTO['pages'][number];
    updateDocumentInstance: () => void;
    handleCommentUpdate: (
        id: string,
        pageIndex: number,
        sectionIndex: number,
        status: WorkflowSectionCommentStatusEnumDTO,
        content: string
    ) => Promise<void>;
    setSavingStatus: (string: SavingStatusProps['status']) => void;
    handleFieldValueUpdate: (sectionIndex: number, fieldIndex: number, body: any) => void;
}

export default function PageContent({
    page,
    updateDocumentInstance,
    handleCommentUpdate,
    setSavingStatus,
    handleFieldValueUpdate
}: PageContentProps) {
    return (
        <Box>
            <Grid container spacing={10}>
                {page.sections.map((section, sectionIndex) => (
                    <React.Fragment key={`${section.page_index}-${section.section_index}`}>
                        <Grid item xs={12}>
                            <DeliverableSectionContent
                                pageId={page.page_id}
                                pageName={page.name}
                                section={section}
                                updateDocumentInstance={updateDocumentInstance}
                                handleCommentUpdate={handleCommentUpdate}
                                setSavingStatus={setSavingStatus}
                                sectionIndex={sectionIndex}
                                handleFieldValueUpdate={handleFieldValueUpdate}
                            />
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
        </Box>
    );
}
