import { useState } from 'react';
import { Box, Button, Popover, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const ErrorsBlock = () => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [errors, setErrors] = useState<any>([
        { id: 1, description: 'Block is missing end events', nodeId: 'Task 1' },
        { id: 2, description: 'Block is missing end events', nodeId: 'Task 1' },
        { id: 3, description: 'Block is missing end events', nodeId: 'Task 1' }
    ]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const deleteError = (id: number) => {
        setErrors(errors.filter((error: any) => error.id !== id));
    };
    return (
        <Box>
            <Button
                aria-describedby={id}
                onClick={handleClick}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: '6px 12px',
                    borderTop: `1px solid ${theme.palette.grey[800]}`,
                    borderLeft: `1px solid ${theme.palette.grey[800]}`,
                    boxShadow: '0px 32px 64px -12px rgba(30, 60, 120, 0.14)',
                    background: theme.palette.grey[700]
                }}
            >
                <ReportProblemOutlinedIcon sx={{ fill: theme.palette.secondary[600] }} />
                <Typography
                    sx={{
                        color: theme.palette.grey[400],
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        fontSize: '12px',
                        ml: 1,
                        textTransform: 'uppercase'
                    }}
                >
                    <FormattedMessage id="issues" />
                </Typography>
                <Typography
                    sx={{
                        color: theme.palette.grey[400],
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        fontSize: '12px',
                        ml: 1,
                        letterSpacing: '0.12em',
                        borderRadius: '50px',
                        p: '2px 5px',
                        background: theme.palette.grey[500]
                    }}
                >
                    {errors.length}
                </Typography>
                <KeyboardArrowUpOutlinedIcon sx={{ ml: 1, fontSize: '20px' }} />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                sx={{
                    top: '-50px',
                    right: '55px',
                    '.MuiPaper-root': {
                        boxShadow: 'none',
                        border: `1px solid ${theme.palette.grey[800]}`,
                        borderRadius: '0px',
                        width: '230px',
                        height: '178px',
                        overflow: 'auto',
                        background: theme.palette.grey[700],
                        p: '12px'
                    }
                }}
            >
                {errors.map((error: any, index: number) => (
                    <Box key={index} sx={{ display: 'flex', mb: index + 1 !== errors.length ? 1 : 0 }}>
                        <CancelOutlinedIcon
                            onClick={() => deleteError(error.id)}
                            sx={{ fill: theme.palette.secondary[600], cursor: 'pointer' }}
                        />
                        <Box ml={1}>
                            <Typography
                                sx={{
                                    color: theme.palette.grey[400],
                                    fontFamily: 'Inter',
                                    fontWeight: 600,
                                    fontSize: '10px'
                                }}
                            >
                                <FormattedMessage id="end_event_is_required" />
                            </Typography>
                            <Typography
                                sx={{
                                    color: theme.palette.grey[400],
                                    fontFamily: 'Inter',
                                    fontWeight: 400,
                                    fontSize: '9px',
                                    fontStyle: 'italic'
                                }}
                            >
                                {error.description}
                            </Typography>
                            <Box sx={{ display: 'flex' }}>
                                <Typography
                                    sx={{
                                        color: theme.palette.grey[400],
                                        fontFamily: 'Inter',
                                        fontWeight: 600,
                                        fontSize: '10px'
                                    }}
                                >
                                    <FormattedMessage id="node_id" />:
                                </Typography>
                                <Typography
                                    sx={{
                                        color: theme.palette.grey[400],
                                        fontFamily: 'Inter',
                                        fontWeight: 400,
                                        fontSize: '9px',
                                        fontStyle: 'italic'
                                    }}
                                >
                                    {error.nodeId}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Popover>
        </Box>
    );
};

export default ErrorsBlock;
