import { gql } from '@apollo/client';

const GET_SHARED_REPORT_LINK = gql`
    query getSharedReportLinkById($id: uuid!) {
        client_report_set_by_pk(id: $id) {
            id
            expires_at
            client_organization_id
        }
    }
`;
const GET_SHARED_REPORT_LINKS = gql`
    query getSharedReportLinks($client_organization_id: uuid) {
        client_report_set(order_by: { created_at: desc }, where: { client_organization_id: { _eq: $client_organization_id } }) {
            id
            expires_at
            created_at
        }
    }
`;

const GET_AGENCY_LOGO_FROM_SHARED_REPORT_ID = gql`
    query MyQuery($id: uuid = "") {
        client_report_set_by_pk(id: $id) {
            client_organization {
                agency {
                    logo_url
                }
            }
        }
    }
`;

// eslint-disable-next-line import/prefer-default-export
export { GET_SHARED_REPORT_LINK, GET_SHARED_REPORT_LINKS, GET_AGENCY_LOGO_FROM_SHARED_REPORT_ID };
