import { useState, useEffect } from 'react';
import { useChatContext } from 'stream-chat-react';
import type { StreamChatType } from '../../types';
import { useTheme } from '@mui/material/styles';
import { Button, Avatar, CircularProgress, Alert } from '@mui/material';
import { getWhatsappBridgeDetails } from '../../../../../services/rest/message_forward';
import { fetchWhatsappMembers, removeMemberFromConversation } from '../../../../../services/rest/twilio';
import { useWorkspaceController } from '../../context/WorkspaceController';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

// Component - List of users that can be removed from the channel
const DeleteMemeberListContainer = (props: { children: React.ReactNode }) => {
    const { children } = props;
    return <div>{children}</div>;
};

// Component - Display row of user that can be deleted from the channel
const UserItemForRemoval = ({ index, user, channelSid }: any) => {
    const theme = useTheme();
    const { closeAdminPanel } = useWorkspaceController();

    const title = user.firstName || user.id;

    return (
        <label htmlFor={user.sid} title={title} className="user-list__row">
            <div className="user-list__column-block">
                <div className="user-list__column--user-data">
                    <Avatar
                        alt="Remy Sharp"
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjFpa5H6Klp8xdAucZC8PqjFuhXlkxQBcRNw&usqp=CAU"
                    />
                    <p className="user-item__name">{user.messagingBinding.address}</p>
                </div>
            </div>
            <div className="user-list__column--checkbox">
                <Button
                    onClick={() => {
                        removeMemberFromConversation({ channelSid, participantSid: user.sid });
                        closeAdminPanel();
                    }}
                >
                    <DeleteOutlineIcon style={{ color: theme.palette.grey['200'] }} />
                </Button>
            </div>
        </label>
    );
};

// Main component - Display tables to add and remove users
// -----------------------------------------------------------------------------------------------

export const WhatsappPanelUsers = () => {
    // Chat context
    const { channel } = useChatContext<StreamChatType>();
    const channelId = channel?.id || '';

    // Local state
    const [isLoading, setIsloading] = useState(true);
    const [twilioChannelId, setTwilioChannelId] = useState('');
    const [whatsappMembers, setWhatsappMembers] = useState([]);

    // Initialize
    const initialize = async () => {
        const response = await getWhatsappBridgeDetails({ streamChannelId: channelId });
        if (response.whatsappBridge?.twilioChannels?.length) {
            const twilioChannelId = response.whatsappBridge.twilioChannels[0];
            setTwilioChannelId(twilioChannelId);
            const { participants } = await fetchWhatsappMembers({ twilioChannelId });
            setWhatsappMembers(participants);
        }
        setIsloading(false);
    };

    // useEffects
    useEffect(() => {
        initialize();
    }, []);

    // Final render
    // ............................................................................................
    if (isLoading) {
        return <CircularProgress />;
    }

    if (whatsappMembers?.length === 0) {
        return <Alert severity="info">The cannel is currently not bridged with any Whatsapp number.</Alert>;
    }

    return (
        <>
            <DeleteMemeberListContainer>
                {whatsappMembers?.length &&
                    whatsappMembers.map((user: any, i) => (
                        <UserItemForRemoval index={i} key={user.sid} user={user} channelSid={twilioChannelId} />
                    ))}
            </DeleteMemeberListContainer>
        </>
    );
};
