import { TaskQuery } from 'api/graphql/generated';
import { createContext, FC, useContext, useEffect, useState } from 'react';

interface Field {
    name: string;
    id: string;
    visible: boolean;
    can_view: boolean;
    can_edit: boolean;
    required_field: boolean;
}

interface FieldContextProps {
    document?: TaskQuery['document'][number];
    updateFields: (newFields: Field[]) => void;
    fields: Field[];
}

const FieldsContext = createContext({} as FieldContextProps);

export const FieldProvider: FC<{ fetchedDocument?: TaskQuery['document'][number] }> = ({ children, fetchedDocument }) => {
    const [document, setDocument] = useState(fetchedDocument);
    const [fields, setFields] = useState<Field[]>([]);

    useEffect(() => {
        if (fetchedDocument) setDocument(fetchedDocument);
    }, [fetchedDocument]);

    useEffect(() => {
        // console.log('THE MODIFIED FIELDS ARE', fields);
    }, [fields]);

    return (
        <FieldsContext.Provider
            value={{
                document,
                fields,
                updateFields: (newFields: Field[]) => {
                    // console.log({ newFields }, { fields });
                    const updatedFields = newFields.reduce((prev, curr) => {
                        const field = prev.find((el) => el.id === curr.id);
                        if (!field) return [...prev, curr];

                        return prev.map((el) => (el.id === field.id ? field : el));
                    }, fields);

                    setFields([...updatedFields]);
                }
            }}
        >
            {children}
        </FieldsContext.Provider>
    );
};

export const useFields = () => useContext(FieldsContext);
