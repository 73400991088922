import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Button, CardMedia, Typography } from '@mui/material';
import ReactFlow, {
    useNodesState,
    ReactFlowProvider,
    useEdgesState,
    BackgroundVariant,
    Background,
    useReactFlow
} from 'react-flow-renderer';
import { useTheme } from '@mui/material/styles';
import { ColumnHeader, NextButton, RowBody } from '../document-builder/ui';
import NODE_TYPES from './types/nodes';
import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import DocumentBuilderContext from '../document-builder/context/workflow.context';
import CustomProvider from 'providers';
import SideBarWorkflow from './sideBar';
import CustomEdge from './diagram/layout/CustomEdge';
import createNodes from './diagram/hooks/create-node';
import ph_magnifying_glass_plus from 'assets/images/ph_magnifying_glass_plus.svg';
import ph_magnifying_glass_minus from 'assets/images/ph_magnifying_glass_minus.svg';
import CreateEdges from './diagram/hooks/create-edges';
import { useMutation } from '@apollo/client';
import { UPDATE_REACTFLOW } from 'services/graphQL/mutations/workflow';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { ReactComponent as LeftArrowIcon } from 'assets/images/arrow-left.svg';
import { AutomationNodeHelper } from 'features/workflow-automation/utils/automation-node.util';
import { TaskNodeHelper } from 'features/workflow/workflow-builder/nodes/Task/TaskNodeHelper';
import { ApprovalTaskNodeHelper } from 'features/workflow/workflow-builder/nodes/ApprovalTask/ApprovalTaskNodeHelper';
import InformationToolTip from 'ui-component/InformationTooltip';

interface WorkflowBuilderContentProps {
    onBack?: () => void;
    onNext: () => void;
}

const edgeTypes = {
    buttonEdge: CustomEdge
};

const getId = () => `node_${Date.now()}`;

const WorkflowNew = ({ onNext, onBack }: WorkflowBuilderContentProps) => {
    const theme = useTheme();
    const { documentBuilder, setDocumentBuilder } = useContext(DocumentBuilderContext);
    const reactFlowWrapper = useRef<HTMLInputElement>(null);
    const [nodes, setNodes, onNodesChange] = useNodesState(documentBuilder.workflow.flowNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(documentBuilder.workflow.flowEdges);
    const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);
    const intl = useIntl();

    const isWorkflowConfigured = nodes.every((node: any) => {
        if (node.type === 'task' || node.type === 'approval_task') {
            if (node.data?.is_valid) return true;
            return false;
        }
        return true;
    });

    useEffect(() => {
        setDocumentBuilder({
            ...documentBuilder,
            workflow: {
                ...documentBuilder.workflow,
                flowEdges: edges,
                flowNodes: nodes,
                setNodes,
                setEdges
            },
            workflowDurationInDays: calculateWorkflowDurationInDays(nodes)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodes, edges]);

    useEffect(() => {}, [documentBuilder.workflow]);

    const calculateWorkflowDurationInDays = (nodes: any) => {
        let duration = 0;

        nodes.forEach((node: any) => {
            if (node.type === 'task') {
                duration += node?.data?.duration?.value || 0;
            }
        });

        return duration;
    };

    const [updateReactflow] = useMutation(UPDATE_REACTFLOW, {
        onCompleted: (data) => {
            // eslint-disable-next-line no-console
            console.log({ data });
        },

        onError: (error) => {
            // eslint-disable-next-line no-console
            console.log({ error });
        }
    });

    const onConnect = CreateEdges({ setEdges, getId, edges });

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const onDrop = useCallback(
        (event) => {
            event.preventDefault();

            const reactFlowBounds = reactFlowWrapper.current!.getBoundingClientRect();
            const type = event.dataTransfer.getData('application/reactflow');

            // check if the dropped element is valid
            if (typeof type === 'undefined' || !type) {
                return;
            }

            createNodes({
                event,
                reactFlowBounds,
                type,
                getId,
                reactFlowInstance,
                setNodes,
                setEdges
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [reactFlowInstance]
    );

    const ZoomButtons = () => {
        const { zoomIn, zoomOut } = useReactFlow();
        return (
            <Box sx={{ display: 'flex' }}>
                <Button onClick={() => zoomIn({ duration: 400 })} sx={{ minWidth: '10px' }}>
                    <Box sx={{ width: '24px' }}>
                        <CardMedia component="img" image={ph_magnifying_glass_plus} alt="" />
                    </Box>
                </Button>
                <Button onClick={() => zoomOut({ duration: 400 })} sx={{ minWidth: '10px' }}>
                    <Box sx={{ width: '24px' }}>
                        <CardMedia component="img" image={ph_magnifying_glass_minus} alt="" />
                    </Box>
                </Button>
            </Box>
        );
    };

    const processWorkflow = async (workflow: any) => {
        const dbInput: any[] = [];
        const edgeIndex: any = {};
        const nodeIndex: any = {};
        workflow.flowEdges.forEach((edge: any) => {
            edgeIndex[edge.id] = edge;
        });
        workflow.flowNodes.forEach((node: any) => {
            nodeIndex[node.id] = node;
        });

        const edges = workflow.flowEdges
            .filter((edge: any) => nodeIndex[edge.source].type !== 'trigger')
            .map((edge: any) => ({
                type: edge?.type === 'approval_task_outgoing_connector' ? 'approval_task_outgoing_connector' : 'connector',
                properties: {
                    id: edge.id,
                    source_id: edge.source,
                    target_id: edge.target,
                    source_position: {
                        x: nodeIndex[edge.source].position.x,
                        y: nodeIndex[edge.source].position.y
                    },
                    target_position: {
                        x: nodeIndex[edge.target].position.x,
                        y: nodeIndex[edge.target].position.y
                    }
                }
            }));

        workflow.flowNodes.forEach((node: any) => {
            if (node.type === 'trigger') {
                return;
            }

            let input: any = {
                type: node.type,
                properties: {
                    id: node.id,
                    name: node.data.label,
                    position: {
                        x: node.position.x,
                        y: node.position.y
                    }
                }
            };

            if (node.type === 'task') {
                input = TaskNodeHelper.convertToWorkflowNode(node);
            } else if (node.type === 'approval_task') {
                input = ApprovalTaskNodeHelper.convertToWorkflowNode(node);
            } else if (node.type === 'automation') {
                // not yet supported;
            } else if (node.type === 'schedule') {
                input.type = 'scheduled_trigger';
                input.properties = {
                    ...input.properties,
                    trigger_date: node.data.trigger_date ?? new Date(),
                    recurring_interval: node.data.recurring_interval ?? { unit: 'days', value: 1 }
                };
            } else if (node.type === 'webhook') {
                input.type = 'manual_trigger';
            } else if (node.type === 'automation_task') {
                input = AutomationNodeHelper.convertToWorkflowNode(node);
            }

            dbInput.push(input);
        });
        dbInput.push(...edges);
        await updateReactflow({
            variables: {
                id: documentBuilder.workflowVersionId,
                reactflow_json: dbInput
            }
        });
    };

    const handleOnNext = async () => {
        try {
            await processWorkflow(documentBuilder.workflow);
            onNext();
        } catch (error: any) {
            console.log(error);
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({ id: 'unable_to_save_changes' }),
                    variant: 'error'
                })
            );
        }
    };

    return (
        <CustomProvider>
            <Box sx={{ borderTop: '1px solid #D4DBEA', height: 'calc(100vh - 144px)' }}>
                <Box sx={{ borderBottom: '1px solid #E0E0E0' }}>
                    <ColumnHeader sx={{ justifyContent: 'space-between', gap: '12px' }}>
                        <RowBody sx={{ gap: '8px', cursor: 'pointer' }} onClick={onBack}>
                            <LeftArrowIcon />
                            <Typography variant="subtitle1" sx={{ color: theme.palette.text.primary }}>
                                Back
                            </Typography>
                        </RowBody>
                        {/* <FormattedMessage id="change_saved" /> */}
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {!isWorkflowConfigured && (
                                <InformationToolTip message="make_sure_that_all_the_nodes_are_configured_before_proceeding" />
                            )}
                            <NextButton disabled={!isWorkflowConfigured} onClick={handleOnNext}>
                                <FormattedMessage id="next" />
                            </NextButton>
                        </Box>
                    </ColumnHeader>
                </Box>

                <Box sx={{ display: 'flex', flexGrow: 1, height: '100%' }}>
                    <ReactFlowProvider>
                        <Box sx={{ flexGrow: 1, height: '100%', position: 'relative' }} ref={reactFlowWrapper}>
                            <ReactFlow
                                edgeTypes={edgeTypes}
                                nodes={nodes}
                                edges={edges}
                                nodeTypes={NODE_TYPES}
                                onNodesChange={onNodesChange}
                                onEdgesChange={onEdgesChange}
                                onConnect={onConnect}
                                onInit={setReactFlowInstance}
                                onDrop={onDrop}
                                onDragOver={onDragOver}
                                fitView
                                snapToGrid
                            >
                                <Background variant={BackgroundVariant.Lines} />
                                {/* <Controls /> */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        position: 'absolute',
                                        bottom: '47px',
                                        left: '26px',
                                        p: '9px 16px',
                                        borderRadius: '100px',
                                        background: '#fff',
                                        border: `1px solid ${theme.palette.dark[700]}`,
                                        zIndex: 9999
                                    }}
                                >
                                    <ZoomButtons />
                                </Box>
                            </ReactFlow>
                        </Box>
                        <SideBarWorkflow />
                    </ReactFlowProvider>
                </Box>
            </Box>
        </CustomProvider>
    );
};

export default WorkflowNew;
