import { Typography, Box, Grid, TextField, CardMedia, Popover, Button, Snackbar, ButtonBase, Dialog, Tooltip } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import logos_facebook from 'assets/images/logos_facebook.svg';
import userCheck from 'assets/images/userCheck.svg';
import copy from 'assets/images/copyWhite.svg';
import refresh from 'assets/images/refresh.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ButtonCustom from 'ui-component/extended/Button';
import CheckIcon from '@mui/icons-material/Check';
import Slide, { SlideProps } from '@mui/material/Slide';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_CLIENT_FACEBOOK_RESOURCES, GET_FACEBOOK_CONNECTION, GET_URL } from 'services/graphQL/queries/fbIntegration';
import { ClientFBResource, MetaDataProps } from 'types/facebook';
import { ClientDetailsIntegrationsItem } from './client-integration-item';
import { FacebookIntegrationDrawer } from './fb-integration-drawer';
import { RESET_FACEBOOK_CONNECTION } from 'services/graphQL/mutations/fbIntegration';
import DialogConfirm from 'ui-component/dialogConfirm';
import { Link } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

type TransitionProps = Omit<SlideProps, 'direction'>;

function TransitionDown(props: TransitionProps) {
    return <Slide {...props} direction="down" />;
}

export const ClientDetailsIntegrations = ({ clientId }: any) => {
    const theme = useTheme();
    const { member } = useAuth();

    const [transition, setTransition] = React.useState<React.ComponentType<TransitionProps> | undefined>(undefined);
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [metaData, setMetaData] = React.useState<MetaDataProps>({
        aggregated: '',
        scopes: {}
    });
    const [resources = [], setResources] = React.useState<ClientFBResource[]>();
    const [openDivider, setOpenDivider] = React.useState(false);
    const [copyUrl, setCopyUrl] = React.useState({
        value: '',
        copied: false
    });
    const [isFacebookBMConnected, setIsFacebookBMConnected] = React.useState(false);
    const handleClickCloseDivider = () => {
        setOpenDivider(false);
    };
    const handleClickOpenDivider = () => {
        setOpenDivider(true);
        handleClosePopover();
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const handleClickPopover = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClosePopover = () => {
        setAnchorEl(null);
    };
    const formik = useFormik({
        initialValues: {
            url: `${window.location.origin}/facebookconnection/${clientId}`
        },
        validationSchema: Yup.object({
            url: Yup.string().trim().required('Required')
        }),
        onSubmit: (values) => {
            console.log(values);
        }
    });
    const onCopy = () => {
        setCopyUrl({
            ...copyUrl,
            copied: true
        });
        setTimeout(() => {
            setCopyUrl({
                ...copyUrl,
                copied: false
            });
        }, 1000);
    };
    const [showDialog, setShowDialog] = React.useState(false);
    const openDialog = () => setShowDialog(true);
    const closeDialog = () => setShowDialog(false);
    const handleClick = (Transition: React.ComponentType<TransitionProps>) => () => {
        setTransition(() => Transition);
        setOpenSnackbar(true);
    };
    const handleClose = () => {
        setOpenSnackbar(false);
    };
    const [getUrl] = useLazyQuery(GET_URL, {
        onCompleted: (data) => {
            setMetaData(data.get_url_metadata);
        }
    });

    const [resetFacebookClientConnection] = useMutation(RESET_FACEBOOK_CONNECTION, {
        onCompleted: (data) => {
            initialize();
            closeDialog();
        },
        fetchPolicy: 'network-only'
    });

    const performReset = () => {
        resetFacebookClientConnection({
            variables: {
                client_organization_id: clientId
            }
        });
    };

    const initialize = () => {
        fetchFbConnection({
            variables: {
                organization_id: member?.organizationId
            }
        });

        getUrl({
            variables: {
                client_organization_id: clientId
            },
            fetchPolicy: 'network-only'
        });

        getResources({
            variables: {
                client_organization_id: clientId
            },
            fetchPolicy: 'network-only'
        });
    };

    React.useEffect(() => {
        initialize();
    }, []);

    const [getResources] = useLazyQuery(GET_CLIENT_FACEBOOK_RESOURCES, {
        onCompleted: (clientResources) => {
            console.log(clientResources);
            setResources(clientResources.client_fb_resources);
        }
    });

    const [fetchFbConnection] = useLazyQuery(GET_FACEBOOK_CONNECTION, {
        onCompleted: (data) => {
            if (data.organization_fb_connection.length > 0) {
                setIsFacebookBMConnected(true);
            }
        },
        fetchPolicy: 'network-only'
    });

    return (
        <Box
            sx={{
                border: `1px solid ${theme.palette.grey[500]}`,
                boxShadow: '0px 10px 16px rgba(0, 0, 0, 0.05)',
                borderRadius: '8px',
                p: '24px',
                mt: 3
            }}
        >
            {!isFacebookBMConnected ? (
                <>
                    <FormattedMessage id="connect_your_facebook_manager_in_the" />{' '}
                    <Link to="/settings">
                        <FormattedMessage id="settings" />
                    </Link>
                </>
            ) : (
                <>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={6}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ width: '32px' }}>
                                    <CardMedia
                                        sx={{ objectFit: 'contain' }}
                                        component="img"
                                        width="18px"
                                        image={logos_facebook}
                                        alt="alt image"
                                    />
                                </Box>
                                <Typography
                                    ml={1}
                                    sx={{
                                        textTransform: 'capitalize',
                                        color: theme.palette.grey[400],
                                        fontFamily: 'Inter',
                                        fontWeight: 600,
                                        fontSize: '14px'
                                    }}
                                >
                                    Facebook
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {!metaData.done ? (
                                    <Box sx={{ background: theme.palette.primary[500], p: '4px 12px', borderRadius: '16px' }}>
                                        <Typography
                                            sx={{
                                                textTransform: 'capitalize',
                                                color: theme.palette.secondary[400],
                                                fontFamily: 'Inter',
                                                fontWeight: 500,
                                                fontSize: '12px'
                                            }}
                                        >
                                            <FormattedMessage id="pending" />
                                        </Typography>
                                    </Box>
                                ) : null}
                                <Typography
                                    onClick={handleClickPopover}
                                    sx={{
                                        color: theme.palette.grey[400],
                                        fontFamily: 'Inter',
                                        fontWeight: 600,
                                        fontSize: '26px',
                                        m: '-11px 0 0 20px',
                                        cursor: 'pointer',
                                        zIndex: 111
                                    }}
                                >
                                    ...
                                </Typography>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClosePopover}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left'
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                    }}
                                >
                                    <Box sx={{ p: '12px' }}>
                                        <Button onClick={handleClickOpenDivider} className="textDecorationNone">
                                            <Box sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
                                                <Box sx={{ width: '18px' }}>
                                                    <CardMedia
                                                        sx={{ objectFit: 'contain' }}
                                                        component="img"
                                                        width="18px"
                                                        image={userCheck}
                                                        alt="alt image"
                                                    />
                                                </Box>
                                                <Typography
                                                    sx={{
                                                        color: theme.palette.grey[400],
                                                        fontFamily: 'Inter',
                                                        fontWeight: 500,
                                                        fontSize: '14px',
                                                        ml: '10px',
                                                        textDecoration: 'none'
                                                    }}
                                                >
                                                    <FormattedMessage id="manage" />
                                                </Typography>
                                            </Box>
                                        </Button>
                                    </Box>
                                </Popover>
                            </Box>
                        </Grid>
                    </Grid>
                    <Typography
                        mt={2}
                        mb={2}
                        sx={{
                            color: theme.palette.grey[400],
                            fontFamily: 'Inter',
                            fontWeight: 400,
                            fontSize: '14px',
                            textDecoration: 'none'
                        }}
                    >
                        <FormattedMessage id="subtitle_facebook" />
                    </Typography>
                    <Box sx={{ display: 'flex', width: { xs: '100%', sm: '40%', md: '30%' } }}>
                        <TextField
                            sx={{
                                '.MuiOutlinedInput-input': {
                                    color: theme.palette.grey[300],
                                    fontWeight: 400,
                                    fontFamily: 'Inter',
                                    fontSize: '14px',
                                    p: '11px'
                                }
                            }}
                            id="url"
                            name="url"
                            value={formik.values.url}
                            onChange={formik.handleChange}
                            variant="outlined"
                            fullWidth
                            error={formik.touched.url && Boolean(formik.errors.url)}
                            helperText={formik.touched.url && formik.errors.url}
                        />

                        <Box ml={2} sx={{ width: '42px' }}>
                            <CopyToClipboard onCopy={onCopy} text={formik.values.url}>
                                <ButtonCustom
                                    onClick={handleClick(TransitionDown)}
                                    colorBtn="red"
                                    titleBtn={
                                        <Box sx={{ width: '18px' }}>
                                            <CardMedia
                                                sx={{ objectFit: 'contain' }}
                                                component="img"
                                                width="18px"
                                                image={copy}
                                                alt="alt image"
                                            />
                                        </Box>
                                    }
                                    sx={{ p: '4px 12px' }}
                                />
                            </CopyToClipboard>
                        </Box>
                        {metaData.done ? (
                            <Box ml={1} sx={{ width: '42px' }}>
                                <Tooltip title="Reset Connection">
                                    <ButtonBase onClick={openDialog}>
                                        <CardMedia
                                            sx={{ objectFit: 'contain' }}
                                            component="img"
                                            width="18px"
                                            image={refresh}
                                            alt="alt image"
                                        />
                                    </ButtonBase>
                                </Tooltip>
                            </Box>
                        ) : null}
                    </Box>
                    <Typography
                        mt={3}
                        mb={3}
                        sx={{
                            color: theme.palette.grey[400],
                            fontFamily: 'Inter',
                            fontWeight: 600,
                            fontSize: '14px',
                            textDecoration: 'none'
                        }}
                    >
                        <FormattedMessage id="request_access" />
                    </Typography>
                    <Grid container spacing={3}>
                        {resources.map((resource) => (
                            <Grid key={resource.resource_type} item xs={12} sm={6}>
                                <ClientDetailsIntegrationsItem resource={resource} />
                            </Grid>
                        ))}
                    </Grid>
                </>
            )}
            <Snackbar
                sx={{
                    '.MuiPaper-root': {
                        background: theme.palette.primary[100],
                        minWidth: '220px',
                        boxShadow: 'none',
                        borderRadius: '8px',
                        p: '16px 24px'
                    }
                }}
                open={openSnackbar}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                TransitionComponent={transition}
                autoHideDuration={2000}
                message={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <CheckIcon sx={{ fill: theme.palette.primary[600], mr: '10px' }} />
                        <Typography sx={{ fontFamily: 'Inter', fontWeight: 500, fontSize: '14px', color: theme.palette.primary[600] }}>
                            <FormattedMessage id="copied_to_clipboard" />
                        </Typography>
                    </Box>
                }
                key={transition ? transition.name : ''}
            />
            <FacebookIntegrationDrawer
                openDivider={openDivider}
                handleClickCloseDivider={handleClickCloseDivider}
                metaData={metaData}
                clientId={clientId}
            />
            <Dialog onClose={closeDialog} open={showDialog}>
                <DialogConfirm onConfirm={performReset} onClose={closeDialog} />
            </Dialog>
        </Box>
    );
};
