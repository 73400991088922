import { Box, Dialog, Modal } from '@mui/material';
import { useEffect, useState } from 'react';
import DialogConfirm from 'ui-component/dialogConfirm';
import { PopOverSelector } from '../clients/components/PopOverSelector';
import SelectTextFields from './dropdown';

import DesignerSVG from 'assets/images/designer.svg';
import ApprovalLogics from '../clients/features/detail/workflow/features/workflow-builder/nodes/z_DEPRECATED_approval-node/dialog/components/approval-logic';
import {
    ConditionBuilderContext,
    useConditionBuilderContext
} from '../clients/features/detail/workflow/features/workflow-builder/nodes/task-nodes/components/LogicBuilder/context/ConditionBuilderContext';
import { LogicBuilder } from '../clients/features/detail/workflow/features/workflow-builder/nodes/task-nodes/components/LogicBuilder';
import DialogConfirmCheckbox from 'features/workflow-task-execution/components/CancelWorkflowRunsDialog';

const items = [
    { id: '1', name: 'Anouar' },
    { id: '2', name: 'Elm' }
];

interface LabelType {
    id: string;
    name: string;
    icon?: string;
}

// eslint-disable-next-line arrow-body-style
const Playground = () => {
    return <></>;
};

export default Playground;
