import React from 'react';
import { Typography, Box, TextField, CardMedia, Grid, Select, MenuItem } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import { Button } from 'ui-component/extended/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clouds from 'assets/images/clouds.svg';
import { useMutation } from '@apollo/client';
import { ADD_MEMBER } from 'services/graphQL/mutations';
import { MEMBER_ROLES } from 'utils/constants';
// import { useDispatch } from 'store';
// import { openSnackbar } from 'store/slices/snackbar';
import { useSnackbar } from 'utils/snackbar.utils';
import { upsertUserAndAddToOrg } from '../../../services/rest/organization';

const DialogInviteTeamMember = ({ onClose, fetchTeam }: any) => {
    // Imports
    const theme = useTheme();
    const intl = useIntl();
    const [inviteSuccessful, setInviteSuccessful] = React.useState(false);
    const [addMember] = useMutation(ADD_MEMBER);
    const { showErrorSnackbar } = useSnackbar();

    // Form & error handling
    const submitAddMember = async (values: any) => {
        try {
            const user = JSON.parse(localStorage.getItem('user') || '');
            console.log('user: ', user);
            const organizationId = JSON.parse(localStorage.getItem('user') || '')?.member?.organizationId ?? '';
            console.log('organizationId: ', organizationId);
            const input = {
                ...values,
                // organization_id: localStorage.getItem('organization_id')
                organization_id: organizationId
            };
            // await addMember({
            //     variables: input
            // });
            await upsertUserAndAddToOrg(input);
            setInviteSuccessful(true);
            fetchTeam();
        } catch (e: any) {
            console.error(e);
            showErrorSnackbar({ message: e.message || 'Unknown error' });
        }
    };
    const formik = useFormik({
        initialValues: {
            email: '',
            role: '',
            title: '',
            first_name: '',
            last_name: ''
        },
        validationSchema: Yup.object({
            first_name: Yup.string().required('Required'),
            last_name: Yup.string().required('Required'),
            email: Yup.string().email('Invalid email').required('Required'),
            role: Yup.string().required('Required'),
            title: Yup.string()
        }),
        onSubmit: submitAddMember
    });

    // Return
    return (
        <Box sx={{ p: '5px 20px', borderRadius: '16px', minWidth: { xs: '350px', sm: '562px' } }}>
            {!inviteSuccessful ? (
                <Box>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={10}>
                            <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600, fontSize: 16, my: 2 }}>
                                <FormattedMessage id="invite_team_member" />
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {' '}
                                <CloseIcon onClick={onClose} sx={{ fontSize: '20px', fill: '#9197AE', cursor: 'pointer' }} />
                            </Box>
                        </Grid>
                    </Grid>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Typography sx={{ mb: '4px', fontWeight: 500, color: theme.palette.grey[900] }}>
                                    <FormattedMessage id="first_name" />
                                </Typography>
                                <TextField
                                    sx={{
                                        '.MuiOutlinedInput-input': {
                                            fontWeight: 400,
                                            fontFamily: 'Inter',
                                            fontSize: '14px'
                                        }
                                    }}
                                    id="first_name"
                                    name="first_name"
                                    value={formik.values.first_name}
                                    onChange={formik.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    placeholder={intl.formatMessage({ id: 'invite_team_member_first_name_placeholder' })}
                                    error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                    helperText={formik.touched.first_name && formik.errors.first_name}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography sx={{ mb: '4px', fontWeight: 500, color: theme.palette.grey[900] }}>
                                    <FormattedMessage id="last_name" />
                                </Typography>
                                <TextField
                                    sx={{
                                        '.MuiOutlinedInput-input': {
                                            fontWeight: 400,
                                            fontFamily: 'Inter',
                                            fontSize: '14px'
                                        }
                                    }}
                                    id="last_name"
                                    name="last_name"
                                    value={formik.values.last_name}
                                    onChange={formik.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    placeholder={intl.formatMessage({ id: 'invite_team_member_last_name_placeholder' })}
                                    error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                    helperText={formik.touched.last_name && formik.errors.last_name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{ mb: '4px', fontWeight: 500, color: theme.palette.grey[900] }}>
                                    <FormattedMessage id="email" />
                                </Typography>
                                <TextField
                                    sx={{
                                        '.MuiOutlinedInput-input': {
                                            fontWeight: 400,
                                            fontFamily: 'Inter',
                                            fontSize: '14px'
                                        }
                                    }}
                                    id="email"
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    placeholder={intl.formatMessage({ id: 'invite_team_member_email_placeholder' })}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography sx={{ mb: '4px', fontWeight: 500, color: theme.palette.grey[900] }}>
                                    <FormattedMessage id="role" />
                                </Typography>
                                <Select
                                    id="role"
                                    name="role"
                                    fullWidth
                                    displayEmpty
                                    value={formik.values.role}
                                    onChange={formik.handleChange}
                                    placeholder={intl.formatMessage({ id: 'invite_team_member_role_placeholder' })}
                                    renderValue={(selected) => (
                                        <Typography sx={{ color: theme.palette.grey[300] }}>{selected || 'Select the role'}</Typography>
                                    )}
                                >
                                    {MEMBER_ROLES.map((role) => (
                                        <MenuItem
                                            key={role.id}
                                            value={role.id}
                                            sx={{
                                                borderBottom: `1px solid ${theme.palette.grey[500]}`,
                                                p: '14px 16px'
                                            }}
                                        >
                                            {role.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography sx={{ mb: '4px', fontWeight: 500, color: theme.palette.grey[900] }}>
                                    <FormattedMessage id="title" />
                                </Typography>
                                <TextField
                                    sx={{
                                        '.MuiOutlinedInput-input': {
                                            fontWeight: 400,
                                            fontFamily: 'Inter',
                                            fontSize: '14px'
                                        }
                                    }}
                                    id="title"
                                    name="title"
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    placeholder={intl.formatMessage({ id: 'invite_team_member_title_placeholder' })}
                                    error={formik.touched.title && Boolean(formik.errors.title)}
                                    helperText={formik.touched.title && formik.errors.title}
                                />
                            </Grid>
                        </Grid>
                        <Box mt={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Box sx={{ width: '125px' }}>
                                {/* <Button onClick={formik.handleSubmit} title={<FormattedMessage id="send_nvite" />} loading={loading} /> */}
                                <Button onClick={formik.handleSubmit} title={<FormattedMessage id="send_nvite" />} />
                            </Box>
                        </Box>
                    </form>
                </Box>
            ) : (
                <Box>
                    <Box sx={{ width: '230px', m: '30px auto 0' }}>
                        <CardMedia sx={{ objectFit: 'contain' }} component="img" image={clouds} alt="alt image" />
                    </Box>
                    <Typography
                        mt={3}
                        mb={3}
                        sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500, fontSize: '16px', textAlign: 'center' }}
                    >
                        <FormattedMessage id="invite_sent_successfully" />
                    </Typography>
                    <Box sx={{ width: '77px', m: '0 auto' }}>
                        <Button onClick={onClose} title={<FormattedMessage id="got_it" />} />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default DialogInviteTeamMember;
