import { Typography, Box, Popover, Button, List, ListItemButton, styled, Divider, Chip, DividerProps, Link, Dialog } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import ActionItem from 'ui-component/workspace/ActionItem';
import { WorkSpace } from 'types';
import { IconCheck, IconLogout } from '@tabler/icons';
import { useDispatch, useSelector } from 'store';
import useAuth from 'hooks/useAuth';
import { openSnackbar } from 'store/slices/snackbar';
import { useLazyQuery } from '@apollo/client';
import { GET_USER_ORGANIZATIONS } from 'services/graphQL';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import logoIcon from 'assets/images/logo.svg';
import { useNavigate } from 'react-router';

type Organization = {
    id: string;
    name: string;
    logo_url: string;
    type: string;
};

type WorkspaceSelectorProps = {
    userName: string;
    currentWs: string;
    list: WorkSpace[];
    onChangeWs: Function;
    dividerOpen?: boolean;
};

const WorkspaceDivider = styled(Divider)<DividerProps>((_) => ({
    height: 'auto',
    '& .MuiDivider-wrapper': {
        padding: 0
    },
    '&:after': {
        width: 0
    }
}));
const WorkspaceSelector = ({ userName, currentWs, list, onChangeWs, dividerOpen }: WorkspaceSelectorProps) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { logout, changeOrganization, member, user } = useAuth();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const openPopover = (event: any) => setAnchorEl(event.currentTarget);
    const closePopover = () => setAnchorEl(null);
    const userId = JSON.parse(`${localStorage.getItem('user')}`);

    const [availableOrganizations, setAvailableOrganizations] = React.useState<Organization[]>([]);

    const [selectedOrganization, setSelectedOrganization] = React.useState<Organization | null>(null);
    const handleSelectOrganization = async (organization: Organization) => {
        await changeOrganization(organization.id);
        navigate(0);
    };

    useEffect(() => {
        if (!availableOrganizations) return;
        const organization = availableOrganizations.find((item) => item.id === member?.organizationId);
        if (!organization) return;
        setSelectedOrganization(organization);
    }, [availableOrganizations, member]);

    const [getUser] = useLazyQuery(GET_USER_ORGANIZATIONS, {
        onCompleted: (res) => {
            setAvailableOrganizations(
                res.organizations.map((item: any) => ({
                    id: item.id,
                    name: item.name,
                    logo_url: item.logo_url,
                    type: item.type
                }))
            );
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first'
    });

    React.useEffect(() => {
        getUser({
            variables: {
                user_id: userId.user.id
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const currentWorkSpace = list.find((item) => item.name === currentWs) ?? list[0];

    const handleLogout = async () => {
        try {
            await logout();
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e.message,
                    variant: 'error'
                })
            );
        } finally {
            closePopover();
        }
    };

    const { drawerOpen } = useSelector((state) => state.menu);

    return (
        <Box sx={{ display: 'flex' }} width="100%">
            <Box width="100%">
                <Button onClick={openPopover} sx={{ padding: 0, width: '100%', textAlign: 'left', cursor: 'default' }}>
                    <Box width="100%" alignItems="center" sx={{ justifyContent: 'left', display: { xs: 'none', md: 'flex' } }}>
                        <ActionItem
                            title={drawerOpen ? selectedOrganization?.name : undefined}
                            subTitle={drawerOpen ? selectedOrganization?.type : undefined}
                            icon={selectedOrganization?.logo_url || logoIcon}
                        />

                        <ExpandMoreIcon sx={{ ml: 1, fill: theme.palette.grey[300] }} />

                        {/* {drawerOpen && <IconChevronDown size={20} strokeWidth={1.5} color={theme.palette.grey[300]} />} */}
                    </Box>
                    <Box
                        width="100%"
                        alignItems="center"
                        sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: dividerOpen ? 'center' : '' }}
                    >
                        <ActionItem
                            title={selectedOrganization?.name}
                            subTitle={selectedOrganization?.type}
                            icon={selectedOrganization?.logo_url || logoIcon}
                            dividerOpen={dividerOpen}
                        />
                        {/* {drawerOpen && <IconChevronDown size={20} strokeWidth={1.5} color={theme.palette.grey[300]} />} */}
                    </Box>
                </Button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={closePopover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <Box sx={{ px: 3, py: 2, width: 320, position: 'relative' }}>
                        <Box sx={{ mb: 1 }}>
                            <Typography variant="h5">{`${user.first_name} ${user.last_name}`}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center', justifyContent: 'space-between', py: 2 }}>
                            <ActionItem title={selectedOrganization?.name} subTitle="" icon={selectedOrganization?.logo_url || logoIcon} />
                            <IconCheck size={20} strokeWidth={2} color={theme.palette.grey[300]} />
                        </Box>

                        <WorkspaceDivider textAlign="right">
                            <Chip
                                label={currentWorkSpace.type}
                                sx={{
                                    backgroundColor: theme.palette.secondary[100],
                                    color: theme.palette.secondary[500],
                                    fontWeight: 600,
                                    textTransform: 'uppercase',
                                    lineHeight: '100%',
                                    height: 'auto',
                                    py: '4px',
                                    fontSize: 8,
                                    letterSpacing: '0.12em'
                                }}
                                size="small"
                            />
                        </WorkspaceDivider>

                        <List>
                            {availableOrganizations
                                .filter((org) => org.id !== selectedOrganization?.id)
                                .map((organization, idx) => (
                                    <ListItemButton
                                        onClick={() => handleSelectOrganization(organization)}
                                        key={idx}
                                        disableGutters
                                        sx={{
                                            '&:hover': {
                                                backgroundColor: 'white'
                                            }
                                        }}
                                    >
                                        <ActionItem
                                            title={organization.name}
                                            subTitle=""
                                            icon={organization.logo_url || logoIcon}
                                            badge={false}
                                        />
                                    </ListItemButton>
                                ))}
                        </List>

                        <WorkspaceDivider textAlign="right" />
                        <Link
                            sx={{ display: 'flex', alignItems: 'center', gap: 1, cursor: 'pointer', mt: 2 }}
                            underline="none"
                            onClick={handleLogout}
                        >
                            <IconLogout size={20} strokeWidth={2} color="#454B89" />
                            <Typography variant="body2" sx={{ color: '#454B89' }}>
                                <FormattedMessage id="log_out" />
                            </Typography>
                        </Link>
                    </Box>
                </Popover>
            </Box>
        </Box>
    );
};

export default WorkspaceSelector;
