import { Checkbox, Typography, TextField, InputAdornment, Box, FormLabel, Tooltip, IconButton } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { FC, useContext, useEffect, useState } from 'react';
import { Handle, NodeProps, Position } from 'react-flow-renderer';
import { useTheme } from '@mui/material/styles';

import { ColumnBodyOne, FlowNode, RowBody } from 'views/pages/clients/features/detail/workflow/features/document-builder/ui';

import { ReactComponent as CalendarIcon } from 'assets/images/calendar.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/images/chevron-down.svg';
import DocumentBuilderContext from '../../../document-builder/context/workflow.context';
import { scheduleTOJson } from './scheduleHelpers';
import _, { isFinite } from 'lodash';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const ScheduleNode: FC<NodeProps> = ({ id }) => {
    const theme = useTheme();
    const { documentBuilder } = useContext(DocumentBuilderContext);
    const node = _.find(documentBuilder?.workflow?.flowNodes, { id });
    const nodeFrequency = node?.data?.recurring_interval?.value || 0;
    const nodeDate = node?.data?.trigger_date || new Date();

    const [newDate, setNewDate] = useState<Date | null>(nodeDate);
    const [checkValue, setCheckValue] = useState<boolean>(nodeFrequency > 0);
    const [source, setSource] = useState(1);
    const [frequency, setFrequency] = useState<number | null>(nodeFrequency);

    const updateFrequencyInJson = (frequency: number) => {
        scheduleTOJson({
            type: 'recurring',
            data: documentBuilder.workflow,
            id,
            newValue: frequency
        });
    };

    const handleFrequencyChange = (event: any) => {
        if (event.target.value === '') {
            setFrequency(null);
            return;
        }
        if (!isFinite(parseInt(event.target.value, 10))) return;
        const eventValue = parseInt(event.target.value, 10);
        const newFrequency = eventValue < 0 ? 0 : eventValue;
        setFrequency(newFrequency);
        updateFrequencyInJson(newFrequency);
    };

    const handleFrequencyBlur = () => {
        if (frequency === null) {
            setFrequency(0);
            updateFrequencyInJson(0);
        }
    };

    const handleDateChange = (newValue: Date | null) => {
        setNewDate(newValue);
        scheduleTOJson({
            type: 'date',
            data: documentBuilder.workflow,
            id,
            newValue
        });
    };

    const handleCheck = () => {
        if (checkValue) {
            setFrequency(0);
            updateFrequencyInJson(0);
        }
        setCheckValue(!checkValue);
    };

    useEffect(() => (documentBuilder.workflow.flowEdges.length > 1 ? setSource(0) : setSource(1)), [documentBuilder]);

    return (
        <FlowNode>
            <ColumnBodyOne>
                <Typography sx={{ fontWeight: 500, fontSize: '14px', color: 'black' }}>Scheduling for workflow</Typography>
                <FormLabel>Workflow trigger date</FormLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        renderInput={(props) => (
                            <TextField
                                {...props}
                                sx={{
                                    '& .MuiInputBase-input': { padding: '8px 0' },
                                    width: '260px',
                                    height: '36px',
                                    '.MuiOutlinedInput-root': { background: theme.palette.dark[100] },
                                    '.MuiOutlinedInput-input': { background: theme.palette.dark[100] },
                                    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                        borderColor: theme.palette.dark[100]
                                    }
                                }}
                            />
                        )}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CalendarIcon />
                                </InputAdornment>
                            )
                        }}
                        label=""
                        value={newDate}
                        onChange={handleDateChange}
                        components={{
                            OpenPickerIcon: ChevronDownIcon
                        }}
                    />
                </LocalizationProvider>
            </ColumnBodyOne>
            <RowBody sx={{ gap: '8px' }}>
                <Checkbox checked={checkValue} sx={{ width: '16px', height: '16px' }} onChange={handleCheck} />
                <Typography sx={{ fontFamily: 'Inter', fontSize: '12px', fontWeight: 600, color: theme.palette.grey[300] }}>
                    This is a recurring event
                </Typography>
            </RowBody>
            {checkValue && (
                <Box>
                    <Box sx={{ display: 'flex' }}>
                        <TextField
                            id="outlined-number"
                            InputLabelProps={{
                                shrink: true
                            }}
                            label="Frequency in days"
                            sx={{
                                textAlign: 'center'
                            }}
                            value={frequency}
                            onChange={handleFrequencyChange}
                            onBlur={handleFrequencyBlur}
                        />
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Tooltip title="If you select 4 days, a workflow run will start on the workflow trigger date and every 4 days afterwards">
                                <IconButton>
                                    <HelpOutlineIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>
            )}
            <Handle
                type="source"
                position={Position.Bottom}
                isConnectable
                style={{ width: '9px', height: '9px', opacity: 1, background: '#98A2B3' }}
            />
        </FlowNode>
    );
};

export default ScheduleNode;
