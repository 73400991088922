import { Box, Typography, CardMedia } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import img1 from 'assets/images/img1.png';
import img2 from 'assets/images/img2.png';
import img3 from 'assets/images/img3.png';
import img4 from 'assets/images/img4.png';
import img5 from 'assets/images/img5.png';
import img6 from 'assets/images/img6.png';
import img7 from 'assets/images/img7.png';
import img8 from 'assets/images/img8.png';
import img9 from 'assets/images/img9.png';
import img10 from 'assets/images/img10.png';
import img11 from 'assets/images/img11.png';
import img12 from 'assets/images/img12.png';
import img13 from 'assets/images/img13.png';
import img14 from 'assets/images/img14.png';
import img15 from 'assets/images/img15.png';

export default function MissingInstagramHelp() {
    const theme = useTheme();
    return (
        <Box>
            <Typography
                sx={{
                    fontWeight: 600,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '18px'
                }}
            >
                How Do I Link Instagram With Facebook From My Facebook Page?
            </Typography>
            <Typography
                mt={3}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px',
                    lineHeight: '18px'
                }}
            >
                Connecting your accounts is quick and straightforward if you know how to do it. If you don’t, it can cause some confusion
                and frustration. There are two ways to connect Instagram to your Facebook page from Facebook. You can do it directly through
                your page from Facebook Business Suite or Facebook Business Manager.
            </Typography>
            <Typography
                mt={6}
                sx={{
                    fontWeight: 600,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '18px'
                }}
            >
                Option 1 - Linking Instagram directly from your Facebook page
            </Typography>
            <Typography
                mt={5}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px',
                    lineHeight: '18px'
                }}
            >
                1. Open{' '}
                <a target="_blank" href="https://www.facebook.com/" rel="noreferrer">
                    {' '}
                    Facebook
                </a>{' '}
                and login to your account
            </Typography>
            <Typography
                mt={5}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px',
                    lineHeight: '18px'
                }}
            >
                2. Open you page, and on the left-hand side, where it says ‘Manage Page’, click ‘settings’
            </Typography>
            <Box mt={3}>
                <CardMedia sx={{ objectFit: 'contain' }} component="img" image={img1} alt="AI tools" />
            </Box>
            <Typography
                mt={5}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px',
                    lineHeight: '18px'
                }}
            >
                3. In the bottom left, you will see a tab where it says ‘Instagram’ —&gt; click
            </Typography>
            <Box mt={3}>
                <CardMedia sx={{ objectFit: 'contain' }} component="img" image={img2} alt="AI tools" />
            </Box>
            <Typography
                mt={5}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px',
                    lineHeight: '18px'
                }}
            >
                4. You will see a window that says ‘Connect to Instagram’
            </Typography>
            <Box mt={3}>
                <CardMedia sx={{ objectFit: 'contain' }} component="img" image={img3} alt="AI tools" />
            </Box>
            <Typography
                mt={5}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px',
                    lineHeight: '18px'
                }}
            >
                5. Click on the blue box that says ‘Connect Account’
            </Typography>
            <Typography
                mt={5}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px',
                    lineHeight: '18px'
                }}
            >
                6. This will take you to a new window where you need to login to Instagram
            </Typography>
            <Box mt={3} sx={{ maxWidth: '400px' }}>
                <CardMedia sx={{ objectFit: 'contain' }} component="img" image={img4} alt="AI tools" />
            </Box>
            <Typography
                mt={5}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px',
                    lineHeight: '18px'
                }}
            >
                7. Once your login credentials are accepted, you will be redirected back to Facebook
            </Typography>
            <Typography
                mt={5}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px',
                    lineHeight: '18px'
                }}
            >
                8. Voila! Your Instagram and Facebook business pages are connected
            </Typography>
            <Typography
                mt={5}
                sx={{
                    fontWeight: 600,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '18px'
                }}
            >
                Option 2 - Linking Instagram through Facebook Business Suite or Business Manager
            </Typography>
            <Typography
                mt={5}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px',
                    lineHeight: '18px'
                }}
            >
                The other option to connect the accounts is through Facebook Business Suite or Facebook Business Manager. Whether it’s
                Business Suite or Business Manager depends which one is active for your Facebook account. You can check this easily by going
                to{' '}
                <a target="_blank" href="https://business.facebook.com/" rel="noreferrer">
                    business.facebook.com
                </a>{' '}
                . Which one loads?
            </Typography>
            <Box mt={3}>
                <CardMedia sx={{ objectFit: 'contain' }} component="img" image={img5} alt="AI tools" />
            </Box>
            <Typography
                mt={12}
                sx={{
                    fontWeight: 600,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '18px'
                }}
            >
                Connecting through Facebook Business Manager
            </Typography>
            <Typography
                mt={5}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px',
                    lineHeight: '18px'
                }}
            >
                If you are on Facebook Business Manager, the process to connect your Instagram is as follows:
            </Typography>
            <Typography
                mt={1}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px',
                    lineHeight: '18px'
                }}
            >
                1. Head to your{' '}
                <a target="_blank" href="https://business.facebook.com/settings/" rel="noreferrer">
                    Business Settings
                </a>{' '}
                in FB Business Manager
            </Typography>
            <Box mt={3}>
                <CardMedia sx={{ objectFit: 'contain' }} component="img" image={img6} alt="AI tools" />
            </Box>
            <Typography
                mt={5}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px',
                    lineHeight: '18px'
                }}
            >
                2. Go to Instagram Accounts on the left (under Accounts)
            </Typography>
            <Box mt={3} sx={{ maxWidth: '400px' }}>
                <CardMedia sx={{ objectFit: 'contain' }} component="img" image={img7} alt="AI tools" />
            </Box>
            <Typography
                mt={5}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px',
                    lineHeight: '18px'
                }}
            >
                3. Click on “Add” and login to your Instagram account
            </Typography>
            <Box mt={3} sx={{ maxWidth: '400px' }}>
                <CardMedia sx={{ objectFit: 'contain' }} component="img" image={img8} alt="AI tools" />
            </Box>
            <Typography
                mt={5}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px',
                    lineHeight: '18px'
                }}
            >
                4. Once you&apos;ve logged in, you&apos;re done. However, one bonus tip. If you want to run ads on Instagram, you should
                also connect your Ad account to this Instagram account.
            </Typography>
            <Typography
                mt={5}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px',
                    lineHeight: '18px'
                }}
            >
                5. On the same screen, click on “Connected Assets”
            </Typography>
            <Box mt={3}>
                <CardMedia sx={{ objectFit: 'contain' }} component="img" image={img9} alt="AI tools" />
            </Box>
            <Typography
                mt={5}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px',
                    lineHeight: '18px'
                }}
            >
                6. On the screen, click on “Add Assets”
            </Typography>
            <Box mt={3}>
                <CardMedia sx={{ objectFit: 'contain' }} component="img" image={img10} alt="AI tools" />
            </Box>
            <Typography
                mt={5}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px',
                    lineHeight: '18px'
                }}
            >
                7. Select your Ad Account(s) you want to connect to Instagram and click “Add”. That’s done!
            </Typography>
            <Box mt={3}>
                <CardMedia sx={{ objectFit: 'contain' }} component="img" image={img11} alt="AI tools" />
            </Box>
            <Typography
                mt={5}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px',
                    lineHeight: '18px'
                }}
            >
                That’s done! 🥳
            </Typography>
            <Typography
                mt={12}
                sx={{
                    fontWeight: 600,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '18px'
                }}
            >
                Connecting through Facebook Business Suite
            </Typography>
            <Typography
                mt={5}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px',
                    lineHeight: '18px'
                }}
            >
                To connect Instagram to Facebook via the Business Suite:
            </Typography>
            <Typography
                mt={5}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px',
                    lineHeight: '18px'
                }}
            >
                1. Start by logging into your{' '}
                <a target="_blank" href="https://business.facebook.com/" rel="noreferrer">
                    Facebook Business Suite
                </a>
            </Typography>
            <Typography
                mt={5}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px',
                    lineHeight: '18px'
                }}
            >
                2. Make sure the Business that is connected to your page is selected on the left
            </Typography>
            <Typography
                mt={5}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px',
                    lineHeight: '18px'
                }}
            >
                3. Click on ‘Settings’ in the bottom left-hand corner
            </Typography>
            <Box mt={3} sx={{ maxWidth: '300px' }}>
                <CardMedia sx={{ objectFit: 'contain' }} component="img" image={img12} alt="AI tools" />
            </Box>
            <Typography
                mt={5}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px',
                    lineHeight: '18px'
                }}
            >
                4. Then select ‘Business Assets’
            </Typography>
            <Box mt={3} sx={{ maxWidth: '500px' }}>
                <CardMedia sx={{ objectFit: 'contain' }} component="img" image={img13} alt="AI tools" />
            </Box>
            <Typography
                mt={5}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px',
                    lineHeight: '18px'
                }}
            >
                5. You will be given the option to ‘Add Assets’
            </Typography>
            <Box mt={3}>
                <CardMedia sx={{ objectFit: 'contain' }} component="img" image={img14} alt="AI tools" />
            </Box>
            <Typography
                mt={5}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px',
                    lineHeight: '18px'
                }}
            >
                6. Choose ‘Instagram Account’
            </Typography>
            <Box mt={3}>
                <CardMedia sx={{ objectFit: 'contain' }} component="img" image={img15} alt="AI tools" />
            </Box>
            <Typography
                mt={5}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px',
                    lineHeight: '18px'
                }}
            >
                7. You will be taken to the Instagram login page
            </Typography>
            <Typography
                mt={5}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px',
                    lineHeight: '18px'
                }}
            >
                8. Login
            </Typography>
            <Typography
                mt={5}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px',
                    lineHeight: '18px'
                }}
            >
                9. You will be redirected back to Facebook, where your Instagram account has been added. 🙌
            </Typography>
        </Box>
    );
}
