import { Channel, DateSeparator, DateSeparatorProps, SimpleReactionsList } from 'stream-chat-react';

import { AdminPanel } from '../AdminPanel/AdminPanel';
import { ChannelInner } from './ChannelInner';
import { EmptyChannel } from '../EmptyChannel/EmptyChannel';
import { TeamMessageInput } from '../TeamMessageInput/TeamMessageInput';
import { TeamTypingIndicator } from '../TeamTypingIndicator/TeamTypingIndicator';
import { ThreadHeader } from '../TeamChannelHeader/ThreadHeader';
import { TeamMessage } from '../TeamMessage/TeamMessage';
import { DateTime } from 'luxon';

import { GiphyInMessageFlagProvider } from '../../context/GiphyInMessageFlagContext';
import { useWorkspaceController } from '../../context/WorkspaceController';
import { UploadIcon } from './UploadIcon';

const LoadingIndicator = () => null;

export const YourCustomDateSeparator = (props: DateSeparatorProps) => {
    const { date } = props;

    function formatDate(d: Date) {
        const date = DateTime.fromJSDate(d);
        const now = DateTime.now();
        const diff = now.diff(date, ['days', 'hours', 'minutes']);

        if (diff.days > 7) {
            return date.toLocaleString(DateTime.DATE_MED);
        }
        if (diff.days > 1) {
            return `Last ${date.toFormat('cccc')}`;
        }
        if (diff.days === 1) {
            return 'Yesterday';
        }
        if (diff.hours > 0) {
            return `Today at ${date.toLocaleString(DateTime.TIME_24_SIMPLE)}`;
        }
        if (diff.minutes > 0) {
            // return `${diff.minutes} minutes ago`rounding up to the minute
            return `${Math.round(diff.minutes)} minutes ago`;
        }
        return 'Now';
    }

    return (
        <div className="date__separator">
            <DateSeparator formatDate={(d) => formatDate(d)} date={date} position="center" unread={false} />
        </div>
    );
};

export const ChannelContainer = () => {
    const { activeWorkspace } = useWorkspaceController();

    if (activeWorkspace.match('Admin')) {
        return <AdminPanel />;
    }

    return (
        <div className="channel__container">
            <Channel
                DateSeparator={YourCustomDateSeparator}
                EmptyStateIndicator={EmptyChannel}
                LoadingIndicator={LoadingIndicator}
                Input={TeamMessageInput}
                Message={TeamMessage}
                ReactionsList={SimpleReactionsList}
                ThreadHeader={ThreadHeader}
                TypingIndicator={TeamTypingIndicator}
                // FileUploadIcon={UploadIcon}
                // maxNumberOfFiles={10}
                // multipleUploads
            >
                <GiphyInMessageFlagProvider>
                    <ChannelInner />
                </GiphyInMessageFlagProvider>
            </Channel>
        </div>
    );
};
