import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { Box, useTheme } from '@mui/system';

export const GreenCheckMark = () => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                background: theme.palette.success[100],
                borderRadius: '100px',
                width: '16px',
                height: '16px'
            }}
        >
            <CheckOutlinedIcon sx={{ fill: '#fff', fontSize: '12px', ml: '2px' }} />
        </Box>
    );
};

export const RedCrossMark = () => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                background: theme.palette.error[100],
                borderRadius: '100px',
                width: '16px',
                height: '16px'
            }}
        >
            <CloseIcon sx={{ fill: '#fff', fontSize: '12px', ml: '2px' }} />
        </Box>
    );
};
