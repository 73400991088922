import { Box, Grid, CardMedia } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import calendar from 'assets/images/calendar.svg';
import { Button } from 'ui-component/extended/Button';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SharedLinkDialog from './SharedLinkDialog';
import { Text, LightText } from 'ui-component/typography';
import { DateTime } from 'luxon';

// NOTE : this part will be used when we add the metrics selector
//
// const itemsFacebook = ['page_likes', 'page_views', 'page_reach'];
// const itemsFacebookAdsMostCommon = [
//     'website_adds_to_cart',
//     'website_leads',
//     'website_purchases',
//     'purchase_roas',
//     'website_purchase_roas',
//     'spend'
// ];
// const itemsFacebookAdsPublication = ['CPM', 'cost_per1000people_reached', 'frequency', 'impressions', 'reach'];
// const itemsFacebookAdsPublication2 = ['clicks', 'CTR'];

interface AddLinkShareProps {
    handleCloseAddingLink: () => void;
    addLink: (expiresAt: string) => Promise<string>;
    handleShareLinkToEmail: (email_recipients: string[]) => Promise<void> | void;
}

const AddLinkShare = ({ handleCloseAddingLink, addLink, handleShareLinkToEmail }: AddLinkShareProps) => {
    const theme = useTheme();
    const [datePickerValue, setDatePickerValue] = React.useState<Date>(new Date());
    const [open, setOpen] = React.useState(false);

    const [newLinkId, setNewLinkId] = React.useState<string | null>(null);

    const [showShareLinkDialog, setShowSharedLinkDialog] = React.useState(false);
    const closeShareLinkDialog = () => setShowSharedLinkDialog(false);
    const openShareLinkDialog = () => setShowSharedLinkDialog(true);

    const handleChangeDatePicker = (value: any) => setDatePickerValue(value);
    const handleOpenDatePicker = () => setOpen(true);
    const handleCloseDatePicker = () => setOpen(false);

    const handleShare = async () => {
        const newLinkId = await addLink(DateTime.fromJSDate(datePickerValue).toISO());
        setNewLinkId(newLinkId);
        openShareLinkDialog();
    };

    React.useEffect(() => {
        console.log(datePickerValue);
    }, [datePickerValue]);

    return (
        <Grid
            container
            direction="column"
            justifyContent="space-between"
            sx={{ height: { xs: '100%', sm: '100vh' }, width: { xs: '100%', sm: '562px' } }}
        >
            <Grid item sm={11} p={3} sx={{ height: { xs: '100%', sm: 'calc(100vh - 100px)' }, overflowY: 'scroll' }}>
                <Text sx={{ fontWeight: 600 }}>
                    <FormattedMessage id="share_report_settings" />
                </Text>
                <Text sx={{ fontSize: 14, mt: 3, mb: 2 }}>
                    <FormattedMessage id="link_will_be_active_till" />
                </Text>

                <Box sx={{ display: 'flex', width: '100%' }}>
                    <Box sx={{ p: '0', width: '100%' }}>
                        <Box
                            sx={{
                                background: theme.palette.dark[100],
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                cursor: 'pointer',
                                borderRadius: '8px',
                                p: '8px'
                            }}
                            onClick={handleOpenDatePicker}
                        >
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ width: '20px' }}>
                                    <CardMedia
                                        sx={{ objectFit: 'contain' }}
                                        component="img"
                                        width="18px"
                                        image={calendar}
                                        alt="alt image"
                                    />
                                </Box>

                                {datePickerValue && (
                                    <LightText sx={{ ml: 1, fontSize: 14 }}>
                                        {DateTime.fromJSDate(datePickerValue).toFormat('dd LLL, yyyy')}
                                    </LightText>
                                )}
                            </Box>
                            <KeyboardArrowDownIcon sx={{ fontSize: '25px', fill: theme.palette.grey[400], ml: '8px' }} />
                        </Box>
                        <Box>
                            {' '}
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={datePickerValue}
                                    open={open}
                                    onClose={handleCloseDatePicker}
                                    onChange={handleChangeDatePicker}
                                    renderInput={({ inputRef }) => <Box ref={inputRef}> </Box>}
                                />
                            </LocalizationProvider>
                        </Box>
                    </Box>
                </Box>
                {/* <DarkText sx={{ mt: 3, fontSize: 12 }}>
                    <FormattedMessage id="desc_add_link_shared" />
                </DarkText>
                <Text sx={{ mt: 3, fontSize: 14 }}>Facebook</Text>
                <Box sx={{ p: '16px 24px', background: theme.palette.grey[700], mt: 3 }}>
                    <DarkText sx={{ fontSize: 10, fontWeight: 600, textTransform: 'uppercase' }}>
                        <FormattedMessage id="page" />
                    </DarkText>
                </Box>
                <Grid container>
                    {itemsFacebook.map((item: string, index: number) => (
                        <Grid key={index} item xs={12} sm={6}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItem: 'center',
                                    p: '16px 24px',
                                    borderBottom: index + 1 !== itemsFacebook.length ? `1px solid ${theme.palette.grey[800]}` : '',
                                    borderRight: (index + 1) % 2 !== 0 ? `1px solid ${theme.palette.grey[800]}` : ''
                                }}
                            >
                                <Text sx={{ fontSize: 14 }}>
                                    <FormattedMessage id={item} />
                                </Text>
                                <CheckCircleIcon sx={{ fill: theme.palette.orange.main, fontSize: '20px' }} />
                            </Box>
                        </Grid>
                    ))}
                </Grid>
                <Box sx={{ p: '16px 24px', background: theme.palette.grey[700], mt: 3 }}>
                    <DarkText sx={{ fontSize: 10, fontWeight: 600, textTransform: 'uppercase' }}>
                        <FormattedMessage id="publication" />
                    </DarkText>
                </Box>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItem: 'center',
                                p: '16px 24px',
                                borderRight: { xs: '', sm: `1px solid ${theme.palette.grey[800]}` }
                            }}
                        >
                            <Text sx={{ fontSize: 14 }}>
                                <FormattedMessage id="impressions" />
                            </Text>
                            <CheckCircleIcon sx={{ fill: theme.palette.orange.main, fontSize: '20px' }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {' '}
                    </Grid>
                </Grid>
                <Text sx={{ fontSize: 14, fontWeight: 500, mt: 3 }}>Facebook Ads</Text>
                <Box sx={{ p: '16px 24px', background: theme.palette.grey[700], mt: 3 }}>
                    <DarkText sx={{ fontSize: 10, fontWeight: 600, textTransform: 'uppercase' }}>
                        <FormattedMessage id="most_common" />
                    </DarkText>
                </Box>
                <Grid container>
                    {itemsFacebookAdsMostCommon.map((item: string, index: number) => (
                        <Grid key={index} item xs={12} sm={6}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItem: 'center',
                                    p: '16px 24px',
                                    borderBottom:
                                        index + 1 !== itemsFacebookAdsMostCommon.length ? `1px solid ${theme.palette.grey[800]}` : '',
                                    borderRight: (index + 1) % 2 !== 0 ? `1px solid ${theme.palette.grey[800]}` : ''
                                }}
                            >
                                <Text sx={{ fontSize: 14 }}>
                                    <FormattedMessage id={item} />
                                </Text>
                                <CheckCircleIcon sx={{ fill: theme.palette.orange.main, fontSize: '20px' }} />
                            </Box>
                        </Grid>
                    ))}
                </Grid>
                <Box sx={{ p: '16px 24px', background: theme.palette.grey[700], mt: 3 }}>
                    <DarkText sx={{ fontSize: 10, fontWeight: 600, textTransform: 'uppercase' }}>
                        <FormattedMessage id="publication" />
                    </DarkText>
                </Box>
                <Grid container>
                    {itemsFacebookAdsPublication.map((item: string, index: number) => (
                        <Grid key={index} item xs={12} sm={6}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItem: 'center',
                                    p: '16px 24px',
                                    borderBottom:
                                        index + 1 !== itemsFacebookAdsPublication.length ? `1px solid ${theme.palette.grey[800]}` : '',
                                    borderRight: (index + 1) % 2 !== 0 ? `1px solid ${theme.palette.grey[800]}` : ''
                                }}
                            >
                                <Text sx={{ fontSize: 14 }}>
                                    <FormattedMessage id={item} />
                                </Text>
                                <CheckCircleIcon sx={{ fill: theme.palette.orange.main, fontSize: '20px' }} />
                            </Box>
                        </Grid>
                    ))}
                </Grid>
                <Box sx={{ p: '16px 24px', background: theme.palette.grey[700], mt: 3 }}>
                    <DarkText sx={{ fontSize: 10, fontWeight: 600, textTransform: 'uppercase' }}>
                        <FormattedMessage id="publication" />
                    </DarkText>
                </Box>
                <Grid container>
                    {itemsFacebookAdsPublication2.map((item: string, index: number) => (
                        <Grid key={index} item xs={12} sm={6}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItem: 'center',
                                    p: '16px 24px',
                                    borderRight: (index + 1) % 2 !== 0 ? `1px solid ${theme.palette.grey[800]}` : ''
                                }}
                            >
                                <Text sx={{ fontSize: 14 }}>
                                    <FormattedMessage id={item} />
                                </Text>
                                <CheckCircleIcon sx={{ fill: theme.palette.orange.main, fontSize: '20px' }} />
                            </Box>
                        </Grid>
                    ))}
                </Grid> */}
            </Grid>
            <Grid item sm={1} sx={{ borderTop: `1px solid ${theme.palette.grey[500]}`, p: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ width: '94px', mr: 1 }}>
                    <Button variant="secondary" onClick={handleCloseAddingLink} title={<FormattedMessage id="cancel" />} />
                </Box>
                <Box sx={{ width: '94px' }}>
                    <Button variant="primary" onClick={handleShare} title={<FormattedMessage id="share" />} />
                </Box>
            </Grid>
            <SharedLinkDialog
                open={showShareLinkDialog}
                onClose={() => {
                    closeShareLinkDialog();
                    handleCloseAddingLink();
                }}
                link={`${process.env.REACT_APP_DOMAIN}/reporting/${newLinkId}`}
                handleShareLinkToEmail={handleShareLinkToEmail}
            />
        </Grid>
    );
};

export default AddLinkShare;
