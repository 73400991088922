import { Box, Button, Grid, TextField, Typography, useTheme } from '@mui/material';
import { useFieldHook } from './useFieldHook';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { BaseFieldProps } from 'features/workflow-task-execution/types';

interface ListProps extends BaseFieldProps {}

export default function List({ field, setSavingStatus, handleFieldValueUpdate }: ListProps) {
    const theme = useTheme();
    const [newEntry, setNewEntry] = React.useState('');
    const [value, handleChange, intl] = useFieldHook<string[]>({ field, setSavingStatus, handleFieldValueUpdate });

    const nonNullValue = value ?? [];

    const handleRemoveItem = (index: number) => {
        const newValue = [...value];
        newValue.splice(index, 1);
        handleChange(newValue);
    };

    const handleAddItem = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' && newEntry) {
            e.preventDefault();
            handleChange([...nonNullValue, newEntry]);
            setNewEntry('');
        }
    };

    return (
        <>
            <Typography sx={{ color: '#000', fontFamily: 'Inter', fontWeight: 500, fontSize: '14px', mb: 2, mt: 3 }}>
                {field.title}
            </Typography>
            <TextField
                sx={{
                    '.MuiOutlinedInput-input': {
                        fontWeight: 400,
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        background: '#fff'
                    }
                }}
                id="accountsToTag"
                name="accountsToTag"
                value={newEntry}
                onChange={(e) => setNewEntry(e.target.value)}
                variant="outlined"
                fullWidth
                placeholder={field.title}
                onKeyDown={handleAddItem}
                disabled={field.permission?.type === 'READ'}
                error={Boolean(field.permission?.is_required)}
                helperText={
                    field.permission?.is_required ? intl.formatMessage({ id: 'please_fill_in_this_field_to_complete_your_task' }) : ''
                }
            />
            <Typography sx={{ color: theme.palette.grey[300], fontFamily: 'Inter', fontWeight: 400, fontSize: '14px', mb: 2, mt: 1 }}>
                Press Enter to add a new item
            </Typography>

            <Box sx={{ backgroundColor: 'orange', display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {(value ?? []).map((item: string, index) => (
                    <Box
                        sx={{
                            p: '8px 12px',
                            borderRadius: '16px',
                            background: theme.palette.primary[300],
                            mr: 2,
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        key={item}
                    >
                        <Typography
                            sx={{
                                color: theme.palette.primary[700],
                                fontFamily: 'Inter',
                                fontWeight: 500,
                                fontSize: '12px',
                                mr: 1
                            }}
                        >
                            {item}
                        </Typography>
                        {field.permission?.type === 'WRITE' && (
                            <CloseIcon
                                onClick={() => handleRemoveItem(index)}
                                sx={{ fill: theme.palette.primary[700], fontSize: '18px', cursor: 'pointer' }}
                            />
                        )}
                    </Box>
                ))}
            </Box>
        </>
    );
}
