import axios, { AxiosInstance } from 'axios';

// Axios client
const baseURL = process.env.REACT_APP_SNIKPIC_BASE_URL;

// Utils
const generateHeaders = () => ({
    authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || `{}`)?.tokens?.access_token ?? ''}`
});

// Get clients of organizations
interface GetClientsInput {
    organizationId: string;
}

export const getClients = async (input: GetClientsInput) => {
    try {
        const client: AxiosInstance = axios.create({ baseURL, headers: generateHeaders() });
        const endpoint = '/client-organization/agency-clients';
        const config = {
            params: {
                organization_id: input.organizationId
            }
        };
        return await client.get(endpoint, config);
    } catch (e) {
        const err: any = e;
        console.error(err.response.data);
        throw new Error(err.response.data.extensions.message);
    }
};
