import { Typography, Box, Dialog, Grid, TextField } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { Button } from 'ui-component/extended/Button';
import { useFormik } from 'formik';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { openSnackbar } from 'store/slices/snackbar';
import { useDispatch } from 'store';

interface SharedLinkDialogProps {
    open: boolean;
    onClose: any;
    link: string;
    handleShareLinkToEmail: (email_recipients: string[]) => Promise<void> | void;
}

const SharedLinkDialog = ({ open, onClose, link, handleShareLinkToEmail }: SharedLinkDialogProps) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const intl = useIntl();

    const formik = useFormik({
        initialValues: { email: '' },
        // Todo add validation of multiple emails
        // validationSchema: Yup.object({
        //     email: Yup.string().email('Invalid email').required('Required')
        // }),
        onSubmit: async (values) => {
            await handleShareLinkToEmail(
                values.email
                    .replace(' ', '')
                    .split(/(,|;)/)
                    .filter((e) => e !== ',' && e !== ';')
            );
        }
    });

    const [copyValueDescription, setCopyValueDescription] = React.useState({
        value: '',
        copied: false
    });

    const onCopy = () => {
        setCopyValueDescription({
            ...copyValueDescription,
            copied: true
        });
        setTimeout(() => {
            setCopyValueDescription({
                ...copyValueDescription,
                copied: false
            });
        }, 1000);
    };
    const handleSnackbar = () => {
        dispatch(
            openSnackbar({
                open: true,
                message: 'copied_to_clipboard',
                transition: 'SlideDown'
            })
        );
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{
                '.MuiPaper-root': { p: 3, width: { xs: '110%', sm: '568px' }, borderRadius: '16px', overflow: 'visible', height: '220px' }
            }}
        >
            <Box sx={{ position: 'relative' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                    {' '}
                    <Typography
                        sx={{
                            color: theme.palette.grey[400],
                            fontSize: '16px',
                            fontWeight: 600,
                            fontFamily: 'Inter'
                        }}
                    >
                        <FormattedMessage id="share" />
                    </Typography>
                    <ClearOutlinedIcon onClick={onClose} sx={{ fill: theme.palette.grey[400], fontSize: '20px', cursor: 'pointer' }} />
                </Box>
                <form onSubmit={formik.handleSubmit}>
                    <TextField
                        sx={{
                            '.MuiOutlinedInput-input': {
                                fontWeight: 400,
                                fontFamily: 'Inter',
                                fontSize: '14px'
                            }
                        }}
                        id="email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        variant="outlined"
                        fullWidth
                        placeholder={intl.formatMessage({ id: 'share_link_email_recipients_placeholder' })}
                        helperText={intl.formatMessage({ id: 'share_link_email_recipients_helper_text' })}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                        <Box sx={{ width: '80px' }}>
                            <Button onClick={formik.handleSubmit} title={<FormattedMessage id="share" />} />
                        </Box>
                    </Box>
                </form>
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: '-140px',
                        left: '-24px',
                        width: { xs: '118%', sm: '568px' },
                        background: theme.palette.background.paper,
                        borderRadius: '16px',
                        p: 3
                    }}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={6} sm={9}>
                            <Box
                                sx={{
                                    border: `1px solid ${theme.palette.grey[500]}`,
                                    p: '10px',
                                    borderRadius: '8px',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: theme.palette.grey[300],
                                        fontSize: '14px',
                                        fontWeight: 400,
                                        fontFamily: 'Inter'
                                    }}
                                >
                                    {link}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CopyToClipboard onCopy={onCopy} text={link}>
                                <Button onClick={handleSnackbar} title={<FormattedMessage id="copy_link" />} />
                            </CopyToClipboard>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Dialog>
    );
};

export default SharedLinkDialog;
