import axios from 'axios';

interface AddAndInviteMember {
    first_name: string;
    last_name: string;
    role: string;
    title: string;
    email: string;
    organization_id: string;
}

export const upsertUserAndAddToOrg = async (input: AddAndInviteMember) => {
    console.log('In upsertUserAndAddToOrg');
    console.log('input', input);
    const baseURL = process.env.REACT_APP_SNIKPIC_BASE_URL;
    const headers = {
        authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '')?.tokens?.access_token ?? ''}`
    };
    const endpointURL = `${baseURL}/organization/add-organization-user-nest`;
    try {
        const response = await axios.post(endpointURL, input, { headers });
        return response.data;
    } catch (e) {
        const err: any = e;
        console.error(err.response.data);
        throw new Error(err.response.data.extensions.message);
    }
};
