// eslint-disable-next-line import/no-named-as-default
import VirtualEmployeeMarketplace from 'views/pages/marketplace/VirtualEmployeeMarketplace';

const MarketplaceRoutes = {
    children: [
        {
            path: '/public/marketplace',
            element: <VirtualEmployeeMarketplace />
        }
    ]
};

export default MarketplaceRoutes;
