import { Box, CircularProgress, Modal } from '@mui/material';

interface LoadingModalProps {
    open: boolean;
}

export const LoadingModal = ({ open }: LoadingModalProps) => (
    <Modal open={open} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box>
            <CircularProgress />
        </Box>
    </Modal>
);
