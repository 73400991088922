import { useState, useEffect } from 'react';
import {
    Typography,
    Box,
    Breadcrumbs,
    Grid,
    Avatar,
    CardMedia,
    Divider,
    Rating,
    Drawer,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import briefcase from 'assets/images/briefcase.svg';
import creditcard from 'assets/images/creditcard.svg';
import { Button } from 'ui-component/extended/Button';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PackageDetailsContainer from './PackageDetailsContainer';
import CreateProposal from './CreateProposal';

interface PackageDetailsDrawerProp {
    open: boolean;
    onClose: any;
    packages: any;
}

const SelectPackageDrawer = ({ open, onClose, packages }: PackageDetailsDrawerProp) => {
    const theme = useTheme();
    const [createProposal, setCreateProposal] = useState(false);
    const [packageDetails, setPackageDetails] = useState(false);
    const [error, setError] = useState(false);
    const [radioPackages, setRadioPackages] = useState<any>([]);
    const [selectedRadioPackages, setSelectedRadioPackages] = useState<any>();
    const handleCloseCreateProposal = () => setCreateProposal(false);
    const handleOpenCreateProposal = () => setCreateProposal(true);
    useEffect(() => {
        const arrRadioPackages: any = [];
        packages?.forEach((element: any) => {
            arrRadioPackages.push({
                id: element.id,
                active: false,
                skil: element.skil,
                jobs: element.jobs,
                price: element.price
            });
        });
        setRadioPackages(arrRadioPackages);
    }, []);
    const handleRadioPackages = (itemSelected: any) => {
        setSelectedRadioPackages(itemSelected);
        setRadioPackages(
            radioPackages.map((item: any) => {
                if (item.id === itemSelected.id) {
                    return { ...item, active: true };
                }
                return { ...item, active: false };
            })
        );
    };
    const handleViewDetailsPackage = (page: string) => {
        if (selectedRadioPackages) {
            if (page === 'CreateProposal') {
                handleOpenCreateProposal();
            } else setPackageDetails(true);
            setError(false);
        } else {
            setError(true);
        }
    };

    const backFunction = () => setPackageDetails(false);
    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            sx={{
                '.MuiPaper-root': {
                    maxWidth: '562px',
                    diplay: 'flex',
                    height: '100vh',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }
            }}
        >
            {createProposal ? (
                <CreateProposal
                    handleCloseCreateProposal={handleCloseCreateProposal}
                    selectedPackege={selectedRadioPackages}
                    back
                    backFunction={handleCloseCreateProposal}
                />
            ) : (
                <Box>
                    {packageDetails ? (
                        <PackageDetailsContainer selectedPackege={selectedRadioPackages} back backFunction={backFunction} />
                    ) : (
                        <Box sx={{ height: '100vh' }}>
                            <Box sx={{ height: 'calc(100vh - 107px)', overflowY: 'auto', p: 4 }}>
                                <Typography
                                    sx={{
                                        color: theme.palette.grey[400],
                                        fontFamily: 'Inter',
                                        fontWeight: 600,
                                        fontSize: '20px',
                                        mb: 2
                                    }}
                                >
                                    <FormattedMessage id="select_package" />
                                </Typography>
                                <Grid container spacing={3}>
                                    {radioPackages?.map((item: any, index: number) => (
                                        <Grid key={index} item xs={12} sm={6}>
                                            <Box
                                                onClick={() => handleRadioPackages(item)}
                                                sx={{
                                                    p: '10px 20px',
                                                    borderRadius: '8px',
                                                    border: item.active
                                                        ? `1px solid ${theme.palette.orange.main}`
                                                        : `1px solid ${theme.palette.grey[500]}`,
                                                    cursor: 'pointer',
                                                    boxShadow: item.active
                                                        ? '0px 12px 16px -4px rgba(30, 60, 120, 0.08), 0px 4px 6px -2px rgba(30, 60, 120, 0.03)'
                                                        : ''
                                                }}
                                            >
                                                <Radio checked={item.active} sx={{ p: 0 }} />
                                                <Typography
                                                    sx={{
                                                        color: theme.palette.grey[400],
                                                        fontFamily: 'Inter',
                                                        fontWeight: 600,
                                                        fontSize: '16px',
                                                        mt: 2
                                                    }}
                                                >
                                                    {item.skil}
                                                </Typography>
                                                {item.jobs.map((itemJob: any, indexjob: number) => (
                                                    <Box key={indexjob} sx={{ display: 'flex', alignItem: 'center', mt: 2 }}>
                                                        <FiberManualRecordIcon
                                                            sx={{ fill: theme.palette.grey[600], fontSize: '10px', mr: 1 }}
                                                        />
                                                        <Typography
                                                            sx={{
                                                                color: theme.palette.grey[400],
                                                                fontFamily: 'Inter',
                                                                fontWeight: 500,
                                                                fontSize: '13px',
                                                                mt: '-3px'
                                                            }}
                                                        >
                                                            {`${itemJob.volume} ${itemJob.title}`}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                                <Divider sx={{ mt: 3, mb: 3 }} />
                                                <Typography
                                                    sx={{
                                                        color: theme.palette.grey[400],
                                                        fontFamily: 'Inter',
                                                        fontWeight: 600,
                                                        fontSize: '15px',
                                                        pb: '12px'
                                                    }}
                                                >
                                                    {item.price}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                                {error && (
                                    <Typography
                                        sx={{
                                            color: 'red',
                                            fontFamily: 'Inter',
                                            fontWeight: 400,
                                            fontSize: '14px',
                                            pb: '12px',
                                            mt: 1
                                        }}
                                    >
                                        Required
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                sx={{
                                    p: 4,
                                    borderTop: `1px solid ${theme.palette.grey[500]}`,
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    height: '107px'
                                }}
                            >
                                <Button
                                    variant="secondary"
                                    sx={{ width: '190px', mr: 1 }}
                                    title={<FormattedMessage id="view_package_details" />}
                                    onClick={() => handleViewDetailsPackage(' ')}
                                />
                                <Button
                                    sx={{ width: '160px' }}
                                    title={<FormattedMessage id="create_proposal" />}
                                    onClick={() => handleViewDetailsPackage('CreateProposal')}
                                />
                            </Box>
                        </Box>
                    )}
                </Box>
            )}
        </Drawer>
    );
};

export default SelectPackageDrawer;
