import { useTheme, Theme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

interface StatusChipProps {
    status: string | undefined;
    mapping: (theme: Theme) => {
        [key: string]: { backgroundColor: string; textColor: string; text: string };
        default: { backgroundColor: string; textColor: string; text: string };
    };
}

export default function StatusChip({ status, mapping }: StatusChipProps) {
    const theme = useTheme();

    const { backgroundColor, textColor, text } = status ? mapping(theme)[status] : mapping(theme).default;

    return (
        <Box sx={{ p: '8px 12px', borderRadius: '16px', background: backgroundColor, mr: 2 }}>
            <Typography sx={{ color: textColor, fontFamily: 'Inter', fontWeight: 500, fontSize: '12px' }}>{text}</Typography>
        </Box>
    );
}
