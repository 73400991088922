import { Box, Dialog, Divider, Typography } from '@mui/material';
import React, { FC, useContext, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import AddIcon from '@mui/icons-material/Add';
import { ReactComponent as File24Icon } from 'assets/images/file24.svg';
import { ReactComponent as TextIcon } from 'assets/images/Text.svg';
import { ReactComponent as TableIcon } from 'assets/images/table.svg';
import { ReactComponent as UploadIcon } from 'assets/images/upload.svg';
import { ReactComponent as TextAreaIcon } from 'assets/images/TextArea.svg';
import { ReactComponent as ChevronsDownIcon } from 'assets/images/chevrons-down.svg';
import { ReactComponent as CheckIcon } from 'assets/images/check.svg';
import { ReactComponent as PhRowIcon } from 'assets/images/ph_rows.svg';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ColumnBody, ColumnHeader, NextButton, TwoColumnBox } from './ui';
import FieldItem from './draggable/fieldItem';
import SavedSectionItem from './draggable/savedSectionItem';
import VariableItem from './subcomponents/variableItem';
import { SectionContainer } from './sectionContainer';
import { FIELD_TYPE, FIELD_TYPE_NAME, Page, Section } from '../types';
import DialogAddPage from './dialog/dialogAddPage';
import PageItem from './dialog/pageMenuItem';
import DocumentBuilderContext from './context/workflow.context';
import { useLazyQuery } from '@apollo/client';
import { GET_DOCUMENT_PARTS } from 'services/graphQL/queries/workflow';
import { createDocumentBuilderSection } from './hooks/useDocumentBuilder';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

interface DocumentBuilderProps {
    onNext: () => void;
}

const FIELD_OPTIONS = [
    {
        icon: <TextIcon />,
        type: FIELD_TYPE.TEXT
    },
    {
        icon: <TextAreaIcon />,
        type: FIELD_TYPE.TEXTAREA
    },
    {
        icon: <ChevronsDownIcon />,
        type: FIELD_TYPE.DROPDOWN
    },
    {
        icon: <CheckIcon />,
        type: FIELD_TYPE.TICKBOX
    },
    {
        icon: <UploadIcon />,
        type: FIELD_TYPE.FILE_UPLOADER
    },
    {
        icon: <CalendarTodayOutlinedIcon fontSize="small" />,
        type: FIELD_TYPE.DATE
    },
    {
        icon: <FormatListBulletedIcon fontSize="small" />,
        type: FIELD_TYPE.LIST
    }
];
// eslint-disable-next-line no-lone-blocks
{
    /* <FieldItem icon={<TableIcon />} name="Table/Evolutive" type={FIELD_TYPE.TABLE} /> */
}
// eslint-disable-next-line no-lone-blocks
{
    /* <FieldItem icon={<LocationOnOutlinedIcon fontSize="small" />} name="Location" type={FIELD_TYPE.LOCATION} /> */
}

const DocumentBuilderContent: FC<DocumentBuilderProps> = ({ onNext }) => {
    const [openAddPageDialog, setOpenAddPageDialog] = useState<boolean>(false);
    const { documentBuilder, setPageIndex, pageIndex, saveChanges } = useContext(DocumentBuilderContext);
    const [savedSections, setSavedSections] = useState<any[]>([]);
    const dispatch = useDispatch();
    const intl = useIntl();

    const handleCloseDialogAddPage = () => {
        setOpenAddPageDialog(false);
    };

    const [query] = useLazyQuery(GET_DOCUMENT_PARTS, {
        onCompleted: (result) => {
            setSavedSections(
                result.workflow_saved_document_parts.map((savedDocumentPart: any) => ({
                    ...createDocumentBuilderSection(savedDocumentPart.content),
                    sectionId: undefined
                }))
            );
        }
    });

    useEffect(() => {
        query({
            variables: {
                type: 'SECTION'
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnNext = async () => {
        try {
            await saveChanges();
            onNext();
        } catch (error: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({ id: 'unable_to_save_changes' }),
                    variant: 'error'
                })
            );
        }
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <Box mt={2} sx={{ display: 'flex', borderTop: '1px solid #D4DBEA', height: '100%', flex: 1 }}>
                <Box sx={{ width: '200px', display: 'flex', flexDirection: 'column' }}>
                    <ColumnHeader>
                        <Typography variant="subtitle1">
                            <FormattedMessage id="pages" />
                        </Typography>
                        <AddIcon onClick={() => setOpenAddPageDialog(true)} sx={{ cursor: 'pointer' }} />

                        <Dialog
                            sx={{ '.MuiDialog-paper': { minWidth: '300px' } }}
                            open={openAddPageDialog}
                            onClose={handleCloseDialogAddPage}
                            aria-labelledby="add-page-dialog-title2"
                            aria-describedby="add-page-dialog-description2"
                        >
                            <DialogAddPage onClose={handleCloseDialogAddPage} />
                        </Dialog>
                    </ColumnHeader>
                    <ColumnBody sx={{ paddingTop: '18px', flex: 1 }}>
                        {documentBuilder.pages.map((page: Page, index: number) => (
                            <PageItem
                                name={page.pageName}
                                key={index}
                                onClick={() => setPageIndex(index)}
                                pageIndex={index}
                                isSelected={pageIndex === index}
                            />
                        ))}
                    </ColumnBody>
                </Box>
                <Box sx={{ width: '336px', display: 'flex', flexDirection: 'column' }}>
                    <ColumnHeader>
                        <Typography variant="subtitle1">
                            <FormattedMessage id="field_types" />
                        </Typography>
                    </ColumnHeader>
                    <ColumnBody sx={{ padding: '16px', display: 'flex', flexDirection: 'column', gap: '16px', flex: 1 }}>
                        <TwoColumnBox>
                            {FIELD_OPTIONS.map((fieldOption, index) => (
                                <FieldItem
                                    key={index}
                                    icon={fieldOption.icon}
                                    name={FIELD_TYPE_NAME[fieldOption.type]}
                                    type={fieldOption.type}
                                />
                            ))}
                        </TwoColumnBox>
                        <Divider />
                        {/* {documentBuilder.pages.find(
                            (page: any) => page.pageVariable || page.pageSections.find((section: any) => section.sectionVariable)
                        ) && (
                            <>
                                <Typography variant="subtitle1">
                                    <FormattedMessage id="variables" />
                                </Typography>
                                <TwoColumnBox>
                                    {documentBuilder.pages.map((page: Page, key: number) => (
                                        <React.Fragment key={key}>
                                            {page.pageVariable ? (
                                                <VariableItem icon={<File24Icon />} name={page.pageName} key={key} />
                                            ) : null}
                                            {page.pageSections.map((section: Section, sIdx: number) =>
                                                section.sectionVariable ? (
                                                    <VariableItem icon={<PhRowIcon />} name={section.sectionName} key={`${key}-${sIdx}`} />
                                                ) : null
                                            )}
                                        </React.Fragment>
                                    ))}
                                </TwoColumnBox>
                                <Divider />
                            </>
                        )} */}
                        <Typography variant="subtitle1">
                            <FormattedMessage id="saved_sections" />
                        </Typography>
                        <TwoColumnBox>
                            {savedSections.map((section: any, key: number) => (
                                <SavedSectionItem name={section.sectionName} key={key} sectionData={section} />
                            ))}
                        </TwoColumnBox>
                    </ColumnBody>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <ColumnHeader sx={{ justifyContent: 'flex-end', gap: '12px' }}>
                        <NextButton onClick={handleOnNext}>
                            <FormattedMessage id="next" />
                        </NextButton>
                    </ColumnHeader>
                    <SectionContainer pageIndex={pageIndex} />
                </Box>
            </Box>
        </DndProvider>
    );
};

export default DocumentBuilderContent;
