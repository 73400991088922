import { ChangeEventHandler } from 'react';
import { ValidationError } from './ValidationError';

import { TextField, Box } from '@mui/material';

type ChannelNameInputProps = {
    name: string;
    error: string | null;
    onChange: ChangeEventHandler<HTMLInputElement>;
    placeholder?: string;
};

export const ChannelNameInputField = ({ name = '', error, placeholder = 'channel-name', onChange }: ChannelNameInputProps) => (
    // <div className="channel-name-input-field">
    <Box sx={{ width: 1 }}>
        <div>
            <h2>
                {/* <span>Name</span> */}
                <ValidationError errorMessage={error} />
            </h2>
            {/* <input onChange={onChange} placeholder={placeholder} type="text" value={name} /> */}
            {/* <TextField id="outlined-basic" label="Outlined" variant="outlined" onChange={onChange} placeholder={placeholder} value={name} /> */}
            <TextField fullWidth variant="outlined" label="Channel name" onChange={onChange} placeholder={placeholder} value={name} />
        </div>
    </Box>
);
