import TriggerNode from '../nodes/start-node';
import ScheduleNode from '../nodes/schedule-node';
import WebhookNode from '../nodes/webhookNode';
import ApprovalNode from '../../../../../../../../../features/workflow/workflow-builder/nodes/ApprovalTask/ApprovalTaskNode';
import End from '../nodes/end-node';
import AutomationNode from 'features/workflow-automation/components/AutomationNode';
import TaskNode from 'features/workflow/workflow-builder/nodes/Task/TaskNode';

const NODE_TYPES = {
    trigger: TriggerNode,
    schedule: ScheduleNode,
    webhook: WebhookNode,
    task: TaskNode,
    automation_task: AutomationNode,
    approval_task: ApprovalNode,
    end: End
} as const;

export default NODE_TYPES;
