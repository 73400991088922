import _ from 'lodash';
import { createContext, useState } from 'react';
import { JsonLogicExpression } from '../types/jsonLogic.types';
import { LogicBuilderExpression } from '../types/logicBuilder.types';

type ConditionBuilderContextType = {
    nodeId: string;
    edges: any[];
    setEdges: (edges: any[]) => void;
    conditions: LogicBuilderExpression[];
    setConditions: (conditions: LogicBuilderExpression[]) => void;
    updateConditions: (updateBody: LogicBuilderExpression) => void;
    convertConditionsArrayToJsonLogic: () => JsonLogicExpression[];
    convertJsonLogicToConditionsArray: (jsonLogicArray: JsonLogicExpression[]) => LogicBuilderExpression[];
    deleteCondition: (conditionId: string) => void;
};

export const ConditionBuilderContext = createContext<ConditionBuilderContextType>({
    nodeId: '',
    edges: [],
    setEdges: () => {},
    conditions: [],
    setConditions: () => {},
    updateConditions: () => {},
    convertConditionsArrayToJsonLogic: () => [],
    convertJsonLogicToConditionsArray: () => [],
    deleteCondition: () => {}
});

export function useConditionBuilderContext(
    nodeId: string,
    initialJsonLogicArray: JsonLogicExpression[],
    edges: any[],
    setEdges: (edges: any[]) => void
): ConditionBuilderContextType {
    const jsonLogicToConditionsArrayHelper = (jsonLogicArray: any[]) => {
        jsonLogicArray.forEach((element: any, index: number) => {
            const operator = Object.keys(element)[0];
            const operatorIsValid = operator === 'if' || operator === 'and' || operator === 'or' || operator === '==';

            if (typeof element === 'object' && !Array.isArray(element) && operatorIsValid) {
                jsonLogicArray[index] = { id: _.uniqueId(), type: operator, content: element[operator] };
                jsonLogicToConditionsArrayHelper(element[operator]);
            } else if (typeof element === 'string') {
                jsonLogicArray[index] = { id: _.uniqueId(), type: 'text', content: element };
            }
        });
    };

    const convertJsonLogicToConditionsArray = (jsonLogicArray: JsonLogicExpression[]) => {
        const conditionsArray = _.cloneDeep(jsonLogicArray);
        jsonLogicToConditionsArrayHelper(conditionsArray);
        return conditionsArray as unknown as LogicBuilderExpression[];
    };

    const initialConditionsArray: LogicBuilderExpression[] =
        initialJsonLogicArray.length > 0
            ? convertJsonLogicToConditionsArray(initialJsonLogicArray)
            : [
                  {
                      id: '1',
                      type: 'if',
                      content: [
                          {
                              id: '2',
                              type: 'or',
                              content: [
                                  { id: '3', type: '==', content: [{ type: 'document' }, false] }
                                  // {
                                  //     id: '4',
                                  //     type: '==',
                                  //     content: [{ type: 'assignee_comments', object_type: 'section', object_id: 'section_id_1' }, true]
                                  // }
                              ]
                          },
                          { id: '5', type: 'text', content: '' },
                          { id: '6', type: 'text', content: '' }
                      ]
                  }
              ];

    const [conditions, setConditions] = useState<LogicBuilderExpression[]>(initialConditionsArray);

    const updateConditions = (updateBody: LogicBuilderExpression) => {
        const newConditions = [...conditions];
        findAndUpdateElementInConditionsArray(newConditions, updateBody);
        setConditions(newConditions);
    };

    const findAndUpdateElementInConditionsArray = (conditionsArray: LogicBuilderExpression[], updateBody: any) => {
        conditionsArray.forEach((element: any, index: number) => {
            if (element.id === updateBody.id) {
                conditionsArray[index] = updateBody;
            } else if (element.type !== 'text' && element.type !== '==') {
                findAndUpdateElementInConditionsArray(element.content, updateBody);
            }
        });
    };

    const convertConditionsArrayToJsonLogic = () => {
        const jsonLogicConditions = _.cloneDeep(conditions);

        conditionsArrayToJsonLogicHelper(jsonLogicConditions);

        return jsonLogicConditions as unknown as JsonLogicExpression[];
    };

    const conditionsArrayToJsonLogicHelper = (conditionsArray: any[]) => {
        conditionsArray.forEach((element: any, index: number) => {
            if (element?.id && element?.type !== 'text') {
                conditionsArray[index] = { [element.type]: element.content };
                conditionsArrayToJsonLogicHelper(element.content);
            } else if (element?.id && element?.type === 'text') {
                conditionsArray[index] = element.content;
            }
        });
    };

    const deleteCondition = (conditionId: string) => {
        const newConditions = _.cloneDeep(conditions);

        deleteConditionHelper(newConditions, conditionId);

        setConditions(newConditions);
    };

    const deleteConditionHelper = (conditionsArray: any[], conditionId: string) => {
        conditionsArray.forEach((element: any, index: number) => {
            if (element.id === conditionId) {
                conditionsArray.splice(index, 1);
            } else if (element.type !== 'text' && element.type !== '==') {
                deleteConditionHelper(element.content, conditionId);
            }
        });
    };

    return {
        nodeId,
        edges,
        setEdges,
        conditions,
        setConditions,
        updateConditions,
        convertConditionsArrayToJsonLogic,
        convertJsonLogicToConditionsArray,
        deleteCondition
    };
}
