import { gql } from '@apollo/client';

export const GET_CLIENT_CUPTION_EXAMPLES = gql`
    query GetClientCaptionExamples($client_organization_id: uuid!) {
        client_organization_caption_examples(where: { client_organization_id: { _eq: $client_organization_id } }) {
            id
            concept
            client_organization_id
            caption
        }
    }
`;
export const GET_AI_TOOLS_HISTORY = gql`
    query GetClientCaptionExamples($service: ai_tools_services_enum!, $client_organization_id: uuid!) {
        ai_tools_history(where: { service: { _eq: $service }, client_organization_id: { _eq: $client_organization_id } }) {
            timestamp
            service
            output
            organization_id
            input
            id
            client_organization_id
        }
    }
`;
export const GET_BRAND_DESCRIPTION = gql`
    query getBrandDescription($client_organization_id: uuid!) {
        client_organizations(where: { id: { _eq: $client_organization_id } }) {
            ai_brand_description
        }
    }
`;
export const GET_SERVICE_USAGE_LIMITS = gql`
    query GetServiceUsage($client_organization_id: String!, $service: String!) {
        ai_tools_get_usage(arg1: { client_organization_id: $client_organization_id, service: $service }) {
            current
            client_limit
            available_credits
        }
    }
`;

export const GET_CAPTION_EXAMPLE_SUBCATEGORIES = gql`
    query GetCaptionExampleSubcategories {
        caption_example_subcategories {
            name
            category_id
        }
    }
`;

export const GET_CAPTION_EXAMPLE_CATEGORIES = gql`
    query GetCaptionCategories {
        caption_example_categories {
            name
            id
        }
    }
`;
