import {
    Box,
    DialogContent,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
    useTheme
} from '@mui/material';
import { AvailableAutomationNode, availableAutomationNodes } from 'features/workflow-automation/types';
import { ReactFlowAutomationNode } from 'features/workflow/types/react-flow.types';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AutomationConfiguration, AutomationConfigurationField } from '@sni4/snikpic-common';
import DocumentBuilderContext from 'views/pages/clients/features/detail/workflow/features/document-builder/context/workflow.context';
import { ColumnBodyOne, RowBody } from 'views/pages/clients/features/detail/workflow/features/document-builder/ui';
import { Page } from 'views/pages/clients/features/detail/workflow/features/types';
import DialogFooter from 'views/pages/clients/features/detail/workflow/features/workflow-builder/nodes/dialogFooter';
import { DocumentFieldSelector } from './DocumentFieldSelector';
import { PageGroupSelector } from './PageGroupSelector';
import { AutomationNodeHelper } from 'features/workflow-automation/utils/automation-node.util';
import { FormatMultiLangString } from 'features/workflow-automation/utils/multi-lang-string.util';
import { DirectInputText } from './DirectInputText';

interface AutomationNodeDialogProps {
    onClose: () => void;
    isOpen: boolean;
    id: string;
}

export const AutomationNodeDialog = ({ onClose, id, isOpen }: AutomationNodeDialogProps) => {
    const theme = useTheme();

    const { documentBuilder } = useContext(DocumentBuilderContext);
    const node = _.find(documentBuilder?.workflow?.flowNodes, { id }) as ReactFlowAutomationNode;
    const pages = documentBuilder.pages as any;

    const [automationConfiguration, setAutomationConfiguration] = useState<AutomationConfiguration>(
        _.cloneDeep(node?.data?.automation_configuration) || {}
    );

    const [isDataValid, setIsDataValid] = useState<boolean>(node?.data?.is_configuration_valid);
    useEffect(() => {
        if (!isOpen) {
            setAutomationConfiguration(_.cloneDeep(node?.data?.automation_configuration) || {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    // todo : bring types for

    const handleUpdateConfiguration = (arg: { configuration: AutomationConfigurationField; path: string }) => {
        const fields = automationConfiguration.fields;
        const updatedFields = _.set(fields, arg.path, arg.configuration);

        const isConfigurationValid = AutomationNodeHelper.isValid(updatedFields);
        setIsDataValid(isConfigurationValid);

        setAutomationConfiguration({ ...automationConfiguration, fields: updatedFields });
    };

    const handleSave = () => {
        const updatedNodes: any[] = documentBuilder.workflow.flowNodes.map((node: any) => {
            if (node.id === id) {
                const reactFlowAutomationNode: ReactFlowAutomationNode = node;
                return {
                    ...reactFlowAutomationNode,
                    data: {
                        ...node.data,
                        automation_configuration: automationConfiguration,
                        is_configuration_valid: isDataValid
                    }
                };
            }
            return node;
        });

        documentBuilder.workflow.setNodes(updatedNodes);

        onClose();
    };

    const SelectedAutomationSet = availableAutomationNodes().find(
        (availableNode) => availableNode.automation_set === node?.data?.automation_configuration?.automation_set
    ) as AvailableAutomationNode;

    const automationConfigurationSet = SelectedAutomationSet.getDefaultConfiguration();

    const selectedActionIndex = SelectedAutomationSet?.getDefaultConfiguration().actions.findIndex(
        (action) => action.id === automationConfiguration.id
    );

    const handleSelectedActionChange = (event: SelectChangeEvent<number>) => {
        const selectedAction = _.cloneDeep(automationConfigurationSet.actions[event.target.value as number]);

        setAutomationConfiguration(selectedAction);
    };

    return (
        <>
            <DialogContent sx={{ padding: '20px 32px', width: '563px', display: 'flex', flexDirection: 'column', gap: '24px' }}>
                <Typography sx={{ fontWeight: 600, fontSize: '16px', color: theme.palette.text.primary }}>
                    <FormatMultiLangString multiLangString={SelectedAutomationSet.label} />
                    {` - `}
                    <FormatMultiLangString multiLangString={automationConfiguration.label} />
                </Typography>
                <ColumnBodyOne>
                    <ColumnBodyOne sx={{ mb: 3 }}>
                        <RowBody sx={{ gap: '16px' }}>
                            <Typography variant="subtitle1" sx={{ color: theme.palette.text.primary }}>
                                <FormattedMessage id="select_an_action" />
                            </Typography>
                            <Divider sx={{ flex: 1 }} />
                        </RowBody>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-helper-label">Action</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label-2"
                                id="demo-simple-select-helper-2"
                                value={selectedActionIndex}
                                label="Action"
                                onChange={handleSelectedActionChange}
                                sx={{ '& .MuiSelect-select': { fontWeight: 400, backgroundColor: 'white' } }}
                            >
                                {automationConfigurationSet.actions.map((action, index) => (
                                    <MenuItem key={index} value={index}>
                                        <FormatMultiLangString multiLangString={action.label} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Typography
                            variant="body1"
                            sx={{ fontWeight: 200, fontSize: '12px', color: theme.palette.text.primary, overflowWrap: 'break-word' }}
                        >
                            <FormatMultiLangString multiLangString={automationConfiguration.description} />
                        </Typography>
                    </ColumnBodyOne>
                    <AutomationConfigurationRenderer
                        fieldConfigurations={automationConfiguration.fields}
                        pages={pages}
                        updateConfiguration={handleUpdateConfiguration}
                    />
                </ColumnBodyOne>
            </DialogContent>
            <Divider />
            <DialogFooter onClose={onClose} onSave={handleSave} isSaveButtonDisabled={!isDataValid} />
        </>
    );
};

interface AutomationConfigurationRendererProps {
    fieldConfigurations: AutomationConfigurationField[];
    pages: Page[];
    updateConfiguration: (arg: { configuration: AutomationConfigurationField; path: string }) => void;
}

const AutomationConfigurationRenderer = ({
    fieldConfigurations: fieldConfiguration,
    pages,
    updateConfiguration
}: AutomationConfigurationRendererProps) => (
    <>
        {fieldConfiguration.map((baseField, index) => {
            switch (baseField.source) {
                case 'DOCUMENT_PAGE':
                    return (
                        <PageGroupSelector
                            configuration={baseField}
                            pages={pages}
                            path={`[${index}]`}
                            updateConfiguration={updateConfiguration}
                            key={index}
                        />
                    );
                case 'DIRECT_INPUT':
                    return <DirectInputText configuration={baseField} path={`[${index}]`} updateConfiguration={updateConfiguration} />;
                case 'DOCUMENT_FIELD':
                    return (
                        <DocumentFieldSelector
                            configuration={baseField}
                            pages={pages}
                            path={`[${index}]`}
                            updateConfiguration={updateConfiguration}
                            key={index}
                        />
                    );
                default:
                    return <Box>Field does not exist</Box>;
            }
        })}
    </>
);
