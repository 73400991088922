import { FIELD_TYPE } from '../../types';
import { v4 as uuid } from 'uuid';

export const defaultField = (type: FIELD_TYPE) => ({
    fieldId: uuid(),
    fieldType: type,
    fieldName: '',
    fieldValue: '',
    fieldItems: type === FIELD_TYPE.TABLE || type === FIELD_TYPE.DROPDOWN ? ['', ''] : undefined,
    fieldTime: Date.now()
});

export const defaultSection = (num: number) => ({
    sectionId: uuid(),
    sectionName: `Untitled Section ${num}`,
    sectionVariable: false,
    sectionNumber: 0,
    sectionFields: [],
    secondCols: [],
    sectionChecked: false,
    sectionClicked: false
});

export const defaultPage = (num: number) => ({
    pageId: uuid(),
    pageName: `Untitled Page ${num}`,
    pageVariable: true,
    pageNumber: 1,
    pageSections: [defaultSection(1)],
    pageChecked: false,
    pageClicked: false
});

export const defaultValue = {
    workflowName: 'Untitled Workflow',
    workflowStatus: '',
    workflowCreationStatus: '',
    pages: [defaultPage(1)],
    workflow: {
        flowStyle: {
            rowHeight: 180,
            colWidth: 300,
            floorSpace: 20
        },
        flowNodes: [],
        flowEdges: [],
        taskAssigned: false,
        taskApproved: false
    },
    documentId: '',
    documentVersionId: '',
    workflowVersionId: '',
    workflowId: '',
    workflowDurationInDays: 0
};
