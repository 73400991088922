import { Box, Typography, CardMedia, Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from 'react';
import ButtonCustom from 'ui-component/extended/Button';
import fa_solid_robot from 'assets/images/fa_solid_robot.svg';
import copy from 'assets/images/copy.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SelectHistoryAiTools from './SelectHistoryAiTools';
import PreviousResult from './PreviousResult';
import { openSnackbar } from 'store/slices/snackbar';
import { useDispatch } from 'store';

interface CaptionGeneratorReultProps {
    handleResults: any;
    history?: boolean;
    resultsValue: any;
    previousValue: PreviousValueType[];
}
interface ResultsValueInputType {
    client_organization_id: string;
    category: string;
    language: string;
    request: string;
}

interface PreviousValueType {
    client_organization_id: string;
    id: string;
    organization_id: string;
    service: number;
    timestamp: string;
    input: ResultsValueInputType;
    output: any;
}

const CaptionGeneratorReults = ({ handleResults, history, resultsValue, previousValue }: CaptionGeneratorReultProps) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [labelsHistoryDateSelector, setLabelsHistoryDateSelector] = React.useState<any>();
    const [valueHistoryDateSelector, setValueHistoryDateSelector] = React.useState('');
    const [resultsPrevious, setResultsPrevious] = React.useState<ResultsValueInputType>();
    const [renderResults, setRenderResults] = React.useState<any>();
    const [date, setDate] = React.useState('');

    const [copyValueDescription, setCopyValueDescription] = React.useState({
        value: '',
        copied: false
    });

    console.log('resultsPrevious: ', resultsPrevious);
    console.log('resultsValue: ', resultsValue);
    console.log('renderResults: ', renderResults);

    const handleSnackbar = () => {
        dispatch(
            openSnackbar({
                open: true,
                message: 'copied_to_clipboard',
                transition: 'SlideDown'
            })
        );
    };
    React.useEffect(() => {
        if (previousValue.length) {
            const arrLabels = previousValue.map((result: PreviousValueType) => result.timestamp);
            setRenderResults(previousValue[0].output);
            setValueHistoryDateSelector(previousValue[0].timestamp);
            setResultsPrevious(previousValue[0].input);
            setDate(previousValue[0].timestamp);
            setLabelsHistoryDateSelector(arrLabels);
        }
    }, [previousValue]);

    const onCopy = () => {
        setCopyValueDescription({
            ...copyValueDescription,
            copied: true
        });
        setTimeout(() => {
            setCopyValueDescription({
                ...copyValueDescription,
                copied: false
            });
        }, 1000);
    };

    const handleValueelectorDate = (value: string) => {
        setValueHistoryDateSelector(value);
        previousValue.forEach((item: PreviousValueType) => {
            if (value === item.timestamp) {
                setResultsPrevious(item.input);
                setRenderResults(item.output);
                setDate(value);
            }
        });
    };
    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: '18px 24px',
                    cursor: 'pointer',
                    borderBottom: `1px solid ${theme.palette.grey[500]}`
                }}
            >
                <Box
                    onClick={handleResults}
                    sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <ArrowBackIcon sx={{ fontSize: '26px', fill: theme.palette.success[300] }} />
                    <Typography ml={1} sx={{ color: theme.palette.success[300], fontFamily: 'Inter', fontWeight: 500, fontSize: '14px' }}>
                        <FormattedMessage id={history ? 'history' : 'results'} />
                    </Typography>
                </Box>
                {history ? (
                    <SelectHistoryAiTools
                        labels={labelsHistoryDateSelector}
                        state={valueHistoryDateSelector}
                        setState={handleValueelectorDate}
                        format="DD, MMMM YYYY"
                    />
                ) : (
                    <Box sx={{ width: '160px' }}>
                        <ButtonCustom
                            onClick={handleResults}
                            titleBtn={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Box mr={1} sx={{ width: '20px' }}>
                                        <CardMedia
                                            sx={{ objectFit: 'contain' }}
                                            component="img"
                                            width="18px"
                                            image={fa_solid_robot}
                                            alt="alt image"
                                        />
                                    </Box>
                                    <Typography sx={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '14px' }}>
                                        <FormattedMessage id="ai_powered" />
                                    </Typography>
                                </Box>
                            }
                            colorBtn="red"
                        />
                    </Box>
                )}
            </Box>
            {history && resultsPrevious && (
                <Box sx={{ p: '24px 24px 0 24px' }}>
                    <PreviousResult resultsPrevious={resultsPrevious} date={date} />
                </Box>
            )}
            {(renderResults || resultsValue) && (
                <Grid container sx={{ alignItems: 'center', p: '24px 24px 0 24px' }}>
                    <Grid item xs={11}>
                        <Typography
                            sx={{
                                whiteSpace: 'pre-line',
                                color: theme.palette.success[400],
                                fontFamily: 'Inter',
                                fontWeight: 400,
                                fontSize: '14px'
                            }}
                        >
                            {/* {resultsValue?.caption ? resultsValue?.caption : renderResults[`${resultsPrevious?.language} Caption`]} */}
                            {resultsValue?.caption ? resultsValue?.caption : renderResults.caption}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} display="flex" justifyContent="flex-end">
                        <CopyToClipboard onCopy={onCopy} text={resultsValue?.caption || renderResults.caption}>
                            <Box onClick={handleSnackbar} sx={{ width: '18px', cursor: 'pointer' }}>
                                <CardMedia sx={{ objectFit: 'contain' }} component="img" width="18px" image={copy} alt="alt image" />
                            </Box>
                        </CopyToClipboard>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};

export default CaptionGeneratorReults;
