/* eslint-disable jsx-a11y/interactive-supports-focus */
import { Box, Typography } from '@mui/material';

interface Props {
    error?: string;
}
const ServerError = ({ error }: Props) => (
    <Box sx={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h1" sx={{ letterSpacing: 4, my: 3 }}>
                Something went wrong
            </Typography>
            <Typography variant="h4" sx={{ my: 2, fontWeight: 500 }}>
                {!error && '500 - Internel Server Error'}
                {!!error && error}
            </Typography>
            <Typography variant="body1" sx={{ letterSpacing: 1, lineHeight: 1.5 }}>
                Try to refresh this page or feel free to contact us if <br />
                the problems persist.
            </Typography>
        </Box>
    </Box>
);

export default ServerError;
