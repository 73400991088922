import { Navigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { GuardProps } from 'types';

const GuestGuard = ({ children }: GuardProps) => {
    const { isLoggedIn } = useAuth();

    if (isLoggedIn) {
        return <Navigate to="/offers" replace />;
    }

    return children;
};

export default GuestGuard;
