import { Grid, Box, Typography, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import { useLazyQuery } from '@apollo/client';
import { GET_URL } from 'services/graphQL/queries/fbIntegration';
import { MetaDataProps } from 'types/facebook';

interface UserDataType {
    accessToken: string;
    userID: string;
}
interface LinksType {
    title: string;
    url: string;
}

export default function FacebookConnection() {
    const theme = useTheme();
    const intl = useIntl();
    const [userData, setUserData] = React.useState<UserDataType>({
        accessToken: '',
        userID: ''
    });
    const [step, setStep] = React.useState(0);
    const { organizationId } = useParams();
    const [context, setContext] = React.useState<any>({});
    const renderStep = () => {
        switch (step) {
            case 4:
                return (
                    <StepOne
                        setStep={setStep}
                        step={step}
                        setUserData={setUserData}
                        urlMetaData={metaData}
                        context={context}
                        setContext={setContext}
                    />
                );
            case 1:
            case 4:
                return (
                    <StepOne
                        setStep={setStep}
                        step={step}
                        setUserData={setUserData}
                        urlMetaData={metaData}
                        context={context}
                        setContext={setContext}
                    />
                );
            case 2:
                return <StepTwo setStep={setStep} step={step} userData={userData} context={context} setContext={setContext} />;
            case 3:
                return <StepThree setStep={setStep} userData={userData} urlMetaData={metaData} context={context} setContext={setContext} />;
            default:
                return '';
        }
    };
    const [metaData, setMetaData] = React.useState<MetaDataProps>({
        aggregated: '',
        scopes: {}
    });

    const [getUrl] = useLazyQuery(GET_URL, {
        onCompleted: (data) => {
            setMetaData(data.get_url_metadata);
            const localContext = context;
            localContext.agency_business = {
                id: data.get_url_metadata.business_id,
                name: data.get_url_metadata.business_name
            };
            setContext({ ...localContext });
            setStep(1);
        }
    });
    React.useEffect(() => {
        getUrl({
            variables: {
                client_organization_id: organizationId
            }
        });
    }, []);
    const Links = ({ title, url }: LinksType) => (
        <Link to={url} className="textDecorationNone">
            <Typography sx={{ fontWeight: 500, fontFamily: 'Inter', color: theme.palette.grey[300], fontSize: '14px', mb: 1 }}>
                <FormattedMessage id={title} />
            </Typography>
        </Link>
    );
    return (
        <Grid container sx={{ minHeight: { xs: '100%', md: '100vh' } }}>
            <Grid
                p={3}
                justifyContent="space-between"
                item
                container
                xs={12}
                sm={3}
                sx={{ background: theme.palette.grey[700], flexDirection: { xs: 'row', sm: 'column' } }}
            >
                <Grid item xs={10}>
                    <Typography
                        mb={2}
                        sx={{
                            fontWeight: 600,
                            fontFamily: 'Inter',
                            color: theme.palette.grey[400],
                            fontSize: { xs: '24px', sm: '18px' }
                        }}
                    >
                        {context.agency_business?.name}
                        <FormattedMessage id="title_facebook" />
                    </Typography>
                    <Typography sx={{ fontWeight: 400, fontFamily: 'Inter', color: theme.palette.grey[300], fontSize: '14px' }}>
                        <FormattedMessage id="subtitle_facebook_connection1" />
                        {context.agency_business?.name}
                        <FormattedMessage id="subtitle_facebook_connection2" />
                    </Typography>
                    <Box mt={5} mb={5}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ background: theme.palette.orange.main, borderRadius: '100px', width: '24px', height: '24px' }}>
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        fontFamily: 'Inter',
                                        color: theme.palette.background.paper,
                                        fontSize: '14px',
                                        m: '3px 0 0 8px'
                                    }}
                                >
                                    1
                                </Typography>
                            </Box>
                            <Box ml={2}>
                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontFamily: 'Inter',
                                        color: theme.palette.grey[600],
                                        fontSize: '10px',
                                        textTransform: 'uppercase',
                                        letterSpacing: '0.12em'
                                    }}
                                >
                                    {`${intl.formatMessage({ id: 'step' })} 1`}
                                </Typography>
                                <Typography
                                    mt={1}
                                    sx={{
                                        fontWeight: 500,
                                        fontFamily: 'Inter',
                                        color: theme.palette.grey[400],
                                        fontSize: '14px'
                                    }}
                                >
                                    <FormattedMessage id="connect_facebook" />
                                </Typography>
                            </Box>
                        </Box>
                        <Divider
                            sx={{ background: theme.palette.grey[500], width: '36px', transform: 'rotate(90deg)', m: '24px 2px 0 -5px' }}
                        />
                        <Box mt={3} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box
                                sx={{
                                    background: step > 1 ? theme.palette.orange.main : theme.palette.background.paper,
                                    borderRadius: '100px',
                                    border: step < 2 ? `1px solid ${theme.palette.orange.main}` : 'none',
                                    width: '24px',
                                    height: '24px'
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        fontFamily: 'Inter',
                                        color: step > 1 ? theme.palette.background.paper : theme.palette.orange.main,
                                        fontSize: '14px',
                                        m: '3px 0 0 7px'
                                    }}
                                >
                                    2
                                </Typography>
                            </Box>
                            <Box ml={2}>
                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontFamily: 'Inter',
                                        color: theme.palette.grey[600],
                                        fontSize: '10px',
                                        textTransform: 'uppercase',
                                        letterSpacing: '0.12em'
                                    }}
                                >
                                    {`${intl.formatMessage({ id: 'step' })} 2`}
                                </Typography>
                                <Typography
                                    mt={1}
                                    sx={{
                                        fontWeight: 500,
                                        fontFamily: 'Inter',
                                        color: theme.palette.grey[400],
                                        fontSize: '14px'
                                    }}
                                >
                                    <FormattedMessage id="confirm_access" />
                                </Typography>
                            </Box>
                        </Box>
                        <Divider
                            sx={{ background: theme.palette.grey[500], width: '36px', transform: 'rotate(90deg)', m: '20px 20px 0 -5px' }}
                        />
                        <Box mt={3} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box
                                sx={{
                                    background: step > 2 ? theme.palette.orange.main : theme.palette.background.paper,
                                    borderRadius: '100px',
                                    border: step < 3 ? `1px solid ${theme.palette.orange.main}` : 'none',
                                    width: '24px',
                                    height: '24px'
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        fontFamily: 'Inter',
                                        color: step > 2 ? theme.palette.background.paper : theme.palette.orange.main,
                                        fontSize: '14px',
                                        m: '3px 0 0 7px'
                                    }}
                                >
                                    3
                                </Typography>
                            </Box>
                            <Box ml={2}>
                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontFamily: 'Inter',
                                        color: theme.palette.grey[600],
                                        fontSize: '10px',
                                        textTransform: 'uppercase',
                                        letterSpacing: '0.12em'
                                    }}
                                >
                                    {`${intl.formatMessage({ id: 'step' })} 3`}
                                </Typography>
                                <Typography
                                    mt={1}
                                    sx={{
                                        fontWeight: 500,
                                        fontFamily: 'Inter',
                                        color: theme.palette.grey[400],
                                        fontSize: '14px'
                                    }}
                                >
                                    <FormattedMessage id="connected" />
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Typography
                        mb={3}
                        sx={{
                            fontWeight: 600,
                            fontFamily: 'Inter',
                            color: theme.palette.common.black,
                            fontSize: '14px'
                        }}
                    >
                        <FormattedMessage id="details_of_request" />
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontFamily: 'Inter',
                            color: theme.palette.grey[600],
                            fontSize: '10px',
                            textTransform: 'uppercase',
                            letterSpacing: '0.12em'
                        }}
                    >
                        <FormattedMessage id="facebook_business_name" />
                    </Typography>
                    <Typography
                        mt={2}
                        sx={{
                            fontWeight: 600,
                            fontFamily: 'Inter',
                            color: theme.palette.common.black,
                            fontSize: '14px'
                        }}
                    >
                        {context.agency_business?.name}
                    </Typography>
                    <Divider sx={{ background: theme.palette.grey[400], mt: '20px' }} />
                    <Typography
                        mt={3}
                        sx={{
                            fontWeight: 400,
                            fontFamily: 'Inter',
                            color: theme.palette.grey[600],
                            fontSize: '10px',
                            textTransform: 'uppercase',
                            letterSpacing: '0.12em'
                        }}
                    >
                        <FormattedMessage id="access_level" />
                    </Typography>
                    <Typography
                        mt={2}
                        sx={{
                            fontWeight: 600,
                            fontFamily: 'Inter',
                            color: theme.palette.common.black,
                            fontSize: '14px'
                        }}
                    >
                        Manage
                    </Typography>
                </Grid>
                <Grid item xs={12} md={2} sx={{ mt: { xs: '60px', md: 0 } }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', width: '250px' }}>
                        <Links url="#" title="privacy-policy" />
                        <Links url="#" title="terms_condition" />
                        <Links url="#" title="manage_cookies" />
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={9}>
                {renderStep()}
            </Grid>
        </Grid>
    );
}
