import { createContext, FC, useContext, useState } from 'react';
import { ContextDescription } from '../../../types/description';

interface DescriptionContextProps {
    description: ContextDescription;
    updateDescription: (newDescription: string) => void;
}

const DescriptionContext = createContext({} as DescriptionContextProps);

export const DescriptionProvider: FC<{ fetchedDescription: ContextDescription }> = ({ children, fetchedDescription }) => {
    const [description, setDescription] = useState(fetchedDescription);

    return (
        <DescriptionContext.Provider
            value={{
                description,
                updateDescription: setDescription
            }}
        >
            {children}
        </DescriptionContext.Provider>
    );
};

export const useDescription = () => useContext(DescriptionContext);
