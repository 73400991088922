import { gql } from '@apollo/client';
import { DOCUMENT_VERSION_FRAGMENT } from 'services/graphQL/fragments/document.fragment';

export const CREATE_WORKFLOW = gql`
    mutation CreateWorkflowAndDocument($client_organization_id: uuid, $name: String, $document_name: String) {
        insert_workflow_workflows_one(
            object: { client_organization_id: $client_organization_id, name: $name, document: { data: { name: $document_name } } }
        ) {
            id
            name
            organization_id
            status
            created_at
            client_organization_id
            document {
                id
                name
            }
        }
    }
`;

export const UPDATE_WORKFLOW_NAME = gql`
    mutation UpdateWorkflowName($id: uuid!, $name: String) {
        update_workflow_workflows_by_pk(pk_columns: { id: $id }, _set: { name: $name }) {
            id
            name
        }
    }
`;

export const CREATE_WORKFLOW_VERSION = gql`
    ${DOCUMENT_VERSION_FRAGMENT}
    mutation CreateWorkflowVersion($workflow_id: uuid, $document_id: uuid, $pages: workflow_pages_arr_rel_insert_input = { data: {} }) {
        insert_workflow_workflow_versions_one(
            object: { workflow_id: $workflow_id, document_version: { data: { document_id: $document_id, pages: $pages } } }
        ) {
            id
            creation_status
            reactflow_status
            document_version {
                ...DocumentVersionFragment
            }
        }
    }
`;

export const UPDATE_WORKFLOW_STATUS = gql`
    mutation UpdateWorkflowStatus($id: uuid!, $status: workflow_workflow_status_enum!) {
        update_workflow_workflows_by_pk(pk_columns: { id: $id }, _set: { status: $status }) {
            id
        }
    }
`;

export const UPDATE_REACTFLOW = gql`
    mutation UpdateWorkflowVersion($id: uuid!, $reactflow_json: jsonb) {
        update_workflow_workflow_versions_by_pk(pk_columns: { id: $id }, _set: { reactflow_json: $reactflow_json }) {
            id
            reactflow_json
        }
    }
`;
