import { gql } from '@apollo/client';

export const STORE_AGENCY_LOGIN_STATE = gql`
    mutation AddFacebookConnection(
        $access_token: String!
        $business_manager_id: String!
        $facebook_user_id: String!
        $business_id: String!
    ) {
        add_facebook_connection(
            arg1: {
                facebook_user_id: $facebook_user_id
                business_manager_id: $business_manager_id
                access_token: $access_token
                business_id: $business_id
            }
        ) {
            organization_id
            id
            fb_user_id
            business_manager_id
        }
    }
`;
export const DELETE_AGENCY_FACEBOOK_CONNECTION = gql`
    mutation DeleteFacebookConnection($organization_id: uuid!) {
        delete_organization_fb_connection(where: { organization_id: { _eq: $organization_id } }) {
            returning {
                id
                fb_user_id
                business_manager_id
                organization_id
            }
            affected_rows
        }
    }
`;
export const SET_META_DATA = gql`
    mutation SetUrl($client_organization_id: String!, $categories: [String]) {
        set_url_metadata(arg1: { client_organization_id: $client_organization_id, categories: $categories }) {
            scopes {
                default
                instagram
                ad
                domain
                lead
                page
            }
            aggregated
        }
    }
`;
export const ADD_FB_RESOURCE = gql`
    mutation AddFBResource(
        $client_organization_id: uuid!
        $fb_id: String!
        $fb_name: String!
        $resource_type: client_fb_resource_type_enum!
    ) {
        insert_client_fb_resources_one(
            object: { resource_type: $resource_type, fb_name: $fb_name, fb_id: $fb_id, client_organization_id: $client_organization_id }
            on_conflict: { constraint: client_fb_resources_pkey, update_columns: [fb_name, fb_id, status] }
        ) {
            status
            resource_type
            fb_name
            fb_id
            created_at
            connection_date
            client_organization_id
        }
    }
`;

export const ADD_CLIENT_FACEBOOK_CONNECTION = gql`
    mutation AddClientFacebookConnection(
        $access_token: String!
        $facebook_user_id: String!
        $business_id: String!
        $client_organization_id: String!
    ) {
        add_client_facebook_connection(
            arg1: {
                facebook_user_id: $facebook_user_id
                access_token: $access_token
                business_id: $business_id
                client_organization_id: $client_organization_id
            }
        ) {
            fb_user_id
            client_organization_id
            business_id
        }
    }
`;

export const RESET_FACEBOOK_CONNECTION = gql`
    mutation ResetFacebookConnection($client_organization_id: String!) {
        reset_client_facebook_connection(arg1: { client_organization_id: $client_organization_id }) {
            ok
        }
    }
`;
