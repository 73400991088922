import { Box, Typography, IconButton } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { FormType } from 'types/clients';

interface ServiceFormProps {
    form: FormType;
    onMenu: () => void;
}
export const ServiceForm = ({ form, onMenu }: ServiceFormProps) => (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ fontSize: 14, fontWeight: 500, my: 1, color: (theme) => theme.palette.grey['900'] }}>{form.name}</Typography>
            <Typography sx={{ fontSize: 12, fontWeight: 400 }}>{`Filled - QUESTION ${form.answered}/${form.questions}`}</Typography>
        </Box>
        <IconButton onClick={onMenu}>
            <MoreHorizIcon />
        </IconButton>
    </Box>
);
