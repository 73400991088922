import { Box, Button, Checkbox, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { ReactComponent as ChevronDownIcon } from 'assets/images/chevron-down.svg';
import Field from '../fields';
import { WFTaskDocumentPermissions } from '@sni4/snikpic-common';

interface SectionDropdownProps {
    section: any;
    documentPermissions: WFTaskDocumentPermissions[];
    setDocumentPermissions: (documentPermissions: WFTaskDocumentPermissions[]) => void;
}

const SectionDropdown = ({ section, documentPermissions, setDocumentPermissions }: SectionDropdownProps) => {
    const initialSectionChecked = section.sectionFields.some(
        (field: any) => field.fieldId === documentPermissions.find((el) => el.field_id === field.fieldId)?.field_id
    );

    const [sectionChecked, setSectionChecked] = useState(initialSectionChecked);

    const handleCheck = () => {
        if (!sectionChecked) {
            setSectionChecked(true);
            section.sectionFields.forEach((el: any) => console.log('el', el));
            setDocumentPermissions([
                ...documentPermissions,
                ...section.sectionFields.map((field: any) => ({
                    field_id: `${field.fieldId}`,
                    type: 'Read',
                    is_required: false
                }))
            ]);
        } else {
            setSectionChecked(false);
            setDocumentPermissions([
                ...documentPermissions.filter((el) => !section.sectionFields.find((field: any) => el.field_id === `${field.fieldId}`))
            ]);
        }
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '48px',
                    backgroundColor: '#EFF2FA',
                    borderColor: '#D4DBEA',
                    cursor: 'pointer'
                }}
                onClick={(e) => {
                    handleCheck();
                }}
            >
                <Checkbox checked={sectionChecked} />
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 16px', width: '100%' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {/* <Typography sx={{ fontWeight: 600, fontSize: '14px', color: '#677487' }}> ↳ </Typography> */}
                        <Typography sx={{ fontWeight: 600, fontSize: '14px', color: '#393D4E' }}>{section.sectionName}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ fontWeight: 600, fontSize: '12px' }}>{section.fields?.length} Fields</Typography>
                        <ChevronDownIcon />
                    </Box>
                </Box>
            </Box>
            {sectionChecked && (
                <Box sx={{ padding: '8px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '16px' }}>
                        <Switch />
                        <Typography sx={{ fontWeight: 500, fontSize: '12px' }}>Can Comment</Typography>
                    </Box> */}
                    {section.sectionFields.map((field: any, index: number) => (
                        <Field
                            key={field.fieldId}
                            field={field}
                            index={index}
                            documentPermissions={documentPermissions}
                            setDocumentPermissions={setDocumentPermissions}
                        />
                    ))}
                </Box>
            )}
        </>
    );
};

export default SectionDropdown;
