import {
    Box,
    Button,
    DialogContent,
    Divider,
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import copy from 'copy-to-clipboard';

import { ColumnBodyOne, NextButton, RowBody } from '../../../document-builder/ui';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import { ReactComponent as CopyIcon } from 'assets/images/copy.svg';
import { ReactComponent as AlertIcon } from 'assets/images/alert.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/images/chevron-down.svg';
import ChevronUpIcon from '@mui/icons-material/ExpandLessOutlined';
import { useContext, useState } from 'react';
import DialogFooter from '../dialogFooter_deprecated';
import DocumentBuilderContext from '../../../document-builder/context/workflow.context';

interface DialogWebhookProps {
    data: any;
    onClose?: () => void;
    onClick?: () => void;
}

const DialogWebhook = ({ data, onClose, onClick }: DialogWebhookProps) => {
    const theme = useTheme();
    const { documentBuilder } = useContext(DocumentBuilderContext);
    const [arrNodes, setArrNodes] = useState(documentBuilder.workflow.flowNodes);

    const [copyText, setCopyText] = useState<string>('');

    const handleTextFieldOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCopyText(event.target.value);
    };

    const handleCopyText = () => {
        copy(copyText);
    };

    const [selectInputValue, setSelectInputValue] = useState<string>(
        data.nData !== undefined && data.nData.input !== undefined ? data.nData.input : 'none'
    );

    const handleSelectInputValueChange = (event: SelectChangeEvent) => {
        setSelectInputValue(event.target.value as string);
    };

    const [selectWebhookValue, setSelectWebhookValue] = useState<string>(
        data.nData !== undefined && data.nData.webhook !== undefined ? data.nData.webhook : 'none'
    );

    const handleSelectWebhookValueChange = (event: SelectChangeEvent) => {
        setSelectWebhookValue(event.target.value as string);
    };

    const [selectBodyValue, setSelectBodyValue] = useState<string>(
        data.nData !== undefined && data.nData.body !== undefined ? data.nData.body : 'none'
    );

    const handleSelectBodyValueChange = (event: SelectChangeEvent) => {
        setSelectBodyValue(event.target.value as string);
    };

    return (
        <>
            <DialogContent style={{ width: '562px', padding: '20px 32px', overflowY: 'auto' }}>
                <ColumnBodyOne sx={{ gap: '21px' }}>
                    <RowBody sx={{ justifyContent: 'space-between' }}>
                        <Typography variant="h4" sx={{ color: theme.palette.text.primary }}>
                            Webhook Configuration
                        </Typography>
                        <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
                    </RowBody>
                    <Typography sx={{ fontWeight: 600, fontSize: '14px', color: theme.palette.text.primary }}>Setup</Typography>
                    <ColumnBodyOne>
                        <Typography variant="h5" sx={{ color: theme.palette.text.primary }}>
                            1. Send an example webhook to:
                        </Typography>
                        <RowBody sx={{ height: '48px', gap: '8px' }}>
                            <TextField
                                sx={{ flex: 1 }}
                                onChange={handleTextFieldOnChange}
                                value={copyText}
                                placeholder="https://www.figma.com/proto/2138&scaling=min -zoom&starting-point-node-id=81%3A2730&hide-ui=1"
                            />
                            <Button
                                variant="outlined"
                                startIcon={<CopyIcon style={{ width: '24px', height: '24px' }} />}
                                sx={{
                                    width: '117px',
                                    height: '48px',
                                    borderRadius: '10px',
                                    border: `1px solid ${theme.palette.grey[100]}`,
                                    backgroundColor: theme.palette.grey[100],
                                    'svg path': {
                                        stroke: '#EE9A8F'
                                    }
                                }}
                                onClick={() => handleCopyText()}
                            >
                                Copy
                            </Button>
                        </RowBody>
                        <Typography variant="subtitle2" sx={{ color: theme.palette.text.primary }}>
                            If the request is successful, you should receive a response of
                            <br />
                            (success: true)
                        </Typography>
                        <Typography variant="subtitle2" sx={{ color: theme.palette.text.primary }}>
                            If the automation is off, a test will automatically start for this trigger
                            <br />
                            If the automation is on, please manually test the trigger again.
                        </Typography>
                    </ColumnBodyOne>
                    <ColumnBodyOne sx={{ marginTop: '11px' }}>
                        <Typography variant="subtitle1" sx={{ color: theme.palette.text.primary }}>
                            2. Verify successful test
                        </Typography>
                        <Typography variant="subtitle2" sx={{ color: theme.palette.text.primary }}>
                            If Snikpic has received a valid example webhook, the test will pass. You`&#39;`ll then be able to use the data
                            sent in the example webhook in the rest of the automation.
                        </Typography>
                        <Box sx={{ borderRadius: '8px', padding: '16px', gap: '8px', backgroundColor: '#EFF2FA', display: 'flex' }}>
                            <AlertIcon />
                            <Typography variant="h6" sx={{ fontWeight: 400 }}>
                                Remember that anyone who has the URL for your webhook trigger can trigger your automation. We recommend
                                thinking carefully about how and where the URL might to shared
                            </Typography>
                        </Box>
                    </ColumnBodyOne>
                    <Typography variant="h5" sx={{ color: theme.palette.text.primary }}>
                        Test Step
                    </Typography>
                    <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
                        Test this trigger to confirm its configuration is correct. The data from this test can be used in later steps.
                    </Typography>
                    <NextButton sx={{ width: '118px', height: '42px', fontSize: '14px', fontWeight: 500 }}>Test trigger</NextButton>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600, color: theme.palette.text.primary }}>
                        Results
                    </Typography>
                    <ColumnBodyOne>
                        <Typography variant="body1" sx={{ color: '#0D982B' }}>
                            Step successful
                        </Typography>
                        <ColumnBodyOne sx={{ gap: '8px' }}>
                            <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
                                Step run 2 days ago
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    value={selectInputValue}
                                    onChange={handleSelectInputValueChange}
                                    IconComponent={ChevronDownIcon}
                                    sx={{ '& .MuiSelect-select': { fontWeight: 400, backgroundColor: 'white' } }}
                                >
                                    <MenuItem value="none">Input</MenuItem>
                                </Select>
                            </FormControl>
                        </ColumnBodyOne>
                    </ColumnBodyOne>
                    <ColumnBodyOne sx={{ gap: '8px' }}>
                        <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
                            Found 1 webhook
                        </Typography>
                        <FormControl fullWidth>
                            <Select
                                value={selectWebhookValue}
                                onChange={handleSelectWebhookValueChange}
                                IconComponent={ChevronDownIcon}
                                sx={{ '& .MuiSelect-select': { fontWeight: 400, backgroundColor: 'white' } }}
                            >
                                <MenuItem value="none">Webhook</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <Select
                                value={selectBodyValue}
                                onChange={handleSelectBodyValueChange}
                                IconComponent={ChevronUpIcon}
                                sx={{ '& .MuiSelect-select': { fontWeight: 400, backgroundColor: 'white' } }}
                            >
                                <MenuItem value="none">body</MenuItem>
                            </Select>
                        </FormControl>
                    </ColumnBodyOne>
                </ColumnBodyOne>
            </DialogContent>
            <Divider />
            <DialogFooter onClose={onClose} arrNodes={arrNodes} />
        </>
    );
};

export default DialogWebhook;
