import { Box, Drawer, Typography, Grid, Avatar, TextField, MenuItem, Select, CircularProgress, CardMedia } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import ButtonCustom from 'ui-component/extended/Button';
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SelectorAutocomplite from 'ui-component/SelectorAutoComplite';
import DrugAndDropFiles from 'ui-component/dragAndDropFiles';
import TextEditor from 'ui-component/textEditor';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GENERATE_ASSET_URL, GET_CLIENTS, GET_PROJECTS_FOR_CLIENT } from 'services/graphQL';
import mime from 'mime';
import useAuth from 'hooks/useAuth';
import { GET_MEMBERS_AND_CLIENTS } from 'services/graphQL/queries/teams';
import { createStandaloneTask, updateStandaloneTask } from 'services/rest/workflow';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment';
import calendar from 'assets/images/calendar.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { uploadFile } from 'services/rest';
import { LoadingModal } from 'ui-component/LoadingModal';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

interface DrawerAddOffersProps {
    handleClickCloseDivider: any;
    selectedTask?: any;
    refresh: any;
    isOnEditMode?: boolean;
}
interface LabelType {
    title: string;
    src?: string;
}
const AddOrEditTask = ({ handleClickCloseDivider, selectedTask, refresh, isOnEditMode = false }: DrawerAddOffersProps) => {
    const theme = useTheme();
    const intl = useIntl();

    const { member, user } = useAuth();

    const organizationId = member?.organizationId ?? '';

    const [files, setFiles] = React.useState<any>([]);
    const [clientsAssigned, setClientsAssigned] = React.useState<any[]>([]);
    const [pendingValue, setPendingValue] = React.useState<LabelType[]>([]);
    const [pendingValueAssigned, setPendingValueAssigned] = React.useState<LabelType[]>([]);
    const [datePickerValue, setDatePickerValue] = React.useState<Date>(new Date(Date.now() + 3600 * 1000));
    const [open, setOpen] = React.useState(false);

    const [clientsLabels, setClientsLabels] = React.useState<any>([]);
    const [selectedClients, setSelectedClients] = React.useState<any[]>([]);
    const [projects, setProjects] = React.useState<any[]>([]);
    const [teamMembers, setTeamMembers] = React.useState<any>([]);

    const [author, setAuthor] = React.useState<any>(user);

    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const handleOpenkDatePicker = () => setOpen(true);
    const handleCloseDatePicker = () => setOpen(false);
    const hanleDatePickerValue = (value: any) => {
        setDatePickerValue(value);
    };

    const [generateURL] = useMutation(GENERATE_ASSET_URL);

    const [getProjects] = useLazyQuery(GET_PROJECTS_FOR_CLIENT, {
        onCompleted: (data) => {
            setProjects(data.projects);
        }
    });

    const [fetchTeamMembersAndClients] = useLazyQuery(GET_MEMBERS_AND_CLIENTS, {
        onCompleted: (data) => {
            setTeamMembers(data.organizations[0]?.members);
        }
    });

    const [getClients, { loading: loadingClients }] = useLazyQuery(GET_CLIENTS, {
        onCompleted: (data) => {
            setClientsLabels(data.client_organizations);
        },
        onError: (error) => {
            console.log(error);
        }
    });

    React.useEffect(() => {
        getClients({ variables: { organizationId } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        fetchTeamMembersAndClients({ variables: { clientOrganizationId: selectedClients[0]?.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedClients]);

    React.useEffect(() => {
        if (selectedTask) {
            formik.setFieldValue('title', selectedTask.title);
            setSelectedClients([selectedTask.client_organization]);
            formik.setFieldValue('providedService', selectedTask.project_id);
            formik.setFieldValue('description', selectedTask.description);
            setAuthor(selectedTask.user);
            const assignee = teamMembers.find((member: any) => member.user_id === selectedTask.user_id);
            setClientsAssigned([assignee]);
            setFiles(selectedTask?.attachments?.map((attachment: any) => ({ ...attachment, preview: attachment.url })));
            if (selectedTask.status !== 'ACTIVE') formik.setFieldValue('status', selectedTask.status);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTask]);

    React.useEffect(() => {
        console.log('in use effect to get projects', selectedClients);
        if (selectedClients) {
            getProjects({ variables: { organizationId, clientId: selectedClients[0]?.id } });
        }
    }, [selectedClients]);

    const formik = useFormik({
        initialValues: {
            title: '',
            description: '',
            providedService: ''
        },
        validationSchema: Yup.object({
            title: Yup.string().required('Required'),
            description: Yup.string().required('Required'),
            providedService: Yup.string().required('Required')
        }),
        onSubmit: async (values) => {
            setIsSubmitting(true);
            try {
                const attachments = await Promise.all(
                    files.map(async (file: any) => {
                        if (file?.url) return file;

                        const filePreviewSplit = file.preview.split('/');
                        const fileName = `${filePreviewSplit[filePreviewSplit.length - 1]}.${mime.getExtension(file.type)}`;

                        const {
                            data: {
                                generate_upload_url: { url }
                            }
                        } = await generateURL({
                            variables: { path: `${organizationId}/tasks/${fileName}` }
                        });

                        await uploadFile(url, file);
                        return {
                            name: file.name,
                            url: url.substring(0, url.indexOf('?'))
                        };
                    })
                );

                if (isOnEditMode) {
                    await updateStandaloneTask({
                        id: selectedTask?.id,
                        status: selectedTask?.status,
                        type: selectedTask?.type,
                        organizationId,
                        clientOrganizationId: selectedClients[0]?.id,
                        projectId: values.providedService,
                        userId: clientsAssigned[0]?.user_id,
                        title: values.title,
                        description: values.description,
                        attachments,
                        dueDate: datePickerValue
                    });
                } else {
                    await createStandaloneTask({
                        organizationId,
                        clientOrganizationId: selectedClients[0]?.id,
                        projectId: values.providedService,
                        userId: clientsAssigned[0]?.user_id,
                        title: values.title,
                        description: values.description,
                        attachments,
                        dueDate: datePickerValue
                    });
                }

                refresh();
                handleClickCloseDivider();
            } catch {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: intl.formatMessage({ id: 'unable_to_create_or_edit_the_task' }),
                        variant: 'error'
                    })
                );
            } finally {
                setIsSubmitting(false);
            }
        }
    });
    const handleDescription = (value: any) => {
        formik.setFieldValue('description', value);
    };

    const deleteImg = (image: string) => {
        const arrFiles = files;
        const newArr: any = [];
        arrFiles.forEach((element: any) => {
            if (element.preview !== image) newArr.push(element);
        });
        setFiles([...newArr]);
    };

    return (
        <Box sx={{ width: { xs: '100%', sm: '562px' } }}>
            <form onSubmit={formik.handleSubmit}>
                <Grid container direction="column" justifyContent="space-between" sx={{ minHeight: '100vh' }}>
                    <Grid p={3} item>
                        <ArrowBackIcon
                            onClick={handleClickCloseDivider}
                            sx={{ display: { xs: 'block', sm: 'none' }, fill: '#000', fontSize: '30px', mb: '20px' }}
                        />
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item xs={8}>
                                {selectedTask ? (
                                    <Typography
                                        sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 700, fontSize: '16px' }}
                                    >
                                        <FormattedMessage id="standalone_task" />
                                    </Typography>
                                ) : (
                                    <Typography
                                        sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 700, fontSize: '16px' }}
                                    >
                                        <FormattedMessage id="add_task" />
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Box mt={1} mb={4} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Box sx={{ p: '0' }}>
                                        <Box
                                            sx={{
                                                background: theme.palette.grey[700],
                                                display: 'flex',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                                borderRadius: '8px',
                                                p: '8px'
                                            }}
                                            onClick={handleOpenkDatePicker}
                                        >
                                            <Box sx={{ width: '20px' }}>
                                                <CardMedia
                                                    sx={{ objectFit: 'contain' }}
                                                    component="img"
                                                    width="18px"
                                                    image={calendar}
                                                    alt="alt image"
                                                />
                                            </Box>
                                            <Typography
                                                ml={1}
                                                sx={{
                                                    color: theme.palette.grey[400],
                                                    fontFamily: 'Inter',
                                                    fontWeight: 400,
                                                    fontSize: '14px'
                                                }}
                                            >
                                                {`Due Date - ${moment(datePickerValue).format('DD MMMM, YYYY @ h:mm A')}`}
                                            </Typography>
                                            <KeyboardArrowDownIcon sx={{ fontSize: '25px', fill: theme.palette.grey[400], ml: '8px' }} />
                                        </Box>
                                        <Box>
                                            {' '}
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DateTimePicker
                                                    value={datePickerValue}
                                                    open={open}
                                                    onClose={handleCloseDatePicker}
                                                    onChange={(newValue) => {
                                                        hanleDatePickerValue(newValue);
                                                    }}
                                                    renderInput={({ inputRef, inputProps, InputProps }) => <Box ref={inputRef}> </Box>}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <TextField
                            sx={{
                                '.MuiOutlinedInput-input': {
                                    fontWeight: 400,
                                    fontFamily: 'Inter',
                                    fontSize: '14px'
                                }
                            }}
                            id="title"
                            name="title"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            variant="outlined"
                            fullWidth
                            placeholder={intl.formatMessage({ id: 'titleHeader' })}
                            error={formik.touched.title && Boolean(formik.errors.title)}
                            helperText={formik.touched.title && formik.errors.title}
                        />
                        <Box mt={3} mb={3}>
                            {loadingClients ? (
                                <CircularProgress />
                            ) : (
                                <SelectorAutocomplite
                                    autocompleteItems={clientsLabels}
                                    pendingValue={pendingValue}
                                    setPendingValue={setPendingValue}
                                    arrItemSelected={selectedClients}
                                    setArrItemsSelected={setSelectedClients}
                                    title="client"
                                    imagePath="logo_url"
                                    labelPath="name"
                                    singleSelect
                                />
                            )}
                        </Box>
                        {selectedClients[0] && (
                            <Box>
                                <Select
                                    disabled={projects.length === 0}
                                    placeholder={intl.formatMessage({ id: 'provided_service' })}
                                    sx={{
                                        '.MuiOutlinedInput-input': {
                                            color: theme.palette.grey[300],
                                            fontWeight: 400,
                                            fontFamily: 'Inter',
                                            fontSize: '14px'
                                        }
                                    }}
                                    fullWidth
                                    value={formik.values.providedService}
                                    onChange={(e) => formik.setFieldValue('providedService', e.target.value)}
                                >
                                    {projects.map((project, index) => (
                                        <MenuItem
                                            key={index}
                                            sx={{
                                                fontFamily: 'Inter',
                                                fontSize: '14px',
                                                borderBottom: `1px solid ${theme.palette.grey[500]}`,
                                                p: '14px 16px'
                                            }}
                                            value={project.id}
                                        >
                                            {project.name}
                                        </MenuItem>
                                    ))}
                                </Select>

                                <Grid container mt={3}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            mb={2}
                                            sx={{
                                                color: theme.palette.grey[400],
                                                fontFamily: 'Inter',
                                                fontWeight: 600,
                                                fontSize: '14px'
                                            }}
                                        >
                                            <FormattedMessage id="owner" />
                                        </Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Avatar sx={{ width: '32px', height: '32px' }} alt="Remy Sharp" src={author?.image_url} />
                                            <Typography
                                                ml={1}
                                                sx={{
                                                    color: theme.palette.grey[400],
                                                    fontFamily: 'Inter',
                                                    fontWeight: 500,
                                                    fontSize: '14px'
                                                }}
                                            >
                                                {`${author.first_name} ${author.last_name}`}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SelectorAutocomplite
                                            arrItemSelected={clientsAssigned}
                                            setArrItemsSelected={setClientsAssigned}
                                            autocompleteItems={teamMembers}
                                            pendingValue={pendingValueAssigned}
                                            setPendingValue={setPendingValueAssigned}
                                            title="task_assigned_to"
                                            imagePath="user.image_url"
                                            labelPath="user.first_name"
                                            singleSelect
                                        />
                                    </Grid>
                                </Grid>

                                <Typography
                                    mt={3}
                                    mb={2}
                                    sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600, fontSize: '14px' }}
                                >
                                    <FormattedMessage id="attachments" />
                                </Typography>
                                <DrugAndDropFiles files={files} setFiles={setFiles} deleteImg={deleteImg} />
                                <Box
                                    mt={3}
                                    sx={{
                                        borderRadius: '10px',
                                        border: `1px solid ${theme.palette.grey[500]}`,
                                        minHeight: { xs: '250px', sm: '300px' }
                                    }}
                                >
                                    <TextEditor
                                        value={formik.values.description}
                                        setValue={handleDescription}
                                        placeholder="what_is_this_channel_about"
                                    />
                                </Box>
                            </Box>
                        )}
                    </Grid>
                    <Grid
                        item
                        container
                        direction="column"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        sx={{ borderTop: `1px solid ${theme.palette.grey[500]}` }}
                    >
                        <Grid item p={2}>
                            <Box sx={{ display: 'flex' }}>
                                <Box mr={2} sx={{ width: '87px' }}>
                                    <ButtonCustom
                                        onClick={handleClickCloseDivider}
                                        colorBtn="white"
                                        titleBtn={<FormattedMessage id="close" />}
                                    />
                                </Box>
                                <Box mr={2} sx={{ width: '130px' }}>
                                    <ButtonCustom
                                        onClick={formik.handleSubmit}
                                        colorBtn="red"
                                        titleBtn={
                                            isOnEditMode ? <FormattedMessage id="edit_task" /> : <FormattedMessage id="create_task" />
                                        }
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            <LoadingModal open={isSubmitting} />
        </Box>
    );
};

export default React.memo(AddOrEditTask);
