import React, { useCallback } from 'react';
import { logChatPromiseExecution, MessageResponse } from 'stream-chat';
import {
    defaultPinPermissions,
    MessageInput,
    MessageList,
    MessageToSend,
    PinEnabledUserRoles,
    Thread,
    useChannelActionContext,
    Window
} from 'stream-chat-react';

import { PinnedMessageList } from '../PinnedMessageList/PinnedMessageList';
import { TeamChannelHeader } from '../TeamChannelHeader/TeamChannelHeader';
import { ThreadMessageInput } from '../TeamMessageInput/ThreadMessageInput';

import { useGiphyInMessageContext } from '../../context/GiphyInMessageFlagContext';

import type { StreamChatType } from '../../types';

import { useChatContext } from 'stream-chat-react';

// import axios from 'axios';
// import { getPutSignedUrl } from 'services/rest/aws_s3';
// import { v4 as uuid } from 'uuid';

import { handleFileUpload } from 'utils/chat';

import { Button, Tooltip } from '@mui/material';
import { resetSession } from '../../../../../services/rest/chat-bot';
// import { resetSession, updateChannelState } from '../../../../../services/rest/chat-bot';

import { useSnackbar } from 'utils/snackbar.utils';

import { useIntl } from 'react-intl';
import { VirtualEmployeeToolbar } from './VirtualEmployeeToolbar';

export const ChannelInner = () => {
    const { inputHasGiphyMessage, clearGiphyFlag } = useGiphyInMessageContext();
    const { sendMessage } = useChannelActionContext<StreamChatType>();

    const { channel: activeChannel } = useChatContext<StreamChatType>() as any; // Max 2023-03-16: added 'as any' to be able to read .custom_data (to discuss with Anouar how to do that properly)
    // const { channel: activeChannel } = useChatContext<any>() as any;

    const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
    const { formatMessage } = useIntl();

    // todo: migrate to channel capabilities once migration guide is available
    const teamPermissions: PinEnabledUserRoles = { ...defaultPinPermissions.team, user: true };
    const messagingPermissions: PinEnabledUserRoles = {
        ...defaultPinPermissions.messaging,
        user: true
    };

    const pinnedPermissions = {
        ...defaultPinPermissions,
        team: teamPermissions,
        messaging: messagingPermissions
    };

    const overrideSubmitHandler = useCallback(
        (message: MessageToSend) => {
            let updatedMessage = {
                attachments: message.attachments,
                mentioned_users: message.mentioned_users,
                parent_id: message.parent?.id,
                parent: message.parent as MessageResponse,
                text: message.text
            };

            const isReply = !!updatedMessage.parent_id;

            if (inputHasGiphyMessage(isReply)) {
                const updatedText = `/giphy ${message.text}`;
                updatedMessage = { ...updatedMessage, text: updatedText };
            }

            if (sendMessage) {
                const sendMessagePromise = sendMessage(updatedMessage);
                logChatPromiseExecution(sendMessagePromise, 'send message');
                clearGiphyFlag(isReply);
            }
        },
        [inputHasGiphyMessage, sendMessage, clearGiphyFlag]
    );

    const resetChatbotSession = async () => {
        if (activeChannel) {
            try {
                await resetSession({ channelId: activeChannel.id || '' });
                showSuccessSnackbar({ message: 'chatbot_session_reset_success' });
            } catch (e) {
                showErrorSnackbar({ message: 'chatbot_session_reset_error' });
            }
        }
    };

    // const handleSelectBrand = async () => {
    //     if (activeChannel) {
    //         try {
    //             // await resetSession({ channelId: activeChannel.id || '' });
    //             await updateChannelState({ channelId: activeChannel.id || '', brandId: '44d41f13-e287-43e0-abd2-5a755082ec4f' }); // TODO Max P1: make brandId variable
    //             showSuccessSnackbar({ message: 'chatbot_select_brand_success' });
    //         } catch (e) {
    //             showErrorSnackbar({ message: 'chatbot_select_brand_error' });
    //         }
    //     }
    // };

    // const handleSelectTask = async () => {
    //     if (activeChannel) {
    //         try {
    //             await updateChannelState({ channelId: activeChannel.id || '', taskId: 'a14601a2-93a6-4275-b6a0-b5875157fa6b' }); // TODO Max P1: make taskId variable
    //             showSuccessSnackbar({ message: 'chatbot_select_task_success' });
    //         } catch (e) {
    //             showErrorSnackbar({ message: 'chatbot_select_task_error' });
    //         }
    //     }
    // };

    // console.log('activeChannel: ', activeChannel);
    return (
        <>
            <Window>
                <TeamChannelHeader />
                {activeChannel && activeChannel.data?.custom_data?.chat_bot?.is_chat_bot_channel && (
                    <Tooltip title={formatMessage({ id: 'reset_chatbot_session_tooltip' })}>
                        <Button variant="outlined" sx={{ m: 2 }} onClick={resetChatbotSession}>
                            {formatMessage({ id: 'reset_chatbot_session' })}
                        </Button>
                    </Tooltip>
                )}
                <MessageList disableQuotedMessages pinPermissions={pinnedPermissions} />
                {activeChannel && activeChannel.data?.custom_data?.chat_bot?.is_chat_bot_channel && (
                    <>
                        <VirtualEmployeeToolbar
                            activeChannelId={activeChannel.id}
                            currentBrandId={activeChannel.data?.custom_data?.chat_bot?.current_brand_id}
                            currentTaskId={activeChannel.data?.custom_data?.chat_bot?.current_task_id}
                            currentVirtualEmployeeId={activeChannel.data?.custom_data?.chat_bot?.current_virtual_employee_id}
                        />
                        {/* <Tooltip title={formatMessage({ id: 'select_brand_tooltip' })}>
                            <Button variant="outlined" sx={{ m: 2 }} onClick={handleSelectBrand}>
                                {formatMessage({ id: 'select_brand' })}
                            </Button>
                        </Tooltip>
                        <Tooltip title={formatMessage({ id: 'select_task_tooltip' })}>
                            <Button variant="outlined" sx={{ m: 2 }} onClick={handleSelectTask}>
                                {formatMessage({ id: 'select_task' })}
                            </Button>
                        </Tooltip> */}
                    </>
                )}
                <MessageInput
                    grow
                    overrideSubmitHandler={overrideSubmitHandler}
                    doFileUploadRequest={async (file, channel) => {
                        // console.log(' In doFileUploadRequest');
                        // console.log('file', file);
                        const { fileUrl } = await handleFileUpload(file, file.type);
                        return { duration: 'request_duration_placeholder', file: fileUrl, thumb_url: fileUrl }; // TO DO P3 - Handle duration which is a placeholder now
                    }}
                    doImageUploadRequest={async (file, channel) => {
                        // console.log(' In doImageUploadRequest');
                        // console.log('file', file);
                        const { fileUrl } = await handleFileUpload(file, file.type);
                        return { duration: 'request_duration_placeholder', file: fileUrl, thumb_url: fileUrl }; // TO DO P3 - Handle duration which is a placeholder now
                    }}
                />
            </Window>
            {/* <Thread additionalMessageInputProps={{ grow: true, Input: ThreadMessageInput, overrideSubmitHandler }} /> */}
            <PinnedMessageList />
        </>
    );
};
