import { Box, Typography, CardMedia, Dialog, Drawer, TextField, Grid, InputAdornment, Input, Switch } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';
import download from 'assets/images/download.svg';
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextEditor from 'ui-component/textEditor';
import EuroIcon from '@mui/icons-material/Euro';
import { Button } from 'ui-component/extended/Button';

interface DIalogCreatePackageProps {
    onClose: any;
}

const DIalogCreatePackage = ({ onClose }: DIalogCreatePackageProps) => {
    const theme = useTheme();
    const intl = useIntl();
    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
            taskName: '',
            descriptionTask: '',
            volume: '',
            rate: '',
            workloadTime: '',
            minVolume: '1',
            switch: false
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Required'),
            description: Yup.string().required('Required'),
            taskName: Yup.string().required('Required'),
            descriptionTask: Yup.string().required('Required'),
            volume: Yup.string().required('Required'),
            rate: Yup.string().required('Required'),
            workloadTime: Yup.string().required('Required'),
            minVolume: Yup.string().required('Required')
        }),
        onSubmit: (values) => {
            console.log(values);
        }
    });
    const handleDescription = (value: any) => formik.setFieldValue('description', value);
    const handleSwitch = (e: any) => formik.setFieldValue('switch', e.target.checked);
    return (
        <Box sx={{ diplay: 'flex', height: '100vh', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box sx={{ p: '30px', height: 'calc(100vh - 107px)', overflowY: 'auto' }}>
                <form onSubmit={formik.handleSubmit}>
                    <Typography
                        sx={{
                            color: '#2D2D2D',
                            fontFamily: 'Inter',
                            fontWeight: 600,
                            fontSize: '18px',
                            mb: 2
                        }}
                    >
                        <FormattedMessage id="create_package" />
                    </Typography>
                    <Typography
                        sx={{
                            color: theme.palette.grey[400],
                            fontFamily: 'Inter',
                            fontWeight: 600,
                            fontSize: '14px',
                            mt: 3,
                            mb: '4px'
                        }}
                    >
                        <FormattedMessage id="name_of_package" />
                    </Typography>
                    <TextField
                        sx={{
                            '.MuiOutlinedInput-input': {
                                fontWeight: 400,
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                background: '#fff'
                            }
                        }}
                        id="name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        variant="outlined"
                        fullWidth
                        placeholder={intl.formatMessage({ id: 'name_of_package' })}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                    <Typography
                        sx={{
                            color: theme.palette.grey[400],
                            fontFamily: 'Inter',
                            fontWeight: 600,
                            fontSize: '14px',
                            mt: 3,
                            mb: '4px'
                        }}
                    >
                        <FormattedMessage id="description" />
                    </Typography>
                    <Box
                        sx={{
                            borderRadius: '10px',
                            border: `1px solid ${theme.palette.grey[500]}`,
                            minHeight: { xs: '350px', sm: '400px' }
                        }}
                    >
                        <TextEditor value={formik.values.description} setValue={handleDescription} placeholder="description" />
                    </Box>
                    <Typography
                        sx={{
                            color: theme.palette.grey[400],
                            fontFamily: 'Inter',
                            fontWeight: 600,
                            fontSize: '14px',
                            mt: 3,
                            mb: '4px'
                        }}
                    >
                        <FormattedMessage id="name_of_task" />
                    </Typography>
                    <TextField
                        sx={{
                            '.MuiOutlinedInput-input': {
                                fontWeight: 400,
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                background: '#fff'
                            }
                        }}
                        id="taskName"
                        name="taskName"
                        value={formik.values.taskName}
                        onChange={formik.handleChange}
                        variant="outlined"
                        fullWidth
                        placeholder={intl.formatMessage({ id: 'story_highlights' })}
                        error={formik.touched.taskName && Boolean(formik.errors.taskName)}
                        helperText={formik.touched.taskName && formik.errors.taskName}
                    />
                    <Typography
                        sx={{
                            color: theme.palette.grey[400],
                            fontFamily: 'Inter',
                            fontWeight: 600,
                            fontSize: '14px',
                            mt: 3,
                            mb: '4px'
                        }}
                    >
                        <FormattedMessage id="description" />
                    </Typography>
                    <TextField
                        sx={{
                            '.MuiOutlinedInput-input': {
                                fontWeight: 400,
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                background: '#fff'
                            },
                            mb: 3
                        }}
                        id="descriptionTask"
                        name="descriptionTask"
                        value={formik.values.descriptionTask}
                        onChange={formik.handleChange}
                        variant="outlined"
                        fullWidth
                        multiline
                        minRows={6}
                        maxRows={6}
                        placeholder={intl.formatMessage({ id: 'story_highlights' })}
                        error={formik.touched.descriptionTask && Boolean(formik.errors.descriptionTask)}
                        helperText={formik.touched.descriptionTask && formik.errors.descriptionTask}
                    />
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <Typography
                                sx={{
                                    color: theme.palette.grey[400],
                                    fontFamily: 'Inter',
                                    fontWeight: 600,
                                    fontSize: '14px',
                                    mb: '4px'
                                }}
                            >
                                <FormattedMessage id="volume" />
                            </Typography>
                            <TextField
                                sx={{
                                    '.MuiOutlinedInput-input': {
                                        fontWeight: 400,
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        background: '#fff'
                                    }
                                }}
                                id="volume"
                                name="volume"
                                value={formik.values.volume}
                                onChange={formik.handleChange}
                                variant="outlined"
                                fullWidth
                                error={formik.touched.volume && Boolean(formik.errors.volume)}
                                helperText={formik.touched.volume && formik.errors.volume}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography
                                sx={{
                                    color: theme.palette.grey[400],
                                    fontFamily: 'Inter',
                                    fontWeight: 600,
                                    fontSize: '14px',
                                    mb: '4px'
                                }}
                            >
                                <FormattedMessage id="rate" />
                            </Typography>
                            <TextField
                                sx={{
                                    '.MuiOutlinedInput-input': {
                                        fontWeight: 400,
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        background: '#fff',
                                        ml: '-10px'
                                    }
                                }}
                                id="rate"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EuroIcon sx={{ fontSize: '15px' }} />
                                        </InputAdornment>
                                    )
                                }}
                                name="rate"
                                value={formik.values.rate}
                                onChange={formik.handleChange}
                                variant="outlined"
                                fullWidth
                                error={formik.touched.rate && Boolean(formik.errors.rate)}
                                helperText={formik.touched.rate && formik.errors.rate}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography
                                sx={{
                                    color: theme.palette.grey[400],
                                    fontFamily: 'Inter',
                                    fontWeight: 600,
                                    fontSize: '14px',
                                    mb: '4px'
                                }}
                            >
                                <FormattedMessage id="workload_time" />
                            </Typography>
                            <TextField
                                sx={{
                                    '.MuiOutlinedInput-input': {
                                        fontWeight: 400,
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        background: '#fff'
                                    }
                                }}
                                id="workloadTime"
                                name="workloadTime"
                                value={formik.values.workloadTime}
                                onChange={formik.handleChange}
                                variant="outlined"
                                fullWidth
                                error={formik.touched.workloadTime && Boolean(formik.errors.workloadTime)}
                                helperText={formik.touched.workloadTime && formik.errors.workloadTime}
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', mt: 3, justifyContent: 'space-between' }}>
                        <Box>
                            <Typography
                                sx={{
                                    color: theme.palette.grey[400],
                                    fontFamily: 'Inter',
                                    fontWeight: 600,
                                    fontSize: '14px',
                                    mb: '4px'
                                }}
                            >
                                <FormattedMessage id="min_order_volume" />
                            </Typography>
                            <TextField
                                disabled
                                sx={{
                                    '.MuiOutlinedInput-input': {
                                        fontWeight: 400,
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        background: '#fff',
                                        '&:disabled': {
                                            background: theme.palette.grey[800]
                                        },
                                        width: '184px'
                                    }
                                }}
                                id="minVolume"
                                name="minVolume"
                                value={formik.values.minVolume}
                                onChange={formik.handleChange}
                                variant="outlined"
                                error={formik.touched.minVolume && Boolean(formik.errors.minVolume)}
                                helperText={formik.touched.minVolume && formik.errors.minVolume}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', mt: 1 }}>
                            <Switch
                                sx={{
                                    '.MuiSwitch-root': {
                                        height: '48px'
                                    },
                                    '.MuiSwitch-thumb': {
                                        height: '16px',
                                        width: '16px'
                                    },
                                    height: '44px',
                                    '.MuiSwitch-switchBase': {
                                        top: '5px',
                                        left: '5px',
                                        '.Mui-checked': {
                                            transform: 'translateX(13px)'
                                        }
                                    },
                                    '.MuiSwitch-track': {
                                        borderRadius: '100px',
                                        background: '#D4DBEA'
                                    },
                                    '.Mui-checked': {
                                        transform: 'translateX(13px) !important'
                                    }
                                }}
                                checked={formik.values.switch}
                                onChange={handleSwitch}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <Typography
                                sx={{
                                    color: theme.palette.grey[400],
                                    fontFamily: 'Inter',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    mt: '13px'
                                }}
                            >
                                <FormattedMessage id="allow_clients_to_change_volume" />
                            </Typography>
                        </Box>
                    </Box>
                </form>
            </Box>
            <Box
                sx={{
                    p: 4,
                    borderTop: `1px solid ${theme.palette.grey[500]}`,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    height: '107px'
                }}
            >
                <Button variant="secondary" sx={{ width: '90px', mr: 1 }} title={<FormattedMessage id="cancel" />} onClick={onClose} />
                <Button sx={{ width: '90px' }} title={<FormattedMessage id="create" />} onClick={formik.handleSubmit} />
            </Box>
        </Box>
    );
};

export default DIalogCreatePackage;
