import { WFAutomationTask, AutomationConfigurationField } from '@sni4/snikpic-common';
import { CreateNodeParams, ReactFlowAutomationNode } from 'features/workflow/types/react-flow.types';
import { getId } from 'features/workflow/utils/react-flow.utils';
import { availableAutomationNodes } from '../types';

export class AutomationNodeHelper {
    static createNode({ position, automationSet }: CreateNodeParams): ReactFlowAutomationNode {
        const availableAutomation = availableAutomationNodes().find((node) => node.automation_set === automationSet);

        if (!availableAutomation) {
            throw new Error('Automation type not found');
        }

        const automationConfiguration = availableAutomation.getDefaultConfiguration().actions[0];

        return {
            id: getId(),
            type: 'automation_task',
            position,
            data: {
                assignee: '',
                name: 'Automation Node',
                // TODO - automation : share configuration
                automation_configuration: automationConfiguration,
                is_configuration_valid: AutomationNodeHelper.isValid(automationConfiguration.fields)
            }
        };
    }

    static convertToWorkflowNode(node: ReactFlowAutomationNode): WFAutomationTask {
        return {
            type: node.type,
            properties: {
                id: node.id,
                position: node.position,
                assignee: node.data.assignee,
                name: node.data.name,
                automation_configuration: node.data.automation_configuration
            }
        };
    }

    static convertToReactFlowNode(node: WFAutomationTask): ReactFlowAutomationNode {
        return {
            id: node.properties.id,
            type: node.type,
            position: node.properties.position,
            data: {
                assignee: node.properties.assignee,
                name: node.properties.name,
                automation_configuration: node.properties.automation_configuration,
                is_configuration_valid: AutomationNodeHelper.isValid(node.properties.automation_configuration.fields)
            }
        };
    }

    static isValid(fields: AutomationConfigurationField[]): boolean {
        for (const field of fields) {
            if (field.required && field.source !== 'DIRECT_INPUT' && !field.selected_object) {
                return false;
            }
            if (field.required && field.source === 'DIRECT_INPUT' && !field.value) {
                return false;
            }
            if (field.type === 'DOCUMENT_PAGE_DROPDOWN') {
                return this.isValid(field.fields);
            }
        }
        return true;
    }
}
