import { useRef, useMemo, createContext, useCallback } from 'react';
import { ApolloProvider } from '@apollo/client';
import { createApolloClient } from 'services/graphQL';

type ClientContextType = {
    setRefresh: (dispatch: any) => void;
};
const ClientContext = createContext<ClientContextType | null>(null);

export const ClientProvider = ({ children }: { children: React.ReactElement }) => {
    const authRef = useRef(null);

    const setRefresh = useCallback((refresh: any) => {
        authRef.current = refresh;
    }, []);

    const client = useMemo(() => createApolloClient(authRef), []);
    return (
        <ClientContext.Provider value={{ setRefresh }}>
            <ApolloProvider client={client}>{children}</ApolloProvider>
        </ClientContext.Provider>
    );
};

export default ClientContext;
