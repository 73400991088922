import React, { useMemo } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';

// material-ui
import { styled, useTheme, Theme } from '@mui/material/styles';
import { AppBar, Box, Container, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import navigation from 'menu-items';
import useConfig from 'hooks/useConfig';
import { closedDrawerWidth, drawerWidth } from 'store/constant';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';

// assets
import { IconChevronRight } from '@tabler/icons';

interface MainStyleProps {
    theme: Theme;
    open: boolean;
    pathname: string;
}

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open, pathname }: MainStyleProps) => {
    const isOnMessageSection = pathname === '/messages';
    console.log('pathname', pathname);

    return {
        ...theme.typography.mainContent,
        ...(!open && {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            // TODO - temporarly keeping the condition on messageX to see if we need to put it back for /messages route
            ...(pathname === '/messagesX' ? { padding: 0 } : {}),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.shorter
            }),
            [theme.breakpoints.up('md')]: {
                marginLeft: 0,
                ...(pathname === '/messagesX' ? { marginRight: 0, padding: 0 } : {}),
                width: `calc(100% - ${closedDrawerWidth}px)`
            },
            [theme.breakpoints.down('md')]: {
                width: `calc(100% - ${closedDrawerWidth}px)`,
                ...(pathname === '/messagesX' ? { marginRight: 0, padding: 0 } : { marginLeft: '20px', padding: '16px' })
            },
            [theme.breakpoints.down('sm')]: {
                width: `calc(100% - ${closedDrawerWidth}px)`,
                ...(pathname === '/messagesX'
                    ? { padding: 0, marginRight: 0 }
                    : { marginLeft: '10px', padding: '16px', marginRight: '10px' })
            }
        }),
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.shorter
            }),
            marginLeft: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            width: `calc(100% - ${drawerWidth}px)`,
            ...(pathname === '/messages' ? { marginTop: '80px', marginRight: 0, padding: 0 } : {}),

            [theme.breakpoints.down('md')]: {
                marginLeft: '20px',
                ...(pathname === '/messages' ? { padding: 0 } : {})
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: '10px',
                ...(pathname === '/messages' ? { padding: 0 } : {})
            }
        }),
        backgroundColor: 'white'
    };
});

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const location = useLocation();

    React.useEffect(() => {
        console.log('location', location);
    }, [location]);

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { container } = useConfig();

    React.useEffect(() => {
        dispatch(openDrawer(!matchDownMd));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    const header = useMemo(
        () => (
            <Toolbar>
                <Header />
            </Toolbar>
        ),
        []
    );

    return (
        <Box sx={{ display: 'flex', ...(location.pathname === '/messages' ? { overflow: 'hidden' } : {}) }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: drawerOpen ? theme.transitions.create('width') : 'none',
                    ...(location.pathname === '/messages' ? { height: '70' } : {})
                }}
            >
                {header}
            </AppBar>

            {/* drawer */}
            <Sidebar />

            {/* main content */}
            <Main theme={theme} open={drawerOpen} pathname={location.pathname}>
                {/* breadcrumb */}
                {container && (
                    <Container maxWidth="lg">
                        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                        <Outlet />
                    </Container>
                )}
                {!container && (
                    <>
                        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                        <Outlet />
                    </>
                )}
            </Main>
        </Box>
    );
};

export default MainLayout;
