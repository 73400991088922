import { useState, useEffect } from 'react';
import { Typography, Box, Breadcrumbs, Grid, Avatar, CardMedia, Divider, Rating } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import briefcase from 'assets/images/briefcase.svg';
import creditcard from 'assets/images/creditcard.svg';

const Reviews = ({ review }: any) => {
    const theme = useTheme();

    return (
        <Box sx={{ width: { xs: '100%', sm: '80%', lg: '60%' }, mb: 3 }}>
            <Typography
                sx={{
                    color: theme.palette.grey[400],
                    fontFamily: 'Inter',
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: '120%'
                }}
            >
                {review.title}
            </Typography>
            <Box sx={{ display: 'flex', mt: 2, alignItems: 'center' }}>
                <Rating name="simple-controlled" value={review.rating} readOnly />
                <Typography
                    sx={{
                        color: '#FDB022',
                        fontFamily: 'Inter',
                        fontWeight: 600,
                        fontSize: '16px',
                        mt: '4px'
                    }}
                >
                    {review.rating}
                </Typography>
            </Box>
            <Typography
                sx={{
                    color: theme.palette.grey[300],
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '135%',
                    mt: 2
                }}
            >
                {review.description}
            </Typography>
            <Box sx={{ display: 'flex', mt: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: '20px' }}>
                        <CardMedia component="img" image={briefcase} alt="briefcase" />
                    </Box>
                    <Typography
                        sx={{
                            color: theme.palette.grey[400],
                            fontFamily: 'Inter',
                            fontWeight: 500,
                            fontSize: '14px',
                            ml: 1
                        }}
                    >
                        {`${review.tasksCompleted} tasks`}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', ml: 5 }}>
                    <Box sx={{ width: '20px' }}>
                        <CardMedia component="img" image={creditcard} alt="briefcase" />
                    </Box>
                    <Typography
                        sx={{
                            color: theme.palette.grey[400],
                            fontFamily: 'Inter',
                            fontWeight: 500,
                            fontSize: '14px',
                            ml: 1
                        }}
                    >
                        {`€${review.price}`}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default Reviews;
