import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface SubtitleProps {
    title: string;
}

const Subtitle = ({ title }: SubtitleProps) => {
    const theme = useTheme();

    return <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 400, fontSize: '14px' }}>{title}</Typography>;
};

export default Subtitle;
