import { useState, useEffect } from 'react';
import {
    Typography,
    Box,
    Breadcrumbs,
    Grid,
    Avatar,
    CardMedia,
    Divider,
    Rating,
    Drawer,
    TextField,
    CircularProgress,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Select,
    MenuItem
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button } from 'ui-component/extended/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextEditor from 'ui-component/textEditor';
import SelectorAutocomplite from 'ui-component/SelectorAutoComplite';
import { useLazyQuery } from '@apollo/client';
import { GET_CLIENTS } from 'services/graphQL';
import useAuth from 'hooks/useAuth';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

interface LabelType {
    title: string;
    src?: string;
}
interface CreateProposalProp {
    handleCloseCreateProposal: any;
    selectedPackege: any;
    back?: boolean;
    backFunction?: any;
    update?: boolean;
}

const itmesSelect = ['Per week', 'Per month', 'Per day'];
const CreateProposal = ({ handleCloseCreateProposal, selectedPackege, back, backFunction, update }: CreateProposalProp) => {
    const theme = useTheme();
    const intl = useIntl();
    const { member } = useAuth();
    const [clientsLabels, setClientsLabels] = useState<any>([]);
    const [pendingValue, setPendingValue] = useState<LabelType[]>([]);
    const [selectedClients, setSelectedClients] = useState<any[]>([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [tasks, setTasks] = useState<any>([]);
    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
            frequency: 'Per week'
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Required'),
            description: Yup.string().required('Required'),
            frequency: Yup.string().required('Required')
        }),
        onSubmit: (values) => {
            console.log(values);
        }
    });
    const handleDescription = (value: any) => formik.setFieldValue('description', value);
    const [getClients, { loading: loadingClients }] = useLazyQuery(GET_CLIENTS, {
        onCompleted: (data) => {
            setClientsLabels(data.client_organizations);
        },
        onError: (error) => {
            console.log(error);
        }
    });
    useEffect(() => {
        getClients({ variables: { organizationId: member?.organizationId } });
        if (selectedPackege) {
            const arrTasks: any = [];
            selectedPackege.jobs.forEach((element: any, index: number) => {
                arrTasks.push({
                    active: index < 2,
                    title: element.title,
                    volume: element.volume,
                    minVolume: element.minVolume,
                    price: element.price
                });
            });
            setTasks(arrTasks);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        let price = 0;
        tasks.forEach((element: any) => {
            if (element.active) price += Math.ceil((element.price / element.minVolume) * element.volume);
        });
        setTotalPrice(price);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tasks]);
    const handleCheckTasks = (title: string) => {
        setTasks(
            tasks.map((task: any) => {
                if (task.title === title) {
                    return { ...task, active: !task.active };
                }
                return task;
            })
        );
    };
    const handleCountTasks = (title: string, expression: string) => {
        if (expression === 'remove') {
            setTasks(
                tasks.map((task: any) => {
                    if (task.title === title && task.volume > task.minVolume && task.active) {
                        return { ...task, volume: task.volume - 1 };
                    }
                    return task;
                })
            );
        } else {
            setTasks(
                tasks.map((task: any) => {
                    if (task.title === title && expression === 'add' && task.active) {
                        return { ...task, volume: task.volume + 1 };
                    }
                    return task;
                })
            );
        }
    };
    return (
        <form onSubmit={formik.handleSubmit}>
            <Box sx={{ height: '100vh', maxWidth: '562px' }}>
                <Box sx={{ height: 'calc(100vh - 107px)', overflowY: 'auto', p: 4 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {back && (
                            <ArrowBackIosNewIcon
                                onClick={backFunction}
                                sx={{ fill: theme.palette.grey[400], cursor: 'pointer', mr: 1, fontSize: '16px' }}
                            />
                        )}
                        <Typography
                            sx={{
                                color: theme.palette.grey[400],
                                fontFamily: 'Inter',
                                fontWeight: 600,
                                fontSize: '20px'
                            }}
                        >
                            <FormattedMessage id={update ? 'update_contract' : 'create_proposal'} />
                        </Typography>
                    </Box>
                    <Typography
                        sx={{
                            color: theme.palette.grey[400],
                            fontFamily: 'Inter',
                            fontWeight: 600,
                            fontSize: '14px',
                            mt: 3,
                            mb: '4px'
                        }}
                    >
                        <FormattedMessage id="name_of_proposal" />
                    </Typography>
                    <TextField
                        sx={{
                            '.MuiOutlinedInput-input': {
                                fontWeight: 400,
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                background: '#fff'
                            }
                        }}
                        id="name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        variant="outlined"
                        fullWidth
                        placeholder="Proposal #1"
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                    <Box mt={3}>
                        {loadingClients ? (
                            <CircularProgress />
                        ) : (
                            <SelectorAutocomplite
                                autocompleteItems={clientsLabels}
                                pendingValue={pendingValue}
                                setPendingValue={setPendingValue}
                                arrItemSelected={selectedClients}
                                setArrItemsSelected={setSelectedClients}
                                title="client"
                                imagePath="logo_url"
                                labelPath="name"
                                singleSelect
                            />
                        )}
                    </Box>
                    <Typography
                        sx={{
                            color: theme.palette.grey[400],
                            fontFamily: 'Inter',
                            fontWeight: 600,
                            fontSize: '14px',
                            mt: 3,
                            mb: '4px'
                        }}
                    >
                        <FormattedMessage id="description" />
                    </Typography>
                    <Box
                        sx={{
                            borderRadius: '10px',
                            border: `1px solid ${theme.palette.grey[500]}`,
                            minHeight: { xs: '350px', sm: '400px' }
                        }}
                    >
                        <TextEditor value={formik.values.description} setValue={handleDescription} placeholder="description" />
                    </Box>
                    <Typography
                        sx={{
                            color: theme.palette.grey[400],
                            fontFamily: 'Inter',
                            fontWeight: 600,
                            fontSize: '14px',
                            mt: 3,
                            mb: '4px'
                        }}
                    >
                        <FormattedMessage id="tasks" />
                    </Typography>

                    {tasks.length > 0 &&
                        tasks.map((task: any, index: number) => (
                            <Grid key={index} container justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        sx={{
                                            '.MuiTypography-root': {
                                                color: theme.palette.grey[400],
                                                fontWeight: '500',
                                                fontFamily: 'Inter'
                                            }
                                        }}
                                        key={index}
                                        control={<Checkbox onChange={() => handleCheckTasks(task.title)} checked={task.active} />}
                                        label={task.title}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-end',
                                            opacity: task.active ? 1 : 0.2
                                        }}
                                    >
                                        <RemoveIcon
                                            onClick={() => handleCountTasks(task.title, 'remove')}
                                            sx={{
                                                fill: theme.palette.grey[400],
                                                border: `1px solid ${theme.palette.grey[500]}`,
                                                borderRadius: '60px',
                                                p: '4px',
                                                fontSize: '20px',
                                                cursor: task.volume === task.minVolume ? '' : 'pointer',
                                                opacity: task.volume === task.minVolume ? 0.2 : 1
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                color: theme.palette.grey[400],
                                                fontFamily: 'Inter',
                                                fontWeight: 600,
                                                fontSize: '14px',
                                                mr: 1,
                                                ml: 1
                                            }}
                                        >
                                            {task.volume}
                                        </Typography>
                                        <AddIcon
                                            onClick={() => handleCountTasks(task.title, 'add')}
                                            sx={{
                                                fill: theme.palette.grey[400],
                                                border: `1px solid ${theme.palette.grey[500]}`,
                                                borderRadius: '60px',
                                                p: '4px',
                                                fontSize: '20px',
                                                cursor: task.active ? 'pointer' : ''
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography
                                        sx={{
                                            color: theme.palette.grey[400],
                                            fontFamily: 'Inter',
                                            fontWeight: 600,
                                            fontSize: '14px',
                                            mr: 1,
                                            ml: 1,
                                            textAlign: 'right',
                                            opacity: task.active ? 1 : 0.2
                                        }}
                                    >
                                        {`€${Math.ceil((task.price / task.minVolume) * task.volume)}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ))}
                    <Typography
                        sx={{
                            color: theme.palette.grey[400],
                            fontFamily: 'Inter',
                            fontWeight: 600,
                            fontSize: '14px',
                            mt: 3,
                            mb: '4px'
                        }}
                    >
                        <FormattedMessage id="facebook_ads_report_frequency" />
                    </Typography>
                    <Select
                        name="frequency"
                        id="frequency"
                        value={formik.values.frequency}
                        onChange={formik.handleChange}
                        disableUnderline
                        IconComponent={KeyboardArrowDownIcon}
                        fullWidth
                        sx={{
                            fieldset: {
                                border: '1px solid',
                                borderColor: '#D1D5DB',
                                borderRadius: '6px'
                            },
                            '.MuiSelect-select': {
                                p: 2,
                                color: '#111827',
                                fontSize: '14px',
                                fontFamily: 'Manrope',
                                fontWeight: 400,
                                width: '100%',
                                background: '#fff'
                            }
                        }}
                    >
                        {itmesSelect.map((item: string) => (
                            <MenuItem key={item} value={item}>
                                <Typography
                                    sx={{
                                        color: '#000',
                                        fontFamily: 'Inter',
                                        fontWeight: 500,
                                        fontSize: '14px'
                                    }}
                                >
                                    {item}
                                </Typography>
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
                <Box
                    sx={{
                        p: 4,
                        borderTop: `1px solid ${theme.palette.grey[500]}`,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '107px'
                    }}
                >
                    <Typography
                        sx={{
                            color: theme.palette.grey[400],
                            fontFamily: 'Inter',
                            fontWeight: 600,
                            fontSize: '30px'
                        }}
                    >
                        {`€${totalPrice}/${formik.values.frequency.split(' ')[1]}`}
                    </Typography>
                    <Box sx={{ display: 'flex' }}>
                        <Button
                            variant="secondary"
                            sx={{ width: '90px', mr: 1 }}
                            title={<FormattedMessage id="cancel" />}
                            onClick={handleCloseCreateProposal}
                        />
                        <Button
                            sx={{ width: '160px' }}
                            title={<FormattedMessage id={update ? 'update_contract' : 'create_proposal'} />}
                            onClick={formik.handleSubmit}
                        />
                    </Box>
                </Box>
            </Box>
        </form>
    );
};

export default CreateProposal;
