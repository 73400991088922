import clsx from 'clsx';
import { Avatar, useChatContext } from 'stream-chat-react';

import { channelByUser, ChannelOrUserType, isChannel } from './utils';

import type { Channel, UserResponse } from 'stream-chat';

import type { StreamChatType } from '../../types';

type SearchResultProps = Pick<ResultsDropdownProps, 'focusedId' | 'setChannel'> & {
    result: ChannelOrUserType;
};

const SearchResult = (props: SearchResultProps) => {
    const { focusedId, result, setChannel } = props;

    const { client, setActiveChannel } = useChatContext<StreamChatType>();

    const handleClick = isChannel(result)
        ? () => setChannel(result as Channel<StreamChatType>)
        : () => channelByUser({ client, setActiveChannel, user: result as UserResponse<StreamChatType> });

    return (
        <div
            onClick={handleClick}
            onKeyPress={handleClick}
            role="button"
            tabIndex={0}
            className={clsx('channel-search__result-container', { highlighted: focusedId === result.id })}
        >
            {isChannel(result) ? (
                <>
                    <div className="result-hashtag">#</div>
                    <p className="channel-search__result-text">{result?.data?.name}</p>
                </>
            ) : (
                <>
                    <Avatar image={result.image} name={result.name || result.id} size={24} />
                    <p className="channel-search__result-text">{result.name || result.id || 'Johnny Blaze'}</p>
                </>
            )}
        </div>
    );
};

type ResultsDropdownProps = {
    teamChannels?: Channel<StreamChatType>[];
    directChannels?: UserResponse<StreamChatType>[];
    focusedId: string;
    loading: boolean;
    setChannel: (channel: Channel<StreamChatType>) => void;
    setQuery: React.Dispatch<React.SetStateAction<string>>;
};

export const ResultsDropdown = (props: ResultsDropdownProps) => {
    const { teamChannels, directChannels, focusedId, loading, setChannel, setQuery } = props;
    document.addEventListener('click', () => setQuery(''));

    console.log('teamChannels: ', teamChannels);

    // console.log('directChannels: ', directChannels);

    return (
        <div className="channel-search__results">
            <p className="channel-search__results-header">Channels</p>
            {loading && !teamChannels?.length && (
                <p className="channel-search__results-header">
                    <i>Loading...</i>
                </p>
            )}
            {!loading && !teamChannels?.length ? (
                <p className="channel-search__results-header">
                    <i>No channels found</i>
                </p>
            ) : (
                teamChannels?.map((channel, i) => <SearchResult result={channel} focusedId={focusedId} key={i} setChannel={setChannel} />)
            )}
            {/* <p className="channel-search__results-header">Users</p>
            {loading && !directChannels?.length && (
                <p className="channel-search__results-header">
                    <i>Loading...</i>
                </p>
            )}
            {!loading && !directChannels?.length ? (
                <p className="channel-search__results-header">
                    <i>No direct messages found</i>
                </p>
            ) : (
                directChannels?.map((user: UserResponse<StreamChatType>, i) => (
                    <SearchResult result={user} focusedId={focusedId} key={i} setChannel={setChannel} />
                ))
            )} */}
        </div>
    );
};
