import { createContext, FC, useContext, useState } from 'react';
import { ContextDueDate } from '../../../types/due-date';

interface DueDateProps {
    dueDate: ContextDueDate;
    updateDueDate: (newDueDate: Date) => void;
}

const DueDateContext = createContext({} as DueDateProps);

export const DueDateProvider: FC<{ fetchedDueDate: ContextDueDate }> = ({ children, fetchedDueDate }) => {
    const [dueDate, setDueDate] = useState(fetchedDueDate);

    return (
        <DueDateContext.Provider
            value={{
                dueDate,
                updateDueDate: setDueDate
            }}
        >
            {children}
        </DueDateContext.Provider>
    );
};

export const useDueDate = () => useContext(DueDateContext);
