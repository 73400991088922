import React, { useState } from 'react';
import { Box, Drawer, Typography, Tabs, Tab } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { FormattedMessage } from 'react-intl';
import { Button } from 'ui-component/extended/Button';
import { ProjectMemberType } from 'types/clients';
import { MembersForm, FormsForm } from './forms';

interface DrawerAddOffersProps {
    open: boolean;
    onClose: () => void;
    project?: any;
    availableTeamMembers: ProjectMemberType[];
    onAddMember: (memberId: string, projectId: string) => Promise<void>;
    onDelMember: (id: string) => Promise<void>;
    deleting: boolean;
}
export const ProvidedServicesDrawer = ({
    open,
    onClose,
    project,
    availableTeamMembers,
    onAddMember,
    onDelMember,
    deleting
}: DrawerAddOffersProps) => {
    const theme = useTheme();
    const [activeTab, setActiveTab] = useState(0);

    const handleChangeTab = (_: any, newValue: number) => setActiveTab(newValue);
    const handleAddMember = async (member: ProjectMemberType) => {
        if (project.id) {
            await onAddMember(member.orgMemberId, project.id);
        }
    };

    const onAddForm = () => {
        console.log('add form');
    };

    const membersAvailable = availableTeamMembers.filter((member: ProjectMemberType) =>
        project?.teamMembers?.every((mem: ProjectMemberType) => mem.orgMemberId !== member.orgMemberId)
    );

    return (
        <Drawer anchor="right" open={open} onClose={onClose} sx={{ '.MuiPaper-root': { width: { xs: '100%', sm: '562px' } } }}>
            {project && (
                <Box sx={{ width: { xs: '100%', sm: '562px' }, p: 4, height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography ml={1} sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600, fontSize: '18px' }}>
                            {project.name}
                        </Typography>
                        <CloseIcon onClick={onClose} sx={{ fontSize: '20px', fill: '#9197AE', cursor: 'pointer' }} />
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Tabs value={activeTab} onChange={handleChangeTab}>
                            <Tab label="About" value={0} />
                            {/* <Tab label="Forms" value={1} /> */}
                        </Tabs>
                    </Box>
                    {activeTab === 0 && (
                        <MembersForm
                            project={project}
                            availableMembers={membersAvailable}
                            onAddMember={handleAddMember}
                            onDelMember={onDelMember}
                            deleting={deleting}
                        />
                    )}
                    {activeTab === 1 && <FormsForm onAddForm={onAddForm} />}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', px: 4 }}>
                        <Button title={<FormattedMessage id="close" />} onClick={onClose} variant="secondary" sx={{ width: 96 }} />
                    </Box>
                </Box>
            )}
        </Drawer>
    );
};
