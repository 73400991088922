import { Box, CardMedia } from '@mui/material';
import AIToolsDrawer from 'modules/ai-tools/AIToolsDrawer';
import React from 'react';
import brainIcon from 'assets/images/brainAI.svg';
import brainAIfff from 'assets/images/brainAIfff.svg';

const AIToolsSection = () => {
    const [openDivider, setOpenDivider] = React.useState(false);
    const handleClickCloseDivider = () => {
        setOpenDivider(false);
    };
    const [urlImage, setUrlImage] = React.useState(brainIcon);
    const handleOpenDivider = () => {
        setOpenDivider(true);
    };

    return (
        <>
            <Box
                onMouseEnter={() => setUrlImage(brainAIfff)}
                onMouseLeave={() => setUrlImage(brainIcon)}
                onClick={handleOpenDivider}
                sx={{ background: '#eceff1', borderRadius: '8px', p: 1, '&:hover': { background: '#f0998d' } }}
            >
                <CardMedia
                    sx={{
                        objectFit: 'contain',
                        color: '#fff'
                    }}
                    aria-controls="menu-list-grow"
                    component="img"
                    image={urlImage}
                    alt="AI tools"
                />
            </Box>

            <AIToolsDrawer openDivider={openDivider} handleClickCloseDivider={handleClickCloseDivider} />
        </>
    );
};

export default AIToolsSection;
