import React from 'react';
import { Box, CardMedia } from '@mui/material';
import editIcon from 'assets/images/edit.svg';

interface EditImageButtonProps {
    sx: object;
    onEdit: (src: string) => void;
}
export const EditImageButton = ({ sx, onEdit }: EditImageButtonProps) => {
    const onAddFile = (files: FileList | null) => {
        if (!files) return;

        const reader = new FileReader();
        const file = files.item(0);
        if (!file) return;

        reader.onloadend = () => {
            onEdit(reader.result as string);
        };

        reader.readAsDataURL(file);
    };

    return (
        <Box sx={sx}>
            {/* {!!src && (
                <Box sx={{ width: '15px', m: '3px 0 0 3px', cursor: 'pointer' }} onClick={() => onEdit(src)}>
                    <CardMedia sx={{ objectFit: 'contain' }} component="img" width="18px" image={editIcon} alt="alt image" />
                </Box>
            )} */}
            <input
                accept={'image/*'}
                id="upload-file"
                type="file"
                style={{ display: 'none' }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onAddFile(e.target.files)}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="upload-file" style={{ display: 'inline' }}>
                <Box sx={{ width: '15px', m: '3px 0 0 3px', cursor: 'pointer' }}>
                    <CardMedia sx={{ objectFit: 'contain' }} component="img" width="18px" image={editIcon} alt="alt image" />
                </Box>
            </label>
        </Box>
    );
};
