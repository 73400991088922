import { Box, Dialog, Popover, Slide, Typography } from '@mui/material';
import { FC, forwardRef, ReactElement, Ref, useState, useContext } from 'react';
import { Handle, NodeProps, Position } from 'react-flow-renderer';
import { TransitionProps } from '@mui/material/transitions';
import { useTheme } from '@mui/material/styles';
import CustomMenuItem from 'views/pages/clients/features/detail/workflow/features/document-builder/subcomponents/menuItem';
import { FlowNode, RowBody } from '../../../../../views/pages/clients/features/detail/workflow/features/document-builder/ui';
import DialogTask from './TaskNodeDialog';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ReactComponent as ArrowRightCircleIcon } from 'assets/images/arrowRightCircle.svg';
import { ReactComponent as CopyIcon } from 'assets/images/copy.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/trash.svg';
import TaskProvider from 'views/pages/clients/features/detail/workflow/features/workflow-builder/components/task/context';
import DocumentBuilderContext from '../../../../../views/pages/clients/features/detail/workflow/features/document-builder/context/workflow.context';
import DuplicateNode from 'views/pages/clients/features/detail/workflow/features/workflow-builder/diagram/hooks/duplicate-node';
import DialogConfirmDelete from '../../../../../views/pages/clients/features/detail/workflow/features/workflow-builder/sideBar/DialogConfirmDelete';
import { removeNodes } from '../../../../../views/pages/clients/features/detail/workflow/features/workflow-builder/diagram/hooks/delete-nodes';
import { ReactFlowApprovalTaskNode, ReactFlowTaskNode } from 'features/workflow/types/react-flow.types';
import { GreenCheckMark, RedCrossMark } from 'ui-component/Icons';
import { FormattedMessage } from 'react-intl';

const Transition = forwardRef((props: TransitionProps & { children: ReactElement<any, any> }, ref: Ref<unknown>) => (
    <Slide direction="down" ref={ref} {...props} />
));

const TaskNode: FC<NodeProps<ReactFlowTaskNode['data'] | ReactFlowApprovalTaskNode['data']>> = ({ yPos, xPos, type, id, data }) => {
    const theme = useTheme();
    const { documentBuilder } = useContext(DocumentBuilderContext);
    const isApprovalTask = type === 'approval_task';

    const [openDialog, setOpenDialog] = useState(false);
    const handleCloseDialog = () => setOpenDialog(false);
    const handleOpenDialog = () => setOpenDialog(true);

    const [open, setOpen] = useState<boolean>(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleSettingsOpen = () => {
        setOpen(true);
    };

    const [moreAnchorEl, setMoreAnchorEl] = useState(null);
    const morePopoverOpen = Boolean(moreAnchorEl);
    const morePopoverId = morePopoverOpen ? 'simple-popover' : undefined;
    const handleClickMorePopupOver = (event: any) => {
        setMoreAnchorEl(event.currentTarget);
    };
    const handleCloseMorePopupOver = () => {
        setMoreAnchorEl(null);
    };

    const handleDuplicate = () => {
        const setNodes = documentBuilder.workflow.setNodes;
        DuplicateNode({ setNodes, type, xPos, yPos });
        handleCloseMorePopupOver();
    };

    const handleDeleteNode = () => {
        removeNodes(documentBuilder.workflow, id);
        handleCloseMorePopupOver();
    };

    return (
        <TaskProvider formId={id}>
            <FlowNode>
                <Handle
                    type="target"
                    position={Position.Top}
                    isConnectable
                    style={{ width: '9px', height: '9px', opacity: 1, background: '#98A2B3' }}
                />
                <RowBody sx={{ justifyContent: 'space-between' }}>
                    <Typography variant="subtitle1" sx={{ color: theme.palette.text.primary }}>
                        {`${isApprovalTask ? 'Approval ' : ''} Task ${data.name ? `(${data.name})` : ''}`}
                    </Typography>
                    <MoreHorizIcon sx={{ cursor: 'pointer' }} onClick={handleClickMorePopupOver} />
                </RowBody>
                <RowBody sx={{ justifyContent: 'space-between', cursor: 'pointer' }} onClick={handleSettingsOpen}>
                    <Typography variant="body1" sx={{ color: '#5A5A5A' }}>
                        {documentBuilder.workflow.taskAssigned ? 'View' : 'Set'} parameters
                    </Typography>
                    <ArrowRightCircleIcon style={{ width: '18px', height: '18px' }} />
                </RowBody>
                <RowBody sx={{ gap: 1 }}>
                    {data.is_valid ? <GreenCheckMark /> : <RedCrossMark />}{' '}
                    <Typography variant="caption" sx={{ color: '#5A5A5A' }}>
                        <FormattedMessage id={data.is_valid ? 'configured' : 'missing_parameters'} />
                    </Typography>
                </RowBody>
                <Popover
                    id={morePopoverId}
                    open={morePopoverOpen}
                    anchorEl={moreAnchorEl}
                    onClose={handleCloseMorePopupOver}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Box sx={{ width: '142px' }}>
                        <CustomMenuItem icon={<CopyIcon />} name="Duplicate" onClick={handleDuplicate} />
                        <CustomMenuItem icon={<DeleteIcon />} name="Delete" onClick={handleOpenDialog} />
                    </Box>
                </Popover>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    onClose={handleClose}
                    sx={{ '.MuiPaper-root': { width: { xs: '100%', sm: '563px' }, height: { xs: '100%', sm: '90vh' }, p: 0 } }}
                >
                    <DialogTask onClose={handleClose} id={id} isApprovalTask={isApprovalTask} type={type} nodeData={data} />
                </Dialog>
                <Handle
                    type="source"
                    position={Position.Bottom}
                    isConnectable={!isApprovalTask}
                    style={{
                        width: '9px',
                        height: '9px',
                        opacity: 1,
                        background: '#98A2B3',
                        ...(isApprovalTask && { background: '#000', border: '0' })
                    }}
                />
            </FlowNode>
            <DialogConfirmDelete open={openDialog} handleClose={handleCloseDialog} onClick={handleDeleteNode} />
        </TaskProvider>
    );
};

export default TaskNode;
