import { Box, CardMedia, Grid, Typography } from '@mui/material';
import moment from 'moment';
import Subtitle from './Subtitle';
import SmallText from './SmallText';
import Title from './Title';
import { months } from 'utils/months';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import fileorange from 'assets/images/fileorange.svg';
import Inspirationorange from 'assets/images/Inspirationorange.svg';
import Promotionorange from 'assets/images/Promotionorange.svg';
import Quotesorange from 'assets/images/Quotesorange.svg';
import Portraitorange from 'assets/images/Portraitorange.svg';
import Pasteventsorange from 'assets/images/Pasteventsorange.svg';
import Calendarventsorange from 'assets/images/Calendarventsorange.svg';
import AccordionResults from './AccordionResults';

interface ResultsValueType {
    request?: string;
    desc?: string;
    day?: number;
    month?: number;
    year?: number;
    period?: string;
    brand_type?: string;
    language?: string;
    category?: string;
}
interface PreviousResultProps {
    resultsPrevious: any;
    date?: string;
    previousPublication?: boolean;
    labelsResults?: any;
    selectedHistory?: any;
    handleSelectedHistory?: any;
    labels?: any;
}

const PreviousResult = ({
    resultsPrevious,
    date,
    previousPublication,
    labelsResults,
    selectedHistory,
    handleSelectedHistory,
    labels
}: PreviousResultProps) => {
    const theme = useTheme();
    const handleImage = (id: string) => {
        switch (id) {
            case 'information':
                return fileorange;
            case 'inspiration':
                return Inspirationorange;
            case 'promotion':
                return Promotionorange;
            case 'quotes':
                return Quotesorange;
            case 'portrait':
                return Portraitorange;
            case 'past_events':
                return Pasteventsorange;
            case 'calendar_events':
                return Calendarventsorange;
            default:
                return '';
        }
    };
    return (
        <Box>
            {previousPublication ? (
                <Box>
                    {selectedHistory ? (
                        <Box>
                            <Box sx={{ borderRadius: '8px', background: theme.palette.orange[200], p: 2 }}>
                                <Typography
                                    sx={{
                                        fontFamily: 'Inter',
                                        fontWeight: 600,
                                        fontSize: '14px',
                                        color: theme.palette.grey[400]
                                    }}
                                >
                                    <FormattedMessage id="brand_description" />
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Inter',
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        color: theme.palette.grey[400],
                                        mt: 1
                                    }}
                                >
                                    {selectedHistory.description}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Inter',
                                        fontWeight: 600,
                                        fontSize: '14px',
                                        color: theme.palette.grey[400],
                                        mt: 3
                                    }}
                                >
                                    <FormattedMessage id="date" />
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Inter',
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        color: theme.palette.grey[400],
                                        mt: 1
                                    }}
                                >
                                    {moment(selectedHistory.date).format('DD MMMM, YYYY')}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Inter',
                                        fontWeight: 600,
                                        fontSize: '14px',
                                        color: theme.palette.grey[400],
                                        mt: 3
                                    }}
                                >
                                    <FormattedMessage id="image" />
                                </Typography>
                                <Box
                                    sx={{
                                        borderRadius: '8px',
                                        border: `1px solid ${theme.palette.grey[500]}`,
                                        width: '50%',
                                        mt: 3
                                    }}
                                >
                                    <CardMedia component="img" image="" alt="AI tools" />
                                </Box>
                            </Box>
                            {labelsResults.map((label: any, index: number) => (
                                <Box key={index}>
                                    <AccordionResults label={label} />
                                </Box>
                            ))}
                        </Box>
                    ) : (
                        <Box>
                            {labels.map((label: any, index: number) => (
                                <Box
                                    onClick={() => handleSelectedHistory(index)}
                                    sx={{
                                        p: '12px',
                                        border: `1px solid ${theme.palette.grey[500]}`,
                                        borderRadius: '8px',
                                        mb: 3,
                                        cursor: 'pointer',
                                        transition: 'all .2s ease-in-out',
                                        '&:hover': {
                                            transform: 'translateY(-3px)',
                                            transition: 'all .2s ease-in-out'
                                        }
                                    }}
                                >
                                    <Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Inter',
                                                    fontWeight: 600,
                                                    fontSize: '14px',
                                                    color: theme.palette.grey[400]
                                                }}
                                            >
                                                {label.description}
                                            </Typography>
                                            <ChevronRightOutlinedIcon sx={{ fill: theme.palette.grey[400] }} />
                                        </Box>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Inter',
                                                fontWeight: 500,
                                                fontSize: '12px',
                                                color: theme.palette.grey[400],
                                                mb: 3
                                            }}
                                        >
                                            {moment(label.date).format('MMMM')}
                                        </Typography>
                                        <Grid container spacing={1}>
                                            {label.categories.map((item: any, index: number) => (
                                                <Grid item>
                                                    <Box
                                                        key={index}
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            p: '9px 14px',
                                                            borderRadius: '8px',
                                                            border: `1px solid ${theme.palette.grey[500]}`
                                                        }}
                                                    >
                                                        <Box sx={{ width: '24px', mr: 2 }}>
                                                            <CardMedia
                                                                sx={{ objectFit: 'contain' }}
                                                                component="img"
                                                                image={handleImage(item)}
                                                                alt="AI tools"
                                                            />
                                                        </Box>
                                                        <Typography
                                                            sx={{
                                                                color: theme.palette.orange.main,
                                                                fontFamily: 'Inter',
                                                                fontWeight: 500,
                                                                fontSize: '14px'
                                                            }}
                                                        >
                                                            {item}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            ))}
                                        </Grid>
                                        <Box
                                            sx={{
                                                borderRadius: '8px',
                                                border: `1px solid ${theme.palette.grey[500]}`,
                                                width: '50%',
                                                mt: 3
                                            }}
                                        >
                                            <CardMedia component="img" image="" alt="AI tools" />
                                        </Box>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    )}
                </Box>
            ) : (
                <Box sx={{ p: '16px', background: (theme) => theme.palette.orange[200], borderRadius: '8px' }}>
                    <Title title="brand_description" />
                    <Box mt={1}>{resultsPrevious.request && <Subtitle title={resultsPrevious.request} />}</Box>
                    <Box mt={1}>{resultsPrevious.desc && <Subtitle title={resultsPrevious.desc} />}</Box>
                    <Box mt={1}> {resultsPrevious.brand_type && <Subtitle title={resultsPrevious.brand_type} />}</Box>
                    <Grid container spacing={3} sx={{ mt: '-5px' }}>
                        <Grid item xs={4}>
                            <Title title="date" />
                            <Box mt={1}>
                                {resultsPrevious.period && <SmallText title={moment(resultsPrevious.period).format('MMMM, YYYY')} />}
                            </Box>

                            {resultsPrevious.month && (
                                <Box mt={1}>
                                    <SmallText title={`${months[resultsPrevious.month - 1]} ${resultsPrevious.year}`} />
                                </Box>
                            )}
                            <Box mt={1}>{date && <SmallText title={moment(date).format('DD, MMMM, YYYY')} />}</Box>
                        </Grid>
                        {resultsPrevious.language && (
                            <Grid item xs={4}>
                                <Title title="language" />
                                <Box mt={1}>
                                    <SmallText title={resultsPrevious.language} />
                                </Box>
                            </Grid>
                        )}
                        {resultsPrevious.category && (
                            <Grid item xs={4}>
                                <Title title="category" />
                                <Box mt={1}>
                                    <SmallText title={resultsPrevious.category} />
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            )}
        </Box>
    );
};

export default PreviousResult;
