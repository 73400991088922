//
import { useEffect, useState } from 'react';

// Libraries
import { CircularProgress, Typography, Alert } from '@mui/material';

// Utils & services
import { getTask, getFinetuning } from 'services/rest/virtual-employee';

// Components
import FinetuningCard from '../../../views/pages/clients/features/detail/AITools/FinetuningCard';

const GeneralTaskFinetuning = (props: { userId: string; taskId: string }) => {
    console.log('In GeneralTaskFinetuning');

    const { userId, taskId } = props;
    console.log('userId: ', userId);
    console.log('taskId: ', taskId);

    // Local state
    const [isLoading, setIsLoading] = useState(false);
    const [task, setTask] = useState({
        action: '',
        descriptionHL: '',
        descriptionPrompt: ''
    });
    const [finetuning, setFinetuning] = useState<{ input: string; output: string }[]>();
    console.log('In local state: task - ', task);
    console.log('In local state: finetuning - ', finetuning);

    // Data fetching
    const initialize = async () => {
        setIsLoading(true);
        const task = await getTask({ taskId });
        // console.log('task: ', task);
        setTask({
            ...task,
            action: task.data.action,
            descriptionHL: task.data.descriptionHL,
            descriptionPrompt: task.data.descriptionPrompt
        });
        const finetuning = await getFinetuning({ userId, taskId });
        // console.log('finetuning: ', finetuning);
        setFinetuning(finetuning.data);
        setIsLoading(false);
    };

    // useEffects
    useEffect(() => {
        initialize();
    }, []);

    // Return
    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <>
            <Typography variant="h1" sx={{ fontFamily: 'Inter', fontWeight: 500 }}>
                {task.action}
            </Typography>
            <Alert severity="info" sx={{ my: 2 }}>
                Specify examples of input/output to help the AI generate content that fits your needs and habits.
            </Alert>
            <FinetuningCard taskId={taskId} userId={userId} />
        </>
    );
};

export default GeneralTaskFinetuning;
