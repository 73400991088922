import { EdgeProps, getSmoothStepPath } from 'react-flow-renderer';
import ButtonDeleteNode from '../../components/buttonDeleteNode';
import { useContext } from 'react';
import DocumentBuilderContext from '../../../document-builder/context/workflow.context';
import { onEdgeRemove } from '../hooks/delete-edges';

const foreignObjectSize = 40;

const CustomEdge = ({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style = {}, markerEnd }: EdgeProps) => {
    const { documentBuilder } = useContext(DocumentBuilderContext);

    const edgePath = getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
        borderRadius: 50
    });

    const height = edgePath.split(',');
    const buttonPos = (targetY - sourceY) / 2;
    const posY = +height[height.length - 1] - buttonPos;
    const posX = sourceX > targetX ? targetX - foreignObjectSize : targetX + foreignObjectSize / 2;

    return (
        <>
            <path id={id} style={style} className="react-flow__edge-path" d={edgePath} markerEnd={markerEnd} />
            <path id={id} className="react-flow__edge-canvas" fill="none" strokeWidth="20" strokeOpacity="0" d={edgePath} />
            <foreignObject
                width={foreignObjectSize}
                height={foreignObjectSize}
                x={posX}
                y={posY}
                className="edgebutton-foreignobject"
                requiredExtensions="http://www.w3.org/1999/xhtml"
            >
                <ButtonDeleteNode className="delbutton" onClick={onEdgeRemove(documentBuilder.workflow, id)} />
            </foreignObject>
        </>
    );
};

export default CustomEdge;
