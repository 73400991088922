import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { NextButton, RowBody } from '../../../../features/document-builder/ui';
import { ReactComponent as LeftArrowIcon } from 'assets/images/arrow-left.svg';
import TemplateSettings from './components/template-settings';
import { FC } from 'react';
import WorkflowPreview from './components/workflow-preview';

interface TemplateCustomizationProps {
    backToPreview: () => void;
}

const Index: FC<TemplateCustomizationProps> = ({ backToPreview }) => {
    const theme = useTheme();

    return (
        <Box sx={{ display: 'flex' }}>
            <Box sx={{ flex: 9 }}>
                <RowBody
                    sx={{
                        height: '56px',
                        justifyContent: 'space-between',
                        border: '1px solid #D4DBEA',
                        padding: '16px 24px',
                        borderRight: 'none'
                    }}
                >
                    <RowBody sx={{ gap: '8px', cursor: 'pointer' }} onClick={backToPreview}>
                        <LeftArrowIcon />
                        <Typography variant="subtitle1" sx={{ color: theme.palette.text.primary }}>
                            Customization
                        </Typography>
                    </RowBody>
                    <RowBody sx={{ gap: '10px' }}>
                        <Typography sx={{ fontWeight: 500, fontSize: '10px', color: '#7A7A7A' }}>TOTAL TASK DURATION</Typography>
                        <Typography sx={{ fontWeight: 700, fontSize: '14px', color: theme.palette.text.primary }}>20 days</Typography>
                    </RowBody>
                </RowBody>
                <WorkflowPreview />
            </Box>
            <Box sx={{ flex: 5 }}>
                <RowBody sx={{ justifyContent: 'space-between', height: '56px', padding: '16px', border: '1px solid #D4DBEA' }}>
                    <Typography variant="subtitle1" sx={{ color: theme.palette.text.primary }}>
                        Template Settings
                    </Typography>
                    <RowBody sx={{ gap: '8px' }}>
                        <Typography sx={{ color: '#AAAAAA', fontSize: '14px', fontWeight: 400 }}>Changes Saved</Typography>
                        <NextButton
                            onClick={
                                //     async () => {
                                //     const response = await fetch(`${BACKEND_ENDPOINT}/workflows/save/${WORKFLOW_ID}`, {
                                //         method: 'POST',
                                //         body: JSON.stringify({ bpmn: xml })
                                //     });
                                //     const payload = await response.json();
                                //     console.log('After saving the workflow the response is: ', payload);
                                // }
                                () => {
                                    console.log('POST THIS WORKFLOW ASAP');
                                }
                            }
                        >
                            Save & close
                        </NextButton>
                    </RowBody>
                </RowBody>
                <TemplateSettings />
            </Box>
        </Box>
    );
};

export default Index;
