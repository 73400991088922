import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

interface CheckboxInterface {
    id: string;
    name: string;
}
interface CheckboxProps {
    options: CheckboxInterface[];
    setCheckboxValue: any;
    checkboxValue: any;
    sx?: { [key: string]: any };
}
const CheckboxFilter = ({ options, setCheckboxValue, checkboxValue, sx }: CheckboxProps) => {
    const handleChangeCheckbox = (checkbox: CheckboxInterface) => {
        const newValues = [...checkboxValue];
        if (newValues.includes(checkbox.id)) {
            newValues.splice(newValues.indexOf(checkbox.id), 1);
        } else {
            newValues.push(checkbox.id);
        }
        setCheckboxValue(newValues);
    };
    return (
        <FormGroup>
            {options.map((checkbox: CheckboxInterface) => (
                <FormControlLabel
                    sx={{
                        '.MuiFormControlLabel-label': { fontFamily: 'Inter', fontSize: '14px', mt: '2px' },
                        ...sx
                    }}
                    control={
                        <Checkbox
                            checkedIcon={<CheckBoxOutlinedIcon />}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }}
                            checked={checkboxValue.includes(checkbox.id)}
                            onChange={() => handleChangeCheckbox(checkbox)}
                            name={checkbox.name}
                        />
                    }
                    label={checkbox.name}
                    key={checkbox.id}
                />
            ))}
        </FormGroup>
    );
};

export default CheckboxFilter;
