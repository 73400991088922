import { Typography, Box, Dialog, CardMedia, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

interface SnackbarCustomProps {
    open: boolean;
    handleClose: () => void;
    selectedHistory: any;
    selectedImage: number;
    nextImage: any;
    prevImage: any;
    resultsValue: any;
}

const DialogImages = ({ open, handleClose, selectedHistory, selectedImage, nextImage, prevImage, resultsValue }: SnackbarCustomProps) => {
    const theme = useTheme();
    const download = (e: any) => {
        console.log(e.target.href);
        fetch(e.target.href, {
            method: 'GET',
            headers: {}
        })
            .then((response) => {
                response.arrayBuffer().then((buffer) => {
                    const url = window.URL.createObjectURL(new Blob([buffer]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'image.png');
                    document.body.appendChild(link);
                    link.click();
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <Dialog
            sx={{
                '.MuiPaper-root': {
                    minWidth: '220px',
                    borderRadius: '8px',
                    p: 3,
                    maxWidth: '700px'
                }
            }}
            open={open}
            onClose={handleClose}
        >
            <Typography sx={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '16px', mb: 3 }}>
                <FormattedMessage id="preview_box" />
            </Typography>
            {selectedHistory ? (
                <Box sx={{ position: 'relative' }}>
                    <Box sx={{ width: '100%' }}>
                        <CardMedia sx={{ objectFit: 'contain' }} component="img" image={selectedHistory[selectedImage]} alt="" />
                    </Box>
                    <Box sx={{ position: 'abolute', top: 1, right: 1 }}>
                        <a
                            className="downloadImage"
                            target="_blank"
                            href={selectedHistory[selectedImage]}
                            download
                            onClick={(e) => download(e)}
                            rel="noreferrer"
                        >
                            <Box sx={{ background: '#fff', borderRadius: '6px', p: '0px 2px' }}>
                                <FileDownloadOutlinedIcon sx={{ fill: theme.palette.orange.main, mb: '-3px' }} />
                            </Box>
                        </a>
                    </Box>
                </Box>
            ) : (
                <Box sx={{ position: 'relative' }}>
                    <Box sx={{ width: '100%' }}>
                        <CardMedia
                            sx={{ objectFit: 'contain' }}
                            component="img"
                            image={resultsValue && resultsValue[selectedImage]}
                            alt=""
                        />
                    </Box>
                    <Box sx={{ position: 'abolute', top: 1, right: 1 }}>
                        <a
                            className="downloadImage"
                            target="_blank"
                            href={resultsValue && resultsValue[selectedImage]}
                            download
                            onClick={(e) => download(e)}
                            rel="noreferrer"
                        >
                            <Box sx={{ background: '#fff', borderRadius: '6px', p: '0px 2px' }}>
                                <FileDownloadOutlinedIcon sx={{ fill: theme.palette.orange.main, mb: '-3px' }} />
                            </Box>
                        </a>
                    </Box>
                </Box>
            )}

            {selectedHistory ? (
                <Box sx={{ position: 'relative', mt: 3, display: 'flex', justifyContent: 'center' }}>
                    <Typography sx={{ fontFamily: 'Inter', fontWeight: 500, fontSize: '12px' }}>
                        {`${selectedImage + 1}/${selectedHistory.length}`}
                    </Typography>
                    <Box sx={{ display: 'flex', position: 'absolute', top: '-5px', right: 0 }}>
                        <Button onClick={prevImage} disabled={selectedImage === 0} sx={{ mr: 4, minWidth: '10px', p: 0 }}>
                            Prev
                        </Button>
                        <Button onClick={nextImage} disabled={selectedImage + 1 === selectedHistory.length} sx={{ minWidth: '10px', p: 0 }}>
                            Next
                        </Button>
                    </Box>
                </Box>
            ) : (
                <Box sx={{ position: 'relative', mt: 3, display: 'flex', justifyContent: 'center' }}>
                    <Typography sx={{ fontFamily: 'Inter', fontWeight: 500, fontSize: '12px' }}>
                        {`${selectedImage + 1}/${resultsValue?.length}`}
                    </Typography>
                    <Box sx={{ display: 'flex', position: 'absolute', top: '-5px', right: 0 }}>
                        <Button onClick={prevImage} disabled={selectedImage === 0} sx={{ mr: 4, minWidth: '10px', p: 0 }}>
                            Prev
                        </Button>
                        <Button onClick={nextImage} disabled={selectedImage + 1 === resultsValue?.length} sx={{ minWidth: '10px', p: 0 }}>
                            Next
                        </Button>
                    </Box>
                </Box>
            )}
        </Dialog>
    );
};

export default DialogImages;
