import { Dialog } from '@mui/material';
import { FC, useContext } from 'react';
import { ClientDetailsContext } from 'views/pages/clients/context/client-details.context';
import WorkflowBuilder from '../features/document-builder';

export interface WorkflowDialogState {
    state: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}
const CreateWorkflowDialog: FC<WorkflowDialogState> = ({ state }) => {
    const [showDialog, setShowDialog] = state;
    const { updateWorkflows } = useContext(ClientDetailsContext);

    const closeDialog = () => {
        setShowDialog(false);
        updateWorkflows();
    };

    if (!showDialog) return null;
    return (
        <Dialog
            fullScreen
            open={showDialog}
            onClose={closeDialog}
            aria-labelledby="create-new-workflow-modal"
            sx={{ '.MuiPaper-root': { p: 0 } }}
        >
            <WorkflowBuilder closeDialog={closeDialog} />
        </Dialog>
    );
};

export default CreateWorkflowDialog;
