/* eslint-disable react/no-unused-prop-types */
import { Box, Typography, Grid, Avatar } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import { get } from 'lodash';
import { PopOverSelector } from '.';

interface PopOverSelectorProps<OptionType> {
    options: OptionType[];
    optionLabelPath: string;
    optionIconPath: string;
    setSelectedOption?: (item: OptionType) => void;
    buttonType?: 'icon' | 'button';
    buttonText?: string;
    buttonOverride?: React.FunctionComponent;
    searchInputPlaceholder?: string;
}

interface FullPopOVerSelectorProps<OptionType> extends PopOverSelectorProps<OptionType> {
    selectedOption?: OptionType;
    title?: string;
}

const FullPopOverSelector = <OptionType extends unknown>(props: FullPopOVerSelectorProps<OptionType>) => {
    const theme = useTheme();
    const { selectedOption, optionIconPath, optionLabelPath, title } = props;

    return (
        <Box>
            {title && (
                <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600, fontSize: '14px' }}>
                    <FormattedMessage id={title} />
                </Typography>
            )}
            <Grid container>
                {selectedOption && (
                    <Grid
                        mt={2}
                        item
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingRight: '10px',
                            borderRight: `1px solid ${theme.palette.grey[500]}`
                        }}
                    >
                        <Box sx={{ width: '32px' }}>
                            <Avatar sx={{ width: '32px', height: '32px' }} alt="Remy Sharp" src={get(selectedOption, optionIconPath)} />
                        </Box>
                        <Typography ml={1} sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600, fontSize: '14px' }}>
                            {get(selectedOption, optionLabelPath)}
                        </Typography>
                    </Grid>
                )}
                <Grid item mt={2}>
                    <PopOverSelector {...props} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default FullPopOverSelector;
