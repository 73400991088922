import * as React from 'react';
import { Dialog, Box, Typography, FormControlLabel, Checkbox } from '@mui/material';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useTheme } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import { Button } from 'ui-component/extended/Button';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { useEffect, useState } from 'react';
import { getProcessmakerRequests } from 'services/rest/workflow_run';
import useAuth from 'hooks/useAuth';
import { generateWorkflowRunTitle } from '../utils/workflow-run.utils';

const Transition = React.forwardRef((props: TransitionProps & { children: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => (
    <Slide direction="down" ref={ref} {...props} />
));

interface CancelWorkflowRunsDialogProps {
    open: boolean;
    handleClose: () => void;
    handleSuccess: () => void;
    selectedIds: number[];
    workflowId: string;
    setSelectedIds: (ids: number[]) => void;
}

export default function CancelWorkflowRunsDialog({
    open,
    handleClose,
    handleSuccess,
    workflowId,
    selectedIds,
    setSelectedIds
}: CancelWorkflowRunsDialogProps) {
    const theme = useTheme();
    const { member } = useAuth();

    const [options, setOptions] = useState<{ id: number; title: string }[]>([]);
    const [shouldOpen, setShouldOpen] = useState(open);

    useEffect(() => {
        if (open) {
            const getWorkflowRunData = async () => {
                const {
                    data: { workflow_runs }
                } = await getProcessmakerRequests(workflowId, member?.organizationId);

                const workflowRunsToCancel = workflow_runs
                    .filter((workflowRun: any) => workflowRun.status === 'ACTIVE')
                    .map((workflowRun: any) => ({
                        id: workflowRun.processmaker_request_id,
                        title: `Run - ${generateWorkflowRunTitle(workflowRun)}`
                    }));

                setOptions(workflowRunsToCancel);
                if (workflowRunsToCancel.length === 0) {
                    handleSuccess();
                    return;
                }
                setShouldOpen(open);
            };

            getWorkflowRunData();
        } else {
            setShouldOpen(open);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const allItemsAreSelected = options.length === selectedIds.length;

    const selectAllItemsHandler = () => {
        if (allItemsAreSelected) {
            setSelectedIds([]);
        } else {
            setSelectedIds(options.map((item) => item.id));
        }
    };

    const selectItemHandler = (id: number) => {
        if (selectedIds.includes(id)) {
            setSelectedIds(selectedIds.filter((item) => item !== id));
        } else {
            const newSelectedIds = [...selectedIds, id];
            setSelectedIds(newSelectedIds);
        }
    };

    return (
        <Dialog
            open={shouldOpen}
            onClose={handleClose}
            TransitionComponent={Transition}
            sx={{ '.MuiPaper-root': { p: 0, borderRadius: '16px' } }}
        >
            <Box p={5}>
                <Typography
                    sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500, fontSize: '20px', textAlign: 'center' }}
                >
                    <FormattedMessage
                        id="The changes will only
                    be effective for the next workflow runs"
                    />
                </Typography>
                <Typography
                    sx={{
                        color: theme.palette.grey[300],
                        fontFamily: 'Inter',
                        fontWeight: 400,
                        fontSize: '14px',
                        textAlign: 'center',
                        mt: 4
                    }}
                >
                    There are existing workflow runs still in progress and your changes will not be applied to them but you can decide to
                    cancel them by selecting them in the list here below.
                </Typography>
                <Box
                    sx={{
                        background: theme.palette.warning[200],
                        p: '16px 24px',
                        borderRadius: '8px',
                        mt: 4,
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <FormControlLabel
                        sx={{ '.MuiFormControlLabel-label': { fontFamily: 'Inter', fontSize: '14px', color: '#000' } }}
                        control={
                            <Checkbox
                                checkedIcon={<CheckBoxOutlinedIcon />}
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }}
                                checked={allItemsAreSelected}
                                onChange={selectAllItemsHandler}
                                name="gilad"
                            />
                        }
                        label="Select All"
                    />

                    {options.map((option) => (
                        <FormControlLabel
                            key={JSON.stringify(option.id)}
                            sx={{ '.MuiFormControlLabel-label': { fontFamily: 'Inter', fontSize: '14px', color: '#000' } }}
                            control={
                                <Checkbox
                                    checkedIcon={<CheckBoxOutlinedIcon />}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }}
                                    checked={selectedIds.includes(option.id)}
                                    onChange={() => selectItemHandler(option.id)}
                                    name="gilad"
                                />
                            }
                            label={option.title}
                        />
                    ))}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <Box sx={{ width: '102px', mr: 2 }}>
                        <Button title="Yes, do it" onClick={handleSuccess} variant="primary" />
                    </Box>
                    <Box sx={{ width: '102px' }}>
                        <Button title="No, don’t" onClick={handleClose} variant="secondary" />
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );
}
