import { Box, Button, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import { DndProvider } from 'react-dnd';
import { ReactComponent as LeftArrowIcon } from 'assets/images/arrow-left.svg';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactFlow, { useEdgesState, useNodesState, Node, Edge, MarkerType } from 'react-flow-renderer';
import { ReactComponent as BranchIcon } from 'assets/images/branch.svg';

import { FC, useContext, useEffect, useState } from 'react';

import {
    ColumnBody,
    ColumnHeader,
    ColumnFooter,
    NextButton,
    BoxNavbar,
    BoxTemplate,
    BoxRect,
    BackButton
} from '../../../../features/document-builder/ui';
import { FIELD_TYPE, Page, Section, FormData } from '../../../../features/types';
import DocumentBuilderContext from '../../../../features/document-builder/context/workflow.context';
import designer from 'assets/images/designer.svg';
import productlaunch from 'assets/images/productlaunch.svg';
import CustomEdge from '../../../../templates/CustomEdge';
import newflow from '../../../../templates/workflowTemplate/newFlow';
import newscheduling from '../../../../templates/workflowTemplate/newScheduling';
import newtotal from '../../../../templates/workflowTemplate/newTotal';
import customnode from '../../../../templates/workflowTemplate/customNode';

interface dataFormProps {
    createTemplate: () => void;
}

const nodeTypes = {
    Newflow: newflow,
    NewScheduling: newscheduling,
    NewTotal: newtotal,
    CustomNode: customnode
};

const edgeTypes = {
    customedge: CustomEdge
};

const initialNodes: Node[] = [
    {
        id: 'conception',
        type: 'Newflow',
        data: {
            title: '1. Conception',
            short: '• MANGER',
            value: 'Create all the post concept to be published within a future 4-week timeframe'
        },
        position: { x: 200, y: 39 }
    },
    {
        id: 'clientreview',
        type: 'Newflow',
        data: {
            title: '2. Concept client review',
            short: '• MANGER',
            value: 'Reviewing monthly calendar created by Social Manager. Can do direct edit to the claendar, provide feedback on it or directly validate it'
        },
        position: { x: 200, y: 239 }
    },
    {
        id: 'copywriting',
        type: 'Newflow',
        data: { title: '3. Copywriting', short: '• COPYWRITER', value: 'Writing caption for a publication' },
        position: { x: 200, y: 439 }
    },
    {
        id: 'design',
        type: 'Newflow',
        data: { title: '4. Design', short: '• DESIGNER', value: 'Uploading assets linked to a publication' },
        position: { x: 200, y: 639 }
    },
    {
        id: 'qualityassurance',
        type: 'NewTotal',
        data: {
            title: '5. Quality Assurance',
            short: '• MANGER',
            value: 'Reviewing publication made by Social Manager and Operational Designer. When submitted task is disapproved, it gets sent back to copywriting'
        },
        position: { x: 115, y: 839 }
    },
    {
        id: 'totalreview',
        type: 'NewTotal',
        data: {
            title: '6. Client Review',
            short: '• Client',
            value: 'Reviewing all publications to be published on a given week. When submitted task is disapproved, it gets sent back to copywriting'
        },
        position: { x: 115, y: 1239 }
    },
    {
        id: 'scheduling',
        type: 'NewScheduling',
        data: {},
        position: { x: 200, y: 1639 }
    },
    {
        id: 'qualitydeclined',
        type: 'CustomNode',
        data: {},
        position: { x: 600, y: 1115 }
    },
    {
        id: 'totaldeclined',
        type: 'CustomNode',
        data: {},
        position: { x: 600, y: 1515 }
    }
];

const initialEdges = [
    { id: 'hidden-e1-2', type: 'customedge', markerEnd: { type: MarkerType.Arrow }, source: 'conception', target: 'clientreview' },
    { id: 'hidden-e1-3', type: 'customedge', markerEnd: { type: MarkerType.Arrow }, source: 'clientreview', target: 'copywriting' },
    { id: 'hidden-e3-4', type: 'customedge', markerEnd: { type: MarkerType.Arrow }, source: 'copywriting', target: 'design' },
    { id: 'hidden-e3-5', type: 'customedge', markerEnd: { type: MarkerType.Arrow }, source: 'design', target: 'qualityassurance' },
    { id: 'hidden-e3-6', type: 'customedge', markerEnd: { type: MarkerType.Arrow }, source: 'qualityassurance', target: 'totalreview' },
    { id: 'hidden-e3-8', type: 'smoothstep', markerEnd: { type: MarkerType.Arrow }, source: 'qualityassurance', target: 'qualitydeclined' },
    { id: 'hidden-e3-6', type: 'customedge', markerEnd: { type: MarkerType.Arrow }, source: 'totalreview', target: 'scheduling' },
    { id: 'hidden-e3-8', type: 'smoothstep', markerEnd: { type: MarkerType.Arrow }, source: 'totalreview', target: 'totaldeclined' }
];

interface PreviewTemplateProps {
    backToOptions: () => void;
    forwardToCustomization: () => void;
}

const PreviewTemplate: FC<PreviewTemplateProps> = ({ backToOptions, forwardToCustomization }) => {
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    const { setPageIndex } = useContext(DocumentBuilderContext);

    const theme = useTheme();

    // useEffect(() => {
    //     const newFlowNodes: WorkFlowNode[] = [...values.workflow.flowNodes];
    //     const newNodes: Node[] = getNodesFromFlow(newFlowNodes, flowPosX, flowPosY, values.workflow.flowStyle);

    //     setNodes(newNodes);
    //     setEdges(getEdgesFromNodes(newNodes, newFlowNodes));

    // }, [values]);

    return (
        <DndProvider backend={HTML5Backend}>
            <Box mt={2} sx={{ display: 'flex', borderTop: '1px solid #D4DBEA' }}>
                <Box sx={{ width: '300px' }}>
                    <ColumnHeader>
                        <Typography variant="subtitle1">
                            <FormattedMessage id="templates" />
                        </Typography>
                    </ColumnHeader>
                    <ColumnBody sx={{ paddingTop: '18px', height: '450px' }}>
                        <BoxNavbar>
                            1
                            <BoxTemplate>
                                <img src={designer} alt="" style={{ position: 'absolute' }} />
                                <BoxRect>Instagram Template Creation </BoxRect>
                            </BoxTemplate>
                        </BoxNavbar>
                        <BoxNavbar
                            sx={{
                                color: '#36333326',
                                fontWeight: '900'
                            }}
                        >
                            2<BoxTemplate sx={{ color: '#36333326' }} />
                        </BoxNavbar>
                        <BoxNavbar
                            sx={{
                                color: '#36333326',
                                fontWeight: '900'
                            }}
                        >
                            3<BoxTemplate sx={{ color: '#36333326' }} />
                        </BoxNavbar>
                    </ColumnBody>
                    <ColumnFooter>
                        <img src={productlaunch} alt="" />
                        <Box sx={{ paddingTop: '20px' }}> More templates coming soon</Box>
                        <Link>Learn More</Link>
                    </ColumnFooter>
                </Box>
                <Box sx={{ flex: 1 }}>
                    <ColumnHeader sx={{ gap: '12px' }}>
                        <BackButton onClick={backToOptions}>
                            <LeftArrowIcon />
                            <FormattedMessage id="options" />
                        </BackButton>
                        <NextButton onClick={forwardToCustomization}>
                            <FormattedMessage id="useTemplate" />
                        </NextButton>
                    </ColumnHeader>
                    <ColumnBody sx={{ display: 'flex' }}>
                        <Box sx={{ width: '70%' }}>
                            <Box
                                id="description"
                                sx={{
                                    marginTop: '30px',
                                    marginLeft: '30px',
                                    padding: '20px',
                                    border: '1px solid #c1bebe',
                                    borderRadius: '8px',
                                    boxShadow: '1px 2px 2px 1px #d3d3d375'
                                }}
                            >
                                <Box sx={{ fontSize: '18px', paddingTop: '20px', paddingBottom: '30px', fontWeight: 'bold' }}>
                                    Description
                                </Box>
                                Cursus neque viverra tellus felis ac, in arcu. Tincidunt non nullam id justo. Ornare amet et pellentesque
                                quis egestas. Neque, enim, lacus, felis est ullamcorper. Tincidunt varius eget dictum malesuada duis
                                molestie consequat eget. Enim in aliquet maecenas enim. Et praesent sed quis curabitur ultricies. Massa at
                                orci ut at bibendum morbi enim, varius. Sit tellus amet enim eget condimentum sit. Et, mollis sed massa
                                imperdiet volutpat vestibulum. Suscipit sagittis aliquam libero dictum sit.
                            </Box>
                            <Box
                                sx={{
                                    marginTop: '10px',
                                    marginLeft: '30px',
                                    padding: '20px',
                                    border: '1px solid #c1bebe',
                                    borderRadius: '8px',
                                    boxShadow: '1px 2px 2px 1px #d3d3d375'
                                }}
                                id="deliverables"
                            >
                                <Box sx={{ fontSize: '18px', paddingTop: '20px', paddingBottom: '30px', fontWeight: 'bold' }}>
                                    Deliverables
                                </Box>
                                Morbi faucibus eleifend massa id. Nullam diam mi scelerisque velit aliquam, faucibus gravida a. Velit, velit
                                dictum vitae varius montes, metus, amet, nulla et. Eu arcu fringilla nulla adipiscing neque nisl, ipsum
                                egestas in. Fusce dolor ridiculus tincidunt diam odio purus amet. Egestas quam amet faucibus faucibus varius
                                donec dignissim mauris sagittis. Mi, nulla rhoncus non vitae condimentum velit amet. Eu faucibus odio eget
                                eu quis tellus posuere ut sed.
                            </Box>
                            <Box
                                id="steps"
                                sx={{
                                    border: '1px dashed #D4DBEA',
                                    borderRadius: '8px',
                                    height: '1500px',
                                    margin: '24px 0px 0px 30px',
                                    padding: '20px'
                                }}
                            >
                                <Typography sx={{ fontWeight: 500, fontSize: '16px', color: theme.palette.text.primary }}>Steps</Typography>
                                <ReactFlow
                                    nodes={nodes}
                                    edges={edges}
                                    nodeTypes={nodeTypes}
                                    edgeTypes={edgeTypes}
                                    onNodesChange={onNodesChange}
                                    onEdgesChange={onEdgesChange}
                                    nodesDraggable={false}
                                    defaultZoom={0.8}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ width: '30%' }}>
                            <Box
                                sx={{
                                    marginTop: '30px',
                                    marginLeft: '30px',
                                    marginRight: '30px',
                                    border: '1px solid #c1bebe',
                                    borderRadius: '8px',
                                    paddingTop: '20px',
                                    paddingBottom: '30px',
                                    boxShadow: '1px 2px 2px 1px #d3d3d375',
                                    width: '20%',
                                    position: 'absolute'
                                }}
                            >
                                <Box sx={{ borderBottom: '1px solid #c1bebe', paddingBottom: '20px', paddingLeft: '20px' }}>
                                    Content of Template
                                </Box>
                                <Box sx={{ position: 'relative' }}>
                                    <Box>
                                        <BranchIcon style={{ position: 'absolute', left: '25px', zIndex: 0 }} />
                                        <Button sx={{ marginLeft: '30px', marginTop: '14px', color: 'black' }} href="#description">
                                            Description
                                        </Button>
                                    </Box>
                                    <Box>
                                        <BranchIcon
                                            style={{ position: 'absolute', left: '25px', top: '25px', height: '60px', zIndex: 0 }}
                                        />
                                        <Button sx={{ marginLeft: '30px', marginTop: '5px', color: 'black' }} href="#deliverables">
                                            Deliverables
                                        </Button>
                                    </Box>
                                    <Box>
                                        <BranchIcon
                                            style={{ position: 'absolute', left: '25px', top: '60px', height: '70px', zIndex: 0 }}
                                        />
                                        <Button sx={{ marginLeft: '30px', marginTop: '0px', color: 'black' }} href="#steps">
                                            Steps
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </ColumnBody>
                </Box>
            </Box>
        </DndProvider>
    );
};

export default PreviewTemplate;
