import { Box, Typography, CardMedia, Dialog, Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';
import download from 'assets/images/download.svg';
import React from 'react';

interface DIalogWeiwImageProps {
    handleCloseDialogImage: any;
    image: string;
    openDialogImage: boolean;
    skil: string;
    imagesCount: number;
    selectedImages: number;
    handleSelectedImagePrev: any;
    handleSelectedImageNext: any;
}

const DIalogWeiwImage = ({
    handleCloseDialogImage,
    image,
    openDialogImage,
    skil,
    selectedImages,
    imagesCount,
    handleSelectedImagePrev,
    handleSelectedImageNext
}: DIalogWeiwImageProps) => {
    const theme = useTheme();

    return (
        <Dialog onClose={handleCloseDialogImage} open={openDialogImage} sx={{ '.MuiPaper-root': { p: '30px' } }}>
            <Typography
                sx={{
                    color: '#2D2D2D',
                    fontFamily: 'Inter',
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: '150%',
                    mb: 3
                }}
            >
                {skil}
            </Typography>
            <CardMedia
                sx={{ objectFit: 'cover', borderRadius: '5px', cursor: 'pointer' }}
                component="img"
                width="100%"
                height="100%"
                image={image}
                alt="alt image"
            />
            <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                <Typography
                    sx={{
                        color: '#2D2D2D',
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        fontSize: '12px',
                        lineHeight: '150%',
                        mt: 3
                    }}
                >
                    {`${selectedImages}/${imagesCount}`}
                </Typography>
                <Box sx={{ position: 'absolute', right: 0, top: 20 }}>
                    <Button
                        disabled={selectedImages - 1 === 0}
                        onClick={handleSelectedImagePrev}
                        sx={{
                            p: 0,
                            minWidth: '10px',
                            color: selectedImages === imagesCount ? theme.palette.orange.main : theme.palette.grey[400]
                        }}
                    >
                        <FormattedMessage id="prev" />
                    </Button>
                    <Button
                        disabled={selectedImages === imagesCount}
                        onClick={handleSelectedImageNext}
                        sx={{
                            p: 0,
                            minWidth: '10px',
                            ml: 4,
                            color: selectedImages - 1 === 0 ? theme.palette.orange.main : theme.palette.grey[400]
                        }}
                    >
                        <FormattedMessage id="next" />
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

export default DIalogWeiwImage;
