import { gql } from '@apollo/client';

const INVITE_MEMBER = gql`
    mutation inviteMember($email: String!, $message: String!, $title: String!) {
        client_invitation_create(arg1: { email: $email, message: $message, title: $title }) {
            agency_id
            client_email
            client_organization_id
            created_at
            status
            updated_at
        }
    }
`;
const DELETE_MEMBER = gql`
    mutation Mutation($organization_id: String!, $user_id: String!) {
        organization_member_remove(arg1: { organization_id: $organization_id, user_id: $user_id }) {
            organization_id
            role_id
            status
            type
            user_id
        }
    }
`;
const ADD_MEMBER_DEV = gql`
    mutation AddMemberDev($organization_id: String!, $user_id: String!, $role: String!) {
        organization_member_add(arg1: { organization_id: $organization_id, user_id: $user_id, role: $role }) {
            organization_id
            role_id
            status
            type
            user_id
        }
    }
`;
const CANCEL_INVITE = gql`
    mutation cancelInvite($email: String!, $message: String!) {
        organization_invitation_cancel(arg1: { message: $message, email: $email }) {
            updated_at
            status
            agency_id
            member_email
            created_at
            message
        }
    }
`;
const ACCEPT_INVITE = gql`
    mutation Mutation($user_id: String!, $email: String!) {
        organization_invitation_accept(arg1: { user_id: $user_id, email: $email }) {
            updated_at
            status
            agency_id
            member_email
            created_at
            message
        }
    }
`;
const SUBMIT_SPECIALIST = gql`
    mutation MyMutation($description: String, $organizationId: String, $organizationName: String, $role: String, $status: String) {
        insert_requests(
            organizationName: $organizationName
            role: $role
            status: $status
            description: $description
            organizationId: $organizationId
        ) {
            id
            organizationId
            organizationName
            role
            description
            status
            createdAt
        }
    }
`;

// todo : insert actual mutation when ready
const ADD_MEMBER = gql`
    mutation MyMutation($email: String!, $first_name: String!, $last_name: String!, $role: String!, $title: String!) {
        add_organization_user(arg1: { email: $email, first_name: $first_name, last_name: $last_name, role: $role, title: $title }) {
            outcome
        }
    }
`;

const UPDATE_MEMBER_BY_PK = gql`
    mutation UpdateOrganizationMemberByPk($id: uuid!, $title: String, $roleId: roles_enum) {
        update_organization_members_by_pk(pk_columns: { id: $id }, _set: { title: $title, role_id: $roleId }) {
            id
            title
            role_id
        }
    }
`;

// eslint-disable-next-line import/prefer-default-export
export { INVITE_MEMBER, DELETE_MEMBER, ADD_MEMBER_DEV, CANCEL_INVITE, ACCEPT_INVITE, SUBMIT_SPECIALIST, ADD_MEMBER, UPDATE_MEMBER_BY_PK };
