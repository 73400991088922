import { Box, Drawer, Typography, Grid, Input, Popover, ClickAwayListener } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import { Button } from 'ui-component/extended/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import DescriptionInputAiTool from 'modules/ai-tools/DescriptionInputAiTool';
import { ADD_CAPTION_EXAMPLE, EDIT_CAPTION_EXAMPLE } from 'services/graphQL/mutations/aiTools/AiToolsMutations';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { GET_CAPTION_EXAMPLE_SUBCATEGORIES } from 'services/graphQL/queries/aiTool';
import { useParams } from 'react-router-dom';

export interface CaptionGeneratorCardType {
    subcategory_id: string;
    client_organization_id: string;
    caption: string;
    concept: string;
    id: string;
}
interface DrawerAddOffersProps {
    open: boolean;
    onClose: () => void;
    item?: CaptionGeneratorCardType;
    getCaptions?: any;
    refetchGetCaptions?: any;
}

export interface Subcategory {
    name: string;
    category_id?: string;
}

const AddCaptionGeneratorDrawer = ({ open, onClose, item, getCaptions, refetchGetCaptions }: DrawerAddOffersProps) => {
    const theme = useTheme();
    const intl = useIntl();
    const dispatch = useDispatch();
    const showError = false;
    const [selectedLabel, setSelectedLabel] = React.useState<Subcategory | null>(null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
    const [subcategories, setSubcategories] = React.useState<Subcategory[]>([]);
    const [descriptionValid, setDescriptionValid] = React.useState(false);
    const { idClient } = useParams();

    const openPopover = (event: React.MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleSelectedLabel = (e: React.MouseEvent<HTMLDivElement>, value: Subcategory) => {
        e.stopPropagation();
        setSelectedLabel(value);
        handleClose();
    };
    const id = anchorEl ? 'simple-popover' : undefined;
    const formik = useFormik({
        initialValues: {
            name: '',
            description: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().trim('The name cannot be all spaces').required('Required'),
            description: Yup.string().trim().required('Required')
        }),
        onSubmit: (values) => {
            if (item) {
                editCaptionExample({
                    variables: {
                        id: item.id,
                        caption: values.description,
                        concept: `${selectedLabel?.name} ${values.name}`,
                        subcategory_id: selectedLabel?.category_id
                    }
                });
            } else {
                onAdd();
            }
        }
    });
    const onAdd = async () => {
        await addCaptionExample({
            variables: {
                client_organization_id: idClient,
                caption: formik.values.description,
                concept: `${selectedLabel?.name} ${formik.values.name}`,
                subcategory_id: selectedLabel?.name
            }
        });
        await refetchGetCaptions();
    };
    const handleDesc = (value: string) => formik.setFieldValue('description', value);

    const [addCaptionExample, { loading: addLoading }] = useMutation(ADD_CAPTION_EXAMPLE, {
        onCompleted: (data) => {
            onClose();
        },
        onError: (e) => {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e.message,
                    variant: 'error'
                })
            );
        }
    });
    const [editCaptionExample, { loading: editLoading }] = useMutation(EDIT_CAPTION_EXAMPLE, {
        onCompleted: (data) => {
            getCaptions();
            onClose();
        },
        onError: (e) => {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e.message,
                    variant: 'error'
                })
            );
        }
    });

    const [getCaptionExampleSubcategories] = useLazyQuery(GET_CAPTION_EXAMPLE_SUBCATEGORIES, {
        onCompleted: (data) => {
            setSubcategories(data.caption_example_subcategories);
        }
    });

    React.useEffect(() => {
        getCaptionExampleSubcategories();
    }, []);

    React.useEffect(() => {
        if (open) {
            if (item) {
                let name = null;
                setSelectedLabel({
                    name: item.subcategory_id
                });

                name = item.concept.slice(item.subcategory_id.length + 1);

                formik.setFieldValue('name', name ?? item.concept);
                formik.setFieldValue('description', item.caption);
            } else {
                formik.setFieldValue('name', '');
                formik.setFieldValue('description', '');
                setSelectedLabel(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item, open]);

    return (
        <Drawer anchor="right" open={open} onClose={onClose}>
            <Box sx={{ width: { xs: '100%', sm: '562px' } }}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container direction="column" justifyContent="space-between" sx={{ minHeight: '100vh' }}>
                        <Grid item sx={{ p: '32px' }} xs={10}>
                            <Typography
                                mb={3}
                                sx={{ fontWeight: 600, fontFamily: 'Inter', color: theme.palette.common.black, fontSize: '16px' }}
                            >
                                <FormattedMessage id="caption_generation" />
                            </Typography>
                            <Typography
                                mb={2}
                                sx={{
                                    color: theme.palette.grey[400],
                                    fontFamily: 'Inter',
                                    fontWeight: 600,
                                    fontSize: '14px',
                                    textTransform: 'capitalize'
                                }}
                            >
                                <FormattedMessage id="concept_description" />
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    border: `1px solid ${theme.palette.grey[500]}`,
                                    borderRadius: '8px',
                                    p: 1,
                                    alignItems: 'center',
                                    width: '100%'
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        borderRight: `1px solid ${theme.palette.grey[500]}`,
                                        p: 1,
                                        width: '40%'
                                    }}
                                    role="button"
                                    onClick={openPopover}
                                >
                                    {selectedLabel ? (
                                        <Typography
                                            sx={{
                                                color: theme.palette.grey[400],
                                                fontFamily: 'Inter',
                                                fontWeight: 500,
                                                fontSize: '14px',
                                                mr: 1,
                                                cursor: 'pointer'
                                            }}
                                        >
                                            {selectedLabel.name}
                                        </Typography>
                                    ) : (
                                        <Typography
                                            sx={{
                                                color: theme.palette.grey[300],
                                                fontFamily: 'Inter',
                                                fontWeight: 400,
                                                fontSize: '14px',
                                                mr: 1,
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <FormattedMessage id="select_a_category" />
                                        </Typography>
                                    )}
                                    <Popover
                                        id={id}
                                        open={!!anchorEl}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left'
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left'
                                        }}
                                        sx={{ '& .MuiPaper-root': { ml: -1, mt: 1.5 } }}
                                    >
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <Box sx={{ maxHeight: 400, overflowY: 'auto' }}>
                                                {subcategories.map((item: Subcategory, index: number) => (
                                                    <Box
                                                        onClick={(e: React.MouseEvent<HTMLDivElement>) => handleSelectedLabel(e, item)}
                                                        key={index}
                                                        sx={{
                                                            p: 2,
                                                            cursor: 'pointer',
                                                            '&:not(last-child)': {
                                                                borderBottom: `1px solid ${theme.palette.grey[500]}`
                                                            }
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                color: theme.palette.grey[400],
                                                                fontFamily: 'Inter',
                                                                fontWeight: 500,
                                                                fontSize: '14px'
                                                            }}
                                                        >
                                                            {item.name}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </ClickAwayListener>
                                    </Popover>
                                    <KeyboardArrowDownIcon />
                                </Box>
                                <Box sx={{ width: '60%' }}>
                                    <Input
                                        disableUnderline
                                        name="name"
                                        value={formik.values.name}
                                        onChange={(e: any) => formik.setFieldValue('name', e.target.value)}
                                        placeholder={intl.formatMessage({ id: 'description_here' })}
                                        sx={{
                                            pl: 1,
                                            ml: 0,
                                            '.MuiInput-input': { '&::placeholder': { color: theme.palette.grey[300], opacity: 1 } },
                                            width: '100%'
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box mt={3}>
                                <DescriptionInputAiTool
                                    value={formik.values.description}
                                    setValue={handleDesc}
                                    placholder="description_here"
                                    totalWordsLength={200}
                                    error={showError}
                                    title="caption"
                                    limit
                                    setValid={setDescriptionValid}
                                />
                            </Box>
                        </Grid>
                        <Grid
                            xs={2}
                            item
                            container
                            justifyContent="flex-end"
                            alignItems="flex-end"
                            sx={{ borderTop: `1px solid ${theme.palette.grey[500]}`, p: '32px' }}
                        >
                            <Box sx={{ display: 'flex' }}>
                                <Box mr={2} sx={{ width: '95px' }}>
                                    <Button title={<FormattedMessage id="cancel" />} onClick={onClose} variant="secondary" />
                                </Box>
                                <Box sx={{ width: '144px' }}>
                                    <Button
                                        onClick={formik.handleSubmit}
                                        title={<FormattedMessage id="save_changes" />}
                                        loading={addLoading || editLoading}
                                        disabled={addLoading || editLoading || !descriptionValid}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Drawer>
    );
};

export default AddCaptionGeneratorDrawer;
