import React, { useEffect } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure, Hits, SearchBox, DynamicWidgets, RefinementList, Pagination, Highlight } from 'react-instantsearch-dom';
import './VirtualEmployeeMarketplace.css';
import { Box, Grid, Input, Modal, TextField, useTheme } from '@mui/material';
import VirtualEmployeeCard from './feature/VirtualEmployeeCard';
import VirtualEmployeeProfile from './profile/VirtualEmployeeProfile';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID || '', process.env.REACT_APP_ALGOLIA_API_KEY || '');
const index = searchClient.initIndex(process.env.REACT_APP_ALGOLIA_INDEX_NAME || '');

function VirtualEmployeeMarketplace() {
    const theme = useTheme();
    const [searchText, setSearchText] = React.useState('');
    const [searchResults, setSearchResults] = React.useState<any>([]);

    const [selectedVirtualEmployee, setSelectedVirtualEmployee] = React.useState<any>(null);

    // const closeSelectedVirtualEmployeeDetails = () => {
    //     setSelectedVirtualEmployee(null);
    // };

    const handleSearchTextChange = async (text: string) => {
        setSearchText(text);
        const result = await index.search(text);
        setSearchResults(result?.hits);
    };

    useEffect(() => {
        handleSearchTextChange('');
    }, []);

    return (
        <Box>
            {selectedVirtualEmployee ? (
                <Box sx={{ p: 2 }}>
                    <ArrowBackIcon
                        sx={{ fontSize: '26px', fill: theme.palette.success[300], cursor: 'pointer' }}
                        onClick={() => setSelectedVirtualEmployee(null)}
                    />

                    <Box sx={{ backgroundColor: 'white' }}>
                        <VirtualEmployeeProfile
                            data={{
                                ...selectedVirtualEmployee
                            }}
                        />
                    </Box>
                </Box>
            ) : (
                <>
                    <Box sx={{ mt: 2, ml: 2 }}>
                        <TextField
                            id="outlined-basic"
                            label="Search for a virtual employee"
                            variant="outlined"
                            value={searchText}
                            onChange={(e: any) => {
                                handleSearchTextChange(e.target.value);
                            }}
                        />
                    </Box>
                    <Box px={2} pt={2}>
                        <Grid container spacing={5}>
                            {searchResults.map((elem: any, index: number) => (
                                <Grid item xs={12} sm={4} key={index}>
                                    <VirtualEmployeeCard
                                        data={{
                                            ...elem,
                                            price: elem.price / 100
                                        }}
                                        handleViewClick={() =>
                                            setSelectedVirtualEmployee({
                                                ...elem
                                            })
                                        }
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </>
            )}
        </Box>
    );
}

export default VirtualEmployeeMarketplace;
