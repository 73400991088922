import { Select, MenuItem, Box, Typography, Avatar } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';

interface SelectFilterOptions {
    id: string | number;
    name: string;
    image_url?: string;
}
interface SelectProps {
    options: SelectFilterOptions[];
    setValues: any;
    values: string[];
}
const SelectFilter = ({ options, values = [], setValues }: SelectProps) => {
    const theme = useTheme();
    const intl = useIntl();

    const addSelectedValue = (selected: string) => {
        if (!values.includes(selected)) {
            setValues([...values, selected]);
        }
    };

    const removeSelectedValue = (selected: string) => {
        setValues(values.filter((item) => item !== selected));
    };

    return (
        <Box>
            <Select
                placeholder={intl.formatMessage({ id: 'provided_service' })}
                sx={{
                    '.MuiOutlinedInput-input': {
                        color: theme.palette.grey[300],
                        fontWeight: 400,
                        fontFamily: 'Inter',
                        fontSize: '14px'
                    }
                }}
                fullWidth
                value=""
            >
                {options.map((item: any) => (
                    <MenuItem
                        key={item.id}
                        sx={{
                            borderBottom: `1px solid ${theme.palette.grey[500]}`,
                            p: '14px 16px'
                        }}
                        value={item.id}
                        onClick={() => addSelectedValue(item.id)}
                    >
                        <Box mr={1} sx={{ width: '32px', height: '32px' }}>
                            <Avatar sx={{ width: '32px', height: '32px' }} alt="Remy Sharp" src={item.image_url} />
                        </Box>

                        <Typography
                            sx={{
                                color: theme.palette.grey[400],
                                fontFamily: 'Inter',
                                fontWeight: 500,
                                fontSize: '14px'
                            }}
                        >
                            {item.name}
                        </Typography>
                    </MenuItem>
                ))}
            </Select>
            {values.map((itemId: any) => {
                const item = options.find((option) => option.id.toString() === itemId);
                if (item) {
                    return (
                        <Box key={item.id} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', m: '20px 0' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box mr={1} sx={{ width: '32px', height: '32px' }}>
                                    <Avatar sx={{ width: '32px', height: '32px' }} alt="Remy Sharp" src={item.image_url} />
                                </Box>
                                <Typography
                                    sx={{
                                        color: theme.palette.grey[400],
                                        fontFamily: 'Inter',
                                        fontWeight: 500,
                                        fontSize: '14px'
                                    }}
                                >
                                    {item.name}
                                </Typography>
                            </Box>
                            <ClearIcon
                                onClick={() => removeSelectedValue(item.id.toString())}
                                sx={{ fontSize: '20px', cursor: 'pointer' }}
                            />
                        </Box>
                    );
                }
                return false;
            })}
        </Box>
    );
};

export default SelectFilter;
