import { gql } from '@apollo/client';

const GET_ORGANIZATION_MEMBERS_BY_ROLE = gql`
    query MyQuery {
        organization_members(where: { role_id: { _eq: MEMBER }, status: { _eq: ACTIVE } }) {
            created_at
            id
            organization_id
            role_id
            status
            type
            user {
                first_name
                image_url
                last_name
                phone
                email
                created_at
            }
        }
    }
`;

const GET_ORGANIZATION_MEMBERS = gql`
    query MyQuery($clientOrganizationId: uuid!) {
        organization_members(
            where: {
                _and: [
                    { status: { _eq: ACTIVE } }
                    {
                        _or: [
                            { client_organization_id: { _eq: $clientOrganizationId } }
                            { _and: [{ client_organization_id: { _is_null: true } }, { type: { _eq: MEMBER } }] }
                        ]
                    }
                ]
            }
        ) {
            created_at
            id
            organization_id
            role_id
            status
            type
            user {
                id
                first_name
                image_url
                last_name
                phone
                email
                created_at
            }
        }
    }
`;

const GET_ALL_ORGANIZATION_MEMBERS = gql`
    query MyQuery {
        organization_members {
            created_at
            id
            organization_id
            role_id
            status
            type
            user {
                id
                first_name
                image_url
                last_name
                phone
                email
                created_at
            }
        }
    }
`;

// eslint-disable-next-line import/prefer-default-export
export { GET_ORGANIZATION_MEMBERS_BY_ROLE, GET_ORGANIZATION_MEMBERS, GET_ALL_ORGANIZATION_MEMBERS };
