import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Title = styled(Typography)(({ theme }) => ({
    textTransform: 'capitalize',
    color: theme.palette.grey[400],
    fontSize: 24,
    fontWeight: 500,
    fontFamily: 'Inter'
}));

export const Text = styled(Typography)(({ theme }) => ({
    fontSize: 16,
    fontWeight: 500,
    fontFamily: 'Inter',
    color: theme.palette.grey[400]
}));

export const LightText = styled(Typography)(({ theme }) => ({
    fontSize: 16,
    fontWeight: 400,
    fontFamily: 'Inter',
    color: theme.palette.grey[400]
}));

export const DarkText = styled(Typography)(({ theme }) => ({
    fontSize: 16,
    fontWeight: 400,
    fontFamily: 'Inter',
    color: theme.palette.grey[600]
}));
