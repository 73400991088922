import { Box, Typography } from '@mui/material';
import { useContext } from 'react';
import ReactFlow, { useEdgesState, useNodesState } from 'react-flow-renderer';
import { useTheme } from '@mui/material/styles';

import { NextButton, RowBody } from '../document-builder/ui';
import PublishContent from './publishContent';

import NODE_TYPES from '../workflow-builder/types/nodes';
import { ReactComponent as LeftArrowIcon } from 'assets/images/arrow-left.svg';
import DocumentBuilderContext from '../document-builder/context/workflow.context';
import CustomEdge from '../workflow-builder/diagram/layout/CustomEdge';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { useIntl } from 'react-intl';
import InformationToolTip from 'ui-component/InformationTooltip';
import { ClientDetailsContext } from 'views/pages/clients/context/client-details.context';

interface PublishDocumentProps {
    onBack?: () => void;
    closeModal: () => void;
}

const edgeTypes = {
    buttonEdge: CustomEdge
};

const PublishDocument = ({ onBack, closeModal }: PublishDocumentProps) => {
    const { documentBuilder, saveChanges } = useContext(DocumentBuilderContext);
    const { updateWorkflows } = useContext(ClientDetailsContext);
    const [nodes, , onNodesChange] = useNodesState(documentBuilder.workflow.flowNodes);
    const [edges, , onEdgesChange] = useEdgesState(documentBuilder.workflow.flowEdges);
    const theme = useTheme();
    const intl = useIntl();

    const handleSaveAndClose = async () => {
        try {
            await saveChanges();
            await updateWorkflows();
            closeModal();
        } catch (error: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({ id: 'unable_to_save_changes' }),
                    variant: 'error'
                })
            );
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <Box sx={{ flex: 9 }}>
                <RowBody
                    sx={{
                        height: '56px',
                        justifyContent: 'space-between',
                        border: '1px solid #D4DBEA',
                        padding: '16px 24px',
                        borderRight: 'none'
                    }}
                >
                    <RowBody sx={{ gap: '8px', cursor: 'pointer' }} onClick={onBack}>
                        <LeftArrowIcon />
                        <Typography variant="subtitle1" sx={{ color: theme.palette.text.primary }}>
                            Back
                        </Typography>
                    </RowBody>
                    <RowBody sx={{ gap: '10px' }}>
                        <Typography sx={{ fontWeight: 500, fontSize: '14px', color: '#7A7A7A' }}>Workflow run duration</Typography>
                        <Typography sx={{ fontWeight: 700, fontSize: '14px', color: theme.palette.text.primary }}>
                            {`${documentBuilder.workflowDurationInDays} days`}
                        </Typography>
                        <InformationToolTip
                            message={`This means that a workflow run will take ${documentBuilder.workflowDurationInDays} days between the start and the closing of the last step assuming that each task takes exactly its specified duration to be completed.`}
                        />
                    </RowBody>
                </RowBody>
                <Box
                    sx={{
                        border: '1px dashed #D4DBEA',
                        borderRadius: '8px',
                        height: '850px',
                        margin: '24px',
                        padding: '24px'
                    }}
                >
                    <Typography sx={{ fontWeight: 500, fontSize: '16px', color: theme.palette.text.primary }}>Steps</Typography>
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        nodeTypes={NODE_TYPES}
                        edgeTypes={edgeTypes}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        nodesDraggable={false}
                        defaultZoom={0.8}
                    />
                </Box>
            </Box>
            <Box sx={{ flex: 5 }}>
                <RowBody
                    sx={{
                        justifyContent: 'space-between',
                        height: '56px',
                        padding: '16px',
                        border: '1px solid #D4DBEA'
                    }}
                >
                    <Typography variant="subtitle1" sx={{ color: theme.palette.text.primary }}>
                        Template Settings
                    </Typography>
                    <RowBody sx={{ gap: '8px' }}>
                        {/* <Typography sx={{ color: '#AAAAAA', fontSize: '14px', fontWeight: 400 }}>Changes Saved</Typography> */}
                        <NextButton onClick={handleSaveAndClose}>Save & close</NextButton>
                    </RowBody>
                </RowBody>
                <PublishContent closeModal={closeModal} />
            </Box>
        </Box>
    );
};

export default PublishDocument;
