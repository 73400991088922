import { Typography, Box, Divider, Dialog } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import DialogCreatePage from './dialogCreatePage';
import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_DOCUMENT_PARTS } from 'services/graphQL/queries/workflow';
import { createDocumentBuilderPage } from '../hooks/useDocumentBuilder';
import update from 'immutability-helper';
import DocumentBuilderContext from '../context/workflow.context';
import { v4 as uuid } from 'uuid';

const DialogAddPage = ({ onClose }: any) => {
    const theme = useTheme();
    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
    const [savedPages, setSavedPages] = useState<any[]>([]);
    const { documentBuilder, setDocumentBuilder } = useContext(DocumentBuilderContext);

    const handleCloseCreatePage = () => {
        setOpenCreateDialog(false);
        onClose();
    };
    const [getDocumentParts] = useLazyQuery(GET_DOCUMENT_PARTS, {
        onCompleted: (result) => {
            setSavedPages(
                result.workflow_saved_document_parts.map((savedDocumentPart: any) => ({
                    ...createDocumentBuilderPage(savedDocumentPart.content),
                    pageId: undefined
                }))
            );
        }
    });

    const addSavedPage = (page: any) => {
        setDocumentBuilder(update(documentBuilder, { pages: { $push: [page] } }));
        handleCloseCreatePage();
    };

    useEffect(() => {
        getDocumentParts({
            variables: {
                type: 'PAGE'
            }
        });
    }, []);

    return (
        <Box>
            <Box
                sx={{ display: 'flex', padding: '10px 20px', alignItems: 'center', cursor: 'pointer' }}
                onClick={() => setOpenCreateDialog(true)}
            >
                <AddIcon />
                <Typography ml={1} sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500, fontSize: '14px' }}>
                    <FormattedMessage id="create_new_page" />
                </Typography>
            </Box>
            <Dialog
                sx={{ '.MuiDialog-paper': { minWidth: '562px' } }}
                open={openCreateDialog}
                onClose={handleCloseCreatePage}
                aria-labelledby="create-page-dialog-title2"
                aria-describedby="create-page-dialog-description2"
            >
                <DialogCreatePage onClose={handleCloseCreatePage} />
            </Dialog>
            <Divider />
            <Box
                sx={{
                    padding: '4px 24px',
                    textTransform: 'uppercase',
                    fontFamily: 'Inter',
                    fontSize: '10px',
                    fontWeight: 600,
                    letterSpacing: '0.12em',
                    color: '#7A7A7A'
                }}
            >
                <FormattedMessage id="saved_pages" />
            </Box>
            <Divider />
            <Box>
                {savedPages.map((savedPage, index) => (
                    <React.Fragment key={index}>
                        <Box
                            sx={{ display: 'flex', padding: '10px 20px', alignItems: 'center', cursor: 'pointer' }}
                            onClick={() => addSavedPage({ ...savedPage, id: uuid() })}
                        >
                            <Typography
                                ml={1}
                                sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500, fontSize: '14px' }}
                            >
                                {savedPage.pageName}
                            </Typography>
                        </Box>
                        {index !== savedPages.length - 1 && <Divider />}
                    </React.Fragment>
                ))}
            </Box>
        </Box>
    );
};

export default DialogAddPage;
