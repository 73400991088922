import { Box, CircularProgress, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from 'react';
import ViewPastRuns from './ViewPastRuns';
import SelectorAutocomplite from 'ui-component/SelectorAutoComplite';
import { Button } from 'ui-component/extended/Button';
import RelevantEventFinderResults from './RelevantEventFinderResults';
import DatePickerAiTool from './DatePickerAiTool';
import DescriptionInputAiTool from './DescriptionInputAiTool';
import { GET_RELEVANT_EVENTS } from 'api/graphql/mutations/aiTools/AiToolsMutations';
import { useMutation, useLazyQuery } from '@apollo/client';
import moment from 'moment';
import { GET_AI_TOOLS_HISTORY, GET_SERVICE_USAGE_LIMITS } from 'services/graphQL/queries/aiTool';
import { GET_CLIENTS } from 'services/graphQL';
import useAuth from 'hooks/useAuth';

interface RelevantEventFinderProps {
    handleToolsNone: () => void;
}
interface LabelType {
    title: string;
    src?: string;
}
interface ResultsValueType {
    date: string;
    excerpt: string;
    name: string;
    score?: number;
    url?: string;
}

const RelevantEventFinder = ({ handleToolsNone }: RelevantEventFinderProps) => {
    const theme = useTheme();
    const { member } = useAuth();

    const [pendingValue, setPendingValue] = React.useState<LabelType[]>([]);
    const [selectedClients, setSelectedClients] = React.useState<any[]>([]);
    const [brandDescription, setBrandDescription] = React.useState('');
    const [datePickerValue, setDatePickerValue] = React.useState<Date | null>(new Date());
    const [results, setResults] = React.useState(false);
    const [resultsValue, setResultsValue] = React.useState<ResultsValueType[]>([]);
    const [history, setHistory] = React.useState(false);
    const [currentUsage, setCurrentUsage] = React.useState(0);
    const [limit, setLimit] = React.useState(0);
    const [credits, setCredits] = React.useState(0);
    const [errorDesc, setErrorDesc] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [clientsLabels, setClientsLabels] = React.useState<any>([]);
    const [descriptionValid, setDescriptionValid] = React.useState(false);

    const [isLoading, setIsLoading] = React.useState(false);

    const [getRelevantEvents, { loading: loadingGetRelevantEvents }] = useMutation(GET_RELEVANT_EVENTS, {
        onCompleted: (data) => {
            setCurrentUsage(currentUsage + 1);
            setResultsValue(data.ai_tools_get_related_event);
            setResults(true);
            if (currentUsage >= limit && credits > 0) {
                setCredits(credits - 1);
            }
        },
        onError: (error) => {
            console.log(error);
        }
    });
    const [getServiceUsage] = useLazyQuery(GET_SERVICE_USAGE_LIMITS, {
        onCompleted: (data) => {
            setCurrentUsage(data.ai_tools_get_usage.current);
            setLimit(data.ai_tools_get_usage.client_limit);
            setCredits(data.ai_tools_get_usage.available_credits);
        },
        onError: (error) => {
            console.log(error);
        }
    });

    const handleCloseResults = () => {
        setResults(false);
        setHistory(false);
    };
    const hanleDatePickerValue = (value: Date) => {
        setDatePickerValue(value);
    };

    const handleOpenResults = async () => {
        if (brandDescription.split(' ').length < 201 && brandDescription.length !== 0) {
            setErrorDesc(false);
            const variables = {
                client_organization_id: selectedClients[0].id,
                year: +moment(datePickerValue).format('YYYY'),
                month: +moment(datePickerValue).format('MM'),
                desc: brandDescription
            };
            getRelevantEvents({
                variables
            });
        } else {
            setErrorDesc(true);
        }
    };
    const [fetchRelevantHistory, { loading: loadingFetchRelevantHistory }] = useLazyQuery(GET_AI_TOOLS_HISTORY, {
        onCompleted: (data) => {
            setResultsValue(data.ai_tools_history);
        }
    });
    const [getClients, { loading: loadingClients }] = useLazyQuery(GET_CLIENTS, {
        onCompleted: (data) => {
            console.log(data);
            setClientsLabels(data.client_organizations);
        },
        onError: (error) => {
            console.log(error);
        }
    });
    const handleHistory = () => {
        fetchRelevantHistory({
            variables: {
                client_organization_id: selectedClients[0].id,
                service: 'EVENT'
            },
            fetchPolicy: 'network-only'
        }).then(() => {
            setResults(true);
            setHistory(true);
        });
    };
    React.useEffect(() => {
        getClients({ variables: { organizationId: member?.organizationId } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (selectedClients[0]) {
            getServiceUsage({
                variables: {
                    client_organization_id: selectedClients[0].id,
                    service: 'EVENT'
                }
            });
            fetchRelevantHistory({
                variables: {
                    client_organization_id: selectedClients[0].id,
                    service: 'EVENT'
                }
            });
            const organization = clientsLabels.find((org: any) => org.id === selectedClients[0].id);
            setBrandDescription(organization.ai_brand_description ?? '');
        }
    }, [selectedClients]);
    const handleOpenkDatePicker = () => setOpen(true);
    const handleCloseDatePicker = () => setOpen(false);

    return (
        <Box sx={{ overflowY: 'auto', maxHeight: { xs: 'calc(100vh - 200px)', sm: 'calc(100vh - 78px)' } }}>
            {loadingGetRelevantEvents ? (
                // {isLoading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: { xs: 'calc(100vh - 200px)', sm: 'calc(100vh - 78px)' }
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (
                <Box>
                    {results ? (
                        <RelevantEventFinderResults handleResults={handleCloseResults} history={history} resultsValue={resultsValue} />
                    ) : (
                        <Box>
                            <Box onClick={handleToolsNone} sx={{ display: 'flex', alignItems: 'center', p: '24px', cursor: 'pointer' }}>
                                <ArrowBackIcon sx={{ fontSize: '26px', fill: theme.palette.success[300] }} />
                                <Typography
                                    ml={1}
                                    sx={{ color: theme.palette.success[300], fontFamily: 'Inter', fontWeight: 500, fontSize: '14px' }}
                                >
                                    <FormattedMessage id="relevant_event_finder" />
                                </Typography>
                            </Box>
                            {selectedClients.length > 0 ? (
                                <ViewPastRuns
                                    size={currentUsage}
                                    handleHistory={handleHistory}
                                    limit={limit}
                                    credits={credits}
                                    loading={loadingFetchRelevantHistory}
                                />
                            ) : null}

                            <Box sx={{ p: '10px 24px 24px 24px' }}>
                                {loadingClients ? (
                                    <CircularProgress />
                                ) : (
                                    <SelectorAutocomplite
                                        autocompleteItems={clientsLabels}
                                        pendingValue={pendingValue}
                                        setPendingValue={setPendingValue}
                                        arrItemSelected={selectedClients}
                                        setArrItemsSelected={setSelectedClients}
                                        title="client"
                                        imagePath="logo_url"
                                        labelPath="name"
                                        singleSelect
                                    />
                                )}
                            </Box>

                            {selectedClients.length > 0 ? (
                                <Box>
                                    <Box sx={{ p: '10px 24px 24px 24px' }}>
                                        <DatePickerAiTool
                                            date={datePickerValue}
                                            handleDate={hanleDatePickerValue}
                                            handleOpen={handleOpenkDatePicker}
                                            open={open}
                                            handleClose={handleCloseDatePicker}
                                            title="date"
                                        />
                                    </Box>
                                    <Box sx={{ p: '10px 24px 24px 24px' }}>
                                        <DescriptionInputAiTool
                                            value={brandDescription}
                                            setValue={setBrandDescription}
                                            placholder="brand_desc_tool"
                                            totalWordsLength={200}
                                            error={errorDesc}
                                            title="brand_description"
                                            setValid={setDescriptionValid}
                                        />
                                    </Box>
                                    <Box sx={{ p: '0 24px 24px 24px', width: '240px' }}>
                                        <Button
                                            onClick={handleOpenResults}
                                            disabled={(currentUsage >= limit && credits < 1) || !descriptionValid}
                                            title={
                                                <Typography sx={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '14px' }}>
                                                    <FormattedMessage id="find_relevant_events" />
                                                </Typography>
                                            }
                                        />
                                    </Box>
                                </Box>
                            ) : null}
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default RelevantEventFinder;
