import { useState, useEffect } from 'react';
import { Typography, Box, Breadcrumbs, Grid, CircularProgress } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import NotFoundImg from 'ui-component/Offer';
import { BaseEditor } from 'slate';
import { ReactEditor } from 'slate-react';
import { HistoryEditor } from 'slate-history';
import DrawerAddOffers from './AddOffersDrawer';
import OffersTable from './OffersTable';
import { useLazyQuery } from '@apollo/client';
import { GET_ACTIVE_OFFERS_CLIENTS } from '../../../services/graphQL/queries/offers';
import AddIcon from '@mui/icons-material/Add';
import { Button } from 'ui-component/extended/Button';
import { OfferType } from 'types/offer';
import { GET_USER_ORGANIZATIONS } from 'services/graphQL';

type CustomEditor = BaseEditor & ReactEditor & HistoryEditor;

type FormattedText = { text: any; bold?: true };

type CustomText = FormattedText;

type ParagraphElement = {
    type: 'paragraph';
    children: CustomText[];
};

type HeadingElement = {
    type: 'heading';
    level: number;
    children: CustomText[];
};

type CustomElement = ParagraphElement | HeadingElement;
declare module 'slate' {
    interface CustomTypes {
        Editor: CustomEditor;
        Element: CustomElement;
        Text: CustomText;
    }
}

const Offers = () => {
    const theme = useTheme();
    const [showDivider, setShowDivider] = useState(false);
    const [offers, setOffers] = useState<OfferType[]>([]);
    const [organizationName, setOrganizationName] = useState('');
    const [fetchOffers, { loading: loadingFetchOffers, refetch }] = useLazyQuery(GET_ACTIVE_OFFERS_CLIENTS, {
        onCompleted: ({ offers }) => {
            setOffers(offers);
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first'
    });
    const userId = JSON.parse(`${localStorage.getItem('user')}`);

    const [getUser] = useLazyQuery(GET_USER_ORGANIZATIONS, {
        onCompleted: (res) => {
            setOrganizationName(res.organizations[0].name);
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first'
    });

    useEffect(() => {
        fetchOffers();
        getUser({
            variables: {
                user_id: userId.user.id
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const closeDivider = () => setShowDivider(false);
    const openDivider = () => setShowDivider(true);

    return (
        <Box>
            <Breadcrumbs aria-label="breadcrumb">
                <Typography sx={{ textTransform: 'capitalize', color: theme.palette.grey[200], fontFamily: 'Inter', fontWeight: 500 }}>
                    {organizationName}
                </Typography>
                <Typography sx={{ textTransform: 'capitalize', color: theme.palette.grey[300], fontFamily: 'Inter', fontWeight: 500 }}>
                    <FormattedMessage id="offers" />
                </Typography>
            </Breadcrumbs>
            <Grid mt={2} mb={3} container alignItems="center">
                <Grid item xs={6}>
                    <Typography
                        sx={{
                            textTransform: 'capitalize',
                            color: theme.palette.grey[400],
                            fontSize: '24px',
                            fontWeight: 500,
                            fontFamily: 'Inter'
                        }}
                    >
                        <FormattedMessage id="offers" />
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Box sx={{ width: '156px' }}>
                            <Button title={<FormattedMessage id="create_offer" />} icon={<AddIcon />} onClick={openDivider} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            {loadingFetchOffers ? (
                <Box mt={10} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box>
                    {offers.length ? (
                        <Box>
                            <OffersTable offers={offers} refetch={refetch} />
                            <Typography
                                mt={4}
                                sx={{
                                    color: theme.palette.grey[600],
                                    fontFamily: 'Inter',
                                    fontWeight: 600,
                                    fontSize: '10px',
                                    textTransform: 'uppercase',
                                    letterSpacing: '0.12em',
                                    textAlign: 'center'
                                }}
                            >
                                <FormattedMessage id="end_of_page" /> - {offers?.length} <FormattedMessage id="results" />
                            </Typography>
                        </Box>
                    ) : (
                        <Box sx={{ width: '315px', m: '15% auto 0' }}>
                            <NotFoundImg title={<FormattedMessage id="no_offers_found" />} />
                        </Box>
                    )}
                </Box>
            )}

            <DrawerAddOffers open={showDivider} onClose={closeDivider} fetchOffers={fetchOffers} />
        </Box>
    );
};

export default Offers;
