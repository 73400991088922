import { Box } from '@mui/material';
import { FC, useContext } from 'react';
import { WFTaskDocumentPermissions } from '@sni4/snikpic-common';
import DocumentBuilderContext from 'views/pages/clients/features/detail/workflow/features/document-builder/context/workflow.context';
import PageDropdown from '../page-dropdown';

interface IDocumentDropdownProps {
    documentPermissions: WFTaskDocumentPermissions[];
    setDocumentPermissions: (documentPermissions: WFTaskDocumentPermissions[]) => void;
}

const Index: FC<IDocumentDropdownProps> = ({ documentPermissions, setDocumentPermissions }) => {
    const { documentBuilder } = useContext(DocumentBuilderContext);

    return (
        <Box>
            {documentBuilder.pages.map((page: any) => (
                <PageDropdown
                    key={page.pageId}
                    page={page}
                    documentPermissions={documentPermissions}
                    setDocumentPermissions={setDocumentPermissions}
                />
            ))}
        </Box>
    );
};

export default Index;
