import { Box, CardMedia, Dialog, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import not_found_outline from 'assets/images/not_found_outline.svg';
import { Button } from 'ui-component/extended/Button';

interface DialogConfirmDeleteProps {
    open: boolean;
    handleClose: () => void;
    onClick: any;
}

const DialogConfirmDelete = ({ open, handleClose, onClick }: DialogConfirmDeleteProps) => {
    const theme = useTheme();

    return (
        <Dialog onClose={handleClose} open={open} sx={{ '.MuiPaper-root': { width: { xs: '100%', sm: '562px' } } }}>
            <Box sx={{ width: '266px', m: '30px auto' }}>
                <CardMedia component="img" width="18px" image={not_found_outline} alt="" />
            </Box>
            <Typography
                sx={{
                    color: '#000',
                    fontFamily: 'Inter',
                    fontWeight: 600,
                    fontSize: '16px',
                    textAlign: 'center'
                }}
            >
                <FormattedMessage id="are_you_sure_you_want_to_delete_the_block" />
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, pb: 3 }}>
                <Button title="Yes, I do" onClick={onClick} sx={{ width: '100px', mr: 1 }} />
                <Button title="No, I don’t" onClick={handleClose} variant="secondary" sx={{ width: '110px' }} />
            </Box>
        </Dialog>
    );
};

export default DialogConfirmDelete;
