import { Box, Divider, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography, useTheme } from '@mui/material';
import { DocumentFieldSelector } from './DocumentFieldSelector';
import { RequiredTag } from './RequiredTag';
import { RowBody } from 'views/pages/clients/features/detail/workflow/features/document-builder/ui';
import { AutomationConfigurationField, DocumentPageDropDownAutomationConfigurationField } from '@sni4/snikpic-common';
import { Page } from 'views/pages/clients/features/detail/workflow/features/types';
import { FormatMultiLangString } from 'features/workflow-automation/utils/multi-lang-string.util';
import { DirectInputText } from './DirectInputText';

interface PageGroupSelectorProps {
    // todo add types
    pages: Page[];
    configuration: DocumentPageDropDownAutomationConfigurationField;
    path: string;
    updateConfiguration: (arg: { configuration: AutomationConfigurationField; path: string }) => void;
}

export const PageGroupSelector = ({ pages, configuration, updateConfiguration, path }: PageGroupSelectorProps) => {
    const theme = useTheme();

    const selectedPageIndex = (() => {
        const index = pages.findIndex((p) => p.pageId === configuration?.selected_object?.id);
        return index !== -1 ? index.toString() : undefined;
    })();

    // const [selectedPageIndex, setSelectedPageIndex] = useState<string>();

    const handlePageChange = (event: SelectChangeEvent) => {
        const newPageIndex = event.target.value.toString();
        const page = pages[parseInt(newPageIndex, 10)];
        // setSelectedPageIndex(newPageIndex);

        updateConfiguration({ configuration: { ...configuration, selected_object: { id: page.pageId } }, path });
    };

    // // Loading the initial state
    // useEffect(() => {
    //     if (configuration?.selected_object) {
    //         const pageIndex = pages.findIndex((p) => p.pageId === configuration?.selected_object?.id);
    //         if (pageIndex !== -1) {
    //             setSelectedPageIndex(pageIndex.toString());
    //         }
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    return (
        <>
            <RowBody sx={{ gap: '16px' }}>
                <Typography variant="subtitle1" sx={{ color: theme.palette.text.primary }}>
                    <FormatMultiLangString multiLangString={configuration.label} />
                </Typography>
                {configuration.required && <RequiredTag />}
                <Divider sx={{ flex: 1 }} />
            </RowBody>
            <FormControl sx={{ minWidth: 120 }} fullWidth>
                <InputLabel id="demo-simple-select-helper-label">Page</InputLabel>
                <Select
                    labelId="demo-simple-select-helper-label-2"
                    id="demo-simple-select-helper-2"
                    value={selectedPageIndex}
                    label="Page"
                    onChange={handlePageChange}
                    sx={{ '& .MuiSelect-select': { fontWeight: 400, backgroundColor: 'white' } }}
                >
                    {pages.map((page, index) => (
                        <MenuItem key={page.pageId} value={index}>
                            {page.pageName}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Box sx={{ ml: 3 }}>
                {selectedPageIndex &&
                    configuration.fields.map((subFieldConfiguration, index) => {
                        if (subFieldConfiguration.source === 'DIRECT_INPUT') {
                            return (
                                <DirectInputText
                                    configuration={subFieldConfiguration}
                                    path={`${path}.fields[${index}]`}
                                    updateConfiguration={updateConfiguration}
                                />
                            );
                        }

                        return (
                            <DocumentFieldSelector
                                pageIndex={parseInt(selectedPageIndex, 10)}
                                configuration={subFieldConfiguration}
                                key={index}
                                pages={pages}
                                path={`${path}.fields[${index}]`}
                                updateConfiguration={updateConfiguration}
                            />
                        );
                    })}
            </Box>
            <Divider sx={{ flex: 1 }} />
        </>
    );
};
