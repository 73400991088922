import { updateSectionFieldContent } from 'services/rest/workflow_run';
import React from 'react';
import { debounce } from 'lodash';
import { DocumentInstanceDTO } from 'features/workflow-task-execution/types';
import { SavingStatusProps } from '../Chips/SavingStatusChip';
import useAuth from 'hooks/useAuth';
import { IntlShape, useIntl } from 'react-intl';

interface useFieldHookProps {
    field: DocumentInstanceDTO['pages'][number]['sections'][number]['fields'][number];
    setSavingStatus: (string: SavingStatusProps['status']) => void;
    handleFieldValueUpdate: (body: any) => void;
}

export function useFieldHook<T>({
    field,
    setSavingStatus,
    handleFieldValueUpdate
}: useFieldHookProps): [T, (newValue: T) => void, IntlShape] {
    const [value, setValue] = React.useState<T>(field.body);
    const { member } = useAuth();
    const intl = useIntl();

    const debouncedHandleChange = React.useRef(
        debounce(async (value) => {
            setSavingStatus('saving');
            await updateSectionFieldContent(
                {
                    field_id: field.id,
                    type: field.type,
                    processmaker_process_request_id: field.processmaker_process_request_id,
                    page_index: field.page_index,
                    section_index: field.section_index,
                    content: { body: value }
                },
                member?.organizationId
            );
            setSavingStatus('saved');
            handleFieldValueUpdate(value);
        }, 1000)
    ).current;

    const handleChange = (newValue: T) => {
        setSavingStatus('not_saved');
        setValue(newValue);
        debouncedHandleChange(newValue);
    };

    React.useEffect(
        () => () => {
            debouncedHandleChange.cancel();
        },
        [debouncedHandleChange]
    );

    return [value, handleChange, intl];
}
