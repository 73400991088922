import { Edge, Node } from 'react-flow-renderer';

export enum WORKFLOW_OPTIONS {
    FROM_SCRATCH = 'FROM_SCRATCH',
    FROM_TEMPLATE = 'FROM_TEMPLATE',
    IDLE = 'IDLE'
}

export enum TEMPLATE_WORKFLOW_STEPS {
    PREVIEW = 'PREVIEW',
    CUSTOMIZE = 'CUSTOMIZE'
}

export enum FIELD_TYPE {
    TEXT = 'TEXT',
    TEXTAREA = 'TEXTAREA',
    DROPDOWN = 'DROPDOWN',
    TICKBOX = 'TICKBOX',
    FILE_UPLOADER = 'FILE_UPLOADER',
    TABLE = 'TABLE',
    DATE = 'DATE',
    LOCATION = 'LOCATION',
    LIST = 'LIST'
}

export const FIELD_TYPE_NAME: Record<FIELD_TYPE, string> = {
    [FIELD_TYPE.TEXT]: 'Single line text',
    [FIELD_TYPE.TEXTAREA]: 'Multiline text',
    [FIELD_TYPE.DROPDOWN]: 'Dropdown',
    [FIELD_TYPE.TICKBOX]: 'Tickbox',
    [FIELD_TYPE.FILE_UPLOADER]: 'File Uploader',
    [FIELD_TYPE.TABLE]: 'Table',
    [FIELD_TYPE.DATE]: 'Date',
    [FIELD_TYPE.LOCATION]: 'Location',
    [FIELD_TYPE.LIST]: 'List'
};

export type DropNumber = {
    fieldValue: string;
    fieldTime: number;
};

export type Field = {
    fieldId: string;
    fieldType: FIELD_TYPE;
    fieldName: string;
    fieldValue: number | string | File;
    fieldItems?: string[];
    fieldTime: number;
};

export type Section = {
    sectionId: string;
    sectionName: string;
    sectionVariable: boolean;
    sectionNumber: number;
    sectionFields: Field[];
    secondCols: number[];
    sectionChecked: boolean;
    sectionClicked: boolean;
};

export type Page = {
    pageId: string;
    pageName: string;
    pageVariable: boolean;
    pageNumber: number;
    pageSections: Section[];
    pageChecked: boolean;
    pageClicked: boolean;
};

export enum WorkFlowTriggerType {
    SCHEDULE = 'SCHEDULE',
    WEBHOOK = 'WEBHOOK'
}

export type WorkFlowNodeSchedule = {
    date: Date;
    eventCheck: boolean;
    week: string;
};

export type WorkFlowNodeWebhook = {
    url?: string;
    input?: string;
    webhook?: string;
    body?: string;
};

export type WorkFlowNodeTask = {
    name?: string;
    icon?: string;
    duration?: string;
};

export type WorkFlowNodeAutomation = {
    nIndex?: number;
};

export type WorkFlowNodeApproval = {
    nIndex?: number;
};

export enum WorkFlowNodeConnectStyle {
    CIRCLE,
    SQUARE,
    NOICON
}

export enum WorkFlowNodeBranchStyle {
    APPROVED,
    NOT_APPROVED
}

export type WorkFlowNodeTaskReview = {
    caption?: string;
    type?: string;
    content?: string;
};

// TODO move this to the common folder
export enum WORKFLOW_NODE_TYPES {
    TRIGGER_NODE = 'trigger',
    SCHEDULE_NODE = 'schedule',
    WEBHOOK_NODE = 'webhook',
    TASK_NODE = 'task',
    AUTOMATION_NODE = 'automation_task',
    AUTOMATION_NODE2 = 'automation_task',
    APPROVAL_NODE = 'approval_task',
    CONNECT_NODE = 'connect',
    BRANCH_NODE = 'branch',
    END_NODE = 'end',
    AUTOMATION_INSTAGRAM = 'automation_instagram',
    AUTOMATION_TIKTOK = 'automation_tiktok'
}

export type WorkFlowNode = {
    nodeType: WORKFLOW_NODE_TYPES;
    floor: number;
    which: number;
    width: number;
    [WORKFLOW_NODE_TYPES.TRIGGER_NODE]?: WorkFlowTriggerType;
    [WORKFLOW_NODE_TYPES.SCHEDULE_NODE]?: WorkFlowNodeSchedule;
    [WORKFLOW_NODE_TYPES.WEBHOOK_NODE]?: WorkFlowNodeWebhook;
    [WORKFLOW_NODE_TYPES.TASK_NODE]?: WorkFlowNodeTask;
    // [WORKFLOW_NODE_TYPES.AUTOMATION_NODE]?: WorkFlowNodeAutomation;
    [WORKFLOW_NODE_TYPES.APPROVAL_NODE]?: WorkFlowNodeApproval;
    [WORKFLOW_NODE_TYPES.CONNECT_NODE]?: WorkFlowNodeConnectStyle;
    [WORKFLOW_NODE_TYPES.BRANCH_NODE]?: WorkFlowNodeBranchStyle;
    [WORKFLOW_NODE_TYPES.END_NODE]?: WorkFlowNodeConnectStyle;
};

export type WorkFlowEdge = {};

export type WorkFlowStyle = {
    rowHeight: number;
    colWidth: number;
    floorSpace: number;
};

export type WorkFlow = {
    flowStyle: WorkFlowStyle;
    flowNodes: any[];
    flowEdges: any[];
    taskAssigned: boolean;
    taskApproved: boolean;
    setNodes?: (nodes: Node[] | ((nodes: Node[]) => Node[])) => void;
    setEdges?: (edges: Edge[] | ((edges: Edge[]) => Edge[])) => void;
};

export type FormData = {
    workflowName: string;
    workflowStatus: string;
    workflowCreationStatus: string;
    pages: Page[];
    workflow: WorkFlow;
    documentId: string;
    documentVersionId: string;
    workflowId: string;
    workflowVersionId: string;
    workflowDurationInDays: number;
};

export const ItemTypes = {
    SECTION: 'section',
    SAVED_SECTION: 'saved_section',
    FIELD: 'field',
    FIELD_NEW: 'field_new',
    TABLE_ITEM: 'tableitem',
    DROPDOWN_ITEM: 'dropdownitem'
};

export enum ContentType {
    DOCUMENT_BUILDER,
    WORKFLOW_BUILDER,
    PUBLISH
}

export interface SnikpicDocument {
    name: string;
    pages: SnikpicPage[];
}
export interface SnikpicPage {
    id: string;
    name: string;
    sections: SnikpicSection[];
    variable: boolean;
    number: number;
}

export interface SnikpicSection {
    id: string;
    name: string;
    fields: SnikpicField[];
    variable: boolean;
    number: number;
}

export interface SnikpicField {
    id: string;
    name: string;
    type: FIELD_TYPE;
    value: string | number | File;
    columnIndex: number;
    rowIndex: number;
}
