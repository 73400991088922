import { Box, Button, CardMedia, MenuItem, Select, Typography, useTheme } from '@mui/material';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ConditionBuilderContext } from '../../context/ConditionBuilderContext';

import ArchiveSVG from 'assets/images/archive.svg';
import DocumentBuilderContext from 'views/pages/clients/features/detail/workflow/features/document-builder/context/workflow.context';

interface ConditionBlockHeaderProps {
    addRuleHandler: () => void;
}

// eslint-disable-next-line arrow-body-style
export const ConditionBlockHeader: FunctionComponent<ConditionBlockHeaderProps> = ({ addRuleHandler }) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ fontWeight: 600, fontSize: '14px', color: '#AAAAAA' }}>IF</Typography>
            <Button onClick={addRuleHandler} sx={{ width: '100px', borderColor: '#fff', p: 0, display: 'flex', gap: '4px' }}>
                <AddIcon sx={{ fontSize: '16px' }} />
                <Typography sx={{ fontWeight: 500, fontSize: '12px' }}>Add Rule</Typography>
            </Button>
        </Box>
    );
};

interface ConditionBlockContentProps {
    conditionObject: any;
    operatorChangeHandler: (newOperator: 'or' | 'and') => void;
}

// eslint-disable-next-line arrow-body-style
export const ConditionBlockContent: FunctionComponent<ConditionBlockContentProps> = ({ conditionObject, operatorChangeHandler }) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box sx={{ display: 'flex', gap: '40px' }}>
                {conditionObject.content[0].content.length > 1 && (
                    <ConditionBracket operator={conditionObject.content[0].type} operatorChangeHandler={operatorChangeHandler} />
                )}
                <Box>
                    {conditionObject.content[0].content.map((elem: any, index: number) => (
                        <ConditionStatement key={elem.id} conditionObject={elem} allowDelete={index > 0} />
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

interface ConditionStatementVariation {
    id: string;
    name: string;
    verb: string;
    options: { id: string; name: string }[];
}

const getConditionStatementVariationById = (conditionStatementVariations: ConditionStatementVariation[], id: string) =>
    conditionStatementVariations.find((elem) => elem.id === id);

function ConditionStatement({ conditionObject, allowDelete = true }: { conditionObject: any; allowDelete?: boolean }) {
    const { updateConditions, deleteCondition } = useContext(ConditionBuilderContext);
    const { documentBuilder } = useContext(DocumentBuilderContext);
    const commentsOptions: { id: string; name: string }[] = [];

    documentBuilder.pages.forEach((page: any) => {
        page.pageSections.forEach((section: any) => commentsOptions.push({ id: section.sectionId, name: section.sectionName }));
    });

    const conditionStatementVariations = [
        {
            id: 'document',
            name: 'Document',
            verb: 'is',
            options: [
                { id: 'approved', name: 'approved' },
                { id: 'declined', name: 'declined' }
            ]
        }
        // ,
        // {
        //     id: 'comments',
        //     name: 'Comments',
        //     verb: 'open in',
        //     options: commentsOptions
        // }
    ];

    const theme = useTheme();

    const conditionType = conditionObject?.content[0]?.type;
    let selectedOptionName = '';

    if (conditionType === 'document') {
        selectedOptionName = conditionObject?.content[1] ? 'approved' : 'declined';
    } else if (conditionType === 'comments') {
        selectedOptionName =
            getConditionStatementVariationById(conditionStatementVariations, conditionType)?.options.find(
                (elem: any) => elem.id === conditionObject?.content[0]?.object_id
            )?.name || '';
    }

    const typeChangeHandler = (e: any) => {
        const newCondition = { ...conditionObject };
        newCondition.content[0].type = e.target.value;

        if (e.target.value === 'comments') {
            newCondition.content = [
                {
                    type: 'comments',
                    object_type: 'section',
                    object_id: getConditionStatementVariationById(conditionStatementVariations, 'comments')?.options[0]?.id
                },
                true
            ];
        } else if (e.target.value === 'document') {
            newCondition.content = [{ type: 'document' }, true];
        }
        updateConditions(newCondition);
    };

    const valueChangeHandler = (e: any) => {
        const newCondition = { ...conditionObject };

        if (conditionType === 'comments') {
            newCondition.content[0].object_id = e.target.value;
        } else if (conditionType === 'document') {
            newCondition.content[1] = e.target.value === 'approved';
        }
        updateConditions(newCondition);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItmes: 'center',
                gap: '10px',
                justifyContent: 'start',
                mt: 1
            }}
        >
            <Select
                value={getConditionStatementVariationById(conditionStatementVariations, conditionType)?.name}
                onChange={typeChangeHandler}
                disableUnderline
                IconComponent={KeyboardArrowDownIcon}
                renderValue={(selected) => selected}
                sx={{
                    fieldset: {
                        border: '1px solid',
                        borderColor: theme.palette.grey[400],
                        borderRadius: '6px'
                    },
                    '.MuiSelect-select': {
                        p: '4px 8px',
                        color: theme.palette.grey[400],
                        fontSize: '14px',
                        fontFamily: 'Inter',
                        fontWeight: 400,
                        width: '100%',
                        background: theme.palette.dark[100]
                    }
                }}
            >
                {conditionStatementVariations.map((item: any, index: number) => (
                    <MenuItem key={index} value={item.id}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%'
                            }}
                        >
                            {item.name}
                        </Box>
                    </MenuItem>
                ))}
            </Select>
            <Typography
                sx={{
                    fontWeight: 500,
                    fontSize: '14px',
                    color: theme.palette.grey[400],
                    mt: '5px'
                }}
            >
                {getConditionStatementVariationById(conditionStatementVariations, conditionType)?.verb}
            </Typography>
            <Select
                // init based on case
                value={selectedOptionName}
                onChange={valueChangeHandler}
                disableUnderline
                IconComponent={KeyboardArrowDownIcon}
                renderValue={(selected) => selected}
                sx={{
                    fieldset: {
                        border: '1px solid',
                        borderColor: theme.palette.grey[400],
                        borderRadius: '6px'
                    },
                    '.MuiSelect-select': {
                        p: '4px 8px',
                        color: theme.palette.grey[400],
                        fontSize: '14px',
                        fontFamily: 'Inter',
                        fontWeight: 400,
                        width: '100%',
                        background: theme.palette.dark[100]
                    }
                }}
            >
                {(getConditionStatementVariationById(conditionStatementVariations, conditionType) as any).options.map(
                    (item: any, index: number) => (
                        <MenuItem key={index} value={item.id}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%'
                                }}
                            >
                                {item.name}
                            </Box>
                        </MenuItem>
                    )
                )}
            </Select>
            {allowDelete && (
                <Box
                    onClick={() => deleteCondition(conditionObject.id)}
                    sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center', justifyContent: 'center' }}
                >
                    <CardMedia
                        sx={{ objectFit: 'contain', maxWidth: '18px' }}
                        component="img"
                        width="18px"
                        image={ArchiveSVG}
                        alt="alt image"
                    />
                </Box>
            )}
        </Box>
    );
}

interface ConditionBracketProps {
    operator: string;
    operatorChangeHandler: (e: any) => void;
}

const ConditionBracket = ({ operator, operatorChangeHandler }: ConditionBracketProps) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                position: 'relative',
                borderRight: `2px solid ${operator === 'and' ? theme.palette.primary[500] : theme.palette.primary[300]}`,
                mt: '7px'
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    width: '12px',
                    height: '2px',
                    background: operator === 'and' ? theme.palette.primary[500] : theme.palette.primary[300],
                    top: 0
                }}
            >
                {' '}
            </Box>
            <Box sx={{ position: 'absolute', top: 'calc(50% - 14px)', right: '-30px' }}>
                <Select
                    value={operator}
                    onChange={(e) => {
                        operatorChangeHandler(e.target.value);
                    }}
                    disableUnderline
                    IconComponent={KeyboardArrowDownIcon}
                    renderValue={(selected) => selected}
                    sx={{
                        fieldset: {
                            border: '1px solid',
                            borderColor: operator === 'and' ? theme.palette.primary[500] : theme.palette.primary[300],
                            borderRadius: '100px'
                        },
                        '.MuiSelect-select': {
                            p: '4px 8px',
                            color: theme.palette.grey[400],
                            fontFamily: 'Inter',
                            fontWeight: 400,
                            background: operator === 'and' ? theme.palette.primary[500] : theme.palette.primary[300],
                            borderRadius: '100px',
                            fontSize: '10px',
                            width: '22px'
                        }
                    }}
                >
                    {['and', 'or'].map((item: any, index: number) => (
                        <MenuItem key={index} value={item}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%'
                                }}
                            >
                                {item}
                            </Box>
                        </MenuItem>
                    ))}
                </Select>
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    width: '12px',
                    height: '2px',
                    background: operator === 'and' ? theme.palette.primary[500] : theme.palette.primary[300],
                    bottom: 0
                }}
            >
                {' '}
            </Box>
        </Box>
    );
};
