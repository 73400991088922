import { Box, CardMedia, FormControl, FormHelperText, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useFieldHook } from './useFieldHook';
import calendar from 'assets/images/calendar.svg';
import moment from 'moment';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as BaseDatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { BaseFieldProps } from 'features/workflow-task-execution/types';

interface DatePickerProps extends BaseFieldProps {
    isTextArea?: boolean;
}

export default function DatePicker({ field, setSavingStatus, isTextArea = false, handleFieldValueUpdate }: DatePickerProps) {
    const isEditable = field.permission?.type !== 'READ';
    const theme = useTheme();
    const [value, handleChange, intl] = useFieldHook<string>({ field, setSavingStatus, handleFieldValueUpdate });
    const [openDatePicker, setOpenDatePicker] = React.useState(false);

    const handleOpenkDatePicker = () => setOpenDatePicker(true);
    const handleCloseDatePicker = () => setOpenDatePicker(false);
    const handleDateChange = (date: string | null) => {
        if (date) handleChange(date);
    };

    return (
        <>
            <Typography sx={{ color: '#000', fontFamily: 'Inter', fontWeight: 500, fontSize: '14px', mb: 2, mt: 3 }}>
                {field.title}
            </Typography>
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        borderRadius: '8px',
                        p: '12px',
                        border: `1px solid ${field.permission?.is_required ? theme.palette.error.main : theme.palette.grey[500]}`,
                        width: '100%',
                        justifyContent: 'space-between'
                    }}
                    onClick={isEditable ? handleOpenkDatePicker : () => {}}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: '20px' }}>
                            <CardMedia sx={{ objectFit: 'contain' }} component="img" width="18px" image={calendar} alt="alt image" />
                        </Box>
                        <Typography
                            ml={1}
                            sx={{
                                color: isEditable ? theme.palette.grey[400] : theme.palette.grey[200],
                                fontFamily: 'Inter',
                                fontWeight: 400,
                                fontSize: '14px'
                            }}
                        >
                            {value ? moment(value).format('DD MMMM YYYY') : 'Select a date'}
                        </Typography>
                    </Box>

                    <KeyboardArrowDownIcon sx={{ fontSize: '25px', fill: theme.palette.grey[400], ml: '8px' }} />
                </Box>
                <FormControl error={Boolean(field.permission?.is_required)}>
                    <FormHelperText>
                        {field.permission?.is_required ? intl.formatMessage({ id: 'please_select_a_date_to_complete_your_task' }) : ''}
                    </FormHelperText>
                </FormControl>
                <Box>
                    {' '}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <BaseDatePicker
                            value={value}
                            open={openDatePicker}
                            onClose={handleCloseDatePicker}
                            onChange={handleDateChange}
                            renderInput={({ inputRef, inputProps, InputProps }) => <Box ref={inputRef}> </Box>}
                        />
                    </LocalizationProvider>
                </Box>
            </Box>
        </>
    );
}
