import { gql } from '@apollo/client';

const GET_RELEVANT_EVENTS = gql`
    mutation GetRelevantEvents($client_organization_id: String!, $year: Int!, $month: Int!, $day: Int! = 1, $desc: String!) {
        ai_tools_get_related_event(
            arg1: { client_organization_id: $client_organization_id, year: $year, month: $month, desc: $desc, day: $day }
        ) {
            url
            score
            name
            excerpt
            date
        }
    }
`;
const GENERATE_CONCEPT = gql`
    mutation GenerateConcept(
        $client_organization_id: String!
        $brand_description: String!
        $period: Int!
        $category: [String!]!
        $context_image: String
        $context_string: String
        $year: Int
    ) {
        ai_tools_generate_concept(
            arg1: {
                brand_type: $brand_description
                client_organization_id: $client_organization_id
                period: $period
                category: $category
                context_image: $context_image
                context_string: $context_string
                year: $year
            }
        ) {
            id
            name
            description
            date
            score
            links
        }
    }
`;
const GENERATE_CAPTION = gql`
    mutation GenerateCaption($category: String!, $client_organization_id: String!, $language: String!, $concept_description: String!) {
        ai_tools_generate_caption(
            arg1: {
                category: $category
                client_organization_id: $client_organization_id
                language: $language
                request: $concept_description
            }
        ) {
            caption
        }
    }
`;
const CAPTION_EXAMPLE = gql`
    mutation InsertCaptionExample($client_organization_id: uuid!, $concept: String!, $caption: String!) {
        insert_client_organization_caption_examples_one(
            object: { concept: $concept, client_organization_id: $client_organization_id, caption: $caption }
        ) {
            id
            concept
            client_organization_id
            caption
        }
    }
`;
const ADD_CAPTION_EXAMPLE_TAB = gql`
    mutation MyMutation($client_organization_id: uuid!, $concept: String!, $caption: String!) {
        insert_client_organization_caption_examples(
            objects: { caption: $caption, client_organization_id: $client_organization_id, concept: $concept }
        ) {
            returning {
                caption
                client_organization_id
                concept
                id
            }
        }
    }
`;
const UPDATE_BRAND_DESCRIPTION = gql`
    mutation UpdateAiBrandDesc($client_organization_id: uuid!, $ai_brand_description: String!) {
        update_client_organizations_by_pk(
            pk_columns: { id: $client_organization_id }
            _set: { ai_brand_description: $ai_brand_description }
        ) {
            id
            ai_brand_description
        }
    }
`;
const DELETE_BRAND_DESCRIPTION = gql`
    mutation DeleteBrandDescription($client_organization_id: uuid!, $ai_brand_description: String!) {
        update_client_organizations_by_pk(
            pk_columns: { id: "2c3d6968-ee94-44e3-b0b7-a1d2a96e8abb" }
            _set: { ai_brand_description: null }
        ) {
            ai_brand_description
            id
            name
            status
        }
    }
`;
const REMOVE_CAPTION_EXAMPLE = gql`
    mutation removeCaptionExample($id: uuid!) {
        delete_client_organization_caption_examples_by_pk(id: $id) {
            id
        }
    }
`;
const EDIT_CAPTION_EXAMPLE = gql`
    mutation MyMutation($id: uuid!, $caption: String, $concept: String) {
        update_client_organization_caption_examples_by_pk(pk_columns: { id: $id }, _set: { caption: $caption, concept: $concept }) {
            concept
            caption
            id
        }
    }
`;
const ADD_CAPTION_EXAMPLE = gql`
    mutation InsertCaptionExample($client_organization_id: uuid!, $concept: String!, $caption: String!, $subcategory_id: String!) {
        insert_client_organization_caption_examples_one(
            object: {
                concept: $concept
                client_organization_id: $client_organization_id
                caption: $caption
                subcategory_id: $subcategory_id
            }
        ) {
            id
            concept
            client_organization_id
            caption
            subcategory_id
        }
    }
`;

const GENERATE_IMAGES = gql`
    mutation GenerateImages($category: AIToolsImageCategory!, $client_organization_id: String!, $description: String!, $image_count: Int!) {
        ai_tools_generate_images(
            arg1: {
                category: $category
                client_organization_id: $client_organization_id
                description: $description
                number_of_images: $image_count
            }
        ) {
            urls
        }
    }
`;

export {
    GET_RELEVANT_EVENTS,
    GENERATE_CONCEPT,
    GENERATE_CAPTION,
    CAPTION_EXAMPLE,
    ADD_CAPTION_EXAMPLE_TAB,
    UPDATE_BRAND_DESCRIPTION,
    DELETE_BRAND_DESCRIPTION,
    REMOVE_CAPTION_EXAMPLE,
    EDIT_CAPTION_EXAMPLE,
    ADD_CAPTION_EXAMPLE,
    GENERATE_IMAGES
};
