import { Typography, Box, TextField, Drawer } from '@mui/material';
import { memo, useContext, useEffect, useState } from 'react';
import { Handle, Position } from 'react-flow-renderer';

import { ColumnBodyOne, FlowNode, RowBody } from '../../../document-builder/ui';
import DialogWebhook from './dialogWebhook';

import { ReactComponent as ArrowRightIcon } from 'assets/images/arrowRightCircle.svg';
import DocumentBuilderContext from '../../../document-builder/context/workflow.context';

const WebhookNode = memo(({ data, isConnectable }: any) => {
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [savedParams, setSavedParams] = useState<boolean>(false);
    const [clickParamText, setClickParamText] = useState<string>('Set parameters');
    const { documentBuilder } = useContext(DocumentBuilderContext);
    const [source, setSource] = useState(1);

    useEffect(() => (documentBuilder.workflow.flowEdges.length > 1 ? setSource(0) : setSource(1)), [documentBuilder]);

    const handleSaveChanges = () => {
        handleCloseDrawer();
        setSavedParams(true);
        setClickParamText('View Parameter');
    };

    const handleOpenDrawer = () => {
        setDrawerOpen(true);
    };

    const handleCloseDrawer = () => {
        setDrawerOpen(false);
    };

    return (
        <FlowNode>
            <Handle
                type="target"
                position={Position.Top}
                isConnectable={isConnectable}
                style={{ width: '9px', height: '9px', opacity: 1, background: '#98A2B3' }}
            />
            <ColumnBodyOne>
                <Typography variant="subtitle1">Webhook received</Typography>
                <RowBody sx={{ justifyContent: 'space-between', cursor: 'pointer' }} onClick={handleOpenDrawer}>
                    <Typography variant="body1" sx={{ color: '#5A5A5A' }}>
                        {clickParamText}
                    </Typography>
                    <ArrowRightIcon style={{ width: '18px', height: '18px' }} />
                </RowBody>
            </ColumnBodyOne>
            {savedParams && (
                <Box sx={{ height: '48px' }}>
                    <TextField
                        fullWidth
                        placeholder="https://www.figma.com/proto/2138&scaling=min -zoom&starting-point-node-id=81%3A2730&hide-ui=1"
                    />
                </Box>
            )}
            <Drawer anchor="right" open={drawerOpen} onClose={handleCloseDrawer} sx={{ zIndex: 1500 }}>
                <DialogWebhook data={data} onClose={handleCloseDrawer} />
            </Drawer>
            <Handle
                type="source"
                position={Position.Bottom}
                isConnectable={isConnectable}
                style={{ width: '9px', height: '9px', opacity: source, background: '#98A2B3' }}
            />
        </FlowNode>
    );
});

export default WebhookNode;
