import { Box, Typography, CardMedia, Dialog, TextField, Select, MenuItem, Grid, Checkbox, FormControlLabel } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';
import fileplus from 'assets/images/fileplus.svg';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDropzone } from 'react-dropzone';
import { Button } from 'ui-component/extended/Button';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { IconTruckDelivery } from '@tabler/icons';

interface DIalogAddPortfolioProps {
    openDialog: boolean;
    onClose: any;
    selectedPastExperience?: any;
}

const DialogAddPastExperience = ({ openDialog, onClose, selectedPastExperience }: DIalogAddPortfolioProps) => {
    const theme = useTheme();
    const intl = useIntl();
    const [checkboxEndDate, setCheckboxEndDate] = useState(false);
    const formik = useFormik({
        initialValues: {
            role: '',
            descriptionTask: '',
            company: '',
            startDate: '',
            endDate: ''
        },
        validationSchema: Yup.object({
            role: Yup.string().required('Required'),
            descriptionTask: Yup.string().required('Required'),
            company: Yup.string().required('Required'),
            startDate: Yup.string().required('Required')
        }),
        onSubmit: (values) => {
            console.log(values);
        }
    });
    const handleChangeCheckboxEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckboxEndDate(event.target.checked);
    };
    React.useEffect(() => {
        if (!openDialog) return;

        if (selectedPastExperience) {
            formik.setFieldValue('role', selectedPastExperience?.title);
            formik.setFieldValue('company', selectedPastExperience?.subtitle);
            formik.setFieldValue('descriptionTask', selectedPastExperience?.desc);
            formik.setFieldValue('startDate', selectedPastExperience?.dateStart);
            if (selectedPastExperience?.dateEnd === 'Present') {
                setCheckboxEndDate(true);
            } else {
                formik.setFieldValue('endDate', selectedPastExperience?.dateEnd);
            }
        } else {
            formik.setFieldValue('role', '');
            formik.setFieldValue('company', '');
            formik.setFieldValue('descriptionTask', '');
            formik.setFieldValue('startDate', '');
            formik.setFieldValue('endDate', '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPastExperience, openDialog]);
    return (
        <Dialog onClose={onClose} open={openDialog} sx={{ '.MuiPaper-root': { p: '30px', width: { xs: '100%', sm: '562px' } } }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography
                    sx={{
                        color: theme.palette.grey[400],
                        fontFamily: 'Inter',
                        fontWeight: 700,
                        fontSize: '16px'
                    }}
                >
                    <FormattedMessage id="past_experience" />
                </Typography>
                <CloseIcon onClick={onClose} sx={{ fill: theme.palette.grey[300], cursor: 'pointer', fontSize: '20px' }} />
            </Box>
            <form onSubmit={formik.handleSubmit}>
                <Typography
                    sx={{
                        color: theme.palette.grey[400],
                        fontFamily: 'Inter',
                        fontWeight: 600,
                        fontSize: '14px',
                        mt: 3,
                        mb: '4px'
                    }}
                >
                    <FormattedMessage id="role" />
                </Typography>
                <TextField
                    sx={{
                        '.MuiOutlinedInput-input': {
                            fontWeight: 400,
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            background: '#fff'
                        }
                    }}
                    id="role"
                    name="role"
                    value={formik.values.role}
                    onChange={formik.handleChange}
                    variant="outlined"
                    fullWidth
                    placeholder={intl.formatMessage({ id: 'financials' })}
                    error={formik.touched.role && Boolean(formik.errors.role)}
                    helperText={formik.touched.role && formik.errors.role}
                />
                <Typography
                    sx={{
                        color: theme.palette.grey[400],
                        fontFamily: 'Inter',
                        fontWeight: 600,
                        fontSize: '14px',
                        mt: 3,
                        mb: '4px'
                    }}
                >
                    <FormattedMessage id="company" />
                </Typography>
                <TextField
                    sx={{
                        '.MuiOutlinedInput-input': {
                            fontWeight: 400,
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            background: '#fff'
                        },
                        mb: 3
                    }}
                    id="industry"
                    name="industry"
                    value={formik.values.company}
                    onChange={formik.handleChange}
                    variant="outlined"
                    fullWidth
                    placeholder="Snikpic"
                    error={formik.touched.company && Boolean(formik.errors.company)}
                    helperText={formik.touched.company && formik.errors.company}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography
                            sx={{
                                color: theme.palette.grey[400],
                                fontFamily: 'Inter',
                                fontWeight: 600,
                                fontSize: '14px',
                                mb: '4px'
                            }}
                        >
                            <FormattedMessage id="start_date" />
                        </Typography>
                        <TextField
                            sx={{
                                '.MuiOutlinedInput-input': {
                                    fontWeight: 400,
                                    fontFamily: 'Inter',
                                    fontSize: '14px',
                                    background: '#fff'
                                }
                            }}
                            id="startDate"
                            name="startDate"
                            value={formik.values.startDate}
                            onChange={formik.handleChange}
                            variant="outlined"
                            fullWidth
                            placeholder="2018"
                            error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                            helperText={formik.touched.startDate && formik.errors.startDate}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography
                            sx={{
                                color: theme.palette.grey[400],
                                fontFamily: 'Inter',
                                fontWeight: 600,
                                fontSize: '14px',
                                mb: '4px'
                            }}
                        >
                            <FormattedMessage id="end_date" />
                        </Typography>
                        <TextField
                            sx={{
                                '.MuiOutlinedInput-input': {
                                    fontWeight: 400,
                                    fontFamily: 'Inter',
                                    fontSize: '14px',
                                    background: '#fff',
                                    '&:disabled': {
                                        background: theme.palette.grey[800]
                                    }
                                }
                            }}
                            id="endDate"
                            name="endDate"
                            value={formik.values.endDate}
                            onChange={formik.handleChange}
                            variant="outlined"
                            fullWidth
                            disabled={checkboxEndDate}
                            placeholder="YYYY"
                            error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                            helperText={formik.touched.endDate && formik.errors.endDate}
                        />
                        <FormControlLabel
                            sx={{
                                '.MuiCheckbox-root': { p: '2px 9px' }
                            }}
                            label={
                                <Typography
                                    sx={{
                                        color: theme.palette.grey[300],
                                        fontFamily: 'Inter',
                                        fontWeight: 400,
                                        fontSize: '12px'
                                    }}
                                >
                                    <FormattedMessage id="present" />
                                </Typography>
                            }
                            control={<Checkbox checked={checkboxEndDate} size="small" onChange={handleChangeCheckboxEndDate} />}
                        />
                    </Grid>
                </Grid>
                <Typography
                    sx={{
                        color: theme.palette.grey[400],
                        fontFamily: 'Inter',
                        fontWeight: 600,
                        fontSize: '14px',
                        mt: 3,
                        mb: '4px'
                    }}
                >
                    <FormattedMessage id="description" />
                </Typography>
                <TextField
                    sx={{
                        '.MuiOutlinedInput-input': {
                            fontWeight: 400,
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            background: '#fff'
                        },
                        mb: 3
                    }}
                    id="descriptionTask"
                    name="descriptionTask"
                    value={formik.values.descriptionTask}
                    onChange={formik.handleChange}
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={6}
                    maxRows={6}
                    placeholder={intl.formatMessage({ id: 'description' })}
                    error={formik.touched.descriptionTask && Boolean(formik.errors.descriptionTask)}
                    helperText={formik.touched.descriptionTask && formik.errors.descriptionTask}
                />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                    <Button sx={{ width: '90px', mr: 1 }} title={<FormattedMessage id="cancel" />} onClick={onClose} variant="secondary" />
                    <Button sx={{ width: '80px' }} title={<FormattedMessage id="save" />} onClick={onClose} />
                </Box>
            </form>
        </Dialog>
    );
};

export default DialogAddPastExperience;
