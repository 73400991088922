import { useEffect, useMemo, useState } from 'react';
import { useChatContext } from 'stream-chat-react';
import type { UserResponse } from 'stream-chat';
import type { StreamChatType } from '../../types';
import { useAdminPanelFormState } from './context/AdminPanelFormContext';
import { ValidationError } from './ValidationError';
import { useLazyQuery } from '@apollo/client';
import { FETCH_ORGANIZATION_MEMBERS } from 'services/graphQL/queries/teams';
import { FETCH_USERS_BY_PKS } from 'services/graphQL/queries/user';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PersonAddIcon from '@mui/icons-material/PersonAdd'; // Added Max
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'; // Added
import allEmailBridgeSelected from '../../assets/allEmailBridgeSelected.svg';
import allEmailBridgeUnSelected from '../../assets/allEmailBridgeUnselected.svg';
import mentionEmailBridgeSelected from '../../assets/mentionEmailBridgeSelected.svg';
import mentionEmailBridgeUnselected from '../../assets/mentionEmailBridgeUnselected.svg';
import { Button, Typography, Divider, Checkbox, Avatar, Tooltip } from '@mui/material';
import { useWorkspaceController } from '../../context/WorkspaceController';
import { useTheme } from '@mui/material/styles'; // Added Max
// import CheckboxFilter from 'ui-component/checkboxFilter/CheckboxFilter';

// Local types
// -----------------------------------------------------------------------------------------------
type UserListLoadState = 'loading' | 'error' | 'empty';

type organizationMember = {
    id: string;
    firstName: string;
    imageUrl: string;
};

const LOAD_STATE_NOTIFICATION: Record<UserListLoadState, string> = {
    empty: 'No users found.',
    error: 'Error loading, please refresh and try again.',
    loading: 'Loading users...'
};

// Intermediate components
// -----------------------------------------------------------------------------------------------

// Main component - Display tables to add and remove users
// -----------------------------------------------------------------------------------------------

// Intermediate components
// -----------------------------------------------------------------------------------------------

// Component - List of users that can be added to the channel
const ListContainer = (props: { children: React.ReactNode }) => {
    const { children } = props;
    const { errors, createChannelType } = useAdminPanelFormState();
    const showHeading = !createChannelType || createChannelType === 'team';
    return (
        // <div className="user-list__container">
        <div>
            {showHeading && (
                <h2>
                    {/* <span>
                        <PersonAddIcon />
                    </span>
                    <span>Add Members</span> */}
                    <ValidationError errorMessage={errors.members} />
                </h2>
            )}
            {/* <div className="user-list__header user-list__row">
                <div className="user-list__column-block">
                    <p>Agency member</p>
                    <p className="user-list__column--last-active">Member ID</p>
                </div>
                <div className="user-list__column--checkbox">
                    <p>Invite</p>
                </div>
            </div> */}
            {children}
        </div>
    );
};

// Component - List of users that can be removed from the channel
const DeleteMemeberListContainer = (props: { children: React.ReactNode }) => {
    const { children } = props;
    const { errors, createChannelType } = useAdminPanelFormState();
    const showHeading = !createChannelType || createChannelType === 'team';
    return (
        // <div className="user-list__container">
        <div>
            {showHeading && (
                <h2>
                    {/* <span>
                        <PersonRemoveIcon />
                    </span>
                    <span>Remove member</span> */}
                    <ValidationError errorMessage={errors.members} />
                </h2>
            )}
            {/* <div className="user-list__header user-list__row">
                <div className="user-list__column-block">
                    <p>Channel member</p>
                    <p className="user-list__column--last-active">Member ID</p>
                </div>
                <div className="user-list__column--checkbox">
                    <p>Remove</p>
                </div>
            </div> */}
            {children}
        </div>
    );
};

// Component - Display row of user that can be added to the channel
type UserItemProps = {
    index: number;
    user: UserResponse<StreamChatType> & { imageUrl: string };
    // imageUrl: string;
};

// const UserItem = ({ index, user, imageUrl }: UserItemProps) => {
const UserItem = ({ index, user, imageUrl }: any) => {
    const { handleMemberSelect } = useAdminPanelFormState();

    const title = user.name || user.id;

    // console.log('In UserItem - user, ', user);

    return (
        <label htmlFor={user.id} title={title} className="user-list__row">
            <div className="user-list__column-block">
                <div className="user-list__column--user-data">
                    <Avatar alt="Remy Sharp" src={imageUrl} />
                    <p className="user-item__name">{title}</p>
                </div>
                {/* <p className="user-list__column--last-active">{user.id}</p> */}
            </div>
            <div className="user-list__column--checkbox">
                {/* <input type="checkbox" name="members" id={user.id} value={user.id} onChange={handleMemberSelect} /> */}
                {/* <CheckboxFilter options={typeOptions} setCheckboxValue={setSelectedTypes} checkboxValue={selectedTypes} /> */}
                {/* <CheckboxFilter /> */}
                <Checkbox name="members" id={user.id} value={user.id} onChange={handleMemberSelect} />
            </div>
        </label>
    );
};

// Component - Display row of user that can be deleted from the channel
const UserItemForRemoval = ({ index, user }: any) => {
    const theme = useTheme();
    const { channel } = useChatContext<StreamChatType>();
    const { handleMemberRemove } = useAdminPanelFormState();
    const { handleMemberAllEmailBridge, handleMemberMentionEmailBridge } = useAdminPanelFormState();
    const { closeAdminPanel } = useWorkspaceController();
    const [allEmailBridge, setAllEmailBridge] = useState(false);
    const [mentionEmailBridge, setMentionEmailBridge] = useState(false);
    useEffect(() => {
        if (
            channel &&
            channel.data &&
            (channel.data as any).allEmailBridgeList &&
            (channel.data as any).allEmailBridgeList.includes(user.id)
        ) {
            setAllEmailBridge(true);
        }
        if (
            channel &&
            channel.data &&
            (channel.data as any).mentionEmailBridgeList &&
            (channel.data as any).mentionEmailBridgeList.includes(user.id)
        ) {
            setMentionEmailBridge(true);
        }
    }, [channel, user.id]);

    const handlerAllEmails = async () => {
        setAllEmailBridge(!allEmailBridge);
        handleMemberAllEmailBridge(user.id, !allEmailBridge);
    };

    const handlerMentionEmails = async () => {
        setMentionEmailBridge(!mentionEmailBridge);
        handleMemberMentionEmailBridge(user.id, !mentionEmailBridge);
    };

    const title = user.firstName || user.id;

    return (
        <label htmlFor={user.id} title={title} className="user-list__row">
            <div className="user-list__column-block">
                <div className="user-list__column--user-data">
                    <Avatar alt="Remy Sharp" src={user.imageUrl} />
                    <p className="user-item__name">{title}</p>
                </div>
                {/* <p className="user-list__column--last-active">{user.id}</p> */}
            </div>
            <div className="user-list__column--checkbox">
                <Tooltip title="Bridge all messages in the channels to that user email" placement="top">
                    <Button onClick={handlerAllEmails}>
                        {allEmailBridge ? (
                            <img src={allEmailBridgeSelected} alt="All email bridge selected" />
                        ) : (
                            <img src={allEmailBridgeUnSelected} alt="All email bridge unselected" />
                        )}
                    </Button>
                </Tooltip>
                <Tooltip title="Bridge messages in the channels to that user email when they are mentioned" placement="top">
                    <Button onClick={handlerMentionEmails}>
                        {mentionEmailBridge ? (
                            <img src={mentionEmailBridgeSelected} alt="Mention email bridge selected" />
                        ) : (
                            <img src={mentionEmailBridgeUnselected} alt="Mention email bridge unselected" />
                        )}
                    </Button>
                </Tooltip>
                <Tooltip title="Remove member from channel" placement="top">
                    <Button
                        onClick={() => {
                            handleMemberRemove(user.id);
                            closeAdminPanel();
                        }}
                    >
                        <DeleteOutlineIcon style={{ color: theme.palette.grey['400'] }} />
                    </Button>
                </Tooltip>
            </div>
        </label>
    );
};

// Main component - Display tables to add and remove users
// -----------------------------------------------------------------------------------------------

export const UserList = (props: { isCreate: boolean }) => {
    // Input data
    // ............................................................................................

    // Props
    const { isCreate } = props;

    // Local storage
    const organizationId = JSON.parse(`${localStorage.getItem('user')}`).member.organizationId; // Used to fetch info of current channel members

    // App context
    const theme = useTheme();
    // console.log('theme: ', theme);

    // Chat context (GetStream data layer)
    const { client, channel } = useChatContext<StreamChatType>();
    const { createChannelType } = useAdminPanelFormState();
    const [loadState, setLoadState] = useState<UserListLoadState | null>(null);
    const [users, setUsers] = useState<UserResponse<StreamChatType>[] | undefined>();
    const channelMembers = useMemo(() => {
        if (isCreate) {
            return [];
        }
        return channel?.state.members ? Object.keys(channel.state.members) : [];
    }, [channel?.state?.members, isCreate]);

    // Snikpic data layer
    const [organizationMembers, setOrganizationMembers] = useState<organizationMember[]>([]); // Snikpic data of the members of the agency
    // const [usersToDisplay, setUsersToDisplay] = useState<UserResponse<StreamChatType>[] | undefined>([]); // Data to be displayed in the list to add members to channel
    const [usersToDisplay, setUsersToDisplay] = useState<any[]>([]); // Data to be displayed in the list to add members to channel
    const [channelOrganizationMembers, setChannelOrganizationMembers] = useState<any[]>([]); // Data to be displayed in the list to remove members from channel
    // console.log('In state - channelOrganizationMembers: ', channelOrganizationMembers);

    // Fetching function
    // ............................................................................................

    // Fetch members of the organization of the logged in user
    const [fetchOrganizationMembers] = useLazyQuery(FETCH_ORGANIZATION_MEMBERS);

    // Fetch members of the organization of the logged in user
    const [fetchUsersByPk] = useLazyQuery(FETCH_USERS_BY_PKS, {
        onCompleted: (data) => {
            const response = data.users.map((item: { id: string; first_name: string; image_url: string }) => {
                const member = {
                    id: item.id,
                    firstName: item.first_name,
                    imageUrl: item.image_url
                };
                return member;
            });
            setChannelOrganizationMembers(response);
        }
    });

    // Utils
    // ............................................................................................
    const initialize = async () => {
        const { data } = await fetchOrganizationMembers({
            variables: {
                organizationId
            }
        });
        const members = data.organizations_by_pk.members.map((item: { user: { id: string; first_name: string; image_url: string } }) => {
            const member = {
                id: item.user.id,
                firstName: item.user.first_name,
                imageUrl: item.user.image_url
            };
            return member;
        });
        setOrganizationMembers(members);
        return members;
    };

    // Use effects
    // ............................................................................................
    // };

    // When chat context from GetStream is ready, fetch channel memebers from GetStream and fetch orgnization members from Snikpic
    useEffect(() => {
        const getUsers = async () => {
            if (loadState) return;
            setLoadState('loading');

            try {
                if (channelMembers.length === 0) {
                    const membersUsed = initialize();
                    const ids = (await membersUsed).map((member: any) => member.id);
                    const response = await client.queryUsers({ id: { $in: ids } }, { id: 1 });
                    setUsers(response.users);
                    return;
                }

                const response = await client.queryUsers({ id: { $nin: channelMembers } }, { id: 1 });
                if (response.users.length) {
                    const membersUsed = initialize();
                    const ids = (await membersUsed).map((member: any) => member.id);
                    const response = await client.queryUsers({ id: { $in: ids } }, { id: 1 });
                    setUsers(response.users);
                } else {
                    setLoadState('empty');
                }
            } catch (event) {
                setLoadState('error');
            }

            setLoadState(null);
        };

        if (client) getUsers();
    }, [client, channelMembers, createChannelType]); // eslint-disable-line react-hooks/exhaustive-deps

    // When channelMembers from getStream are ready, fetch Snikpic data of those users (used to remove members)
    useEffect(() => {
        const fetchChannelMemberData = async () => {
            try {
                if (channelMembers?.length > 0) {
                    fetchUsersByPk({
                        variables: {
                            ids: channelMembers
                        }
                    });
                }
            } catch (e) {
                console.error(e);
            }
            setLoadState(null);
        };
        fetchChannelMemberData();
    }, [channelMembers, fetchUsersByPk]);

    // When Snikpic organization users and GetStream users are ready: take the interesection of the arrays to display the users that can be added to a channel (only users from the organization can be added) (used to add members)
    useEffect(() => {
        const getUsers = async () => {
            if (loadState) return;
            setLoadState('loading');

            try {
                if (channelMembers.length === 0) {
                    setLoadState('empty');
                    return;
                }

                const response = await client.queryUsers({ id: { $nin: channelMembers } }, { id: 1 });
                // console.log('response', response);
                if (response.users.length) {
                    setUsers(response.users);
                    initialize();
                } else {
                    setLoadState('empty');
                }
            } catch (event) {
                setLoadState('error');
            }

            setLoadState(null);
        };

        if (client) getUsers();
    }, [client, channelMembers, createChannelType]); // eslint-disable-line react-hooks/exhaustive-deps

    // When channelMembers from getStream are ready, fetch Snikpic data of those users (used to remove members)
    useEffect(() => {
        const fetchChannelMemberData = async () => {
            try {
                if (channelMembers?.length > 0) {
                    fetchUsersByPk({
                        variables: {
                            ids: channelMembers
                        }
                    });
                }
            } catch (e) {
                console.error(e);
            }
            setLoadState(null);
        };
        fetchChannelMemberData();
    }, [channelMembers, fetchUsersByPk]);

    // When Snikpic organization users and GetStream users are ready: take the interesection of the arrays to display the users that can be added to a channel (only users from the organization can be added) (used to add members)
    useEffect(() => {
        const crossCheckUsers = async () => {
            try {
                // if channelMembers is empty, it will look for all users of the organization that are in GetStream and add their info to the arrayOfUsersToDisplay
                if (channelMembers.length === 0) {
                    const arrayOfUsersToDisplay = [];
                    const userIdToExclude = JSON.parse(`${localStorage.getItem('user')}`).user.id;
                    for (const member of organizationMembers) {
                        // making sure that users from organization are in GetSream
                        const userFound = users && users.find((user) => user.id === member.id);
                        // if user is in GetStream, add it to the array of users to display
                        const isInOrganization = !!userFound;
                        if (isInOrganization) {
                            arrayOfUsersToDisplay.push({
                                banned: false,
                                created_at: userFound?.created_at,
                                id: userFound?.id,
                                name: member.firstName,
                                online: userFound?.online,
                                role: userFound?.role,
                                shadow_banned: userFound?.shadow_banned,
                                updated_at: userFound?.updated_at,
                                imageUrl: member.imageUrl
                            });
                        }
                    }
                    const filteredArrayOfUsersToDisplay = arrayOfUsersToDisplay.filter((user) => user.id !== userIdToExclude);
                    setUsersToDisplay(filteredArrayOfUsersToDisplay);
                } else if (organizationMembers.length && users?.length) {
                    const arrayOfUsersToDisplay = [];
                    for (const member of organizationMembers) {
                        // making sure that users from organization are in GetSream
                        const userFound = users.find((item) => item.id === member.id);
                        // console.log('user found', userFound);
                        // if user is in GetStream, add it to the array of users to display
                        const isInOrganization = !!userFound;
                        if (isInOrganization) {
                            arrayOfUsersToDisplay.push({
                                banned: false,
                                created_at: userFound?.created_at,
                                id: userFound?.id,
                                name: member.firstName,
                                online: userFound?.online,
                                role: userFound?.role,
                                shadow_banned: userFound?.shadow_banned,
                                updated_at: userFound?.updated_at,
                                imageUrl: member.imageUrl
                            });
                        }
                    }
                    // console.log('arrayOfUsersToDisplay: ', arrayOfUsersToDisplay);

                    const filteredArrayOfUsersToDisplay = arrayOfUsersToDisplay.filter((user) => !channelMembers.includes(user.id));
                    setUsersToDisplay(filteredArrayOfUsersToDisplay);
                }
            } catch (e) {
                console.error(e);
            }
            setLoadState(null);
        };
        crossCheckUsers();
    }, [organizationMembers, users, isCreate]);

    // Final render
    // ............................................................................................
    return (
        <>
            {!isCreate ? (
                <>
                    <Typography
                        mt={3}
                        sx={{
                            fontWeight: 500,
                            fontFamily: 'Inter',
                            color: theme.palette.grey[400],
                            fontSize: '14px'
                        }}
                    >
                        Edit members
                    </Typography>
                    <Typography
                        // mt={1}
                        sx={{
                            fontWeight: 400,
                            fontFamily: 'Inter',
                            color: theme.palette.grey[600],
                            fontSize: '12px'
                        }}
                    >
                        Among channel members, you can enable all chat bridging through emails or only when member is mentioned. You can
                        also remove members from the channel.
                    </Typography>
                    <Divider sx={{ m: '20px 0' }} />
                    <DeleteMemeberListContainer>
                        {loadState ? (
                            <div className="user-list__message">{LOAD_STATE_NOTIFICATION[loadState]}</div>
                        ) : (
                            channelOrganizationMembers?.length &&
                            channelOrganizationMembers.map((user, i) => <UserItemForRemoval index={i} key={user.id} user={user} />)
                        )}
                    </DeleteMemeberListContainer>
                </>
            ) : (
                ''
            )}

            <Typography
                mt={3}
                sx={{
                    fontWeight: 500,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[400],
                    fontSize: '14px'
                }}
            >
                Add members
            </Typography>
            <Typography
                // mt={1}
                sx={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    color: theme.palette.grey[600],
                    fontSize: '12px'
                }}
            >
                Select members from your organization to be added to the channel
            </Typography>
            <Divider sx={{ m: '20px 0' }} />
            <ListContainer>
                {loadState ? (
                    <div className="user-list__message">{LOAD_STATE_NOTIFICATION[loadState]}</div>
                ) : (
                    usersToDisplay?.length &&
                    usersToDisplay.map((user, i) => <UserItem index={i} key={user.id} user={user} imageUrl={user.imageUrl} />)
                )}
            </ListContainer>
        </>
    );
};
