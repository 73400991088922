import React from 'react';
import { Box, Typography } from '@mui/material';

type MenuItemProps = {
    children: React.ReactNode;
    icon: React.ReactNode;
    onClick: () => void | Promise<void>;
};
export const MenuItem = ({ children, icon, onClick }: MenuItemProps) => (
    <Box
        onClick={onClick}
        sx={{
            display: 'flex',
            px: 3,
            py: 1,
            cursor: 'pointer',
            alignItems: 'center',
            '&:hover': { bgcolor: (theme) => theme.palette.grey['100'] }
        }}
    >
        {icon}
        <Typography
            sx={{
                color: (theme) => theme.palette.grey[400],
                fontFamily: 'Inter',
                fontWeight: 500,
                fontSize: '14px',
                ml: '10px'
            }}
        >
            {children}
        </Typography>
    </Box>
);
