import { Dialog } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import DialogTableDeliverables from './Deliverable';

export default function PublicDeliverable() {
    const { taskId, organizationId } = useParams();

    if (!taskId) return null;

    return (
        <Dialog fullScreen open onClose={() => {}} sx={{ '.MuiPaper-root': { p: 0 } }}>
            <DialogTableDeliverables isPublic taskId={parseInt(taskId, 10)} organizationId={organizationId} handleClose={() => {}} />;
        </Dialog>
    );
}
