import { useState } from 'react';

interface useValidateProps {
    documentBuilder: any;
    pageIndex: number;
    sectionIndex: number;
    fieldIndex: number;
}

const useValidate = ({ documentBuilder, pageIndex, sectionIndex, fieldIndex }: useValidateProps) => {
    const [error, setError] = useState(false);

    const validateField = () => {
        if (documentBuilder.pages[pageIndex].pageSections[sectionIndex].sectionFields[fieldIndex].fieldName.length === 0) {
            setError(true);
        }

        if (documentBuilder.pages[pageIndex].pageSections[sectionIndex].sectionFields[fieldIndex].fieldName.length > 0) {
            setError(false);
        }
    };

    return { error, validateField };
};

export default useValidate;
