import { createContext } from 'react';

// type WorkflowTableContextType = [workflows: any, setWorkflows: (workflows: any) => void, updateWorkflows: () => Promise<void>];
type ClientDetailsContextType = {
    workflows: any;
    setWorkflows: (workflows: any) => void;
    updateWorkflows: () => Promise<void>;
    getWorkflowsLoading: boolean;
};

export const ClientDetailsContext = createContext<ClientDetailsContextType>({
    workflows: [],
    setWorkflows: () => {},
    updateWorkflows: () => Promise.resolve(),
    getWorkflowsLoading: false
});
