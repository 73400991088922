import { Box, Typography, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import { FormattedMessage } from 'react-intl';
import { ServiceForm } from './form';
import { FormType } from 'types/clients';

const forms: FormType[] = [
    { name: 'Form Name 1', questions: 4, answered: 4 },
    { name: 'Form Name 2', questions: 4, answered: 2 },
    { name: 'Form Name 3', questions: 4, answered: 0 }
];

interface FormsFormProps {
    onAddForm: () => void;
}
export const FormsForm = ({ onAddForm }: FormsFormProps) => {
    const theme = useTheme();

    const onMenu = () => {
        console.log('onMenu called');
    };

    return (
        <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    borderBottom: `1px solid ${theme.palette.grey[500]}`,
                    py: 2,
                    mt: 2
                }}
            >
                <IconButton onClick={onAddForm}>
                    <AddIcon />
                </IconButton>
                <Typography ml={2} sx={{ color: theme.palette.grey[300], fontFamily: 'Inter', fontWeight: 500 }}>
                    <FormattedMessage id="add_form" />
                </Typography>
            </Box>
            <Box sx={{ my: 3, flexGrow: 1, borderBottom: `1px solid ${theme.palette.grey[500]}` }}>
                {forms.map((form: FormType) => (
                    <ServiceForm form={form} onMenu={onMenu} />
                ))}
            </Box>
        </Box>
    );
};
