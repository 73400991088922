import axios from 'axios';

export const getTask = async (taskId: number, organizationId: string | undefined) => {
    const baseURL = process.env.REACT_APP_SNIKPIC_BASE_URL;
    const endpointURL = `${baseURL}/workflow/task/get_task`;

    const response = await axios.post(
        endpointURL,
        {
            id: taskId
        },
        {
            headers: {
                ...(localStorage.getItem('user')
                    ? {
                          authorization: `Bearer ${JSON.parse(localStorage.getItem('user') as string)?.tokens?.access_token ?? ''}`,
                          'x-hasura-organization-id': organizationId || ''
                      }
                    : {})
            }
        }
    );
    return response;
};

export const getPublicTask = async (taskId: number, organizationId: string) => {
    const baseURL = process.env.REACT_APP_SNIKPIC_BASE_URL;
    const endpointURL = `${baseURL}/workflow/task/get_public_task`;

    const response = await axios.post(endpointURL, {
        task_id: taskId,
        organization_id: organizationId
    });
    return response;
};

export const getDocumentInstanceAttachedToTask = async (taskId: number, organizationId: string | undefined) => {
    const baseURL = process.env.REACT_APP_SNIKPIC_BASE_URL;
    const endpointURL = `${baseURL}/workflow/document/get_document_instance_attached_to_task`;

    const response = await axios.post(
        endpointURL,
        {
            task_id: taskId
        },
        {
            headers: {
                ...(localStorage.getItem('user')
                    ? {
                          authorization: `Bearer ${JSON.parse(localStorage.getItem('user') as string)?.tokens?.access_token ?? ''}`,
                          'x-hasura-organization-id': organizationId || ''
                      }
                    : {})
            }
        }
    );
    return response;
};

export const getFullDocumentAttachedToWorkflowRun = async (
    workflowRunId: number,
    documentVersionId: string,
    organizationId: string | undefined
) => {
    const baseURL = process.env.REACT_APP_SNIKPIC_BASE_URL;
    const endpointURL = `${baseURL}/workflow/document/get_full_document_instance_attached_to_workflow_run`;

    const response = await axios.post(
        endpointURL,
        {
            workflow_run_id: workflowRunId,
            document_version_id: documentVersionId
        },
        {
            headers: {
                authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '')?.tokens?.access_token ?? ''}`,
                'x-hasura-organization-id': organizationId || ''
            }
        }
    );
    return response;
};

export const updateSectionFieldContent = async (
    fieldContent: {
        type: string;
        processmaker_process_request_id: number;
        page_index: number;
        section_index: number;
        field_id: string;
        content: {
            body: string;
        };
    },
    organizationId: string | undefined
) => {
    const baseURL = process.env.REACT_APP_SNIKPIC_BASE_URL;
    const endpointURL = `${baseURL}/workflow/document/update_field_content`;
    console.log('REACT_APP_TEMPORARY_ORGANIZATION_ID', process.env.REACT_APP_TEMPORARY_ORGANIZATION_ID);

    const response = await axios.post(endpointURL, fieldContent, {
        // TODO: Replace this with token from auth service
        headers: {
            ...(localStorage.getItem('user')
                ? {
                      authorization: `Bearer ${JSON.parse(localStorage.getItem('user') as string)?.tokens?.access_token ?? ''}`,
                      'x-hasura-organization-id': organizationId || ''
                  }
                : {})
        }
    });
    return response;
};

export const completeTask = async (
    taskId: number,
    organizationId: string | undefined,
    approvalStatus: 'approved' | 'rejected' = 'approved',
    isPublicTask: boolean = false
) => {
    const baseURL = process.env.REACT_APP_SNIKPIC_BASE_URL;
    const endpointURL = `${baseURL}/workflow/task/complete${isPublicTask ? '_public' : ''}_task`;

    const response = await axios.post(
        endpointURL,
        {
            id: taskId,
            approval_status: approvalStatus,
            ...(isPublicTask ? { organization_id: organizationId } : {})
        },
        {
            ...(!isPublicTask
                ? {
                      headers: {
                          authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '')?.tokens?.access_token ?? ''}`,
                          'x-hasura-organization-id': organizationId || ''
                      }
                  }
                : {})
        }
    );
    return response;
};

export const shareTask = async (email: string, link: string, organizationId: string | undefined) => {
    const baseURL = process.env.REACT_APP_SNIKPIC_BASE_URL;
    const endpointURL = `${baseURL}/workflow/task/share_task`;

    const response = await axios.post(
        endpointURL,
        {
            email,
            link
        },
        {
            headers: {
                authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '')?.tokens?.access_token ?? ''}`,
                'x-hasura-organization-id': organizationId || ''
            }
        }
    );
    return response;
};

export const getProcessmakerRequests = async (workflowId: string, organizationId: string | undefined) => {
    const baseURL = process.env.REACT_APP_SNIKPIC_BASE_URL;
    const endpointURL = `${baseURL}/workflow-runs`;

    const response = await axios.get(
        endpointURL,

        {
            params: {
                workflowId
            },
            headers: {
                authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '')?.tokens?.access_token ?? ''}`,
                'x-hasura-organization-id': organizationId || ''
            }
        }
    );
    return response;
};

export const getTasksByProcessmakerRequestId = async (processmakerRequestId: number, organizationId: string | undefined) => {
    const baseURL = process.env.REACT_APP_SNIKPIC_BASE_URL;
    const endpointURL = `${baseURL}/workflow/task/get_tasks_by_processmaker_request_id`;

    const response = await axios.post(
        endpointURL,
        {
            processmaker_request_id: processmakerRequestId
        },
        {
            headers: {
                authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '')?.tokens?.access_token ?? ''}`,
                'x-hasura-organization-id': organizationId || ''
            }
        }
    );
    return response;
};
