import { addEdge, MarkerType } from 'react-flow-renderer';
import React, { useCallback } from 'react';
import { Edge } from 'react-flow-renderer/dist/esm/types';

interface IDeleteConnection {
    setEdges: (edges: Edge[] | ((edges: Edge[]) => Edge[])) => void;
    getId: () => string;
    edges: Edge[];
}

const CreateEdges = ({ setEdges, getId, edges }: IDeleteConnection) =>
    useCallback(
        (params) => {
            const paramsConfig = {
                id: getId(),
                type: 'buttonEdge',
                markerEnd: {
                    type: MarkerType.Arrow,
                    color: '#EE9A8F'
                }
            };

            const cloneParams = Object.assign(params, paramsConfig);

            setEdges((eds) => addEdge(cloneParams, eds));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [edges]
    );

export default CreateEdges;
