import { createContext, FC, useContext, useState } from 'react';

interface Document {
    id: string;
}

interface DocumentContextProps {
    document: Document;
    updateDocument: (newDocument: Document) => void;
}

const DocumentContext = createContext({} as DocumentContextProps);

const DocumentProvider: FC<{ initialDocument: Document }> = ({ children, initialDocument }) => {
    const [document, setDocument] = useState(initialDocument);

    return <DocumentContext.Provider value={{ document, updateDocument: setDocument }}> {children} </DocumentContext.Provider>;
};

export const useDocument = () => useContext(DocumentContext);

export default DocumentProvider;
