import axios, { AxiosInstance } from 'axios';

// Axios client
const baseURL = process.env.REACT_APP_SNIKPIC_BASE_URL;

// Utils
const generateHeaders = () => ({
    authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || `{}`)?.tokens?.access_token ?? ''}`
});

export const getEmployees = async () => {
    try {
        const client: AxiosInstance = axios.create({ baseURL, headers: generateHeaders() });
        const endpoint = '/virtual-employee/employees';
        return await client.get(endpoint);
    } catch (e) {
        const err: any = e;
        console.error(err.response.data);
        throw new Error(err.response.data.extensions.message);
    }
};

export const getTasks = async (arg: { employeeId?: string }) => {
    try {
        const { employeeId } = arg;
        const client: AxiosInstance = axios.create({ baseURL, headers: generateHeaders() });
        const endpoint = employeeId ? `/virtual-employee/tasks?employee_id=${employeeId}` : '/virtual-employee/tasks';
        return await client.get(endpoint);
    } catch (e) {
        const err: any = e;
        console.error(err.response.data);
        throw new Error(err.response.data.extensions.message);
    }
};

export const getTask = async (input: { taskId: string }) => {
    try {
        const client: AxiosInstance = axios.create({ baseURL, headers: generateHeaders() });
        const endpoint = `/virtual-employee/tasks/${input.taskId}`;
        return await client.get(endpoint);
    } catch (e) {
        const err: any = e;
        console.error(err.response.data);
        throw new Error(err.response.data.extensions.message);
    }
};

export const getFinetuning = async (input: { taskId: string; brandId?: string; userId?: string }) => {
    try {
        console.log('In service > REST > getFinetuning');
        console.log('input: ', input);
        const { taskId, brandId, userId } = input;
        const client: AxiosInstance = axios.create({ baseURL, headers: generateHeaders() });

        // Define endpoint
        let endpoint = '/virtual-employee/finetunings?';
        if (taskId && !brandId && !userId) {
            endpoint += `task_id=${input.taskId}`;
        }
        if (taskId && brandId) {
            endpoint += `task_id=${input.taskId}&brand_id=${input.brandId}`;
        }
        if (taskId && userId) {
            endpoint += `task_id=${input.taskId}&user_id=${input.userId}`;
        }
        if (taskId && brandId && userId) {
            endpoint += `task_id=${input.taskId}&brand_id=${input.brandId}&user_id=${input.userId}`;
        }

        // Fetch and return
        return await client.get(endpoint);
    } catch (e) {
        const err: any = e;
        console.error(err.response.data);
        throw new Error(err.response.data.extensions.message);
    }
};

export const createFinetuning = async (input: { taskId: string; brandId?: string; userId?: string }) => {
    try {
        const client: AxiosInstance = axios.create({ baseURL, headers: generateHeaders() });
        const endpoint = `/virtual-employee/finetunings`;
        const data = {
            taskId: input.taskId,
            brandId: input.brandId
        };
        return await client.post(endpoint, data);
    } catch (e) {
        const err: any = e;
        console.error(err.response.data);
        throw new Error(err.response.data.extensions.message);
    }
};

export const editFinetuning = async (input: { finetuningId: string; examplesInputOutput: { input: string; output: string }[] }) => {
    try {
        const client: AxiosInstance = axios.create({ baseURL, headers: generateHeaders() });
        const endpoint = `/virtual-employee/finetunings/${input.finetuningId}`;
        const data = {
            examples_input_output: input.examplesInputOutput
        };
        return await client.patch(endpoint, data);
    } catch (e) {
        const err: any = e;
        console.error(err.response.data);
        throw new Error(err.response.data.extensions.message);
    }
};

export const createUpdateDeleteIoExample = async (input: {
    finetuningId: string;
    action: string;
    index?: number;
    inputOutputExample?: { input: string; output: string };
}) => {
    // console.log('In createUpdateDeleteIoExample');
    // console.log('input: ', input);
    try {
        const client: AxiosInstance = axios.create({ baseURL, headers: generateHeaders() });
        const endpoint = `/virtual-employee/finetunings/${input.finetuningId}/create_update_delete_io_example`;
        const data = {
            action: input.action,
            index: input.index?.toString() || null, // Max 2023-03-23 Convert to string because index=0 in the BE will be interpreted as null... (there is probably a native way to it but okay...)
            io_example_content: input.inputOutputExample || null
        };
        return await client.post(endpoint, data);
    } catch (e) {
        const err: any = e;
        console.error(err.response.data);
        throw new Error(err.response.data.extensions.message);
    }
};
