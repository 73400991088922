import { Box, useTheme } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { ConditionBuilderContext, useConditionBuilderContext } from './context/ConditionBuilderContext';
import { v4 as uuid } from 'uuid';
import { ConditionBlockContent, ConditionBlockHeader } from './components/ConditionBlock';
import { ThenOrElseBlockContent, ThenOrElseBlockHeader } from './components/ThenOrElseBlock';
import { LogicBuilderExpression, LogicBuilderIfExpression } from './types/logicBuilder.types';
import { JsonLogicIfExpression } from './types/jsonLogic.types';

export const LogicBuilder: FC = () => {
    const theme = useTheme();

    return (
        <Box sx={{ border: `1px solid ${theme.palette.grey[500]}`, p: 2, borderRadius: '8px', m: '12px 0' }}>
            <LogicBlock />
        </Box>
    );
};

interface BlockFactoryProps {
    conditions: LogicBuilderExpression[];
}

// TODO - TO BE REVIEWED WHEN NESTED LOGIC IS IMPLEMENTED
const BlockFactory = ({ conditions }: BlockFactoryProps) => {
    for (const element of conditions) {
        if (element.type === 'if') {
            return <IfBlock conditionObject={element} />;
            // eslint-disable-next-line no-else-return
        } else if (element.type === 'or') {
            return <Box>OR BLOCK</Box>;
        } else if (element.type === 'and') {
            return <Box>and BLOCK</Box>;
        } else if (element.type === '==') {
            return <Box>==</Box>;
        } else if (element.type === 'text') {
            return <Box>TEXT</Box>;
        }
    }
    return <></>;
};

const LogicBlock = () => {
    const { conditions } = useContext(ConditionBuilderContext);

    return (
        <>
            <BlockFactory conditions={conditions} />
        </>
    );
};

interface IfBlockProps {
    conditionObject: LogicBuilderIfExpression;
}

export const IfBlock = ({ conditionObject }: IfBlockProps) => {
    const { updateConditions } = useContext(ConditionBuilderContext);

    const addRuleHandler = () => {
        const newCondition = { ...conditionObject };
        // Note: Normally the condition on the array is not necessary as the content of an if is always a or or an and. But it's here for typescript
        if (Array.isArray(newCondition.content[0].content)) {
            newCondition.content[0].content.push({ id: uuid(), type: '==', content: [{ type: 'document' }, false] });
        }
        updateConditions(newCondition);
    };

    const operatorChangeHandler = (newOperator: 'and' | 'or') => {
        const newCondition = { ...conditionObject };
        newCondition.content[0].type = newOperator;
        updateConditions(newCondition);
    };

    return (
        <>
            {/* Condition statement part */}
            <Box>
                <ConditionBlockHeader addRuleHandler={addRuleHandler} />
                <ConditionBlockContent conditionObject={conditionObject} operatorChangeHandler={operatorChangeHandler} />
            </Box>
            {/* Then part */}
            <ThenOrElseBlockHeader thenOrElse="then" />
            <ThenOrElseBlockContent conditionObject={conditionObject.content[1]} />
            <ThenOrElseBlockHeader thenOrElse="else" />
            <ThenOrElseBlockContent conditionObject={conditionObject.content[2]} />
        </>
    );
};

export default LogicBuilder;
