import _ from 'lodash';
import { AutomationConfigurationSet, automationConfigurationSets, MultiLanguageString } from '@sni4/snikpic-common';
import { WORKFLOW_NODE_TYPES } from 'views/pages/clients/features/detail/workflow/features/types';
// import { automationConfigurationSet } from './example';
import TikTokIcon from './tiktok_logo.svg';
import InstagramIcon from './instagram_logo.svg';

// TODO: move workflow node types this to the common folder
export interface AvailableAutomationNode {
    label: MultiLanguageString;
    type: string;
    automation_set: WORKFLOW_NODE_TYPES;
    getDefaultConfiguration: () => AutomationConfigurationSet;
    icon: string;
}
// TODO: store available nodes in context ?
export const availableAutomationNodes = (): AvailableAutomationNode[] =>
    automationConfigurationSets.map((automationConfigurationSet) => ({
        label: automationConfigurationSet.label,
        type: 'automation_task',
        automation_set: WORKFLOW_NODE_TYPES.AUTOMATION_INSTAGRAM,
        getDefaultConfiguration: () => _.cloneDeep(automationConfigurationSet),
        icon: automationConfigurationSet.icon
    }));
