import { TextField as BaseTextField, Typography } from '@mui/material';
import { BaseFieldProps } from 'features/workflow-task-execution/types';
import { useFieldHook } from './useFieldHook';

interface TextFieldProps extends BaseFieldProps {
    isTextArea?: boolean;
}

export default function TextField({ field, setSavingStatus, isTextArea = false, handleFieldValueUpdate }: TextFieldProps) {
    const [value, handleChange, intl] = useFieldHook<string>({ field, setSavingStatus, handleFieldValueUpdate });

    return (
        <>
            <Typography sx={{ color: '#000', fontFamily: 'Inter', fontWeight: 500, fontSize: '14px', mb: 2, mt: 3 }}>
                {field.title}
            </Typography>
            <BaseTextField
                sx={{
                    '.MuiOutlinedInput-input': {
                        fontWeight: 400,
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        background: '#fff'
                    }
                }}
                id={field.id}
                name={field.id}
                variant="outlined"
                fullWidth
                placeholder={field.title}
                value={value}
                onChange={(e) => handleChange(e.target.value)}
                multiline={isTextArea}
                minRows={isTextArea ? 4 : 1}
                disabled={field.permission?.type === 'READ'}
                error={Boolean(field.permission?.is_required)}
                helperText={
                    field.permission?.is_required ? intl.formatMessage({ id: 'please_fill_in_this_field_to_complete_your_task' }) : ''
                }
            />
        </>
    );
}
