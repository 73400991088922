import axios from 'axios';
import { getPutSignedUrl } from 'services/rest/aws_s3';
import { v4 as uuid } from 'uuid';

export const handleFileUpload = async (file: any, type: string | undefined) => {
    // From docs: https://getstream.io/chat/docs/javascript/file_uploads/?language=javascript#using-your-own-cdn
    try {
        // console.log('In handleFileUpload');
        // console.log('file: ', file);
        const fileNameArray = file.name.split('.');
        const ext = fileNameArray[fileNameArray.length - 1];
        const fileNameWithoutExtArray = fileNameArray.slice(0, fileNameArray.length - 1);
        const fileNameWithoutExt = fileNameWithoutExtArray.join('.');
        const uniqueString = uuid().substring(0, 8);
        const path = `${process.env.REACT_APP_CHAT_ATTACHMENTS_PATH}/${fileNameWithoutExt}_${uniqueString}.${ext}`;
        const signedUrl = await getPutSignedUrl({ path });
        const config = { headers: { 'content-type': type || '' } };
        const response = await axios.put(signedUrl, file, config);
        const fileUrl = response?.config?.url?.split('?')[0] || 'error';
        return { fileUrl };
    } catch (e) {
        // console.log(JSON.stringify(e));
        return { fileUrl: 'error' };
        // return null;
    }
};
