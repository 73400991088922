import { Box, Typography, useTheme } from '@mui/material';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import WarningIcon from '@mui/icons-material/Warning';
import React from 'react';

export interface SavingStatusProps {
    status: 'saving' | 'saved' | 'not_saved' | 'error';
}

const savingStatusDictionary = {
    saving: 'Saving...',
    saved: 'Saved',
    not_saved: 'Not saved',
    error: 'Error'
};

export default function SavingStatusChip({ status }: SavingStatusProps) {
    return (
        <Box>
            <SavingStatusIcon status={status} />
            <Typography sx={{ color: '#aaa', fontFamily: 'Inter', fontWeight: 500, fontSize: '14px', display: 'inline' }}>
                {savingStatusDictionary[status]}
            </Typography>
        </Box>
    );
}

function SavingStatusIcon({ status }: { status: SavingStatusProps['status'] }) {
    const theme = useTheme();
    if (status === 'saving') {
        return (
            <Box
                sx={{
                    background: theme.palette.orange[100],
                    borderRadius: '100px',
                    width: '16px',
                    height: '16px',
                    display: 'inline-block',
                    mr: 1
                }}
            >
                <HourglassTopIcon sx={{ fill: '#fff', fontSize: '12px', ml: '2px' }} />
            </Box>
        );
    }
    if (status === 'saved') {
        return (
            <Box
                sx={{
                    background: theme.palette.success[100],
                    borderRadius: '100px',
                    width: '16px',
                    height: '16px',
                    display: 'inline-block',
                    mr: 1
                }}
            >
                <CheckOutlinedIcon sx={{ fill: '#fff', fontSize: '12px', ml: '2px' }} />
            </Box>
        );
    }
    if (status === 'not_saved') {
        return (
            <Box
                sx={{
                    background: theme.palette.grey[100],
                    borderRadius: '100px',
                    width: '16px',
                    height: '16px',
                    display: 'inline-block',
                    mr: 1
                }}
            >
                <CancelIcon sx={{ fill: '#fff', fontSize: '12px', ml: '2px' }} />
            </Box>
        );
    }

    return (
        <Box
            sx={{
                background: theme.palette.error[100],
                borderRadius: '100px',
                width: '16px',
                height: '16px',
                display: 'inline-block',
                mr: 1
            }}
        >
            <WarningIcon sx={{ fill: '#fff', fontSize: '12px', ml: '2px' }} />
        </Box>
    );
}
