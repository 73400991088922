import { useState } from 'react';
import Box from '@mui/material/Box';
import {
    Table,
    Popover,
    CardMedia,
    Dialog,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    Avatar,
    IconButton
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { useMutation } from '@apollo/client';
import moment from 'moment';

import zapoff from 'assets/images/zapoff.svg';
import DialogConfirm from 'ui-component/dialogConfirm';
import DrawerAddOffers from './AddOffersDrawer';
import { DEACTIVE_OFFER } from '../../../services/graphQL/mutations/offer/OffersMutations';
import { OfferType } from 'types/offer';

function EnhancedTableHead() {
    const theme = useTheme();

    return (
        <TableHead sx={{ borderBottom: `1px solid ${theme.palette.grey[500]}`, background: theme.palette.grey[700] }}>
            <TableRow>
                <TableCell align="left">
                    {' '}
                    <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600 }}>
                        <FormattedMessage id="name" />
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600 }}>
                        <FormattedMessage id="clients" />
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600 }}>
                        <FormattedMessage id="date_added" />
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600, display: 'none' }}>
                        <FormattedMessage id="date_added" />
                    </Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

interface OffersTableProps {
    offers: OfferType[];
    refetch: () => Promise<any>;
}
export default function OffersTable({ offers, refetch }: OffersTableProps) {
    const theme = useTheme();

    const FullTableRow = ({ offer }: { offer: OfferType }) => {
        const [showDivider, setShowDivider] = useState(false);
        const [showDialog, setShowDialog] = useState(false);
        const [anchorEl, setAnchorEl] = useState<any>(null);

        const [nameOffers, setNameOffers] = useState<any>();
        const [offerId, setOfferId] = useState('');

        const id = anchorEl ? 'simple-popover' : undefined;
        const openPopover = (event: any) => setAnchorEl(event.currentTarget);
        const closePopover = () => setAnchorEl(null);
        const openDialog = () => setShowDialog(true);
        const closeDialog = () => setShowDialog(false);
        const openDivider = () => setShowDivider(true);
        const closeDivider = () => setShowDivider(false);

        const [deactiveOfferMutation, { loading }] = useMutation(DEACTIVE_OFFER, {
            onCompleted: async (_: any) => {
                await refetch();
                closeDivider();
            },
            onError: (error: any) => {
                console.log(error);
            }
        });

        const handleClickDeactive = (offer: any) => {
            setOfferId(offer.id);
            openDialog();
            closePopover();
        };

        const deactiveOffer = () => {
            deactiveOfferMutation({
                variables: {
                    id: offerId,
                    status: 'ARCHIVED'
                }
            });
        };

        const handleClickPopoverView = (itemOffers: any) => {
            setNameOffers(itemOffers);
            openDivider();
            closePopover();
        };

        const clients = offer.projects
            .map((project) => project.client_organization)
            .filter((client, idx, arr) => idx === arr.findIndex(({ id }) => id === client.id));

        return (
            <TableRow hover role="checkbox" tabIndex={-1}>
                <TableCell
                    onClick={() => handleClickPopoverView(offer)}
                    component="th"
                    scope="row"
                    sx={{ width: '357px', pt: '35px', cursor: 'pointer' }}
                >
                    <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500 }}>{offer.title}</Typography>
                </TableCell>
                <TableCell
                    onClick={() => handleClickPopoverView(offer)}
                    align="left"
                    sx={{ width: '357px', pt: '35px', cursor: 'pointer' }}
                >
                    <Box sx={{ display: 'flex' }}>
                        {clients.slice(0, 3).map((client: any, indexClient: number) => (
                            <Box key={indexClient + 1} sx={{ width: '32px', mr: indexClient + 1 > 0 ? '-12px' : 0 }}>
                                <Avatar sx={{ width: '32px', height: '32px' }} alt="Remy Sharp" src={client.logo_url} />
                            </Box>
                        ))}
                        {clients.length > 3 && (
                            <Box
                                sx={{
                                    background: theme.palette.grey[700],
                                    borderRadius: '100px',
                                    width: '32px',
                                    zIndex: 2,
                                    border: `2px solid ${theme.palette.background.paper}`,
                                    p: '5px 0 0 4px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600, fontSize: '10px' }}>
                                    {`+${clients.length - 3}`}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </TableCell>
                <TableCell
                    onClick={() => handleClickPopoverView(offer)}
                    align="left"
                    sx={{ width: '357px', pt: '30px', cursor: 'pointer' }}
                >
                    <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600 }}>
                        {moment(offer.created_at).format('DD MMMM, YYYY')}
                    </Typography>
                </TableCell>
                <TableCell align="right" sx={{ pt: '30px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Box sx={{ width: '40px' }}>
                            <IconButton onClick={openPopover}>
                                <MoreHoriz />
                            </IconButton>
                            <Popover
                                id={id}
                                open={!!anchorEl}
                                anchorEl={anchorEl}
                                onClose={closePopover}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                            >
                                <Box sx={{ p: '24px' }}>
                                    <Box
                                        onClick={() => handleClickPopoverView(offer)}
                                        sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
                                    >
                                        <VisibilityOutlinedIcon sx={{ width: '20px' }} />
                                        <Typography
                                            sx={{
                                                color: theme.palette.grey[400],
                                                fontFamily: 'Inter',
                                                fontWeight: 500,
                                                fontSize: '14px',
                                                ml: '10px'
                                            }}
                                        >
                                            <FormattedMessage id="view" />
                                        </Typography>
                                    </Box>
                                    <Box
                                        onClick={() => handleClickDeactive(offer)}
                                        mt={1}
                                        sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
                                    >
                                        <CardMedia
                                            sx={{ objectFit: 'contain' }}
                                            component="img"
                                            width="18px"
                                            image={zapoff}
                                            alt="alt image"
                                        />
                                        <Typography
                                            sx={{
                                                color: theme.palette.grey[400],
                                                fontFamily: 'Inter',
                                                fontWeight: 500,
                                                fontSize: '14px',
                                                ml: '12px'
                                            }}
                                        >
                                            <FormattedMessage id="deactivate" />
                                        </Typography>
                                    </Box>
                                </Box>
                            </Popover>
                        </Box>
                    </Box>
                </TableCell>
                <Dialog sx={{ '.MuiDialog-paper': { minWidth: { xs: '350px', sm: '562px' } } }} onClose={closeDialog} open={showDialog}>
                    <DialogConfirm onConfirm={deactiveOffer} onClose={closeDialog} loading={loading} />
                </Dialog>
                <DrawerAddOffers open={showDivider} onClose={closeDivider} item={nameOffers} />
            </TableRow>
        );
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table sx={{ borderRadius: '8px 8px 0 0', minWidth: 750 }} aria-labelledby="tableTitle">
                        <EnhancedTableHead />
                        <TableBody>
                            {offers.map((offer: OfferType, index: number) => (
                                <FullTableRow offer={offer} key={offer.id} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
}
