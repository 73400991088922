import { combineReducers } from 'redux';
import snackbarReducer from './slices/snackbar';
import menuReducer from './slices/menu';
import clientsReducer from './slices/clients';

const reducer = combineReducers({
    snackbar: snackbarReducer,
    menu: menuReducer,
    clients: clientsReducer
});

export default reducer;
