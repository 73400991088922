// eslint-disable-next-line import/no-named-as-default
import PublicDeliverable from 'features/workflow-task-execution/PublicDeliverable';
import IndividualFinetuningPage from 'views/pages/ai/IndividualFinetuningPage';

const PublicTasksRoutes = {
    children: [
        {
            path: '/public/task/:organizationId/:taskId',
            element: <PublicDeliverable />
        },
        {
            path: '/public/ai/finetuning/users/:userId/tasks/:taskId',
            element: <IndividualFinetuningPage />
        }
    ]
};

export default PublicTasksRoutes;
