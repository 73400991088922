import { gql } from '@apollo/client';

export const DOCUMENT_VERSION_FRAGMENT = gql`
    fragment DocumentVersionFragment on workflow_document_versions {
        id
        document_id
        created_at
        content
    }
`;

export const DOCUMENT_DATA_FRAGMENT = gql`
    ${DOCUMENT_VERSION_FRAGMENT}
    fragment DocumentData on workflow_documents {
        id
        document_versions {
            ...DocumentVersionFragment
        }
    }
`;
