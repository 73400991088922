import { Box, Typography } from '@mui/material';
import { memo } from 'react';
import { useTheme } from '@mui/material/styles';

import { FlowNode } from '../../features/document-builder/ui';
import { Handle, Position } from 'react-flow-renderer';

export default memo(({ data, isConnectable }: any) => {
    const theme = useTheme();

    return (
        <FlowNode sx={{ padding: '24px', gap: '8px', width: '350px' }}>
            <Handle
                type="target"
                position={Position.Top}
                isConnectable={isConnectable}
                style={{ width: '1px', height: '1px', background: 'white' }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <Typography sx={{ color: theme.palette.text.primary, fontSize: '14px', fontWeight: 500, fontFamily: 'Inter' }}>
                    {data.title}
                </Typography>
                <Typography
                    sx={{
                        color: theme.palette.text.secondary,
                        fontSize: '10px',
                        fontWeight: 500,
                        fontFamily: 'Inter',
                        textTransform: 'uppercase'
                    }}
                >
                    {data.short}
                </Typography>
            </Box>
            <Typography sx={{ color: theme.palette.text.secondary, fontSize: '14px', fontWeight: 400, fontFamily: 'Inter' }}>
                {data.value}
            </Typography>
            <Handle
                type="source"
                position={Position.Bottom}
                isConnectable={isConnectable}
                style={{ width: '1px', height: '1px', background: 'white' }}
            />
        </FlowNode>
    );
});
