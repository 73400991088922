import { Divider, TextField, Typography, useTheme } from '@mui/material';
import { RequiredTag } from './RequiredTag';
import { RowBody } from 'views/pages/clients/features/detail/workflow/features/document-builder/ui';
import { AutomationConfigurationField, DirectInputTextAutomationConfigurationField } from '@sni4/snikpic-common';
import { ExtractMultiLangString, FormatMultiLangString } from 'features/workflow-automation/utils/multi-lang-string.util';

interface DirectInputTextProps {
    configuration: DirectInputTextAutomationConfigurationField;
    path: string;
    updateConfiguration: (arg: { configuration: AutomationConfigurationField; path: string }) => void;
}

export const DirectInputText = ({ configuration, updateConfiguration, path }: DirectInputTextProps) => {
    const theme = useTheme();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateConfiguration({ configuration: { ...configuration, value: event.target.value }, path });
    };

    return (
        <>
            <RowBody sx={{ gap: '16px' }}>
                <Typography variant="subtitle1" sx={{ color: theme.palette.text.primary }}>
                    <FormatMultiLangString multiLangString={configuration.label} />
                </Typography>
                {configuration.required && <RequiredTag />}
                <Divider sx={{ flex: 1 }} />
            </RowBody>
            <TextField
                sx={{
                    '.MuiOutlinedInput-input': { background: '#fff' }
                }}
                fullWidth
                InputLabelProps={{ shrink: true }}
                placeholder={ExtractMultiLangString(configuration.placeholder)}
                value={configuration.value || ''}
                onChange={handleValueChange}
            />
        </>
    );
};
