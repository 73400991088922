import { Popover, PopoverProps, Box } from '@mui/material';

type PopoverMenuProps = Partial<PopoverProps> & {
    open: boolean;
    onClose: () => void;
};
export const PopoverMenu = ({ open, onClose, children, ...others }: PopoverMenuProps) => (
    <Popover
        open={open}
        onClose={onClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
        }}
        {...others}
    >
        <Box sx={{ py: 1 }}>{children}</Box>
    </Popover>
);
