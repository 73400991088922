import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Avatar, Box, Typography, Grid, CardMedia, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconChevronRight } from '@tabler/icons';
import RelevantEventFinder from './RelevantEventFinder';
import PublicationConceptGenerator from './PublicationConceptGeneratorv2';
import CaptionGenerator from './CaptionGenerator';
import bookmark from 'assets/images/bookmark.svg';
import fileText from 'assets/images/fileText.svg';
import imageAi from 'assets/images/imageAi.svg';
import plus from 'assets/images/plus.svg';
import ImageGenerator from './ImageGenerator';

interface AIToolsDrawerContentProps {
    handleClose: any;
}

const list = [
    {
        icon: (
            <Box sx={{ with: '18px', mr: '5px' }}>
                <CardMedia sx={{ objectFit: 'contain' }} component="img" image={bookmark} alt="AI tools" />
            </Box>
        ),
        formatId: 'relevant_elevant_finder'
    },
    {
        icon: (
            <Box sx={{ with: '18px', mr: '5px' }}>
                <CardMedia sx={{ objectFit: 'contain' }} component="img" image={plus} alt="AI tools" />
            </Box>
        ),
        formatId: 'publication_concept_generator'
    },
    {
        icon: (
            <Box sx={{ with: '18px', mr: '5px' }}>
                <CardMedia sx={{ objectFit: 'contain' }} component="img" image={fileText} alt="AI tools" />
            </Box>
        ),
        formatId: 'caption_generator'
    },
    {
        icon: (
            <Box sx={{ with: '18px', mr: '5px' }}>
                <CardMedia sx={{ objectFit: 'contain' }} component="img" image={imageAi} alt="AI tools" />
            </Box>
        ),
        formatId: 'image_generator'
    }
];

const AIToolsDrawerContent = ({ handleClose }: AIToolsDrawerContentProps) => {
    const theme = useTheme();
    const [tools, setTools] = React.useState('');
    const handleToolsNone = () => {
        setTools('');
    };
    const handleTools = (tool: string) => {
        setTools(tool);
    };

    const renderTool = () => {
        switch (tools) {
            case 'relevant_elevant_finder':
                return <RelevantEventFinder handleToolsNone={handleToolsNone} />;
            case 'publication_concept_generator':
                return <PublicationConceptGenerator handleToolsNone={handleToolsNone} />;
            case 'caption_generator':
                return <CaptionGenerator handleToolsNone={handleToolsNone} />;
            case 'image_generator':
                return <ImageGenerator handleToolsNone={handleToolsNone} />;
            default:
                return '';
        }
    };
    return (
        <>
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: '81px',
                    padding: 3,
                    display: 'flex',
                    borderBottomWidth: 1,
                    borderBottomStyle: 'solid',
                    borderColor: theme.palette.grey[500],
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                    <Typography variant="h5">AI Tools</Typography>
                </Box>
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        position: 'absolute',
                        left: { xs: '', sm: 0 },
                        transform: { xs: 'translateX(0%)', sm: 'translateX(-50%)' },
                        right: { xs: '20px', sm: '' },
                        overflow: 'hidden',
                        transition: 'all .2s ease-in-out',
                        border: '1px solid',
                        borderColor: theme.palette.grey[500],
                        borderRadius: '100%',
                        backgroundColor: 'white'
                    }}
                    onClick={() => handleClose()}
                    color="inherit"
                >
                    <IconChevronRight stroke={1.5} size="1.3rem" />
                </Avatar>
            </Box>
            {renderTool() === '' ? (
                <Grid container direction="column" spacing={2} p={3}>
                    {list &&
                        list.length > 0 &&
                        list.map((listItem, index) => (
                            <Grid item key={index}>
                                <Button
                                    onClick={() => handleTools(listItem.formatId)}
                                    sx={{
                                        letterSpacing: '0.46px',
                                        fontSize: { xs: '16px', md: '14px' },
                                        borderRadius: { xs: '4px', md: '10px' },
                                        padding: { xs: '13px', md: '16px' },
                                        background: theme.palette.grey[700],
                                        width: '100%',
                                        color: '#1d2675',
                                        border: theme.palette.orange.main,
                                        fontFamily: 'Inter',
                                        fontWeight: 500,
                                        justifyContent: 'space-between',
                                        // '&:hover': {
                                        //     background: theme.palette.background.paper
                                        // },
                                        height: '42px'
                                    }}
                                >
                                    <Box display="flex" alignItems="center">
                                        {listItem.icon}
                                        <Typography
                                            sx={{
                                                fontFamily: 'Inter',
                                                fontWeight: 600,
                                                fontSize: '14px',
                                                color: theme.palette.success[300]
                                            }}
                                        >
                                            <FormattedMessage id={listItem.formatId} />
                                        </Typography>
                                    </Box>
                                    <IconChevronRight />
                                </Button>
                            </Grid>
                        ))}
                </Grid>
            ) : (
                renderTool()
            )}
        </>
    );
};

export default AIToolsDrawerContent;
