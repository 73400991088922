import axios from 'axios';

interface GetPutSignedUrlInput {
    path: string;
}

export const getPutSignedUrl = async (input: GetPutSignedUrlInput) => {
    const baseURL = process.env.REACT_APP_SNIKPIC_BASE_URL;
    const endpointURL = `${baseURL}/aws/s3/generate_put_signed_url`;
    try {
        const response = await axios.post(
            endpointURL,
            {
                path: input.path
            },
            {
                headers: {
                    authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '')?.tokens?.access_token ?? ''}`
                }
            }
        );
        return response.data;
    } catch (e) {
        const err: any = e;
        console.error(err.response.data);
        throw new Error(err.response.data.extensions.message);
    }
};
