import { ApolloClient, InMemoryCache, createHttpLink, from, FetchResult, Observable } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { loadState } from 'utils/storage';
import jwt from 'jsonwebtoken';

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_SNIKPIC_GQL_URL
});

const errorLink = (ref: any) =>
    onError(({ graphQLErrors, networkError, operation, forward }) => {
        if (graphQLErrors) {
            const state = loadState('user');
            const token = state.tokens?.access_token;
            const decoded = jwt.decode(token) as any;
            const expired = parseInt(decoded?.auth_time ?? '0', 10) < new Date().getTime() / 1000;
            // for (const err of graphQLErrors) {
            //     if (err.message?.includes('query_root')) {
            //         unAuth = true;
            //         break;
            //     }
            // }

            if (operation.operationName === 'refreshToken' || !expired) return;
            const observable = new Observable<FetchResult<Record<string, any>>>((observer) => {
                (async () => {
                    try {
                        ref.current && (await ref.current());

                        const subscriber = {
                            next: observer.next.bind(observer),
                            error: observer.error.bind(observer),
                            complete: observer.complete.bind(observer)
                        };

                        forward(operation).subscribe(subscriber);
                    } catch (err) {
                        observer.error(err);
                    }
                })();
            });

            // eslint-disable-next-line consistent-return
            return observable;
        }

        if (networkError) console.log(`[Network error]: ${networkError}`);
    });

const authLink = setContext((_, { headers, removeHeader }) => {
    // get the authentication token from local storage if it exists
    const state = loadState('user');
    const token = state.tokens?.access_token;
    const organizationId = state.member?.organizationId;
    const user = state.user;

    const newHeaders = {
        ...headers
        // 'x-hasura-admin-secret': process.env.REACT_APP_HASURA_ADMIN_SECRET ?? undefined
    };
    if (!removeHeader && token) {
        newHeaders.Authorization = `Bearer ${token}`;
        delete newHeaders['x-hasura-admin-secret'];
        newHeaders['x-snikpic-organization'] = organizationId;
        newHeaders['x-hasura-organization-id'] = organizationId;
    } else if (user?.id) {
        newHeaders['x-hasura-user-id'] = user.id;
    }
    if (organizationId && !token) {
        newHeaders['x-hasura-organization-id'] = organizationId;
    }
    return { headers: newHeaders };
});

export const createApolloClient = (ref: any) =>
    new ApolloClient({
        link: from([errorLink(ref), authLink, httpLink]),
        cache: new InMemoryCache(),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'cache-and-network'
            }
        }
    });
