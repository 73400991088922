// Config
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Material
import { Box, Typography, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

// Utils
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';

// Components
import InputOutputCard from './InputOutputCard';
import InputOutputEditor from './InputOutputEditor';

// Services
import { getTask, getFinetuning, createFinetuning } from '../../../../../../services/rest/virtual-employee';

interface FinetuningCardProps {
    taskId: string;
    brandId?: string;
    userId?: string;
}

export interface InputOutputCardType {
    finetuningId: string;
    input: string;
    output: string;
}

const FinetuningCard = ({ taskId, brandId, userId }: FinetuningCardProps) => {
    // Context & global state
    const theme = useTheme();
    // const { idClient } = useParams();

    // Local state
    const [taskName, setTaskName] = useState('');
    const [taskDescription, setTaskDescription] = useState('');
    const [finetuningId, setFinetuningId] = useState('');
    const [examplesInputOutput, setExamplesInputOutput] = useState([]);
    // console.log('In local state > taskName: ', taskName);
    // console.log('In local state > taskDescription: ', taskDescription);
    // console.log('In local state > finetuningId: ', finetuningId);
    // const examplesInputOutput = [
    //     {
    //         input: 'input 1',
    //         output: 'output 1'
    //     },
    //     {
    //         input: 'input 2',
    //         output: 'output 2'
    //     }
    // ];

    const [showCaptionGenerator, setShowCaptionGenerator] = useState(false);
    const closeCaptionGenerator = () => setShowCaptionGenerator(false);
    const openCaptionGenerator = () => {
        setShowCaptionGenerator(true);
        // setSelectedCaptionGenerator(item);
        closePopover();
    };
    const closePopover = () => setAnchorEl(null);
    const [anchorEl, setAnchorEl] = useState<any>(null);
    // const [selectedCaptionGenerator, setSelectedCaptionGenerator] = useState<InputOutputCardProps>();

    // Data fetching
    const getTool = async () => {
        const response = await getTask({ taskId });
        setTaskName(response.data.action);
        setTaskDescription(response.data.role);
    };

    const getInputOutpoutExamples = async () => {
        if (brandId || userId) {
            const response = await getFinetuning({ taskId, brandId, userId });
            if (response.data?.finetuningId) {
                setFinetuningId(response.data.finetuningId);
                setExamplesInputOutput(response.data.examplesInputOutput.length > 0 ? response.data.examplesInputOutput : []);
            } else {
                const response = await createFinetuning({ taskId, brandId, userId });
                setFinetuningId(response.data.finetuningId);
            }
        }
    };

    const initialize = async () => {
        await getTool();
        await getInputOutpoutExamples();
    };

    // useEffects
    useEffect(() => {
        initialize();
    }, [taskId, finetuningId]);

    // Return
    return (
        <Box>
            <Grid container spacing={2}>
                {/* <Grid item xs={12} sm={3}>
                    <Typography variant="h4">{taskName || ''}</Typography>
                    <Typography variant="subtitle2" sx={{ pt: 2 }}>
                        {taskDescription || ''}
                    </Typography>
                </Grid> */}
                {/* <Grid item xs={12} sm={9}> */}
                <Grid item xs={12} sm={12}>
                    <Box
                        sx={{
                            border: `1px solid ${theme.palette.grey[500]}`,
                            p: 2,
                            boxShadow: '0px 10px 16px rgba(0, 0, 0, 0.05)',
                            borderRadius: '8px'
                        }}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="overline">
                                <FormattedMessage id="examples_input_output" />
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="h4">({examplesInputOutput.length})</Typography>
                                {/* <AddIcon sx={{ ml: 1, fontSize: '20px', cursor: 'pointer' }} /> */}
                                {finetuningId && (
                                    <AddIcon onClick={openCaptionGenerator} sx={{ ml: 1, fontSize: '20px', cursor: 'pointer' }} />
                                )}
                            </Box>
                        </Box>
                        {examplesInputOutput?.map((item: { input: string; output: string }, index) => (
                            <InputOutputCard
                                key={index}
                                finetuningId={finetuningId}
                                index={index}
                                input={item.input}
                                output={item.output}
                                getFinetuning={getInputOutpoutExamples}
                            />
                        ))}
                    </Box>
                </Grid>
            </Grid>
            <InputOutputEditor
                open={showCaptionGenerator}
                onClose={closeCaptionGenerator}
                getFinetuning={getInputOutpoutExamples}
                finetuningId={finetuningId}
            />
        </Box>
    );
};

export default FinetuningCard;
