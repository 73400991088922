import * as React from 'react';
import { Box, Typography, CardMedia } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { FormattedMessage } from 'react-intl';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import calendar from 'assets/images/calendar.svg';
import chatCircle from 'assets/images/chatCircle.svg';
import clockCounter from 'assets/images/clockCounter.svg';
import RectangleStart from 'assets/images/RectangleStart.svg';
import brainIcon from 'assets/images/brainAI.svg';
import branchIcon from 'assets/images/branch.svg';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { Button } from 'ui-component/extended/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DIalogSharedLink from 'views/pages/tasks/DIalogSharedLink';
import CommentsDrawer from './components/CommentDrawer';
import {
    getTask,
    getDocumentInstanceAttachedToTask,
    completeTask,
    shareTask,
    getFullDocumentAttachedToWorkflowRun,
    getPublicTask
} from 'services/rest/workflow_run';
import { DateTime } from 'luxon';
import { useMutation } from '@apollo/client';
import { openSnackbar } from 'store/slices/snackbar';
import produce from 'immer';
import { Workflow_Section_Comment_Status_Enum } from 'api/codegen-graphql/generated/graphql';
import SavingStatus, { SavingStatusProps } from './components/Chips/SavingStatusChip';
import { DocumentInstanceDTO, SectionCommentDTO, TaskDTO, TaskStatusEnum, WorkflowSectionCommentStatusEnumDTO } from './types';
import { UPDATE_SECTION_COMMENT } from './services/graphQL/queries';
import TaskStatusChip from './components/Chips/TaskStatusChip';
import TaskDueDateStatus from './components/Chips/TaskDueDateStatusChip';
import PageContent from './components/PageContent';
import { useDispatch } from 'store';
import useAuth from 'hooks/useAuth';
import AIToolsDrawerContent from 'modules/ai-tools/AIToolsDrawerContent';

// const temporaryContext = {
//     task_id: 425
// };

interface DialogTableDeliverablesProps {
    handleClose: () => void;
    isPublic?: boolean;
    taskId?: number;
    workflowRunId?: number;
    documentVersionId?: string;
    organizationId?: string;
}

type ApprovalStatus = 'approved' | 'rejected' | 'not_reviewed';

export default function DialogTableDeliverables({
    handleClose,
    taskId,
    workflowRunId,
    documentVersionId,
    isPublic = false,
    organizationId
}: DialogTableDeliverablesProps) {
    const theme = useTheme();
    const { member } = useAuth();

    const dispatch = useDispatch();
    const [taskData, setTaskData] = React.useState<TaskDTO | null>(null);
    const [documentInstance, setDocumentInstance] = React.useState<DocumentInstanceDTO | null>(null);
    const [comments, setComments] = React.useState<SectionCommentDTO[]>([]);
    const [activePageIndex, setActivePage] = React.useState<number>(0);
    const [dialogShared, setDialogShared] = React.useState(false);
    const [savingStatus, setSavingStatus] = React.useState<SavingStatusProps['status']>('saved');
    const [approvalStatus, setApprovalStatus] = React.useState<ApprovalStatus[]>([]);
    const [openAIToolDrawer, setOpenAIToolDrawer] = React.useState(false);

    const [openDivider, setOpenDivider] = React.useState(false);

    const fetchTaskData = async () => {
        if (!taskId) return;

        let response: any;

        if (!isPublic) {
            response = await getTask(taskId, member?.organizationId);
        }
        if (isPublic && organizationId) {
            response = await getPublicTask(taskId, organizationId);
        }
        if (response) setTaskData(response.data);
    };

    const updateDocumentInstance = async () => {
        let response: any;
        if (taskId) response = await getDocumentInstanceAttachedToTask(taskId, member?.organizationId);
        if (workflowRunId && documentVersionId) {
            response = await getFullDocumentAttachedToWorkflowRun(workflowRunId, documentVersionId, member?.organizationId);
        }
        if (response) {
            setDocumentInstance(response.data);
            const updatedComments: SectionCommentDTO[] = [];
            (response.data as DocumentInstanceDTO).pages.forEach((page) => {
                page.sections.forEach((section) => {
                    if (section.comments) {
                        updatedComments.push(...section.comments);
                    }
                });
            });
            setComments(updatedComments);

            if (approvalStatus.length === 0) {
                const newApprovalStatus: ApprovalStatus[] = [];

                (response.data as DocumentInstanceDTO).pages.forEach((page) => {
                    newApprovalStatus.push('not_reviewed');
                });
                setApprovalStatus(newApprovalStatus);
            }
        }
    };

    const [updateSectionComment] = useMutation(UPDATE_SECTION_COMMENT, {
        onCompleted: (data) => {},
        onError: (e: any) => {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e.message,
                    variant: 'error'
                })
            );
        }
    });

    const setSectionComment = (
        id: string,
        pageIndex: number,
        sectionIndex: number,
        newStatus: WorkflowSectionCommentStatusEnumDTO,
        content: string
    ) => {
        setDocumentInstance(
            produce((newDocumentInstance) => {
                newDocumentInstance?.pages[pageIndex].sections[sectionIndex].comments?.forEach((comment) => {
                    if (comment.id === id) {
                        comment.status = newStatus;
                        comment.content = content;
                    }
                });
                if (newDocumentInstance) {
                    newDocumentInstance.pages[pageIndex].comments_count = newDocumentInstance?.pages[pageIndex].sections.reduce(
                        (acc, section) => acc + (section?.comments?.filter((comment) => comment?.status === 'OPEN').length || 0),
                        0
                    );
                }
            })
        );
    };

    const handleCommentUpdate = async (
        id: string,
        pageIndex: number,
        sectionIndex: number,
        status: WorkflowSectionCommentStatusEnumDTO,
        content: string
    ) => {
        const { status: initialStatus, content: initialContent } = documentInstance?.pages[pageIndex].sections[sectionIndex].comments?.find(
            (comment) => comment.id === id
        ) as SectionCommentDTO;

        try {
            setSectionComment(id, pageIndex, sectionIndex, status, content);

            await updateSectionComment({
                variables: {
                    id,
                    content,
                    status: status as unknown as Workflow_Section_Comment_Status_Enum
                }
            });
        } catch (e) {
            initialStatus && setSectionComment(id, pageIndex, sectionIndex, initialStatus, initialContent);
            throw e;
        }
    };

    const handleFieldValueUpdate = async (sectionIndex: number, fieldIndex: number, body: any) => {
        setDocumentInstance(
            produce((newDocumentInstance) => {
                if (newDocumentInstance?.pages[activePageIndex].sections[sectionIndex].fields[fieldIndex]) {
                    newDocumentInstance.pages[activePageIndex].sections[sectionIndex].fields[fieldIndex].body = body;
                }
            })
        );
    };

    const handleClickCloseDivider = () => {
        setOpenDivider(false);
    };
    const handleOpenDivider = () => {
        setOpenDivider(true);
    };

    const handleOpenAIToolDrawer = () => {
        setOpenAIToolDrawer(true);
    };

    const handleCloseAIToolDrawer = () => {
        setOpenAIToolDrawer(false);
    };

    const handleOpenialogShared = () => setDialogShared(true);
    const handleCloseialogShared = () => setDialogShared(false);

    const nextPublication = () => {
        setActivePage(Math.min(activePageIndex + 1, (documentInstance?.pages.length ?? 1) - 1));
    };
    const prevPublication = () => {
        setActivePage(Math.max(0, activePageIndex - 1));
    };
    const handleApproveOrReject = async (newApprovalStatus: ApprovalStatus) => {
        if (!taskId) return;
        const keepNonEvaluatedItems = (_: ApprovalStatus) => _ === 'not_reviewed';
        const keepApprovedItems = (_: ApprovalStatus) => _ === 'approved';
        const updatedStatus = [...approvalStatus];
        updatedStatus[activePageIndex] = newApprovalStatus;

        if (updatedStatus.filter(keepNonEvaluatedItems).length === 0) {
            const completionStatus = updatedStatus.filter(keepApprovedItems).length === updatedStatus.length ? 'approved' : 'rejected';

            await completeTask(taskId, isPublic ? organizationId : member?.organizationId, completionStatus, isPublic);
            setTaskData(
                produce((newTaskData) => {
                    if (newTaskData) newTaskData.status = TaskStatusEnum.COMPLETED;
                })
            );
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'The task was successfully completed!'
                })
            );
            handleClose();
        }

        nextPublication();
        setApprovalStatus(updatedStatus);
    };

    const handleCancel = () => {
        const updatedStatus = [...approvalStatus];
        updatedStatus[activePageIndex] = 'not_reviewed';
        setApprovalStatus(updatedStatus);
    };

    React.useEffect(() => {
        if (taskId) fetchTaskData();
        updateDocumentInstance();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        updateDocumentInstance();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePageIndex]);

    React.useEffect(() => {
        const updatedComments: SectionCommentDTO[] = [];
        documentInstance?.pages.forEach((page) => {
            page.sections.forEach((section) => {
                if (section.comments) {
                    updatedComments.push(...section.comments);
                }
            });
        });
        setComments(updatedComments);
    }, [documentInstance]);

    const requiredFieldsAreFilledIn = documentInstance?.pages[activePageIndex].sections.every((section) =>
        section.fields.every((field) => {
            if (field.type === 'TICKBOX') {
                return true;
            }
            if (field.permission?.is_required && Array.isArray(field.body) && field.body.length === 0) {
                return false;
            }
            if (field.permission?.is_required && !field.body) {
                return false;
            }
            return true;
        })
    );

    return (
        <Box sx={{ height: '100%' }}>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    p: 3,
                    borderBottom: `1px solid ${theme.palette.grey[500]}`
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box>
                        <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500, fontSize: '16px', mr: 2 }}>
                            {taskData?.title}
                        </Typography>
                        <Typography sx={{ color: theme.palette.grey[600], fontFamily: 'Inter', fontWeight: 400, fontSize: '14px', mt: 1 }}>
                            {taskData?.description}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TaskStatusChip status={taskData?.status} />
                    <Box
                        sx={{
                            background: theme.palette.grey[700],
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '8px',
                            p: '8px',
                            mr: 2
                        }}
                    >
                        <Box sx={{ width: '20px' }}>
                            <CardMedia sx={{ objectFit: 'contain' }} component="img" width="18px" image={calendar} alt="alt image" />
                        </Box>
                        <Typography ml={1} sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 400, fontSize: '14px' }}>
                            Due Date -{' '}
                            {taskData?.due_date
                                ? DateTime.fromISO(taskData?.due_date).toLocaleString({
                                      weekday: 'short',
                                      month: 'short',
                                      day: '2-digit',
                                      hour: '2-digit',
                                      minute: '2-digit'
                                  })
                                : 'n/a'}
                        </Typography>
                    </Box>
                    <TaskDueDateStatus status={taskData?.due_date_status} />
                    {!isPublic && <ShareOutlinedIcon onClick={handleOpenialogShared} sx={{ mr: 2, cursor: 'pointer' }} />}
                    {!isPublic && (
                        <Box onClick={handleClose} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                            <CloseIcon sx={{ fill: theme.palette.grey[400], fontize: '22px' }} />
                            <Typography
                                sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500, fontSize: '14px', ml: 2 }}
                            >
                                <FormattedMessage id="close" />
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>
            <Box sx={{ display: 'flex', height: 'calc(100% - 97px)' }}>
                <Box sx={{ width: '340px', borderRight: `1px solid ${theme.palette.grey[500]}` }}>
                    <Box
                        sx={{
                            p: '16px 24px',
                            borderBottom: `1px solid ${theme.palette.grey[500]}`,
                            zIndex: 2
                        }}
                    >
                        <Typography
                            sx={{
                                color: theme.palette.grey[400],
                                fontFamily: 'Inter',
                                fontWeight: 500,
                                fontSize: '14px'
                            }}
                        >
                            Weekly Calendar
                        </Typography>
                    </Box>
                    <Box sx={{ ml: '22px', mr: '30px' }}>
                        {documentInstance &&
                            documentInstance?.pages?.map((page: DocumentInstanceDTO['pages'][number], index: number) => (
                                <Box
                                    // todo add onClick warning on id to use !!
                                    onClick={() => setActivePage(page.page_index)}
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        position: 'relative',
                                        borderLeft:
                                            index + 1 !== documentInstance.pages.length ? `2px solid ${theme.palette.grey[500]}` : '',
                                        pl: index + 1 !== documentInstance.pages.length ? '18px' : '20px',
                                        pt: index === 0 ? '24px' : 1,
                                        mt: index === 0 ? 0 : 2,
                                        cursor: 'pointer'
                                    }}
                                >
                                    {index !== 0 ? (
                                        <Box
                                            sx={{
                                                width: '16px',
                                                position: 'absolute',
                                                left: index + 1 !== documentInstance.pages.length ? '-3px' : '-1px',
                                                zIndex: 0,
                                                top: '-35px'
                                            }}
                                        >
                                            <CardMedia
                                                sx={{ objectFit: 'contain' }}
                                                component="img"
                                                width="18px"
                                                image={branchIcon}
                                                alt="alt image"
                                            />
                                        </Box>
                                    ) : (
                                        <Box
                                            sx={{
                                                width: '16px',
                                                position: 'absolute',
                                                left: '-2px',
                                                zIndex: 0,
                                                top: '10px'
                                            }}
                                        >
                                            <CardMedia
                                                sx={{ objectFit: 'contain' }}
                                                component="img"
                                                width="18px"
                                                image={RectangleStart}
                                                alt="alt image"
                                            />
                                        </Box>
                                    )}
                                    <Box>
                                        <Typography
                                            sx={{
                                                color:
                                                    page.page_index === activePageIndex ? theme.palette.grey[400] : theme.palette.grey[300],
                                                fontFamily: 'Inter',
                                                fontWeight: page.page_index === activePageIndex ? 700 : 500,
                                                fontSize: '14px'
                                            }}
                                        >
                                            {page.name}
                                        </Typography>
                                        {page.comments_count > 0 && (
                                            <Box
                                                sx={{
                                                    display: 'inline-block',
                                                    verticalAlign: 'middle',
                                                    borderRadius: '30px',
                                                    width: '8px',
                                                    height: '8px',
                                                    mr: 1
                                                }}
                                                bgcolor="primary.main"
                                            />
                                        )}
                                        <Typography
                                            sx={{
                                                display: 'inline',
                                                color: theme.palette.grey[300],
                                                fontFamily: 'Inter',
                                                fontWeight: 600,
                                                fontSize: '10px',
                                                letterSpacing: '0.12em',
                                                textTransform: 'uppercase',
                                                mt: 1
                                            }}
                                        >
                                            {page.comments_count > 0 ? `${page.comments_count} open comments` : `No comments`}
                                        </Typography>
                                    </Box>
                                    {approvalStatus[page.page_index] !== 'not_reviewed' && (
                                        <Box
                                            sx={{
                                                background:
                                                    approvalStatus[page.page_index] === 'approved'
                                                        ? theme.palette.success[100]
                                                        : theme.palette.error[100],
                                                borderRadius: '100px',
                                                width: '16px',
                                                height: '16px'
                                            }}
                                        >
                                            {approvalStatus[page.page_index] === 'approved' ? (
                                                <CheckOutlinedIcon sx={{ fill: '#fff', fontSize: '12px', ml: '2px' }} />
                                            ) : (
                                                <CloseIcon sx={{ fill: '#fff', fontSize: '12px', ml: '2px' }} />
                                            )}
                                        </Box>
                                    )}
                                </Box>
                            ))}
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        borderRight: `1px solid ${theme.palette.grey[500]}`,
                        position: 'relative',
                        height: '100%'
                    }}
                >
                    <Box
                        sx={{
                            p: '16px 24px',
                            borderBottom: `1px solid ${theme.palette.grey[500]}`,
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500, fontSize: '14px' }}>
                            Task Activities
                        </Typography>
                        {/* <Box>
                            <Box
                                sx={{
                                    background: theme.palette.success[100],
                                    borderRadius: '100px',
                                    width: '16px',
                                    height: '16px',
                                    display: 'inline-block',
                                    mr: 1
                                }}
                            >
                                <CheckOutlinedIcon sx={{ fill: '#fff', fontSize: '12px', ml: '2px' }} />
                            </Box>
                            <Typography sx={{ color: '#aaa', fontFamily: 'Inter', fontWeight: 500, fontSize: '14px', display: 'inline' }}>
                                {isSaving ? 'Saving...' : 'Saved'}
                            </Typography>
                        </Box> */}
                        <SavingStatus status={savingStatus} />
                    </Box>
                    <Box
                        className="scroleNone"
                        sx={{
                            height: 'calc(100% - 52px)',
                            overflow: 'scroll',
                            p: '24px 24px 100px 24px'
                        }}
                    >
                        {documentInstance?.pages[activePageIndex] && (
                            <PageContent
                                page={documentInstance?.pages[activePageIndex]}
                                updateDocumentInstance={updateDocumentInstance}
                                handleCommentUpdate={handleCommentUpdate}
                                setSavingStatus={setSavingStatus}
                                handleFieldValueUpdate={handleFieldValueUpdate}
                            />
                        )}
                    </Box>
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: '0',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            borderTop: `1px solid ${theme.palette.grey[500]}`,
                            p: '12px 24px',
                            background: '#fff'
                        }}
                    >
                        {taskId && (
                            //
                            <Box sx={{ display: 'flex', justifyContent: 'space-around', gap: 1 }}>
                                <Button
                                    sx={{ whiteSpace: 'nowrap', texAlign: 'center', px: 3, display: 'inline', width: 'auto' }}
                                    title="Approve page"
                                    onClick={() => handleApproveOrReject('approved')}
                                    variant="primary"
                                    disabled={
                                        taskData?.status !== 'ACTIVE' ||
                                        approvalStatus[activePageIndex] === 'approved' ||
                                        !requiredFieldsAreFilledIn
                                    }
                                />
                                {taskData?.subtype === 'approval_task' && (
                                    <Button
                                        sx={{ whiteSpace: 'nowrap', texAlign: 'center', px: 3, display: 'inline', width: 'auto' }}
                                        title="Reject page"
                                        onClick={() => handleApproveOrReject('rejected')}
                                        variant="primary"
                                        disabled={taskData?.status !== 'ACTIVE' || approvalStatus[activePageIndex] === 'rejected'}
                                    />
                                )}
                                <Button
                                    sx={{ whiteSpace: 'nowrap', texAlign: 'center', px: 3, display: 'inline', width: 'auto' }}
                                    title="Cancel"
                                    onClick={handleCancel}
                                    variant="primary"
                                    disabled={taskData?.status !== 'ACTIVE' || approvalStatus[activePageIndex] === 'not_reviewed'}
                                />
                            </Box>
                        )}
                        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
                            <Box
                                onClick={prevPublication}
                                sx={{
                                    width: '36px',
                                    height: '36px',
                                    borderRadius: '100px',
                                    background: theme.palette.grey[800],
                                    mr: 2,
                                    cursor: 'pointer'
                                }}
                            >
                                <ArrowBackIcon sx={{ fill: theme.palette.grey[400], fontSize: '18px', m: '9px' }} />
                            </Box>
                            <Typography
                                sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500, fontSize: '14px', mr: 2 }}
                            >
                                {documentInstance?.pages[activePageIndex]?.name}
                            </Typography>
                            <Box
                                onClick={nextPublication}
                                sx={{
                                    width: '36px',
                                    height: '36px',
                                    borderRadius: '100px',
                                    background: theme.palette.grey[800],
                                    mr: 2,
                                    cursor: 'pointer'
                                }}
                            >
                                <ArrowBackIcon
                                    sx={{ fill: theme.palette.grey[400], fontSize: '18px', m: '9px', transform: 'rotate(180deg)' }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ width: '73px', p: '16px 16px 16px 20px', position: 'relative' }}>
                    <Box onClick={handleOpenDivider} sx={{ position: 'relative', width: '24px', cursor: 'pointer' }}>
                        <Box sx={{ width: '24px' }}>
                            <CardMedia sx={{ objectFit: 'contain' }} component="img" width="24px" image={chatCircle} alt="alt image" />
                        </Box>
                        <Box
                            sx={{
                                width: '20px',
                                height: '20px',
                                position: 'absolute',
                                top: '-7px',
                                right: '-7px',
                                background: '#F98B7C',
                                borderRadius: '100px',
                                border: '3px solid #fff',
                                p: '2px'
                            }}
                        >
                            <Box sx={{ width: '12px' }}>
                                <CardMedia
                                    sx={{ objectFit: 'contain' }}
                                    component="img"
                                    width="12px"
                                    image={clockCounter}
                                    alt="alt image"
                                />
                            </Box>
                        </Box>
                    </Box>
                    {!isPublic && (
                        <Box onClick={handleOpenAIToolDrawer} sx={{ width: '23px', mt: 5, cursor: 'pointer' }}>
                            <CardMedia sx={{ objectFit: 'contain' }} component="img" image={brainIcon} alt="AI tools" />
                        </Box>
                    )}
                    {!isPublic && openAIToolDrawer && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                width: openAIToolDrawer ? '562px' : '0px',
                                height: '100%'
                            }}
                        >
                            <Box
                                sx={{
                                    background: theme.palette.background.default,
                                    height: '100%',
                                    width: { xs: '100%', sm: '562px' },
                                    borderLeft: `1px solid ${theme.palette.grey[500]}`
                                }}
                            >
                                <AIToolsDrawerContent handleClose={handleCloseAIToolDrawer} />
                            </Box>
                        </Box>
                    )}
                    {openDivider && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                width: openDivider ? '453px' : '0px',
                                height: '100%'
                            }}
                        >
                            <CommentsDrawer
                                handleClickCloseDivider={handleClickCloseDivider}
                                updateDocumentInstance={updateDocumentInstance}
                                comments={comments}
                                handleCommentUpdate={handleCommentUpdate}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
            <DIalogSharedLink
                open={dialogShared}
                handleClose={handleCloseialogShared}
                linkShared={`public/task/${member?.organizationId}/${taskId}`}
                handleShare={shareTask}
            />
        </Box>
    );
}
