import { Theme } from '@mui/material/styles';
import { TaskStatusEnum } from 'features/workflow-task-execution/types';
import StatusChip from './StatusChip';

interface TaskStatusChipProps {
    status: TaskStatusEnum | undefined;
}

const TaskStatusMapping = (
    theme: Theme
): { [key in TaskStatusEnum | 'default']: { backgroundColor: string; textColor: string; text: string } } => ({
    [TaskStatusEnum.CLOSED]: {
        backgroundColor: theme.palette.grey[500],
        textColor: theme.palette.grey[100],
        text: 'Closed'
    },
    [TaskStatusEnum.COMPLETED]: {
        backgroundColor: theme.palette.grey[500],
        textColor: theme.palette.grey[100],
        text: 'Completed'
    },
    [TaskStatusEnum.ACTIVE]: {
        backgroundColor: theme.palette.success[400],
        textColor: theme.palette.primary.light,
        text: 'Active'
    },
    default: {
        backgroundColor: theme.palette.grey[500],
        textColor: theme.palette.grey[100],
        text: 'No status'
    }
});

export default function TaskStatusChip({ status }: TaskStatusChipProps) {
    return <StatusChip status={status} mapping={TaskStatusMapping} />;
}
