import { Typography, Box, Grid, TextField, Avatar, Select, MenuItem } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import ButtonCustom from 'ui-component/extended/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { MEMBER_ROLES } from 'utils/constants';
import { useMutation } from '@apollo/client';
import { UPDATE_MEMBER_BY_PK } from 'services/graphQL/mutations/team/teamMutations';

const DialogViewTeamMember = ({ onClose, item }: any) => {
    const theme = useTheme();
    const intl = useIntl();

    const [updateMember] = useMutation(UPDATE_MEMBER_BY_PK);

    // const [updateMember] = useMutation(UPDATE_MEMBER_BY_PK, {
    //     onCompleted: (data: any) => {
    //         console.log('completed');
    //         // onClose();
    //         // fetchOffers();
    //     },
    //     onError: (error: any) => {
    //         console.log(error);
    //     }
    // });

    const formik = useFormik({
        initialValues: {
            role: '',
            title: ''
        },
        validationSchema: Yup.object({
            role: Yup.string().required('Required'),
            title: Yup.string()
        }),
        onSubmit: async (values) => {
            const response = await updateMember({
                variables: {
                    id: item.id,
                    title: values.title,
                    roleId: values.role
                }
            });
            onClose();
        }
    });
    React.useEffect(() => {
        if (item) {
            formik.setFieldValue('role', item?.role_id);
            formik.setFieldValue('title', item?.title);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);
    return (
        <Box sx={{ p: '5px 20px', borderRadius: '16px', width: { xs: '100%', sm: '562px' } }}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={9}>
                    <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500, fontSize: '16px' }}>
                        <FormattedMessage id="team_member_one" />
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {' '}
                        <CloseIcon onClick={onClose} sx={{ fontSize: '20px', fill: '#9197AE', cursor: 'pointer' }} />
                    </Box>
                </Grid>
            </Grid>
            <Grid
                mt={5}
                pb={2}
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{ borderBottom: `1px solid ${theme.palette.grey[500]}` }}
            >
                <Grid item xs={7} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ mr: '2px' }}>
                        <Avatar sx={{ width: '48px', height: '48px' }} alt="Remy Sharp" src={item.user.image_url} />
                    </Box>
                    <Box>
                        <Typography ml={1} sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500 }}>
                            {`${item.user.first_name} ${item.user.last_name ?? ''}`}
                        </Typography>
                        <Typography ml={1} sx={{ color: theme.palette.grey[600], fontFamily: 'Inter', fontWeight: 500, fontSize: '12px' }}>
                            {item.user.email}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Typography
                        ml={1}
                        sx={{
                            color: theme.palette.orange.main,
                            fontFamily: 'Inter',
                            fontWeight: 500,
                            fontSize: '14px',
                            textAlign: 'right'
                        }}
                    >
                        <FormattedMessage id={item.type} />
                    </Typography>
                </Grid>
            </Grid>
            <form onSubmit={formik.handleSubmit}>
                <Box mt={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{ mb: '4px', fontWeight: 500, color: theme.palette.grey[900] }}>
                                <FormattedMessage id="role" />
                            </Typography>
                            <Select
                                id="role"
                                name="role"
                                fullWidth
                                displayEmpty
                                value={formik.values.role}
                                onChange={formik.handleChange}
                                placeholder={intl.formatMessage({ id: 'role' })}
                                renderValue={(selected) => {
                                    const name = MEMBER_ROLES.find((role) => role.id === selected)?.name ?? selected;
                                    return <Typography sx={{ color: theme.palette.grey[300] }}>{name}</Typography>;
                                }}
                            >
                                {MEMBER_ROLES.map((role) => (
                                    <MenuItem
                                        key={role.id}
                                        value={role.id}
                                        sx={{
                                            borderBottom: `1px solid ${theme.palette.grey[500]}`,
                                            p: '14px 16px'
                                        }}
                                    >
                                        {role.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{ mb: '4px', fontWeight: 500, color: theme.palette.grey[900] }}>
                                <FormattedMessage id="title" />
                            </Typography>
                            <TextField
                                sx={{
                                    '.MuiOutlinedInput-input': {
                                        fontWeight: 400,
                                        fontFamily: 'Inter',
                                        fontSize: '14px'
                                    }
                                }}
                                id="title"
                                name="title"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                variant="outlined"
                                fullWidth
                                error={formik.touched.title && Boolean(formik.errors.title)}
                                helperText={formik.touched.title && formik.errors.title}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box mt={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box sx={{ width: '145px' }}>
                        <ButtonCustom onClick={formik.handleSubmit} colorBtn="red" titleBtn={<FormattedMessage id="save_changes" />} />
                    </Box>
                </Box>
            </form>
        </Box>
    );
};

export default DialogViewTeamMember;
