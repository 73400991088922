import _ from 'lodash';
import { WFApprovalTask, WFTask } from '@sni4/snikpic-common';
import { CreateNodeParams, ReactFlowApprovalTaskNode, ReactFlowTaskNode } from 'features/workflow/types/react-flow.types';
import { getId } from 'features/workflow/utils/react-flow.utils';
import { TaskNodeHelper } from '../Task/TaskNodeHelper';

export class ApprovalTaskNodeHelper {
    static createNode({ position }: CreateNodeParams): ReactFlowApprovalTaskNode {
        const taskNode = TaskNodeHelper.createNode({ position });
        const approvalTaskNode: ReactFlowApprovalTaskNode = {
            ...taskNode,
            type: 'approval_task',
            data: {
                ...taskNode.data,
                conditions: []
            }
        };

        return approvalTaskNode;
    }

    static convertToWorkflowNode(node: ReactFlowApprovalTaskNode): WFApprovalTask {
        const taskNode = TaskNodeHelper.convertToWorkflowNode(node);
        const approvalTaskNode: WFApprovalTask = {
            ...taskNode,
            type: 'approval_task',
            properties: {
                ...taskNode.properties,
                // TODO: fix this type
                conditions: node.data.conditions as any
            }
        };

        return approvalTaskNode;
    }

    static convertToReactFlowNode(node: WFApprovalTask): ReactFlowApprovalTaskNode {
        return ApprovalTaskNodeHelper.validateNode({
            id: node.properties.id,
            type: node.type,
            position: node.properties.position,
            data: {
                assignee: node.properties.assignee,
                name: node.properties.name,
                role: node.properties.role,
                description: node.properties.description,
                duration: node.properties.duration,
                document_permissions: node.properties.document_permissions,
                conditions: node.properties.conditions,
                is_valid: false
            }
        });
    }

    static validateNode(node: ReactFlowApprovalTaskNode): ReactFlowApprovalTaskNode {
        const validatedNode = TaskNodeHelper.validateNode(node);

        return {
            ...validatedNode,
            data: {
                ...validatedNode.data,
                conditions: node.data.conditions,
                is_valid: validatedNode.data.is_valid && !_.isEmpty(node.data.conditions)
            }
        };
    }
}
