import { ProjectMemberType, ProjectType } from 'types/clients';

export const formatProject = (fetchedData: any[]) => {
    const formattedData: ProjectType[] = fetchedData.map((data) => {
        const projectUsers: ProjectMemberType[] = data.project_users.map((user: any) => ({
            id: user.id,
            projectId: user.project_id,
            orgMemberId: user.organization_members_id,
            icon: user.organization_member.user.image_url,
            firstName: user.organization_member.user.first_name
        }));

        return {
            id: data.id,
            name: data.name,
            offerId: data.offer_id,
            description: data.offer.description,
            clients: [],
            src: data.offer.image_url,
            status: data.status,
            date: data.created_at,
            teamMembers: projectUsers
        };
    });

    return formattedData;
};
