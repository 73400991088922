import { getId as defaultGetId } from 'features/workflow/utils/react-flow.utils';
import { Node } from 'react-flow-renderer';

interface IDuplicateNode {
    getId?: () => string;
    setNodes?: (nodes: Node[] | ((nodes: Node[]) => Node[])) => void;
    type: string;
    yPos: number;
    xPos: number;
}

const DuplicateNode = ({ getId = defaultGetId, setNodes, type, yPos, xPos }: IDuplicateNode) => {
    const newNode = {
        id: getId(),
        type,
        position: { x: xPos, y: yPos + 200 },
        data: {
            label: `${type} node`,
            getId
        }
    };

    // @ts-ignore
    setNodes((nds) => nds.concat(newNode));
};

export default DuplicateNode;
