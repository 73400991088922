import { MouseEventHandler, useCallback } from 'react';
import { Avatar, useChannelActionContext, useChannelStateContext, useChatContext } from 'stream-chat-react';

import SidebarImage from '../../assets/newImages/sidebar/SidebarImage.png';
import { ChannelInfoIcon } from './ChannelInfoIcon';
import { useWorkspaceController } from '../../context/WorkspaceController';
import { SearchIcon } from '../../assets';
import type { StreamChatType } from '../../types';
import bell from '../../assets/newImages/top/bell.svg';
import calendar from '../../assets/newImages/top/calendar.svg';
import Vector from '../../assets/newImages/top/Vector.svg';
import video from '../../assets/newImages/top/video.svg';
// import SidebarImage from '../../assets/newImages/sidebar/SidebarImage.png';
import ChatPreviewPlaceholderImage from '../../assets/newImages/sidebar/ChatPreviewPlaceholderImage.svg';

import { Tooltip } from '@mui/material';

export const TeamChannelHeader = () => {
    const { displayWorkspace } = useWorkspaceController();
    const { client } = useChatContext<StreamChatType>();
    const { channel, watcher_count } = useChannelStateContext<StreamChatType>();
    const { closeThread } = useChannelActionContext<StreamChatType>();
    const { togglePinnedMessageListOpen } = useWorkspaceController();

    const teamHeader = `${channel?.data?.name || channel?.data?.id || 'random'}`;

    const openChannelEditPanel = useCallback(() => {
        displayWorkspace('Admin-Channel-Edit');
    }, [displayWorkspace]);

    const onPinIconClick: MouseEventHandler = useCallback(
        (event) => {
            closeThread?.(event);
            togglePinnedMessageListOpen();
        },
        [closeThread, togglePinnedMessageListOpen]
    );

    const getMessagingHeader = (displayImages: boolean) => {
        const members = Object.values(channel.state.members).filter(({ user }) => user?.id !== client.userID);
        const additionalMembers = members.length - 3;

        if (!members.length) {
            return (
                <div className="workspace-header__block">
                    <Avatar image={null} size={32} />
                    <p className="team-channel-header__name user">Empty channel</p>
                </div>
            );
        }
        // console log all the user images found
        // console.log(members);
        return displayImages ? (
            <div className="workspace-header__right_images">
                {members.slice(0, 3).map((member, i) => (
                    <div key={i} className="workspace-header__right_images_image">
                        <Avatar image={member?.user?.image} size={32} />
                    </div>
                ))}
            </div>
        ) : (
            <div className="workspace-header__block">
                {members.map(({ user }, i) => {
                    if (i > 2) return null;
                    return (
                        <div key={i} className="workspace-header__block-item">
                            <Avatar image={user?.image} name={user?.name || user?.id} size={32} />
                            <p className="team-channel-header__name user">{user?.name || user?.id || '/'}</p>
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderChannelMembersList = () => {
        const members = Object.values(channel.state.members).filter(({ user }) => user?.id !== client.userID);
        const memberNames = members.map((member) => member.user?.name || member.user?.id || '/');
        return memberNames.join(', ');
    };

    const getWatcherText = (watchers?: number) => {
        if (!watchers) return 'No users online';
        if (watchers === 1) return '1 user online';
        return `${watchers} users online`;
    };

    return (
        <div className="team-channel-header__container">
            {channel.type === 'messaging' ? (
                getMessagingHeader(true)
            ) : (
                <div className="workspace-header__block">
                    <div className="team-channel-header__name workspace-header__title">
                        {/* <img src={SidebarImage} alt="image" className="workspace-header__title_image" /> */}
                        <img
                            src={(channel?.data?.imageUrl as string) || ChatPreviewPlaceholderImage}
                            alt="channel_image"
                            className="workspace-header__title_image"
                        />
                        {teamHeader}
                    </div>
                    <button type="button" onClick={openChannelEditPanel}>
                        <ChannelInfoIcon />
                    </button>
                </div>
            )}
            <div className="workspace-header__block">
                {/* <div className='workspace-header__subtitle'>{getWatcherText(watcher_count)}</div>
        <button
          className='workspace-header__subtitle'
          onClick={onPinIconClick}
        >
          <PinIcon />
          Pins
        </button> */}
                <div className="workspace-header__right">
                    {/* <div className="workspace-header__right_icon">
                        <SearchIcon />
                    </div> */}
                    {/* <input className="workspace-header__right_input" placeholder="Search Chat" /> */}
                    <div className="workspace-header__right_icons">
                        {/* <img src={bell} alt="bell"/> */}
                        {/* <img src={calendar} alt="calendar" /> */}
                        {/* <img src={Vector} alt="Vector" /> */}
                        {/* <img src={video} alt="video" /> */}
                    </div>
                    <Tooltip title={renderChannelMembersList()}>{getMessagingHeader(true)}</Tooltip>
                </div>
            </div>
        </div>
    );
};
