import { Button } from '@mui/material';
import { ReactComponent as PhRowIcon } from 'assets/images/ph_rows.svg';
import { useDrag } from 'react-dnd';
import { ItemTypes, Section } from 'views/pages/clients/features/detail/workflow/features/types';

interface SavedSectionItemProps {
    name?: string;
    sectionData?: Section;
    type?: string;
}

const SavedSectionItem = ({
    name = 'Untitled section',
    sectionData = {
        sectionId: '',
        secondCols: [],
        sectionChecked: false,
        sectionClicked: false,
        sectionFields: [],
        sectionName: 'Test template section',
        sectionVariable: false,
        sectionNumber: 0
    },
    type = ItemTypes.SAVED_SECTION
}: SavedSectionItemProps) => {
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: ItemTypes.SAVED_SECTION,
            item: { section: sectionData, originalIndex: -1 },
            collect: (monitor) => ({
                isDragging: monitor.isDragging()
            })
        }),
        []
    );

    return (
        <Button
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '8px',
                backgroundColor: '#EFF2FA',
                gap: '12px',
                height: '136px',
                maxWidth: '144px',
                padding: '8px',
                fontSize: '12px'
            }}
            ref={drag}
        >
            <PhRowIcon />
            {name}
        </Button>
    );
};

export default SavedSectionItem;
