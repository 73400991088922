import { useState } from 'react';
import { Typography, Box, Divider, Popover, Drawer } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import LaunchIcon from '@mui/icons-material/Launch';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import DIalogConfirmDelete from './DIalogConfirmDelete';
import PackageDetailsContainer from '../../feature/PackageDetailsContainer';
import DIalogCreatePackage from './DIalogCreatePackage';

const Packages = ({ packageContainer }: any) => {
    const theme = useTheme();
    const [openPackageDetailsDrawer, setOpenPackageDetailsDrawer] = useState(false);
    const [editPackage, setEditPackage] = useState(false);
    const [editPackageValue, setEditPackageValue] = useState(false);
    const [dialogConfirmDelete, setDialogConfirmDelete] = useState(false);
    const [selectedPackege, setSelectedPackege] = useState<any>();
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const id = anchorEl ? 'simple-popover' : undefined;
    const openPopover = (event: any) => setAnchorEl(event.currentTarget);
    const closePopover = () => setAnchorEl(null);
    const closeOpenPackageDetailsDrawer = () => setOpenPackageDetailsDrawer(false);

    const openOpenPackageDetailsDrawer = (item: any) => {
        setOpenPackageDetailsDrawer(true);
        setSelectedPackege(item);
    };
    const handleClickEdit = (item: any) => {
        setEditPackage(true);
        openOpenPackageDetailsDrawer(item);
        closePopover();
    };
    const handleOpenDialogConfirmDelete = () => {
        setDialogConfirmDelete(true);
        closePopover();
    };
    const handleCloseDialogConfirmDelete = () => setDialogConfirmDelete(false);
    return (
        <Box sx={{ border: `1px solid ${theme.palette.grey[500]}`, p: '20px', borderRadius: '8px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                    sx={{
                        color: '#2D2D2D',
                        fontFamily: 'Inter',
                        fontWeight: 600,
                        fontSize: '16px',
                        mb: 1
                    }}
                >
                    {packageContainer.skil}
                </Typography>
                <Box sx={{ width: '40px' }}>
                    <MoreVertOutlinedIcon onClick={openPopover} sx={{ cursor: 'pointer' }} />
                    <Popover
                        id={id}
                        open={!!anchorEl}
                        anchorEl={anchorEl}
                        onClose={closePopover}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                    >
                        <Box sx={{ p: '24px' }}>
                            <Box
                                onClick={() => handleClickEdit(packageContainer)}
                                sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
                            >
                                <Typography
                                    sx={{
                                        color: theme.palette.grey[400],
                                        fontFamily: 'Inter',
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        mb: '10px'
                                    }}
                                >
                                    <FormattedMessage id="edit" />
                                </Typography>
                            </Box>
                            <Box
                                onClick={handleOpenDialogConfirmDelete}
                                mt={1}
                                sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
                            >
                                <Typography
                                    sx={{
                                        color: theme.palette.grey[400],
                                        fontFamily: 'Inter',
                                        fontWeight: 500,
                                        fontSize: '14px'
                                    }}
                                >
                                    <FormattedMessage id="delete" />
                                </Typography>
                            </Box>
                        </Box>
                    </Popover>
                </Box>
            </Box>

            {packageContainer.jobs.map((itemJob: any, indexjob: number) => (
                <Box key={indexjob} sx={{ display: 'flex', alignItem: 'center', mt: 2 }}>
                    <FiberManualRecordIcon sx={{ fill: theme.palette.grey[600], fontSize: '10px', mr: 1 }} />
                    <Typography
                        sx={{
                            color: theme.palette.grey[400],
                            fontFamily: 'Inter',
                            fontWeight: 500,
                            fontSize: '13px',
                            mt: '-3px'
                        }}
                    >
                        {`${itemJob.volume} ${itemJob.title}`}
                    </Typography>
                </Box>
            ))}
            <Divider sx={{ mt: 3, mb: 3 }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                    sx={{
                        color: theme.palette.grey[400],
                        fontFamily: 'Inter',
                        fontWeight: 600,
                        fontSize: '15px'
                    }}
                >
                    {packageContainer.price}
                </Typography>
                <Box onClick={() => handleClickEdit(packageContainer)} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <LaunchIcon sx={{ fill: theme.palette.orange.main, mr: 1, fontSize: '16px' }} />
                    <Typography
                        sx={{
                            color: theme.palette.orange.main,
                            fontFamily: 'Inter',
                            fontWeight: 600,
                            fontSize: '14px'
                        }}
                    >
                        <FormattedMessage id="view" />
                    </Typography>
                </Box>
            </Box>
            <Drawer
                anchor="right"
                open={openPackageDetailsDrawer}
                onClose={closeOpenPackageDetailsDrawer}
                sx={{
                    '.MuiPaper-root': {
                        maxWidth: '562px',
                        diplay: 'flex',
                        height: '100vh',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }
                }}
            >
                {editPackageValue ? (
                    <DIalogCreatePackage onClose={() => setEditPackageValue(false)} />
                ) : (
                    <PackageDetailsContainer
                        selectedPackege={selectedPackege}
                        editPackage={editPackage}
                        setEditPackageValue={setEditPackageValue}
                    />
                )}
            </Drawer>
            <DIalogConfirmDelete
                onClose={handleCloseDialogConfirmDelete}
                open={dialogConfirmDelete}
                title="are_you_sure_you_want_to_delete_the_package"
            />
        </Box>
    );
};

export default Packages;
