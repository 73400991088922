import { Box, Typography } from '@mui/material';
import React, { FC, useState, useContext } from 'react';
import { Handle, NodeProps, Position } from 'react-flow-renderer';
import { FlowNode } from 'views/pages/clients/features/detail/workflow/features/document-builder/ui';
import ButtonDeleteNode from '../../components/buttonDeleteNode';
import DialogConfirmDelete from '../../sideBar/DialogConfirmDelete';
import TriggerItem from './trigger-item';
import DocumentBuilderContext from '../../../document-builder/context/workflow.context';
import { DeleteTrigger } from '../../diagram/hooks/delete-trigger';

const StartNode: FC<NodeProps> = ({ data, id }) => {
    const { documentBuilder } = useContext(DocumentBuilderContext);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <FlowNode sx={{ border: '1px dashed #AAAAAA', position: 'relative' }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 600, padding: '0 8px' }}>
                Trigger
            </Typography>
            <TriggerItem type={data.type} />
            <Handle
                type="source"
                position={Position.Bottom}
                isConnectable
                style={{ width: '9px', height: '9px', opacity: 1, background: '#98A2B3' }}
            />
            <Box sx={{ position: 'absolute', top: '-40px', right: 0 }}>
                <ButtonDeleteNode className="delbutton" onClick={handleOpen} />
            </Box>
            <DialogConfirmDelete open={open} handleClose={handleClose} onClick={DeleteTrigger(documentBuilder.workflow, id)} />
        </FlowNode>
    );
};

export default StartNode;
