import { Typography, Box, Grid, TextField } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Button } from 'ui-component/extended/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'store';
import { useMutation } from '@apollo/client';
import { EDIT_CLIENT_RELEVANT_LINKS_BY_PK, INSERT_CLIENT_RELEVANT_LINKS_ONE } from 'api/graphql/mutations';
import { setCurrentClient } from 'store/slices/clients';
import { openSnackbar } from 'store/slices/snackbar';

interface LinkEditDialogProps {
    onClose: () => void;
    item?: any;
}

const LinkEditDialog = ({ onClose, item }: LinkEditDialogProps) => {
    const dispatch = useDispatch();
    const { currentClient } = useSelector((state) => state.clients);
    const type = item ? 'EDIT' : 'ADD';

    const [insertNewClientLinks, { loading: creating }] = useMutation(INSERT_CLIENT_RELEVANT_LINKS_ONE, {
        onCompleted: (data: any) => {
            const newLink = data.insert_client_relevant_links_one;
            const links = [...(currentClient.links ?? []), { id: newLink.id, link: newLink.url, name: newLink.title, src: newLink.icon }];
            const updatedCurrentClient = { ...currentClient, links };
            dispatch(setCurrentClient(updatedCurrentClient));
            onClose();
        },
        onError: (error: any) => {
            console.log(error);
        }
    });

    const [editClientLink, { loading: saving }] = useMutation(EDIT_CLIENT_RELEVANT_LINKS_BY_PK, {
        onCompleted: ({ update_client_relevant_links_by_pk: data }: any) => {
            if (currentClient && currentClient.links) {
                const links = [...currentClient.links];
                for (let i = 0; i < links.length; i++) {
                    if (links[i].id === data.id) {
                        links[i] = {
                            id: data.id,
                            name: data.title,
                            link: data.url,
                            src: data?.icon
                        };
                    }
                }
                dispatch(setCurrentClient({ ...currentClient, links }));
                onClose();
            }
        },
        onError: (e: any) => {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e.message,
                    variant: 'error'
                })
            );
        }
    });

    const theme = useTheme();
    const intl = useIntl();
    const formik = useFormik({
        initialValues: {
            linkName: '',
            linkUrl: ''
        },
        validationSchema: Yup.object({
            linkName: Yup.string().required('Required'),
            linkUrl: Yup.string().required('Required')
        }),
        onSubmit: (values) => {
            if (type === 'ADD') {
                insertNewClientLinks({
                    variables: {
                        agency_id: currentClient.agencyId,
                        client_id: currentClient.id,
                        title: values.linkName,
                        url: values.linkUrl
                    }
                });
            } else if (type === 'EDIT') {
                editClientLink({
                    variables: {
                        id: item.id,
                        title: values.linkName,
                        url: values.linkUrl
                    }
                });
            }
        }
    });

    React.useEffect(() => {
        if (item) {
            formik.setFieldValue('linkName', item?.name);
            formik.setFieldValue('linkUrl', item?.link);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    return (
        <Box sx={{ p: '5px 20px', borderRadius: '16px', width: { xs: '100%', sm: '562px' } }}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={6}>
                    <Typography ml={1} sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500, fontSize: '16px' }}>
                        {item ? <FormattedMessage id="edit_link" /> : <FormattedMessage id="add_link" />}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {' '}
                        <CloseIcon onClick={onClose} sx={{ fontSize: '20px', fill: '#9197AE', cursor: 'pointer' }} />
                    </Box>
                </Grid>
            </Grid>
            <form onSubmit={formik.handleSubmit}>
                <Box mt={3}>
                    <TextField
                        sx={{
                            '.MuiOutlinedInput-input': {
                                fontWeight: 400,
                                fontFamily: 'Inter',
                                fontSize: '14px'
                            }
                        }}
                        id="linkName"
                        name="linkName"
                        value={formik.values.linkName}
                        onChange={formik.handleChange}
                        variant="outlined"
                        fullWidth
                        placeholder={intl.formatMessage({ id: 'link_title' })}
                        error={formik.touched.linkName && Boolean(formik.errors.linkName)}
                        helperText={formik.touched.linkName && formik.errors.linkName}
                    />
                </Box>
                <Box mt={3}>
                    <TextField
                        sx={{
                            '.MuiOutlinedInput-input': {
                                fontWeight: 400,
                                fontFamily: 'Inter',
                                fontSize: '14px'
                            }
                        }}
                        id="linkUrl"
                        name="linkUrl"
                        value={formik.values.linkUrl}
                        onChange={formik.handleChange}
                        variant="outlined"
                        fullWidth
                        placeholder={intl.formatMessage({ id: 'link_url' })}
                        error={formik.touched.linkUrl && Boolean(formik.errors.linkUrl)}
                        helperText={formik.touched.linkUrl && formik.errors.linkUrl}
                    />
                </Box>
                <Box mt={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box sx={{ width: item ? '155px' : '115px' }}>
                        <Button
                            onClick={formik.handleSubmit}
                            title={item ? <FormattedMessage id="save_changes" /> : <FormattedMessage id="add_link" />}
                            loading={(type === 'ADD' && creating) || (type === 'EDIT' && saving)}
                        />
                    </Box>
                </Box>
            </form>
        </Box>
    );
};

export default LinkEditDialog;
