import { Typography, Box, Dialog, CardMedia } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import logoFacebookSnickpik from 'assets/images/logoasdasd.png';
import { useTheme } from '@mui/material/styles';
import { Button } from 'ui-component/extended/Button';

interface IntegrationDialogProps {
    openDialogIntegrate: boolean;
    handleCloseDialog: any;
}

const IntegrationDialogSuccess = ({ openDialogIntegrate, handleCloseDialog }: IntegrationDialogProps) => {
    const theme = useTheme();

    return (
        <Dialog open={openDialogIntegrate} onClose={handleCloseDialog}>
            <Box sx={{ p: '32px', borderRadius: '16px', width: { xs: '100%', sm: '562px' }, position: 'relative' }}>
                <Box sx={{ width: '114px', m: '0 auto' }}>
                    <CardMedia sx={{ objectFit: 'contain' }} component="img" width="18px" image={logoFacebookSnickpik} alt="alt image" />
                </Box>
                <Typography
                    mt={2}
                    sx={{
                        fontWeight: 600,
                        fontFamily: 'Inter',
                        color: theme.palette.grey[400],
                        fontSize: '16px',
                        textAlign: 'center'
                    }}
                >
                    <FormattedMessage id="connected_successful" />
                </Typography>
                <Box sx={{ maxWidth: '77px', m: '20px auto 0' }}>
                    <Button onClick={handleCloseDialog} variant="primary" title={<FormattedMessage id="got_it" />} />
                </Box>
            </Box>
        </Dialog>
    );
};

export default IntegrationDialogSuccess;
