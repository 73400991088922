import { gql } from '@apollo/client';

const GET_CLIENTS = gql`
    query GetClients($organizationId: uuid!) {
        client_organizations(where: { agency_id: { _eq: $organizationId }, status: { _eq: ACTIVE } }) {
            agency_id
            id
            name
            logo_url
            created_at
            ai_brand_description
            members {
                id
                organization_id
                status
                user_id
                user {
                    first_name
                    email
                    image_url
                    last_name
                    phone
                }
            }
            client_relevant_links {
                agency_id
                client_id
                created_at
                icon
                id
                title
                updated_at
                url
            }
        }
    }
`;

const GET_SPECIFIC_CLIENT = gql`
    query GetSpecificClient($clientOrganizationId: uuid!) {
        client_organizations_by_pk(id: $clientOrganizationId) {
            agency_id
            id
            logo_url
            name
            description
            status
            members {
                id
                organization_id
                role_id
                status
                user_id
                user {
                    first_name
                    agency_id
                    id
                    image_url
                    last_name
                    phone
                    email
                }
            }
            client_relevant_links {
                title
                url
                id
                icon
            }
        }
    }
`;

// eslint-disable-next-line import/prefer-default-export
export { GET_CLIENTS, GET_SPECIFIC_CLIENT };
