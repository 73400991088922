import { FIELD_TYPE, SnikpicDocument, SnikpicPage, SnikpicSection } from 'views/pages/clients/features/detail/workflow/features/types';
import { v4 as uuid } from 'uuid';

const toWorkflowDocumentFieldType = (type: FIELD_TYPE) => {
    switch (type) {
        case FIELD_TYPE.TEXT:
            return 'TEXT';
        case FIELD_TYPE.TEXTAREA:
            return 'TEXT_AREA';
        case FIELD_TYPE.DROPDOWN:
            return 'DROPDOWN';
        case FIELD_TYPE.TICKBOX:
            return 'TICKBOX';
        case FIELD_TYPE.FILE_UPLOADER:
            return 'FILE_UPLOADER';
        case FIELD_TYPE.TABLE:
            return 'TABLE';
        case FIELD_TYPE.LOCATION:
            return 'LOCATION';
        case FIELD_TYPE.DATE:
            return 'DATE';
        case FIELD_TYPE.LIST:
            return 'LIST';
        default:
            return 'TEXT';
    }
};

const extractFieldConfiguration = (type: FIELD_TYPE, field: any) => {
    switch (type) {
        case FIELD_TYPE.DROPDOWN:
            return {
                options: field.items
            };
        case FIELD_TYPE.TICKBOX:
            return {
                question: field.name
            };
        case FIELD_TYPE.TABLE:
            return {
                maximum_items: parseInt(field.value as string, 10),
                columns: field.items.map((item: string, index: number) => ({
                    index,
                    name: item,
                    label: item.replace(/\s+/g, '_').toLowerCase()
                }))
            };
        default:
            return undefined;
    }
};

export const toPagePart = (page: any, index: number): any => {
    const sections = page.sections.map(toSectionPart);

    return {
        id: page.id ?? uuid(),
        name: page.name,
        index,
        is_saved: false,
        is_variable: page.variable,
        sections,
        number: page.number
    };
};

export const toSectionPart = (section: any, index: number): any => {
    const fields = section.fields.map((field: any) => ({
        id: field.id ?? uuid(),
        title: field.name,
        type: toWorkflowDocumentFieldType(field.type),
        row_index: field.rowIndex ?? 0,
        column_index: field.columnIndex ?? 0,
        configuration: extractFieldConfiguration(field.type, field)
    }));

    return {
        id: section.id ?? uuid(),
        name: section.name,
        index,
        is_variable: section.variable,
        is_saved: false,
        fields,
        number: section.number
    };
};

export const toWorkflowDocumentInput = (document: SnikpicDocument): any => {
    const pages = document.pages.map(toPagePart);
    return {
        name: document.name,
        pages
    };
};

export const toSnikpicDocument = (documentBuilder: any): SnikpicDocument => ({
    name: documentBuilder.workflowName,
    pages: documentBuilder.pages.map(toSnikpicPage)
});

export const toSnikpicPage = (documentBuilderPage: any): SnikpicPage => ({
    id: documentBuilderPage.pageId ?? uuid(),
    name: documentBuilderPage.pageName,
    variable: documentBuilderPage.pageVariable,
    sections: documentBuilderPage.pageSections.map(toSnikpicSection),
    number: documentBuilderPage.pageNumber
});

export const toSnikpicSection = (documentBuilderSection: any): SnikpicSection => ({
    fields: documentBuilderSection.sectionFields.map((field: any) => ({
        id: field.fieldId,
        type: field.fieldType,
        value: field.fieldValue,
        name: field.fieldName,
        items: field.fieldItems,
        columnIndex: field.fieldColumnIndex,
        rowIndex: field.fieldRowIndex
    })),
    id: documentBuilderSection.sectionId ?? uuid(),
    name: documentBuilderSection.sectionName,
    variable: documentBuilderSection.sectionVariable,
    number: documentBuilderSection.sectionNumber
});
