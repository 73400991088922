import { SyntheticEvent } from 'react';

// material-ui
import { Fade, Grow, Slide, SlideProps, Box, Typography, Alert, IconButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import MuiSnackbar from '@mui/material/Snackbar';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { FormattedMessage } from 'react-intl';

import { KeyedObject } from 'types';
import { useDispatch, useSelector } from 'store';
import { closeSnackbar } from 'store/slices/snackbar';

// animation function
function TransitionSlideLeft(props: SlideProps) {
    return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props: SlideProps) {
    return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props: SlideProps) {
    return <Slide {...props} direction="down" />;
}

function GrowTransition(props: SlideProps) {
    return <Grow {...props} />;
}

// animation options
const animation: KeyedObject = {
    SlideLeft: TransitionSlideLeft,
    SlideUp: TransitionSlideUp,
    SlideRight: TransitionSlideRight,
    SlideDown: TransitionSlideDown,
    Grow: GrowTransition,
    Fade
};

// ==============================|| SNACKBAR ||============================== //

const Snackbar = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const snackbar = useSelector((state) => state.snackbar);
    const { anchorOrigin, message, open, transition, variant, icon } = snackbar;
    const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(closeSnackbar());
    };

    return (
        <>
            {/* default snackbar */}
            {variant === 'default' && (
                <MuiSnackbar
                    sx={{
                        '.MuiPaper-root': {
                            background: theme.palette.primary[100],
                            minWidth: '220px',
                            boxShadow: 'none',
                            borderRadius: '8px',
                            p: '16px 24px'
                        }
                    }}
                    anchorOrigin={anchorOrigin}
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    message={
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <CheckIcon sx={{ fill: theme.palette.primary[600], mr: '10px' }} />
                            <Typography sx={{ fontFamily: 'Inter', fontWeight: 500, fontSize: '14px', color: theme.palette.primary[600] }}>
                                <FormattedMessage id={message} />
                            </Typography>
                        </Box>
                    }
                    TransitionComponent={animation[transition]}
                />
            )}

            {/* alert snackbar */}
            {variant === 'alert' && (
                <MuiSnackbar
                    TransitionComponent={animation[transition]}
                    anchorOrigin={anchorOrigin}
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    sx={{
                        '.MuiPaper-root': {
                            background: theme.palette.primary[500],
                            minWidth: '120px',
                            boxShadow: 'none',
                            borderRadius: '8px',
                            p: '8px 24px'
                        }
                    }}
                    message={
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ width: '24px', mr: 1 }}>{icon}</Box>
                            <Typography
                                sx={{ fontFamily: 'Inter', fontWeight: 500, fontSize: '14px', color: theme.palette.secondary[400] }}
                            >
                                <FormattedMessage id={message} />
                            </Typography>
                        </Box>
                    }
                />
            )}

            {variant === 'error' && (
                <MuiSnackbar
                    TransitionComponent={animation[transition]}
                    anchorOrigin={anchorOrigin}
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <Alert
                        variant="filled"
                        color="error"
                        severity="error"
                        action={
                            <IconButton sx={{ color: 'background.paper' }} size="small" aria-label="close" onClick={handleClose}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        }
                    >
                        {message}
                    </Alert>
                </MuiSnackbar>
            )}
        </>
    );
};

export default Snackbar;
