import { useState } from 'react';
import { Box } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AuthTitle, AuthPassword, AuthLogo, AuthInput } from '../components';
import { Button } from 'ui-component/extended/Button';
import useAuth from 'hooks/useAuth';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

const Signup = () => {
    const intl = useIntl();
    const { signup } = useAuth();
    const [busy, setBusy] = useState(false);
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: ''
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required('First name is required'),
            lastName: Yup.string(),
            email: Yup.string().email('Invalid email address').required('Email is required'),
            password: Yup.string().required('Password required').min(6, 'Password must be longer than 6'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Password mismatch')
                .required('Confirm password is required')
        }),
        onSubmit: async (values) => {
            setBusy(true);
            try {
                await signup(values.email, values.password, values.firstName, values.lastName);
            } catch (e: any) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: e.message,
                        variant: 'error'
                    })
                );
            } finally {
                setBusy(false);
            }
        }
    });

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100vh' }}>
            <Box sx={{ width: { xs: '90%', sm: 405 } }}>
                <Box sx={{ my: 2 }}>
                    <AuthLogo />
                    <AuthTitle>
                        <FormattedMessage id="sign_up" />
                    </AuthTitle>
                </Box>
                <form onSubmit={formik.handleSubmit}>
                    <AuthInput
                        name="firstName"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        placeholder={intl.formatMessage({ id: 'first_name' })}
                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                        helperText={formik.touched.firstName && formik.errors.firstName}
                    />
                    <AuthInput
                        name="lastName"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        placeholder={intl.formatMessage({ id: 'last_name' })}
                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                        helperText={formik.touched.lastName && formik.errors.lastName}
                    />
                    <AuthInput
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        placeholder={intl.formatMessage({ id: 'email' })}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                    <AuthPassword
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        placeholder={intl.formatMessage({ id: 'password' })}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                    />
                    <AuthPassword
                        name="confirmPassword"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        placeholder={intl.formatMessage({ id: 'confirm_password' })}
                        error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                    />
                    <Button sx={{ my: 3 }} title={intl.formatMessage({ id: 'sign_up' })} onClick={formik.handleSubmit} loading={busy} />
                </form>
            </Box>
        </Box>
    );
};

export default Signup;
