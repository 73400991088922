import { gql } from '@apollo/client';

const TASK_QUERY = gql`
    query Task($document_id: uuid_comparison_exp, $task_id: uuid_comparison_exp) {
        task: workflow_tasks(where: { id: $task_id }) {
            description
            due_date
            role
            user {
                first_name
                id
                image_url
                processmaker_user {
                    processmaker_id
                }
            }
        }
        document: workflow_documents(where: { id: $document_id }) {
            pages {
                id
                name
                sections {
                    name
                    id
                    fields {
                        name
                        id
                        props: fields_attached_to_tasks(where: { task_id: $task_id }) {
                            can_edit
                            can_view
                            required_field
                            visible
                        }
                    }
                }
            }
        }
    }
`;

export default TASK_QUERY;
