import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';

interface TitleProps {
    title: string;
}

const Title = ({ title }: TitleProps) => {
    const theme = useTheme();

    return (
        <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600, fontSize: '14px' }}>
            <FormattedMessage id={title} />
        </Typography>
    );
};

export default Title;
