import { gql } from '@apollo/client';

const GET_OFFERS = gql`
    query GetOffers {
        offers {
            updated_at
            title
            image_url
            description
            created_at
            id
            agency {
                id
                name
            }
        }
    }
`;

const GET_ACTIVE_OFFERS = gql`
    query GetActiveOffers {
        offers(where: { status: { _eq: ACTIVE } }) {
            id
            title
            image_url
            description
            created_at
            updated_at
            agency {
                id
                name
            }
        }
    }
`;

const GET_USERS = gql`
    query GetUser {
        offers(where: { id: { _eq: "4aa511f8-d33a-44d2-8cc7-31079ad9e3a1" } }) {
            agency {
                members {
                    id
                    organization_id
                    user {
                        first_name
                        last_name
                        image_url
                        email
                    }
                    status
                }
            }
        }
    }
`;

const GET_ACTIVE_OFFERS_CLIENTS = gql`
    query getActiveOffersClients {
        offers(where: { status: { _eq: ACTIVE } }) {
            agency_id
            created_at
            description
            id
            image_url
            status
            title
            updated_at
            projects(where: { status: { _eq: ACTIVE } }) {
                id
                agency_id
                created_at
                details
                name
                status
                client_organization {
                    id
                    name
                    status
                    logo_url
                    members(where: { role_id: { _eq: CLIENT_MANAGER } }) {
                        id
                        role_id
                        status
                        user {
                            id
                            first_name
                            last_name
                            email
                            image_url
                            phone
                        }
                    }
                }
            }
        }
    }
`;

// eslint-disable-next-line import/prefer-default-export
export { GET_OFFERS, GET_ACTIVE_OFFERS, GET_USERS, GET_ACTIVE_OFFERS_CLIENTS };
