import { Typography, Box, Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Button } from 'ui-component/extended/Button';
import CloseIcon from '@mui/icons-material/Close';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

interface CropDialogProps {
    url: string;
    onClose: () => void;
    onDone: (file: File) => void;
}
export const CropDialog = ({ url, onClose, onDone }: CropDialogProps) => {
    const theme = useTheme();
    const [crop, setCrop] = React.useState<Crop>({
        unit: '%',
        width: 50,
        height: 50,
        x: 25,
        y: 25
    });
    const [loading, setLoading] = useState(false);

    const onCrop = async () => {
        onClose();
        try {
            setLoading(true);
            const response = await fetch(url);
            const data = await response.blob();
            const metadata = { type: 'image/jpeg' };
            const file = new File([data], 'test.jpg', metadata);

            const image = new Image();
            image.onload = () => {
                if (crop.unit === '%') {
                    crop.x = (image.width * crop.x) / 100;
                    crop.y = (image.height * crop.y) / 100;
                    crop.width = (image.width * crop.width) / 100;
                    crop.height = (image.height * crop.height) / 100;
                }

                const canvas = document.createElement('canvas');
                canvas.width = crop.width;
                canvas.height = crop.height;
                const ctx = canvas.getContext('2d');

                ctx?.drawImage(image, crop.x, crop.y, crop.width, crop.height, 0, 0, crop.width, crop.height);
                canvas.toBlob(async (blob) => {
                    if (blob) {
                        try {
                            const cropped = new File([blob], 'logo.jpg');
                            onDone(cropped);
                        } catch (e) {
                            console.log(JSON.stringify(e));
                        }
                    }
                });
            };

            image.src = URL.createObjectURL(file);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ p: '5px 20px', borderRadius: '16px', width: { xs: '280px', sm: '364px' } }}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={6}>
                    <Typography ml={1} sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500, fontSize: '16px' }}>
                        <FormattedMessage id="crop" />
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {' '}
                        <CloseIcon onClick={onClose} sx={{ fontSize: '20px', fill: '#9197AE', cursor: 'pointer' }} />
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', width: '100%', minHeight: 200 }}>
                <ReactCrop
                    className="crop"
                    crop={crop}
                    onComplete={(c, p) => setCrop(p)}
                    onChange={(c, p) => setCrop(p)}
                    style={{ maxWidth: '600px', maxHeight: '800px' }}
                >
                    <img width="100%" src={url} alt="crop-target" />
                </ReactCrop>
            </Box>
            <Box mt={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ width: '120px' }}>
                    <Button onClick={onCrop} title={<FormattedMessage id="crop_image" />} loading={loading} />
                </Box>
            </Box>
        </Box>
    );
};
