import { useState } from 'react';
import { Box, Typography, Popover, CardMedia, IconButton, Dialog } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import edit from 'assets/images/edit.svg';
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';
import { Text } from 'ui-component/typography';
import ConfirmDialog from 'ui-component/dialogConfirm';
// import AddCaptionGeneratorDrawer, { CaptionGeneratorCardType } from './AddCaptionGeneratorDrawer';
import InputOutputEditor from './InputOutputEditor';
// import { useMutation } from '@apollo/client';
// import { REMOVE_CAPTION_EXAMPLE } from 'services/graphQL/mutations/aiTools/AiToolsMutations';

// Services
import { createUpdateDeleteIoExample } from '../../../../../../services/rest/virtual-employee';

export interface InputOutputCardProps {
    finetuningId: string;
    index: number;
    input: string;
    output: string;
    getFinetuning: () => void;
}

const InputOutputCard = (item: InputOutputCardProps) => {
    const { finetuningId, input, output, index, getFinetuning } = item;
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const [showConfirm, setShowConfirm] = useState(false);
    const id = anchorEl ? 'simple-popover' : undefined;
    const [showCaptionGenerator, setShowCaptionGenerator] = useState(false);
    const [selectedCaptionGenerator, setSelectedCaptionGenerator] = useState<InputOutputCardProps>();

    const [loading, setLoading] = useState(false);

    const closeCaptionGenerator = () => setShowCaptionGenerator(false);
    const openCaptionGenerator = (item: InputOutputCardProps) => {
        setShowCaptionGenerator(true);
        setSelectedCaptionGenerator(item);
        closePopover();
    };

    const openConfirm = () => setShowConfirm(true);
    const closeConfirm = () => setShowConfirm(false);
    const openPopover = (event: any) => setAnchorEl(event.currentTarget);
    const closePopover = () => setAnchorEl(null);

    const deleteInputOutput = async () => {
        setLoading(true);
        await createUpdateDeleteIoExample({
            finetuningId,
            action: 'delete',
            index
        });
        getFinetuning();
        closeConfirm();
        closePopover();
        setLoading(false);
    };

    return (
        <Box sx={{ background: theme.palette.grey[700], borderRadius: '8px', p: 2, mt: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="overline">
                    <FormattedMessage id="input" />
                </Typography>
                <IconButton onClick={openPopover}>
                    <MoreHoriz />
                </IconButton>
                <Popover
                    id={id}
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    onClose={closePopover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                >
                    <Box sx={{ p: '16px 24px' }}>
                        <Box
                            onClick={() => openCaptionGenerator(item)}
                            sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center', mb: 2 }}
                        >
                            <Box sx={{ width: '20px', mr: '5px' }}>
                                <CardMedia sx={{ objectFit: 'contain' }} component="img" image={edit} alt="AI tools" />
                            </Box>
                            <Text sx={{ fontSize: '14px', ml: '10px' }}>
                                <FormattedMessage id="edit" />
                            </Text>
                        </Box>
                        <Box onClick={openConfirm} sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
                            <BackspaceOutlinedIcon sx={{ width: '20px' }} />
                            <Text sx={{ fontSize: '14px', ml: '10px' }}>
                                <FormattedMessage id="remove" />
                            </Text>
                        </Box>
                    </Box>
                </Popover>
            </Box>
            <Typography sx={{ mb: 2 }}>{input}</Typography>
            <Typography variant="overline">
                <FormattedMessage id="output" />
            </Typography>
            <Typography>{output}</Typography>
            <InputOutputEditor
                open={showCaptionGenerator}
                onClose={closeCaptionGenerator}
                item={selectedCaptionGenerator}
                getFinetuning={getFinetuning}
                finetuningId={finetuningId}
            />
            <Dialog open={showConfirm} onClose={closeConfirm}>
                {/* <ConfirmDialog onClose={closeConfirm} onConfirm={() => removeCaption(item.id)} loading={loading} /> */}
                {/* <ConfirmDialog onClose={closeConfirm} onConfirm={() => console.log('about to remove')} /> */}
                <ConfirmDialog onClose={closeConfirm} onConfirm={() => deleteInputOutput()} loading={loading} />
            </Dialog>
        </Box>
    );
};

export default InputOutputCard;
