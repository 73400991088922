import { Box, Avatar, Typography, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { ProjectMemberType } from 'types/clients';

interface MemberProps {
    member: ProjectMemberType;
    onDelete: (id: string) => void;
}
export const Member = ({ member, onDelete }: MemberProps) => {
    const theme = useTheme();

    const handleDelete = () => {
        onDelete(member.id);
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Avatar sx={{ width: '32px', height: '32px' }} alt="Remy Sharp" src={member.icon} />
                <Box ml={1}>
                    <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500 }}>
                        {member.firstName}
                    </Typography>
                    <Typography
                        sx={{
                            color: theme.palette.grey[400],
                            fontFamily: 'Inter',
                            fontWeight: 400,
                            fontSize: '12px'
                        }}
                    >
                        {member.firstName}
                    </Typography>
                </Box>
            </Box>
            <IconButton onClick={handleDelete}>
                <RemoveCircleOutlineIcon />
            </IconButton>
        </Box>
    );
};
