import axios from 'axios';
// import useAuth from 'hooks/useAuth';

// Create channel
interface CreateChannelInput {
    name: string;
}

export const createChannel = async (input: CreateChannelInput) => {
    const baseURL = process.env.REACT_APP_SNIKPIC_BASE_URL;
    const endpointURL = `${baseURL}/stream/channels/create_with_name_and_creator_id`;
    try {
        const response = await axios.post(
            endpointURL,
            {
                name: input.name,
                creatorId: `${JSON.parse(localStorage.getItem('user') || '')?.user?.id ?? ''}`
            },
            {
                headers: {
                    authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '')?.tokens?.access_token ?? ''}`
                }
            }
        );
        return response.data;
    } catch (e) {
        // console.log(JSON.stringify(e));
        return null;
    }
};

// Update channel image
// type UpdateChannelImageInput = {
//     name: string;
// };

export const updateChannelImage = async (channelId: string, imageUrl: string) => {
    const baseURL = process.env.REACT_APP_SNIKPIC_BASE_URL;
    const endpointURL = `${baseURL}/stream/channels/update_channel_image`;
    try {
        const response = await axios.post(
            endpointURL,
            {
                channelId,
                imageUrl
            },
            {
                headers: {
                    authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '')?.tokens?.access_token ?? ''}`
                }
            }
        );
        return response.data;
    } catch (e) {
        // console.log(JSON.stringify(e));
        return null;
    }
};
