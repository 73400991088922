import * as React from 'react';
import Box from '@mui/material/Box';
import {
    Table,
    Popover,
    Avatar,
    CardMedia,
    Dialog,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    IconButton
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import zapoff from 'assets/images/zapoff.svg';
import deleteIcon from 'assets/images/deleteIcon.svg';
import DialogConfirm from 'ui-component/dialogConfirm';
import moment from 'moment';
import DialogViewTeamMember from './DialogViewTeamMember';
import { DELETE_MEMBER } from '../../../api/graphql/mutations/team/teamMutations';
import { useMutation } from '@apollo/client';
import MoreHoriz from '@mui/icons-material/MoreHoriz';

function EnhancedTableHead() {
    const theme = useTheme();
    const color = theme.palette.grey[400];

    return (
        <TableHead sx={{ borderBottom: `1px solid ${theme.palette.grey[500]}`, background: theme.palette.grey[700] }}>
            <TableRow>
                <TableCell align="left">
                    {' '}
                    <Typography sx={{ color, fontFamily: 'Inter', fontWeight: 600 }}>
                        <FormattedMessage id="name" />
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography sx={{ color, fontFamily: 'Inter', fontWeight: 600 }}>
                        <FormattedMessage id="email" />
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography sx={{ color, fontFamily: 'Inter', fontWeight: 600 }}>
                        <FormattedMessage id="title" />
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography sx={{ color, fontFamily: 'Inter', fontWeight: 600 }}>
                        <FormattedMessage id="type" />
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography sx={{ color, fontFamily: 'Inter', fontWeight: 600 }}>
                        <FormattedMessage id="date_added" />
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography sx={{ color, fontFamily: 'Inter', fontWeight: 600, display: 'none' }}>
                        <FormattedMessage id="date_added" />
                    </Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

export default function TeamTable({ members, organizationId, fetchTeam }: any) {
    const theme = useTheme();

    const FullTableRow = ({ isItemSelected, row }: any) => {
        const [showDialog, setShowDialog] = React.useState(false);
        const [anchorEl, setAnchorEl] = React.useState<any>(null);
        const [nameOffers, setNameOffers] = React.useState<any>();
        const [idMember, setIdMember] = React.useState('');
        const [openDialogViewTeamMember, setOpenDialogViewTeamMember] = React.useState(false);
        const id = anchorEl ? 'simple-popover' : undefined;

        const openPopover = (event: any) => setAnchorEl(event.currentTarget);
        const closePopover = () => setAnchorEl(null);
        const openDialog = () => setShowDialog(true);
        const closeDialog = () => setShowDialog(false);

        const [deleteMember] = useMutation(DELETE_MEMBER, {
            onCompleted: (data: any) => {
                fetchTeam();
                setShowDialog(false);
            },
            onError: (error: any) => {
                console.log(error);
            }
        });
        const hendleOpenDialogViewTeamMember = () => {
            setOpenDialogViewTeamMember(true);
            closePopover();
        };
        const hendleCloseDialogViewTeamMember = () => setOpenDialogViewTeamMember(false);

        const onConfirmDeleteMember = () => {
            deleteMember({
                variables: { organization_id: organizationId, user_id: idMember }
            });
        };
        const handleClickDelete = (idUser: string) => {
            setIdMember(idUser);
            openDialog();
            closePopover();
        };
        const handleClickPopoverView = (itemOffers: any) => {
            // console.log('In handleClickPopoverView');
            // console.log('itemOffers: ', itemOffers);
            setNameOffers(itemOffers);
            hendleOpenDialogViewTeamMember();
            closePopover();
        };

        return (
            <TableRow hover role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row.id} selected={isItemSelected}>
                <TableCell onClick={() => handleClickPopoverView(row)} component="th" scope="row" sx={{ pt: '35px', cursor: 'pointer' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar sx={{ width: '32px', height: '32px' }} alt="Remy Sharp" src={row.user.image_url} />
                        <Box>
                            <Typography ml={1} sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500 }}>
                                {row.user.first_name}
                            </Typography>
                            <Typography
                                ml={1}
                                sx={{
                                    color: theme.palette.grey[600],
                                    fontFamily: 'Inter',
                                    fontWeight: 600,
                                    textTransform: 'uppercase',
                                    fontSize: '10px',
                                    letterSpacing: '0.12em',
                                    mt: '-1px'
                                }}
                            >
                                {row.role_id}
                            </Typography>
                        </Box>
                    </Box>
                </TableCell>
                <TableCell onClick={() => handleClickPopoverView(row)} align="left" sx={{ pt: '35px', cursor: 'pointer' }}>
                    <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500 }}>{row.user.email}</Typography>
                </TableCell>
                <TableCell onClick={() => handleClickPopoverView(row)} align="left" sx={{ pt: '35px', cursor: 'pointer' }}>
                    <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600 }}>{row.title}</Typography>
                </TableCell>
                <TableCell onClick={() => handleClickPopoverView(row)} align="left" sx={{ pt: '35px', cursor: 'pointer' }}>
                    <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600, textTransform: 'capitalize' }}>
                        {row.type === 'MEMBER' ? 'In-Agency' : 'Freelancer'}
                    </Typography>
                </TableCell>
                <TableCell onClick={() => handleClickPopoverView(row)} align="left" sx={{ pt: '35px', cursor: 'pointer' }}>
                    <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600 }}>
                        {moment(row.created_at).format('DD MMMM, YYYY')}
                    </Typography>
                </TableCell>
                <TableCell align="right" sx={{ pt: '30px' }}>
                    <IconButton onClick={openPopover}>
                        <MoreHoriz />
                    </IconButton>
                    <Popover
                        id={id}
                        open={!!anchorEl}
                        anchorEl={anchorEl}
                        onClose={closePopover}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                    >
                        <Box sx={{ p: '24px' }}>
                            <Box
                                onClick={() => handleClickPopoverView(row)}
                                sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
                            >
                                <VisibilityOutlinedIcon sx={{ width: '20px' }} />
                                <Typography
                                    sx={{
                                        color: theme.palette.grey[400],
                                        fontFamily: 'Inter',
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        ml: '10px'
                                    }}
                                >
                                    <FormattedMessage id="view" />
                                </Typography>
                            </Box>
                            <Box
                                onClick={() => handleClickDelete(row.user.id)}
                                mt={1}
                                sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
                            >
                                <CardMedia sx={{ objectFit: 'contain' }} component="img" width="18px" image={zapoff} alt="alt image" />
                                <Typography
                                    sx={{
                                        color: theme.palette.grey[400],
                                        fontFamily: 'Inter',
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        ml: '12px'
                                    }}
                                >
                                    <FormattedMessage id="deactivate" />
                                </Typography>
                            </Box>
                            <Box
                                onClick={() => handleClickDelete(row.user.id)}
                                sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center', mt: '10px', ml: '2px' }}
                            >
                                <Box sx={{ width: '18px' }}>
                                    <CardMedia
                                        sx={{ objectFit: 'contain' }}
                                        component="img"
                                        width="18px"
                                        image={deleteIcon}
                                        alt="alt image"
                                    />
                                </Box>
                                <Typography
                                    sx={{
                                        color: theme.palette.grey[400],
                                        fontFamily: 'Inter',
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        ml: '10px'
                                    }}
                                >
                                    <FormattedMessage id="delete" />
                                </Typography>
                            </Box>
                        </Box>
                    </Popover>
                </TableCell>
                <Dialog sx={{ '.MuiDialog-paper': { minWidth: { xs: '350px', sm: '562px' } } }} onClose={closeDialog} open={showDialog}>
                    <DialogConfirm onConfirm={onConfirmDeleteMember} onClose={closeDialog} />
                </Dialog>
                <Dialog
                    sx={{ '.MuiDialog-paper': { minWidth: { xs: '350px', sm: '562px' } } }}
                    onClose={hendleCloseDialogViewTeamMember}
                    open={openDialogViewTeamMember}
                >
                    <DialogViewTeamMember onClose={hendleCloseDialogViewTeamMember} item={nameOffers} />
                </Dialog>
            </TableRow>
        );
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table sx={{ borderRadius: '8px 8px 0 0', minWidth: 750 }} aria-labelledby="tableTitle">
                        <EnhancedTableHead />
                        <TableBody>
                            {members.map((row: any, index: number) => {
                                // console.log('In map - row: ', row);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return <FullTableRow labelId={labelId} row={row} />;
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
}
