import { Typography, Box, Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import moment from 'moment';
import { ClientFBResource, ClientFBResourceStatus } from 'types/facebook';

export interface ItemType {
    name: string;
    status: ClientFBResourceStatus;
    date?: string;
}
interface ClientDetailsIntegrationsItemProps {
    resource: ClientFBResource;
}

export const ClientDetailsIntegrationsItem = ({ resource }: ClientDetailsIntegrationsItemProps) => {
    const theme = useTheme();
    const isCompleted = resource.status === 'COMPLETED';

    const dateConnected = isCompleted ? (
        <Box>
            <Typography
                sx={{
                    color: theme.palette.grey[300],
                    fontFamily: 'Inter',
                    fontWeight: 600,
                    fontSize: '10px',
                    textTransform: 'uppercase',
                    letterSpacing: '0.12em',
                    textAlign: 'right'
                }}
            >
                <FormattedMessage id="date_connected" />
            </Typography>
            <Typography
                mt={1}
                sx={{
                    color: theme.palette.grey[400],
                    fontFamily: 'Inter',
                    fontWeight: 500,
                    fontSize: '14px',
                    textAlign: 'right'
                }}
            >
                {moment(resource.connection_date).format('DD MMMM, YYYY')}
            </Typography>
        </Box>
    ) : (
        ''
    );

    return (
        <Box
            sx={{
                background: theme.palette.grey[700],
                borderRadius: '8px',
                p: '16px'
            }}
        >
            <Grid container justifyContent="space-between">
                <Grid item xs={12} sm={6}>
                    <Typography
                        sx={{
                            color: theme.palette.common.black,
                            fontFamily: 'Inter',
                            fontWeight: 500,
                            fontSize: '14px'
                        }}
                    >
                        <FormattedMessage id={`${resource.resource_type}_access`} />
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                            sx={{
                                color: isCompleted ? theme.palette.success[400] : theme.palette.grey[300],
                                fontFamily: 'Inter',
                                fontWeight: 500,
                                fontSize: '12px'
                            }}
                        >
                            {resource.status}
                        </Typography>
                        <Box
                            ml={1}
                            sx={{
                                width: '15px',
                                background: isCompleted ? theme.palette.success[400] : theme.palette.grey[300],
                                borderRadius: '100px',
                                height: '15px'
                            }}
                        >
                            {isCompleted ? (
                                <CheckIcon sx={{ fontSize: '12px', fill: theme.palette.background.paper, m: '-4px 0 1px 1.5px' }} />
                            ) : (
                                <CancelIcon sx={{ fontSize: '12px', fill: theme.palette.background.paper, m: '-4px 0 1px 1.5px' }} />
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid mt={5} container justifyContent="space-between">
                <Grid item xs={12} sm={6}>
                    <Typography
                        sx={{
                            color: theme.palette.grey[300],
                            fontFamily: 'Inter',
                            fontWeight: 600,
                            fontSize: '10px',
                            textTransform: 'uppercase',
                            letterSpacing: '0.12em'
                        }}
                    >
                        <FormattedMessage id={`${resource.resource_type}_access_title`} />
                    </Typography>
                    <Typography
                        sx={{
                            color: theme.palette.grey[400],
                            fontFamily: 'Inter',
                            fontWeight: 500,
                            fontSize: '14px',
                            mt: 1
                        }}
                    >
                        {resource.fb_name}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {dateConnected}
                </Grid>
            </Grid>
        </Box>
    );
};
