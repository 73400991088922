import { Popover, CardMedia, Typography, Box } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { FormattedMessage } from 'react-intl';
import zapoff from 'assets/images/zapoff.svg';

interface MenuProps {
    id?: string;
    open: boolean;
    anchorEl: Element;
    onClose: () => void;
    onMenu: (key: string) => void;
}
export const Menu = ({ id, open, anchorEl, onClose, onMenu }: MenuProps) => (
    <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
        <Box sx={{ p: '8px 24px' }}>
            <Box onClick={() => onMenu('view')} sx={{ display: 'flex', my: 2, cursor: 'pointer', alignItems: 'center' }}>
                <VisibilityOutlinedIcon sx={{ width: '20px' }} />
                <Typography
                    sx={{
                        color: (theme) => theme.palette.grey[400],
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        fontSize: '14px',
                        ml: '10px'
                    }}
                >
                    <FormattedMessage id="view" />
                </Typography>
            </Box>
            <Box onClick={() => onMenu('deactivate')} sx={{ display: 'flex', my: 2, cursor: 'pointer', alignItems: 'center' }}>
                <CardMedia sx={{ objectFit: 'contain' }} component="img" width="18px" image={zapoff} alt="alt image" />
                <Typography
                    sx={{
                        color: (theme) => theme.palette.grey[400],
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        fontSize: '14px',
                        ml: '12px'
                    }}
                >
                    <FormattedMessage id="deactivate" />
                </Typography>
            </Box>
        </Box>
    </Popover>
);
