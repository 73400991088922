import { TextField } from '@mui/material';
import React from 'react';

interface IDescription {
    description: string;
    setDescription: (value: string) => void;
}

const Index = ({ description, setDescription }: IDescription) => {
    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value);
    };

    return (
        <TextField
            sx={{
                '.MuiOutlinedInput-input': { background: '#fff' }
            }}
            fullWidth
            multiline
            rows={4}
            label="Description"
            InputLabelProps={{ shrink: true }}
            placeholder="In this task, you will be responsible for..."
            value={description}
            onChange={handleDescriptionChange}
        />
    );
};

export default Index;
