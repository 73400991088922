import { gql } from '@apollo/client';

const ADD_MEMBER_TO_PROIVIDED_SERVICE = gql`
    mutation MyMutation($project_id: uuid!, $membership_id: uuid!) {
        insert_project_users_one(object: { organization_members_id: $membership_id, project_id: $project_id }) {
            id
        }
    }
`;

const DEACTIVATE_CLIENT = gql`
    mutation updateClient($clientId: uuid!) {
        update_client_organizations_by_pk(pk_columns: { id: $clientId }, _set: { status: INACTIVE }) {
            id
            status
        }
        update_projects(where: { business_id: { _eq: $clientId } }, _set: { status: INACTIVE }) {
            affected_rows
        }
    }
`;

export { ADD_MEMBER_TO_PROIVIDED_SERVICE, DEACTIVATE_CLIENT };
