import { Navigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { GuardProps } from 'types';

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const UserGuard = ({ children }: GuardProps) => {
    const { isLoggedIn } = useAuth();

    if (!isLoggedIn) {
        return <Navigate to="/auth/login" />;
    }

    return children;
};

export default UserGuard;
