import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import ButtonCustom from 'ui-component/extended/Button';
import { FormattedMessage } from 'react-intl';
import CreateWorkflowDialog from './widgets/createWorkflowDialog';

const CreateNewWorkflow = () => {
    const theme = useTheme();
    const [showWorkflowDialog, setShowWorkflowDialog] = React.useState(false);
    return (
        <Box>
            <ButtonCustom
                onClick={() => setShowWorkflowDialog(true)}
                titleBtn={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                            sx={{
                                color: theme.palette.background.paper,
                                fontWeight: 500,
                                fontFamily: 'Inter',
                                fontSize: '20px',
                                m: '-3px 5px 0 0'
                            }}
                        >
                            +
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontFamily: 'Inter' }}>
                            <FormattedMessage id="create_new_workflow" />
                        </Typography>
                    </Box>
                }
                colorBtn="red"
            />
            <CreateWorkflowDialog state={[showWorkflowDialog, setShowWorkflowDialog]} />
        </Box>
    );
};

export default CreateNewWorkflow;
