import axios, { AxiosRequestConfig } from 'axios';
import useAuth from 'hooks/useAuth';

interface GenerateStripeCustomerPortalUrlInput {
    stripeCustomerId: string;
}

export const generateStripeCustomerPortalUrl = async (input: GenerateStripeCustomerPortalUrlInput) => {
    const baseURL = process.env.REACT_APP_SNIKPIC_BASE_URL;
    const endpointURL = `${baseURL}/stripe/customer-portal`;
    try {
        console.log('In generateStripeCustomerPortalUrl for: ', input.stripeCustomerId);
        const response = await axios.post(
            endpointURL,
            {
                stripe_customer_id: input.stripeCustomerId
            },
            {
                headers: {
                    authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '')?.tokens?.access_token ?? ''}`
                    // 'x-hasura-organization-id': pullOrganizationIdFromAuthContext()
                }
            }
        );
        return response.data;
    } catch (e) {
        console.log(JSON.stringify(e));
        return null;
    }
};
