import { useState, FC, useCallback } from 'react';
import { Box } from '@mui/material';

import { TEMPLATE_WORKFLOW_STEPS } from '../../features/types';
import TemplatePreview from './components/template-preview';
import TemplateCustomization from './components/template-customization';

// const validationSchema = yup.array().of(
//     yup.object().shape({
//         pageName: yup.string().required('Required'),
//         pageSections: yup.array().of(
//             yup.object().shape({
//                 sectionName: yup.string().required('Required')
//             })
//         )
//     })
// );

interface CreateWorkflowFromTemplateProps {
    backToOptions: () => void;
}
const CreateWorkflowFromTemplate: FC<CreateWorkflowFromTemplateProps> = ({ backToOptions }) => {
    const [step, setStep] = useState<TEMPLATE_WORKFLOW_STEPS>(TEMPLATE_WORKFLOW_STEPS.PREVIEW);

    const toPreview = useCallback(() => setStep(TEMPLATE_WORKFLOW_STEPS.PREVIEW), [setStep]);
    const toCustomization = useCallback(() => setStep(TEMPLATE_WORKFLOW_STEPS.CUSTOMIZE), [setStep]);

    return (
        <Box>
            {step === TEMPLATE_WORKFLOW_STEPS.PREVIEW && (
                <TemplatePreview backToOptions={backToOptions} forwardToCustomization={toCustomization} />
            )}
            {step === TEMPLATE_WORKFLOW_STEPS.CUSTOMIZE && <TemplateCustomization backToPreview={toPreview} />}
        </Box>
    );
};

export default CreateWorkflowFromTemplate;
