import React from 'react';
import { Box, Alert } from '@mui/material';
import { generateStripeCustomerPortalUrl } from 'services/rest/billing';
import { useLazyQuery } from '@apollo/client';
import { GET_USER_ORGANIZATIONS } from 'services/graphQL';

const BillingTab = () => {
    // Fetch userId
    const userId = JSON.parse(`${localStorage.getItem('user')}`);

    // Define function to create and open Stripe customer portal
    const createAndOpenStripeCustomerPortal = async (stripeCustomerId: string) => {
        try {
            const stripeCustomerPortalUrl = await generateStripeCustomerPortalUrl({ stripeCustomerId });
            window.open(stripeCustomerPortalUrl.url);
        } catch (e) {
            console.log(e);
        }
    };

    // Define function to fetch Stripe customer id based on the logged in user
    const [getUser] = useLazyQuery(GET_USER_ORGANIZATIONS, {
        onCompleted: (res) => {
            createAndOpenStripeCustomerPortal(res.organizations[0].stripe_customer_id);
        }
    });

    // Initialize component
    const initialize = () => {
        getUser({
            variables: {
                user_id: userId.user.id
            }
        });
    };
    React.useEffect(() => {
        initialize();
    }, []);

    // Render componenent
    return (
        <Box>
            <Alert sx={{ mx: 2 }} severity="info">
                Manage your billing settings from the portal which opened in a new tab.
            </Alert>
        </Box>
    );
};

export default BillingTab;
