import { Grid, Box, Typography, Divider, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SelectCustom from 'ui-component/selectCustom';
import ButtonCustom from 'ui-component/extended/Button';
import HelpDialog from './HelpDialog';
import React from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { useMutation } from '@apollo/client';
import { ADD_CLIENT_FACEBOOK_CONNECTION, ADD_FB_RESOURCE } from 'services/graphQL/mutations/fbIntegration';

interface StepTwoProps {
    setStep: any;
    step: number;
    userData: UserDataType;
    context: any;
    setContext: any;
}
interface UserDataType {
    accessToken: string;
    userID: string;
}
interface LabelsType {
    id: string;
    name: string;
}

interface OptionalSelectorProps {
    titleId: string;
    choices: LabelsType[];
    formikFieldName: string;
    handleValue?: any;
}

export default function StepTwo({ setStep, step, userData, context, setContext }: StepTwoProps) {
    const theme = useTheme();
    const params = useParams();
    const organizationId = params.organizationId;
    const axiosConfig: AxiosRequestConfig = {
        baseURL: 'https://graph.facebook.com/v14.0',
        params: {
            access_token: userData.accessToken
        },
        headers: { 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept' }
    };

    const [openDialogHelp, setOpenDialogHelp] = React.useState(false);
    const [businessOptions, setBusinessOptions] = React.useState<LabelsType[]>([]);
    const [pageOptions, setPageOptions] = React.useState<LabelsType[]>([]);
    const [adAccountOptions, setAdAccountOptions] = React.useState<LabelsType[]>([]);
    const [catalogOptions, setCatalogOptions] = React.useState<LabelsType[]>([]);
    const [pixelOptions, setPixelOptions] = React.useState<LabelsType[]>([]);
    const [instagramOptions, setInstagramOptions] = React.useState<LabelsType[]>([]);

    const [accessTokenMap] = React.useState(new Map<string, string>());

    const options = {
        business: businessOptions,
        page: pageOptions,
        ad: adAccountOptions,
        catalog: catalogOptions,
        pixel: pixelOptions,
        instagram: instagramOptions
    };

    const formik = useFormik({
        initialValues: {
            business: '',
            ad: '',
            page: '',
            catalog: '',
            pixel: '',
            instagram: ''
        },
        validationSchema: Yup.object({
            page: Yup.string().required('Required'),
            business: Yup.string().required('Required')
        }),
        onSubmit: async (values) => {
            const callStack: Promise<any>[] = [
                addClientConnection({
                    variables: {
                        client_organization_id: organizationId,
                        access_token: userData.accessToken,
                        facebook_user_id: userData.userID,
                        business_id: values.business
                    }
                })
            ];

            const localContext = context;
            localContext.business = businessOptions.find((business) => business.id === values.business);

            if (values.ad !== '') {
                callStack.push(
                    addClientAccess({
                        value: values.ad,
                        resourceType: 'ad'
                    })
                );
            }
            if (values.page !== '') {
                callStack.push(
                    addClientAccess({
                        value: values.page,
                        resourceType: 'page'
                    })
                );
            }
            if (values.catalog !== '') {
                callStack.push(
                    addClientAccess({
                        value: values.catalog,
                        resourceType: 'catalog'
                    })
                );
            }
            if (values.pixel !== '') {
                callStack.push(
                    addClientAccess({
                        value: values.pixel,
                        resourceType: 'pixel'
                    })
                );
            }
            if (values.instagram !== '') {
                callStack.push(
                    addClientAccess({
                        value: values.instagram,
                        resourceType: 'instagram'
                    })
                );
            }

            await Promise.allSettled(callStack);

            confirmAccess();
        }
    });
    const handleCloseDialogHelp = () => {
        setOpenDialogHelp(false);
    };
    const handleOpenDialogHelp = () => {
        setOpenDialogHelp(true);
    };
    const confirmAccess = () => {
        setStep(step + 1);
    };
    const [_addClientAccess, { loading: addClientAccessLoading }] = useMutation(ADD_FB_RESOURCE, {
        context: {
            headers: {
                'x-hasura-role': 'CLIENT_MANAGER'
            }
        },
        onCompleted: (data) => {
            console.log('addFbResource', data);
        },
        onError: (error: any) => {
            console.log(error);
        }
    });

    const [addClientConnection] = useMutation(ADD_CLIENT_FACEBOOK_CONNECTION, {
        onCompleted: (data) => {
            console.log(data);
        }
    });

    const addClientAccess = async ({ value, resourceType }: any): Promise<void> => {
        const choices = options[resourceType as keyof typeof options] ?? [];
        const { name } = choices.find((choice) => choice.id === value) as LabelsType;

        await _addClientAccess({
            variables: {
                client_organization_id: organizationId,
                fb_id: value,
                fb_name: name,
                resource_type: resourceType
            }
        });
    };

    const fetchBusinesses = async () => {
        try {
            const values = await axios(`/me/businesses?fields=name,business_name`, axiosConfig);
            formik.setFieldValue('business', '');

            setBusinessOptions(
                values.data.data.map((element: any) => ({
                    id: element.id,
                    name: element.name
                }))
            );
        } catch (err) {
            console.error(err);
        }
    };

    const fetchAdAccounts = async (businessId: string) => {
        try {
            const values = await axios(`/${businessId}/owned_ad_accounts?fields=id,name`, axiosConfig);
            formik.setFieldValue('ad', '');
            if (values.data.data.length < 1) {
                setAdAccountOptions([]);
            } else {
                setAdAccountOptions(
                    values.data.data.map((element: any) => ({
                        id: element.id,
                        name: element.name
                    }))
                );
            }
        } catch (err) {
            console.error(err);
            setAdAccountOptions([]);
        }
    };

    const fetchPages = async (businessId: string) => {
        try {
            const values = await axios(`/${businessId}/owned_pages?fields=id,name,access_token`, axiosConfig);

            formik.setFieldValue('page', '');

            setPageOptions(
                values.data.data
                    .filter((element: any) => element.access_token)
                    .map((element: any) => {
                        accessTokenMap.set(element.id, element.access_token);
                        console.log({ element });
                        return {
                            id: element.id,
                            name: element.name
                        };
                    })
            );
        } catch (err) {
            console.error(err);
        }
    };

    const fetchCatalogs = async (businessId: string) => {
        try {
            const values = await axios(`/${businessId}/owned_product_catalogs?fields=id,name`, axiosConfig);

            formik.setFieldValue('catalog', '');
            if (values.data.data.length < 1) {
                setCatalogOptions([]);
            } else {
                setCatalogOptions(
                    values.data.data.map((element: any) => ({
                        id: element.id,
                        name: element.name
                    }))
                );
            }
        } catch (err) {
            console.error(err);
            setCatalogOptions([]);
        }
    };

    const fetchPixels = async (businessId: string) => {
        try {
            const values = await axios(`/${businessId}/owned_ad_accounts?fields=adspixels{name}`, axiosConfig);

            formik.setFieldValue('pixel', '');
            if (values.data.data.length < 1) {
                setPixelOptions([]);
            } else {
                setPixelOptions(
                    values.data.data
                        .map((ads: any) => ads?.adspixels?.data?.map((pixel: any) => ({ id: pixel.id, name: pixel.name })))
                        .flat()
                        .filter((pixel: any) => pixel !== undefined)
                );
            }
        } catch (err) {
            console.error(err);
            setPixelOptions([]);
        }
    };

    const fetchInstagramAccounts = async (pageId: string, accessToken?: string) => {
        if (accessToken === undefined) {
            setInstagramOptions([]);
        }
        try {
            const values = await axios(`/${pageId}/instagram_accounts?fields=id,username`, {
                ...axiosConfig,
                params: { access_token: accessToken }
            });

            formik.setFieldValue('instagram', '');
            if (values.data.data.length < 1) {
                setInstagramOptions([]);
            } else {
                setInstagramOptions(
                    values.data.data.map((element: any) => ({
                        id: element.id,
                        name: element.username
                    }))
                );
            }
        } catch (e) {
            console.error(e);
            setInstagramOptions([]);
        }
    };

    React.useEffect(() => {
        fetchBusinesses();
    }, []);

    const onBusinessChanged = (value: LabelsType) => {
        fetchPixels(value.id);
        fetchCatalogs(value.id);
        fetchAdAccounts(value.id);
        fetchPages(value.id);
    };

    const onPageChanged = (value: LabelsType) => {
        const accessToken = accessTokenMap.get(value.id);
        fetchInstagramAccounts(value.id, accessToken);
    };

    const OptionalSelector = ({ titleId, choices, formikFieldName, handleValue }: OptionalSelectorProps) => {
        if (choices.length < 1) {
            return null;
        }

        return (
            <Grid key={`selector-${formikFieldName}`} item xs={12} sm={6}>
                <Typography
                    mb={2}
                    sx={{
                        fontWeight: 500,
                        fontFamily: 'Inter',
                        color: theme.palette.grey[400],
                        fontSize: '14px'
                    }}
                >
                    <FormattedMessage id={titleId} />
                </Typography>
                <SelectCustom
                    labels={choices}
                    value={(formik.values as any)[formikFieldName]}
                    setValue={(value: string) => formik.setFieldValue(formikFieldName, value)}
                    handleValue={handleValue}
                />

                {formikFieldName === 'instagram' ? (
                    <Box mt={1} sx={{ display: 'flex' }}>
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontFamily: 'Inter',
                                color: theme.palette.grey[300],
                                fontSize: '14px',
                                ml: '5px'
                            }}
                        >
                            <span className="spanFacebook">
                                <FormattedMessage id="missing_your_instagram_account" />
                            </span>
                            Make sure to connect your Instagram Account to one of your pages.{' '}
                            <Button
                                sx={{
                                    p: 0,
                                    textDecoration: 'underline',
                                    fontSize: '14px',
                                    color: theme.palette.grey[300],
                                    fontWeight: 400,
                                    fontFamily: 'Inter',
                                    mt: '-2px'
                                }}
                                onClick={handleOpenDialogHelp}
                                className="colorLink"
                            >
                                Click here
                            </Button>{' '}
                            to learn how
                        </Typography>
                    </Box>
                ) : null}
            </Grid>
        );
    };

    return (
        <Box sx={{ height: { xs: '100%', sm: '100vh' }, position: 'relative' }}>
            <Box sx={{ p: { xs: '24px', sm: '95px' } }}>
                <form onSubmit={formik.handleSubmit}>
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontFamily: 'Inter',
                            color: theme.palette.grey[400],
                            fontSize: '16px'
                        }}
                    >
                        <FormattedMessage id="facebook_assets" />
                    </Typography>
                    <Link to="#">
                        <Typography
                            mt={2}
                            sx={{
                                fontWeight: 500,
                                fontFamily: 'Inter',
                                color: theme.palette.grey[400],
                                fontSize: '14px',
                                maxWidth: '250px'
                            }}
                        >
                            <FormattedMessage id="facebook_assets_desc" />
                        </Typography>
                    </Link>
                    <Divider sx={{ m: '20px 0 30px 0' }} />
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Typography
                                mb={2}
                                sx={{
                                    fontWeight: 500,
                                    fontFamily: 'Inter',
                                    color: theme.palette.grey[400],
                                    fontSize: '14px'
                                }}
                            >
                                <FormattedMessage id="choose_business_account" />
                            </Typography>
                            <SelectCustom
                                labels={businessOptions}
                                value={formik.values.business}
                                setValue={(value: string) => formik.setFieldValue('business', value)}
                                handleValue={onBusinessChanged}
                            />
                        </Grid>
                        <OptionalSelector titleId="choose_page" choices={pageOptions} formikFieldName="page" handleValue={onPageChanged} />
                        <OptionalSelector titleId="choose_ad_account" choices={adAccountOptions} formikFieldName="ad" />

                        <OptionalSelector titleId="choose_pixel" choices={pixelOptions} formikFieldName="pixel" />
                        <OptionalSelector titleId="choose_catalog" choices={catalogOptions} formikFieldName="catalog" />
                        <OptionalSelector titleId="choose_instagram_account" choices={instagramOptions} formikFieldName="instagram" />
                    </Grid>
                    <Divider sx={{ m: '20px 0 30px 0' }} />
                    <Box sx={{ width: { xs: '100%', sm: '180px' } }}>
                        <ButtonCustom onClick={formik.handleSubmit} colorBtn="red" titleBtn={<FormattedMessage id="confirm_access" />} />
                    </Box>
                </form>
            </Box>
            <Box sx={{ position: { xs: 'relative', sm: 'absolute' }, bottom: { xs: '', sm: '20px' }, width: '100%' }}>
                <Typography
                    sx={{
                        fontWeight: 500,
                        fontFamily: 'Inter',
                        color: theme.palette.grey[600],
                        fontSize: '14px',
                        textAlign: { xs: 'center', sm: 'right' },
                        mr: { xs: '0', sm: '20px' },
                        mb: { xs: '30px', sm: 0 }
                    }}
                >
                    © 2022 Snikpic SRL - VAT BE0729.842.450
                </Typography>
            </Box>
            <HelpDialog openDialogHelp={openDialogHelp} handleCloseDialogHelp={handleCloseDialogHelp} />
        </Box>
    );
}
