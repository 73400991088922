import { useState } from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AutoCompleteClients } from './autocomplete-clients';
import { ProjectMemberType } from 'types/clients';

interface LabelType {
    title: string;
    src?: string;
}

interface MemberBarProps {
    members: ProjectMemberType[];
    membersAvailable: ProjectMemberType[];
    onAddMember?: (member: ProjectMemberType) => void;
}
export const MemberBar = ({ members, membersAvailable, onAddMember }: MemberBarProps) => {
    console.log('In MemberBar, membersAvailable: ', membersAvailable);
    const [pendingValue, setPendingValue] = useState<LabelType[]>([]);
    const theme = useTheme();

    const availables: ProjectMemberType[] = membersAvailable.filter((member: ProjectMemberType) =>
        members.every((mem: ProjectMemberType) => mem.orgMemberId !== member.orgMemberId)
    );

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {members.map(
                (member: ProjectMemberType, idx: number) =>
                    idx < 3 && (
                        <Box key={idx + 1} sx={{ width: '32px', mr: idx + 1 > 0 ? '-12px' : 0 }}>
                            <Avatar sx={{ width: '32px', height: '32px' }} alt="user-avatar" src={member.icon} />
                        </Box>
                    )
            )}
            {members.length > 3 && (
                <Box
                    sx={{
                        background: theme.palette.grey[700],
                        borderRadius: '100px',
                        width: '32px',
                        zIndex: 2,
                        border: `2px solid ${theme.palette.background.paper}`,
                        p: '5px 0 0 4px'
                    }}
                >
                    <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600 }}>
                        {`+${members.length - 3}`}
                    </Typography>
                </Box>
            )}
            {membersAvailable.length > 0 && !!onAddMember && (
                <>
                    <Box sx={{ borderRight: `1px solid ${theme.palette.grey[500]}`, m: '0 5px' }} />
                    <AutoCompleteClients
                        labels={availables}
                        pendingValue={pendingValue}
                        setPendingValue={setPendingValue}
                        handleClickAutoCompleteItem={onAddMember}
                        placeholder="start_typing_team_member_name"
                    />
                </>
            )}
        </Box>
    );
};
