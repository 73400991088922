import { TextField } from '@mui/material';
import React from 'react';

interface IIndex {
    numberOfDays: number;
    setNumberOfDays: (value: number) => void;
}

const Index = ({ numberOfDays, setNumberOfDays }: IIndex) => {
    const handleDurationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNumberOfDays(Number(event.target.value));
    };

    return (
        <>
            <TextField
                sx={{
                    '.MuiOutlinedInput-input': { background: '#fff' }
                }}
                id="due-days"
                label="Task duration in days*"
                value={numberOfDays}
                onChange={handleDurationChange}
                type="number"
            />
        </>
    );
};

export default Index;
