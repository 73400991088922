import React, { ErrorInfo, ReactNode } from 'react';
import ServerError from 'views/pages/others/500';

interface Props {
    children?: ReactNode;
}

interface State {
    error: string;
}
class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            error: ''
        };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({ error: error.message });
    }

    render() {
        const { children } = this.props;
        const { error } = this.state;
        if (error) {
            return <ServerError error={error} />;
        }

        return children;
    }
}

export default ErrorBoundary;
