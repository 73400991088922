import { createContext } from 'react';

interface DocumentBuilderProps {
    documentBuilder: any;
    setDocumentBuilder: any;
    pageIndex: number;
    setPageIndex: (arg: number) => void;
    addPage: any;
    updatePage: any;
    removePage: any;
    duplicatePage: any;
    addSection: any;
    updateSection: any;
    removeSection: any;
    moveSection: any;
    duplicateSection: any;
    findSection: any;
    addField: any;
    removeField: any;
    moveField: any;
    findField: any;
    changeFieldType: any;
    updateFieldName: any;
    updateFieldValue: any;
    loading: Boolean;
    globalEdges?: any;
    saveChanges: any;
    workflowIsBeingEdited: boolean;
}

const DocumentBuilderContext = createContext({} as DocumentBuilderProps);

export default DocumentBuilderContext;
