import { Box, Button, CardMedia } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import deleteNode from 'assets/images/deleteNode.svg';

interface ButtonDeleteNodeProps {
    onClick: () => void;
    className: string;
}

const ButtonDeleteNode = ({ onClick, className }: ButtonDeleteNodeProps) => {
    const theme = useTheme();

    return (
        <Button
            className={className}
            onClick={onClick}
            sx={{ boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)', borderRadius: '8px', minWidth: '10px' }}
        >
            <Box sx={{ width: '18px' }}>
                <CardMedia component="img" width="100%" image={deleteNode} alt="alt image" />
            </Box>
        </Button>
    );
};

export default ButtonDeleteNode;
