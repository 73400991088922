import { Typography, Box, Grid, Avatar, Divider } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import { VirtualEmployeeData } from '../feature/VirtualEmployeeCard';
import { Button } from 'ui-component/extended/Button';

interface VirtualEmployeeProfileProps {
    data: VirtualEmployeeData;
}

const VirtualEmployeeProfile = ({ data }: VirtualEmployeeProfileProps) => {
    const theme = useTheme();

    const handleClick = () => {
        window.open('https://t.me/virtual_employee_test2_bot?start=ve__a1314762-a7c2-48f6-aa01-b2346bff08b8', '_blank');
    };

    return (
        <Box>
            <Box>
                <Typography
                    mt={2}
                    mb={3}
                    sx={{
                        textTransform: 'capitalize',
                        color: theme.palette.grey[400],
                        fontSize: '24px',
                        fontWeight: 500,
                        fontFamily: 'Inter'
                    }}
                >
                    <FormattedMessage id="profile" />
                </Typography>
                <Grid container justifyContent="space-between">
                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar sx={{ width: '98px', height: '98px' }} alt="Remy Sharp" src={data.profile_picture} />
                        <Box ml={3}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 600, fontSize: '24px' }}>
                                    {data.first_name}
                                </Typography>
                            </Box>
                            <Typography
                                sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500, fontSize: '16px', mt: 1 }}
                            >
                                {data.role}
                            </Typography>

                            <Button sx={{ mt: 1 }} onClick={handleClick} title={`Start chatting with ${data.first_name}`} />
                        </Box>
                    </Grid>
                    {/* {role !== 'freelancer' && (
                        <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                variant="secondary"
                                sx={{ width: '102px', mr: 2 }}
                                onClick={() => console.log(1)}
                                title={<FormattedMessage id="messageOne" />}
                            />
                            <Button
                                sx={{ width: '140px' }}
                                onClick={handleOpenSelectPackageDrawer}
                                title={<FormattedMessage id="send_proposal" />}
                            />
                        </Grid>
                    )} */}
                </Grid>

                <Typography
                    sx={{
                        color: '#2D2D2D',
                        fontFamily: 'Inter',
                        fontWeight: 600,
                        fontSize: '18px',
                        mt: 5
                    }}
                >
                    <FormattedMessage id="skills" />
                </Typography>

                <Divider sx={{ mt: 2, mb: 2 }} />

                <Grid container spacing={1}>
                    {data?.tags?.split(',').map((tag: string, index: number) => (
                        <Grid item key={index}>
                            <Typography
                                sx={{
                                    color: theme.palette.grey[300],
                                    fontFamily: 'Inter',
                                    fontWeight: 600,
                                    fontSize: '14px',
                                    background: theme.palette.grey[700],
                                    p: '6px 8px',
                                    borderRadius: '4px'
                                }}
                            >
                                {tag}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
                <Typography
                    sx={{
                        color: '#2D2D2D',
                        fontFamily: 'Inter',
                        fontWeight: 600,
                        fontSize: '18px',
                        mt: 5
                    }}
                >
                    <FormattedMessage id="description" />
                </Typography>

                <Divider sx={{ mt: 2, mb: 2 }} />

                <Typography
                    sx={{
                        color: theme.palette.grey[400],
                        fontFamily: 'Inter',
                        fontWeight: 400,
                        fontSize: '15px',
                        maxWith: '620px'
                    }}
                >
                    {data.bio
                        .replace(/\/n/g, '\n')
                        .split('\n')
                        .map((item, key) => (
                            <>
                                <br />
                                <p key={key}>{item}</p>
                            </>
                        ))}
                </Typography>
            </Box>
        </Box>
    );
};

export default VirtualEmployeeProfile;
