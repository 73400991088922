import { graphql } from '../codegen/gql';

const CREATE_SECTION_COMMENT = graphql(`
    mutation CREATE_SECTION_COMMENT(
        $content: String
        $page_index: Int
        $processmaker_process_request_id: Int
        $section_id: uuid
        $section_index: Int
        $page_id: uuid
        $page_name: String
        $section_name: String
    ) {
        insert_workflow_section_comments_one(
            object: {
                content: $content
                page_index: $page_index
                processmaker_process_request_id: $processmaker_process_request_id
                section_id: $section_id
                section_index: $section_index
                page_id: $page_id
                page_name: $page_name
                section_name: $section_name
            }
        ) {
            client_organization_author
            client_organization_resolver
            content
            created_at
            created_by
            id
            page_index
            processmaker_process_request_id
            resolved_at
            resolved_by
            section_id
            section_index
            status
            updated_at
            page_name
            page_id
            section_name
        }
    }
`);

const UPDATE_SECTION_COMMENT = graphql(`
    mutation UPDATE_SECTION_COMMENT_STATUS($id: uuid!, $status: workflow_section_comment_status_enum, $content: String) {
        update_workflow_section_comments_by_pk(pk_columns: { id: $id }, _set: { status: $status, content: $content }) {
            id
            status
            content
        }
    }
`);

const GET_ALL_SECTION_COMMENTS = graphql(`
    query GET_ALL_SECTION_COMMENTS($processmaker_process_request_id: Int!, $status: [workflow_section_comment_status_enum!]) {
        workflow_section_comments(
            where: { processmaker_process_request_id: { _eq: $processmaker_process_request_id }, status: { _in: $status } }
            order_by: { created_at: desc }
        ) {
            id
            page_id
            page_index
            page_name
            section_id
            section_index
            section_name
            processmaker_process_request_id
            resolved_at
            resolved_by
            created_by
            created_at
            content
            client_organization_resolver
            client_organization_author
            author {
                id
                image_url
                last_name
                first_name
                organization_members {
                    title
                }
            }
        }
    }
`);

// eslint-disable-next-line import/prefer-default-export
export { CREATE_SECTION_COMMENT, UPDATE_SECTION_COMMENT, GET_ALL_SECTION_COMMENTS };
