// React
import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

// Libraries
import { Autocomplete, TextField, Alert, Box, Typography, Stack, Skeleton } from '@mui/material';

// Utils & components
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'utils/snackbar.utils';

// Assets
import { ReactComponent as AiToolsIcon } from 'assets/images/aitools.svg';

// Services
import { updateChannelState } from '../../../../../services/rest/chat-bot';
import { getClients } from '../../../../../services/rest/client_organizations';
import { getEmployees, getTasks } from '../../../../../services/rest/virtual-employee';

export const VirtualEmployeeToolbar = (props: {
    activeChannelId: string;
    currentVirtualEmployeeId: string;
    currentBrandId?: string;
    currentTaskId?: string;
}) => {
    const { activeChannelId, currentBrandId, currentTaskId, currentVirtualEmployeeId } = props;

    // Global state & context
    // const { formatMessage } = useIntl();
    const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
    const { member } = useAuth();
    const intl = useIntl();

    // Local state
    // Admin
    const [isLoading, setIsLoading] = useState(false);
    // Clients
    const [clients, setClients] = useState<{ id: string; label: string }[]>([]);
    const [selectedClient, setSelectedClient] = useState<string | null>(currentBrandId || null);
    // Tasks
    const [tasks, setTasks] = useState<{ id: string; label: string }[]>([]);
    const [selectedTask, setSelectedTask] = useState<string | null>(currentTaskId || null);
    // Employees
    const [employees, setEmployees] = useState<{ id: string; label: string }[]>([]);
    const [selectedEmployee, setSelectedEmployee] = useState<string>(currentVirtualEmployeeId);
    // console.log('In local state - employees: ', employees);

    // Data fetching
    const initializeClients = async () => {
        const response = member?.organizationId ? await getClients({ organizationId: member?.organizationId }) : { data: [] }; // TODO Max: discuss with Anouar what's the best way to do this
        const clientsFormatted = response.data.map((client: any) => ({
            id: client.id,
            label: client.name
        }));
        setClients(clientsFormatted);
    };

    const initializeEmployees = async () => {
        const response = await getEmployees();
        const employeesFormatted = response.data.map((item: any) => ({
            id: item.id,
            label: item.role
        }));
        setEmployees(employeesFormatted);
    };

    const initializeTasks = async (arg: { employeeId: string }) => {
        setIsLoading(true);
        const response = await getTasks({ employeeId: selectedEmployee });
        const tasksFormatted = response.data.map((task: any) => ({
            id: task.id,
            label: task.action
        }));
        setTasks(tasksFormatted);
        setIsLoading(false);
    };

    const initialize = async () => {
        setIsLoading(true);
        await initializeClients();
        await initializeEmployees();
        // await initializeTasks();
        setIsLoading(false);
    };

    // useEffects
    useEffect(() => {
        initialize();
    }, []);

    // Update list of tasks whenever employee is changed
    useEffect(() => {
        initializeTasks({ employeeId: selectedEmployee });
    }, [selectedEmployee]);

    // Handlers
    const handleSelectBrand = async (arg: { brandId: string | null }) => {
        const { brandId } = arg;
        if (activeChannelId) {
            try {
                setSelectedClient(brandId || null);
                await updateChannelState({
                    channelId: activeChannelId || '',
                    virtualEmployeeId: currentVirtualEmployeeId,
                    brandId: brandId || null,
                    taskId: selectedTask || null
                });
                showSuccessSnackbar({ message: 'chatbot_new_session_started' });
            } catch (e) {
                showErrorSnackbar({ message: 'error' });
            }
        }
    };

    const handleSelectTask = async (arg: { taskId: string | null }) => {
        const { taskId } = arg;
        if (activeChannelId) {
            try {
                setSelectedTask(taskId || null);
                await updateChannelState({
                    channelId: activeChannelId || '',
                    virtualEmployeeId: currentVirtualEmployeeId,
                    taskId: taskId || null,
                    brandId: selectedClient || null
                });
                showSuccessSnackbar({ message: 'chatbot_new_session_started' });
            } catch (e) {
                showErrorSnackbar({ message: 'error' });
            }
        }
    };

    const handleSelectEmployee = async (arg: { employeeId: string }) => {
        const { employeeId } = arg;
        if (activeChannelId) {
            try {
                setSelectedEmployee(employeeId);
                await updateChannelState({
                    channelId: activeChannelId || '',
                    virtualEmployeeId: employeeId,
                    taskId: null, // If use changes employee - task is reset
                    brandId: selectedClient || null
                });
                showSuccessSnackbar({ message: 'chatbot_new_session_started' });
            } catch (e) {
                showErrorSnackbar({ message: 'error' });
            }
        }
    };

    // Return
    if (isLoading) {
        // return <Skeleton variant="rounded" width={210} height={60} />;
        return <Skeleton variant="rounded" animation="wave" sx={{ m: 2 }} height={90} />;
    }
    return (
        <>
            {/* TODO Max: take the color code bellow (#F8EAE8) from some theme file */}
            <Alert severity="info" icon={<AiToolsIcon fontSize="inherit" />} sx={{ m: 2, background: '#F8EAE8' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {employees.length > 0 ? (
                        <Autocomplete
                            id="select-employee-autocomplete"
                            disablePortal
                            size="small"
                            sx={{
                                width: `${employees[employees.findIndex((x) => x.id === selectedEmployee)]?.label?.length + 10 || '30'}ch`
                            }} // With of the field is either the width of its content or 30 characters
                            value={employees[employees.findIndex((x) => x.id === selectedEmployee)] || null} // Value can't be just the id; it has to be the object
                            options={employees}
                            isOptionEqualToValue={(option, value) => option.id === value.id} // Added because of https://stackoverflow.com/questions/61947941/
                            // renderInput={(params) => <TextField {...params} variant="standard" label="Task" />}
                            renderInput={(params) => <TextField {...params} placeholder="Virtual employee" />}
                            onChange={(event, newInputValue) => {
                                handleSelectEmployee({ employeeId: newInputValue?.id || '1ef42e06-6010-4181-a9ca-e9291e3054a7' }); // ID of the "Assistant" is no employee is selected - TODO Max: put in env or constant
                            }}
                        />
                    ) : null}
                    &nbsp;<Typography>&#8226;</Typography>&nbsp;
                    {tasks.length > 0 ? (
                        <Autocomplete
                            id="select-task-autocomplete"
                            disablePortal
                            size="small"
                            sx={{ width: `${tasks[tasks.findIndex((x) => x.id === selectedTask)]?.label?.length + 10 || '30'}ch` }} // With of the field is either the width of its content or 30 characters
                            value={tasks[tasks.findIndex((x) => x.id === selectedTask)] || null} // Value can't be just the id; it has to be the object
                            options={tasks}
                            isOptionEqualToValue={(option, value) => option.id === value.id} // Added because of https://stackoverflow.com/questions/61947941/
                            // renderInput={(params) => <TextField {...params} variant="standard" label="Task" />}
                            renderInput={(params) => <TextField {...params} placeholder="Task" />}
                            onChange={(event, newInputValue) => {
                                handleSelectTask({ taskId: newInputValue?.id || null });
                            }}
                        />
                    ) : (
                        <Typography variant="overline">{intl.formatMessage({ id: 'vtm_no_specific_task_available' })}</Typography>
                    )}
                    &nbsp;<Typography>&#8226;</Typography>&nbsp;
                    {clients.length > 0 ? (
                        <Autocomplete
                            id="select-client-autocomplete"
                            disablePortal
                            size="small"
                            sx={{ width: `${clients[clients.findIndex((x) => x.id === selectedClient)]?.label?.length + 7 || '30'}ch` }} // With of the field is either the width of its content or 30 characters
                            value={clients[clients.findIndex((x) => x.id === selectedClient)] || null} // Value can't be just the id; it has to be the object
                            options={clients}
                            isOptionEqualToValue={(option, value) => option.id === value.id} // Added because of https://stackoverflow.com/questions/61947941/
                            // renderInput={(params) => <TextField {...params} variant="standard" label="Client" />}
                            renderInput={(params) => <TextField {...params} placeholder="Brand" />}
                            onChange={(event, newInputValue) => {
                                handleSelectBrand({ brandId: newInputValue?.id || null });
                            }}
                        />
                    ) : null}
                </Box>
                <Box>
                    <Stack spacing={-1.5}>
                        {!selectedTask && (
                            <Typography variant="caption" sx={{ pt: 1 }}>
                                {intl.formatMessage({ id: 'vtm_toolbar_no_task_selected_info' })}
                            </Typography>
                        )}
                        {!selectedClient && (
                            <Typography variant="caption" sx={{ pt: 1 }}>
                                {intl.formatMessage({ id: 'vtm_toolbar_no_brand_selected_info' })}
                            </Typography>
                        )}
                    </Stack>
                </Box>
            </Alert>
        </>
    );
};
