import { Box, Button, Grid, Typography, ClickAwayListener, TextField, stepConnectorClasses } from '@mui/material';
import { FC, ReactElement, useContext, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';
import ProcessItem from './subcomponents/processItem';
import { ReactComponent as FileIcon } from 'assets/images/file.svg';
import { ReactComponent as FlowIcon } from 'assets/images/Flow.svg';
import { ReactComponent as SendIcon } from 'assets/images/send.svg';
import { ReactComponent as CheckIcon } from 'assets/images/check.svg';
import { ContentType } from '../types';
import DocumentBuilderContent from './documentBuilderContent';
import PublishDocument from '../workflow-publish';
import DocumentBuilderContext from './context/workflow.context';
import WorkflowNew from '../workflow-builder/WorkflowNew';
import { ClientDetailsContext } from 'views/pages/clients/context/client-details.context';
import { UPDATE_WORKFLOW_NAME } from 'services/graphQL/mutations/workflow';
import { useMutation } from '@apollo/client';

type ProcessStatus = {
    titleId: string;
    icon: ReactElement;
    type: ContentType;
};

const ProcessItems: ProcessStatus[] = [
    {
        titleId: 'document',
        icon: <FileIcon />,
        type: ContentType.DOCUMENT_BUILDER
    },
    {
        titleId: 'workflow',
        icon: <FlowIcon />,
        type: ContentType.WORKFLOW_BUILDER
    },
    {
        titleId: 'publish',
        icon: <SendIcon />,
        type: ContentType.PUBLISH
    }
];
interface CreateNewProps {
    closeDialog: () => void;
}
const CreateNew: FC<CreateNewProps> = ({ closeDialog }) => {
    const theme = useTheme();

    const { documentBuilder, setDocumentBuilder } = useContext(DocumentBuilderContext);

    const [curContent, setCurContent] = useState<ContentType>(ContentType.DOCUMENT_BUILDER);
    const [isEditable, setIsEditable] = useState(false);

    const [updateWorkflowName] = useMutation(UPDATE_WORKFLOW_NAME);

    const handleOnNext = () => {
        setCurContent((cur) => cur + 1);
    };

    const handleOnPrev = () => {
        setCurContent((cur) => cur - 1);
    };

    const handleSetIsEditable = () => {
        setIsEditable(!isEditable);
    };

    const handleSetChangeTitle = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setDocumentBuilder({ ...documentBuilder, workflowName: e.target.value });
    };

    const saveTitle = async () => {
        if (documentBuilder?.workflowId) {
            await updateWorkflowName({
                variables: {
                    id: documentBuilder.workflowId,
                    name: documentBuilder.workflowName
                }
            });
        }
    };
    return (
        <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            <Grid container justifyContent="space-between" alignItems="center" sx={{ p: '14px 20px' }}>
                <Grid item xs={4}>
                    {isEditable ? (
                        <ClickAwayListener
                            onClickAway={() => {
                                setIsEditable(false);
                                saveTitle();
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <TextField
                                    sx={{
                                        width: '498px',
                                        '.MuiOutlinedInput-input': {
                                            color: theme.palette.grey[300],
                                            fontWeight: 400,
                                            fontFamily: 'Inter',
                                            fontSize: '14px'
                                        }
                                    }}
                                    value={documentBuilder?.workflowName}
                                    onChange={handleSetChangeTitle}
                                    variant="outlined"
                                    fullWidth
                                />
                                <CheckIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setIsEditable(false);
                                        saveTitle();
                                    }}
                                />
                            </Box>
                        </ClickAwayListener>
                    ) : (
                        <Typography
                            onClick={handleSetIsEditable}
                            ml={1}
                            sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500, fontSize: '16px' }}
                        >
                            {documentBuilder?.workflowName}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '32px' }}>
                        {ProcessItems.map((process, index) => (
                            <Box key={index}>
                                <ProcessItem
                                    key={index}
                                    index={index}
                                    active={curContent >= index}
                                    titleId={process.titleId}
                                    icon={process.icon}
                                />
                            </Box>
                        ))}
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button sx={{ color: '#9197AE' }} onClick={closeDialog}>
                            <CloseIcon sx={{ fontSize: '20px', fill: '#9197AE' }} />
                            <FormattedMessage id="close" />
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                {curContent === ContentType.DOCUMENT_BUILDER && <DocumentBuilderContent onNext={handleOnNext} />}
            </Box>
            {curContent === ContentType.WORKFLOW_BUILDER && <WorkflowNew onBack={handleOnPrev} onNext={handleOnNext} />}
            {curContent === ContentType.PUBLISH && <PublishDocument onBack={handleOnPrev} closeModal={closeDialog} />}
        </Box>
    );
};

export default CreateNew;
