import { Typography, useTheme } from '@mui/material';
import { RowBody } from 'views/pages/clients/features/detail/workflow/features/document-builder/ui';

export const RequiredTag = () => {
    const theme = useTheme();
    return (
        <RowBody
            sx={{
                justifyContent: 'center',
                width: '84px',
                height: '24px',
                borderRadius: '16px',
                backgroundColor: theme.palette.text.secondary
            }}
        >
            <Typography variant="subtitle2" sx={{ color: theme.palette.grey[700] }}>
                Required
            </Typography>
        </RowBody>
    );
};
