import { Box, Drawer, Typography, Grid, Input, TextField } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import { Button } from 'ui-component/extended/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React from 'react';
// import { useLazyQuery, useMutation } from '@apollo/client';
// import DescriptionInputAiTool from 'modules/ai-tools/DescriptionInputAiTool';
// import { ADD_CAPTION_EXAMPLE, EDIT_CAPTION_EXAMPLE } from 'services/graphQL/mutations/aiTools/AiToolsMutations';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import { useDispatch } from 'store';
// import { openSnackbar } from 'store/slices/snackbar';
// import { GET_CAPTION_EXAMPLE_SUBCATEGORIES } from 'services/graphQL/queries/aiTool';
// import { useParams } from 'react-router-dom';

import { InputOutputCardProps } from './InputOutputCard';

// Services
import { createUpdateDeleteIoExample } from '../../../../../../services/rest/virtual-employee';

// export interface CaptionGeneratorCardType {
//     subcategory_id: string;
//     client_organization_id: string;
//     caption: string;
//     concept: string;
//     id: string;
// }

// interface DrawerAddOffersProps {
//     open: boolean;
//     onClose: () => void;
//     item?: InputOutputCardProps;
//     getCaptions?: any;
//     refetchGetCaptions?: any;
// }

export interface Subcategory {
    name: string;
    category_id?: string;
}

interface InputOutputEditorProps {
    open: boolean;
    onClose: () => void;
    getFinetuning: () => void;
    item?: InputOutputCardProps;
    // getCaptions?: any;
    // refetchGetCaptions?: any;
    finetuningId: string;
}

const InputOutputEditor = (props: InputOutputEditorProps) => {
    // console.log('In InputOutputEditor');
    // console.log('props: ', props);
    const { open, onClose, item, getFinetuning, finetuningId } = props;

    const theme = useTheme();
    // const intl = useIntl();
    // const dispatch = useDispatch();
    // const showError = false;
    // const [selectedLabel, setSelectedLabel] = React.useState<Subcategory | null>(null);
    // const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
    // const [subcategories, setSubcategories] = React.useState<Subcategory[]>([]);
    // const [descriptionValid, setDescriptionValid] = React.useState(false);
    // const { idClient } = useParams();

    // const openPopover = (event: React.MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget);
    // const handleClose = () => setAnchorEl(null);

    // const handleSelectedLabel = (e: React.MouseEvent<HTMLDivElement>, value: Subcategory) => {
    //     e.stopPropagation();
    //     setSelectedLabel(value);
    //     handleClose();
    // };
    // const id = anchorEl ? 'simple-popover' : undefined;

    const [isLoading, setIsLoading] = React.useState(false);

    const formik = useFormik({
        enableReinitialize: true, // Important to get initial values from props
        initialValues: {
            input: item?.input || '',
            output: item?.output || ''
        },
        validationSchema: Yup.object({
            input: Yup.string().trim().required('Required'),
            output: Yup.string().trim().required('Required')
        }),
        onSubmit: async (values) => {
            setIsLoading(true);
            if (item) {
                await createUpdateDeleteIoExample({
                    finetuningId: item.finetuningId,
                    action: 'update',
                    index: item.index,
                    inputOutputExample: {
                        input: values.input,
                        output: values.output
                    }
                });
                await getFinetuning();
                onClose();
            } else {
                if (finetuningId) {
                    // console.log('about to create finetuning in the form');
                    await createUpdateDeleteIoExample({
                        finetuningId,
                        action: 'create',
                        inputOutputExample: {
                            input: values.input,
                            output: values.output
                        }
                    });
                } else {
                    // ...
                }
                await getFinetuning();
                onClose();
            }
            formik.resetForm();
            setIsLoading(false);
        }
    });

    return (
        <Drawer anchor="right" open={open} onClose={onClose}>
            <Box sx={{ width: { xs: '100%', sm: '562px' } }}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container direction="column" justifyContent="space-between" sx={{ minHeight: '100vh' }}>
                        <Grid item sx={{ p: '32px' }} xs={10}>
                            <Typography variant="h4">
                                <FormattedMessage id="example_input_output" />
                            </Typography>
                            <TextField
                                error={Boolean(formik.touched.input && formik.errors.input)}
                                fullWidth
                                helperText={formik.touched.input && formik.errors.input}
                                label="Input"
                                margin="normal"
                                name="input"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type="text"
                                value={formik.values.input}
                                variant="outlined"
                                multiline
                                rows={3}
                            />
                            <TextField
                                error={Boolean(formik.touched.output && formik.errors.output)}
                                fullWidth
                                helperText={formik.touched.output && formik.errors.output}
                                label="Output"
                                margin="normal"
                                name="output"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type="text"
                                value={formik.values.output}
                                variant="outlined"
                                multiline
                                rows={10}
                            />
                        </Grid>
                        <Grid
                            xs={2}
                            item
                            container
                            justifyContent="flex-end"
                            alignItems="flex-end"
                            sx={{ borderTop: `1px solid ${theme.palette.grey[500]}`, p: '32px' }}
                        >
                            <Box sx={{ display: 'flex' }}>
                                <Box mr={2} sx={{ width: '95px' }}>
                                    <Button title={<FormattedMessage id="cancel" />} onClick={onClose} variant="secondary" />
                                </Box>
                                <Box sx={{ width: '144px' }}>
                                    <Button
                                        onClick={formik.handleSubmit}
                                        title={<FormattedMessage id="save_changes" />}
                                        loading={isLoading}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Drawer>
    );
};

export default InputOutputEditor;
