import { Box, Drawer, Typography, Grid, Divider } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import LinkShared from './LinkShared';
import { Button } from 'ui-component/extended/Button';
import AddLinkShare from './AddLinkShare';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_SHARED_REPORT_LINKS } from 'services/graphQL/queries/client/clientPerformance';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import {
    DELETE_SHARED_REPORT_LINK,
    ADD_SHARED_REPORT_LINK,
    UPDATE_SHARED_REPORT_EMAIL_RECIPIENTS
} from 'services/graphQL/mutations/client/clientPerformance';
import { LinkType } from './types';
import { useParams } from 'react-router-dom';

interface ShareReportDrawerProps {
    clientOrganizationId: string;
    openDivider: any;
    handleClickCloseDivider: any;
}

const ShareReportDrawer = ({ clientOrganizationId, openDivider, handleClickCloseDivider }: ShareReportDrawerProps) => {
    const theme = useTheme();
    const [addingLink, setAddingLink] = React.useState(false);
    const [reportLinkList, setReportLinkList] = React.useState<LinkType[]>([]);

    const { idClient } = useParams();

    const handleCloseAddingLink = () => setAddingLink(false);
    const handleOpenAddingLink = () => setAddingLink(true);

    const [getSharedReportLinks] = useLazyQuery(GET_SHARED_REPORT_LINKS);
    const [addSharedReportLink] = useMutation(ADD_SHARED_REPORT_LINK);
    const [deleteSharedReportLink] = useMutation(DELETE_SHARED_REPORT_LINK);
    const [updateSharedReportEmailRecipients] = useMutation(UPDATE_SHARED_REPORT_EMAIL_RECIPIENTS);

    const getLinks = async () => {
        let fetchedData: LinkType[];
        try {
            ({
                data: { client_report_set: fetchedData }
            } = await getSharedReportLinks({
                variables: { client_organization_id: idClient }
            }));
            setReportLinkList(fetchedData);
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e.message,
                    variant: 'error'
                })
            );
        }
    };

    const deleteLink = async (id: string) => {
        try {
            await deleteSharedReportLink({
                variables: { id }
            });
            setReportLinkList((current) => current.filter((link) => link.id !== id));
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e.message,
                    variant: 'error'
                })
            );
        }
    };

    const addLink = async (expiresAt: string): Promise<string> => {
        try {
            const {
                data: { insert_client_report_set_one: newLink }
            } = await addSharedReportLink({
                variables: { client_organization_id: clientOrganizationId, expires_at: expiresAt }
            });
            setReportLinkList((current) => [newLink, ...current]);
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'report_link_created',
                    transition: 'SlideDown'
                })
            );
            return newLink.id;
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e.message,
                    variant: 'error'
                })
            );
        }
        return '';
    };

    const addEmailRecipients = async (reportId: string, emailRecipients: string[]) => {
        try {
            await updateSharedReportEmailRecipients({
                variables: { id: reportId, email_recipients: emailRecipients }
            });
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'report_was_shared_by_email',
                    transition: 'SlideDown'
                })
            );
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e.message,
                    variant: 'error'
                })
            );
        }
    };

    React.useEffect(() => {
        getLinks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Drawer anchor="right" open={openDivider} onClose={handleClickCloseDivider}>
            {addingLink ? (
                <AddLinkShare
                    addLink={addLink}
                    handleCloseAddingLink={handleCloseAddingLink}
                    handleShareLinkToEmail={(emailRecipients: string[]) => {
                        addEmailRecipients(reportLinkList[0].id, emailRecipients);
                    }}
                />
            ) : (
                <Box>
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        sx={{ height: { xs: '100%', sm: '100vh' }, width: { xs: '100%', sm: '562px' } }}
                    >
                        <Grid item sm={11} p={3} sx={{ height: { xs: '100%', sm: 'calc(100vh - 100px)' }, overflowY: 'scroll' }}>
                            <Typography
                                sx={{
                                    color: theme.palette.grey[400],
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    fontFamily: 'Inter'
                                }}
                            >
                                <FormattedMessage id="share_report" />
                            </Typography>
                            <Box onClick={handleOpenAddingLink} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', mt: 4 }}>
                                <Box
                                    sx={{
                                        background: theme.palette.grey[800],
                                        width: '32px',
                                        height: '32px',
                                        borderRadius: '100px',
                                        mr: 1
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: theme.palette.grey[400],
                                            fontSize: '26px',
                                            fontFamily: 'Inter',
                                            m: '-3px 0 0 7px'
                                        }}
                                    >
                                        +
                                    </Typography>
                                </Box>
                                <Typography
                                    sx={{
                                        color: theme.palette.grey[300],
                                        fontSize: '14px',
                                        fontWeight: 500,
                                        fontFamily: 'Inter',
                                        m: '-3px 0 0 7px'
                                    }}
                                >
                                    <FormattedMessage id="create_new_link" />
                                </Typography>
                            </Box>
                            <Divider sx={{ m: '20px 0 20px' }} />
                            <Box>
                                {reportLinkList.map((link: LinkType) => (
                                    <LinkShared link={link} handleDeletion={() => deleteLink(link.id)} />
                                ))}
                            </Box>
                        </Grid>
                        <Grid
                            item
                            sm={1}
                            sx={{ borderTop: `1px solid ${theme.palette.grey[500]}`, p: 2, display: 'flex', justifyContent: 'flex-end' }}
                        >
                            <Box sx={{ width: '94px' }}>
                                <Button variant="primary" onClick={handleOpenAddingLink} title={<FormattedMessage id="share" />} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </Drawer>
    );
};

export default ShareReportDrawer;
