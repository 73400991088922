import { Box, Button, ButtonGroup, Dialog, Divider, Popover, TextField, Typography, useTheme } from '@mui/material';
// import { ReactComponent as BranchIcon } from 'assets/images/branch.svg';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import update from 'immutability-helper';
import { useContext, useState } from 'react';
// import { ReactComponent as SquareIcon } from 'assets/images/square.svg';
import { ReactComponent as SaveIcon } from 'assets/images/save.svg';
import { ReactComponent as TextIcon } from 'assets/images/Text.svg';
import { ReactComponent as CopyIcon } from 'assets/images/copy.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/deleteIcon.svg';
import { ReactComponent as VectorIcon } from 'assets/images/vector.svg';
import MenuItem from '../subcomponents/menuItem';
import DialogEditPage from './dialogEditPage';
import DIalogCongirm from 'ui-component/dialogConfirm';
import DocumentBuilderContext from '../context/workflow.context';
import { insertSavedPage } from 'services/rest/workflow';
import { toPagePart, toSnikpicPage } from 'utils/formatters/workflow';
import useAuth from 'hooks/useAuth';
import _ from 'lodash';

interface PageItemProps {
    name?: string;
    onClick: () => void;
    pageIndex: number;
    isSelected?: boolean;
}

const PageItem = ({ name = '', onClick, pageIndex, isSelected = false }: PageItemProps) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
    const [openDialogConfirm, setOpenDialogConfirm] = useState<boolean>(false);
    const { documentBuilder, setDocumentBuilder, removePage, duplicatePage } = useContext(DocumentBuilderContext);

    const extractedPageNumber = documentBuilder.pages[pageIndex]?.pageNumber;
    const pageNumber = _.isFinite(extractedPageNumber) ? extractedPageNumber : 0;

    const handleIncreasePageNumber = () => {
        setDocumentBuilder(
            update(documentBuilder, { pages: { [pageIndex]: { pageNumber: { $set: pageNumber + 1 }, pageVariable: { $set: true } } } })
        );
    };

    const handleDecreasePageNumber = () => {
        if (pageNumber - 1 >= 0) {
            setDocumentBuilder(
                update(documentBuilder, { pages: { [pageIndex]: { pageNumber: { $set: pageNumber - 1 }, pageVariable: { $set: true } } } })
            );
        }
    };

    const { member } = useAuth();

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClickPopover = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseDialogConfirm = () => {
        setOpenDialogConfirm(false);
    };

    const handleDeletePage = () => {
        removePage(pageIndex);
        handleClose();
        handleCloseDialogConfirm();
    };

    const handleDuplicatePage = () => {
        // const newData = { ...documentBuilder.pages[pageIndex], pageName: `${documentBuilder.pages[pageIndex].pageName} (Duplicated)` };
        // const newValues = [...documentBuilder.pages.slice(0, pageIndex + 1), newData, ...documentBuilder.pages.slice(pageIndex + 1)];
        // setDocumentBuilder(update(documentBuilder, { pages: { $set: newValues } }));
        duplicatePage(pageIndex);
        handleClose();
    };

    const handleDeclareVariable = () => {
        const oldValue = documentBuilder.pages[pageIndex].pageVariable;
        setDocumentBuilder(update(documentBuilder, { pages: { [pageIndex]: { pageVariable: { $set: !oldValue } } } }));
        handleClose();
    };

    const handleMakeReusable = () => {
        insertSavedPage({
            name: documentBuilder.pages[pageIndex].pageName,
            content: toPagePart(toSnikpicPage(documentBuilder.pages[pageIndex]), 0),
            organizationId: member?.organizationId ?? ''
        });
        handleClose();
    };

    const handleRename = () => {
        setOpenEditDialog(true);
        handleClose();
    };

    const handleOpenDialogConfirm = () => {
        if (documentBuilder.pages.length < 2) return;
        setOpenDialogConfirm(true);
    };

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
    };

    return (
        <>
            <Box
                sx={{
                    // backgroundColor: isSelected ? theme.palette.orange.light : 'white',
                    padding: '12px 20px',
                    borderBottom: `1px solid ${theme.palette.grey[500]}`,
                    borderTop: pageIndex === 0 ? `1px solid ${theme.palette.grey[500]}` : 'none'
                }}
            >
                <Box
                    sx={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: '4px',

                        cursor: 'pointer'
                    }}
                    onClick={onClick}
                >
                    {/* <BranchIcon style={{ position: 'absolute', top: '-14px', left: '6px', zIndex: 0 }} /> */}
                    {/* {documentBuilder.pages[pageIndex].pageVariable && <SquareIcon />} */}
                    <Box sx={isSelected ? { fontWeight: 'bold' } : {}}>{name}</Box>
                    <MoreHorizIcon sx={{ cursor: 'pointer' }} onClick={handleClickPopover} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <ButtonGroup size="small" aria-label="small outlined button group">
                            <Button onClick={handleDecreasePageNumber}>-</Button>
                            <Button sx={{ backgroundColor: theme.palette.primary.main, color: 'white' }}>{pageNumber}</Button>
                            <Button color="primary" onClick={handleIncreasePageNumber}>
                                +
                            </Button>
                        </ButtonGroup>
                    </Box>
                </Box>
            </Box>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                <Box sx={{ width: '300px' }}>
                    <MenuItem icon={<SaveIcon />} name="Save (Make reusable)" onClick={handleMakeReusable} />
                    {/* <Divider />
                    <MenuItem
                        icon={<VectorIcon />}
                        name={documentBuilder.pages[pageIndex].pageVariable ? 'Remove variable' : 'Declare variable'}
                        onClick={handleDeclareVariable}
                    /> */}
                    <Divider />
                    <MenuItem icon={<TextIcon />} name="Rename" onClick={handleRename} />
                    <Divider />
                    <MenuItem icon={<CopyIcon />} name="Duplicate" onClick={handleDuplicatePage} />
                    <Divider />
                    <MenuItem icon={<DeleteIcon />} name="Delete" onClick={handleOpenDialogConfirm} />
                </Box>
            </Popover>
            <Dialog
                sx={{ '.MuiDialog-paper': { minWidth: '562px' } }}
                open={openEditDialog}
                onClose={handleCloseEditDialog}
                aria-labelledby="edit-page-dialog-title2"
                aria-describedby="edit-page-dialog-description2"
            >
                <DialogEditPage onClose={handleCloseEditDialog} value={documentBuilder.pages[pageIndex].pageName} pageIndex={pageIndex} />
            </Dialog>
            <Dialog
                sx={{ '.MuiDialog-paper': { minWidth: { xs: '350px', sm: '562px' } } }}
                open={openDialogConfirm}
                onClose={handleCloseDialogConfirm}
                aria-labelledby="alert-dialog-title2"
                aria-describedby="alert-dialog-description2"
            >
                <DIalogCongirm onConfirm={handleDeletePage} onClose={handleCloseDialogConfirm} />
            </Dialog>
        </>
    );
};

export default PageItem;
