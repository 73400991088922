import { useState, useEffect } from 'react';
import { Typography, Box, Breadcrumbs, Grid, Avatar, CardMedia, Divider, Rating, Drawer, Dialog } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import imageConfirm from 'assets/images/imageConfirm.svg';
import { Button } from 'ui-component/extended/Button';

interface PackageDetailsDrawerProp {
    open: boolean;
    onClose: any;
    title: string;
}

const DialogConfirm = ({ open, onClose, title }: PackageDetailsDrawerProp) => {
    const theme = useTheme();
    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{
                '.MuiPaper-root': {
                    borderRadius: '16px',
                    width: { xs: '100%', sm: '562px' },
                    p: 4
                }
            }}
        >
            <Box sx={{ with: '267px', m: '0 auto' }}>
                <CardMedia sx={{ objectFit: 'contain' }} component="img" image={imageConfirm} alt="imageConfirm" />
            </Box>
            <Typography
                sx={{
                    color: theme.palette.grey[400],
                    fontFamily: 'Inter',
                    fontWeight: 600,
                    fontSize: '16px',
                    mt: 3,
                    textAlign: 'center'
                }}
            >
                <FormattedMessage id={title} />
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 3 }}>
                <Button sx={{ width: '100px' }} title={<FormattedMessage id="yes_i_do" />} onClick={onClose} />
                <Button sx={{ width: '110px' }} variant="secondary" title={<FormattedMessage id="no_i_dont" />} onClick={onClose} />
            </Box>
        </Dialog>
    );
};

export default DialogConfirm;
