import { MouseEventHandler } from 'react';
import { Grid, Box } from '@mui/material';
import ButtonCustom from 'ui-component/extended/Button';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles'; // Added Max

type AdminPanelFooterProps = {
    buttonText: string;
    onButtonClick: MouseEventHandler<HTMLButtonElement>;
    onCancelButtonClick: MouseEventHandler<HTMLButtonElement>;
};

export const AdminPanelFooter = ({ buttonText, onButtonClick, onCancelButtonClick }: AdminPanelFooterProps) => {
    const theme = useTheme();

    const handleClickCloseDivider = () => {
        console.log('Clicked handleClickCloseDivider');
    };

    const buildFilters = () => {
        console.log('Clicked buildFilters');
    };

    return (
        // <div className="admin-panel__form-footer">
        <div>
            {/* <button type="button">Cancel</button>
            <button type="button" onClick={onButtonClick}>
                {buttonText}
            </button> */}

            <Grid item container direction="column" alignItems="flex-end">
                <Grid item p={2}>
                    <Box sx={{ display: 'flex' }}>
                        <Box mr={2} sx={{ width: '87px' }}>
                            <ButtonCustom onClick={onCancelButtonClick} colorBtn="white" titleBtn={<FormattedMessage id="cancel" />} />
                        </Box>
                        <Box mr={1} sx={{ width: '130px' }}>
                            <ButtonCustom onClick={onButtonClick} colorBtn="red" titleBtn={<FormattedMessage id="save" />} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};
