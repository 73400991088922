// @ts-ignore
import { Box, CardMedia } from '@mui/material';
import { FileIcon, getFileExtensionFromUrl, getFileNameFromUrl, isImage } from 'utils/files.utils';

interface FileCardProps {
    fileUrl: string;
    handleOpenDialog: () => void;
}

export const FileCard = ({ fileUrl, handleOpenDialog }: FileCardProps) => {
    const extension = getFileExtensionFromUrl(fileUrl);
    const fileName = getFileNameFromUrl(fileUrl);

    if (extension && isImage(extension)) {
        return (
            <CardMedia
                sx={{ objectFit: 'cover', borderRadius: '5px', cursor: 'pointer' }}
                component="img"
                onClick={() => handleOpenDialog()}
                width="100%"
                height="100%"
                image={fileUrl}
                alt="alt image"
            />
        );
    }

    return (
        <Box
            sx={{ objectFit: 'cover', borderRadius: '5px', cursor: 'pointer' }}
            onClick={() => handleOpenDialog()}
            width="100%"
            height="100%"
        >
            <FileIcon extension={extension || ''} />
        </Box>
    );
};
