import GuestGuard from './guards/guest-guard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import SignIn from 'views/pages/authentication/features/login';
import Signup from 'views/pages/authentication/features/register';
import ForgotPassword from 'views/pages/authentication/features/forgot-password';
import ResetPassword from 'views/pages/authentication/features/reset-password';

const LoginRoutes = {
    path: '/auth',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/auth/login',
            element: <SignIn />
        },
        {
            path: '/auth/forgotpassword',
            element: <ForgotPassword />
        },
        {
            path: '/auth/resetpasword',
            element: <ResetPassword />
        },
        {
            path: '/auth/signup',
            element: <Signup />
        }
    ]
};

export default LoginRoutes;
