import { gql } from '@apollo/client';
import { DOCUMENT_DATA_FRAGMENT } from 'services/graphQL/fragments/document.fragment';

export const GET_WORKFLOWS = gql`
    query GET_WORKFLOWS($client_organization_id: uuid!) {
        workflow_workflows(where: { client_organization_id: { _eq: $client_organization_id }, status: { _neq: ARCHIVED } }) {
            id
            name
            project {
                name
            }
            status
            active_version {
                id
                reactflow_json
                document_version {
                    id
                    document_id
                    content
                    created_at
                }
            }
        }
    }
`;

export const GET_DOCUMENT_PARTS = gql`
    query SavedDocumentParts($type: String) {
        workflow_saved_document_parts(where: { type: { _eq: $type } }) {
            id
            name
            content
            organization_id
            type
        }
    }
`;

export const UPDATE_WORKFLOW_PROJECT_ID = gql`
    mutation MyMutation($id: uuid!, $project_id: uuid) {
        update_workflow_workflows_by_pk(pk_columns: { id: $id }, _set: { project_id: $project_id }) {
            id
            project_id
        }
    }
`;
