import { Box } from '@mui/material';
import { WFTaskDocumentPermissions } from '@sni4/snikpic-common';
import DocumentDropdown from './components/document-dropdown';

interface DocumentAssignProps {
    documentPermissions: WFTaskDocumentPermissions[];
    setDocumentPermissions: (documentPermissions: WFTaskDocumentPermissions[]) => void;
}

const AssignDocument = ({ documentPermissions, setDocumentPermissions }: DocumentAssignProps) => (
    // const [checked, setChecked] = useState<boolean>(false);
    // const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setChecked(event.target.checked);
    // };
    <Box>
        {/* <AssignDocumentToTaskCheckbox checked={checked} handleCheck={handleCheck} /> */}
        <DocumentDropdown documentPermissions={documentPermissions} setDocumentPermissions={setDocumentPermissions} />
        {/* {checked && (
                <>
                    <AssignFullDocumentToTask />
                </>
            )} */}
    </Box>
);
export default AssignDocument;
