import { Box, Typography, CardMedia, Dialog } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import outline_III from 'assets/images/outline_III.svg';
import { Button } from 'ui-component/extended/Button';

interface DIalogConfirmDeleteProps {
    onClose: any;
    open: boolean;
    title: string;
}

const DIalogConfirmDelete = ({ onClose, open, title }: DIalogConfirmDeleteProps) => {
    const theme = useTheme();

    return (
        <Dialog onClose={onClose} open={open} sx={{ '.MuiPaper-root': { p: { xs: '30px', sm: '30px 80px' } } }}>
            <Box sx={{ maxWidth: '400px', m: '0 auto' }}>
                <CardMedia component="img" image={outline_III} alt="alt image" />
            </Box>
            <Typography
                sx={{
                    color: '#000',
                    fontFamily: 'Inter',
                    fontWeight: 600,
                    fontSize: '16px',
                    textAlign: 'center',
                    mt: 3
                }}
            >
                <FormattedMessage id={title} />
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                <Button sx={{ width: '100px', mr: 1 }} title={<FormattedMessage id="yes_i_do" />} onClick={onClose} />
                <Button variant="secondary" sx={{ width: '110px' }} title={<FormattedMessage id="no_i_dont" />} onClick={onClose} />
            </Box>
        </Dialog>
    );
};

export default DIalogConfirmDelete;
