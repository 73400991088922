import { Select, MenuItem, Typography, FormControl, FormHelperText } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface SelectProps {
    labels: any;
    setValue: any;
    value: any;
    handleValue?: any;
    sx?: { [key: string]: any };
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
}
export interface LabelsType {
    id: string;
    name: string;
}
const SelectCustom = ({ value, setValue, labels, handleValue, sx, disabled = false, error = false, helperText = '' }: SelectProps) => {
    const theme = useTheme();

    return (
        <FormControl error={error}>
            <Select
                sx={{
                    '.MuiOutlinedInput-input': {
                        color: theme.palette.grey[300],
                        fontWeight: 400,
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        ...sx
                    }
                }}
                fullWidth
                value={value}
                onChange={(e) => setValue(e.target.value)}
                disabled={disabled}
            >
                {labels[0]?.name
                    ? labels.map((item: LabelsType) => (
                          <MenuItem
                              onClick={() => {
                                  if (handleValue) {
                                      handleValue(item);
                                  }
                              }}
                              key={item.id}
                              sx={{
                                  borderBottom: `1px solid ${theme.palette.grey[500]}`,
                                  p: '14px 16px'
                              }}
                              value={item.id}
                          >
                              <Typography
                                  sx={{
                                      color: theme.palette.grey[400],
                                      fontFamily: 'Inter',
                                      fontWeight: 500,
                                      fontSize: '14px'
                                  }}
                              >
                                  {item.name}
                              </Typography>
                          </MenuItem>
                      ))
                    : labels.map((item: string) => (
                          <MenuItem
                              key={item}
                              sx={{
                                  borderBottom: `1px solid ${theme.palette.grey[500]}`,
                                  p: '14px 16px'
                              }}
                              value={item}
                          >
                              <Typography
                                  sx={{
                                      color: theme.palette.grey[400],
                                      fontFamily: 'Inter',
                                      fontWeight: 500,
                                      fontSize: '14px'
                                  }}
                              >
                                  {item}
                              </Typography>
                          </MenuItem>
                      ))}
            </Select>
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    );
};

export default SelectCustom;
