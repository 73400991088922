import { Box, Typography, CardMedia, Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from 'react';
import ButtonCustom from 'ui-component/extended/Button';
import fa_solid_robot from 'assets/images/fa_solid_robot.svg';
import bx_category from 'assets/images/bx_category.svg';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import DialogImages from './DialogImages';

interface CaptionGeneratorReultProps {
    handleResults: any;
    history?: boolean;
    resultsValue: any;
}

const ImageGeneratorReults = ({ handleResults, history, resultsValue = [] }: CaptionGeneratorReultProps) => {
    const theme = useTheme();
    const [selectedHistory, setSelectedHistory] = React.useState<any>();
    const [openDialogImages, setOpenDialogImages] = React.useState(false);
    const [selectedImage, setSelectedImage] = React.useState(0);
    const handleOpenDialogImages = (index: number) => {
        setSelectedImage(index);
        setOpenDialogImages(true);
    };
    const prevImage = () => {
        if (selectedImage !== 0) setSelectedImage(selectedImage - 1);
    };
    const nextImage = () => {
        if (selectedImage + 1 !== selectedHistory?.length) setSelectedImage(selectedImage + 1);
    };
    const handleCloseDialogImages = () => setOpenDialogImages(false);
    const download = (e: any) => {
        console.log(e.target.href);
        fetch(e.target.href, {
            method: 'GET',
            headers: {}
        })
            .then((response) => {
                response.arrayBuffer().then((buffer) => {
                    const url = window.URL.createObjectURL(new Blob([buffer]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'image.png');
                    document.body.appendChild(link);
                    link.click();
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleSelectedHistory = (index: number) => {
        setSelectedHistory(resultsValue[index]);
    };

    const backResults = () => {
        if (history && selectedHistory) {
            setSelectedHistory(null);
        } else {
            handleResults();
        }
    };
    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: '18px 24px',
                    cursor: 'pointer',
                    borderBottom: `1px solid ${theme.palette.grey[500]}`
                }}
            >
                <Box
                    onClick={backResults}
                    sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <ArrowBackIcon sx={{ fontSize: '26px', fill: theme.palette.success[300] }} />
                    <Typography ml={1} sx={{ color: theme.palette.success[300], fontFamily: 'Inter', fontWeight: 500, fontSize: '14px' }}>
                        <FormattedMessage id={history ? 'history' : 'results'} />
                    </Typography>
                </Box>
                {!history && (
                    <Box sx={{ width: '160px' }}>
                        <ButtonCustom
                            onClick={handleResults}
                            titleBtn={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Box mr={1} sx={{ width: '20px' }}>
                                        <CardMedia
                                            sx={{ objectFit: 'contain' }}
                                            component="img"
                                            width="18px"
                                            image={fa_solid_robot}
                                            alt="alt image"
                                        />
                                    </Box>
                                    <Typography sx={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '14px' }}>
                                        <FormattedMessage id="ai_powered" />
                                    </Typography>
                                </Box>
                            }
                            colorBtn="red"
                        />
                    </Box>
                )}
            </Box>
            <Box className="noneScrollBar" sx={{ overflow: 'auto', height: 'calc(100vh - 155px)' }}>
                {!history ? (
                    <Box p={3}>
                        <Grid container spacing={2} mb={3}>
                            {resultsValue.map((url: string, index: number) => (
                                <Grid key={index} item xs={6}>
                                    <Box
                                        onClick={() => handleOpenDialogImages(index)}
                                        sx={{
                                            width: '100%',
                                            borderRadius: '8px',
                                            border: '3px solid #fff',
                                            background: `url(${url})`,
                                            backgroundSize: '120%',
                                            backgroundPosition: 'center',
                                            p: 1,
                                            cursor: 'pointer',
                                            height: '248px',
                                            zIndex: 1
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end'
                                            }}
                                        >
                                            <a target="_blank" href={url} download onClick={(e) => download(e)} rel="noreferrer">
                                                <Box sx={{ background: '#fff', borderRadius: '6px', p: '0px 2px' }}>
                                                    <FileDownloadOutlinedIcon sx={{ fill: theme.palette.orange.main, mb: '-3px' }} />
                                                </Box>
                                            </a>
                                        </Box>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                ) : (
                    <Box>
                        {selectedHistory ? (
                            <Box p={3}>
                                <Box sx={{ background: '#FFF5F3', p: 2, borderRadius: '8px', mb: 3 }}>
                                    <Typography sx={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '14px' }}>
                                        <FormattedMessage id="image_generation" />
                                    </Typography>
                                    <Typography sx={{ fontFamily: 'Inter', fontWeight: 400, fontSize: '14px', mt: 3 }}>
                                        {selectedHistory.input.description}
                                    </Typography>
                                    <Grid container justifyContent="space-between" mt={3}>
                                        <Grid item xs={4}>
                                            <Typography sx={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '14px' }}>
                                                <FormattedMessage id="date" />
                                            </Typography>
                                            <Typography sx={{ fontFamily: 'Inter', fontWeight: 400, fontSize: '14px', mt: 1 }}>
                                                {selectedHistory.timestamp}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography sx={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '14px' }}>
                                                <FormattedMessage id="category" />
                                            </Typography>
                                            <Typography sx={{ fontFamily: 'Inter', fontWeight: 400, fontSize: '14px', mt: 1 }}>
                                                <FormattedMessage id={selectedHistory.input.category} />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography sx={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '14px' }}>
                                                <FormattedMessage id="no_of_iImages" />
                                            </Typography>
                                            <Typography sx={{ fontFamily: 'Inter', fontWeight: 400, fontSize: '14px', mt: 1 }}>
                                                {selectedHistory.input.number_of_images}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Grid container spacing={2} mb={3}>
                                    {selectedHistory.output.map((url: string, index: number) => (
                                        <Grid key={index} item xs={6}>
                                            <Box
                                                // onClick={() => handleImage(label.title)}
                                                onClick={() => handleOpenDialogImages(index)}
                                                sx={{
                                                    width: '100%',
                                                    borderRadius: '8px',
                                                    border: '3px solid #fff',
                                                    background: `url(${url})`,
                                                    // height: '138px',
                                                    backgroundSize: '120%',
                                                    backgroundPosition: 'center',
                                                    p: 1,
                                                    cursor: 'pointer',
                                                    height: '248px',
                                                    zIndex: 1
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-end',
                                                        zIndex: 2222
                                                    }}
                                                >
                                                    <a target="_blank" href={url} download onClick={(e) => download(e)} rel="noreferrer">
                                                        <Box sx={{ background: '#fff', borderRadius: '6px', p: '0px 2px' }}>
                                                            <FileDownloadOutlinedIcon
                                                                sx={{ fill: theme.palette.orange.main, mb: '-3px' }}
                                                            />
                                                        </Box>
                                                    </a>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        ) : (
                            <Box p={3}>
                                {resultsValue.map((item: any, index: number) => (
                                    <Box
                                        onClick={() => handleSelectedHistory(index)}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            p: 1,
                                            border: `1px solid ${theme.palette.grey[500]}`,
                                            borderRadius: '8px',
                                            mb: 3,
                                            cursor: 'pointer',
                                            transition: 'all .2s ease-in-out',
                                            '&:hover': {
                                                transform: 'translateY(-3px)',
                                                transition: 'all .2s ease-in-out'
                                            }
                                        }}
                                    >
                                        <Box>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Inter',
                                                    fontWeight: 600,
                                                    fontSize: '14px',
                                                    color: theme.palette.grey[400]
                                                }}
                                            >
                                                {item.input.description}
                                            </Typography>
                                            <Box sx={{ display: 'flex', mt: 2, mb: 2 }}>
                                                <Box sx={{ width: '16px' }}>
                                                    <CardMedia component="img" width="18px" image={bx_category} alt="alt image" />
                                                </Box>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Inter',
                                                        fontWeight: 500,
                                                        fontSize: '14px',
                                                        color: theme.palette.grey[400],
                                                        ml: 1
                                                    }}
                                                >
                                                    <FormattedMessage id={item.input.category} />
                                                </Typography>
                                            </Box>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Inter',
                                                    fontWeight: 500,
                                                    fontSize: '12px',
                                                    color: theme.palette.grey[400]
                                                }}
                                            >
                                                {`${item.input.number_of_images} Images`}
                                            </Typography>
                                        </Box>
                                        <ChevronRightOutlinedIcon sx={{ fill: theme.palette.grey[400] }} />
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
            <DialogImages
                open={openDialogImages}
                handleClose={handleCloseDialogImages}
                selectedHistory={selectedHistory?.output}
                selectedImage={selectedImage}
                prevImage={prevImage}
                nextImage={nextImage}
                resultsValue={resultsValue}
            />
        </Box>
    );
};

export default ImageGeneratorReults;
