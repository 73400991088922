import { Box, Drawer, Typography, Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import ButtonCustom from 'ui-component/extended/Button';
import React, { useEffect } from 'react';
import CheckboxFilter from 'ui-component/checkboxFilter/CheckboxFilter';
import SelectFilter from 'ui-component/selectFilter';
import { GetTaskFilters, GetTaskStatus, GetTaskType } from 'services/rest/workflow';
import { loadState } from 'utils/storage';
import { useLazyQuery } from '@apollo/client';
import { GET_ALL_ORGANIZATION_MEMBERS, GET_CLIENTS } from 'services/graphQL';

interface DrawerAddOffersProps {
    handleClickCloseDivider: any;
    setFilters: any;
    filters: GetTaskFilters;
}

const statusOptions: { id: GetTaskStatus; name: string }[] = [
    {
        id: 'ACTIVE',
        name: 'ACTIVE'
    },
    {
        id: 'Completed',
        name: 'COMPLETED'
    }
];

const typeOptions: { id: GetTaskType; name: string }[] = [
    {
        id: 'workflow',
        name: 'Workflow Run'
    },
    {
        id: 'standalone',
        name: 'Standalone Task'
    }
];

const Filters = ({ handleClickCloseDivider, setFilters, filters }: DrawerAddOffersProps) => {
    const theme = useTheme();
    console.log('rendering filter');
    const [selectedBrands, setSelectedBrands] = React.useState<string[]>(filters.clientOrganizationIds);
    const [selectedTeamMembers, setSelectedTeamMembers] = React.useState<string[]>(filters.userIds);
    const [selectedStatuses, setSelectedStatuses] = React.useState<GetTaskStatus[]>(filters.status);
    const [selectedTypes, setSelectedTypes] = React.useState<GetTaskType[]>([]);

    const [brandOptions, setBrandOptions] = React.useState<any>([]);
    const [teamMemberOptions, setTeamMemberOptions] = React.useState<{ id: string; name: string; image_url: string }[]>([]);

    const userInfo = loadState('user');
    const organizationId = userInfo.member.organizationId;

    const [getClients] = useLazyQuery(GET_CLIENTS, {
        variables: {
            organizationId
        },
        onCompleted: (results) => {
            const brandOptions = results.client_organizations.map((client: any) => ({
                id: client.id,
                name: client.name,
                image_url: client.logo_url
            }));
            setBrandOptions(brandOptions);
        }
    });

    const [getOrganizationMembers] = useLazyQuery(GET_ALL_ORGANIZATION_MEMBERS, {
        variables: {
            organizationId
        },
        onCompleted: (results) => {
            const members = results.organization_members.map((member: any) => ({
                id: member.user.id as string,
                name: `${member.user.first_name} ${member.user.last_name}`,
                image_url: member.user.image_url as string
            }));
            setTeamMemberOptions(members);
        }
    });

    useEffect(() => {
        console.log('initial rendering');
        getClients();
        getOrganizationMembers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submitFilters = () => {
        setFilters({
            status: selectedStatuses,
            type: selectedTypes,
            clientOrganizationIds: selectedBrands ?? [],
            userIds: selectedTeamMembers
        });
        handleClickCloseDivider();
    };

    const handleAllChecked = () => {
        if (selectedStatuses.length === statusOptions.length) {
            setSelectedStatuses([]);
        } else {
            setSelectedStatuses([...statusOptions.map((option) => option.id)]);
        }
    };
    const handleAllCheckedType = () => {
        if (selectedTypes.length === typeOptions.length) {
            setSelectedTypes([]);
        } else {
            setSelectedTypes([...typeOptions.map((option) => option.id)]);
        }
    };
    const clearFilters = () => {
        setSelectedTypes([]);
        setSelectedStatuses([]);
        setSelectedTeamMembers([]);
        setSelectedBrands([]);
    };
    return (
        <Box sx={{ minWidth: { xs: '100%', sm: '350px' } }}>
            <Grid container direction="column" justifyContent="space-between" sx={{ minHeight: { xs: '100%', xl: '100vh' } }}>
                <Grid p={3} item>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={8}>
                            <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 700, fontSize: '16px' }}>
                                <FormattedMessage id="filter" />
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <ButtonCustom onClick={clearFilters} colorBtn="white" titleBtn={<FormattedMessage id="clear_all" />} />
                        </Grid>
                    </Grid>
                    <Box mt={3} mb={1} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <Box>
                            <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500, fontSize: '14px' }}>
                                <FormattedMessage id="assignees" />
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                onClick={() => setSelectedTeamMembers([])}
                                sx={{
                                    color: theme.palette.grey[400],
                                    fontFamily: 'Inter',
                                    fontWeight: 500,
                                    fontSize: '12px',
                                    cursor: 'pointer',
                                    textAlign: 'right'
                                }}
                            >
                                <FormattedMessage id="clear" />
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ m: '20px 0' }}>
                        <SelectFilter options={teamMemberOptions} values={selectedTeamMembers} setValues={setSelectedTeamMembers} />
                    </Box>
                    <Box mt={3} mb={1} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <Box>
                            <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500, fontSize: '14px' }}>
                                <FormattedMessage id="status" />
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                onClick={handleAllChecked}
                                sx={{
                                    color: theme.palette.grey[400],
                                    fontFamily: 'Inter',
                                    fontWeight: 500,
                                    fontSize: '12px',
                                    cursor: 'pointer',
                                    textAlign: 'right'
                                }}
                            >
                                {selectedStatuses.length === statusOptions.length ? (
                                    <FormattedMessage id="clear_all" />
                                ) : (
                                    <FormattedMessage id="select_all" />
                                )}
                            </Typography>
                        </Box>
                    </Box>
                    <Box pb={2} sx={{ borderBottom: `1px solid ${theme.palette.grey[500]}` }}>
                        <CheckboxFilter options={statusOptions} setCheckboxValue={setSelectedStatuses} checkboxValue={selectedStatuses} />
                    </Box>
                    <Box mt={3} mb={1} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <Box>
                            <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500, fontSize: '14px' }}>
                                <FormattedMessage id="type" />
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                onClick={handleAllCheckedType}
                                sx={{
                                    color: theme.palette.grey[400],
                                    fontFamily: 'Inter',
                                    fontWeight: 500,
                                    fontSize: '12px',
                                    cursor: 'pointer',
                                    textAlign: 'right'
                                }}
                            >
                                {selectedTypes.length === typeOptions.length ? (
                                    <FormattedMessage id="clear_all" />
                                ) : (
                                    <FormattedMessage id="select_all" />
                                )}
                            </Typography>
                        </Box>
                    </Box>
                    <Box pb={2} sx={{ borderBottom: `1px solid ${theme.palette.grey[500]}` }}>
                        <CheckboxFilter options={typeOptions} setCheckboxValue={setSelectedTypes} checkboxValue={selectedTypes} />
                    </Box>
                    <Box mt={3} mb={1} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <Box>
                            <Typography sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500, fontSize: '14px' }}>
                                <FormattedMessage id="brand" />
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                onClick={() => setSelectedBrands([])}
                                sx={{
                                    color: theme.palette.grey[400],
                                    fontFamily: 'Inter',
                                    fontWeight: 500,
                                    fontSize: '12px',
                                    cursor: 'pointer',
                                    textAlign: 'right'
                                }}
                            >
                                <FormattedMessage id="clear" />
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ m: '20px 0' }}>
                        <SelectFilter options={brandOptions} values={selectedBrands} setValues={setSelectedBrands} />
                    </Box>
                </Grid>
                <Grid
                    item
                    container
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    sx={{ borderTop: `1px solid ${theme.palette.grey[500]}` }}
                >
                    <Grid item p={2}>
                        <Box sx={{ display: 'flex' }}>
                            <Box mr={2} sx={{ width: '87px' }}>
                                <ButtonCustom
                                    onClick={handleClickCloseDivider}
                                    colorBtn="white"
                                    titleBtn={<FormattedMessage id="cancel" />}
                                />
                            </Box>
                            <Box mr={1} sx={{ width: '130px' }}>
                                <ButtonCustom onClick={submitFilters} colorBtn="red" titleBtn={<FormattedMessage id="apply_filter" />} />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Filters;
