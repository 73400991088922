import { Box, Typography, Avatar, Popover, Input, CardMedia } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { Button } from 'ui-component/extended/Button';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import deleteIcon from 'assets/images/deleteIcon.svg';
import { DateTime } from 'luxon';
import { openSnackbar } from 'store/slices/snackbar';
import { dispatch } from 'store';
import { useMutation } from '@apollo/client';
import { Workflow_Section_Comment_Status_Enum } from 'api/codegen-graphql/generated/graphql';
import useAuth from 'hooks/useAuth';
import CommentsDrawer from './CommentDrawer';
import { SectionCommentDTO, WorkflowSectionCommentStatusEnumDTO } from 'features/workflow-task-execution/types';

interface CommentProps {
    comment: SectionCommentDTO;
    showContent?: boolean;
    updateDocumentInstance: () => void;
    handleCommentUpdate: (
        id: string,
        pageIndex: number,
        sectionIndex: number,
        status: WorkflowSectionCommentStatusEnumDTO,
        content: string
    ) => Promise<void>;
}

export default function Comment({ comment, showContent, updateDocumentInstance, handleCommentUpdate }: CommentProps) {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const { user } = useAuth();

    const [editingComment, setEditingComment] = React.useState(false);
    const [editCommentContent, setEditCommentContent] = React.useState('');
    const id = anchorEl ? 'simple-popover' : undefined;
    const isUserAllowedToEdit = comment.created_by === user?.id && comment.status === WorkflowSectionCommentStatusEnumDTO.OPEN;

    const handleStatusChange = async () => {
        const newStatus =
            comment.status === WorkflowSectionCommentStatusEnumDTO.OPEN
                ? WorkflowSectionCommentStatusEnumDTO.RESOLVED
                : WorkflowSectionCommentStatusEnumDTO.OPEN;

        await handleCommentUpdate(comment.id, comment.page_index, comment.section_index, newStatus, comment.content);
    };

    const handleCommentContentChange = async () => {
        await handleCommentUpdate(comment.id, comment.page_index, comment.section_index, comment.status, editCommentContent);
        setEditingComment(false);
    };

    const handleOpenEditComment = (value: string) => {
        setEditingComment(true);
        setEditCommentContent(value);
        closePopover();
    };
    const openPopover = (event: any) => setAnchorEl(event.currentTarget);
    const closePopover = () => setAnchorEl(null);
    // const handleStatus = () => {
    //     setStatus(
    //         status === WorkflowSectionCommentStatusEnumDTO.OPEN
    //             ? WorkflowSectionCommentStatusEnumDTO.RESOLVED
    //             : WorkflowSectionCommentStatusEnumDTO.OPEN
    //     );
    // };
    return (
        <Box mt={3}>
            <Box sx={{ display: 'flex', alignItems: editingComment ? 'flex-start' : 'center' }}>
                <Avatar sx={{ width: 24, height: 24 }} alt="Remy Sharp" src={comment?.author?.image_url} />
                {editingComment ? (
                    <Box ml={1} sx={{ border: `1px solid ${theme.palette.grey[500]}`, borderRadius: '8px', width: '100%' }}>
                        <Box sx={{ p: '12px 16px' }}>
                            <Input
                                value={editCommentContent}
                                onChange={(e) => setEditCommentContent(e.target.value)}
                                disableUnderline
                                multiline
                                maxRows={4}
                                minRows={4}
                                fullWidth
                            />
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', borderTop: `1px solid ${theme.palette.grey[500]}`, p: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ width: '87px' }}>
                                    <Button title="Cancel" onClick={() => setEditingComment(false)} variant="secondary" />
                                </Box>
                                <Box
                                    onClick={handleCommentContentChange}
                                    sx={{
                                        width: '42px',
                                        height: '42px',
                                        background: theme.palette.orange.main,
                                        borderRadius: '100px',
                                        ml: 2,
                                        cursor: 'pointer'
                                    }}
                                >
                                    <ArrowUpwardIcon sx={{ fill: '#fff', fontSize: '20px', m: '10px 0px 0px 11px' }} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                                sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500, fontSize: '14px', ml: 1 }}
                            >
                                {comment?.author?.first_name ?? 'External contributor'}
                            </Typography>
                            <Typography
                                sx={{
                                    color: theme.palette.grey[600],
                                    fontFamily: 'Inter',
                                    fontWeight: 500,
                                    fontSize: '22px',
                                    m: '-10px 5px 0px 5px'
                                }}
                            >
                                .
                            </Typography>
                            <Typography
                                sx={{
                                    color: theme.palette.grey[600],
                                    fontFamily: 'Inter',
                                    fontWeight: 500,
                                    fontSize: '10px',
                                    letterSpacing: '0.12em',
                                    textTransform: 'uppercase'
                                }}
                            >
                                {comment?.author?.organization_members[0]?.title ?? 'N/A'}
                            </Typography>
                        </Box>

                        {showContent && (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography
                                    sx={{
                                        color: theme.palette.grey[600],
                                        fontFamily: 'Inter',
                                        fontWeight: 500,
                                        fontSize: '10px',
                                        letterSpacing: '0.12em'
                                    }}
                                >
                                    {DateTime.fromISO(comment.created_at).toLocaleString({
                                        weekday: 'short',
                                        month: 'short',
                                        day: '2-digit',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })}
                                </Typography>
                                {isUserAllowedToEdit && (
                                    <Typography
                                        onClick={openPopover}
                                        sx={{
                                            color: theme.palette.grey[400],
                                            fontFamily: 'Inter',
                                            fontWeight: 500,
                                            fontSize: '18px',
                                            m: '-10px 0px 0px 10px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        ...
                                    </Typography>
                                )}
                                <Popover
                                    id={id}
                                    open={!!anchorEl}
                                    anchorEl={anchorEl}
                                    onClose={closePopover}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left'
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left'
                                    }}
                                >
                                    <Box sx={{ p: '12px 24px' }}>
                                        <Box
                                            onClick={() => handleOpenEditComment(comment.content)}
                                            sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
                                        >
                                            <CreateOutlinedIcon sx={{ width: '20px' }} />
                                            <Typography
                                                sx={{
                                                    color: theme.palette.grey[400],
                                                    fontFamily: 'Inter',
                                                    fontWeight: 500,
                                                    fontSize: '14px',
                                                    ml: '10px'
                                                }}
                                            >
                                                <FormattedMessage id="edit" />
                                            </Typography>
                                        </Box>
                                        <Box
                                            // onClick={() => handleClickDelete(row.user.id)}
                                            sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center', mt: '10px', ml: '2px' }}
                                        >
                                            <Box sx={{ width: '18px' }}>
                                                <CardMedia
                                                    sx={{ objectFit: 'contain' }}
                                                    component="img"
                                                    width="18px"
                                                    image={deleteIcon}
                                                    alt="alt image"
                                                />
                                            </Box>
                                            <Typography
                                                sx={{
                                                    color: theme.palette.grey[400],
                                                    fontFamily: 'Inter',
                                                    fontWeight: 500,
                                                    fontSize: '14px',
                                                    ml: '10px'
                                                }}
                                            >
                                                <FormattedMessage id="delete" />
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Popover>
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
            {!editingComment && (
                <Box>
                    {!showContent && (
                        <Box sx={{ display: 'flex', alignItems: 'center', ml: '34px', mt: 1 }}>
                            <Typography
                                sx={{
                                    color: theme.palette.grey[600],
                                    fontFamily: 'Inter',
                                    fontWeight: 500,
                                    fontSize: '10px',
                                    letterSpacing: '0.12em'
                                }}
                            >
                                {DateTime.fromISO(comment.created_at).toLocaleString({
                                    weekday: 'short',
                                    month: 'short',
                                    day: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit'
                                })}
                            </Typography>
                            {isUserAllowedToEdit && (
                                <Typography
                                    onClick={openPopover}
                                    sx={{
                                        color: theme.palette.grey[400],
                                        fontFamily: 'Inter',
                                        fontWeight: 500,
                                        fontSize: '18px',
                                        m: '-10px 0px 0px 10px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    ...
                                </Typography>
                            )}
                            <Popover
                                id={id}
                                open={!!anchorEl}
                                anchorEl={anchorEl}
                                onClose={closePopover}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left'
                                }}
                            >
                                <Box sx={{ p: '12px 24px' }}>
                                    <Box
                                        onClick={() => handleOpenEditComment(comment.content)}
                                        sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
                                    >
                                        <CreateOutlinedIcon sx={{ width: '20px' }} />
                                        <Typography
                                            sx={{
                                                color: theme.palette.grey[400],
                                                fontFamily: 'Inter',
                                                fontWeight: 500,
                                                fontSize: '14px',
                                                ml: '10px'
                                            }}
                                        >
                                            <FormattedMessage id="edit" />
                                        </Typography>
                                    </Box>
                                </Box>
                            </Popover>
                        </Box>
                    )}
                    {comment.section_id && showContent && (
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ ml: '34px', borderRadius: '16px', p: '4px 12px', background: theme.palette.grey[500], mt: 1 }}>
                                <Typography
                                    sx={{
                                        color: theme.palette.grey[400],
                                        fontFamily: 'Inter',
                                        fontWeight: 600,
                                        fontSize: '12px'
                                    }}
                                >
                                    {`${comment.page_name} > ${comment.section_name}`}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                    <Typography
                        sx={{
                            color: theme.palette.grey[300],
                            fontFamily: 'Inter',
                            fontWeight: 400,
                            fontSize: '12px',
                            ml: '34px',
                            mt: 1
                        }}
                    >
                        {comment.content}
                    </Typography>
                    <Box onClick={handleStatusChange} sx={{ display: 'flex', alignItems: 'center', ml: '34px', mt: 1, cursor: 'pointer' }}>
                        <Box
                            sx={{
                                width: '15px',
                                height: '15px',
                                borderRadius: '20px',
                                border:
                                    comment.status === WorkflowSectionCommentStatusEnumDTO.RESOLVED
                                        ? `1px solid #0BA5EC`
                                        : `1px solid #667085`,
                                position: 'relative',
                                mr: 1,
                                background: comment.status === WorkflowSectionCommentStatusEnumDTO.RESOLVED ? '#0BA5EC' : '#fff'
                            }}
                        >
                            <CheckIcon
                                sx={{
                                    fill: comment.status === WorkflowSectionCommentStatusEnumDTO.RESOLVED ? '#fff' : '#667085',
                                    fontSize: '10px',
                                    position: 'absolute',
                                    top: '2px',
                                    left: '1px'
                                }}
                            />
                        </Box>
                        <Typography
                            sx={{
                                color: theme.palette.grey[600],
                                fontFamily: 'Inter',
                                fontWeight: 700,
                                fontSize: '10px',
                                letterSpacing: '0.12em',
                                textTransform: 'uppercase'
                            }}
                        >
                            {comment.status === WorkflowSectionCommentStatusEnumDTO.RESOLVED ? (
                                <FormattedMessage id="resolved" />
                            ) : (
                                <FormattedMessage id="mark_as_resolved" />
                            )}
                        </Typography>
                    </Box>
                </Box>
            )}
        </Box>
    );
}
