import {
    ChangeEventHandler,
    createContext,
    MouseEventHandler,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react';
import { Workspace } from '../../../context/WorkspaceController';
import { useChatContext } from 'stream-chat-react';
import { PartialUpdateChannel } from 'stream-chat';
import { StreamChatType } from '../../../types';
import { string } from 'prop-types';
import { v4 as uuid } from 'uuid';

type UpsertChannelParams = { name: string; members: string[] };

type ChannelType = 'team' | 'messaging';

type UpsertAction = 'create' | 'update';

export type FormValues = {
    name: string;
    members: string[];
};

export type FormErrors = {
    name: string | null;
    members: string | null;
};

export interface OwnPartialUpdateChannel extends PartialUpdateChannel {
    bridge: boolean;
    allEmailBridgeList: string[];
    mentionEmailBridgeList: string[];
}

type AdminPanelFormContext = FormValues & {
    handleInputChange: ChangeEventHandler<HTMLInputElement>;
    handleMemberSelect: ChangeEventHandler<HTMLInputElement>;
    handleSubmit: MouseEventHandler<HTMLButtonElement>;
    createChannelType?: ChannelType; // why is it not in the below context? TBD
    errors: FormErrors;
    handleMemberRemove: (id: string) => Promise<null>; // new
    handleMemberAllEmailBridge: (id: string, allEmailBridge: boolean) => Promise<null>; // new
    handleMemberMentionEmailBridge: (id: string, mentionEmailBridge: boolean) => Promise<null>; // new
};

const Context = createContext<AdminPanelFormContext>({
    members: [],
    name: '',
    handleInputChange: () => null,
    handleMemberSelect: () => null,
    handleSubmit: () => null,
    errors: { name: null, members: null },
    handleMemberRemove: async () => null, // new
    handleMemberAllEmailBridge: async () => null, // new
    handleMemberMentionEmailBridge: async () => null // new
});

type AdminPanelFormProps = {
    workspace: Workspace;
    onSubmit: () => void;
    defaultValues: FormValues;
};

const getChannelTypeFromWorkspaceName = (workspace: Workspace): ChannelType | undefined =>
    workspace.match(/.*__(team|messaging)/)?.[1] as ChannelType | undefined;

const getUpsertAction = (workspace: Workspace): UpsertAction | undefined => {
    if (workspace.match('Channel-Create')) return 'create';
    if (workspace.match('Channel-Edit')) return 'update';
    return undefined;
};

export const AdminPanelForm = ({ children, defaultValues, workspace, onSubmit }: PropsWithChildren<AdminPanelFormProps>) => {
    const { client, channel, setActiveChannel } = useChatContext<StreamChatType>();
    const [name, setChannelName] = useState<string>(defaultValues.name);
    const [members, setMembers] = useState<string[]>(['thierry', 'josh']);
    const [errors, setErrors] = useState<FormErrors>({ name: null, members: null });

    const createChannelType = getChannelTypeFromWorkspaceName(workspace);
    const action = getUpsertAction(workspace);

    const createChannel = useCallback(
        async ({ name, members }: UpsertChannelParams) => {
            // console.log('createChannel');
            if (!createChannelType || members.length === 0) return;

            // Max 2023-02-14
            // uuid() instead of name as in the demo, name was used as channelId and that created issues when creating a channel with an already existing name
            // if channel didn't exist yet: all good
            // if channel already existed and the user was is: no channel was created and user was redirected to existing channel: good as well
            // if channel already existed and the user was not in: error
            // behavior now: a new channel is created each time, even if the name is the same, the id will always be unique
            const newChannel = client.channel(createChannelType, uuid(), {
                name,
                members,
                bridge: false,
                allEmailBridgeList: [],
                mentionEmailBridgeList: [],
                demo: 'team'
            });

            await newChannel.watch();

            setActiveChannel(newChannel);
            // console.log('channel', newChannel);
        },
        [createChannelType, setActiveChannel, client]
    );

    const updateChannel = useCallback(
        async ({ name, members }: UpsertChannelParams) => {
            if (name !== (channel?.data?.name || channel?.data?.id)) {
                // await channel?.update({ name }, { text: `Channel name changed to ${name}` });
                await channel?.updatePartial({ set: { name } });
            }

            if (members?.length) {
                await channel?.addMembers(members);
            }
        },
        [channel]
    );

    const validateForm = useCallback(
        ({
            action,
            createChannelType,
            values
        }: {
            // eslint-disable-next-line react/no-unused-prop-types
            values: FormValues;
            // eslint-disable-next-line react/no-unused-prop-types
            createChannelType?: ChannelType;
            // eslint-disable-next-line react/no-unused-prop-types
            action?: UpsertAction;
        }): FormErrors | null => {
            let errors: FormErrors = { name: null, members: null };

            if (action === 'create') {
                errors = {
                    name: !values.name && createChannelType === 'team' ? 'Channel name is required' : null,
                    members: values.members.length < 1 ? 'At least one additional member is required' : null
                };
            }

            if (action === 'update' && values.name === defaultValues.name && values.members.length === 0) {
                // errors = {
                //     name: 'Name not changed (change name or add members)',
                //     members: 'No new members added (change name or add members)'
                // };
            }

            return Object.values(errors).some((v) => !!v) ? errors : null;
        },
        [defaultValues.name]
    );

    const handleSubmit: MouseEventHandler<HTMLButtonElement> = useCallback(
        async (event) => {
            console.log('In AdminPanelFormContext > handleSubmit');
            console.log('event: ', event);
            event.preventDefault();
            const errors = validateForm({ values: { name, members }, action, createChannelType });
            console.log('errors: ', errors);

            if (errors) {
                setErrors(errors);
                return;
            }

            try {
                if (action === 'create') await createChannel({ name, members });
                if (action === 'update') await updateChannel({ name, members });
                onSubmit();
            } catch (err) {
                console.error(err);
            }
        },
        [action, createChannelType, name, members, createChannel, updateChannel, onSubmit, validateForm]
    );

    const handleInputChange: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
        event.preventDefault();
        setChannelName(event.target.value);
    }, []);

    const handleMemberSelect: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
        console.log('In handleMemberSelect - event: ', event);
        setMembers((prevMembers) => {
            const { value } = event.target;
            if (event.target.checked) {
                return prevMembers.length ? [...prevMembers, value] : [value];
            }
            return prevMembers?.filter((prevUser) => prevUser !== value);
        });
    }, []);

    const removeMember = async (id: string) => {
        await channel?.removeMembers([id]);
    };

    const handleMemberRemove = async (id: string) => {
        // console.log('In handleMemberRemove for userId: ', id);
        // console.log('channel: ', channel);
        removeMember(id);
        return null;
    };

    // handleMemberAllEmailBridge check if the user is already in the list and if not, check if allEmailBridge is true, then add the user to the list. If the user is in the list and allEmailBridge is false, remove the user from the list. if the user is not in the list and allEmailBridge is false, do nothing. if the user is not in the list and allEmailBridge is true, add the user to the list.
    const handleMemberAllEmailBridge = async (id: string, allEmailBridge: boolean) => {
        console.log('In handleMemberAllEmailBridge for userId: ', id);
        console.log('channel: ', channel);
        console.log('allEmailBridge: ', allEmailBridge);
        const allEmailBridgeList = (channel?.data?.allEmailBridgeList as Array<string>) || [];
        if (allEmailBridgeList.includes(id)) {
            if (!allEmailBridge) {
                const newAllEmailBridgeList = allEmailBridgeList.filter((userId: any) => userId !== id);
                // query the channel to get the current list of allEmailBridgeList, then add the new user to the list and update the channel.
                await channel?.updatePartial({ set: { allEmailBridgeList: newAllEmailBridgeList } } as unknown as OwnPartialUpdateChannel);
            }
        }
        if (!allEmailBridgeList.includes(id)) {
            if (allEmailBridge) {
                const newAllEmailBridgeList = [...allEmailBridgeList, id];
                await channel?.updatePartial({ set: { allEmailBridgeList: newAllEmailBridgeList } } as unknown as OwnPartialUpdateChannel);
            }
        }
        return null;
    };

    const handleMemberMentionEmailBridge = async (id: string, mentionEmailBridge: boolean) => {
        console.log('In handleMemberMentionEmailBridge for userId: ', id);
        console.log('channel: ', channel);
        console.log('mentionEmailBridge: ', mentionEmailBridge);
        const mentionEmailBridgeList = (channel?.data?.mentionEmailBridgeList as Array<string>) || [];
        if (mentionEmailBridgeList.includes(id)) {
            if (!mentionEmailBridge) {
                const newMentionEmailBridgeList = mentionEmailBridgeList.filter((userId: any) => userId !== id);
                await channel?.updatePartial({
                    set: { mentionEmailBridgeList: newMentionEmailBridgeList }
                } as unknown as OwnPartialUpdateChannel);
            }
        }
        if (!mentionEmailBridgeList.includes(id)) {
            if (mentionEmailBridge) {
                const newMentionEmailBridgeList = [...mentionEmailBridgeList, id];
                await channel?.updatePartial({
                    set: { mentionEmailBridgeList: newMentionEmailBridgeList }
                } as unknown as OwnPartialUpdateChannel);
            }
        }
        return null;
    };

    useEffect(() => {
        setChannelName(defaultValues.name);
        setMembers(defaultValues.members);
    }, [defaultValues, createChannelType]);

    return (
        <Context.Provider
            value={{
                createChannelType,
                errors,
                name,
                members,
                handleInputChange,
                handleMemberSelect,
                handleSubmit,
                handleMemberRemove,
                handleMemberAllEmailBridge,
                handleMemberMentionEmailBridge
            }}
        >
            {children}
        </Context.Provider>
    );
};

export const useAdminPanelFormState = () => useContext(Context);
