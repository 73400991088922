import { Box, CardMedia, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import solidImg from 'assets/images/solidImg.svg';
import { Button } from 'ui-component/extended/Button';

interface SimpleDialogProps {
    messageTextKey?: string;
    onConfirm: () => void;
    confirmTextKey?: string;
    onClose?: () => void;
    closeTextKey?: string;
    loading?: boolean;
    image?: string;
}

const DialogConfirm = ({
    onConfirm,
    onClose,
    loading,
    messageTextKey = 'are_you_sure',
    confirmTextKey = 'yes_do_it',
    closeTextKey = 'No_don_t',
    image = solidImg
}: SimpleDialogProps) => {
    const theme = useTheme();
    return (
        <Box sx={{ p: '20px', width: { xs: '100%', sm: '565px' } }}>
            <Box sx={{ width: '235px', m: '50px auto 0' }}>
                <CardMedia sx={{ objectFit: 'contain' }} component="img" width="100%" image={solidImg} alt="alt image" />
            </Box>

            <Typography
                sx={{
                    color: theme.palette.grey[400],
                    fontFamily: 'Inter',
                    fontWeight: 600,
                    fontSize: '16px',
                    textAlign: 'center',
                    mt: '20px',
                    mb: '20px'
                }}
            >
                <FormattedMessage id={messageTextKey} />
            </Typography>

            <Box pb={4} sx={{ width: '235px', m: '0 auto 0', display: 'flex', justifyContent: 'space-around' }}>
                <Box sx={{ with: '101px' }}>
                    <Button onClick={onConfirm} title={<FormattedMessage id={confirmTextKey} />} loading={loading} />
                </Box>
                {onClose && (
                    <Box sx={{ with: '101px' }}>
                        <Button onClick={onClose} title={<FormattedMessage id={closeTextKey} />} variant="secondary" />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default DialogConfirm;
