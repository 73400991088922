import { useState, useEffect } from 'react';
import { Typography, Box, Breadcrumbs, Grid, CircularProgress, CardMedia } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import NotFoundImg from 'ui-component/Offer';
import { useLazyQuery } from '@apollo/client';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import AddIcon from '@mui/icons-material/Add';
import { Button } from 'ui-component/extended/Button';
import { OfferType } from 'types/offer';
import { GET_USER_ORGANIZATIONS } from 'services/graphQL';
import shuffle from 'assets/images/shuffle.svg';
import portfolio2 from 'assets/images/portfolio2.png';
import portfolio from 'assets/images/portfolio.png';
import FreelancerCard from './feature/FreelancerCard';
import Filters from '../tasks/Filters';
import FiltersFreelancers from './feature/FiltersFreelancers';
import Profile from './profile';
import PackageDetailsDrawer from './feature/PackageDetailsDrawer';
import VirtualEmployeeCard from './feature/VirtualEmployeeCard';
import VirtualEmployeeProfile from './profile/VirtualEmployeeProfile';

const virtualEmployeeData = {
    profile_picture: '',
    first_name: 'John',
    last_name: 'Doe',
    role: 'Frontend Developer',
    short_bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nunc sit amet aliquam lacinia, nisl nisl aliq',
    bio: `Are you trying to complete a research, analytics or strategic project that is bottlenecking important decisions and actions?/n/nDo you need a high-quality deliverable fully aligned with your goals that you can feel comfortable will be done right?/n/nI am a Management Consultant passionate about helping people better understand their markets, competitors, and customers. As someone who has worked with dozens of Fortune 500 companies, startups, and investors, I know how painful getting reliable data, research, and analysis is. You don’t need to hire a Bain, BCG, or McKinsey to get high quality, expert-level work done for your company. I love research. I love analysis. I love helping others get to the next level with great research & analysis./n/nI work with you to align on your project goals, break those goals down into key questions we need to answer, set the deliverables you need, and provide them in the format that lets you provide the highest impact./n/nBenefits of working with me:/n+I help you put your best foot forward, in front of investors, colleagues, and clients/n+You get top consulting-firm level deliverables/n+You don’t have to figure out how to do the research and analysis on your own/n+You get your deliverables quickly. I’m used to working with Private Equity firms who need work done yesterday/n/nI can help you across a range of tasks including:/n✅Storyline: Creating a detailed storyline for a multi-page deck to answer a specific question/n✅Brainstorm: Coming-up with innovative ideas to answer a specific business question/n✅Email: Writing professional business emails to your clients, advisors, investors or team members/n✅Problem-solving: Decomposing a problem into sub-component and helping you answer a specific business question/n/n/nSound like a fit? I'd love to learn about your project and explore how I can help. I look forward to hearing from you!`,
    tags: 'copywriting',
    price: 100
};

const freelancers = [
    {
        name: 'Albert Flores',
        type: 'Content Writer',
        description: 'Donec dictum tristique porta. Etiam convallis lorem lobortis nulla molestie, nec tincidunt ex Donec dictum tris...',
        works: [
            {
                skil: 'Caption Writing',
                rating: 'TOP 1%',
                worksCompleted: '15',
                snikpicClient: true,
                finance: 'Financial Services'
            },
            {
                skil: 'Copywriting',
                rating: 'TOP 5%',
                worksCompleted: '4'
            },
            {
                skil: 'Design',
                rating: 'TOP 10%',
                worksCompleted: '6'
            },
            {
                skil: 'Content Writing',
                rating: 'test not taken',
                worksCompleted: '9'
            }
        ],
        reviews: [
            {
                title: 'Social Media Design for Instagram',
                rating: 4.2,
                tasksCompleted: 3,
                price: '150',
                description:
                    'Ut sodales, ex sit amet consectetur accumsan, nibh ex sollicitudin metus, volutpat lacinia arcu nibh vel ante. Proin dapibus dui eget justo tincidunt eleifend. Mauris porta elementum est. Nullam euismod quis libero sed convallis.'
            },
            {
                title: 'Social Media Design for Instagram',
                rating: 4.2,
                tasksCompleted: 3,
                price: '150',
                description:
                    'Ut sodales, ex sit amet consectetur accumsan, nibh ex sollicitudin metus, volutpat lacinia arcu nibh vel ante. Proin dapibus dui eget justo tincidunt eleifend. Mauris porta elementum est. Nullam euismod quis libero sed convallis.'
            },
            {
                title: 'Social Media Design for Instagram',
                rating: 4.2,
                tasksCompleted: 3,
                price: '150',
                description:
                    'Ut sodales, ex sit amet consectetur accumsan, nibh ex sollicitudin metus, volutpat lacinia arcu nibh vel ante. Proin dapibus dui eget justo tincidunt eleifend. Mauris porta elementum est. Nullam euismod quis libero sed convallis.'
            }
        ],
        portfolio: [
            {
                snikpicClient: true,
                finance: 'Financial Services',
                skil: 'Caption Writing',
                description:
                    'Donec dictum tristique porta. Etiam convallis lorem lobortis nulla molestie, nec tincidunt ex Donec dictum tris...',
                images: [portfolio2, portfolio, portfolio2]
            },
            {
                snikpicClient: false,
                finance: 'Commerce',
                skil: 'Copywriting',
                description:
                    'Donec dictum tristique porta. Etiam convallis lorem lobortis nulla molestie, nec tincidunt ex Donec dictum tris...',
                images: [portfolio2, portfolio, portfolio2]
            },
            {
                snikpicClient: false,
                finance: 'Commerce',
                skil: 'Copywriting',
                description:
                    'Donec dictum tristique porta. Etiam convallis lorem lobortis nulla molestie, nec tincidunt ex Donec dictum tris...',
                images: [portfolio2, portfolio, portfolio2]
            },
            {
                snikpicClient: false,
                finance: 'Commerce',
                skil: 'Copywriting',
                description:
                    'Donec dictum tristique porta. Etiam convallis lorem lobortis nulla molestie, nec tincidunt ex Donec dictum tris...',
                images: [portfolio2, portfolio, portfolio2]
            }
        ],
        packages: [
            {
                id: 1,
                description: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.',
                skil: 'Social Media Design for Instagram',
                jobs: [
                    {
                        title: 'Story Highlights',
                        volume: 3,
                        minVolume: 3,
                        price: 50,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Carousel Post',
                        volume: 1,
                        minVolume: 1,
                        price: 100,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Pictural Post',
                        volume: 2,
                        minVolume: 2,
                        price: 200,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Story',
                        volume: 2,
                        minVolume: 2,
                        price: 200,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    }
                ],
                price: '€550'
            },
            {
                id: 2,
                description: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.',
                skil: 'Social Media Design for Instagram',
                jobs: [
                    {
                        title: 'Story Highlights',
                        volume: 3,
                        minVolume: 3,
                        price: 50,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Carousel Post',
                        volume: 1,
                        minVolume: 1,
                        price: 100,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Pictural Post',
                        volume: 2,
                        minVolume: 2,
                        price: 200,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Story',
                        volume: 2,
                        minVolume: 2,
                        price: 200,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    }
                ],
                price: '€550'
            },
            {
                id: 3,
                description: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.',
                skil: 'Social Media Design for Instagram',
                jobs: [
                    {
                        title: 'Story Highlights',
                        volume: 3,
                        minVolume: 3,
                        price: 50,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Carousel Post',
                        volume: 1,
                        minVolume: 1,
                        price: 100,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Pictural Post',
                        volume: 2,
                        minVolume: 2,
                        price: 200,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Story',
                        volume: 2,
                        minVolume: 2,
                        price: 200,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    }
                ],
                price: '€550'
            },
            {
                id: 4,
                description: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.',
                skil: 'Social Media Design for Instagram',
                jobs: [
                    {
                        title: 'Story Highlights',
                        volume: 3,
                        minVolume: 3,
                        price: 50,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Carousel Post',
                        volume: 1,
                        minVolume: 1,
                        price: 100,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Pictural Post',
                        volume: 2,
                        minVolume: 2,
                        price: 200,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Story',
                        volume: 2,
                        minVolume: 2,
                        price: 200,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    }
                ],
                price: '€550'
            }
        ],
        pastExperience: [
            {
                title: 'Designer',
                subtitle: 'Snikpic',
                dateStart: '2019',
                dateEnd: 'Present',
                desc: 'Ut sodales, ex sit amet consectetur accumsan, nibh ex sollicitudin metus, volutpat lacinia arcu nibh vel ante. Proin dapibus dui eget justo tincidunt eleifend. Mauris porta elementum est. Nullam euismod quis libero sed convallis. Vestibulum fringilla felis nec turpis aliquam auctor a a lectus. Etiam porttitor at eros vitae posuere. '
            },
            {
                title: 'Designer',
                subtitle: 'Snikpic',
                dateStart: '2019',
                dateEnd: 'Present',
                desc: 'Ut sodales, ex sit amet consectetur accumsan, nibh ex sollicitudin metus, volutpat lacinia arcu nibh vel ante. Proin dapibus dui eget justo tincidunt eleifend. Mauris porta elementum est. Nullam euismod quis libero sed convallis. Vestibulum fringilla felis nec turpis aliquam auctor a a lectus. Etiam porttitor at eros vitae posuere. '
            }
        ],
        rating: '4.2',
        worksCompleted: '34',
        startPrice: '550',
        online: false
    },
    {
        name: 'Albert Flores',
        type: 'Content Writer',
        description: 'Donec dictum tristique porta. Etiam convallis lorem lobortis nulla molestie, nec tincidunt ex Donec dictum tris...',
        portfolio: [
            {
                snikpicClient: true,
                finance: 'Financial Services',
                skil: 'Caption Writing',
                description:
                    'Donec dictum tristique porta. Etiam convallis lorem lobortis nulla molestie, nec tincidunt ex Donec dictum tris...',
                images: [portfolio2, portfolio, portfolio2]
            },
            {
                snikpicClient: false,
                finance: 'Commerce',
                skil: 'Copywriting',
                description:
                    'Donec dictum tristique porta. Etiam convallis lorem lobortis nulla molestie, nec tincidunt ex Donec dictum tris...',
                images: [portfolio2, portfolio, portfolio2]
            }
        ],
        reviews: [
            {
                title: 'Social Media Design for Instagram',
                rating: 4.2,
                tasksCompleted: 3,
                price: '150',
                description:
                    'Ut sodales, ex sit amet consectetur accumsan, nibh ex sollicitudin metus, volutpat lacinia arcu nibh vel ante. Proin dapibus dui eget justo tincidunt eleifend. Mauris porta elementum est. Nullam euismod quis libero sed convallis.'
            },
            {
                title: 'Social Media Design for Instagram',
                rating: 4.2,
                tasksCompleted: 3,
                price: '150',
                description:
                    'Ut sodales, ex sit amet consectetur accumsan, nibh ex sollicitudin metus, volutpat lacinia arcu nibh vel ante. Proin dapibus dui eget justo tincidunt eleifend. Mauris porta elementum est. Nullam euismod quis libero sed convallis.'
            },
            {
                title: 'Social Media Design for Instagram',
                rating: 4.2,
                tasksCompleted: 3,
                price: '150',
                description:
                    'Ut sodales, ex sit amet consectetur accumsan, nibh ex sollicitudin metus, volutpat lacinia arcu nibh vel ante. Proin dapibus dui eget justo tincidunt eleifend. Mauris porta elementum est. Nullam euismod quis libero sed convallis.'
            }
        ],
        works: [
            {
                skil: 'Caption Writing',
                rating: 'TOP 1%',
                worksCompleted: '15',
                snikpicClient: true,
                finance: 'Financial Services'
            },
            {
                skil: 'Copywriting',
                rating: 'TOP 5%',
                worksCompleted: '4'
            },
            {
                skil: 'Design',
                rating: 'TOP 10%',
                worksCompleted: '6'
            },
            {
                skil: 'Content Writing',
                rating: 'test not taken',
                worksCompleted: '9'
            }
        ],
        packages: [
            {
                id: 1,
                description: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.',
                skil: 'Social Media Design for Instagram',
                jobs: [
                    {
                        title: 'Story Highlights',
                        volume: 3,
                        minVolume: 3,
                        price: 50,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Carousel Post',
                        volume: 1,
                        minVolume: 1,
                        price: 100,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Pictural Post',
                        volume: 2,
                        minVolume: 2,
                        price: 200,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Story',
                        volume: 2,
                        minVolume: 2,
                        price: 200,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    }
                ],
                price: '€550'
            },
            {
                id: 2,
                description: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.',
                skil: 'Social Media Design for Instagram',
                jobs: [
                    {
                        title: 'Story Highlights',
                        volume: 3,
                        minVolume: 3,
                        price: 50,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Carousel Post',
                        volume: 1,
                        minVolume: 1,
                        price: 100,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Pictural Post',
                        volume: 2,
                        minVolume: 2,
                        price: 200,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Story',
                        volume: 2,
                        minVolume: 2,
                        price: 200,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    }
                ],
                price: '€550'
            },
            {
                id: 3,
                description: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.',
                skil: 'Social Media Design for Instagram',
                jobs: [
                    {
                        title: 'Story Highlights',
                        volume: 3,
                        minVolume: 3,
                        price: 50,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Carousel Post',
                        volume: 1,
                        minVolume: 1,
                        price: 100,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Pictural Post',
                        volume: 2,
                        minVolume: 2,
                        price: 200,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Story',
                        volume: 2,
                        minVolume: 2,
                        price: 200,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    }
                ],
                price: '€550'
            },
            {
                id: 4,
                description: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.',
                skil: 'Social Media Design for Instagram',
                jobs: [
                    {
                        title: 'Story Highlights',
                        volume: 3,
                        minVolume: 3,
                        price: 50,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Carousel Post',
                        volume: 1,
                        minVolume: 1,
                        price: 100,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Pictural Post',
                        volume: 2,
                        minVolume: 2,
                        price: 200,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Story',
                        volume: 2,
                        minVolume: 2,
                        price: 200,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    }
                ],
                price: '€550'
            }
        ],
        pastExperience: [
            {
                title: 'Designer',
                subtitle: 'Snikpic',
                dateStart: '2019',
                dateEnd: 'Present',
                desc: 'Ut sodales, ex sit amet consectetur accumsan, nibh ex sollicitudin metus, volutpat lacinia arcu nibh vel ante. Proin dapibus dui eget justo tincidunt eleifend. Mauris porta elementum est. Nullam euismod quis libero sed convallis. Vestibulum fringilla felis nec turpis aliquam auctor a a lectus. Etiam porttitor at eros vitae posuere. '
            },
            {
                title: 'Designer',
                subtitle: 'Snikpic',
                dateStart: '2019',
                dateEnd: 'Present',
                desc: 'Ut sodales, ex sit amet consectetur accumsan, nibh ex sollicitudin metus, volutpat lacinia arcu nibh vel ante. Proin dapibus dui eget justo tincidunt eleifend. Mauris porta elementum est. Nullam euismod quis libero sed convallis. Vestibulum fringilla felis nec turpis aliquam auctor a a lectus. Etiam porttitor at eros vitae posuere. '
            }
        ],
        rating: '4.2',
        worksCompleted: '34',
        startPrice: '550',
        online: true
    },
    {
        name: 'Albert Flores',
        type: 'Content Writer',
        description: 'Donec dictum tristique porta. Etiam convallis lorem lobortis nulla molestie, nec tincidunt ex Donec dictum tris...',
        portfolio: [
            {
                snikpicClient: true,
                finance: 'Financial Services',
                skil: 'Caption Writing',
                description:
                    'Ut sodales, ex sit amet consectetur accumsan, nibh ex sollicitudin metus, volutpat lacinia arcu nibh vel ante. Proin dapibus dui eget justo tincidunt eleifend. Mauris porta elementum est. Nullam euismod quis libero sed convallis. ',
                images: [portfolio2, portfolio, portfolio2]
            },
            {
                snikpicClient: false,
                finance: 'Commerce',
                skil: 'Copywriting',
                description:
                    'Ut sodales, ex sit amet consectetur accumsan, nibh ex sollicitudin metus, volutpat lacinia arcu nibh vel ante. Proin dapibus dui eget justo tincidunt eleifend. Mauris porta elementum est. Nullam euismod quis libero sed convallis.',
                images: [portfolio2, portfolio, portfolio2]
            }
        ],
        reviews: [
            {
                title: 'Social Media Design for Instagram',
                rating: 4.2,
                tasksCompleted: 3,
                price: '150',
                description:
                    'Ut sodales, ex sit amet consectetur accumsan, nibh ex sollicitudin metus, volutpat lacinia arcu nibh vel ante. Proin dapibus dui eget justo tincidunt eleifend. Mauris porta elementum est. Nullam euismod quis libero sed convallis.'
            },
            {
                title: 'Social Media Design for Instagram',
                rating: 4.2,
                tasksCompleted: 3,
                price: '150',
                description:
                    'Ut sodales, ex sit amet consectetur accumsan, nibh ex sollicitudin metus, volutpat lacinia arcu nibh vel ante. Proin dapibus dui eget justo tincidunt eleifend. Mauris porta elementum est. Nullam euismod quis libero sed convallis.'
            },
            {
                title: 'Social Media Design for Instagram',
                rating: 4.2,
                tasksCompleted: 3,
                price: '150',
                description:
                    'Ut sodales, ex sit amet consectetur accumsan, nibh ex sollicitudin metus, volutpat lacinia arcu nibh vel ante. Proin dapibus dui eget justo tincidunt eleifend. Mauris porta elementum est. Nullam euismod quis libero sed convallis.'
            }
        ],
        pastExperience: [
            {
                title: 'Designer',
                subtitle: 'Snikpic',
                dateStart: '2019',
                dateEnd: 'Present',
                desc: 'Ut sodales, ex sit amet consectetur accumsan, nibh ex sollicitudin metus, volutpat lacinia arcu nibh vel ante. Proin dapibus dui eget justo tincidunt eleifend. Mauris porta elementum est. Nullam euismod quis libero sed convallis. Vestibulum fringilla felis nec turpis aliquam auctor a a lectus. Etiam porttitor at eros vitae posuere. '
            },
            {
                title: 'Designer',
                subtitle: 'Snikpic',
                dateStart: '2019',
                dateEnd: 'Present',
                desc: 'Ut sodales, ex sit amet consectetur accumsan, nibh ex sollicitudin metus, volutpat lacinia arcu nibh vel ante. Proin dapibus dui eget justo tincidunt eleifend. Mauris porta elementum est. Nullam euismod quis libero sed convallis. Vestibulum fringilla felis nec turpis aliquam auctor a a lectus. Etiam porttitor at eros vitae posuere. '
            }
        ],
        works: [
            {
                skil: 'Caption Writing',
                rating: 'TOP 1%',
                worksCompleted: '15',
                snikpicClient: true,
                finance: 'Financial Services'
            },
            {
                skil: 'Copywriting',
                rating: 'TOP 5%',
                worksCompleted: '4'
            },
            {
                skil: 'Design',
                rating: 'TOP 10%',
                worksCompleted: '6'
            },
            {
                skil: 'Content Writing',
                rating: 'test not taken',
                worksCompleted: '9'
            }
        ],
        packages: [
            {
                id: 1,
                description: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.',
                skil: 'Social Media Design for Instagram',
                jobs: [
                    {
                        title: 'Story Highlights',
                        volume: 3,
                        minVolume: 3,
                        price: 50,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Carousel Post',
                        volume: 1,
                        minVolume: 1,
                        price: 100,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Pictural Post',
                        volume: 2,
                        minVolume: 2,
                        price: 200,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Story',
                        volume: 2,
                        minVolume: 2,
                        price: 200,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    }
                ],
                price: '€550'
            },
            {
                id: 2,
                description: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.',
                skil: 'Social Media Design for Instagram',
                jobs: [
                    {
                        title: 'Story Highlights',
                        volume: 3,
                        minVolume: 3,
                        price: 50,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Carousel Post',
                        volume: 1,
                        minVolume: 1,
                        price: 100,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Pictural Post',
                        volume: 2,
                        minVolume: 2,
                        price: 200,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Story',
                        volume: 2,
                        minVolume: 2,
                        price: 200,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    }
                ],
                price: '€550'
            },
            {
                id: 3,
                description: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.',
                skil: 'Social Media Design for Instagram',
                jobs: [
                    {
                        title: 'Story Highlights',
                        volume: 3,
                        minVolume: 3,
                        price: 50,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Carousel Post',
                        volume: 1,
                        minVolume: 1,
                        price: 100,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Pictural Post',
                        volume: 2,
                        minVolume: 2,
                        price: 200,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Story',
                        volume: 2,
                        minVolume: 2,
                        price: 200,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    }
                ],
                price: '€550'
            },
            {
                id: 4,
                description: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.',
                skil: 'Social Media Design for Instagram',
                jobs: [
                    {
                        title: 'Story Highlights',
                        volume: 3,
                        minVolume: 3,
                        price: 50,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Carousel Post',
                        volume: 1,
                        minVolume: 1,
                        price: 100,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Pictural Post',
                        volume: 2,
                        minVolume: 2,
                        price: 200,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    },
                    {
                        title: 'Story',
                        volume: 2,
                        minVolume: 2,
                        price: 200,
                        description:
                            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
                    }
                ],
                price: '€550'
            }
        ],
        rating: '4.2',
        worksCompleted: '34',
        startPrice: '550',
        online: true
    }
];

const Marketplace = () => {
    const theme = useTheme();
    const [organizationName, setOrganizationName] = useState('');
    const userId = JSON.parse(`${localStorage.getItem('user')}`);
    const [openDividerFilter, setOpenDividerFilter] = useState(false);

    const [profile, setProfile] = useState(false);
    const [selectedFreelancer, setSelectedFreelancer] = useState<any>();

    const handleClickCloseDividerFilter = () => {
        setOpenDividerFilter(false);
    };
    const handleOpenDividerFilter = () => {
        setOpenDividerFilter(true);
    };
    const [getUser] = useLazyQuery(GET_USER_ORGANIZATIONS, {
        onCompleted: (res) => {
            setOrganizationName(res.organizations[0].name);
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first'
    });

    useEffect(() => {
        getUser({
            variables: {
                user_id: userId.user.id
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleSelectedFreelancer = (freelancer: any) => {
        setSelectedFreelancer(freelancer);
        setProfile(true);
    };
    return (
        <Box>
            {profile ? (
                <VirtualEmployeeProfile data={virtualEmployeeData} />
            ) : (
                <Box>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography
                            sx={{ textTransform: 'capitalize', color: theme.palette.grey[200], fontFamily: 'Inter', fontWeight: 500 }}
                        >
                            {organizationName}
                        </Typography>
                        <Typography
                            sx={{ textTransform: 'capitalize', color: theme.palette.grey[300], fontFamily: 'Inter', fontWeight: 500 }}
                        >
                            <FormattedMessage id="marketplace" />
                        </Typography>
                    </Breadcrumbs>
                    <Typography
                        mt={2}
                        mb={3}
                        sx={{
                            textTransform: 'capitalize',
                            color: theme.palette.grey[400],
                            fontSize: '24px',
                            fontWeight: 500,
                            fontFamily: 'Inter'
                        }}
                    >
                        <FormattedMessage id="marketplace" />
                    </Typography>
                    <Grid mt={1} mb={3} container justifyContent="space-between" sx={{ display: { xs: 'none', sm: 'flex' } }}>
                        <Grid item xs={6}>
                            <Box ml={1} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                <SearchSharpIcon sx={{ fill: theme.palette.grey[600] }} />
                                <Typography sx={{ color: theme.palette.grey[600], fontFamily: 'Inter', fontWeight: 500 }}>
                                    <FormattedMessage id="search" />
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Box
                                    onClick={handleOpenDividerFilter}
                                    mr={2}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        border: `1px solid ${theme.palette.dark[700]}`,
                                        borderRadius: '8px',
                                        p: '8px 16px 8px 8px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <FilterAltOutlinedIcon sx={{ fill: theme.palette.grey[600], fontSize: '20px', mt: '-1px' }} />
                                    <Typography ml={1} sx={{ color: theme.palette.grey[600], fontFamily: 'Inter', fontWeight: 500 }}>
                                        <FormattedMessage id="filter" />
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        border: `1px solid ${theme.palette.dark[700]}`,
                                        borderRadius: '8px',
                                        p: '8px 16px 8px 8px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <CardMedia sx={{ objectFit: 'contain' }} component="img" width="18px" image={shuffle} alt="alt image" />
                                    <Typography ml={1} sx={{ color: theme.palette.grey[600], fontFamily: 'Inter', fontWeight: 500 }}>
                                        <FormattedMessage id="sort" />
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    {/* {loadingFetchOffers ? (
                <Box mt={10} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : ( */}
                    <Box>
                        {freelancers.length ? (
                            <Box>
                                <Grid container spacing={5}>
                                    {freelancers.map((freelancer: any, index: number) => (
                                        <Grid item xs={12} sm={4} key={index}>
                                            <FreelancerCard freelancer={freelancer} handleSelectedFreelancer={handleSelectedFreelancer} />
                                        </Grid>
                                    ))}
                                    <Grid item xs={12} sm={4}>
                                        <VirtualEmployeeCard data={virtualEmployeeData} handleViewClick={() => {}} />
                                    </Grid>
                                </Grid>
                                <Typography
                                    mt={4}
                                    sx={{
                                        color: theme.palette.grey[600],
                                        fontFamily: 'Inter',
                                        fontWeight: 600,
                                        fontSize: '10px',
                                        textTransform: 'uppercase',
                                        letterSpacing: '0.12em',
                                        textAlign: 'center'
                                    }}
                                >
                                    <FormattedMessage id="end_of_page" /> - {freelancers?.length} <FormattedMessage id="results" />
                                </Typography>
                            </Box>
                        ) : (
                            <Box sx={{ width: '315px', m: '15% auto 0' }}>
                                <NotFoundImg title={<FormattedMessage id="no_freelancers" />} />
                            </Box>
                        )}
                    </Box>
                    {/* )} */}
                    <FiltersFreelancers openDivider={openDividerFilter} handleClickCloseDivider={handleClickCloseDividerFilter} />
                </Box>
            )}
        </Box>
    );
};

export default Marketplace;
