import { gql } from '@apollo/client';

const CHANGE_OFFER_STATUS = gql`
    mutation MyMutation($status: String!, $projectId: uuid!) {
        update_projects_by_pk(pk_columns: { id: $projectId }, _set: { status: $status }) {
            created_at
            details
            id
            name
            offer_id
            status
            business_id
            agency_id
        }
    }
`;

const SUBSCRIBE_TO_OFFER = gql`
    mutation Subscribe($agencyId: uuid, $businessId: uuid, $details: String, $name: String, $offerId: uuid) {
        insert_projects_one(
            object: { offer_id: $offerId, name: $name, details: $details, business_id: $businessId, agency_id: $agencyId }
        ) {
            id
            name
            status
            offer_id
            details
            created_at
            business_id
            agency_id
        }
    }
`;

// eslint-disable-next-line import/prefer-default-export
export { CHANGE_OFFER_STATUS, SUBSCRIBE_TO_OFFER };
