import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { ReactComponent as ChevronDownIcon } from 'assets/images/chevron-down.svg';
import SectionDropdown from '../section-dropdown';
import { WFTaskDocumentPermissions } from '@sni4/snikpic-common';

interface PageDropdownProps {
    page: any;
    documentPermissions: WFTaskDocumentPermissions[];
    setDocumentPermissions: (documentPermissions: WFTaskDocumentPermissions[]) => void;
}

const Index = ({ page, documentPermissions, setDocumentPermissions }: PageDropdownProps) => {
    const [pageChecked, setCheckedPage] = useState(false);

    return (
        <>
            <Box
                sx={{ display: 'flex', alignItems: 'center', height: '48px', backgroundColor: '#F9FAFB', cursor: 'pointer' }}
                onClick={() => setCheckedPage(!pageChecked)}
            >
                {/* <Checkbox checked={pageChecked} onChange={(event) => setCheckedPage(event.target.checked)} /> */}
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 16px', width: '100%' }}>
                    <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>{page.pageName}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <Typography sx={{ fontWeight: 600, fontSize: '12px' }}>
                            {page.pageSections.reduce((p: any) => p + 1, 0)} Sections
                        </Typography>
                        <ChevronDownIcon />
                    </Box>
                </Box>
            </Box>
            {page.pageSections.map((section: any) => (
                <SectionDropdown
                    key={section.sectionId}
                    documentPermissions={documentPermissions}
                    setDocumentPermissions={setDocumentPermissions}
                    section={section}
                />
            ))}
        </>
    );
};

export default Index;
