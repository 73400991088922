import { TextField } from '@mui/material';
import React from 'react';

interface IRole {
    role: string;
    setRole: (value: string) => void;
}

const Index = ({ role, setRole }: IRole) => {
    const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRole = event.target.value;
        setRole(newRole);
    };

    return (
        <TextField
            sx={{
                '.MuiOutlinedInput-input': { background: '#fff' }
            }}
            fullWidth
            label="Role*"
            InputLabelProps={{ shrink: true }}
            placeholder="e.g., Project Manager, Designer, etc."
            value={role}
            onChange={handleRoleChange}
        />
    );
};

export default Index;
