import { SectionPaper } from '../ui';
import AddIcon from '@mui/icons-material/Add';
import update from 'immutability-helper';
import { ReactComponent as DragThumbIcon } from 'assets/images/dragThumb.svg';
import { ReactComponent as SquareIcon } from 'assets/images/square.svg';
import { ReactComponent as CheckIcon } from 'assets/images/check.svg';
import { ReactComponent as SaveIcon } from 'assets/images/save.svg';
import { ReactComponent as CopyIcon } from 'assets/images/copy.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/deleteIcon.svg';
import { ReactComponent as VectorIcon } from 'assets/images/vector.svg';
import { ReactComponent as TextIcon } from 'assets/images/Text.svg';
import { ReactComponent as TableIcon } from 'assets/images/table.svg';
import { ReactComponent as UploadIcon } from 'assets/images/upload.svg';
import { ReactComponent as TextAreaIcon } from 'assets/images/TextArea.svg';
import { ReactComponent as ChevronsDownIcon } from 'assets/images/chevrons-down.svg';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, Button, ClickAwayListener, Dialog, Divider, Grid, Popover, TextField, Typography, useTheme } from '@mui/material';
import { memo, useCallback, useContext, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import MenuItem from '../subcomponents/menuItem';
import FieldElement, { FieldItemData } from './Field';
import { FIELD_TYPE, ItemTypes, Section, FormData, FIELD_TYPE_NAME } from '../../types';
import { useDrag, useDrop } from 'react-dnd';
import DIalogCongirm from 'ui-component/dialogConfirm';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import DocumentBuilderContext from '../context/workflow.context';
import { insertSavedSection } from 'services/rest/workflow';
import { toSectionPart, toSnikpicSection } from 'utils/formatters/workflow';
import useAuth from 'hooks/useAuth';

interface SectionProps {
    sectionIndex: number;

    handle: Function;
}

const FieldOptions = [
    { icon: <TextIcon />, name: 'Text', type: FIELD_TYPE.TEXT },
    { icon: <TextAreaIcon />, name: 'Textarea', type: FIELD_TYPE.TEXTAREA },
    { icon: <ChevronsDownIcon />, name: 'Dropdown', type: FIELD_TYPE.DROPDOWN },
    { icon: <CheckIcon />, name: 'Tickbox', type: FIELD_TYPE.TICKBOX },
    { icon: <UploadIcon />, name: 'File uploader', type: FIELD_TYPE.FILE_UPLOADER },
    // { icon: <TableIcon />, name: 'Table/Evolutive', type: FIELD_TYPE.TABLE },
    { icon: <CalendarTodayOutlinedIcon fontSize="small" />, name: 'Date', type: FIELD_TYPE.DATE },
    // { icon: <LocationOnOutlinedIcon fontSize="small" />, name: 'Location', type: FIELD_TYPE.LOCATION },
    { icon: <FormatListBulletedIcon fontSize="small" />, name: 'List', type: FIELD_TYPE.LIST }
];

interface Item {
    section: Section;
    originalIndex: number;
}

const SectionElement = memo(({ sectionIndex, handle }: SectionProps) => {
    const theme = useTheme();
    const intl = useIntl();
    const ref = useRef<HTMLDivElement>(null);
    const elementRef = useRef<HTMLDivElement>(null);
    const [titleEditing, setTitleEditing] = useState<boolean>(false);
    const [hoverIndex, setHoverIndex] = useState<number>();
    const [openAddFieldDialog, setOpenAddFieldDialog] = useState<boolean>(false);
    const [secColIdx, setSecColIdx] = useState<number>();
    const [openDialogConfirm, setOpenDialogConfirm] = useState<boolean>(false);
    const {
        pageIndex,
        setPageIndex,
        documentBuilder,
        setDocumentBuilder,
        addField,
        removeSection,
        moveSection,
        duplicateSection,
        findSection,
        removeField
    } = useContext(DocumentBuilderContext);
    const sectionData = documentBuilder.pages[pageIndex].pageSections[sectionIndex];
    const originalIndex = findSection(sectionData).index;
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const fields = documentBuilder.pages[pageIndex].pageSections[sectionIndex].sectionFields;

    const { member } = useAuth();

    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: ItemTypes.SECTION,
            item: { section: sectionData, originalIndex },
            collect: (monitor) => ({
                isDragging: monitor.isDragging()
            }),
            end: (item, monitor) => {
                const { section: droppedSection, originalIndex: idx } = item;
                const didDrop = monitor.didDrop();
                if (!didDrop) {
                    moveSection(droppedSection, idx);
                }
            }
        }),
        [sectionData, originalIndex, moveSection, sectionIndex]
    );

    const [, drop] = useDrop(
        () => ({
            accept: ItemTypes.SECTION,
            hover({ section: draggedSection }: Item) {
                if (draggedSection !== sectionData) {
                    const { section, index: overIndex } = findSection(sectionData);
                    moveSection(draggedSection, overIndex);
                }
            }
        }),
        [findSection, moveSection, sectionIndex]
    );

    const [, fieldDrop] = useDrop(() => ({ accept: ItemTypes.FIELD }));

    const handleHover = useCallback(
        (index: number) => {
            setHoverIndex(index);
        },
        [setHoverIndex]
    );

    const [{ isOver }, insertDrop] = useDrop(
        () => ({
            accept: ItemTypes.FIELD_NEW,
            drop: ({ field: draggedField }: FieldItemData) => {
                let newCards = [];

                setDocumentBuilder((newState: FormData) => {
                    if (hoverIndex === 0) {
                        newCards = [draggedField, ...newState.pages[pageIndex].pageSections[sectionIndex].sectionFields];
                    } else {
                        newCards = [...fields.slice(0, hoverIndex), draggedField, ...fields.slice(hoverIndex)];
                    }

                    return update(newState, {
                        pages: { [pageIndex]: { pageSections: { [sectionIndex]: { sectionFields: { $set: newCards } } } } }
                    });
                });
            },
            collect: (monitor: any) => ({
                isOver: monitor.isOver()
            })
        }),
        [pageIndex, hoverIndex, sectionIndex]
    );

    const [, newSectionDrop] = useDrop(
        () => ({
            accept: ItemTypes.SAVED_SECTION,
            hover({ section: draggedField }: Item) {
                handle(sectionIndex);
            }
        }),
        [pageIndex, handle, sectionIndex]
    );

    const [{ isPushOver }, pushDrop] = useDrop(
        () => ({
            accept: ItemTypes.FIELD_NEW,
            drop: ({ field: draggedField }: FieldItemData) => {
                setDocumentBuilder((currState: any) =>
                    update(currState, {
                        pages: { [pageIndex]: { pageSections: { [sectionIndex]: { sectionFields: { $push: [draggedField] } } } } }
                    })
                );
            },
            collect: (monitor: any) => ({
                isPushOver: monitor.isOver()
            })
        }),
        [pageIndex, hoverIndex, sectionIndex]
    );

    const onTitleEditClose = () => {
        setTitleEditing(false);
    };

    const handleClickPopover = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseDialogAddField = () => {
        setOpenAddFieldDialog(false);
    };

    // const handleDeleteField = (fieldIndex: number, colIdx: number) => {
    //     const secondColList = documentBuilder.pages[pageIndex].pageSections[sectionIndex].secondCols;
    //     setDocumentBuilder((currState: any) =>
    //         update(currState, {
    //             pages: {
    //                 [pageIndex]: {
    //                     pageSections: {
    //                         [sectionIndex]: {
    //                             sectionFields: { $splice: [[fieldIndex, 1]] },
    //                             secondCols: { $splice: [[secondColList.indexOf(colIdx), 1]] }
    //                         }
    //                     }
    //                 }
    //             }
    //         })
    //     );
    // };

    const handleDeleteField = (fieldId: string) => {
        removeField(sectionIndex, fieldId);
    };

    const handleAddFieldAtSecondCol = (col: number) => {
        setSecColIdx(col);
        setOpenAddFieldDialog(true);
    };

    const handleDuplicateSection = () => {
        duplicateSection(sectionData.sectionId);
    };

    const handleDeclareVariable = () => {
        const oldValue = sectionData.sectionVariable;
        setDocumentBuilder((currState: any) =>
            update(currState, {
                pages: { [pageIndex]: { pageSections: { [sectionIndex]: { sectionVariable: { $set: !oldValue } } } } }
            })
        );
    };

    const handleMakeReusable = async () => {
        // const oldValue = sectionData.isSaved;
        await insertSavedSection({
            name: sectionData.sectionName,
            content: toSectionPart(toSnikpicSection(sectionData), 0),
            organizationId: member?.organizationId ?? ''
        });
        handleClose();
    };

    const handleCloseDialogConfirm = () => {
        setOpenDialogConfirm(false);
    };

    const handleChangeSectionName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setDocumentBuilder((currState: any) =>
            update(currState, {
                pages: {
                    [pageIndex]: {
                        pageSections: {
                            [sectionIndex]: {
                                sectionName: {
                                    $set: e.target.value
                                }
                            }
                        }
                    }
                }
            })
        );
    };

    fieldDrop(insertDrop(ref));
    newSectionDrop(drag(elementRef));

    const rowCount = sectionData.sectionFields.length - sectionData.secondCols.length;

    const handleRemove = () => {
        removeSection(sectionData.sectionId);
        handleCloseDialogConfirm();
    };
    return (
        <SectionPaper ref={elementRef}>
            <Box ref={drop} sx={{ display: 'flex', alignItems: 'center', gap: '8px', height: '50px', marginBottom: '16px' }}>
                <DragThumbIcon style={{ cursor: 'pointer' }} />
                {titleEditing ? (
                    <ClickAwayListener onClickAway={onTitleEditClose}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <TextField
                                sx={{
                                    width: '498px',
                                    '.MuiOutlinedInput-input': {
                                        color: theme.palette.grey[300],
                                        fontWeight: 400,
                                        fontFamily: 'Inter',
                                        fontSize: '14px'
                                    }
                                }}
                                name={`[${pageIndex}].pageSections[${sectionIndex}].sectionName`}
                                value={documentBuilder.pages[pageIndex].pageSections[sectionIndex].sectionName}
                                onChange={handleChangeSectionName}
                                variant="outlined"
                                fullWidth
                                placeholder={intl.formatMessage({ id: 'section_name' })}
                            />
                            <CheckIcon style={{ cursor: 'pointer' }} onClick={onTitleEditClose} />
                        </Box>
                    </ClickAwayListener>
                ) : (
                    <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: '8px', cursor: 'pointer' }}
                        onClick={() => setTitleEditing(true)}
                    >
                        {sectionData.sectionVariable && <SquareIcon />}
                        <Typography variant="h4">{sectionData.sectionName}</Typography>
                    </Box>
                )}
                <MoreHorizIcon sx={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={handleClickPopover} />
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                >
                    <Box sx={{ width: '300px' }}>
                        <MenuItem icon={<SaveIcon />} name="Save (Make reusable)" onClick={handleMakeReusable} />
                        {/* <Divider />
                        <MenuItem
                            icon={<VectorIcon />}
                            name={sectionData.sectionVariable ? 'Remove variable' : 'Declare variable'}
                            onClick={handleDeclareVariable}
                        /> */}
                        <Divider />
                        <MenuItem icon={<CopyIcon />} name="Duplicate" onClick={handleDuplicateSection} />
                        <Divider />
                        <MenuItem icon={<DeleteIcon />} name="Delete" onClick={() => setOpenDialogConfirm(true)} />
                    </Box>
                </Popover>
                <Dialog
                    sx={{ '.MuiDialog-paper': { minWidth: { xs: '350px', sm: '562px' } } }}
                    open={openDialogConfirm}
                    onClose={handleCloseDialogConfirm}
                    aria-labelledby="alert-dialog-title2"
                    aria-describedby="alert-dialog-description2"
                >
                    <DIalogCongirm onConfirm={handleRemove} onClose={handleCloseDialogConfirm} />
                </Dialog>
            </Box>
            <Box ref={ref}>
                {Array(rowCount)
                    .fill(null)
                    .map((i: any, colIdx: number) => {
                        const index = colIdx + sectionData.secondCols.filter((col: any) => col < colIdx).length;
                        return (
                            <Grid container spacing={2} key={colIdx}>
                                <Grid item xs={6}>
                                    {index === hoverIndex && isOver && <Divider />}
                                    <FieldElement fieldIndex={index} key={index} sectionIndex={sectionIndex} onHover={handleHover} />
                                </Grid>
                                {
                                    sectionData.secondCols.find((col: any) => col === colIdx) !== undefined ? (
                                        <Grid item xs={6}>
                                            <FieldElement
                                                fieldIndex={index + 1}
                                                key={index + 1}
                                                sectionIndex={sectionIndex}
                                                onHover={handleHover}
                                            />
                                        </Grid>
                                    ) : (
                                        <></>
                                    )
                                    // TODO : Second field button doesn't work
                                    // (
                                    //     <Grid
                                    //         item
                                    //         xs={6}
                                    //         sx={{ display: 'flex', flexDirection: 'column-reverse', alignItems: 'start', marginBottom: '16px' }}
                                    //     >
                                    //         <Button onClick={() => handleAddFieldAtSecondCol(colIdx)}>
                                    //             <AddIcon />
                                    //             Add Field
                                    //         </Button>
                                    //     </Grid>
                                    // )
                                }
                            </Grid>
                        );
                    })}
            </Box>
            {isPushOver && <Divider />}
            <Box ref={pushDrop}>
                <Button
                    sx={{ color: theme.palette.orange.main, display: 'flex', alignItems: 'center' }}
                    onClick={() => setOpenAddFieldDialog(true)}
                >
                    <AddIcon />
                    Add Field
                </Button>
            </Box>
            <Dialog
                sx={{ '.MuiDialog-paper': { minWidth: '300px' } }}
                open={openAddFieldDialog}
                onClose={handleCloseDialogAddField}
                aria-labelledby="add-field-dialog-title"
                aria-describedby="add-field-dialog-description"
            >
                <Box sx={{ width: '300px' }}>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            paddingLeft: '20px',
                            paddingBottom: '10px',
                            color: '#393D4E',
                            fontWeight: 600,
                            fontSize: '10px',
                            textTransform: 'uppercase',
                            letterSpacing: '0.12em'
                        }}
                    >
                        Field Type
                    </Typography>
                    <Divider />
                    {FieldOptions.map((option, index: number) => (
                        <MenuItem
                            icon={option.icon}
                            name={FIELD_TYPE_NAME[option.type]}
                            key={index}
                            onClick={() => addField(sectionIndex, option.type, handleCloseDialogAddField)}
                        />
                    ))}
                </Box>
            </Dialog>
        </SectionPaper>
    );
});

export default SectionElement;
