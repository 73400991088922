import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import CreateVector from 'assets/images/createvector.svg';
import { WORKFLOW_OPTIONS } from '../types';
import CloseIcon from '@mui/icons-material/Close';
import { CreateNewButton } from './ui';
import FromTemplate from '../../options/from-template';
import DocumentBuilder from './CreateNew';
import DocumentBuilderContext from './context/workflow.context';
import useDocumentBuilder from './hooks/useDocumentBuilder';

interface WorkflowDialogProps {
    closeDialog: () => void;
}

const CreateWorkflow: FC<WorkflowDialogProps> = ({ closeDialog }) => {
    const [workflowOption, setWorkflowOption] = useState<WORKFLOW_OPTIONS>(WORKFLOW_OPTIONS.IDLE);
    const fromScratch = () => setWorkflowOption(WORKFLOW_OPTIONS.FROM_SCRATCH);
    // const fromTemplate = useCallback(() => setWorkflowOption(WORKFLOW_OPTIONS.FROM_TEMPLATE), [setWorkflowOption]);
    const backToOptions = () => setWorkflowOption(WORKFLOW_OPTIONS.IDLE);

    const builderContext = useDocumentBuilder();

    const theme = useTheme();
    return (
        <DocumentBuilderContext.Provider value={builderContext}>
            <Box sx={{ borderRadius: '4px', width: '100%' }}>
                {workflowOption === WORKFLOW_OPTIONS.IDLE && (
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography
                                ml={1}
                                sx={{ color: theme.palette.grey[400], fontFamily: 'Inter', fontWeight: 500, fontSize: '16px' }}
                            >
                                Create Workflow
                            </Typography>
                            <IconButton onClick={closeDialog}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '1rem',
                                minHeight: 'calc(100vh - 10rem)'
                            }}
                        >
                            {/* <CreateNewButton onClick={fromTemplate}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <img src={CreateVector} alt="plus-icon" />
                                    <Box>Use Template</Box>
                                </Box>
                            </CreateNewButton> */}
                            <CreateNewButton onClick={fromScratch}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <img src={CreateVector} alt="plus-icon" />
                                    <Box>Create New Workflow</Box>
                                </Box>
                            </CreateNewButton>
                        </Box>
                    </Box>
                )}
            </Box>
            {workflowOption === WORKFLOW_OPTIONS.FROM_TEMPLATE && <FromTemplate backToOptions={backToOptions} />}
            {workflowOption === WORKFLOW_OPTIONS.FROM_SCRATCH && <DocumentBuilder closeDialog={closeDialog} />}
        </DocumentBuilderContext.Provider>
    );
};

export default CreateWorkflow;
