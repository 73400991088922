import { Box, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Switch, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { WFTaskDocumentPermissions, WorkflowFieldPermissionTypes } from '@sni4/snikpic-common';

interface FieldProps {
    field: any;
    index: number;
    documentPermissions: WFTaskDocumentPermissions[];
    setDocumentPermissions: (documentPermissions: WFTaskDocumentPermissions[]) => void;
}

const Field = ({ field, index, documentPermissions, setDocumentPermissions }: FieldProps) => {
    const [isVisible] = useState(true);

    const { isRequired, permissionType, canEdit, canView } = useMemo(() => {
        const permission = documentPermissions.find((el) => el.field_id === `${field.fieldId}`) || {
            field_id: `${field.fieldId}`,
            type: WorkflowFieldPermissionTypes.READ,
            is_required: false
        };

        const isRequired = permission.is_required || false;
        const permissionType = permission.type || WorkflowFieldPermissionTypes.READ;
        const canEdit = permissionType === 'Write';
        const canView = permissionType === 'Read';

        return { isRequired, permissionType, canEdit, canView };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentPermissions]);

    const togglePermission = () => {
        const newDocumentPermissions: WFTaskDocumentPermissions[] = documentPermissions.map((el) => {
            if (el.field_id === `${field.fieldId}`) {
                const newType =
                    el.type === WorkflowFieldPermissionTypes.READ ? WorkflowFieldPermissionTypes.WRITE : WorkflowFieldPermissionTypes.READ;
                return {
                    ...el,
                    type: newType,
                    is_required: newType === WorkflowFieldPermissionTypes.READ ? false : el.is_required
                };
            }
            return el;
        });

        setDocumentPermissions(newDocumentPermissions);
    };

    const handleIsRequiredChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let documentPermissionExists = false;
        const newDocumentPermissions: WFTaskDocumentPermissions[] = documentPermissions.map((el) => {
            if (el.field_id === `${field.fieldId}`) {
                documentPermissionExists = true;
                return {
                    ...el,
                    is_required: event.target.checked
                };
            }
            return el;
        });

        if (!documentPermissionExists) {
            newDocumentPermissions.push({
                field_id: `${field.fieldId}`,
                type: permissionType,
                is_required: event.target.checked
            });
        }
        setDocumentPermissions(newDocumentPermissions);
    };

    return (
        <Box
            sx={{
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                backgroundColor: isVisible ? 'white' : '#EFF2FA',
                borderRadius: '8px',
                border: '1px solid #D4DBEA'
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ fontWeight: 600, fontSize: '14px', color: '#393D4E' }}>
                    {index + 1}. {field.fieldName}
                </Typography>
                {!isVisible && <Typography sx={{ fontSize: '12px' }}>HIDDEN</Typography>}
            </Box>
            <Divider />
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <Switch onChange={handleIsRequiredChange} checked={isRequired} disabled={!canEdit} />
                <Typography sx={{ fontWeight: 400, fontSize: '12px' }}>Required Field</Typography>
                <FormControl sx={{ marginLeft: 'auto' }}>
                    <RadioGroup row defaultValue={canEdit ? 'edit' : 'view'}>
                        <FormControlLabel
                            value="view"
                            control={
                                <Radio
                                    checked={canView}
                                    onChange={(e) => {
                                        togglePermission();
                                    }}
                                    sx={{ padding: 0 }}
                                    size="small"
                                    disabled={!isVisible}
                                />
                            }
                            label="Can view"
                            sx={{ fontSize: '12px', marginRight: '16px', '.MuiTypography-root': { ml: 1 } }}
                        />
                        <FormControlLabel
                            value="edit"
                            control={
                                <Radio
                                    checked={canEdit}
                                    onChange={(e) => {
                                        togglePermission();
                                    }}
                                    sx={{ padding: 0 }}
                                    size="small"
                                    disabled={!isVisible}
                                />
                            }
                            label="Can edit"
                            sx={{ fontSize: '12px', marginRight: '16px', '.MuiTypography-root': { ml: 1 } }}
                        />
                    </RadioGroup>
                </FormControl>
                {/* TODO - add visibility toggle */}
                {/* <IconButton
                    onClick={() => {
                        setIsVisible(!isVisible);
                    }}
                    sx={{ padding: 0, width: '24px', height: '24px' }}
                >
                    {isVisible ? <EyeShowIcon /> : <EyeHiddenIcon />}
                </IconButton> */}
            </Box>
        </Box>
    );
};

export default Field;
