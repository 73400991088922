// material-ui
import { Link, Box, Divider, Drawer, Typography, Avatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// project imports
import MobileSection from './MobileSection';
import LocalizationSection from './LocalizationSection';
import AIToolsSection from './AIToolsSection';
import MenuIcon from '@mui/icons-material/Menu';
import React from 'react';
import logoIcon from 'assets/images/logo.svg';
import { WorkSpace } from 'types';
import WorkspaceSelector from '../Sidebar/MenuList/WorkspaceSelector';
import MenuList from '../Sidebar/MenuList';
import { IconChevronLeft, IconChevronRight, IconLogout } from '@tabler/icons';
import { useDispatch } from 'store';
import useAuth from 'hooks/useAuth';
import { FormattedMessage } from 'react-intl';
import { openSnackbar } from 'store/slices/snackbar';
// ==============================|| MAIN NAVBAR / HEADER ||============================== //
const workspaceList: WorkSpace[] = [
    {
        name: 'Snikpic',
        unreadCnt: 10,
        type: 'agency',
        icon: logoIcon,
        alert: false
    },
    {
        name: 'IBM',
        unreadCnt: 56,
        type: 'client',
        icon: logoIcon,
        alert: true
    },
    {
        name: 'Nike',
        unreadCnt: 5,
        type: 'client',
        icon: logoIcon,
        alert: false
    },
    {
        name: 'Agoria',
        unreadCnt: 23,
        type: 'client',
        icon: logoIcon,
        alert: false
    }
];

const Header = () => {
    const dispatch = useDispatch();
    const [showDivider, setShowDivider] = React.useState(false);
    const [dividerOpen, setDividerOpen] = React.useState(false);
    const closeDivider = () => setShowDivider(false);
    const openDivider = () => setShowDivider(true);
    const userName: string = 'Paintyn Philips';
    const [currentWs, setCurrentWs] = React.useState('');
    const theme = useTheme();
    const { logout } = useAuth();
    const handleLogout = async () => {
        try {
            await logout();
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e.message,
                    variant: 'error'
                })
            );
        }
    };
    React.useEffect(() => {
        setCurrentWs(workspaceList[0].name);
    }, []);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
            {/* header search */}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }} />
            <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }} />

            {/* mega-menu */}
            {/* <Box sx={{ display: { xs: 'block', sm: 'none' } }}> */}
            {/* Below will display on xs and sm, and will not display as of md */}
            <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                <MenuIcon onClick={openDivider} />
                <Drawer
                    anchor="left"
                    open={showDivider}
                    onClose={closeDivider}
                    sx={{ '.MuiPaper-root': { width: !dividerOpen ? '70%' : '112px', overflow: 'visible', position: 'relative' } }}
                >
                    <Box
                        onClick={closeDivider}
                        sx={{
                            position: 'absolute',
                            right: '-70px',
                            bottom: '20px',
                            width: '40px',
                            height: '40px',
                            background: '#fff',
                            borderRadius: '8px',
                            p: 1
                        }}
                    >
                        <IconChevronLeft stroke={1.5} size="1.5rem" />
                    </Box>
                    <Box p={3}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                position: 'absolute',
                                right: 0,
                                transform: 'translateX(50%)',
                                overflow: 'hidden',
                                transition: 'all .2s ease-in-out',
                                border: '1px solid',
                                borderColor: theme.palette.grey[500],
                                borderRadius: '100%',
                                backgroundColor: 'white'
                            }}
                            onClick={() => setDividerOpen(!dividerOpen)}
                            color="inherit"
                        >
                            {!dividerOpen ? (
                                <IconChevronLeft stroke={1.5} size="1.3rem" />
                            ) : (
                                <IconChevronRight stroke={1.5} size="1.3rem" />
                            )}
                        </Avatar>
                        <WorkspaceSelector
                            userName={userName}
                            currentWs={currentWs}
                            list={workspaceList}
                            dividerOpen={dividerOpen}
                            onChangeWs={(name: string) => setCurrentWs(name)}
                        />
                    </Box>
                    <Divider />
                    <MenuList closeDivider={closeDivider} dividerOpen={dividerOpen} />
                    <Link
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            cursor: 'pointer',
                            pl: '30px'
                        }}
                        underline="none"
                        onClick={handleLogout}
                    >
                        <IconLogout size={20} strokeWidth={2} color="#616161" />
                        <Typography sx={{ color: '#616161', fontFamily: 'Inter', fontSize: '0.875rem', fontWeight: 500 }}>
                            <FormattedMessage id="log_out" />
                        </Typography>
                    </Link>
                </Drawer>
            </Box>
            {/* <Box sx={{ display: 'flex', alignItems: 'center' }}> */}
            <Box>
                <AIToolsSection />
            </Box>

            {/* live customization & localization */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <LocalizationSection />
            </Box>

            {/* notification & profile */}

            {/* mobile header */}
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                {/* <Box> */}
                <MobileSection />
            </Box>
            {/* </Box> */}
        </Box>
    );
};

export default Header;
