import { Avatar, Badge, BadgeProps, Box, CardMedia, styled, Typography, useTheme } from '@mui/material';

type ActionItemProps = {
    title?: string;
    subTitle?: string;
    icon: string;
    badge?: boolean;
    dividerOpen?: boolean;
};

const StyledBadge = styled(Badge)<BadgeProps>((_) => ({
    '& .MuiBadge-badge': {
        width: 12,
        height: 12,
        borderRadius: '100%',
        background: '#F98B7C',
        border: `2px solid white`
    }
}));

const ActionItem = ({ title, subTitle, icon, badge, dividerOpen }: ActionItemProps) => {
    const theme = useTheme();

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <StyledBadge variant={badge ? 'dot' : undefined}>
                {/* <Avatar variant="rounded" sx={{ borderRadius: '8px', width: 32, height: 32 }} src={icon} /> */}
                <Box sx={{ width: '32px', height: '32px' }}>
                    <CardMedia sx={{ objectFit: 'contain', borderRadius: '8px' }} component="img" image={icon} alt="AI tools" />
                </Box>
            </StyledBadge>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                {(title || subTitle) && (
                    <Box pl={1}>
                        <Typography variant="h4" color={theme.palette.grey[400]} fontWeight={700} fontFamily="Inter">
                            {title}
                        </Typography>
                        <Typography color={theme.palette.grey[600]} fontSize="10px" fontWeight={500}>
                            Agency
                        </Typography>
                    </Box>
                )}
            </Box>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                {!dividerOpen && (
                    <Box pl={1}>
                        <Typography variant="h4" color={theme.palette.grey[400]} fontWeight={700} fontFamily="Inter">
                            {title}
                        </Typography>
                        <Typography color={theme.palette.grey[600]} fontSize="10px" fontWeight={500}>
                            {subTitle}
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default ActionItem;
