import { MouseEventHandler } from 'react';
import PlusAdd from '../../assets/newImages/sidebar/plusadd.svg';

type AddChannelButtonProps = {
    onClick: MouseEventHandler<HTMLButtonElement>;
};

export const AddChannelButton = ({ onClick }: AddChannelButtonProps) => (
    <button type="button" onClick={onClick}>
        <img src={PlusAdd} alt="PlusAdd" />
    </button>
);
