import { SavingStatusProps } from '../components/Chips/SavingStatusChip';

export enum TaskStatusEnum {
    ACTIVE = 'ACTIVE',
    COMPLETED = 'COMPLETED',
    CLOSED = 'CLOSED'
}

export enum TaskDueDateStatusEnum {
    OVERDUE = 'overdue',
    DUE_IN_LESS_THAN_12_HOURS = 'due_in_less_than_12_hours',
    ON_TRACK = 'on_track'
}

export interface TaskDTO {
    id: number;
    title: string;
    description: string;
    status: TaskStatusEnum;
    due_date: string;
    due_date_status: TaskDueDateStatusEnum;
    subtype: string;
    user: {
        first_name: string;
        last_name: string;
        id: string;
        image_url: string;
    };
}

export enum WorkflowSectionCommentStatusEnumDTO {
    ARCHIVED = 'ARCHIVED',
    OPEN = 'OPEN',
    RESOLVED = 'RESOLVED'
}

export interface SectionCommentDTO {
    id: string;
    processmaker_process_request_id: number;
    content: string;
    status: WorkflowSectionCommentStatusEnumDTO;
    section_id: string;
    page_index: number;
    section_index: number;
    created_by: string;
    resolved_by?: string;
    created_at: string;
    updated_at?: string;
    resolved_at?: string;
    author: {
        id: string;
        first_name?: string;
        last_name?: string;
        image_url?: string;
        organization_members: {
            title?: string;
        }[];
    };
    page_id: string;
    page_name: string;
    section_name: string;
}

export interface DocumentInstanceDTO {
    name: string;
    pages: {
        name: string;
        page_id: string;
        page_index: number;
        comments_count: number;
        processmaker_process_request_id: number;
        sections: {
            name: string;
            section_id: string;
            page_index: number;
            section_index: number;
            comments?: SectionCommentDTO[];
            processmaker_process_request_id: number;
            fields: {
                id: string;
                title: string;
                type: 'TEXT' | 'TEXT_AREA' | 'DROPDOWN' | 'TICKBOX' | 'LIST' | 'DATE' | 'FILE_UPLOADER';
                row_index: number;
                column_index: number;
                page_index: number;
                section_index: number;
                body?: any;
                processmaker_process_request_id: number;
                configuration: any;
                permission?: {
                    type: string;
                    is_required: boolean;
                };
            }[];
        }[];
    }[];
}

export interface BaseFieldProps {
    field: DocumentInstanceDTO['pages'][number]['sections'][number]['fields'][number];
    setSavingStatus: (string: SavingStatusProps['status']) => void;
    handleFieldValueUpdate: (body: any) => void;
}

export interface WorkflowRun {
    processmaker_request_id: number;
    status: string;
    created_at: string;
    period: {
        start_date: string;
        end_date: string;
    };
    data: {
        type: string;
        project_id: string;
        organization_id: string;
        document_version_id: string;
        workflow_version_id: string;
        client_organization_id: string;
        workflow_version_duration: number;
    };
}
