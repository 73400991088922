import update from 'immutability-helper';
import React, { memo, FC, useContext, useCallback, useState, useRef } from 'react';
import { useDrop } from 'react-dnd';
import AddIcon from '@mui/icons-material/Add';
import SectionElement from './draggable/Section';
import { ItemTypes, Page, Section, FormData } from '../types';
import { AddSectionButton, ColumnBody } from './ui';
import { FormattedMessage } from 'react-intl';
import { Divider, Box } from '@mui/material';
import DocumentBuilderContext from './context/workflow.context';

export interface SectionContainerState {
    cards: any[];
}

interface SectionContainerProps {
    pageIndex: number;
}

interface Item {
    section: Section;
    originalIndex: number;
}

export const SectionContainer: FC<SectionContainerProps> = ({ pageIndex }) => {
    const { documentBuilder, setDocumentBuilder, setPageIndex, addSection } = useContext(DocumentBuilderContext);
    const [hoverIndex, setHoverIndex] = useState<number>();
    const sections = documentBuilder.pages[pageIndex].pageSections;
    const ref = useRef<HTMLDivElement>(null);

    // React.useEffect(() => console.log(pageIndex), [pageIndex]);

    const handleHover = useCallback(
        (index: number) => {
            setHoverIndex(index);
        },
        [setHoverIndex]
    );

    const [, drop] = useDrop(() => ({ accept: ItemTypes.SECTION }));

    const [{ isPushOver }, pushDrop] = useDrop(
        () => ({
            accept: ItemTypes.SAVED_SECTION,
            drop: ({ section: draggedSection }: Item) => {
                setDocumentBuilder((currState: any) =>
                    update(currState, {
                        pages: { [pageIndex]: { pageSections: { $push: [draggedSection] } } }
                    })
                );
            },
            collect: (monitor: any) => ({
                isPushOver: monitor.isOver()
            })
        }),
        [hoverIndex, pageIndex]
    );

    const [{ isOver }, insertDrop] = useDrop(
        () => ({
            accept: ItemTypes.SAVED_SECTION,
            drop: ({ section: draggedSection }: Item) => {
                const newSections = [...sections.slice(0, hoverIndex), draggedSection, ...sections.slice(hoverIndex)];
                setDocumentBuilder((currState: any) =>
                    update(currState, {
                        pages: { [pageIndex]: { pageSections: { $set: newSections } } }
                    })
                );
            },
            collect: (monitor: any) => ({
                isOver: monitor.isOver()
            })
        }),
        [hoverIndex]
    );

    drop(insertDrop(ref));

    return (
        <ColumnBody
            sx={{
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                flex: 1
            }}
        >
            <Box
                ref={ref}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '12px',
                    width: '100%'
                }}
            >
                {documentBuilder.pages[pageIndex].pageSections.map((section: Section, index: number) => (
                    <React.Fragment key={index}>
                        {index === hoverIndex && isOver && <Divider />}
                        <SectionElement handle={handleHover} key={index} sectionIndex={index} />
                    </React.Fragment>
                ))}
                {isPushOver && <Divider />}
            </Box>
            <Box ref={pushDrop} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <AddSectionButton onClick={addSection}>
                    <AddIcon />
                    <FormattedMessage id="add_section" />
                </AddSectionButton>
            </Box>
        </ColumnBody>
    );
};
