import { Box, Typography, Grid, CircularProgress, Slider } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_AI_TOOLS_HISTORY, GET_SERVICE_USAGE_LIMITS } from 'services/graphQL/queries/aiTool';
import { GET_CLIENTS } from 'services/graphQL';
import ViewPastRuns from './ViewPastRuns';
import useAuth from 'hooks/useAuth';
import SelectorAutocomplite from 'ui-component/SelectorAutoComplite';
import Render from 'assets/images/aiTools/Render.png';
import Action from 'assets/images/aiTools/action.png';
import foofAI from 'assets/images/aiTools/foofAI.png';
import icon from 'assets/images/aiTools/icon.png';
import Illustration from 'assets/images/aiTools/Illustration.png';
import object from 'assets/images/aiTools/object.png';
import portraitAI from 'assets/images/aiTools/portraitAI.png';
import Scene from 'assets/images/aiTools/Scene.png';
import DescriptionInputAiTool from './DescriptionInputAiTool';
import { Button } from 'ui-component/extended/Button';
import ImageGeneratorReults from './ImageGeneratorReults';
import { GENERATE_IMAGES } from 'services/graphQL/mutations/aiTools/AiToolsMutations';

// API
import { generateImagesREST } from '../../services/rest/ai_tools';

interface ImageGeneratorProps {
    handleToolsNone: () => void;
}
interface LabelType {
    title: string;
    src?: string;
}
interface LabelsImages {
    id: string;
    title: string;
    image: string;
    active: boolean;
}

const ImageGenerator = ({ handleToolsNone }: ImageGeneratorProps) => {
    const theme = useTheme();
    const intl = useIntl();
    const { member } = useAuth();
    const [pendingValue, setPendingValue] = React.useState<LabelType[]>([]);
    const [results, setResults] = React.useState(false);
    const [history, setHistory] = React.useState(false);
    const [resultsValue, setResultsValue] = React.useState<any[]>([]);
    const [errorDesc, setErrorDesc] = React.useState(false);
    const [selectedClients, setSelectedClients] = React.useState<any[]>([]);
    const [clientsLabels, setClientsLabels] = React.useState<any>([]);
    const [currentUsage, setCurrentUsage] = React.useState(0);
    const [limit, setLimit] = React.useState(0);
    const [credits, setCredits] = React.useState(0);
    const [labels, setLabels] = React.useState<LabelsImages[]>([
        { id: 'food', image: foofAI, title: intl.formatMessage({ id: 'food' }), active: false },
        { id: 'portrait', image: portraitAI, title: intl.formatMessage({ id: 'portrait' }), active: false },
        { id: 'object', image: object, title: intl.formatMessage({ id: 'object' }), active: false },
        { id: 'icon', image: icon, title: intl.formatMessage({ id: 'icon' }), active: false },
        { id: 'scene', image: Scene, title: intl.formatMessage({ id: 'scene' }), active: false },
        { id: 'illustration', image: Illustration, title: intl.formatMessage({ id: 'illustration' }), active: false },
        { id: 'render', image: Render, title: intl.formatMessage({ id: 'render' }), active: false },
        { id: 'action', image: Action, title: intl.formatMessage({ id: 'action' }), active: false }
    ]);
    const [brandDescription, setBrandDescription] = React.useState('');
    const [descriptionValid, setDescriptionValid] = React.useState(false);
    const [sizeImages, setSizeImages] = React.useState(3);
    const [errCategories, setErrCategories] = React.useState(false);
    const [errMinLength, setErrMinLength] = React.useState(false);

    const [imageGenerationIsLoading, setImageGenerationIsLoading] = React.useState(false);

    const [getClients, { loading: loadingClients }] = useLazyQuery(GET_CLIENTS, {
        onCompleted: (data) => {
            setClientsLabels(data.client_organizations);
        },
        onError: (error) => {
            console.log(error);
        }
    });
    const [fetchImageHistory, { loading: loadingFetchImageHistory }] = useLazyQuery(GET_AI_TOOLS_HISTORY, {
        onCompleted: (data) => {
            setResultsValue(data.ai_tools_history);
        }
    });

    const [getServiceUsage] = useLazyQuery(GET_SERVICE_USAGE_LIMITS, {
        onCompleted: (data) => {
            setCurrentUsage(data.ai_tools_get_usage.current);
            setLimit(data.ai_tools_get_usage.client_limit);
            setCredits(data.ai_tools_get_usage.available_credits);
        },
        onError: (error) => {
            console.log(error);
        }
    });

    const [generateImages, { loading: loadingGenerateImages }] = useMutation(GENERATE_IMAGES, {
        onCompleted(data) {
            console.log('In generateImages - data; ', data);
            // console.log({ results: data.ai_tools_generate_images });
            setResultsValue(data.ai_tools_generate_images.urls);
        }
    });

    React.useEffect(() => {
        getClients({ variables: { organizationId: member?.organizationId } });
    }, []);

    React.useEffect(() => {
        if (selectedClients[0]) {
            fetchImageHistory({
                variables: {
                    client_organization_id: selectedClients[0].id,
                    service: 'IMAGE'
                }
            });
            getServiceUsage({
                variables: {
                    client_organization_id: selectedClients[0].id,
                    service: 'IMAGE'
                }
            });
        }
    }, [selectedClients]);

    const handleHistory = () => {
        fetchImageHistory({
            variables: {
                client_organization_id: selectedClients[0].id,
                service: 'IMAGE'
            },
            fetchPolicy: 'network-only'
        }).then(() => {
            setResults(true);
            setHistory(true);
        });
    };
    const handleImage = (title: string) => {
        setLabels(
            labels.map((label: LabelsImages) => {
                if (label.title === title) return { ...label, active: true };
                return { ...label, active: false };
            })
        );
    };
    const handleOpenResults = async () => {
        const arrTrueCategory: any = [];
        labels.forEach((item: any) => {
            if (item.active) arrTrueCategory.push(item);
        });
        if (
            brandDescription.split(' ').length > 4 && // Minimum 5 words
            brandDescription.split(' ').length < 201 && // Max 200 words
            brandDescription.length !== 0 &&
            arrTrueCategory.length > 0
        ) {
            setErrorDesc(false);
            setErrCategories(false);
            setImageGenerationIsLoading(true);
            const variables = {
                category: arrTrueCategory[0].id,
                client_organization_id: selectedClients[0].id,
                description: brandDescription,
                number_of_images: sizeImages
            };
            const response = await generateImagesREST(variables);
            const urls = response.data.map((image: any) => image.url);
            setImageGenerationIsLoading(false);
            setResultsValue(urls);
            setResults(true);
            setHistory(false);
        } else {
            setErrorDesc(true);
            if (arrTrueCategory.length === 0) setErrCategories(true);
            if (brandDescription.split(' ').length < 5) setErrMinLength(true);
        }
    };
    const handleChangeSizeImages = (event: Event, newValue: number | number[]) => {
        setSizeImages(newValue as number);
    };
    function valuetext(value: number) {
        return `${value}`;
    }
    const handleCloseResults = () => {
        setResults(false);
        setHistory(false);
    };
    return (
        <Box>
            {/* {loadingGenerateImages ? ( */}
            {imageGenerationIsLoading ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 'calc(100vh - 81px)'
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (
                <Box>
                    {results ? (
                        <ImageGeneratorReults resultsValue={resultsValue} handleResults={handleCloseResults} history={history} />
                    ) : (
                        <Box>
                            <Box onClick={handleToolsNone} sx={{ display: 'flex', alignItems: 'center', p: '24px', cursor: 'pointer' }}>
                                <ArrowBackIcon sx={{ fontSize: '26px', fill: theme.palette.success[300] }} />
                                <Typography
                                    ml={1}
                                    sx={{ color: theme.palette.success[300], fontFamily: 'Inter', fontWeight: 500, fontSize: '14px' }}
                                >
                                    <FormattedMessage id="image_generation" />
                                </Typography>
                            </Box>
                            <Box className="noneScrollBar" sx={{ overflow: 'auto', height: 'calc(100vh - 155px)' }}>
                                {selectedClients.length > 0 ? (
                                    <ViewPastRuns
                                        size={currentUsage}
                                        handleHistory={handleHistory}
                                        limit={limit}
                                        credits={credits}
                                        loading={loadingFetchImageHistory}
                                    />
                                ) : null}
                                <Box sx={{ p: '10px 24px 24px 24px' }}>
                                    {loadingClients ? (
                                        <CircularProgress />
                                    ) : (
                                        <SelectorAutocomplite
                                            autocompleteItems={clientsLabels}
                                            pendingValue={pendingValue}
                                            setPendingValue={setPendingValue}
                                            arrItemSelected={selectedClients}
                                            setArrItemsSelected={setSelectedClients}
                                            title="client"
                                            imagePath="logo_url"
                                            labelPath="name"
                                            singleSelect
                                        />
                                    )}
                                </Box>
                                {selectedClients.length > 0 && (
                                    <Box sx={{ p: '0 24px 24px 24px' }}>
                                        <Grid container spacing={2} mb={3}>
                                            {labels.map((label: LabelsImages, index: number) => (
                                                <Grid key={index} item xs={6}>
                                                    <a href="#" className="noneUnerline">
                                                        <Box
                                                            onClick={() => handleImage(label.title)}
                                                            sx={{
                                                                width: '100%',
                                                                borderRadius: '8px',
                                                                border: label.active
                                                                    ? `3px solid ${theme.palette.orange.main}`
                                                                    : '3px solid #fff',
                                                                background: `url(${label.image})`,
                                                                height: '138px',
                                                                backgroundSize: '120%',
                                                                backgroundPosition: 'center',
                                                                p: 1,
                                                                cursor: 'pointer',
                                                                transition: 'all .2s ease-in-out',
                                                                '&:hover': {
                                                                    transform: 'translateY(-3px)',
                                                                    transition: 'all .2s ease-in-out'
                                                                }
                                                            }}
                                                        >
                                                            <Box sx={{ display: 'flex' }}>
                                                                <Typography
                                                                    sx={{
                                                                        color: label.active ? '#fff' : theme.palette.grey[300],
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: 500,
                                                                        fontSize: '14px',
                                                                        p: '6px 8px',
                                                                        background: label.active ? theme.palette.orange.main : '#fff',
                                                                        borderRadius: '6px',
                                                                        textDecoration: 'none !important'
                                                                    }}
                                                                >
                                                                    {label.title}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </a>
                                                </Grid>
                                            ))}
                                        </Grid>
                                        {errCategories && (
                                            <Typography
                                                mb={2}
                                                sx={{
                                                    color: '#c62828',
                                                    fontFamily: 'Inter',
                                                    fontWeight: 400,
                                                    fontSize: '12px',
                                                    mt: 1
                                                }}
                                            >
                                                <FormattedMessage id="please_select_a_category" />
                                            </Typography>
                                        )}
                                        {errMinLength && (
                                            <Typography
                                                mb={2}
                                                sx={{
                                                    color: '#c62828',
                                                    fontFamily: 'Inter',
                                                    fontWeight: 400,
                                                    fontSize: '12px',
                                                    mt: 1
                                                }}
                                            >
                                                <FormattedMessage id="description_must_be_at_least_5_words" />
                                            </Typography>
                                        )}
                                        <Box id="desc">
                                            <DescriptionInputAiTool
                                                value={brandDescription}
                                                setValue={setBrandDescription}
                                                placholder="description_image_ai_tools"
                                                totalWordsLength={200}
                                                error={errorDesc}
                                                title="image_description"
                                                setValid={setDescriptionValid}
                                                rows={2}
                                            />
                                        </Box>
                                        <Typography
                                            mb={2}
                                            sx={{
                                                color: theme.palette.grey[400],
                                                fontFamily: 'Inter',
                                                fontWeight: 600,
                                                fontSize: '14px',
                                                mt: 3
                                            }}
                                        >
                                            <FormattedMessage id="number_of_images" />
                                        </Typography>
                                        <Slider
                                            getAriaValueText={() => valuetext(sizeImages)}
                                            onChange={handleChangeSizeImages}
                                            value={sizeImages}
                                            step={1}
                                            min={1}
                                            valueLabelDisplay="on"
                                            max={10}
                                            sx={{
                                                '.MuiSlider-rail': {
                                                    color: theme.palette.grey[800],
                                                    height: '8px'
                                                },
                                                '.MuiSlider-track': {
                                                    height: '8px'
                                                },
                                                '.MuiSlider-thumbColorPrimary': {
                                                    width: '24px',
                                                    height: '24px',
                                                    background: '#fff',
                                                    border: `1px solid ${theme.palette.orange.main}`
                                                },
                                                '.MuiSlider-valueLabelOpen': {
                                                    background: 'transparent',
                                                    color: theme.palette.grey[400],
                                                    fontWeight: 500,
                                                    fontSize: '16px',
                                                    transform: 'translateY(130%) !important'
                                                }
                                            }}
                                        />
                                        <Box sx={{ width: '160px', mt: 6, pb: { xs: '100px', sm: 0 } }}>
                                            <Button
                                                onClick={handleOpenResults}
                                                disabled={(currentUsage >= limit && credits < 1) || !descriptionValid}
                                                loading={imageGenerationIsLoading}
                                                title={
                                                    <Typography sx={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '14px' }}>
                                                        <FormattedMessage id="generate_images" />
                                                    </Typography>
                                                }
                                            />
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default ImageGenerator;
