import React, { useContext, useEffect } from 'react';
import { WORKFLOW_NODE_TYPES } from 'views/pages/clients/features/detail/workflow/features/types';
import { Accordion, AccordionDetails, AccordionSummary, Box, Input, InputAdornment, Typography } from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WebhookIcon from '@mui/icons-material/Webhook';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import ErrorsBlock from './ErrorsBlock';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DocumentBuilderContext from '../../document-builder/context/workflow.context';
import { AutomationNodeDraggableItemList } from './AutomationNodeDraggableItemList';

const SideBarWorkflow = () => {
    const [expandedTriggers, setxpandedTriggers] = React.useState(true);
    const [expandedTasks, setExpandedTasks] = React.useState(true);
    const [valueInputSearch, setValueInputSearch] = React.useState('');
    const [expandedAutomation, setExpandedAutomation] = React.useState(true);
    const [expandedEnd, setExpandedEnd] = React.useState(true);
    const { documentBuilder } = useContext(DocumentBuilderContext);

    const initialDisabledNodes = {
        scheduledTime: false,
        webhook: false
    };

    const [disabledNode, setDisabledNode] = React.useState(initialDisabledNodes);

    useEffect(() => {
        setDisabledNode(initialDisabledNodes);

        documentBuilder.workflow.flowNodes.map((el: any) => {
            if (el.type === 'trigger') {
                setDisabledNode({
                    scheduledTime: true,
                    webhook: true
                });
            }
        });
    }, [documentBuilder]);

    const intl = useIntl();
    const theme = useTheme();

    const onDragStart = (event: any, nodeType: any) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };

    return (
        <Box sx={{ width: '303px', position: 'relative' }}>
            <Input
                id="input-with-icon-adornment"
                disableUnderline
                value={valueInputSearch}
                onChange={(e) => setValueInputSearch(e.target.value)}
                startAdornment={
                    <InputAdornment position="start">
                        <SearchRoundedIcon />
                    </InputAdornment>
                }
                fullWidth
                placeholder={intl.formatMessage({ id: 'placeholder_search_triggers' })}
                sx={{ p: '8px 16px' }}
            />
            <div className="description">
                <Accordion
                    expanded={expandedTriggers}
                    onChange={(e, isExpanded) => setxpandedTriggers(isExpanded)}
                    sx={{ m: '0px !important' }}
                >
                    <AccordionSummary
                        sx={{
                            background: expandedTriggers ? theme.palette.dark[100] : '#fff',
                            minHeight: '34px !important',
                            p: '0 24px',
                            '.MuiAccordionSummary-content': { m: 0 },
                            m: '0 !important'
                        }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography
                            sx={{
                                color: theme.palette.grey[400],
                                fontFamily: 'Inter',
                                fontWeight: 500,
                                fontSize: '10px',
                                textTransform: 'uppercase',
                                letterSpacing: '0.12em'
                            }}
                        >
                            <FormattedMessage id="triggers" />
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                        <Box
                            onDragStart={(event) => onDragStart(event, WORKFLOW_NODE_TYPES.SCHEDULE_NODE)}
                            draggable={!disabledNode.scheduledTime}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: disabledNode.scheduledTime ? 'default' : 'grab',
                                p: '16px 24px',
                                borderBottom: ` 1px solid ${theme.palette.grey[500]}`,
                                background: disabledNode.scheduledTime ? theme.palette.text.hint : '#fff'
                            }}
                        >
                            <AccessTimeIcon
                                sx={{
                                    fontSize: '20px',
                                    fill: disabledNode.scheduledTime ? theme.palette.grey[600] : theme.palette.grey[400]
                                }}
                            />
                            <Typography
                                sx={{
                                    color: disabledNode.scheduledTime ? theme.palette.grey[600] : theme.palette.grey[400],
                                    fontFamily: 'Inter',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    ml: 1
                                }}
                            >
                                <FormattedMessage id="scheduled_time" />
                            </Typography>
                        </Box>
                        {/* <Box
                            onDragStart={(event) => onDragStart(event, WORKFLOW_NODE_TYPES.WEBHOOK_NODE)}
                            draggable={!disabledNode.webhook}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: disabledNode.webhook ? 'default' : 'grab',
                                p: '16px 24px',
                                background: disabledNode.webhook ? theme.palette.text.hint : '#fff'
                            }}
                        >
                            <WebhookIcon
                                sx={{ fontSize: '20px', fill: disabledNode.webhook ? theme.palette.grey[600] : theme.palette.grey[400] }}
                            />
                            <Typography
                                sx={{
                                    color: disabledNode.webhook ? theme.palette.grey[600] : theme.palette.grey[400],
                                    fontFamily: 'Inter',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    ml: 1
                                }}
                            >
                                <FormattedMessage id="webhook" />
                            </Typography>
                        </Box> */}
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expandedTasks} onChange={(e, isExpanded) => setExpandedTasks(isExpanded)} sx={{ m: '0px !important' }}>
                    <AccordionSummary
                        sx={{
                            background: expandedTasks ? theme.palette.dark[100] : '#fff',
                            minHeight: '34px !important',
                            p: '0 24px',
                            '.MuiAccordionSummary-content': { m: 0 },
                            m: '0 !important'
                        }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography
                            sx={{
                                color: theme.palette.grey[400],
                                fontFamily: 'Inter',
                                fontWeight: 500,
                                fontSize: '10px',
                                textTransform: 'uppercase',
                                letterSpacing: '0.12em'
                            }}
                        >
                            <FormattedMessage id="tasks" />
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                        <Box
                            onDragStart={(event) => onDragStart(event, WORKFLOW_NODE_TYPES.TASK_NODE)}
                            draggable
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'grab',
                                p: '16px 24px',
                                borderBottom: ` 1px solid ${theme.palette.grey[500]}`,
                                background: '#fff'
                            }}
                        >
                            <TextSnippetOutlinedIcon
                                sx={{
                                    fontSize: '20px',
                                    fill: theme.palette.grey[400]
                                }}
                            />
                            <Typography
                                sx={{
                                    color: theme.palette.grey[400],
                                    fontFamily: 'Inter',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    ml: 1
                                }}
                            >
                                <FormattedMessage id="standard_task" />
                            </Typography>
                        </Box>
                        <Box
                            onDragStart={(event) => onDragStart(event, WORKFLOW_NODE_TYPES.APPROVAL_NODE)}
                            draggable
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'grab',
                                p: '16px 24px',
                                background: '#fff'
                            }}
                        >
                            <TaskOutlinedIcon
                                sx={{
                                    fontSize: '20px',
                                    fill: theme.palette.grey[400]
                                }}
                            />
                            <Typography
                                sx={{
                                    color: theme.palette.grey[400],
                                    fontFamily: 'Inter',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    ml: 1
                                }}
                            >
                                <FormattedMessage id="approval_task" />
                            </Typography>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                {process.env.REACT_APP_SHOW_AUTOMATION === 'true' && (
                    <>
                        <Accordion
                            expanded={expandedAutomation}
                            onChange={(e, isExpanded) => setExpandedAutomation(isExpanded)}
                            sx={{ m: '0px !important' }}
                        >
                            <AccordionSummary
                                sx={{
                                    background: expandedAutomation ? theme.palette.dark[100] : '#ff',
                                    minHeight: '34px !important',
                                    p: '0 24px',
                                    '.MuiAccordionSummary-content': { m: 0 },
                                    m: '0 !important'
                                }}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography
                                    sx={{
                                        color: theme.palette.grey[400],
                                        fontFamily: 'Inter',
                                        fontWeight: 500,
                                        fontSize: '10px',
                                        textTransform: 'uppercase',
                                        letterSpacing: '0.12em'
                                    }}
                                >
                                    <FormattedMessage id="automation" />
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ p: 0 }}>
                                <AutomationNodeDraggableItemList onDragStart={onDragStart} />
                            </AccordionDetails>
                        </Accordion>
                    </>
                )}
                <Accordion expanded={expandedEnd} onChange={(e, isExpanded) => setExpandedEnd(isExpanded)} sx={{ m: '0px !important' }}>
                    <AccordionSummary
                        sx={{
                            background: expandedAutomation ? theme.palette.dark[100] : '#ff',
                            minHeight: '34px !important',
                            p: '0 24px',
                            '.MuiAccordionSummary-content': { m: 0 },
                            m: '0 !important'
                        }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography
                            sx={{
                                color: theme.palette.grey[400],
                                fontFamily: 'Inter',
                                fontWeight: 500,
                                fontSize: '10px',
                                textTransform: 'uppercase',
                                letterSpacing: '0.12em'
                            }}
                        >
                            <FormattedMessage id="end" />
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                        <Box
                            onDragStart={(event) => onDragStart(event, WORKFLOW_NODE_TYPES.END_NODE)}
                            draggable
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'grab',
                                p: '16px 24px',
                                background: '#fff'
                            }}
                        >
                            <FiberManualRecordIcon sx={{ fontSize: '20px', fill: '#000' }} />
                            <Typography
                                sx={{
                                    color: theme.palette.grey[400],
                                    fontFamily: 'Inter',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    ml: 1
                                }}
                            >
                                <FormattedMessage id="end_of_flow" />
                            </Typography>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </div>
            <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
                <ErrorsBlock />
            </Box>
        </Box>
    );
};

export default SideBarWorkflow;
